import { addwishlisthook } from 'actions/currencycategoryAction';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import isEmpty from "lib/isEmpty";
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const CreateWishlist = (props) => {
    const [whishlist, setWishlist] = useState(true)
    const [name, setName] = useState()
    const [error, setError] = useState({})
    const { isAuth } = useSelector(state => state.auth);
    console.log("Auth", isAuth)
    const create = async () => {
        // props.wishname(name); 
        console.log("props", props)
        // props.wishname(name);
        if (name === "" || name == undefined || name == null) {
            setError({ 'name': 'Field should not be empty' })
        } else {
            let payload = {
                name: name,
                id: props.id
            }
            let result = await addwishlisthook(payload);
            console.log("result>>>>>", result);
            props.refresh();
            props.onDismiss()
        }
    }
    let history = useHistory();
    const handleClick = () => {
        history.push('/login');
    }
    return (
        <>
            <Modal show={whishlist} size="sm" centered>
                <Modal.Header className='align-items-center'>

                    <Modal.Title>Create New Wishlist</Modal.Title>
                    <button className='closebtn btn btn-link text-light nounder' onClick={() => props.onDismiss()}>
                        <i className="fa fa-times fa-6" aria-hidden="true"></i>

                    </button>
                </Modal.Header>
                <Modal.Body>
                    <p className='mb-2 f-12'>Do you want to create your own wishlist</p>
                    <input type="text" className="form-control themeinput" maxlength="10" value={name}
                        onChange={(e) => setName(e.target.value)} />
                    <span className="text-danger">{error.name}</span>

                    <div className='mt-3'>
                        {isAuth === true ? <button className="btn green-btn mr-2" onClick={() => create()}>Add</button> : <button className="btn green-btn mr-2" onClick={handleClick}>Login</button>}
                        <button className="btn red-btn" onClick={() => props.onDismiss()}>Cancel</button>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default CreateWishlist