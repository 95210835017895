// import constant
import {
    SET_CATEGORY_PAIR
} from '../constant';

const initialValue = []

const categoryPair = (state = initialValue, action) => {
    // console.log("SET_CATEGORY_PAIR-.",action.data);
    switch (action.type) {
        case SET_CATEGORY_PAIR:
            return[
                ...action.data
            ]
        default:
            return state;
    }
}

export default categoryPair;