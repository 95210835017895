import React, { useState } from 'react';
import {Modal,Tabs,Dropdown,InputGroup,FormControl,Button, Form, Tab} from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { MenuItem, Select } from '@material-ui/core';
import { useDispatch } from 'react-redux';

// import component
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem"

import {updateIdProof} from '../../../../actions/userKyc'

import isEmpty from '../../../../lib/isEmpty';
import { toastAlert } from '../../../../lib/toastAlert';
import validation from '../../../IDProof/validation';

 


const Checkboxvalidatemodal = (props) => {
   
    const [checkboxvalidate, setCheckboxvalidate] = useState(true)
   //state
   




    return(
        <>
            <Modal show={checkboxvalidate} className="modalbg uploadmodal font-12" size="md" centered scrolable>
   
            <Modal.Header className='align-items-center'> 
               <Modal.Title>Trading Conditions</Modal.Title>
               <button className='closebtn btn btn-link text-light nounder' onClick={() =>props.onDismiss()}>
               <i className="fa fa-times fa-6" aria-hidden="true"></i>

                </button>
            </Modal.Header>
            <Modal.Body className='font-12'>
            <p className="text-center font_14_kyc_he">You have to confirm all Trading conditions in order to send the KYC FORM</p>
                 <div className="text-center d-flex align-items-center justify-content-center mt-3 pb-2">
                    <button className="btn btn btn-light" onClick={() =>props.onDismiss()}>Proceed</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Checkboxvalidatemodal

