import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { Link } from "react-router-dom"
import PropTypes from "prop-types";
import clsx from "classnames";
import {
  Checkbox,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import "./styles.css";
import "./example-styles.css";
import { Tabs, Tab, InputGroup, FormControl, Form, Button, Dropdown, Accordion, Card, Col, Row } from 'react-bootstrap'
import isLogin from '../../lib/isLogin';
import SocketContext from '../Context/SocketContext';
import { capitalize } from '../../lib/stringCase';
import { momentFormat } from '../../lib/dateTimeHelper'
import { toFixed, toLocaleString, lotDisplay } from '../../lib/roundOf'

// import component
import MarketPriceTable from "./Markerpricetable";
import NewOrder from './newtrade/Modals/NewOrder';
import DoneOrder from './newtrade/Modals/DoneOrder';
import InfoOrder from './newtrade/Modals/InfoOrder';
import Positionorder from './newtrade/Positionorder'
import ConfirmModal from "./newtrade/Modals/ConfirmModal";
import ErrorModal from './newtrade/Modals/ErrorModal'

import Chart from "./Chart";
import RecentTrade from "./RecentTrade";
import Sidebar from "./newtrade/Sidebar";
// import Sidebar from "./newtrade/Sidebar";
import setting from '../../assets/images/newtrade/setting.svg'
import star from '../../assets/images/newtrade/star.svg'
import flag from '../../assets/images/newtrade/flag.svg'
import loaderimg from '../../assets/images/newtrade/LOADING_LOGO_3.gif'

import Symboltab from "./newtrade/elements/Symboltab";

import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import ReactTooltip from "react-tooltip";

import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from 'moment';

// import action

import {
  getPositionOrder,
  orderPlace,
  getTradeData,
  getuserTradeData,
  cancelOrder,
  getTransaction,
  filterPositionOrder,
  cancelAllOrder,
  filterTransaction,
  filterclosedposition,
  getcalenderData,
  getMarketHours,
  historyloadmore,
  Transactionloadmore,
  Orderloadmore,
  // priceConversion,
  priceConversiontosecond,
  CloseAll
} from '../../actions/perpetualTradeAction';

import { setTradePair, setPairList, getPairList } from '../../actions/spotTradeAction'
import { getAssetData } from "actions/walletAction";
import { setEquAndmarign, userPair } from "../../actions/users";
//import lib
import isEmpty from "lib/isEmpty";
import { ObjectIsempty } from "lib/isEmpty";
import { encryptObject } from '../../lib/cryptoJS'
import { toastAlert } from '../../lib/toastAlert';
import { inverseOrderCost, orderValue, SpreadCal, balanecDispaly, BidandAskDisplay, EstMargin, volumetoquantity, priceConversion, getPorfitCalculation, quantitytovolume } from '../../lib/bybit';
import { SET_SPOT_ORDER_BOOK } from "constant";

import close from '../../assets/images/newtrade/close.svg'
import { USER_REGISTER_START } from "constant";
import infoimg from '../../assets/images/newtrade/info.svg'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import validation from "./validation";
const initialData = {
  currentPage: 1,
  nextPage: true,
  limit: 10,
  count: 0,
  data: []
}

const initialFormValue = {
  'price': '',
  'quantity': '0',
  'total': '',
  'leverage': '1:100',
  'isProfitLoss': false,
  'typeTIF': 'GTC',
  'pairname': '',
  'Expriydate': '',
  'comment': '',
  'takeprofit': 0,
  'stopprice': 0,
  'Limit_range_price': 0,
  'Limit_range_pips': 0,
  'orderType': 'market',
  'buyorsell': 'buy'
}

const Type = localStorage.getItem("liveorDemo")
const ShowcaseLayout = (props) => {

  const { tikerRoot } = useParams()
  let dispatch = useDispatch()
  const socketContext = useContext(SocketContext)
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const orderBookRef = useRef();



  //state
  const [History, setHistory] = useState([])
  const [closed_position, setClosedPos] = useState([]);
  const [bclosed_position, setBclosedPos] = useState([])
  const [orderHistoryCnt, setorderHistoryCnt] = useState(0)
  const [closedhistoryCnt, setClosedhistoryCnt] = useState(0)
  const [loader, setLoader] = useState({})
  const [orderData, setOrderData] = useState(initialData)
  const [filled, setfilled] = useState()
  const [fillterposition, setfillterposition] = useState(false)
  // console.log("fillterposition_fillterpositionv", fillterposition);
  const [openorder, setOpenorder] = useState()
  const [bopenorder, setBopenorder] = useState();
  const [positionorder, setPositionorder] = useState([])
  console.log("positionorder---------", positionorder);
  const [fpositinorder, setfposition] = useState(false)
  // console.log("fpositinorder_V_fpositinorder_fpositinorderfpositinorder", fpositinorder);
  const [weekday, setweekday] = useState(["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"])
  const [bpositionorder, setBpositionorder] = useState([]);
  const [sellLoader, setSellLoader] = useState(false)
  const [sellLoaders, setMarketSellLoader] = useState(false)
  const [submit, setSubmit] = useState("")
  const [submited, setSubmited] = useState("")
  const [buyLoader, setBuyLoader] = useState(false)
  const [limitloader, setLimitLoader] = useState(false)
  const [marketloader, setMarketLoader] = useState(false)
  const [stoploader, setstoploader] = useState(false)
  const [stoplimitloader, setstoplimitloader] = useState(false)
  const [buyLoaders, setMarketBuyLoader] = useState(false)
  const [marketbuyorsell, setBuysell] = useState();
  const [formValue, setFormValue] = useState(initialFormValue);
  const [calender, setCalenderData] = useState([])
  const [currentBreakpoint, setCurrentBreakpoint] = useState("lg");
  const [markethoures, Setmarketdate] = useState()
  const [mounted, setMounted] = useState(false);
  const [results, setResults] = useState([]);
  const [neworder, setNewOrder] = useState(false)
  const [doneorder, setDoneOrder] = useState(false)
  const [symbolTab, setSymbolTab] = useState(false)
  const [shrink, setShrink] = useState(false)
  const [iniialShow, setIniialShow] = useState(true);

  const [shrinkcont, setShrinkcont] = useState(false)
  const [bottompop, setBottompop] = useState(false)

  const [orderBook, setOrderBook] = useState({
    "buyOrder": [],
    "sellOrder": [],
  })
  const [trigger_type, settriggerType] = useState()
  const [triggerordertype, settriggerordertype] = useState()
  const [trigger_price, Settrigger_price] = useState()
  // const [orderType, setOrderType] = useState('market')
  // const [buyorsell, Setbuyorsell] = useState('buy')
  const [transaction, Settransaction] = useState([])
  // console.log("transaction_transactiontransaction", transaction);
  const [volume, setVolume] = useState(0)
  const [pAndLarr, setpAndLarr] = useState([])
  const [displayLoader, setDisplayLoader] = useState(false)
  const [ischart, setChart] = useState(false)
  const [filledDetailsPage, setFilledDetailsPage] = useState(0)
  const [filledDetails, setFilledDetails] = useState(0)
  const [loadhistory, setLoadhistory] = useState(true)
  const [loadTransaction, setLoadTransaction] = useState(true)
  const [loadOrder, setLoadOrder] = useState(true)
  const [filledOrder, setFilledOrder] = useState(0)
  const [positionPage, Setpositionpage] = useState(1)
  const [InfopairData, setInfopairData] = useState({})

  // props
  const { handleCount } = props
  const { currentPage, nextPage, limit, count, data } = orderData
  // const { price, quantity, leverage, isProfitLoss, typeTIF, pairname, Epriydate, comment, takeprofit, stopprice } = formValue;

  const [Distance, setDistance] = useState(0)
  const [stopopen, setStopopen] = useState(false);
  const [takeopen, setTakeopen] = useState(false);
  const [Markupprice, setmarkupprice] = useState();
  const [positionsearch, setPositionsearch] = useState("");
  const [alldirection, setAlldirection] = useState("");
  // console.log("alldirection_alldirection_alldirection_alldirection", alldirection);
  const [aggregated, setAggregated] = useState('Not Aggregated');
  const [ordersearch, setOrdersearch] = useState("");
  const [historysearch, setHistorysearch] = useState("");
  const [historyday, setHistoryday] = useState("");
  const [historystart, SetHistorystart] = useState("");
  const [historyend, setHistoryend] = useState("");
  const [ishistoryday, setIshistoryday] = useState(true);
  const [ishistorydate, setIshistorydate] = useState(false);
  const [transactionsearch, setTransactionsearch] = useState("");
  const [transactiontype, setTransactiontype] = useState("");
  const [btransaction, setBtransaction] = useState([]);
  const [isExpriy, setExpriy] = useState(false)
  const [LimitDisable, SetLimitDisabel] = useState(false)
  const [StopDisable, SetStopDisable] = useState(false)
  // const [Limit_Range, setLimitRange] = useState(0)
  const [CancelSell, setCancelSell] = useState(false)
  const [CancelBuy, setCancelBuy] = useState(false)
  const [CloseSell, setCloseSell] = useState(false)
  const [CloseBuy, setCloseBuy] = useState(false)
  const [startDate, setStartDate] = useState(new Date());
  const [filterdatas, setdatassss] = useState()
  const [Dates, setdates] = useState()
  const [infoorder, setInfoOrder] = useState(false)
  const [infoorderdata, setInfoData] = useState()
  const [Equtitypre, SetEqutityper] = useState(0)
  const [PandLSingel, SetPandLSingel] = useState()
  const [Margin, setPositionMargin] = useState(0)
  const [freemagins, SetFreemargin] = useState(0)
  const [marginlevels, Setmarginlevels] = useState(0)
  const [profitandloss, SetProLoss] = useState(0)
  const [errors, setError] = useState({})
  const [positionCount, setpositionCount] = useState(0)
  const [pipValue, setpipValue] = useState(0)
  const [Est_Margin, setEmargin] = useState(0)
  const [tpPip, settpPip] = useState(0)
  const [spPip, setspPip] = useState(0)
  const [tpUsd, settpUsd] = useState(0)
  const [spUsd, setspUsd] = useState(0)
  const [posconFirm, setposconFirm] = useState(false)
  const [conFirm, setconFirm] = useState(false)
  const [errorShow, setErrorShow] = useState(false)
  const [errorres, setErrorres] = useState([])
  const [page, setPage] = useState(1)
  const [transactioncount, setTransactioncount] = useState(0)
  const [doneordercnt, setDoneordercnt] = useState(0)
  const [isTEdit, setTEdit] = useState(false)
  const [isSEdit, setSEdit] = useState(false)
  const [loadmoreloader, setloadmoreloader] = useState(false)
  const [Marginpop, setMarginpop] = useState(false)
  //redux state
  const kycStatus = useSelector(state => state.userKyc.Status);
  const { verifiedstatus, Synapsverify } = kycStatus
  console.log(kycStatus, "kycStatus_verifiedstatus")
  const walletData = useSelector(state => state.wallet);
  const { useraccount, userId, accounts, viewedpair, emailStatus } = useSelector(state => state.account)
  console.log("accountSpottrade", emailStatus)
  const currencyDoc = useSelector(state => state.currency)
  const pairList = useSelector(state => state.pairList);
  const { firstCurrency, secondCurrency } = useSelector(state => state.tradeAsset);
  const { isAuth } = useSelector(state => state.auth);
  const pairData = useSelector(state => state.tradePair)
  const pairsData = useRef(pairData)
  const formsValue = useRef(formValue)
  const SpPip = useRef(spPip)
  const TpPip = useRef(tpPip)
  const Stopopen = useRef(stopopen)
  const Takeopen = useRef(takeopen)
  const TpUsd = useRef(tpUsd)
  const SpUsd = useRef(spUsd)
  const pairsList = useRef(pairList)
  const positionsorder = useRef(positionorder)
  const [closedpage, setClosedpage] = useState(1)
  const [transactionpage, settransactionpage] = useState(1)
  const [confirmLoader,setconfirmLoader] = useState(false)

  const { price, quantity, leverage, buyorsell, isProfitLoss, typeTIF, pairname, Epriydate, comment, takeprofit, stopprice, orderType, Limit_range_pips, Limit_range_price } = formValue;

  // console.log(useraccount,'formsValue',accounts)
  useEffect(() => {
    setBottompop(JSON.parse(window.sessionStorage.getItem("bottompop")));
    setShrinkcont(JSON.parse(window.sessionStorage.getItem("shrinkcont")));

  }, []);

  useEffect(() => {
    window.sessionStorage.setItem("bottompop", bottompop);
    window.sessionStorage.setItem("shrinkcont", shrinkcont);
  }, [bottompop, shrinkcont]);


  const setWhole = (value) => {
    // 👇️ take parameter passed from Child component
    setShrink(value)
  }

  const type = localStorage.getItem("liveorDemo")


  const onBreakpointChange = (breakpoint) => {
    setCurrentBreakpoint(breakpoint);
  };

  const disableCheckDiv = (e) => {
    if (e.target.getAttribute("id") == "check-api-radio") {
      document.getElementById("diable_check_id_2").classList.add("disable_chk");
      document.getElementById("diable_check_id_1").classList.remove("disable_chk");
      document.getElementById("check-api-radio1").classList.add("disable_chk");
      document.getElementById("check-api-radio").classList.remove("disable_chk");
    }
    else if (e.target.getAttribute("id") == "check-api-radio1") {
      document.getElementById("diable_check_id_1").classList.add("disable_chk");
      document.getElementById("diable_check_id_2").classList.remove("disable_chk");
      document.getElementById("check-api-radio").classList.add("disable_chk");
      document.getElementById("check-api-radio1").classList.remove("disable_chk");
    }
  }

  const position = <>Position <span className="badge badge-secondary px-2">{positionCount && positionCount}</span></>
  const orders = <>Orders <span className="badge badge-secondary px-2">{orderHistoryCnt}</span></>
  const priceallert = <>Price alert <span className="badge badge-secondary px-2">0</span></>


  const percentagePnL = (e) => {
    try {
      var type = e.target.id
      var percent = e.target.value;
      if (type == "takeprofit" && takeopen) {
        if (buyorsell == 'buy') {
          //this.setState({profitpercentage:percent})
          let profit = parseFloat(Markupprice.ask) * (parseFloat(percent) / 100);
          let takeprofit = parseFloat(Markupprice.ask) + parseFloat(profit);
          //console.log("percentagePnL", takeprofit)
          // setTakeprofit(takeprofit)
        }
        else {
          //this.setState({profitpercentage:percent})
          let profit = parseFloat(Markupprice.bid) * (parseFloat(percent) / 100);
          let takeprofit = parseFloat(Markupprice.bid) - parseFloat(profit);
          //console.log("percentagePnL", takeprofit)
          // setTakeprofit(takeprofit)
        }
      }
      else if (type == "stopprice" && stopopen) {
        if (buyorsell == 'buy') {
          //this.setState({stoplosspercentage : percent})
          let loss = parseFloat(Markupprice.ask) * (parseFloat(percent) / 100);
          let stopprice = parseFloat(Markupprice.ask) - parseFloat(loss);
          //console.log("percentagePnL", stopprice)
          // setstopprice(stopprice)
        }
        else {
          //this.setState({stoplosspercentage : percent})
          let loss = parseFloat(Markupprice.bid) * (parseFloat(percent) / 100);
          let stopprice = parseFloat(Markupprice.bid) + parseFloat(loss);
          //console.log("percentagePnL", stopprice)
          // setstopprice(stopprice)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  //handle change functions
  const handleClick = () => {
    history.push('/login');
  }

  const increaseVolume = async (volume) => {
    try {
      let value
      if (volume > pairData.maxQuantity) {
        setError({ ...errors, ...{ 'quantity': `Volume shouldn't be greater than ${pairData.maxQuantity}` } })
        setVolume(volume)
        return
      }
      else if (volume >= 10) {
        value = volume + 10
        setVolume(value)
      } else if (volume >= 1) {
        value = volume + 1
        setVolume(value)
      }
      else if (volume >= 0.1) {
        value = (volume + 0.1).toFixed(1)
        setVolume(parseFloat(value))
      }
      else {
        value = (volume + 0.01).toFixed(2)
        setVolume(parseFloat(value))
      }
      await calculateQuantity(value)
    } catch (err) {
      console.log(err)
    }
  }

  const decreaseVolume = async (volume) => {
    try {
      let value
      if (volume < pairData.minQuantity) {
        value = 0
        setError({ ...errors, ...{ 'quantity': `Volume shouldn't be less than ${pairData.minQuantity}` } })
        setVolume(value)
        return
      }
      else if (volume > 10) {
        value = volume - 10
        console.log(volume, 'decreaseVolume')
        setVolume(value)
      }
      else if (volume > 1) {
        value = volume - 1
        console.log(volume, 'decreaseVolume')
        setVolume(value)
      }
      else if (volume > 0.1) {
        value = (volume - 0.1).toFixed(1)
        setVolume(parseFloat(value))
      }
      else {
        value = (volume - 0.01).toFixed(2)
        setVolume(parseFloat(value))
      }
      await calculateQuantity(value)
    } catch (err) {
      console.log(err)
    }
  }

  const handleChange = async (e) => {
    try {
      e.preventDefault();
      const { name, value } = e.target;
      let regex = /[ A-Za-z`!@#$%^&*()_+=\[\]{};':"\\|,<>\/?~]/
      // let regex = /^[0-9]+$/  ^-?\d+(?:\.\d+)?$
      // console.log(!['typeTIF', 'leverage', 'tpPip', 'spPip', 'tpUsd', 'spUsd','Limit_Range'].includes(name) && !/^\d*\.?\d*$/.test(value),'handleChange-check')
      // if (!['typeTIF', 'leverage', 'tpPip', 'spPip', 'tpUsd', 'spUsd','Limit_Range'].includes(name) && !/^\d*\.?\d*$/.test(value)) {
      //   return
      // }
      console.log(!['typeTIF', 'leverage'].includes(name) && regex.test(value), value?.split('.')?.length > 2, value?.split('-')?.length > 2, (value?.split('-')?.length >= 2 && !isEmpty(value?.split('-')[0])), 'handleChange-check')
      if (!['typeTIF', 'leverage'].includes(name) && regex.test(value) || value?.split('.')?.length > 2 || value?.split('-')?.length > 2 || (value?.split('-')?.length >= 2 && !isEmpty(value?.split('-')[0]))) {
        return
      }
      if (name === 'tpPip') {
        let interval
        if (pairData.secondCurrencySymbol == 'JPY') {
          interval = (1 / Math.pow(10, 5)) * Math.pow(10, 4)
        } else {
          interval = (1 / Math.pow(10, pairData.precision)) * Math.pow(10, pairData.pip_position)
        }
        // let tppip = parseFloat(value).toFixed(2)
        // tppip = (isNaN(tppip) || tppip==undefined || tppip ==null) ? 0 : tppip

        // else if(precision == 0){
        //   setError({ ...errors, ...{ [name]: '' } })
        // }
        let tpusd = (parseFloat(value) * pipValue).toFixed(2)
        tpusd = (isNaN(tpusd) || tpusd == undefined || tpusd == null) ? 0 : tpusd
        let takeprofit = (parseFloat(price) + (parseFloat(value) / Math.pow(10, pairData.pip_position))).toFixed(pairData.precision)
        settpPip(value)
        settpUsd(parseFloat(tpusd))
        let precision = (Math.abs(value) * Math.pow(10, pairData.pip_position == 0 ? 4 : pairData.pip_position)) % (interval * Math.pow(10, pairData.pip_position == 0 ? 4 : pairData.pip_position))
        console.log(precision, 'handleChange-tpPip')
        if (precision != 0) {
          setError({ ...errors, ...{ [name]: `Invalid precision : correct precision is equal to ${interval}` } })
          return false
        }
        calculateTpandSp(parseFloat(takeprofit), 'takeprofit')
        return true
      }
      if (name == 'tpUsd') {
        let pipInterval
        if (pairData.secondCurrencySymbol == 'JPY') {
          pipInterval = (1 / Math.pow(10, 5)) * Math.pow(10, 4)
        } else {
          pipInterval = (1 / Math.pow(10, pairData.precision)) * Math.pow(10, pairData.pip_position)
        }
        let toFixedlength = pipInterval.toString().split('.')[1] ? pipInterval.toString().split('.')[1].length : 0
        // else{
        //   setError({ ...errors, ...{ [name]: `` } })
        // }
        settpUsd(value)
        let tppip = (parseFloat(value) / pipValue).toFixed(2)
        let takeprofit = (parseFloat(price) + (parseFloat(tppip) / Math.pow(10, pairData.pip_position))).toFixed(pairData.precision)
        tppip = (isNaN(tppip) || tppip == undefined || tppip == null) ? 0 : parseFloat(tppip).toFixed(toFixedlength)
        settpPip(parseFloat(tppip))
        console.log('calculateTpandSp', takeprofit)
        let precision = (Math.abs(value) * Math.pow(10, pairData.pip_position == 0 ? 4 : pairData.pip_position)) % (0.01 * Math.pow(10, pairData.pip_position == 0 ? 4 : pairData.pip_position))
        if (precision != 0) {
          setError({ ...errors, ...{ [name]: `Invalid precision : correct precision is equal to ${0.01}` } })
          return false
        }
        calculateTpandSp(parseFloat(takeprofit), 'takeprofit')
        return true
      }
      if (name === 'spPip') {
        let interval
        if (pairData.secondCurrencySymbol == 'JPY') {
          interval = (1 / Math.pow(10, 5)) * Math.pow(10, 4)
        } else {
          interval = (1 / Math.pow(10, pairData.precision)) * Math.pow(10, pairData.pip_position)
        }
        let precision = (Math.abs(value) * Math.pow(10, pairData.pip_position == 0 ? 4 : pairData.pip_position)) % (interval * Math.pow(10, pairData.pip_position == 0 ? 4 : pairData.pip_position))


        // else if(precision == 0){
        //   let errorData = { ...errors, ...{ 'spPip': '' } }
        //   console.log(precision,'precision1',precision == 0,errorData)
        //   setError(errorData)
        // }
        let spusd = (parseFloat(value) * pipValue).toFixed(2)
        spusd = (isNaN(spusd) || spusd == undefined || spusd == null) ? 0 : spusd
        console.log(name, parseFloat(value) * pipValue, name === 'tpPip', 'handleChange', spusd)
        let stopprice = (parseFloat(price) + (parseFloat(value) / Math.pow(10, pairData.pip_position))).toFixed(pairData.precision)
        setspPip(value)
        setspUsd(parseFloat(spusd))
        if (precision !== 0) {
          console.log(precision, 'precision', precision !== 0)
          setError({ ...errors, ...{ [name]: `Invalid precision : correct precision is equal to ${interval}` } })
          return false
        }
        calculateTpandSp(parseFloat(stopprice), 'stopprice')
        return true
      }
      if (name == 'spUsd') {
        let pipInterval
        if (pairData.secondCurrencySymbol == 'JPY') {
          pipInterval = (1 / Math.pow(10, 5)) * Math.pow(10, 4)
        } else {
          pipInterval = (1 / Math.pow(10, pairData.precision)) * Math.pow(10, pairData.pip_position)
        }
        let toFixedlength = pipInterval.toString().split('.')[1] ? pipInterval.toString().split('.')[1].length : 0
        setspUsd(value)
        let sppip = (parseFloat(value) / pipValue).toFixed(2)
        let stopprice = (parseFloat(price) + (parseFloat(sppip) / Math.pow(10, pairData.pip_position))).toFixed(pairData.precision)
        sppip = (isNaN(sppip) || sppip == undefined || sppip == null) ? 0 : parseFloat(sppip).toFixed(toFixedlength)
        setspPip(parseFloat(sppip))
        let precision = (Math.abs(value) * Math.pow(10, pairData.pip_position == 0 ? 4 : pairData.pip_position)) % (0.01 * Math.pow(10, pairData.pip_position == 0 ? 4 : pairData.pip_position))
        if (precision != 0) {
          setError({ ...errors, ...{ [name]: `Invalid precision : correct precision is equal to ${0.01}` } })
          return false
        }
        console.log(name, value, stopprice, sppip, 'handleChange')
        calculateTpandSp(parseFloat(stopprice), 'stopprice')
        return true
      }
      if (name === 'takeprofit') {
        // setTEdit(true)
        let val = value
        // if(isNaN(value)||value==''||value==undefined){
        //   val =0
        //   let formData = { ...formValue, ...{ 'takeprofit': val } }
        //   setFormValue(formData)
        //   console.log(formData, 'value,type')
        //   setError({ ...errors, ...{ 'takeprofit': `The value must be between 0 to ${price}`} })
        // }else{
        let pipInterval
        if (pairData.secondCurrencySymbol == 'JPY') {
          pipInterval = (1 / Math.pow(10, 5)) * Math.pow(10, 4)
        } else {
          pipInterval = (1 / Math.pow(10, pairData.precision)) * Math.pow(10, pairData.pip_position)
        }
        let toFixedlength = pipInterval.toString().split('.')[1] ? pipInterval.toString().split('.')[1].length : 0
        let pip = ((value - price) * Math.pow(10, pairData.pip_position))
        pip = (isNaN(pip) || pip == undefined || pip == null) ? 0 : parseFloat(pip).toFixed(toFixedlength)
        let usd = pip * pipValue
        usd = (isNaN(usd) || usd == undefined || usd == null) ? 0 : parseFloat(usd).toFixed(2)
        settpPip(parseFloat(pip))
        settpUsd(parseFloat(usd))
        return calculateTpandSp(val, name)
        // }
      }
      if (name === 'stopprice') {
        // setSEdit(true)
        let val = value
        // if(isNaN(value)||value==''||value==undefined){
        //   val =0
        //   let formData = { ...formValue, ...{ 'stopprice': val } }
        //   setFormValue(formData)
        //   console.log(formData, 'value,type')
        //   setError({ ...errors, ...{ 'stopprice': `The value must be between 0 to ${price}`} })
        // }else{
        let pipInterval
        if (pairData.secondCurrencySymbol == 'JPY') {
          pipInterval = (1 / Math.pow(10, 5)) * Math.pow(10, 4)
        } else {
          pipInterval = (1 / Math.pow(10, pairData.precision)) * Math.pow(10, pairData.pip_position)
        }
        let toFixedlength = pipInterval.toString().split('.')[1] ? pipInterval.toString().split('.')[1].length : 0
        let pip = ((value - price) * Math.pow(10, pairData.pip_position))
        pip = (isNaN(pip) || pip == undefined || pip == null) ? 0 : parseFloat(pip).toFixed(toFixedlength)
        let usd = pip * pipValue
        usd = (isNaN(usd) || usd == undefined || usd == null) ? 0 : parseFloat(usd).toFixed(2)
        setspPip(pip)
        setspUsd(parseFloat(usd))
        return calculateTpandSp(val, name)
        // }
        // return calculateTpandSp(val, name)
      }
      if (name == 'Limit_Range') {
        let interval
        if (pairData.secondCurrencySymbol == 'JPY') {
          interval = (1 / Math.pow(10, 5)) * Math.pow(10, 4)
        } else {
          interval = (1 / Math.pow(10, pairData.precision)) * Math.pow(10, pairData.pip_position)
        }
        let precision = (Math.abs(value) * Math.pow(10, pairData.pip_position)) % (interval * Math.pow(10, pairData.pip_position))
        let Limit_range_price
        if (buyorsell == 'buy') {
          Limit_range_price = (price + (value / Math.pow(10, pairData.pip_position)))
          Limit_range_price = parseFloat(Limit_range_price).toFixed(pairData.precision)
        } else {
          Limit_range_price = (price - (value / Math.pow(10, pairData.pip_position)))
          Limit_range_price = parseFloat(Limit_range_price).toFixed(pairData.precision)
        }

        if (precision !== 0) {
          console.log(precision, 'precision', precision !== 0)
          setError({ ...errors, ...{ ['Limit_Range']: `Invalid precision : correct precision is equal to ${interval}` } })
          let formData = { ...formValue, ...{ 'Limit_range_pips': value, 'Limit_range_price': parseFloat(Limit_range_price) } }
          setFormValue(formData)
          return false
        } else {

          setError({ ...errors, ...{ ['Limit_Range']: `` } })
          let formData = { ...formValue, ...{ 'Limit_range_pips': value, 'Limit_range_price': parseFloat(Limit_range_price) } }
          console.log(formData, 'Limit_Range', Limit_range_price)
          setFormValue(formData)
          return true
        }
        // let formData = { ...formValue, ...{ 'Limit_range_pips': value } }
        // setFormValue(formData)
        // return true
      }
      if (name == 'price') {
        let interval = (1 / Math.pow(10, pairData.precision)).toString().split('.')[1] ? (1 / Math.pow(10, pairData.precision)).toString().split('.')[1] : 0
        let precision = value.split('.')[1] ? value.split('.')[1] : 0
        console.log(precision.length, interval.length, 'Invalid precision')
        if (precision?.length > interval?.length) {
          setError({ ...errors, [name]: `Invalid precision ${interval}` })
        } else {
          setError({ ...errors, [name]: `` })
        }
        PriceValidation(value, Markupprice)
        let formData = { ...formValue, ...{ [name]: value } }
        setFormValue(formData)
        let valPip = value * Math.pow(10, pairData.pip_position)
        var buyprice = !isEmpty(Markupprice) && Markupprice?.ask ? Markupprice.ask * Math.pow(10, pairData.pip_position) : pairData?.ask * Math.pow(10, pairData.pip_position)
        var sellprice = !isEmpty(Markupprice) && Markupprice?.bid ? Markupprice.bid * Math.pow(10, pairData.pip_position) : pairData?.bid * Math.pow(10, pairData.pip_position)
        let price = buyorsell == 'buy' ? buyprice : sellprice
        localStorage.setItem('price', value)
        let Distance = Math.abs(parseFloat(price) - parseFloat(valPip)).toFixed(1)
        setDistance(isNaN(Distance) ? 0 : Distance)

        // if(takeopen){
        //   let takeprofit = (parseFloat(value) + (parseFloat(tpPip) / Math.pow(10, pairData.pip_position))).toFixed(pairData.precision)
        //   calculateTpandSp(parseFloat(takeprofit), 'takeprofit')
        // }
        // if(stopopen){
        //   let stopprice = (parseFloat(value) + (parseFloat(spPip) / Math.pow(10, pairData.pip_position))).toFixed(pairData.precision)
        //   calculateTpandSp(parseFloat(stopprice), 'stopprice')
        // }
        // let formData = { ...formsValue.current, ...{ [name]: value } }
        // setFormValue(formData)
        console.log(formData, 'handleChange__price')
        return true
      }
      if (name == 'quantity') {
        let precision = (Math.abs(value) * Math.pow(10, pairData.pip_position == 0 ? 4 : pairData.pip_position)) % (pairData.minQuantity * Math.pow(10, pairData.pip_position == 0 ? 4 : pairData.pip_position))
        if (precision != 0) {
          setError({ ...errors, ...{ [name]: `Invalid precision : correct precision is equal to ${pairData.minQuantity}` } })
          setVolume(value)
          return false
        }
        if (value < pairData.minQuantity || isNaN(value)) {
          console.log(value < pairData.minQuantity, 'value < pairData.minQuantity')
          setError({ ...errors, ...{ [name]: `Volume shouldn't be less than ${pairData.minQuantity}` } })
          setVolume(value)
          return false
        } else if (value > pairData.maxQuantity || isNaN(value)) {
          console.log(value > pairData.maxQuantity, 'value > pairData.maxQuantity')
          setError({ ...errors, ...{ [name]: `Volume shouldn't be greater than ${pairData.maxQuantity}` } })
          setVolume(value)
          return false
        }
        else {
          setVolume(value)
          await calculateQuantity(value)
          return true
        }
      }
      // let formData = { ...formValue, ...{ [name]: value } }

      // setFormValue(formData)
      // calculateOrderCost(formData.price, formData.quantity, formData.leverage, formData)
      setError({ ...errors, ...{ [name]: "" } })

    } catch (err) {
      console.log(err, "Error-handleChnage")
    }
  }

  const increaseTpPrice = (type) => {
    try {
      let value
      if (type === 'stopprice') {
        value = parseFloat(stopprice) + parseFloat(1 / Math.pow(10, pairData.precision))

        let pipInterval
        if (pairData.secondCurrencySymbol == 'JPY') {
          pipInterval = (1 / Math.pow(10, 5)) * Math.pow(10, 4)
        } else {
          pipInterval = (1 / Math.pow(10, pairData.precision)) * Math.pow(10, pairData.pip_position)
        }
        let toFixedlength = pipInterval.toString().split('.')[1] ? pipInterval.toString().split('.')[1].length : 0
        let pip = ((value - price) * Math.pow(10, pairData.pip_position))
        pip = (isNaN(pip) || pip == undefined || pip == null) ? 0 : parseFloat(pip).toFixed(toFixedlength)
        let usd = pip * pipValue
        usd = (isNaN(usd) || usd == undefined || usd == null) ? 0 : parseFloat(usd).toFixed(2)
        setspPip(pip)
        setspUsd(parseFloat(usd))

        if (buyorsell == 'buy' && value > price) {
          setError({ ...errors, ...{ 'stopprice': 'Stop Loss price must not greater than price' } })
        } else {
          setError({ ...errors, ...{ 'stopprice': '' } })
        }
        setFormValue({ ...formValue, ...{ 'stopprice': isNaN(value) ? price : value.toFixed(pairData.precision) } })
      } else if (type === 'takeprofit') {
        value = parseFloat(takeprofit) + parseFloat(1 / Math.pow(10, pairData.precision))

        let pipInterval
        if (pairData.secondCurrencySymbol == 'JPY') {
          pipInterval = (1 / Math.pow(10, 5)) * Math.pow(10, 4)
        } else {
          pipInterval = (1 / Math.pow(10, pairData.precision)) * Math.pow(10, pairData.pip_position)
        }
        let toFixedlength = pipInterval.toString().split('.')[1] ? pipInterval.toString().split('.')[1].length : 0
        let pip = ((value - price) * Math.pow(10, pairData.pip_position))
        pip = (isNaN(pip) || pip == undefined || pip == null) ? 0 : parseFloat(pip).toFixed(toFixedlength)
        let usd = pip * pipValue
        usd = (isNaN(usd) || usd == undefined || usd == null) ? 0 : parseFloat(usd).toFixed(2)
        settpPip(parseFloat(pip))
        settpUsd(parseFloat(usd))

        if (buyorsell == 'sell' && value > price) {
          setError({ ...errors, ...{ 'takeprofit': 'Take profit price must not be greater than price' } })
        } else {
          setError({ ...errors, ...{ 'takeprofit': '' } })
        }
        setFormValue({ ...formValue, ...{ 'takeprofit': isNaN(value) ? price : value.toFixed(pairData.precision) } })
      }

    } catch (err) {
      console.log(err)
    }
  }

  const decreaseTpPrice = (type) => {
    try {
      let value
      if (type === 'stopprice') {
        value = parseFloat(stopprice) - parseFloat(1 / Math.pow(10, pairData.precision))

        let pipInterval
        if (pairData.secondCurrencySymbol == 'JPY') {
          pipInterval = (1 / Math.pow(10, 5)) * Math.pow(10, 4)
        } else {
          pipInterval = (1 / Math.pow(10, pairData.precision)) * Math.pow(10, pairData.pip_position)
        }
        let toFixedlength = pipInterval.toString().split('.')[1] ? pipInterval.toString().split('.')[1].length : 0
        let pip = ((value - price) * Math.pow(10, pairData.pip_position))
        pip = (isNaN(pip) || pip == undefined || pip == null) ? 0 : parseFloat(pip).toFixed(toFixedlength)
        let usd = pip * pipValue
        usd = (isNaN(usd) || usd == undefined || usd == null) ? 0 : parseFloat(usd).toFixed(2)
        setspPip(pip)
        setspUsd(parseFloat(usd))

        if (buyorsell == 'sell' && value < price) {
          setError({ ...errors, ...{ 'stopprice': 'Stop Loss price must not be less than price' } })
        } else {
          setError({ ...errors, ...{ 'stopprice': '' } })
        }
        setFormValue({ ...formValue, ...{ 'stopprice': isNaN(value) ? price : value.toFixed(pairData.precision) } })
      } else if (type === 'takeprofit') {
        value = parseFloat(takeprofit) - parseFloat(1 / Math.pow(10, pairData.precision))

        let pipInterval
        if (pairData.secondCurrencySymbol == 'JPY') {
          pipInterval = (1 / Math.pow(10, 5)) * Math.pow(10, 4)
        } else {
          pipInterval = (1 / Math.pow(10, pairData.precision)) * Math.pow(10, pairData.pip_position)
        }
        let toFixedlength = pipInterval.toString().split('.')[1] ? pipInterval.toString().split('.')[1].length : 0
        let pip = ((value - price) * Math.pow(10, pairData.pip_position))
        pip = (isNaN(pip) || pip == undefined || pip == null) ? 0 : parseFloat(pip).toFixed(toFixedlength)
        let usd = pip * pipValue
        usd = (isNaN(usd) || usd == undefined || usd == null) ? 0 : parseFloat(usd).toFixed(2)
        settpPip(parseFloat(pip))
        settpUsd(parseFloat(usd))

        if (buyorsell == 'buy' && value < price) {
          setError({ ...errors, ...{ 'takeprofit': 'Take profit price must not be less than price' } })
        } else {
          setError({ ...errors, ...{ 'takeprofit': '' } })
        }
        setFormValue({ ...formValue, ...{ 'takeprofit': isNaN(value) ? price : value.toFixed(pairData.precision) } })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const increasetpPip = async (type) => {
    try {
      let value
      let AddValue
      let interval
      if (pairData.secondCurrencySymbol == 'JPY') {
        interval = (1 / Math.pow(10, 5)) * Math.pow(10, 4)
      } else {
        interval = (1 / Math.pow(10, pairData.precision)) * Math.pow(10, pairData.pip_position)
      }
      if (type == 'stopprice') {
        if (buyorsell == 'sell') {
          value = (spPip + interval).toFixed(2)
          value = (isNaN(value) || value == undefined || value == null) ? 0 : value
          setspPip(parseFloat(value))
          AddValue = interval / Math.pow(10, pairData.pip_position)
          let Stopprice = (parseFloat(stopprice) + AddValue).toFixed(pairData.precision)
          let spusd = (parseFloat(value) * pipValue).toFixed(2)
          spusd = (isNaN(spusd) || spusd == undefined || spusd == null) ? 0 : spusd
          setspUsd(parseFloat(spusd))
          await calculateTpandSp(parseFloat(Stopprice), type)
        }
        else if (buyorsell == 'buy' && spPip < 0) {
          value = (spPip + interval).toFixed(2)
          value = (isNaN(value) || value == undefined || value == null) ? 0 : value
          setspPip(parseFloat(value))
          AddValue = interval / Math.pow(10, pairData.pip_position)
          console.log('formData--->', stopprice + AddValue, stopprice, AddValue)
          let Stopprice = (parseFloat(stopprice) + AddValue).toFixed(pairData.precision)
          let spusd = (parseFloat(value) * pipValue).toFixed(2)
          spusd = (isNaN(spusd) || spusd == undefined || spusd == null) ? 0 : spusd
          setspUsd(parseFloat(spusd))
          await calculateTpandSp(parseFloat(Stopprice), type)
        }
      }
      else if (type == 'takeprofit') {
        if (buyorsell == 'buy') {
          value = (tpPip + interval).toFixed(2)
          value = (isNaN(value) || value == undefined || value == null) ? 0 : value
          settpPip(parseFloat(value))
          AddValue = interval / Math.pow(10, pairData.pip_position)
          let Takeprofit = (parseFloat(takeprofit) + AddValue).toFixed(pairData.precision)
          let tpusd = (parseFloat(value) * pipValue).toFixed(2)
          tpusd = (isNaN(tpusd) || tpusd == undefined || tpusd == null) ? 0 : tpusd
          settpUsd(parseFloat(tpusd))
          await calculateTpandSp(parseFloat(Takeprofit), type)
        }
        else if (buyorsell == 'sell' && tpPip < 0) {
          value = (tpPip + interval).toFixed(2)
          value = (isNaN(value) || value == undefined || value == null) ? 0 : value
          settpPip(parseFloat(value))
          AddValue = interval / Math.pow(10, pairData.pip_position)
          let Takeprofit = (parseFloat(takeprofit) + AddValue).toFixed(pairData.precision)
          let tpusd = (parseFloat(value) * pipValue).toFixed(2)
          tpusd = (isNaN(tpusd) || tpusd == undefined || tpusd == null) ? 0 : tpusd
          settpUsd(parseFloat(tpusd))
          await calculateTpandSp(parseFloat(Takeprofit), type)
        }
      }
    } catch (err) {
      console.log(err, 'increasePipvalue--err')
    }
  }

  const decreasetpPip = async (type) => {
    try {
      let value
      let subValue
      let interval
      if (pairData.secondCurrencySymbol == 'JPY') {
        interval = (1 / Math.pow(10, 5)) * Math.pow(10, 4)
      } else {
        interval = (1 / Math.pow(10, pairData.precision)) * Math.pow(10, pairData.pip_position)
      }
      if (type == 'stopprice') {
        if (buyorsell == 'buy') {
          value = (spPip - interval).toFixed(2)
          value = (isNaN(value) || value == undefined || value == null) ? 0 : value
          setspPip(parseFloat(value))
          subValue = interval / Math.pow(10, pairData.pip_position)
          let Stopprice = (parseFloat(stopprice) - subValue).toFixed(pairData.precision)
          let spusd = (value * pipValue).toFixed(2)
          spusd = (isNaN(spusd) || spusd == undefined || spusd == null) ? 0 : spusd
          setspUsd(parseFloat(spusd))
          await calculateTpandSp(parseFloat(Stopprice), type)
        }
        else if (buyorsell == 'sell' && spPip > 0) {
          value = (spPip - interval).toFixed(2)
          value = (isNaN(value) || value == undefined || value == null) ? 0 : value
          setspPip(parseFloat(value))
          subValue = interval / Math.pow(10, pairData.pip_position)
          let Stopprice = (parseFloat(stopprice) - subValue).toFixed(pairData.precision)
          let spusd = (value * pipValue).toFixed(2)
          spusd = (isNaN(spusd) || spusd == undefined || spusd == null) ? 0 : spusd
          setspUsd(parseFloat(spusd))
          await calculateTpandSp(parseFloat(Stopprice), type)
        }
      }
      else if (type == 'takeprofit') {
        if (buyorsell == 'sell') {
          value = (tpPip - interval).toFixed(2)
          value = (isNaN(value) || value == undefined || value == null) ? 0 : value
          settpPip(parseFloat(value))
          subValue = interval / Math.pow(10, pairData.pip_position)
          let Takeprofit = (parseFloat(takeprofit) - subValue).toFixed(pairData.precision)
          let tpusd = (value * pipValue).toFixed(2)
          tpusd = (isNaN(tpusd) || tpusd == undefined || tpusd == null) ? 0 : tpusd
          settpUsd(parseFloat(tpusd))
          await calculateTpandSp(parseFloat(Takeprofit), type)
        }
        else if (buyorsell == 'buy' && tpPip > 0) {
          value = (tpPip - interval).toFixed(2)
          value = (isNaN(value) || value == undefined || value == null) ? 0 : value
          settpPip(parseFloat(value))
          subValue = interval / Math.pow(10, pairData.pip_position)
          let Takeprofit = (parseFloat(takeprofit) - subValue).toFixed(pairData.precision)
          let tpusd = (value * pipValue).toFixed(2)
          tpusd = (isNaN(tpusd) || tpusd == undefined || tpusd == null) ? 0 : tpusd
          settpUsd(parseFloat(tpusd))
          await calculateTpandSp(parseFloat(Takeprofit), type)
        }
      }
    } catch (err) {
      console.log(err, 'decreasetpPip--err')
    }
  }

  const increasetpUsd = async (type) => {
    try {
      let interval = 0.01
      let AddValue
      let value
      let intervalPip
      let pipInterval
      if (pairData.secondCurrencySymbol == 'JPY') {
        pipInterval = (1 / Math.pow(10, 5)) * Math.pow(10, 4)
      } else {
        pipInterval = (1 / Math.pow(10, pairData.precision)) * Math.pow(10, pairData.pip_position)
      }
      if (type == 'stopprice') {
        if (buyorsell == 'sell') {
          value = (parseFloat(spUsd) + interval)
          value = (isNaN(value) || value == undefined || value == null) ? 0 : value
          setspUsd(parseFloat(value).toFixed(2))
          let sppip = (value / pipValue)
          let toFixedlength = pipInterval.toString().split('.')[1] ? pipInterval.toString().split('.')[1].length : 0
          sppip = sppip.toFixed(toFixedlength)
          intervalPip = parseFloat(sppip) - spPip
          sppip = (isNaN(sppip) || sppip == undefined || sppip == null) ? 0 : sppip
          setspPip(parseFloat(sppip))
          AddValue = intervalPip / Math.pow(10, pairData.pip_position)
          let Stopprice = (stopprice + AddValue).toFixed(pairData.precision)
          await calculateTpandSp(parseFloat(Stopprice), type)
        }
        else if (buyorsell == 'buy' && spUsd < 0) {
          value = (parseFloat(spUsd) + interval)
          value = (isNaN(value) || value == undefined || value == null) ? 0 : value
          setspUsd(parseFloat(value).toFixed(2))
          let sppip = (value / pipValue)
          let toFixedlength = pipInterval.toString().split('.')[1] ? pipInterval.toString().split('.')[1].length : 0
          sppip = sppip.toFixed(toFixedlength)
          intervalPip = parseFloat(sppip) - parseFloat(spPip)
          sppip = (isNaN(sppip) || sppip == undefined || sppip == null) ? 0 : sppip
          setspPip(parseFloat(sppip))
          AddValue = intervalPip / Math.pow(10, pairData.pip_position)
          let Stopprice = (stopprice + AddValue).toFixed(pairData.precision)
          await calculateTpandSp(parseFloat(Stopprice), type)
        }
      } else if (type == 'takeprofit') {
        if (buyorsell == 'buy') {
          value = (parseFloat(tpUsd) + interval)
          value = (isNaN(value) || value == undefined || value == null) ? 0 : value
          settpUsd(parseFloat(value).toFixed(2))
          let tppip = (value / pipValue)
          let toFixedlength = pipInterval.toString().split('.')[1] ? pipInterval.toString().split('.')[1].length : 0
          tppip = tppip.toFixed(toFixedlength)
          intervalPip = parseFloat(tppip) - tpPip
          tppip = (isNaN(tppip) || tppip == undefined || tppip == null) ? 0 : tppip
          settpPip(parseFloat(tppip))
          AddValue = intervalPip / Math.pow(10, pairData.pip_position)
          let Takeprofit = (takeprofit + AddValue).toFixed(pairData.precision)
          console.log(Takeprofit, takeprofit, AddValue, interval, 'formData--->')
          await calculateTpandSp(parseFloat(Takeprofit), type)
        }
        else if (buyorsell == 'sell' && tpUsd < 0) {
          value = (parseFloat(tpUsd) + interval)
          value = (isNaN(value) || value == undefined || value == null) ? 0 : value
          settpUsd(parseFloat(value).toFixed(2))
          let tppip = (value / pipValue)
          let toFixedlength = pipInterval.toString().split('.')[1] ? pipInterval.toString().split('.')[1].length : 0
          tppip = tppip.toFixed(toFixedlength)
          intervalPip = parseFloat(tppip) - tpPip
          tppip = (isNaN(tppip) || tppip == undefined || tppip == null) ? 0 : tppip
          settpPip(parseFloat(tppip))
          AddValue = intervalPip / Math.pow(10, pairData.pip_position)
          let Takeprofit = (takeprofit + AddValue).toFixed(pairData.precision)
          await calculateTpandSp(parseFloat(Takeprofit), type)
        }
      }
    } catch (err) {
      console.log(err, 'increasetpUsd--err')
    }
  }

  const decreasetpUsd = async (type) => {
    try {
      let interval = 0.01
      let subValue
      let value
      let intervalPip
      let pipInterval
      if (pairData.secondCurrencySymbol == 'JPY') {
        pipInterval = (1 / Math.pow(10, 5)) * Math.pow(10, 4)
      } else {
        pipInterval = (1 / Math.pow(10, pairData.precision)) * Math.pow(10, pairData.pip_position)
      }
      if (type == 'stopprice') {
        if (buyorsell == 'buy') {
          value = (parseFloat(spUsd) - interval)
          value = (isNaN(value) || value == undefined || value == null) ? 0 : value
          setspUsd(parseFloat(value).toFixed(2))
          let sppip = (value / pipValue)
          let toFixedlength = pipInterval.toString().split('.')[1] ? pipInterval.toString().split('.')[1].length : 0
          sppip = sppip.toFixed(toFixedlength)
          intervalPip = parseFloat(sppip) - spPip
          sppip = (isNaN(sppip) || sppip == undefined || sppip == null) ? 0 : sppip
          setspPip(parseFloat(sppip))
          subValue = intervalPip / Math.pow(10, pairData.pip_position)
          let Stopprice = (stopprice + subValue).toFixed(pairData.precision)
          console.log(Stopprice, stopprice, subValue, intervalPip, value, sppip, 'decreasetpUsd')
          await calculateTpandSp(parseFloat(Stopprice), type)
        }
        else if (buyorsell == 'sell' && spUsd > 0) {
          value = (parseFloat(spUsd) - interval)
          value = (isNaN(value) || value == undefined || value == null) ? 0 : value
          setspUsd(parseFloat(value).toFixed(2))
          let sppip = (value / pipValue)
          let toFixedlength = pipInterval.toString().split('.')[1] ? pipInterval.toString().split('.')[1].length : 0
          sppip = sppip.toFixed(toFixedlength)
          intervalPip = parseFloat(sppip) - spPip
          sppip = (isNaN(sppip) || sppip == undefined || sppip == null) ? 0 : sppip
          setspPip(parseFloat(sppip))
          subValue = intervalPip / Math.pow(10, pairData.pip_position)
          let Stopprice = (stopprice + subValue).toFixed(pairData.precision)
          await calculateTpandSp(parseFloat(Stopprice), type)
        }
      }
      else if (type == 'takeprofit') {
        if (buyorsell == 'sell') {
          value = (parseFloat(tpUsd) - interval)
          value = (isNaN(value) || value == undefined || value == null) ? 0 : value
          settpUsd(parseFloat(value).toFixed(2))
          let tppip = (value / pipValue)
          let toFixedlength = pipInterval.toString().split('.')[1] ? pipInterval.toString().split('.')[1].length : 0
          tppip = tppip.toFixed(toFixedlength)
          tppip = (isNaN(tppip) || tppip == undefined || tppip == null) ? 0 : tppip
          intervalPip = parseFloat(tppip) - tpPip
          settpPip(parseFloat(tppip))
          subValue = intervalPip / Math.pow(10, pairData.pip_position)
          let Takeprofit = (takeprofit - subValue).toFixed(pairData.precision)
          console.log(Takeprofit, takeprofit, subValue, intervalPip, value, tppip, 'decreasetpUsd')
          await calculateTpandSp(parseFloat(Takeprofit), type)
        }
        else if (buyorsell == 'buy' && tpUsd > 0) {
          value = (parseFloat(tpUsd) - interval)
          value = (isNaN(value) || value == undefined || value == null) ? 0 : value
          settpUsd(parseFloat(value).toFixed(2))
          let tppip = (value / pipValue)
          let toFixedlength = pipInterval.toString().split('.')[1] ? pipInterval.toString().split('.')[1].length : 0
          tppip = tppip.toFixed(toFixedlength)
          tppip = (isNaN(tppip) || tppip == undefined || tppip == null) ? 0 : tppip
          intervalPip = parseFloat(tppip) - tpPip
          settpPip(parseFloat(tppip))
          subValue = intervalPip / Math.pow(10, pairData.pip_position)
          let Takeprofit = (takeprofit - subValue).toFixed(pairData.precision)
          await calculateTpandSp(parseFloat(Takeprofit), type)
        }
      }
    } catch (err) {
      console.log(err, 'decreasetpUsd--err')
    }
  }


  //calculation functions
  const calculationDynamic = async (result) => {
    try {
      if (formsValue.current.orderType == 'market') {
        let price = buyorsell == 'buy' ? result.ask : result.bid
        localStorage.setItem('price', price)
        let valPip = parseFloat(price) * Math.pow(10, pairsData.current.pip_position)
        let Price = buyorsell == 'buy' ? result.ask * Math.pow(10, pairsData.current.pip_position) : result.bid * Math.pow(10, pairsData.current.pip_position)
        let Distance = Math.abs(parseFloat(Price) - parseFloat(valPip)).toFixed(1)
        // console.log(formsValue.current, 'calculationDynamic', orderType)
        // let sptoprice
        // let takeprofit
        // if (Stopopen.current) {
        //   sptoprice = price + (SpPip.current / Math.pow(10, pairsData.current.pip_position))
        //   sptoprice = (sptoprice).toFixed(pairsData.current.precision)
        //   await calculateTpandSp(sptoprice, 'stopprice')
        // }
        // if (Takeopen.current) {
        //   takeprofit = price + (TpPip.current / Math.pow(10, pairsData.current.pip_position))
        //   takeprofit = (takeprofit).toFixed(pairsData.current.precision)
        //   await calculateTpandSp(takeprofit, 'takeprofit')
        // }
        // let formData = { ...formsValue.current, ...{ 'price': parseFloat(price), 'stopprice': sptoprice, 'takeprofit': takeprofit } }
        let formData = { ...formsValue.current, ...{ 'price': parseFloat(price) } }
        setFormValue(formData)
        // console.log(Distance, result, valPip, Price, price, 'Distance---->', pairsData.current)
        setDistance(isNaN(parseFloat(Distance)) ? 0 : Distance)
      } else {
        let price = formsValue.current.price
        PriceValidation(price, result)
        // console.log(formsValue, price, 'calculationDynamic1', orderType)
        let valPip = parseFloat(price) * Math.pow(10, pairsData.current.pip_position)
        let Price = buyorsell == 'buy' ? result.ask * Math.pow(10, pairsData.current.pip_position) : result.bid * Math.pow(10, pairsData.current.pip_position)
        let Distance = Math.abs(parseFloat(Price) - parseFloat(valPip)).toFixed(1)
        setDistance(isNaN(parseFloat(Distance)) ? 0 : Distance)
      }
    } catch (err) {
      console.log(err, 'calculationDynamic')
    }
  }

  const calculateTpandSp = (value, type) => {
    console.log(isNaN(value), type, price, 'value,type')
    try {
      if (isNaN(value) || value == '' || value == undefined || value == null) {
        let formData = { ...formValue, ...{ [type]: '' } }
        setFormValue(formData)
        let error = { ...errors, ...{ [type]: `The value must be between 0 to ${price}` } }
        console.log(formData, error, 'value,type')
        setError(error)
      }
      else {
        if (type == "takeprofit" && takeopen) {
          if (buyorsell == 'buy' && value >= price) {
            let formData = { ...formValue, ...{ 'takeprofit': isNaN(value) ? price : value } }
            setFormValue(formData)
            console.log(formData, 'value,type')
            setError({ ...errors, ...{ 'takeprofit': '', 'tpPip': '', 'tpUsd': '' } })
          } else if (buyorsell == 'sell' && value <= price) {
            let formData = { ...formValue, ...{ 'takeprofit': isNaN(value) ? price : value } }
            setFormValue(formData)
            setError({ ...errors, ...{ 'takeprofit': '', 'tpPip': '', 'tpUsd': '' } })
          } else if (buyorsell == 'buy' && value < price) {
            let formData = { ...formValue, ...{ 'takeprofit': isNaN(value) ? price : value } }
            setFormValue(formData)
            setError({ ...errors, ...{ 'takeprofit': 'Take profit price must not be less than price' } })
          }
          else if (buyorsell == 'sell' && value > price) {
            let formData = { ...formValue, ...{ 'takeprofit': isNaN(value) ? price : value } }
            setFormValue(formData)
            setError({ ...errors, ...{ 'takeprofit': 'Take profit price must not be greater than price' } })
          }
        } else if ((type == "stopprice" && stopopen)) {
          if (buyorsell == 'buy' && value <= price) {
            console.log(value, type, 'value,type')
            let formData = { ...formValue, ...{ 'stopprice': isNaN(value) ? price : value } }
            setFormValue(formData)
            setError({ ...errors, ...{ 'stopprice': '', 'spPip': '', 'spUsd': '' } })
          } else if (buyorsell == 'sell' && value >= price) {
            let formData = { ...formValue, ...{ 'stopprice': isNaN(value) ? price : value } }
            setFormValue(formData)
            setError({ ...errors, ...{ 'stopprice': '', 'spPip': '', 'spUsd': '' } })
          } else if (buyorsell == 'buy' && value > price) {
            console.log(value, type, 'value,type')
            let formData = { ...formValue, ...{ 'stopprice': isNaN(value) ? price : value } }
            setFormValue(formData)
            setError({ ...errors, ...{ 'stopprice': 'Stop loss price must not be greater than price' } })
          }
          else if (buyorsell == 'sell' && value < price) {
            let formData = { ...formValue, ...{ 'stopprice': isNaN(value) ? price : value } }
            setFormValue(formData)
            setError({ ...errors, ...{ 'stopprice': 'Stop loss price must not be less than price' } })
          }
        }
      }
    } catch (err) {
      console.log(err, 'calculateTpandSp--err')
    }

  }

  const calculateQuantity = async (value) => {
    try {
      let buyprice = Markupprice?.ask ? Markupprice?.ask : pairData?.ask
      let sellprice = Markupprice?.bid ? Markupprice.bid : pairData?.bid
      let Price = price ? price : buyorsell == 'buy' ? buyprice : sellprice
      let quantity = volumetoquantity(value, pairData, Price)
      let formData = { ...formValue, ...{ 'quantity': quantity.toFixed(2) } }
      setFormValue(formData)
      let Estmargin = await EstMargin(Price, quantity, leverage, pairData, buyorsell, pairList)
      setEmargin(isNaN(Estmargin) ? 0 : parseFloat(Estmargin).toFixed(2))
      await calculatepipValue(quantity, pairData, value)
      console.log('calculateQuantity', Estmargin, price, Price, quantity, leverage)
      setError({ ...errors, ...{ 'quantity': '' } })
      // let formData = { ...formValue, ...{ 'quantity': quantity.toFixed(2) } }
      // setFormValue(formData)
    } catch (err) {
      console.log(err, 'calculateQuantity--err')
    }
  }

  const calculatepipValue = async (quantity, pairData, volume) => {
    try {
      if (pairData.category.toUpperCase() == 'FOREX') {
        if (pairData.secondCurrencySymbol != 'USD' && pairData.firstCurrencySymbol != 'USD') {
          const convertquan = await priceConversion(pairData.firstCurrencySymbol, quantity, buyorsell, pairList)
          quantity = convertquan
          let Price = price ? price : localStorage.getItem('price')
          console.log(Price, price, localStorage.getItem('price'), 'calculatepipValue')
          let pipValue = (1 / Math.pow(10, pairData?.pip_position) * parseFloat(quantity)) / parseFloat(Price)
          console.log(quantity, 'calculatepipValue', pipValue)
          setpipValue(isNaN(pipValue) ? 0 : pipValue)
        }
        else if (pairData.firstCurrencySymbol == 'USD') {
          let pipValue = (1 / Math.pow(10, pairData?.pip_position) * parseFloat(quantity))
          pipValue = await priceConversion(pairData.secondCurrencySymbol, pipValue, buyorsell, pairList)
          console.log(pipValue, 'pipValue')
          setpipValue(isNaN(pipValue) ? 0 : pipValue)
        }
        else {
          let pipValue = (1 / Math.pow(10, pairData?.pip_position) * parseFloat(quantity))
          setpipValue(isNaN(pipValue) ? 0 : pipValue)
        }
      }
      else if (pairData.category.toUpperCase() == 'CRYPTO') {
        let pipValue = (pairData.lotsize * volume).toFixed(2)
        setpipValue(parseFloat(pipValue))
      }
      else {
        let pipValue = (pairData.lotsize * volume).toFixed(2)
        setpipValue(parseFloat(pipValue))
      }
    } catch (err) {
      console.log(err, 'calculatepipValue-err')
    }
  }

  const calculateEstmargin = async (Estmargin, pairData) => {
    try {
      console.log('calculateEstmargin', Estmargin, pipValue)
      if (pairData.secondCurrencySymbol != 'USD') {
        let obj = {
          currecnysymbol: pairData.secondCurrencySymbol,
          buyorsell: buyorsell,
          amount: Estmargin
        }
        const { status, result } = await priceConversion(obj)
        if (status) {
          Estmargin = result
        }
      }
      console.log('calculateEstmargin', Estmargin)
      setEmargin(isNaN(Estmargin) ? 0 : Estmargin?.toFixed(4))
    } catch (err) {
      console.log(err, 'calculateEstmargin--err')
    }
  }


  const positionmargin = (result) => {
    try {
      let prolss = 0
      let prolss1 = 0
      let marginlevel = 0
      let volume = 0
      let leverage = 0
      // let pairName = window.location.pathname.split("/")[2];
      let pairName = localStorage.getItem('curpair')
      // let SingelDetail = pAndLarr && pAndLarr.length > 0 ? pAndLarr.filter((val) => val.pairName == pairName) :
      //   result && result.length > 0 && result?.filter((val) => val.pairName == pairName)

      let SingelDetail = result && result.length > 0 && result?.filter((val) => val.pairName == pairName)

      prolss1 = SingelDetail && SingelDetail.length > 0 && SingelDetail.reduce((total, currentValue) => {
        return total = parseFloat(total) + parseFloat(currentValue.pAndL);
      }, 0)
      // SingelDetail && SingelDetail.length > 0 && SingelDetail.map((item) => {
      //   prolss1 = prolss1 + item.pAndL
      // })
      SetPandLSingel(prolss1)
      // result && result.length > 0 && result.map((item) => {
      //   // prolss = prolss + item.pAndL
      //   volume = volume + item.positionFilled
      //   leverage = leverage + item.leverage
      //   marginlevel = marginlevel + item.tradeFee
      // })
      // prolss = pAndLarr.reduce((total,currentValue) => {
      //   return total = parseFloat(total) + parseFloat(currentValue.pAndL);
      // })
      // let arr = pAndLarr.length > 0 ? pAndLarr : result && result.length > 0 && result
      let arr = result && result.length > 0 && result
      // console.log(prolss, pAndLarr, 'err-->', result, arr)
      prolss = arr?.length > 0 ? arr?.reduce((total, currentValue) => {
        return total = parseFloat(total) + parseFloat(currentValue.pAndL);
      }, 0) : 0
      let marginUsed = arr?.length > 0 ? arr?.reduce((total, currentValue) => {
        // let positionmargin = parseFloat(currentValue.positionFilled) / parseFloat(currentValue.leverage);
        // return total = parseFloat(total) + parseFloat(positionmargin);
        return total = parseFloat(total) + parseFloat(currentValue.margin);
      }, 0) : 0
      volume = arr?.length > 0 ? arr?.reduce((total, currentValue) => {
        return total = parseFloat(total) + parseFloat(currentValue.positionFilled);
      }, 0) : 0
      leverage = arr?.length > 0 ? arr?.reduce((total, currentValue) => {
        return total = parseFloat(total) + parseFloat(currentValue.leverage);
      }, 0) : 0
      marginlevel = arr?.length > 0 ? arr?.reduce((total, currentValue) => {
        return total = parseFloat(total) + parseFloat(currentValue.tradeFee);
      }, 0) : 0
      // pAndLarr && pAndLarr.length > 0 ? pAndLarr.map((val) => {
      //   prolss = prolss + val.pAndL
      // }) : result && result.length > 0 && result.map((item) => {
      //   prolss = prolss + item.pAndL
      // })

      let equity = useraccount?.balance - prolss
      SetEqutityper(equity)
      // let positionmargins = volume / leverage
      setPositionMargin(marginUsed)

      let freemargin = equity - marginUsed
      SetFreemargin(freemargin)
      let marginlevels = (equity / marginUsed).toFixed(2)
      marginlevels = parseFloat(marginlevels) * 100
      let data = {
        equity: equity,
        positionmargins: marginUsed,
        freemargin: freemargin,
        marginlevels: marginlevels
      }
      dispatch(setEquAndmarign(data))
      Setmarginlevels(marginlevels)
      SetProLoss(prolss)
    } catch (err) {
      console.log('err-->', err)
    }
  }
  //Validation functions
  const PriceValidation = async (price, Markupprice) => {
    try {
      let { orderType, buyorsell } = formsValue.current
      let bidPrice = Markupprice?.bid ? Markupprice?.bid : pairData?.bid
      let askPrice = Markupprice?.ask ? Markupprice?.ask : pairData?.ask
      console.log('PriceValidation', bidPrice, askPrice, price, orderType, buyorsell)
      if (buyorsell == 'buy' && orderType == 'limit' && price > askPrice) {
        console.log('PriceValidation', buyorsell == 'buy' && orderType == 'limit' && price > askPrice)
        console.log('PriceValidation', buyorsell == 'buy' && orderType == 'limit' && price > askPrice)
        SetLimitDisabel(true)
      } else if (buyorsell == 'sell' && orderType == 'limit' && price < bidPrice) {
        console.log('PriceValidation', buyorsell == 'sell' && orderType == 'limit' && price < bidPrice)
        SetLimitDisabel(true)
      } else if (buyorsell == 'buy' && (orderType == 'stop' || orderType == 'stop_limit') && price < askPrice) {
        console.log('PriceValidation', buyorsell == 'buy' && (orderType == 'stop' || orderType == 'stop_limit') && price < askPrice)
        SetStopDisable(true)
      } else if (buyorsell == 'sell' && (orderType == 'stop' || orderType == 'stop_limit') && price > bidPrice) {
        console.log('PriceValidation', buyorsell == 'sell' && (orderType == 'stop' || orderType == 'stop_limit') && price > bidPrice)
        SetStopDisable(true)
      }
      else {
        SetLimitDisabel(false)
        SetStopDisable(false)
      }
      // let startInterval = parseFloat(bidPrice) - (20 / Math.pow(10, pairData.pip_position))
      // let EndInterval = parseFloat(askPrice) + (20 / Math.pow(10, pairData.pip_position))
      // if (price >= startInterval && price <= EndInterval) {
      //   console.log('PriceValidation', startInterval,(20 / Math.pow(10, pairData.pip_position)), EndInterval, price >= startInterval || price <= EndInterval)
      //   setError({ ...errors, ...{ 'price': `The value cannot between ${startInterval.toFixed(pairData.precision)} and ${EndInterval.toFixed(pairData.precision)}` } })
      // }
      // else if (buyorsell == 'buy' && orderType == 'limit' && price > startInterval) {
      //   console.log('PriceValidation', buyorsell == 'buy' && orderType == 'limit' && price > startInterval)
      //   SetLimitDisabel(true)
      //   setError({ ...errors, ...{ 'price': `` } })
      //   // setError({...errors,...{'price':`The value cannot between ${startInterval} and ${EndInterval}`}})
      // } else if (buyorsell == 'sell' && orderType == 'limit' && price < EndInterval) {
      //   console.log('PriceValidation', buyorsell == 'sell' && orderType == 'limit' && price < EndInterval)
      //   SetLimitDisabel(true)
      //   setError({ ...errors, ...{ 'price': `` } })
      // } else if (buyorsell == 'buy' && (orderType == 'stop' || orderType == 'stop_limit') && price < EndInterval) {
      //   console.log('PriceValidation', buyorsell == 'buy' && (orderType == 'stop' || orderType == 'stop_limit') && price < EndInterval)
      //   SetStopDisable(true)
      //   setError({ ...errors, ...{ 'price': `` } })
      // } else if (buyorsell == 'sell' && (orderType == 'stop' || orderType == 'stop_limit') && price > startInterval) {
      //   console.log('PriceValidation', buyorsell == 'sell' && (orderType == 'stop' || orderType == 'stop_limit') && price > startInterval)
      //   SetStopDisable(true)
      //   setError({ ...errors, ...{ 'price': `` } })
      // }
      // else {
      //   SetLimitDisabel(false)
      //   SetStopDisable(false)
      // }
    } catch (err) {
      console.log(err, "PriceValidation")
    }
  }
  // const calculateOrderCost = (price, quantity, leverage) => {
  //   if (!isEmpty(price) && !isEmpty(quantity) && !isEmpty(leverage) && !isEmpty(pairData)) {
  //     setFormValue((el) => {
  //       return {
  //         ...el,
  //         'leverage': leverage,
  //         'buyOrderCost': inverseOrderCost({
  //           price,
  //           quantity,
  //           leverage,
  //           takerFee: pairData.taker_fees,
  //           buyorsell: 'buy'
  //         }),
  //         'buyOrderValue': orderValue({
  //           price,
  //           quantity
  //         }),
  //         'sellOrderCost': inverseOrderCost({
  //           price,
  //           quantity,
  //           leverage,
  //           takerFee: pairData.taker_fees,
  //           buyorsell: 'sell'
  //         }),
  //         'sellOrderValue': orderValue({
  //           price,
  //           quantity
  //         }),
  //       }
  //     })
  //   }
  // }

  //order placing functions
  const handleSubmit = async () => {
    try {
      console.log(verifiedstatus, "verifiedstatus_handleSubmit")
      if (verifiedstatus === 'PENDING') {
        toastAlert('error', "You are not able to trade until kyc is approved")
        setLimitLoader(false)
        setMarketLoader(false)
        setstoplimitloader(false)
        setstoploader(false)
        return
      }
      else if (verifiedstatus === 'REJECTED' || verifiedstatus === 'Not verified') {
        toastAlert('error', "Please submit your kyc")
        setLimitLoader(false)
        setMarketLoader(false)
        setstoplimitloader(false)
        setstoploader(false)
        return
      }
      else if (emailStatus === "unverified") {
        toastAlert('error', "Activation link already sent to your email...Please activate your email account.")
        setLimitLoader(false)
        setMarketLoader(false)
        setstoplimitloader(false)
        setstoploader(false)
        return
      }
      if (takeopen && pairData.tradestatus == 'local') {
        if (buyorsell == 'buy' && takeprofit < Markupprice.ask) {
          console.log(buyorsell == 'buy' && takeprofit < Markupprice.ask, "buyorsell == 'buy' && takeprofit < Markupprice.bid")
          toastAlert('error', "Long: Take profit price must be greater than market price")
          setLimitLoader(false)
          setMarketLoader(false)
          setstoplimitloader(false)
          setstoploader(false)
          return
        }
        else if (buyorsell == 'sell' && takeprofit > Markupprice.bid) {
          console.log(buyorsell == 'sell' && takeprofit > Markupprice.bid, "buyorsell == 'sell' && takeprofit > Markupprice.ask")
          toastAlert('error', "Short: Take profit price must be less than market price")
          setLimitLoader(false)
          setMarketLoader(false)
          setstoplimitloader(false)
          setstoploader(false)
          return
        }
      }
      if (stopopen && pairData.tradestatus == 'local') {
        if (buyorsell == 'buy' && stopprice > Markupprice.ask) {
          console.log(buyorsell == 'buy' && stopprice > Markupprice.ask, "buyorsell == 'buy' && stopprice > Markupprice.bid")
          toastAlert('error', "Long: Spot loss price must be less than market price ")
          setLimitLoader(false)
          setMarketLoader(false)
          setstoplimitloader(false)
          setstoploader(false)
          return
        }
        else if (buyorsell == 'sell' && stopprice < Markupprice.bid) {
          console.log(buyorsell == 'sell' && stopprice < Markupprice.bid, "buyorsell == 'sell' && stopprice < Markupprice.ask")
          toastAlert('error', "Short: Spot loss price must be greater than market price ")
          setLimitLoader(false)
          setMarketLoader(false)
          setstoplimitloader(false)
          setstoploader(false)
          return
        }
      }
      console.log('handleSubmit2', buyorsell, orderType, Est_Margin)
      console.log(leverage, 'leverage-->')
      let reqData = {
        price: orderType == 'market' ? buyorsell == 'buy' ?
          Markupprice.ask ? parseFloat(Markupprice.ask) : parseFloat(pairData.ask) :
          Markupprice.bid ? parseFloat(Markupprice.bid) : parseFloat(pairData.bid) : parseFloat(price),
        quantity: parseFloat(quantity),
        volume: parseFloat(volume),
        buyorsell: buyorsell,
        ordertype: (orderType == 'conditional') ? triggerordertype : orderType,
        pairId: pairData.pairId,
        leverage: parseFloat(leverage),
        'timeinforcetype': typeTIF,
        takeProfitPrice: parseFloat(takeprofit),
        stopLossPrice: parseFloat(stopprice),
        isProfitLoss: (stopopen == true || takeopen == true) ? true : false,
        trigger_price: (orderType == 'conditional') ? trigger_price : 0,
        trigger_type: (orderType == 'conditional') ? trigger_type : 'none',
        Estmargin: Est_Margin,
        pairname: pairData.tiker_root,
        'newdate': new Date(),
        isexpriy: isExpriy,
        Expriydate: Epriydate,
        limitRangepips: (orderType == 'stop_limit') ? Limit_range_pips : 0,
        limitRangeprice: (orderType == 'stop_limit') ? Limit_range_price : 0,
        type: useraccount?.type,
        accountId: useraccount?.accountId,
        comment: comment
      }
      console.log(leverage, 'leverage-->', reqData)
      if (typeof leverage === 'string') {
        let lever = leverage.split(':')
        reqData.leverage = parseFloat(lever[0]) / parseFloat(lever[1])
      }
      let vaildate = await validation(reqData)
      console.log(!isEmpty(vaildate) || !ObjectIsempty(errors), vaildate, errors, ObjectIsempty(errors), isEmpty(vaildate), "errors,vaildate")
      if (!isEmpty(vaildate) || !ObjectIsempty(errors)) {
        console.log(vaildate, errors, "errors,vaildate")
        setError({ ...errors, ...vaildate })
        if (buyorsell == 'buy') {
          setBuyLoader(false)
        }
        else if (buyorsell == 'sell') {
          setSellLoader(false)
        }
        //limit or market
        if (orderType == 'limit') {
          setLimitLoader(false)
        }
        else if (orderType == 'market') {
          setMarketLoader(false)
        }
        else if (orderType == 'stop') {
          setstoploader(false)
        }
        else if (orderType == 'stop_limit') {
          setstoplimitloader(false)
        }
        console.log(vaildate, 'vaildate')
        return false
      }
      let encryptToken = {
        token: encryptObject(reqData)
      }
      let { status, loading, message, error, result, key } = await orderPlace(encryptToken);
      if (key == 'Margin level') {
        setMarginpop(true)
        let leverage
        if (pairData.tradestatus == 'xoh') {
          leverage = pairData.leverage
        } else {
          leverage = useraccount.leverage
        }
        await initialDataSet(leverage, pairData, orderType, pairList)
        return
      }
      // setResults(result)
      if (buyorsell == 'buy') {
        setBuyLoader(loading)
      } else if (buyorsell == 'sell') {
        setSellLoader(loading)
      }
      //limit or market
      if (orderType == 'limit') {
        setLimitLoader(loading)
      } else if (orderType == 'market') {
        setMarketLoader(loading)
      }

      if (status) {
        toastAlert('success', message, 'spotOrder');
        let leverage
        if (pairData.tradestatus == 'xoh') {
          leverage = pairData.leverage
        } else {
          leverage = useraccount.leverage
        }
        await initialDataSet(leverage, pairData, orderType, pairList)
      } else {
        toastAlert('error', message, 'spotOrder');
        let leverage
        if (pairData.tradestatus == 'xoh') {
          leverage = pairData.leverage
        } else {
          leverage = useraccount.leverage
        }
        await initialDataSet(leverage, pairData, orderType, pairList)
      }
    }
    catch (err) {
      console.log('handleSubmit-err', err)
    }
  }

  //closed and cancel orders functions
  const handleMarketSubmit = async (orderType, index) => {
    try {
      let obj = {
        index: index,
        status: true
      }
      setLoader(obj)
      let price = orderType.price;
      let quantity = orderType.positionFilled;
      let leverage = orderType.estleverage;
      let estleverage = orderType.estleverage
      let buyorsell = orderType.buyorsell;
      let orderId = orderType.orderId2 ? orderType.orderId2 : 0
      let Estmargin = await EstMargin(price, quantity, leverage)
      let reqData = {
        price: price,
        quantity: quantity,
        leverage: leverage,
        buyorsell: buyorsell,
        Estmargin: Estmargin,
        pairname: pairData.tiker_root,
        ordertype: 'market',
        type: useraccount?.type,
        accountId: useraccount.accountId,
        orderId: orderId,
        action: 'close',
        positionId: orderType._id
      }
      console.log('orderType--->', reqData)

      let encryptToken = {
        token: encryptObject(reqData)
      }
      let { status, loading, message, error, result } = await orderPlace(encryptToken, type);

      obj = {
        index: index,
        status: false
      }
      setLoader(obj)
      if (status) {
        toastAlert('success', t(message), 'cancelOrder');
      } else {
        toastAlert('error', t(message), 'cancelOrder');
      }

    }
    catch (err) {
      console.log('handleMarketSubmit--err', err)
    }
  }

  const ClosePosition = async (buyorsell) => {
    try {
      // let orderData = positionorder.filter(val => (val.buyorsell == buyorsell && val.pairName == pairData.tiker_root))
      let pairName = localStorage.getItem('curpair')
      // if (orderData.length > 0) {
      let data = {
        pairname: pairName,
        buyorsell: buyorsell,
        // closedData: orderData,
        useraccount: useraccount
      }
      let { respone } = await CloseAll(data)
      setErrorShow(true)
      setErrorres(respone)
      // if (status) {
      //   toastAlert('success', t(message), 'ClosePosition');
      // } else {
      //   toastAlert('error', t(message), 'ClosePosition');
      // }
      // }
    } catch (err) {
      console.log('ClosePosition--err', err)
    }
  }

  const CloseAllPosition = async () => {
    try {
      setconfirmLoader(true)
      let data = {
        useraccount: useraccount
      }
      let { respone } = await CloseAll(data)
      setconfirmLoader(false)
      setErrorShow(true)
      setErrorres(respone)
    } catch (err) {
      console.log('CloseAllPosition--err', err)
    }
  }

  const handleCancelOrder = async (order) => {
    try {
      order = { id: order }
      let { status, loading, error, message } = await cancelOrder(order);
      if (status == "success") {
        toastAlert('success', t(message), 'cancelOrder');
      } else {
        toastAlert('error', t(message), 'cancelOrder');
      }
    }
    catch (err) {
      console.log("handleCancelOrder-err", err);
    }
  }

  const CancelTrade = async (type) => {
    try {
      // let order = openorder.filter(val => val.buyorsell == type)
      // let obj = { orders: order }
      setconfirmLoader(true)
      let data = {
        accountId: useraccount.accountId,
        type: isEmpty(type) ? '' : type,
        pairname: isEmpty(type) ? '' : pairData.socketsymbol,
      }
      let { status, loading, respone } = await cancelAllOrder(data)
      if (status == 'success') {
        setconfirmLoader(false)
        setErrorShow(true)
        setErrorres(respone)
        // toastAlert('success', t(message), 'cancelOrder')
      }
      setconfirmLoader(false)
    } catch (err) {
      console.log(err, 'CancelTrade--err')
    }
  }

  let isDisabled = false;
  if (buyLoader || sellLoader) {
    isDisabled = true
  }

  //fetch Data functions
  const fetchTransaction = async () => {
    try {
      let data = { accountId: localStorage.getItem("accounts") };
      const { status, loading, result, transaction_count } = await getTransaction(data)
      console.log(result, 'fetchTransaction')
      //console.log(result)
      if (status) {
        Settransaction(result);
        setBtransaction(result);
        setTransactioncount(transaction_count);
        console.log("transaction_count", transaction_count)
      }
    }
    catch (err) {
      console.log(err, 'fetchTransactionccccccccccccc')
    }
  }

  const fetchuserTradeData = async (reqData) => {//fetch data
    try {
      const { status, loading, result } = await getuserTradeData(reqData, Type);
      console.log(result, 'fetchuserTradeData')
      setOpenorder(result.orderHistory)
      setBopenorder(result.orderHistory);
      setClosedPos(result.closed_positions);
      setorderHistoryCnt(result.orderHistoryCnt)
      setClosedhistoryCnt(result.closed_positions_count)
      console.log("result.closed_positions_count", result.closed_positions_count)
      setDoneordercnt(result.Histroydetails_count)
      // setBclosedPos(result.closed_positions);
      setHistory(result.Histroydetails)
      // let pairName = (window.location.pathname.split("/")[2])
      let pairName = localStorage.getItem('curpair')
      let sellCount = result.orderHistory.filter((val) => val.buyorsell == 'sell' && val.pairName == pairName)
      let buyCount = result.orderHistory.filter((val) => val.buyorsell == 'buy' && val.pairName == pairName)
      setCancelBuy(buyCount.length > 0 ? true : false)
      setCancelSell(sellCount.length > 0 ? true : false)
      // console.log(sellCount.length, buyCount.length, 'sellCount,buyCount')
      setorderHistoryCnt(result.orderHistoryCnt)
      setfilled(result.Filleddetails)
    } catch (err) {
      console.log(err, 'fetchuserTradeData')
    }
  }

  const fetchPositionOrder = async (reqData) => {//fetch data
    try {
      const { status, loading, result, count } = await getPositionOrder(reqData);
      setLoader(loading)
      if (status == 'success') {
        setloadmoreloader(false)
        console.log(positionorder, result, 'fetchPositionOrder')
        // setPositionorder(result?.length > 0 ? result.reverse():[]);
        setpAndLarr([])
        if (positionorder.length > 0 && reqData.pageSize > 1) {
          setPositionorder([...positionorder, ...result])
          // positionsorder.current=[...result,...positionorder]
        } else {
          setPositionorder(result)
        }

        setpositionCount(count)
        setBpositionorder(result);
        // positionmargin(result)
        // let pairName = window.location.pathname.split("/")[2]
        let pairName = localStorage.getItem('curpair')
        let sellCount = result && result.length > 0 && await result.filter(val => (val.buyorsell == 'sell' && val.pairName == pairName))
        let buyCount = result && result.length > 0 && await result.filter(val => (val.buyorsell == 'buy' && val.pairName == pairName))
        setCloseBuy(sellCount.length > 0 ? true : false)//This  sell buy inverse for closing trade
        setCloseSell(buyCount.length > 0 ? true : false)
      }
    } catch (err) {
      console.log(err, 'fetchPositionOrder')
    }
  }

  const fetchTradeData = async (reqData) => {//fetch data
    try {
      const { status, loading, result } = await getTradeData(reqData);
      if (status == 'success') {
        setOrderBook(result)
      }
    } catch (err) {
      console.log(err, 'fetchTradeData')
    }
  }

  const CalendarData = async () => {
    try {
      let dates = { filter: "Today" }
      if (dates.filter == 'Today') {
        let today = new Date()
        let tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate())
        let tomorrowdates = tomorrow.getTime()
        dates.start = tomorrowdates - (new Date().getHours() * 60 * 60 + (new Date().getMinutes()) * 60 + (new Date().getSeconds())) * 1000
        const todays = new Date()
        const tomorrows = new Date(todays)
        tomorrows.setDate(tomorrows.getDate() + 1)
        let tomorrowdate = tomorrows.getTime()
        dates.end = tomorrowdate - (new Date().getHours() * 60 * 60 + (new Date().getMinutes()) * 60 + (new Date().getSeconds())) * 1000
      }
      dates.type = useraccount.type
      const { status, loading, result } = await getcalenderData(dates)
      if (status == "success") {
        setCalenderData(result)
      }
    } catch (err) {
      console.log("CalendarData-err", err);
    }
  }

  const marketopencolse = async (socketsymbol) => {
    try {
      let d = new Date().toLocaleString("en-US", { timeZoneName: "short" })
      d = d.split(/GMT/g)[1] ? d : new Date(d).toString()
      let data = {
        socketsymbol: socketsymbol,
        localdate: d
      }
      const { status, loading, result } = await getMarketHours(data)
      if (status == 'success') {
        Setmarketdate(result)
      }
    } catch (err) {
      console.log(err, 'marketopencolse--err')
    }
  }

  const fetchPairList = async () => {
    try {
      let status
      let result
      if (pairList.length > 0) {
        status = 'success'
        result = pairList
        console.log("pairList", pairList)
      } else {
        const PairList = await getPairList();
        status = PairList.status
        result = PairList.result
      }
      let userAccountID = localStorage.getItem('accounts')
      if (status == 'success') {
        await setPairList(result, dispatch)
        if (result && result.length > 0) {
          if (isEmpty(tikerRoot)) {
            let pairDataFilter = result.filter((el => el.status !== "deactive"))

            let pair = isEmpty(viewedpair) ? `${result[0].socketsymbol}` : `${viewedpair}`
            let pairDetail = pairDataFilter.find((el => el.tiker_root == pair))
            if (isEmpty(pairDetail)) {
              pair = `${pairDataFilter[0].socketsymbol}`
              pairDetail = pairDataFilter.find((el => el.tiker_root == pair))
            }
            localStorage.setItem('curpair', pair)
            history.replace('/trade/' + pair)
            await userPair({ pair: pair }, dispatch)
            // let pairDetail = result.find((el => el.tiker_root == pair))
            pairsData.current = pairDetail
            await setTradePair(pairDetail, dispatch)
            console.log("Debugger else part 1 : ", useraccount?.leverage, accounts.find((val => val.accountId == userAccountID)).leverage)
            let leverage
            if (pairDetail.tradestatus === 'xoh') {
              leverage = pairDetail.leverage
            } else {
              leverage = useraccount?.leverage ? useraccount?.leverage : accounts.find((val => val.accountId == userAccountID)).leverage
            }
            await initialDataSet(leverage, pairDetail, orderType, result)
            let reqData = {
              pairId: pairDetail?._id,
              type: localStorage.getItem("liveorDemo")
            }
            fetchTradeData(reqData)
            // fetchTransaction(reqDatas)
            marketopencolse(pairDetail?.socketsymbol)
          } else {
            // let currency = (window.location.pathname.split("/")[2]);
            let pairDataFilter = result.filter((el => el.status !== "deactive"))
            let currency = localStorage.getItem('curpair')
            let pairDetail = pairDataFilter.find((el => el.tiker_root == currency))
            console.log("Debugger else part 1 : ", pairDetail)
            if (isEmpty(pairDetail)) {
              currency = `${pairDataFilter[0].socketsymbol}`
              localStorage.setItem('curpair', currency)
              pairDetail = pairDataFilter.find((el => el.tiker_root == currency))
              history.replace('/trade/' + currency)
              await userPair({ pair: currency }, dispatch)
            }
            pairsData.current = pairDetail
            let setTradePairs = setTradePair(pairDetail, dispatch)
            let leverage = formValue.leverage
            if (pairDetail.tradestatus === 'xoh') {
              leverage = pairDetail?.leverage
            }
            else {
              leverage = useraccount?.leverage ? useraccount?.leverage : accounts?.find((val => val.accountId == userAccountID)).leverage
            }
            await initialDataSet(leverage, pairDetail, orderType, result)
            let reqData = {
              pairId: pairDetail?._id,
              type: localStorage.getItem("liveorDemo")
            }
            // fetchPositionOrder(objData)
            fetchTradeData(reqData)
            // fetchuserTradeData(reqDatas)
            // fetchTransaction(reqDatas)
            marketopencolse(pairDetail.socketsymbol)
          }
        }
      }
    }
    catch (err) {
      console.log(err, 'fetchPairList-err')
    }
  }

  const getProfitCalculation = async () => {
    try {
      // let pairName = window.location.pathname.split("/")[2];
      let pairName = localStorage.getItem('curpair')
      let userAccountID = localStorage.getItem('accounts')
      userAccountID = useraccount?.accountId ? userAccountID : useraccount?.accountId

      if (pairName && userAccountID) {
        let objData = {
          pairName: pairName,
          accountId: userAccountID,
        }
        await fetchPositionOrder(objData)
      } else {
        return true
      }
    } catch (err) {
      console.log(err, 'getProfitCalculation')
    }
  }
  const ArrangethePositionorders = async (result) => {
    try {
      // console.log('perpetualmarketprice', positionsorder.current, pairsList.current)
      if (positionsorder.current?.length > 0) {
        let data = positionsorder.current.filter((val) => (val.pairName == result.tiker_root))
        data.length > 0 && data.map(async (val, i) => {
          if (val.pairName == result.tiker_root) {
            let pairDetail = pairsList.current.find((value) => (value.tiker_root === val.pairName))
            // console.log(pairDetail, 'perpetualmarketprice', pairsList.current)
            val['close_price'] = val.buyorsell === 'buy' ? result.ask : result.bid
            let volume = quantitytovolume(val.positionFilled, pairDetail, val.price)
            let profit = await getPorfitCalculation(val['close_price'], val.price, volume, pairDetail, val.buyorsell, pairsList.current)
            val['profitnloss'] = profit
            val['pAndL'] = parseFloat(profit) + parseFloat(val.totalFee)
            val['closingFee'] = parseFloat(val.closingFee) + parseFloat(val.commission)
            let data = positionsorder.current
            data[val.index] = val

            setPositionorder(data)
          }
        })
      }
    } catch (err) {
      console.log(err, 'ArrangethePositionorders__err')
    }
  }
  //fetch data uesEffect
  useEffect(() => {
    document.title = "TradingOne"
    fetchPairList()
    // CalendarData()
    Dateformate()
    // let oldintervalId = localStorage.getItem('getProfitCalculation', intervalId)
    // if (oldintervalId) {
    //   clearInterval(oldintervalId);
    //   localStorage.removeItem('getProfitCalculation')
    // }
    // const intervalId = setInterval(async () => {
    //   await getProfitCalculation()
    // }, 2000)
    // localStorage.setItem('getProfitCalculation', intervalId)
  }, [tikerRoot, localStorage.getItem('liveorDemo')])

  useEffect(() => {
    let leverage = formValue.leverage
    if (pairData.tradestatus !== 'xoh') {
      leverage = useraccount?.leverage
      let formData = { ...formsValue.current, ...{ 'leverage': leverage } }
      setData(leverage)
      setFormValue(formData)
    }
    let userAccountID = localStorage.getItem('accounts')
    let objData = {
      accountId: useraccount?.accountId ? userAccountID : useraccount?.accountId,
      pageSize: positionPage,
      direction: alldirection,
      pair: positionsearch
    }
    let reqDatas = {
      accountId: useraccount?.accountId ? userAccountID : useraccount?.accountId
    }

    if (isAuth) {
      fetchPositionOrder(objData)
      fetchuserTradeData(reqDatas)
      // fetchMoreData()
      fetchTransaction(reqDatas)
    }
  }, [useraccount, pairList])
  const setData = async (leverage) => {
    try {
      var buyprice = pairData && pairData?.ask
      var sellprice = pairData && pairData?.bid
      let Price = buyorsell == 'buy' ? buyprice : sellprice
      localStorage.setItem('price', Price)
      let quantity = volumetoquantity(pairData.minQuantity, pairData, Price)
      let Est_margin = await EstMargin(Price, quantity, leverage, pairData, buyorsell, pairList)
      setEmargin(isNaN(Est_margin) ? 0 : Est_margin?.toFixed(2))
      let formData = { ...formsValue.current, ...{ 'quantity': quantity?.toFixed(2), 'leverage': leverage, 'price': Price } }
      setFormValue(formData)
    } catch (err) {
      console.log('setData_err', err)
    }
  }
  // useEffect(() => {
  //   let oldintervalId = localStorage.getItem('getProfitCalculation', intervalId)
  //   if (oldintervalId) {
  //     clearInterval(oldintervalId);
  //     localStorage.removeItem('getProfitCalculation')
  //   }
  //   const intervalId = setInterval(async () => {
  //     let accountId = localStorage.getItem('accounts')
  //     let pairName = localStorage.getItem('curpair')
  //     console.log(accountId, 'getporfitcalculation')
  //     let data = { accountId: accountId, pairName: pairName }
  //     socketContext.socket.emit('getporfitcalculation', data)
  //     // positionmargin(positionorder)
  //   }, 2000)
  //   localStorage.setItem('getProfitCalculation', intervalId)
  // }, [])

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  //socket fetch data useEffect
  useEffect(() => {
    console.log("markupprice", Markupprice, SocketContext.socket)
    socketContext.socket.on('positionmargin', (results) => {
      console.log('positionmargin__socket', results)
      try {
        SetPandLSingel(results.prolss1)
        SetEqutityper(results.equity)
        setPositionMargin(results.positionmargins)
        SetFreemargin(results.freemargin)
        Setmarginlevels(results.marginlevels)
        SetProLoss(results.prolss)
        setCloseBuy(results.sellCount.length > 0 ? true : false)//This  sell buy inverse for closing trade
        setCloseSell(results.buyCount.length > 0 ? true : false)
        let data = {
          equity: results.equity,
          positionmargins: results.positionmargins,
          freemargin: results.freemargin,
          marginlevels: results.marginlevels
        }
        dispatch(setEquAndmarign(data))
      } catch (err) {
        console.log(err, 'positionmargin__err')
      }
    })
    socketContext.socket.on('positionorders', (result) => {
      try {
        console.log('positionorders-useEffect', result)
        // setPositionorder(result?.length > 0 ? result.reverse() : []);
        // setpAndLarr([])
        setPositionorder(result.result)
        setpositionCount(result.count)
        // positionmargin(result)
        let pairName = localStorage.getItem('curpair')
        let sellCount = result && result.result.length > 0 && result.result.filter(val => (val.buyorsell == 'sell' && val.pairName == pairName))
        let buyCount = result && result.result.length > 0 && result.result.filter(val => (val.buyorsell == 'buy' && val.pairName == pairName))
        setCloseBuy(sellCount.length > 0 ? true : false)//This  sell buy inverse for closing trade
        setCloseSell(buyCount.length > 0 ? true : false)
      } catch (err) {
        console.log(err, 'positionorders__err')
      }
    })

    socketContext.socket.on('usertrade', (result) => {
      try {
        console.log('usertrade-useEffect', result)
        setOpenorder(result.orderHistory)
        setBopenorder(result.orderHistory);
        setClosedPos(result.closed_positions);
        // setBclosedPos(result.closed_positions);
        setHistory(result.Histroydetails);
        setorderHistoryCnt(result.orderHistoryCnt)
        setClosedhistoryCnt(result.closed_positions_count)
        setfilled(result.Filleddetails)
      } catch (err) {
        console.log(err, 'usertrade-useEffect')
      }
    })

    socketContext.socket.on('TRADE', (result) => {
      try {
        console.log("TRADE>", result);
        if (result.type == localStorage.getItem("liveorDemo")) {
          setOrderBook(result)
        }
      } catch (err) {
        console.log('TRADE__err', err)
      }
    })

    socketContext.socket.on('perpetualmarketprice', async (result) => {
      try {
        let pairName = localStorage.getItem('curpair')

        if (!isEmpty(pairName)) {
          // let pairName = window.location.pathname.split("/")[2];
          let pairName = localStorage.getItem('curpair')
          if (result.tiker_root == pairName) {
            // console.log(result, 'pairData,pairsData,Markupprices')
            setmarkupprice(result)
            await calculationDynamic(result)
          }
        }
        else {
          let pairName = pairList[0]?.tiker_root
          if (result.tiker_root == pairName) {
            // console.log(result, 'pairData,pairsData,Markupprice')
            setmarkupprice(result)
            await calculationDynamic(result)
          }
        }

        await ArrangethePositionorders(result)
      } catch (err) {
        console.log(err, 'perpetualmarketprice___err')
      }
    })

  }, [socketContext.socket, tikerRoot, useraccount])

  //useRef useEffect
  useEffect(() => {
    // let pairName = window.location.pathname.split("/")[2];
    let pairName = localStorage.getItem('curpair')
    if (pairsData.current.tiker_root == pairName && (isEmpty(pairData) || pairData == undefined || pairData == null)) {
      pairsData.current = pairsData.current
    } else if (pairsData.current.tiker_root == pairData.tiker_root) {
      pairsData.current = pairData
    }
  }, [pairData])

  useEffect(() => {
    formsValue.current = formValue
  }, [formValue])

  useEffect(() => {
    Stopopen.current = stopopen
    Takeopen.current = takeopen
    SpPip.current = spPip
    TpPip.current = tpPip
    TpUsd.current = tpUsd
    SpUsd.current = spUsd
  }, [stopopen, takeopen, spPip, tpPip, tpUsd, spUsd])

  useEffect(() => {
    positionsorder.current = positionorder
  }, [positionorder])

  useEffect(() => {
    pairsList.current = pairList
  }, [pairList])


  useEffect(() => {
    if (!isEmpty(pairList) && ischart) {
      setDisplayLoader(false)
    }
    else {
      setDisplayLoader(true)
    }
  }, [pairList, ischart])

  const handleChangeprops = () => {
    setChart(true)
  }

  //Onchange useEffect

  useEffect(() => {
    console.log(pipValue, takeopen, 'pipValue-useEffect')
    if (takeopen) {
      // if (tpPip) {
      let tpusd = (parseFloat(tpPip) * pipValue).toFixed(2)
      console.log(tpusd, 'pipValue-useEffect')
      settpUsd(parseFloat(tpusd))
      // }
    }
    if (stopopen) {
      // if (spPip) {
      let spusd = (parseFloat(spPip) * pipValue).toFixed(2)
      console.log(spusd, 'pipValue-useEffect')
      setspUsd(parseFloat(spusd))
      // }
    }
  }, [pipValue])

  useEffect(() => {
    let takeprofit = 0
    let stopprice = 0
    let formData = formValue
    if (takeopen && !isTEdit) {
      takeprofit = (parseFloat(price) + (parseFloat(tpPip) / Math.pow(10, pairData.pip_position))).toFixed(pairData.precision)
      calculateTpandSp(parseFloat(takeprofit), 'takeprofit')
    }
    if (stopopen && !isSEdit) {
      stopprice = (parseFloat(price) + (parseFloat(spPip) / Math.pow(10, pairData.pip_position))).toFixed(pairData.precision)
      calculateTpandSp(parseFloat(stopprice), 'stopprice')
    }
    if (!isTEdit) {
      formData = { ...formData, ...{ 'takeprofit': isNaN(takeprofit) ? price : takeprofit } }
      setFormValue(formData)
    } if (!isSEdit) {
      formData = { ...formData, ...{ 'stopprice': isNaN(stopprice) ? price : stopprice } }
      setFormValue(formData)
    }
  }, [price])

  const getmonth = (data) => {
    try {
      if (data == "Current Month") {
        return Date.now() - (86400000 * (new Date().getDate()))
      }
      if (data == "Last 2 Month") {
        return Date.now() - (86400000 * (new Date().getDate() + 30))
      }
      if (data == "Last 3 Month") {
        return Date.now() - (86400000 * (new Date().getDate() + 61))
      }
    } catch (err) {
      console.log('getmonth---err', err)
    }
  }

  //filter functions
  const handleorderfilter = async (search) => {
    setOrdersearch(search);
    if (search.length > 0) {
      const filteredData = await openorder.filter(value => ((value.pairName).toUpperCase()).includes(search.toUpperCase()));
      setOpenorder(filteredData);
    }
    else {
      setOpenorder(bopenorder);
    }
  }

  const handletransactionfilter = async (search, type, transactionpage) => {
    console.log("handletransactionfilter", type);
    let data = { accountId: localStorage.getItem("accounts") };
    console.log("data_data_data", data);
    if (search.length > 0) {
      setTransactionsearch(search);
      data.search = search
    }
    if (type) {
      setTransactiontype(type);
      data.paymenttype = type
    }
    console.log("datassdasdasdsdd", data);
    data.page = transactionpage
    settransactionpage(transactionpage)
    let result = await filterTransaction(data);
    console.log("result_resultresult", result);
    // if (!search && !type) {
    //   Settransaction(btransaction)
    // } else {
    setTransactioncount(result.count)
    // Settransaction(result?.result)
    // }
    if (result) {
      setloadmoreloader(false)
    }
    console.log("resp", result)
    if (result && result?.result?.length > 0) {
      Settransaction(result.result)
      // setFilledDetails(filledDetails + 1)
    }
    if (result.count <= result.result.length) {
      setLoadTransaction(false)
    } else {
      setLoadTransaction(true)
    }

    //console.log("result in handle filter", result);
  }

  const handletransactionLoadmore = async (search, type) => {
    setloadmoreloader(true)
    console.log("handletransactionfilter", type);
    let data = { accountId: localStorage.getItem("accounts") };
    console.log("data_data_data", data);
    if (search?.length > 0) {
      setTransactionsearch(search);
      data.search = search
    }
    if (type) {
      setTransactiontype(type);
      data.paymenttype = type
    }
    console.log("datassdasdasdsdd", data);
    data.page = transactionpage + 1
    settransactionpage(transactionpage + 1)
    let result = await filterTransaction(data);
    console.log("result_resultresult", result);
    if (!search && !type) {
      Settransaction(btransaction)
    } else {
      setTransactioncount(result?.result?.length)
      Settransaction(result?.result)
    }
    if (result) {
      setloadmoreloader(false)
    }
    console.log("result", result)
    if (result && result?.result?.length > 0) {
      Settransaction([...transaction, ...result.result])
      setFilledDetails(filledDetails + 1)
    }
    if (result?.count <= result?.result?.length) {
      setLoadTransaction(false)
    }
    //console.log("result in handle filter", result);
  }

  const handlehistoryfilter = async (search, day, start, end, closedpage) => {
    //console.log("handlehistory filter", search, day, start, end);
    setloadmoreloader(true)
    if (ishistoryday && ishistorydate) { }
    setHistorysearch(search);
    setHistoryday(day);
    SetHistorystart(start);
    setHistoryend(end);
    var payload = {};

    if (search.length > 0) {
      payload.search = search;
    }
    if (ishistoryday) {
      payload.ishistoryday = ishistoryday
      if (day) {
        payload.days = day
      }
    }
    if (ishistorydate) {
      payload.ishistorydate = ishistorydate
      if (start) {
        payload.startdate = start;
      }
      if (end) {
        payload.enddate = end;
      }
    }
    payload.accountId = useraccount.accountId
    payload.page = closedpage
    setClosedpage(closedpage)
    let filtereddata = await filterclosedposition(payload);
    //console.log("filtered data", filtereddata);
    setClosedPos(filtereddata?.result);
    if (filtereddata) {
      setloadmoreloader(false)
    }
    setClosedhistoryCnt(filtereddata.count)
    console.log("resp", filtereddata)
    if (filtereddata && filtereddata.result.length > 0) {
      console.log("closed_position", filtereddata.result, closed_position)
      setClosedPos(filtereddata.result)
      setFilledDetailsPage(filledDetailsPage + 1)
      console.log("closed_position1", filtereddata.result, closed_position, filtereddata.count)
    }
    if (filtereddata.count <= filtereddata.result.length) {
      setLoadhistory(false)
    } else {
      setLoadhistory(true)
    }
  }
  const handlehistoryLoadmore = async (search, day, start, end, closedpage) => {
    setloadmoreloader(true)
    if (ishistoryday && ishistorydate) { }
    setHistorysearch(search);
    setHistoryday(day);
    SetHistorystart(start);
    setHistoryend(end);
    var payload = {};
    if (search.length > 0) {
      payload.search = search;
    }
    if (ishistoryday) {
      payload.ishistoryday = ishistoryday
      if (day) {
        payload.days = day
      }
    }
    if (ishistorydate) {
      payload.ishistorydate = ishistorydate
      if (start) {
        payload.startdate = start;
      }
      if (end) {
        payload.enddate = end;
      }
    }
    payload.accountId = useraccount.accountId
    payload.page = closedpage
    setClosedpage(closedpage)

    let filtereddata = await filterclosedposition(payload);
    //console.log("filtered data", filtereddata);
    setClosedPos(filtereddata?.result);
    if (filtereddata) {
      setloadmoreloader(false)
    }
    setClosedhistoryCnt(filtereddata.count)
    console.log("resp", filtereddata)
    if (filtereddata && filtereddata.result.length > 0) {
      console.log("closed_position", filtereddata.result, closed_position)
      setClosedPos([...closed_position, ...filtereddata.result])
      setFilledDetailsPage(filledDetailsPage + 1)
      console.log("closed_position1", filtereddata.result, closed_position, filtereddata.count)
    }
    if (filtereddata.count <= filtereddata.result.length) {
      setLoadhistory(false)
    } else {
      setLoadhistory(true)
    }
  }
  // const handlpositionfilter = async (search, direction) => {
  //   console.log("handlpositionfilterdata", direction);
  //   var data = {};
  //   // data.aggregate = aggregate
  //   // aggregate && setAggregated(aggregate)
  //   setfillterposition(true)
  //   setPositionsearch(search);
  //   setAlldirection(direction);
  //   if (search) {
  //     data.search = search
  //   }
  //   if (direction) {
  //     console.log("directiondirectiondirectiondirection");
  //     data.direction = direction
  //   }
  //   // if (!search && !direction && aggregate == "Not Aggregated") {
  //   //   // console.log('fpositinorder,fillterposition')
  //   //   setfillterposition(false)
  //   //   setPositionorder(bpositionorder.reverse())
  //   // }
  //   if (!search && !direction) {
  //     // console.log('fpositinorder,fillterposition')
  //     setfillterposition(false)
  //     setPositionorder(bpositionorder)
  //   }
  //   else {
  //     // console.log('fpositinorder,fillterposition2')
  //     data.accountId=useraccount.accountId
  //     let filter = await filterPositionOrder(data);
  //     console.log("filter_V_Vfilterfilter", filter);
  //     setPositionorder(filter?.result);
  //     setfposition(filter?.result)
  //   }

  // }

  const handlpositionfilter = async (search, direction) => {
    try {
      let data = {};
      setfillterposition(true)
      setPositionsearch(search);
      setAlldirection(direction);
      data.pair = isEmpty(search) ? '' : search
      data.direction = isEmpty(direction) ? '' : direction
      data.accountId = useraccount.accountId
      data.pageSize = 1
      const { status, loading, result, count } = await getPositionOrder(data);
      if (status == 'success') {
        if (positionorder.length > 0 && data.pageSize > 1) {
          setPositionorder([...positionorder, ...result])
          // positionsorder.current=[...result,...positionorder]
        } else {
          setPositionorder(result)
          setpositionCount(count)
        }
      }
    } catch (err) {
      console.log(err, 'handlpositionfilter__err')
    }
  }

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime()
  };

  const Dateformate = async (e) => {
    try {
      let filter = 'Today'
      if (!isEmpty(e?.target?.value)) {
        filter = e.target.value
      }
      let dates = { filter };
      var d = new Date();
      var startOfWeek = new Date(d.setDate(d.getDate() + 7 - d.getDay()));
      var ensdatedd = new Date(d.setDate(d.getDate() + 6))
      if (filter == 'Today') {
        let today = new Date()
        let tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate())
        let tomorrowdates = tomorrow.getTime()
        dates.start = tomorrowdates - (new Date().getHours() * 60 * 60 + (new Date().getMinutes()) * 60 + (new Date().getSeconds())) * 1000
        const todays = new Date()
        const tomorrows = new Date(todays)
        tomorrows.setDate(tomorrows.getDate() + 1)
        let tomorrowdate = tomorrows.getTime()
        dates.end = tomorrowdate - (new Date().getHours() * 60 * 60 + (new Date().getMinutes()) * 60 + (new Date().getSeconds())) * 1000
        let d = new Date();
        let day = weekday[d.getDay()];
        setdatassss(day)
        let todayss = new Date().toLocaleDateString()
        setdates(todayss)
      }
      else if (filter == 'Tomorrow') {

        let today = new Date()
        let tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() + 1)
        let tomorrowdates = tomorrow.getTime()
        dates.start = tomorrowdates - (new Date().getHours() * 60 * 60 + (new Date().getMinutes()) * 60 + (new Date().getSeconds())) * 1000
        const todays = new Date()
        const tomorrows = new Date(todays)
        tomorrows.setDate(tomorrows.getDate() + 2)
        let tomorrowdate = tomorrows.getTime()
        dates.end = tomorrowdate - (new Date().getHours() * 60 * 60 + (new Date().getMinutes()) * 60 + (new Date().getSeconds())) * 1000
        let d = new Date();
        let day = weekday[d.getDay() + 1];
        setdatassss(day)
        let todaysss = new Date()
        let tomorrowssss = new Date(todaysss)
        tomorrowssss.setDate(tomorrowssss.getDate() + 1)
        let tomorrowDAtes = tomorrowssss.getTime()
        let todayss = new Date(tomorrowDAtes).toLocaleDateString()
        setdates(todayss)
      }
      else if (filter == 'Yesterday') {
        let today = new Date()
        let yesterday = new Date(today)
        yesterday.setDate(yesterday.getDate() - 1)
        dates.start = yesterday.getTime()
        let todays = new Date()
        let yesterdays = new Date(todays)
        yesterdays.setDate(yesterdays.getDate() - 2)
        dates.end = yesterday.getTime()
        let d = new Date();
        let day = weekday[d.getDay() - 1];
        setdatassss(day)
        let todaysss = new Date()
        let tomorrowssss = new Date(todaysss)
        tomorrowssss.setDate(tomorrowssss.getDate() - 1)
        let tomorrowDAtes = tomorrowssss.getTime()
        let todayss = new Date(tomorrowDAtes).toLocaleDateString()
        setdates(todayss)
      }
      else if (filter == 'Previous Week') {
        var d = new Date();
        var end = d.setTime(d.getTime() - (d.getDay() ? d.getDay() : 7) * 24 * 60 * 60 * 1000);
        var start = d.setTime(d.getTime() - 6 * 24 * 60 * 60 * 1000);
        dates.start = start
        dates.end = end
        let datestart = new Date(start)
        let todaysss = new Date(datestart).toLocaleDateString()
        setdates(todaysss)
        let dd = new Date(todaysss);
        let day = weekday[dd.getDay() - 5];
        setdatassss(day)
      }
      else if (filter == 'Next Week') {
        let dd = new Date();
        let startOfWeek = new Date(dd.setDate(dd.getDate() + 7 - dd.getDay()));
        let tomorrowdates = startOfWeek.getTime()
        var ensdatedd = new Date(dd.setDate(dd.getDate() + 6))
        let enddatesss = ensdatedd.getTime()
        dates.start = tomorrowdates
        dates.end = enddatesss
        let d = new Date(tomorrowdates);
        let day = weekday[d.getDay()];
        setdatassss(day)
        let ddate = new Date()
        let startOfWeeks = new Date(ddate.setDate(ddate.getDate() + 7 - ddate.getDay()));
        let tomorrowDAtes = startOfWeeks.getTime()
        let todayss = new Date(tomorrowDAtes).toLocaleDateString()
        setdates(todayss)
      }
      else if (filter == 'This Week') {
        let curr = new Date() // get current date
        let first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
        let last = first + 6; // last day is the first day + 6
        let firstday = new Date(curr.setDate(first)).toUTCString();
        let lastday = new Date(curr.setDate(last)).toUTCString();
        dates.start = new Date(firstday).getTime()
        dates.end = new Date(lastday).getTime()
        let d = new Date();
        let day = weekday[d.getDay() - 3];
        setdatassss(day)
        let todaysss = new Date()
        let tomorrowssss = new Date(todaysss)
        tomorrowssss.setDate(tomorrowssss.getDate() - 3)
        let tomorrowDAtes = tomorrowssss.getTime()
        let todayss = new Date(tomorrowDAtes).toLocaleDateString()
        setdates(todayss)

      }
      dates.type = useraccount.type
      const { status, loading, result } = await getcalenderData(dates, filter)
      setCalenderData(result)
    } catch (err) {
      console.log('Dateformate--err', err)
    }
  }

  const showloader = () => {
    document.getElementById("loader").classList.add("d-block");
    document.getElementById("loader").classList.remove("d-none");
  }

  const hideloader = () => {
    document.getElementById("loader").classList.add("d-none")
    document.getElementById("loader").classList.remove("d-block")
  }

  //initialDataSet function
  const initialDataSet = async (leverage, pairDetail, e, pairList) => {
    try {
      console.log(leverage, pairDetail, 'quantity,leverage,Price')
      setTEdit(false)
      setSEdit(false)
      setLimitLoader(false)
      setMarketLoader(false)
      setstoplimitloader(false)
      setstoploader(false)
      setVolume(pairDetail.minQuantity)
      setStopopen(false)
      setExpriy(false)
      setTakeopen(false)
      setError({})
      setspPip(0)
      settpPip(0)
      settpUsd(0)
      setspUsd(0)
      var buyprice = pairDetail && pairDetail?.ask
      var sellprice = pairDetail && pairDetail?.bid
      let Price = buyorsell == 'buy' ? buyprice : sellprice
      localStorage.setItem('price', Price)
      let quantity = volumetoquantity(pairDetail.minQuantity, pairDetail, Price)
      let Est_margin = 0
      if (!isEmpty(leverage)) {
        Est_margin = await EstMargin(Price, quantity, leverage, pairDetail, buyorsell, pairList)
      }
      setEmargin(isNaN(Est_margin) ? 0 : Est_margin.toFixed(2))
      let formData = { ...initialFormValue, ...{ 'quantity': quantity.toFixed(2), 'leverage': leverage, 'price': Price, 'orderType': e } }
      formsValue.current = formData
      setFormValue(formData)
      if (pairDetail.category.toUpperCase() == 'FOREX') {
        if (pairDetail.secondCurrencySymbol != 'USD' && pairDetail.firstCurrencySymbol != 'USD') {
          const convertquan = await priceConversion(pairDetail.firstCurrencySymbol, quantity, buyorsell, pairList)
          quantity = convertquan
          let pipValue = (1 / Math.pow(10, pairDetail?.pip_position) * parseFloat(quantity)) / parseFloat(Price)
          console.log(quantity, 'calculatepipValue', pipValue)
          setpipValue(isNaN(pipValue) ? 0 : pipValue)
        }
        else if (pairDetail.firstCurrencySymbol == 'USD') {
          let pipValue = (1 / Math.pow(10, pairDetail?.pip_position) * parseFloat(quantity))
          pipValue = await priceConversion(pairDetail.secondCurrencySymbol, pipValue, buyorsell, pairList)
          console.log(pipValue, 'pipValue')
          setpipValue(isNaN(pipValue) ? 0 : pipValue)
        }
        else {
          let pipValue = (1 / Math.pow(10, pairDetail?.pip_position) * parseFloat(quantity))
          console.log(pipValue, 'pipValue')
          setpipValue(isNaN(pipValue) ? 0 : pipValue)
        }
      }
      else if (pairDetail.category.toUpperCase() == 'CRYPTO') {
        let pipValue = (pairDetail.lotsize * pairDetail.minQuantity).toFixed(2)
        setpipValue(parseFloat(pipValue))
      }
      else {
        let pipValue = (pairDetail.lotsize * pairDetail.minQuantity).toFixed(2)
        setpipValue(parseFloat(pipValue))
      }
      if (Markupprice) {
        if (Markupprice?.tiker_root != pairDetail.tiker_root) {
          setmarkupprice({})
        }
      }
      console.log('Markupprice?.markprice', Price, quantity, leverage)
      let valPip = Price * Math.pow(10, pairDetail.pip_position)
      let Distance = Math.abs(parseFloat(Price * Math.pow(10, pairDetail.pip_position)) - parseFloat(valPip)).toFixed(1)
      setDistance(isNaN(Distance) ? 0 : Distance)

      // let formData = { ...initialFormValue, ...{ 'quantity': quantity.toFixed(2), 'leverage': leverage, 'price': Price ,'orderType':e} }
      // setFormValue(formData)
    } catch (err) {
      console.log(err, 'initialDataSet--err')
    }
  }

  const yesterday = moment().subtract(1, 'day');
  const disablePastDt = current => {
    return current.isAfter(yesterday);
  };

  const fetchMoreData = async () => {
    setloadmoreloader(true)
    let data = {
      page: closed_position.length,
      accountId: localStorage.getItem("accounts")
    }
    var resp = await historyloadmore(data)
    if (resp) {
      setloadmoreloader(false)
    }
    console.log("resp", resp)
    if (resp && resp.data.length > 0) {
      console.log("closed_position", resp.data, closed_position)
      setClosedPos([...closed_position, ...resp.data])
      setFilledDetailsPage(filledDetailsPage + 1)
      console.log("closed_position1", resp.data, closed_position)
    }
    if (resp.data.length <= 0) {
      setLoadhistory(false)
    }


  };
  const fetchTransData = async () => {
    setloadmoreloader(false)
    let data = {
      page: transaction.length,
      accountId: localStorage.getItem("accounts")
    }
    if (transactionsearch.length > 0) {
      // setTransactionsearch(search);
      data.search = transactionsearch
    }
    if (transactiontype) {
      // setTransactiontype(type);
      data.paymenttype = transactiontype
    }
    var resp = await Transactionloadmore(data)
    if (resp) {
      setloadmoreloader(false)
    }
    console.log("resp", resp)
    if (resp && resp.data.length > 0) {
      Settransaction([...transaction, ...resp.data])
      setFilledDetails(filledDetails + 1)
    }
    if (resp.data.length <= 0) {
      setLoadTransaction(false)
    }

  };
  const SearchOrderData = async (search, page) => {
    try {
      setOrdersearch(search);
      let data = {
        page: page,
        accountId: localStorage.getItem("accounts"),
        search: search
      }
      console.log("fetchOrderData", data)
      setPage(page)
      var resp = await Orderloadmore(data)
      console.log("resp", resp, resp.data.orderHistory)
      if (resp && resp.data.orderHistory.length > 0) {
        setOpenorder(resp.data.orderHistory)
        setorderHistoryCnt(resp.data.orderHistoryCount)
        console.log("setOpenorder ", resp.data.orderHistory)
        // setFilledOrder( filledOrder + 1)
      }
      if (resp.data.orderHistory.length <= 0) {
        setLoadOrder(false)
      }
    } catch (err) {
      console.log(err, 'SearchOrderData_err')
    }
  }
  const fetchOrderData = async (search, page) => {
    try {
      setloadmoreloader(true)
      setOrdersearch(search);
      let data = {
        page: page,
        accountId: localStorage.getItem("accounts"),
        search: search
      }
      setPage(page)
      console.log("fetchOrderData", data)
      var resp = await Orderloadmore(data)
      console.log("resp", resp.data.orderHistory)
      if (resp) {
        setloadmoreloader(false)
      }
      if (resp && resp.data.orderHistory.length > 0) {
        setOpenorder([...openorder, ...resp.data.orderHistory])
        setorderHistoryCnt(resp.data.orderHistoryCount)
        console.log("setOpenorder ", resp.data.orderHistory)
        // setFilledOrder( filledOrder + 1)
      }
      if (resp.data.orderHistory.length <= 0) {
        setLoadOrder(false)
      }
    } catch (err) {
      console.log(err, 'fetchOrderData__err')
    }
  };
  const SetTime = () => {
    try {
      let time = new Date().setMinutes(0)
      time = new Date(time).setHours(17)
      return time
    } catch (err) {
      console.log(err, 'SetTime__edrr')
    }
  }
  const { buyOrder, sellOrder } = orderBook
  // console.log(pairData, pairsData, Markupprice, 'pairData,pairsData,Markupprice')
  return (
    <>
      <div className={shrink ? "d-flex gap-3 shrink_div main_div_col" : "d-flex gap-3 main_div_col"}>
        <Sidebar setWhole={setWhole} activetabkey={"0"} id="side_trade" />
        {posconFirm &&
          <ConfirmModal title='Close All open position'
            message='Do you want to close all opened position'
            onDismiss={() => setposconFirm(false)}
            onCofirm={(accountId) => { CloseAllPosition() }}
            id={useraccount.accountId}
            loader = {confirmLoader}
          />
        }
        {Marginpop &&
          <ConfirmModal title='Margin Level Alert'
            message='By proceeding with this order, your Margin Level will drop below the critical threshold of 100%. You have two options:'
            message1='1. Make an additional deposit to meet the margin requirements for this trade.'
            message2='2. Opt for a smaller order size to reduce this risk.'
            onDismiss={() =>{setMarginpop(false)}}
            loader = {confirmLoader}
          // onCofirm={() => { history.push("/tradedashboard/deposit") }}
          />
        }
        {conFirm &&
          <ConfirmModal title='Close All open orders'
            message='Do you want to close all opened orders'
            onDismiss={() => setconFirm(false)}
            onCofirm={(accountId) => { CancelTrade() }}
            id={useraccount.accountId}
            loader = {confirmLoader}
          />
        }
        {errorShow &&
          <ErrorModal
            respone={errorres}
            onDismiss={() => setErrorShow(false)}
          />
        }
        {neworder && <NewOrder pairData={pairData} onDismiss={() => setNewOrder(false)} />}
        {doneorder == true ? <DoneOrder onDismiss={() => setDoneOrder(false)} Data={History} Count={doneordercnt} /> : ''}
        <div className={shrinkcont ? "d-flex w-full calc_width shrinkcont" : "d-flex w-full calc_width expandcont"}>

          <div className="pr-small left">
            <div className="trand-cont">
              <div className={bottompop ? "graybox p-1 chartloader position-relative shrinktop" : "graybox p-1 chartloader  position-relative"} >
                <div className={displayLoader ? "loader d-flex" : 'loader d-none'} id="loader" onchange={() => { showloader() }}>
                  <img src={loaderimg} />
                </div>
                {/* :
             <div className="loader" id="loader" onchange={()=>{hideloader()}}></div> } */}
                {/* {pairList && pairList === undefined || pairList === null || pairList === ""  ?
             <div className="loader" id="loader" onchange={()=>{showloader()}}></div> :
             <div className="loader" id="loader" onchange={()=>{hideloader()}}></div> } */}
                <Chart onSet={() => (setChart(true))} />

              </div>
              <div className=" position-relative p-0 mt-1">
                <button className="btn bottombtn p-1" onClick={() => setBottompop(!bottompop)}>
                  <span className={bottompop ? "fa fa-chevron-down" : "fa fa-chevron-up"}>
                  </span>
                </button>
                <Tabs defaultActiveKey="position" id="uncontrolled-tab-example" className="graytab pt-2 px-0 withot_wrap ">

                  <Tab eventKey="position" title={position} className=" position-relative">
                    <div className="d-flex align-items-center gap-2 px-0">
                      <InputGroup className="themeinput">
                        <FormControl placeholder="" value={positionsearch}
                          onChange={(e) => {
                            Setpositionpage(1);
                            handlpositionfilter(e?.target?.value, alldirection, aggregated);
                          }} />
                        <InputGroup.Append>
                          <InputGroup.Text id="basic-addon2"><span className="fa fa-search"></span></InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                      <select className="selectcount themeselect w-full" value={alldirection}
                        onChange={(e) => { Setpositionpage(1); handlpositionfilter(positionsearch, e.target.value, aggregated) }}
                      >
                        <option value="">All Direction (Default)</option>
                        <option value="Buy">Buy</option>
                        <option value="Sell">Sell</option>
                      </select>
                      {/* <select className="selectcount themeselect w-full"
                        onChange={(e) => { handlpositionfilter(positionsearch, alldirection, e.target.value) }}
                      >
                        <option value="Not Aggregated">Not Aggregated (Default)</option>
                        <option value="Aggregated">Aggregated</option>

                      </select> */}
                      <div className="d-flex align-items-center">
                        {positionorder && positionCount > positionorder?.length && positionCount > 10 ?
                          loadmoreloader ?
                            <button className="text-light btn m-0 py-1 px-3" disabled>
                              <i className="fa fa-spinner mr-2"></i>
                              <span>Loading..</span>
                            </button> :
                            <button className="text-light btn m-0 py-1 px-3"
                              onClick={() => {
                                setloadmoreloader(true)
                                let userAccountID = localStorage.getItem('accounts')
                                let pageSize = positionPage + 1
                                Setpositionpage(pageSize)
                                let objData = {
                                  accountId: useraccount?.accountId ? userAccountID : useraccount?.accountId,
                                  pageSize: pageSize,
                                  direction: alldirection,
                                  pair: positionsearch
                                }
                                fetchPositionOrder(objData)
                              }}>
                              Load More
                            </button>
                          : ""}
                      </div>
                      <button type="button"
                        onClick={() => { setposconFirm(true) }}
                        className="btn btn-primary text-uppercase py-2 btn-sm-blue-table btn_rem_focus"
                        disabled={!positionorder?.length > 0}
                      >
                        Close All
                      </button>
                    </div>

                    <div className={bottompop ? "viji darkbox h-200 table_scroll_color_new scrollbar_eable_br" : "viji darkbox d-none table_scroll_color_new scrollbar_eable_br"} >
                      <div className="table-responsive p-0">
                        <table className="w-full">
                          <thead>
                            <tr>
                              <th></th>
                              <th><label>S NO</label></th>
                              <th><label>Created </label></th>
                              <th><label>Symbol </label></th>
                              <th><label>Quantity </label></th>
                              <th><label>Direction </label></th>
                              <th><label>Entry Price </label></th>
                              <th><label>Market Price</label></th>
                              <th><label>T/P</label></th>
                              <th><label>S/L</label></th>
                              <th><label>Gross P/L</label></th>
                              <th><label>Swap</label></th>
                              <th><label>Commission</label></th>
                              <th><label>Net USD</label></th>
                              <th><label>Action</label></th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* {fillterposition ? fpositinorder && */
                              // fpositinorder && fpositinorder?.length > 0 ? fpositinorder.map((val, index) => {
                              //   // console.log(fpositinorder,fillterposition,'fpositinorder,fillterposition')
                              //   let pairDetail = pairList.find((value) => (value.tiker_root === val.pairName))
                              //   return (
                              //     <Positionorder
                              //       val={val}
                              //       pairDetail={pairDetail}
                              //       handleMarketSubmit={handleMarketSubmit}
                              //       loader={loader}
                              //       index={index}
                              //       setpAndLarr={setpAndLarr}
                              //       pAndLarr={pAndLarr}
                              //     />
                              //   )
                              // })
                              // :
                              // <tr><td colspan={10} className="text-center">There is no data</td></tr>
                              // :
                              positionorder && positionorder.length > 0 ? positionorder.map((val, index) => {
                                let pairDetail = pairList.find((value) => (value.tiker_root === val.pairName))
                                return (
                                  val && <Positionorder
                                    val={val}
                                    pairDetail={pairDetail}
                                    handleMarketSubmit={handleMarketSubmit}
                                    loader={loader}
                                    index={index}
                                    setpAndLarr={setpAndLarr}
                                    pAndLarr={pAndLarr}
                                  />
                                )
                              })
                                :
                                <tr><td colspan={15} className="text-center"><div className="centerdiv"><p>There is no data</p></div></td></tr>
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="statlist pb-0 fleex_bet_stalist">

                      <div className="" data-tip data-for='delete-btn'>
                        <label>Balance:</label>
                        <p>USD {useraccount && useraccount?.balance ? toLocaleString(parseFloat(useraccount?.balance.toFixed(2))) : 0}</p>
                        <ReactTooltip id='delete-btn' place='top'>
                          Balance = Initial Deposit - Total Realized Net Loss
                          {/* <br/>  */}
                          + Total Realized Net Profit + Deposits - Withdrawals
                        </ReactTooltip>
                      </div>

                      <div className="" data-tip data-for='delete-btn1'>
                        <label>Equity:</label>
                        <p>USD {Equtitypre ? toLocaleString(parseFloat(Equtitypre.toFixed(2))) : 0}</p>
                        <ReactTooltip id='delete-btn1' place='top'>
                          Equity = Balance - Unrealized Net Loss
                          {/* <br/> */}
                          + Unrealized Net Profit + Bonus
                        </ReactTooltip>
                      </div>

                      <div className="" data-tip data-for='delete-btn2'>
                        <label>Margin:</label>
                        <p>USD {Margin ? toLocaleString(parseFloat(Margin.toFixed(2))) : 0}</p>
                        <ReactTooltip id='delete-btn2' place='top'>
                          Margin = Sum of all open Positions' Margin.
                          {/* <br />
                          Position Margin = (Position Volume / Leverage),
                          <br />
                          denominated to account currency. */}
                        </ReactTooltip>
                      </div>

                      <div className="" data-tip data-for='delete-btn3'>
                        <label>Free Margin:</label>
                        <p>USD {freemagins ? toLocaleString(parseFloat(freemagins.toFixed(2))) : 0}</p>
                        <ReactTooltip id='delete-btn3' place='top'>
                          Free Margin = Equity - Margin Used
                        </ReactTooltip>
                      </div>

                      <div className="" data-tip data-for='delete-btn4'>
                        <label>Margin Level:</label>
                        <p>{marginlevels ? toLocaleString(parseFloat(marginlevels.toFixed(2))) : 0} %</p>
                        <ReactTooltip id='delete-btn4' place='top'>
                          Margin Level = (Equity - estimated Closing Commission) /<br /> Margin Used
                          {/* expressed as a percentage. <br />When your Margin Level is equal to the Stop Out<br /> Level your positions get stopped out. */}
                        </ReactTooltip>
                      </div>

                      {/* <div className="">
                      <label>Smart Stop:</label>
                      <p>USD 911.12</p>
                    </div> */}
                      <div className="" data-tip data-for='delete-btn5'>
                        <label>Unr. Net P&L:</label>
                        <p className={clsx({ "greenText": profitandloss >= 0 }, { "redText": profitandloss < 0 })}>{profitandloss ? toLocaleString(parseFloat(profitandloss.toFixed(2))) : 0}</p>
                        <ReactTooltip id='delete-btn5' place='top'>
                          Your Unrealized P&L after expenses
                          {/* <br />  */}
                          (Commissions and Swaps)
                        </ReactTooltip>
                      </div>

                    </div>
                  </Tab>

                  <Tab eventKey="history" title="History" className="pt-0">
                    <div className="d-flex jc-between gap-2 px-0 py-0 block_mob_res">
                      <div className="d-flex align-items-center gap-2 py-0 block_mob_res mob_res_scrol_blk block_mob_res_scroll_on_ipad">
                        <InputGroup className="themeinput" onChange={e => { handlehistoryfilter(e.target.value, historyday, historystart, historyend, 1) }}>
                          <FormControl placeholder="" />
                          <InputGroup.Append>
                            <InputGroup.Text id="basic-addon2"><span className="fa fa-search"></span></InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                        <div className={"d-flex gap-2 py-0 block_mob_res"} onChange={(e) => { disableCheckDiv(e) }}>
                          <Form.Check type="radio" className="mb-0" >
                            <Form.Check.Input id="check-api-radio" type="radio" name="radio" checked={ishistoryday} isValid onChange={e => {
                              setIshistoryday(true);
                              setIshistorydate(false);
                              setOpenorder(setBopenorder);
                            }} />
                            <Form.Check.Label for="check-api-radio" id="diable_check_id_1">
                              <select className="darkselect selectcount selctsm" onChange={e => handlehistoryfilter(historysearch, e.target.value, historystart, historyend, 1)}>
                                <option value="">All History</option>
                                <option value={Date.now() - (86400000 * 3)}>Last 3 Days</option>
                                <option value={Date.now() - (86400000 * new Date().getDay())}>Current Week</option>
                                <option value={Date.now() - (86400000 * (new Date().getDay() + 7))} >Last 2 Week</option>
                                <option value={Date.now() - (86400000 * (new Date().getDay() + 14))}>Last 3 Week</option>
                                <option value={getmonth("Current Month")}>Current Month</option>
                                <option value={getmonth("Last 2 Month")}>Last 2 Month</option>
                                <option value={getmonth("Last 3 Month")}>Last 3 Month</option>
                              </select>
                            </Form.Check.Label>

                          </Form.Check>
                          <Form.Check type="radio" className="mb-0" >
                            <Form.Check.Input id="check-api-radio1" type="radio" name="radio" isValid checked={ishistorydate} className="mb-0 disable_chk"
                              onChange={e => { setIshistorydate(e.target.checked); setIshistoryday(false) }} />
                            <Form.Check.Label for="check-api-radio1" className="mb-0 disable_chk" id="diable_check_id_2">
                              <div className="d-flex gap-2 tabinput p-0">
                                <input type="date" className="themeinput py-1" max={new Date()} onChange={e => handlehistoryfilter(historysearch, historyday, e.target.value, historyend, 1)} />
                                <input type="date" className="themeinput py-1" max={new Date()} onChange={e => handlehistoryfilter(historysearch, historyday, historystart, e.target.value, 1)} />
                              </div>
                            </Form.Check.Label>
                          </Form.Check>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="d-flex align-items-center">
                          {console.log("loadmore", loadhistory, closedhistoryCnt)}
                          {loadhistory === true && closedhistoryCnt > closed_position?.length ?
                            loadmoreloader ?
                              <button className="text-light btn m-0 py-1 px-3" disabled>
                                <i className="fa fa-spinner mr-2"></i>
                                <span>Loading..</span>
                              </button>
                              :
                              <button className="text-light btn m-0 py-1 px-3" onClick={() => { handlehistoryLoadmore(historysearch, historyday, historystart, historyend, closedpage + 1) }}>
                                Load More
                              </button>
                            : ""}
                        </div>

                        <div className="d-flex align-items-center">
                          <button className="text-light btn m-0 py-1 px-3 my-1" onClick={() => setDoneOrder(true)}>Done Orders</button>
                        </div>
                      </div>
                    </div>
                    <button className="btn bottombtn p-1" onClick={() => setBottompop(!bottompop)}><span className={bottompop ? "fa fa-chevron-down" : "fa fa-chevron-up"}></span></button>
                    <div className={bottompop ? "viji darkbox h-200 table_scroll_color_new scrollbar_eable_br" : "viji darkbox d-none table_scroll_color_new scrollbar_eable_br"} >
                      <div className="table-responsive p-0">
                        <table className="w-full">
                          <tr>
                            <th></th>
                            <th><label>Created(UTC + 3)</label></th>
                            <th><label>Symbol</label></th>
                            <th><label>Direction</label></th>
                            <th><label>Entry Price</label></th>
                            <th><label>Closing Price</label></th>
                            <th><label>Closing Quantity</label></th>
                            <th><label>Gross P/L</label></th>
                            <th><label>Swap</label></th>
                            <th><label>Commission</label></th>
                            <th><label>Net USD</label></th>
                            <th><label>Balance USD</label></th>
                            <th></th>
                          </tr>
                          <tbody>
                            {/* {console.log('usertrade-useEffect', closed_position)} */}
                            {
                              closed_position && closed_position.length > 0 && closed_position.map((item, key) => {
                                let pairDetail = pairList.find((value) => (value.tiker_root === item.pairname))
                                let closingFee = parseFloat(item.closingFee) + parseFloat(item.commission)
                                // console.log(pairDetail, item.afterBalance, 'pairDetail,item.afterBalance')
                                return (
                                  <tr >
                                    {/* <td>
                                    <button className="graybtn"><img src={setting} /></button>
                                  </td> */}
                                    <td></td>
                                    <td>{momentFormat(item.createdDate, 'DD-MM-YYYY HH:mm')}</td>
                                    <td>{item.pairname}</td>
                                    <td className={clsx({ "greenText": item.buyorsell === 'buy' }, { "redText": item.buyorsell == 'sell' })}>{capitalize(item.closing_direction)}</td>
                                    <td>{(item?.entry_price == undefined || item?.entry_price == null || isNaN(item?.entry_price)) ? item?.entry_price : (parseFloat(item.entry_price.toFixed(pairDetail?.precision)))}</td>
                                    <td>{(item?.exit_price == undefined || item?.exit_price == null || isNaN(item?.exit_price)) ? item?.exit_price : (parseFloat(item.exit_price.toFixed(pairDetail?.precision)))}</td>
                                    <td>{(item?.quantity == undefined || item?.quantity == null || isNaN(item?.quantity)) ? item?.quantity : lotDisplay(item.quantity, pairDetail, item?.exit_price).toFixed(2)}</td>
                                    <td className={clsx({ "greenText": item?.grossPandL > 0 }, { "redText": item?.grossPandL < 0 })}>{(item?.grossPandL == undefined || item?.grossPandL == null || isNaN(item?.grossPandL)) ? new Number(0).toPrecision(3) : item?.grossPandL == 0 ? (new Number(item.grossPandL).toPrecision(3)) : parseFloat(item.grossPandL).toFixed(2)}</td>
                                    <td className={clsx({ "greenText": item?.swapFee > 0 }, { "redText": item?.swapFee < 0 })}>
                                      {(item?.swapFee == undefined || item?.swapFee == null || isNaN(item?.swapFee))
                                        ? new Number(0).toPrecision(3) :
                                        item?.swapFee == 0 ?
                                          (new Number(item.swapFee).toPrecision(3))
                                          :
                                          parseFloat(item.swapFee).toFixed(2)}
                                    </td>
                                    <td className={clsx({ "greenText": item?.closingFee > 0 }, { "redText": item?.closingFee < 0 })}>
                                      {((closingFee).toFixed(2) == 0 ?
                                        new Number(closingFee).toPrecision(3) :
                                        (closingFee).toFixed(2))}
                                    </td>
                                    <td className={clsx({ "greenText": item?.profitnloss > 0 }, { "redText": item?.profitnloss < 0 })}>{(item?.profitnloss == undefined || item?.profitnloss == null || isNaN(item?.profitnloss)) ? new Number(0).toPrecision(3) : item?.profitnloss == 0 ? (new Number(item.profitnloss).toPrecision(3)) : parseFloat(item.profitnloss).toFixed(2)}</td>
                                    <td>{(item?.afterBalance == undefined || item?.afterBalance == null || isNaN(item?.afterBalance)) ? item?.afterBalance : toLocaleString(parseFloat(item?.afterBalance).toFixed(2))}</td>
                                    {/* <td>{toFixed(item?.afterBalance, 4)}</td> */}
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="statlist pb-0 fleex_bet_stalist">

                      <div className="" data-tip data-for='delete-btn11'>
                        <label>Balance:</label>
                        <p>USD {useraccount && useraccount?.balance ? toLocaleString(parseFloat(useraccount?.balance.toFixed(2))) : 0}</p>
                        <ReactTooltip id='delete-btn11' place='top'>
                          Balance = Initial Deposit - Total Realized Net Loss
                          {/* <br/>  */}
                          + Total Realized Net Profit + Deposits - Withdrawals
                        </ReactTooltip>
                      </div>

                      <div className="" data-tip data-for='delete-btn12'>
                        <label>Equity:</label>
                        <p>USD {Equtitypre ? toLocaleString(parseFloat(Equtitypre.toFixed(2))) : 0}</p>
                        <ReactTooltip id='delete-btn12' place='top'>
                          Equity = Balance - Unrealized Net Loss
                          {/* <br/> */}
                          + Unrealized Net Profit + Bonus
                        </ReactTooltip>
                      </div>


                      <div className="" data-tip data-for='delete-btn13'>
                        <label>Margin:</label>
                        <p>USD {Margin ? toLocaleString(parseFloat(Margin.toFixed(2))) : 0}</p>
                        <ReactTooltip id='delete-btn13' place='top'>
                          Margin = Sum of all open Positions' Margin.
                          {/* <br /> */}
                          {/* Position Margin = (Position Volume / Leverage), */}
                          {/* <br /> */}
                          {/* denominated to account currency. */}
                        </ReactTooltip>
                      </div>

                      <div className="" data-tip data-for='delete-btn14'>
                        <label>Free Margin:</label>
                        <p>USD {freemagins ? toLocaleString(parseFloat(freemagins.toFixed(2))) : 0}</p>
                        <ReactTooltip id='delete-btn14' place='top'>
                          Free Margin = Equity - Margin Used
                        </ReactTooltip>
                      </div>

                      <div className="" data-tip data-for='delete-btn15'>
                        <label>Margin Level:</label>
                        <p>{marginlevels ? toLocaleString(parseFloat(marginlevels.toFixed(2))) : 0} %</p>
                        <ReactTooltip id='delete-btn15' place='top'>
                          Margin Level = (Equity - estimated Closing Commission) /<br /> Margin Used
                          {/* expressed as a percentage. <br />When your Margin Level is equal to the Stop Out<br /> Level your positions get stopped out. */}
                        </ReactTooltip>
                      </div>

                      {/* <div className="">
                      <label>Smart Stop:</label>
                      <p>USD 911.12</p>
                    </div> */}
                      <div className="" data-tip data-for='delete-btn16'>
                        <label>Unr. Net P&L:</label>
                        <p className={clsx({ "greenText": profitandloss >= 0 }, { "redText": profitandloss < 0 })}>{profitandloss ? toLocaleString(parseFloat(profitandloss.toFixed(2))) : 0}</p>
                        <ReactTooltip id='delete-btn16' place='top'>
                          Your Unrealized P&L after expenses
                          {/* <br />  */}
                          (Commissions and Swaps)
                        </ReactTooltip>
                      </div>

                    </div>
                  </Tab>

                  <Tab eventKey="orders" title={orders}>
                    <div className="d-flex jc-between gap-2 px-0 py-0">
                      <div className="d-flex align-items-center gap-2 py-0">
                        <InputGroup className="themeinput">
                          <FormControl placeholder="" value={ordersearch}
                            // onChange={(e) => handleorderfilter(e?.target?.value)}
                            onChange={(e) => SearchOrderData(e?.target?.value, 1)}
                          />
                          <InputGroup.Append>
                            <InputGroup.Text id="basic-addon2"><span className="fa fa-search"></span></InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </div>

                      <div className="d-flex py-0">
                        <div className="d-flex align-items-center">
                          {loadOrder === true && orderHistoryCnt > openorder?.length ? loadmoreloader ?
                            <button className="text-light btn m-0 py-1 px-3" disabled>
                              <i className="fa fa-spinner mr-2"></i>
                              <span>Loading..</span>
                            </button> :
                            <button className="text-light btn m-0 py-1 px-3" onClick={() => { fetchOrderData(ordersearch, page + 1) }}>Load More</button>
                            :
                            ""}
                        </div>

                        <div className="d-flex align-items-center">
                          {/* <button className='text-light btn m-0 py-1 px-3 ' onClick={() => setNewOrder(true)}>New Order</button> */}
                          <button type="button"
                            onClick={() => { setconFirm(true) }}
                            className="btn btn-primary text-uppercase py-2 btn-sm-blue-table btn_rem_focus py-0"
                            disabled={!openorder?.length > 0}
                          >
                            Close All
                          </button>
                          {/* <button className="text-light btn m-0 py-1 px-3">New Order</button> */}
                        </div>
                      </div>
                    </div>
                    <button className="btn bottombtn p-1" onClick={() => setBottompop(!bottompop)}><span className={bottompop ? "fa fa-chevron-down" : "fa fa-chevron-up"}></span></button>
                    <div className={bottompop ? "viji darkbox h-200 table_scroll_color_new scrollbar_eable_br" : "viji darkbox d-none table_scroll_color_new scrollbar_eable_br"}>
                      <div className="table-responsive p-0">
                        <table className="w-full">
                          <tr>
                            <th>
                              {/* <button className="graybtn" onClick={() => setInfoOrder(true)}><img src={infoimg} /></button> */}
                            </th>
                            <th><label>S NO</label></th>
                            <th><label>Symbol</label></th>
                            <th><label>Direction</label></th>
                            <th><label>Date And Time</label></th>
                            <th><label>Ordertype</label></th>
                            <th><label>Current Quantity</label></th>
                            <th><label>Submitted Price</label></th>
                            <th><label>T/P</label></th>
                            <th><label>S/L</label></th>
                            <th><label>Action</label></th>
                            {/* <th><label>Quantity USD</label></th> */}
                            <th></th>

                          </tr>
                          <tbody>
                            {
                              openorder && openorder.length > 0 && openorder.map((item, key) => {
                                var remainingQuantity = item.quantity - item.filledAmount
                                let pairDetail = pairList.find((value) => (value.tiker_root === item.pairName))
                                // console.log("remainingQuantity-----------?", remainingQuantity);
                                return (
                                  <tr >
                                    <td><button className="graybtn" onClick={() => { setInfopairData(pairDetail); setInfoData(item); setInfoOrder(true) }}><img src={infoimg} /></button></td>
                                    <td>{key + 1}</td>
                                    <td>{item.pairName}</td>
                                    <td className={clsx({ "greenText": item.buyorsell === 'buy' }, { "redText": item.buyorsell == 'sell' })}>{capitalize(item.buyorsell)}</td>
                                    {/* <td>{item.buyorsell}</td>  */}
                                    {/* <td> {item.orderDate}</td> */}
                                    <td>{momentFormat(item.orderDate, 'DD-MM-YYYY HH:mm')}</td>
                                    {/* <td>{item.filledAmount}/{remainingQuantity}</td> */}
                                    <td>{item.orderType}</td>
                                    <td>{(item?.quantity == undefined || item?.quantity == null || isNaN(item?.quantity)) ? 0 : lotDisplay(remainingQuantity, pairDetail, item?.price).toFixed(2)}</td>
                                    <td>{(item?.price == undefined || item?.price == null || isNaN(item?.price)) ? item?.price : (parseFloat(item.price).toFixed(pairDetail?.precision))}</td>
                                    <td>{(item?.takeProfitPrice == undefined || item?.takeProfitPrice == null || isNaN(item?.takeProfitPrice)) ? 0 : item.takeProfitPrice == 0 ? (new Number(item?.takeProfitPrice).toPrecision(3)) : parseFloat(item.takeProfitPrice).toFixed(pairDetail.precision)}</td>
                                    <td>{(item?.stopLossPrice == undefined || item?.stopLossPrice == null || isNaN(item?.stopLossPrice)) ? 0 : item.stopLossPrice == 0 ? (new Number(item?.stopLossPrice).toPrecision(3)) : parseFloat(item.stopLossPrice).toFixed(pairDetail.precision)}</td>
                                    <td><button type="button" className="bluebtn orange" onClick={() => { handleCancelOrder(item._id) }}>

                                      {loader && <i className="fas fa-spinner fa-spin"></i>}
                                      <i className="fas fa-times text-white px-2"></i>
                                      {/* <button className="bluebtn green"><img src={close} /></button> */}
                                      {/* {t("CANCEL")} */}
                                    </button></td>
                                  </tr>
                                )
                              })
                            }
                            {
                              !openorder && openorder && openorder.length === 0 && <tr>
                                <td colspan="10" height="150" className="text-center">- {t('NO_DATA')} -</td>
                              </tr>
                            }

                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="statlist pb-0 fleex_bet_stalist">

                      <div className="" data-tip data-for='delete-btn21'>
                        <label>Balance:</label>
                        <p>USD {useraccount && useraccount?.balance ? toLocaleString(parseFloat(useraccount?.balance.toFixed(2))) : 0}</p>
                        <ReactTooltip id='delete-btn21' place='top'>
                          Balance = Initial Deposit - Total Realized Net Loss
                          {/* <br/>  */}
                          + Total Realized Net Profit + Deposits - Withdrawals
                        </ReactTooltip>
                      </div>


                      <div className="" data-tip data-for='delete-btn22'>
                        <label>Equity:</label>
                        <p>USD {Equtitypre ? toLocaleString(parseFloat(Equtitypre.toFixed(2))) : 0}</p>
                        <ReactTooltip id='delete-btn22' place='top'>
                          Equity = Balance - Unrealized Net Loss
                          {/* <br/> */}
                          + Unrealized Net Profit + Bonus
                        </ReactTooltip>
                      </div>

                      <div className="" data-tip data-for='delete-btn23'>
                        <label>Margin:</label>
                        <p>USD {Margin ? toLocaleString(parseFloat(Margin.toFixed(2))) : 0}</p>
                        <ReactTooltip id='delete-btn23' place='top'>
                          Margin = Sum of all open Positions' Margin.
                          {/* <br /> */}
                          {/* Position Margin = (Position Volume / Leverage), */}
                          {/* <br /> */}
                          {/* denominated to account currency. */}
                        </ReactTooltip>
                      </div>

                      <div className="" data-tip data-for='delete-btn24'>
                        <label>Free Margin:</label>
                        <p>USD {freemagins ? toLocaleString(parseFloat(freemagins.toFixed(2))) : 0}</p>
                        <ReactTooltip id='delete-btn24' place='top'>
                          Free Margin = Equity - Margin Used
                        </ReactTooltip>
                      </div>

                      <div className="" data-tip data-for='delete-btn25'>
                        <label>Margin Level:</label>
                        <p>{marginlevels ? toLocaleString(parseFloat(marginlevels.toFixed(2))) : 0} %</p>
                        <ReactTooltip id='delete-btn25' place='top'>
                          Margin Level = (Equity - estimated Closing Commission) /<br /> Margin Used
                          {/* expressed as a percentage. <br />When your Margin Level is equal to the Stop Out<br /> Level your positions get stopped out. */}
                        </ReactTooltip>
                      </div>

                      {/* <div className="">
                      <label>Smart Stop:</label>
                      <p>USD 911.12</p>
                    </div> */}

                      <div className="" data-tip data-for='delete-btn26'>
                        <label>Unr. Net P&L:</label>
                        <p className={clsx({ "greenText": profitandloss >= 0 }, { "redText": profitandloss < 0 })}>{profitandloss ? toLocaleString(parseFloat(profitandloss.toFixed(2))) : 0}</p>
                        <ReactTooltip id='delete-btn26' place='top'>
                          Your Unrealized P&L after expenses
                          {/* <br />  */}
                          (Commissions and Swaps)
                        </ReactTooltip>
                      </div>

                    </div>
                  </Tab>
                  <Tab eventKey="transactions" title="Transactions">
                    <div className="d-flex jc-between gap-2 px-0 py-0 flex_mob_blk_res my-1">
                      <div className="d-flex align-items-center gap-2">
                        <InputGroup className="themeinput">
                          <FormControl placeholder="" onChange={(e) => handletransactionfilter(e?.target?.value, transactiontype, 1)} />
                          <InputGroup.Append>
                            <InputGroup.Text id="basic-addon2"><span className="fa fa-search"></span></InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                        <select className="darkselect selectcount py-1" onChange={e => handletransactionfilter(transactionsearch, e.target.value, 1)}>
                          <option value="">All transaction(Default)</option>
                          <option value="fiat_deposit">Fiat Deposit</option>
                          <option value="fiat_withdraw">Fiat Withdraw</option>
                        </select>
                      </div>
                      <div className="d-flex align-items-center">
                        {loadTransaction === true && transactioncount > transaction?.length ?
                          loadmoreloader ?
                            <button className="text-light btn m-0 py-1 px-3" disabled>
                              <i className="fa fa-spinner mr-2"></i>
                              <span>Loading..</span>
                            </button> :
                            <button className="text-light btn m-0 py-1 px-3" onClick={() => { handletransactionLoadmore(transactionsearch, transactiontype) }}>Load More</button> : ""}
                      </div>

                      <div className="d-flex align-items-center">
                        <Link to="/tradedashboard/withdraw"><button className="text-light btn m-0 py-1 px-3 mr-2 btn-success">Withdraw</button></Link>
                        <Link to="/tradedashboard/deposit"> <button className="text-light btn m-0 py-1 px-3 btn-success">Deposit</button></Link>
                      </div>
                    </div>
                    <button className="btn bottombtn p-1" onClick={() => setBottompop(!bottompop)}><span className={bottompop ? "fa fa-chevron-down" : "fa fa-chevron-up"}></span></button>
                    <div className={bottompop ? "viji darkbox h-200 table_scroll_color_new scrollbar_eable_br" : "viji darkbox d-none table_scroll_color_new scrollbar_eable_br"}>
                      <div className="table-responsive p-0">
                        <table className="w-full">
                          <tr>
                            <th><label>ID</label></th>
                            <th><label>Time(UTC + 3)</label></th>
                            <th><label>Type</label></th>
                            <th><label>Gross USD</label></th>
                            {/* <th><label>Note</label></th> */}
                          </tr>
                          <tbody>
                            {transaction && transaction.length > 0 ? transaction.map((val, index) => (

                              <tr>
                                <td> {val._id} </td>
                                <td> {momentFormat(val.createdAt, 'DD-MM-YYYY HH:mm')} </td>
                                <td>{val.paymentType == 'fiat_deposit' ? "Fiat Deposit" : "Fiat Withdraw"}</td>
                                <td className={clsx({ "greenText": val?.actualAmount >= 0 }, { "redText": val?.actualAmount < 0 })}>{(val?.actualAmount == undefined || val?.actualAmount == null || isNaN(val?.actualAmount)) ? 0 : toLocaleString(val.actualAmount)}</td>
                                <td>{val.toAddress}</td>
                              </tr>
                            )) : <tr><td colspan={5} className="text-center">There is no data</td></tr>

                            }

                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="statlist pb-0 fleex_bet_stalist">

                      <div className="" data-tip data-for='delete-btn31'>
                        <label>Balance:</label>
                        <p>USD {useraccount && useraccount?.balance ? toLocaleString(parseFloat(useraccount?.balance.toFixed(2))) : 0}</p>
                        <ReactTooltip id='delete-btn31' place='top'>
                          Balance = Initial Deposit - Total Realized Net Loss
                          {/* <br/>  */}
                          + Total Realized Net Profit + Deposits - Withdrawals
                        </ReactTooltip>
                      </div>

                      <div className="" data-tip data-for='delete-btn32'>
                        <label>Equity:</label>
                        <p>USD {Equtitypre ? toLocaleString(parseFloat(Equtitypre.toFixed(2))) : 0}</p>
                        <ReactTooltip id='delete-btn32' place='top'>
                          Equity = Balance - Unrealized Net Loss
                          {/* <br/> */}
                          + Unrealized Net Profit + Bonus
                        </ReactTooltip>
                      </div>

                      <div className="" data-tip data-for='delete-btn33'>
                        <label>Margin:</label>
                        <p>USD {Margin ? toLocaleString(parseFloat(Margin.toFixed(2))) : 0}</p>
                        <ReactTooltip id='delete-btn33' place='top'>
                          Margin = Sum of all open Positions' Margin.
                          {/* <br /> */}
                          {/* Position Margin = (Position Volume / Leverage), */}
                          {/* <br /> */}
                          {/* denominated to account currency. */}
                        </ReactTooltip>
                      </div>

                      <div className="" data-tip data-for='delete-btn34'>
                        <label>Free Margin:</label>
                        <p>USD {freemagins ? toLocaleString(parseFloat(freemagins.toFixed(2))) : 0}</p>
                        <ReactTooltip id='delete-btn34' place='top'>
                          Free Margin = Equity - Margin Used
                        </ReactTooltip>
                      </div>

                      <div className="" data-tip data-for='delete-btn35'>
                        <label>Margin Level:</label>
                        <p>{marginlevels ? toLocaleString(parseFloat(marginlevels.toFixed(2))) : 0} %</p>
                        <ReactTooltip id='delete-btn35' place='top'>
                          Margin Level = (Equity - estimated Closing Commission) /<br /> Margin Used
                          {/* expressed as a percentage. <br />When your Margin Level is equal to the Stop Out<br /> Level your positions get stopped out. */}
                        </ReactTooltip>
                      </div>

                      {/* <div className="">
                      <label>Smart Stop:</label>
                      <p>USD 911.12</p>
                    </div> */}

                      <div className="" data-tip data-for='delete-btn36'>
                        <label>Unr. Net P&L:</label>
                        <p className={clsx({ "greenText": profitandloss >= 0 }, { "redText": profitandloss < 0 })}>{profitandloss ? toLocaleString(parseFloat(profitandloss.toFixed(2))) : 0}</p>
                        <ReactTooltip id='delete-btn36' place='top'>
                          Your Unrealized P&L after expenses
                          {/* <br />  */}
                          (Commissions and Swaps)
                        </ReactTooltip>
                      </div>

                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
          <div className="pl-0 pl_mob_res pr-0 mt-3 mt-md-0 right position-relative">
            <button className="btn rightpop" onClick={() => setShrinkcont(!shrinkcont)} ><span className={shrinkcont ? "fa fa-chevron-right" : "fa fa-chevron-left"}></span></button>
            <div className="graybox domtab graytab">
              <Tabs defaultActiveKey="symbol" id="uncontrolled-tab-example" className="graytab pt-2 px-1 withot_wrap rightwrap">
                <Tab eventKey="symbol" title="Symbol">
                  <div className="d-flex jc-between removeinshrink align-items-center wishdrop gap-2 px-0">
                    <div className='custdrop w-full px-0'>
                      <div className='d-flex gap-1 p-0 '>
                        <div className='drop w-full p-0' onClick={() => setSymbolTab(!symbolTab)}>
                          <p className='d-flex jc-between align-items-center p-1 f-12'>{pairsData && pairsData.current.displayname} <span className="fa fa-chevron-down"></span></p>
                          {/* {console.log("ppairdata", pairsData.current.displayname)} */}
                        </div>
                      </div>
                      <div className={symbolTab ? "d-block wishdrop themetab" : "d-none wishdrop themetab"}>
                        <Symboltab onClose={() => { setSymbolTab(false) }} />
                      </div>
                    </div>
                    {/* <button className="lightgraybtn"><img src={star} /></button> */}
                  </div>
                  <div className="darkbox h-100-calc-full new-drop-bx sectn_date_sm">
                    <Accordion className="calendar_accordion p-0" defaultActiveKey={"set0"}>
                      <Card>
                        <Card.Header className="p-0">
                          <Accordion.Toggle eventKey={"set0"}
                            // onClick={()=>{setIniialShow(!iniialShow)}}
                            as={Button}
                            variant="link"
                          >
                            <div className="py-0 card_symbol_acc">
                              <button onClick={() => setShrinkcont(true)} className="btn removeinexpand btn-link text-white card_symbol_acc text-center">
                                <i className="fa fa-plus f-12"></i>
                              </button>
                              <button className="btn btn-link removeinshrink text-white card_symbol_acc">
                                <i className="fa fa-plus mr-2 f-12"></i>
                                <span className="">New Order</span>
                              </button>
                            </div>
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={"set0"} className="removeinshrink"
                        // style={{display:iniialShow?"block":"none"}}
                        >
                          <Card.Body className="p-0">
                            <div className="d-flex jc-between text-white">

                              <small>
                                {" "}
                                <span className="fa fa-check text-light text-success">
                                  &nbsp;
                                </span>{" "}
                                {pairsData && pairsData.current.displayname}
                              </small>
                            </div>
                            <div className="themetab   px-0 new_order_tab">
                              <Tabs defaultActiveKey="market" className="px-0 market_down"
                                onSelect={async (e) => {
                                  let leverage
                                  if (pairData.tradestatus === 'xoh') {
                                    leverage = pairData?.leverage
                                  } else {
                                    leverage = useraccount.leverage
                                  }
                                  await initialDataSet(leverage, pairData, e, pairList)
                                  // setOrderType(e)
                                }}
                              >

                                <Tab
                                  eventKey="market"
                                  title="Market"
                                  className=" px-0"
                                >
                                  <div className="mt-2 px-0 d-flex flex-1 gap-2">
                                    <button className={buyorsell == "sell" ? "btn mb-0 pb-0 system_limit_sellLimit sellbtn sellbtn_active" : "btn mb-0 pb-0 system_limit_sellLimit sellbtn"}
                                      onClick={() => {
                                        setSellLoader(true)
                                        // handleSubmit('sell')
                                        // Setbuyorsell('sell')
                                        setMarketSellLoader(true);
                                        // setMarketValue(Marketprices)
                                        setMarketSellLoader(true);
                                        setFormValue({ ...formValue, ...{ 'buyorsell': 'sell', 'price': Markupprice && Markupprice?.bid ? Markupprice?.bid : pairData?.bid } })
                                      }}
                                    >
                                      <p>Sell</p>
                                      <p>{
                                        Markupprice?.bid ?
                                          isNaN(Markupprice?.disbid) ? 0 : Markupprice?.disbid :
                                          isNaN(pairsData?.current?.bid) ? 0 : pairsData?.current?.bid
                                      }
                                        <span className="text lastnumber">
                                          {Markupprice?.bid ?
                                            isNaN(Markupprice?.pipBid) ? 0 : Markupprice?.pipBid :
                                            isNaN(BidandAskDisplay(pairsData?.current?.bid, pairsData?.current?.precision)) ? '' : BidandAskDisplay(pairsData?.current?.bid, pairsData?.current?.precision)}
                                        </span></p>
                                    </button>


                                    <button className={buyorsell == "buy" ? "btn mb-0 pb-0 system_limit_buyLimit buybtn buybtn_active" : "btn mb-0 pb-0 system_limit_buyLimit buybtn"}
                                      onClick={() => {
                                        // setBuyLoader(true)
                                        // handleSubmit('buy')
                                        // Setbuyorsell('buy')
                                        setMarketBuyLoader(true)
                                        // setMarketValue(Marketprices)
                                        setFormValue({ ...formValue, ...{ 'buyorsell': 'buy', 'price': Markupprice && Markupprice?.ask ? Markupprice?.ask : pairData?.ask } })
                                      }}
                                    >
                                      <p>Buy</p>
                                      {Markupprice?.ask ?
                                        isNaN(Markupprice?.disask) ? 0 : Markupprice?.disask :
                                        isNaN(pairsData?.current?.ask) ? 0 : pairsData?.current?.ask
                                      }
                                      <span className="text lastnumber">
                                        {Markupprice?.ask ?
                                          isNaN(Markupprice?.pipAsk) ? 0 : Markupprice?.pipAsk :
                                          isNaN(BidandAskDisplay(pairsData?.current?.ask, pairsData?.current?.precision)) ? '' : BidandAskDisplay(pairsData?.current?.ask, pairsData?.current?.precision)}
                                      </span>
                                    </button>
                                  </div>
                                  <center>
                                    <small className="text-white">
                                      Spread:{Markupprice && Markupprice?.spread ? Markupprice?.spread : pairData?.spread};
                                      High: {Markupprice && Markupprice?.high ? Markupprice?.high : pairData?.high};
                                      Low: {Markupprice && Markupprice?.low ? Markupprice?.low : pairData?.low}
                                    </small>
                                  </center>

                                  <div className="px-0 font-12">
                                    <div className="row px-2 row_redc_foint">
                                      <div className="col-lg-4">
                                        <p className="font_label_sm">Volume</p>
                                        <div className='input_grp_number input_typ_gere px-0'>
                                          <input className="selectcount themeselect  w-full"
                                            type='text'
                                            name="quantity"
                                            min='0'
                                            value={volume}
                                            onChange={handleChange} />
                                          <div className='input-group-append font_sm_ap' onClick={() => { increaseVolume(volume) }}>
                                            <i className='fa fa-plus' />
                                          </div>
                                          <div className='input-group-append font_sm_ap' onClick={() => { decreaseVolume(volume) }}>
                                            <i className='fa fa-minus' />
                                          </div>
                                        </div>
                                        <p className="font_label_sm">Pip value : {pipValue.toFixed(2)} </p>
                                      </div>

                                      <div className="col-lg-4 px-lg-0">
                                        <p className="font_label_sm">
                                          <div className="d-flex jc-between px-0 pt-0"> Contract value <span className="f-10">{pairData.firstCurrencySymbol}</span></div>
                                          <div className="inputboxbg"> {quantity}</div> </p>
                                        <p className="font_label_sm">Commission : {pairData && pairData.commission} </p>
                                      </div>
                                      <div className="col-lg-4">
                                        <p className="font_label_sm">
                                          <div className="d-flex jc-between px-0 pt-0"> Margin
                                            <span className="f-10">USD</span></div>
                                          <div className="inputboxbg">{Est_Margin}</div> </p>
                                      </div>
                                    </div>
                                    <span className="text-danger">{errors && errors?.quantity}</span>
                                  </div>

                                  <div className="px-0 font-12">
                                    <p className="font_label_sm">Leverage</p>

                                    <div className="inputboxbg inputboxbg_gery_txt">{leverage && leverage}</div>

                                    {/* <select className="selectcount themeselect w-full"
                                    name="leverage"
                                    onChange={handleChange}
                                    disabled
                                  >
                                    <option value={leverage && leverage}>{leverage && leverage}</option>
                                  </select> */}
                                    <span className="text-danger">{errors && errors?.leverage}</span>
                                  </div>

                                  <div className="d-flex px-0 justify-content-between align-items-center row mx-0">
                                    <div className="px-0 d-flex col-5">
                                      <input type="checkbox" id="stop_loss_check" checked={stopopen}
                                        onChange={(e) => {
                                          e.target.checked == true ? setStopopen(true) : setStopopen(false);
                                          setFormValue({ ...formValue, ...{ 'stopprice': price } })
                                        }}
                                      />
                                      <label className='mb-0 ml-2'>Stop Loss</label>

                                    </div >
                                    <div className='px-0 d-flex col-5'>
                                      <input type="checkbox" id="take_profit_check" checked={takeopen}
                                        onChange={(e) => {
                                          e.target.checked == true ? setTakeopen(true) : setTakeopen(false);
                                          setFormValue({ ...formValue, ...{ 'takeprofit': price } })
                                        }} />
                                      <label className='mb-0 ml-2'>Take Profit</label>

                                    </div>

                                  </div>
                                  <div className="d-flex px-0 justify-content-between align-items-center row mx-0 pt-0">
                                    <div className="px-0 d-flex col-5 pt-0">
                                      <p className='text-danger f-10'>{errors && errors.stopprice}</p>
                                    </div>
                                    <div className="px-0 d-flex col-5 pt-0">
                                      <p className='text-danger f-10'>{errors && errors.takeprofit}</p>
                                    </div>
                                  </div>
                                  <div className="row px-2 py-0">
                                    <div className="col-5 col-sm-5 py-0">
                                      {stopopen ? <div className="form_open py-0" id="form_stop">
                                        <div className="row py-0">
                                          <div className="col-12 col-md-12 py-1">
                                            <div className='input_grp_number input_typ_gere p-0'>
                                              <input className="selectcount themeselect  w-full"
                                                type='text'
                                                name="stopprice"
                                                min='0'
                                                value={stopprice}
                                                onBlur={() => { setSEdit(false) }}
                                                onFocus={(e) => { setSEdit(true) }}
                                                onChange={handleChange} />
                                              <div className='input-group-append font_sm_ap' onClick={() => { increaseTpPrice('stopprice') }}>
                                                <i className='fa fa-plus' />
                                              </div>
                                              <div className='input-group-append font_sm_ap' onClick={() => { decreaseTpPrice('stopprice') }}>
                                                <i className='fa fa-minus' />
                                              </div>
                                            </div>
                                          </div>
                                          {console.log(errors, 'errors?.spPip')}
                                          <div className="col-12 col-md-12 py-1">
                                            <div className='input_grp_number input_typ_gere p-0'>
                                              <input className="selectcount themeselect  w-full"
                                                type='text'
                                                name="spPip"
                                                min='0'
                                                value={spPip}
                                                onChange={handleChange}
                                              />
                                              <div className='input-group-append font_sm_ap' onClick={() => { increasetpPip('stopprice') }}>
                                                <i className='fa fa-plus' />
                                              </div>
                                              <div className='input-group-append font_sm_ap' onClick={() => { decreasetpPip('stopprice') }}>
                                                <i className='fa fa-minus' />
                                              </div>
                                            </div>
                                            <span className="text-danger">{errors && errors?.spPip}</span>
                                          </div>
                                          <div className="col-12 col-md-12 py-1">
                                            <div className='input_grp_number input_typ_gere p-0'>
                                              <input className="selectcount themeselect  w-full"
                                                type='text'
                                                name="spUsd"
                                                min='0'
                                                value={spUsd}
                                                onChange={handleChange}
                                              />
                                              <div className='input-group-append font_sm_ap' onClick={() => { increasetpUsd('stopprice') }}>
                                                <i className='fa fa-plus' />
                                              </div>
                                              <div className='input-group-append font_sm_ap' onClick={() => { decreasetpUsd('stopprice') }}>
                                                <i className='fa fa-minus' />
                                              </div>
                                            </div>
                                            <span className="text-danger">{errors && errors?.spUsd}</span>
                                          </div>
                                          {/* <div className="col-12 col-md-12 py-1">
                                          <div className='input_grp_number input_typ_gere p-0'>
                                            <input className="selectcount themeselect  w-full"
                                              type='text'
                                              name="quantity"
                                              min='0'
                                            // value={volume}
                                            // onChange={handleChange} 
                                            />
                                            <div className='input-group-append font_sm_ap' onClick={() => { increaseVolume(volume) }}>
                                              <i className='fa fa-plus' />
                                            </div>
                                            <div className='input-group-append font_sm_ap' onClick={() => { decreaseVolume(volume) }}>
                                              <i className='fa fa-minus' />
                                            </div>
                                          </div>
                                        </div> */}
                                        </div>
                                      </div> :
                                        ""}
                                    </div>
                                    {stopopen || takeopen ? <div className="col-2 text-center py-0">
                                      <div className="row py-0 stoptake">
                                        <div className="col-12 col-md-12 py-1">
                                          <label className='mb-0'>Price</label>
                                        </div>
                                        <div className="col-12 col-md-12 py-1">
                                          <label className='mb-0'>Pips</label>
                                        </div>
                                        <div className="col-12 col-md-12 py-1">
                                          <label className='mb-0'>USD</label>
                                        </div>
                                        {/* <div className="col-12 col-md-12 py-1">
                                        <label className='mb-0'>%</label>
                                      </div> */}
                                      </div>
                                    </div> : ""}
                                    <div className="col-5 col-sm-5 py-0">
                                      {takeopen ?
                                        <div className="form_open  py-0" id="form_take">
                                          <div className="row  py-0">
                                            <div className="col-12 col-md-12 py-1">
                                              <div className='input_grp_number input_typ_gere p-0'>
                                                <input className="selectcount themeselect  w-full"
                                                  type='text'
                                                  name="takeprofit"
                                                  min='0'
                                                  value={takeprofit}
                                                  onBlur={() => { setTEdit(false) }}
                                                  onFocus={() => { setTEdit(true) }}
                                                  onChange={handleChange} />
                                                <div className='input-group-append font_sm_ap' onClick={() => { increaseTpPrice('takeprofit') }}>
                                                  <i className='fa fa-plus' />
                                                </div>
                                                <div className='input-group-append font_sm_ap' onClick={() => { decreaseTpPrice('takeprofit') }}>
                                                  <i className='fa fa-minus' />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-md-12 py-1">
                                              <div className='input_grp_number input_typ_gere p-0'>
                                                <input className="selectcount themeselect  w-full"
                                                  type='text'
                                                  name="tpPip"
                                                  min='0'
                                                  value={tpPip}
                                                  onChange={handleChange}
                                                />
                                                <div className='input-group-append font_sm_ap' onClick={() => { increasetpPip('takeprofit') }}>
                                                  <i className='fa fa-plus' />
                                                </div>
                                                <div className='input-group-append font_sm_ap' onClick={() => { decreasetpPip('takeprofit') }}>
                                                  <i className='fa fa-minus' />
                                                </div>
                                              </div>
                                              <span className="text-danger">{errors && errors?.tpPip}</span>
                                            </div>
                                            <div className="col-12 col-md-12 py-1">
                                              <div className='input_grp_number input_typ_gere p-0'>
                                                <input className="selectcount themeselect  w-full"
                                                  type='text'
                                                  name="tpUsd"
                                                  min='0'
                                                  value={tpUsd}
                                                  onChange={handleChange}
                                                />
                                                <div className='input-group-append font_sm_ap' onClick={() => { increasetpUsd('takeprofit') }}>
                                                  <i className='fa fa-plus' />
                                                </div>
                                                <div className='input-group-append font_sm_ap' onClick={() => { decreasetpUsd('takeprofit') }}>
                                                  <i className='fa fa-minus' />
                                                </div>
                                              </div>
                                              <span className="text-danger">{errors && errors?.tpUsd}</span>
                                            </div>
                                            {/* <div className="col-12 col-md-12 py-1">
                                            <div className='input_grp_number input_typ_gere p-0'>
                                              <input className="selectcount themeselect  w-full"
                                                type='text'
                                                name="quantity"
                                                min='0'
                                              // value={volume}
                                              // onChange={handleChange} 
                                              />
                                              <div className='input-group-append font_sm_ap' onClick={() => { increaseVolume(volume) }}>
                                                <i className='fa fa-plus' />
                                              </div>
                                              <div className='input-group-append font_sm_ap' onClick={() => { decreaseVolume(volume) }}>
                                                <i className='fa fa-minus' />
                                              </div>
                                            </div>
                                          </div> */}
                                          </div>
                                        </div> :
                                        ""}
                                    </div>
                                  </div>
                                  {/* {console.log(Markupprice,"Market closed")} */}
                                  {/* <textarea
                                    placeholder="Comment"
                                    className="mt-2 mb-3 form-control text-white system_textarea"
                                    type="textarea"
                                    name='comment'
                                    vale={comment}
                                    onChange={(e) => { handleChange(e) }}
                                  /> */}
                                  {marketloader ?
                                    <button className={buyorsell == 'buy' ? "text-white system_placeorderBtn loading_btn" : "text-white system_placeorderBtn loading_btn red"} disabled>
                                      <i className="fa fa-spinner mr-2"></i>
                                      <span>Loading..</span>
                                    </button> :
                                    isAuth === true ? !isEmpty(Markupprice) ? <button className={buyorsell == 'buy' ? "text-white system_placeorderBtn" : 'text-white system_placeorderBtn  red'}
                                      onClick={() => {
                                        // setBuyLoader(true)
                                        setMarketLoader(true)
                                        // setSubmited('market')
                                        handleSubmit()
                                      }} >
                                      Place Market Order
                                    </button> :
                                      <button className="text-white system_placeorderBtn" >Market closed</button> :
                                      <button className="text-white system_placeorderBtn" onClick={handleClick}>Login</button>}
                                </Tab>
                                <Tab
                                  eventKey="limit"
                                  title="Limit"
                                  className="px-0 pb-0"
                                >
                                  <div className="mt-2 px-0 d-flex flex-1 gap-2 mb-0">
                                    <button className={buyorsell == "sell" ? "btn mb-0 pb-0 system_limit_sellLimit sellbtn sellbtn_active" : "btn mb-0 pb-0 system_limit_sellLimit sellbtn"}
                                      onClick={() => {
                                        setSellLoader(true)
                                        // Setbuyorsell('sell')
                                        setFormValue({ ...formValue, ...{ 'buyorsell': 'sell', 'price': Markupprice && Markupprice?.bid ? Markupprice?.bid : pairData?.bid } })
                                      }}

                                    >
                                      <p>Sell Limit</p>
                                      <p className='mb-0'>
                                        {Markupprice && Markupprice.bid ?
                                          isNaN(Markupprice?.disbid) ? 0 : Markupprice?.disbid :
                                          isNaN(pairData?.bid) ? 0 : pairData?.bid}
                                        <span className='text lastnumber'>
                                          {Markupprice && Markupprice.bid ?
                                            isNaN(Markupprice?.pipBid) ? 0 : Markupprice?.pipBid :
                                            isNaN(BidandAskDisplay(pairData?.bid, pairData?.precision)) ? '' : BidandAskDisplay(pairsData?.current?.bid, pairsData?.current?.precision)}
                                        </span>
                                      </p>
                                    </button>
                                    <button className={buyorsell == "buy" ? "btn mb-0 pb-0 system_limit_buyLimit buybtn buybtn_active" : "btn mb-0 pb-0 system_limit_buyLimit buybtn"}
                                      onClick={() => {
                                        setBuyLoader(true)
                                        // Setbuyorsell('buy')
                                        setFormValue({ ...formValue, ...{ 'buyorsell': 'buy', 'price': Markupprice && Markupprice?.ask ? Markupprice?.ask : pairData?.ask } })
                                      }}
                                    >
                                      <p>Buy Limit</p>
                                      <p className='mb-0'>
                                        {Markupprice?.ask ?
                                          isNaN(Markupprice?.disask) ? 0 : Markupprice?.disask :
                                          isNaN(pairData?.ask) ? 0 : pairData?.ask
                                        }
                                        <span className='text lastnumber'>
                                          {Markupprice?.ask ?
                                            isNaN(Markupprice?.pipAsk) ? 0 : Markupprice?.pipAsk :
                                            isNaN(BidandAskDisplay(pairData?.ask, pairData?.precision)) ? '' : BidandAskDisplay(pairData?.ask, pairData?.precision)}
                                        </span>
                                      </p>
                                    </button>
                                  </div>
                                  <center>
                                    <small className="text-white">
                                      Spread:{Markupprice && Markupprice?.spread ? Markupprice?.spread : pairData?.spread};
                                      High: {Markupprice && Markupprice?.high ? Markupprice?.high : pairData?.high};
                                      Low: {Markupprice && Markupprice?.low ? Markupprice?.low : pairData?.low}
                                    </small>
                                  </center>

                                  <div className=" font-12">
                                    <div className='px-0'>
                                      <p className="mb-0 font_label_sm">Entry Price</p>
                                      <input
                                        className={LimitDisable && LimitDisable ? "form-control themeinput eror_input" : "form-control themeinput"}
                                        name="price"
                                        value={price}
                                        onChange={handleChange}
                                        type="text"
                                      />
                                      <span className="text-danger">{errors && errors?.price}</span>
                                      <p className="font_label_sm">Distance: {Distance && Distance}</p>
                                    </div>
                                    <div className="row px-2 row_redc_foint">
                                      <div className="col-lg-4">
                                        <p className="font_label_sm">Volume</p>
                                        <div className='input_grp_number input_typ_gere px-0'>
                                          <input className="selectcount themeselect  w-full"
                                            type='text'
                                            name="quantity"
                                            min='0'
                                            value={volume}
                                            onChange={handleChange} />
                                          <div className='input-group-append font_sm_ap' onClick={() => { increaseVolume(volume) }}>
                                            <i className='fa fa-plus' />
                                          </div>
                                          <div className='input-group-append font_sm_ap' onClick={() => { decreaseVolume(volume) }}>
                                            <i className='fa fa-minus' />
                                          </div>
                                        </div>
                                        <p className="font_label_sm">
                                          Pip value : <span className="font-09 font_label_sm">{pipValue.toFixed(2)}</span>
                                        </p>
                                      </div>
                                      <div className="col-lg-4 px-lg-0">
                                        <p className="font_label_sm">
                                          <div className="d-flex jc-between px-0 pt-0"> Contract value <span className="f-10">{pairData.firstCurrencySymbol}</span></div>
                                          <div className="inputboxbg"> {quantity}</div> </p>
                                        <p className="font_label_sm">Commission : {pairData && pairData.commission} </p>
                                      </div>
                                      <div className="col-lg-4">
                                        <p className="font_label_sm">
                                          <div className="d-flex jc-between px-0 pt-0"> Margin
                                            <span className="f-10">USD</span></div>
                                          <div className="inputboxbg">{Est_Margin}</div> </p>
                                      </div>
                                    </div>
                                    <span className="text-danger">{errors && errors?.quantity}</span>
                                  </div>

                                  <div className="px-0 font-12 py-0">
                                    <p className="font_label_sm">Leverage</p>
                                    <div className="inputboxbg inputboxbg_gery_txt">{leverage && leverage}</div>

                                    {/* <select className="selectcount themeselect w-full"
                                    name="leverage"
                                    onChange={handleChange}
                                    disabled
                                  >
                                    <option value={leverage && leverage}>{leverage && leverage}</option>
                                  </select> */}
                                    <span className="text-danger">{errors && errors?.leverage}</span>
                                  </div>

                                  <div className='px-0'>
                                    <div className=' px-0 d-flex align-items-center'>
                                      <input
                                        type="checkbox"
                                        checked={isExpriy}
                                        onChange={(e) => { e.target.checked == true ? setExpriy(true) : setExpriy(false) }}
                                      />
                                      <label className='mb-0 ml-2 font-12'>Expiry</label>

                                      {/* <Form.Control 
                                 type="date" 
                              

                                selected={Epriydate}
                                placeholder="Enter Expiry"
                                className="example-custom-input input_date_new_pick ml-2"
                                name="dob"
                                onChange={(date) => {
                                  let newDate = new Date(date)
                                  newDate.setMilliseconds(0)
                                  let formData = { ...formValue, ...{ 'Epriydate': newDate.getTime() } }
                                  setFormValue(formData)
                                }}
                               
                                dateFormat="MMMM d, yyyy "

                                minDate={new Date()}
                           
                             
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                                 /> */}


                                      {/* <Datetime
                                        popperPlacement="top-end"
                                        input={true}
                                        // closeOnSelect={true}
                                        value={Epriydate}
                                        // isValidDate={valid}
                                        className="w-100 rdt_new"
                                        timeFormat="HH:mm:ss"
                                        timeConstraints={{
                                          hours: { min: new Date(Date.now()).getHours(), max: 23 },
                                        }}
                                        isValidDate={disablePastDt}
                                        inputProps={{ onKeyDown: (e) => { e.preventDefault() } }}
                                        onChange={(e) => {
                                          let newDate = new Date(e._d)
                                          setFormValue({ ...formValue, 'Epriydate': newDate.getTime() })
                                          console.log("event", e._d)
                                        }}
                                      // onChange={(value) => {
                                      //   Auctionset(value);
                                      // }}
                                      /> */}


                                      <DatePicker
                                        disabled={!isExpriy}
                                        showIcon
                                        popperClassName="some-custom-class"
                                        popperPlacement="top-end"
                                        selected={Epriydate}
                                        className="example-custom-input"
                                        onChange={(date) => {
                                          console.log("event", date)
                                          let newDate = new Date(date)
                                          newDate.setMilliseconds(0)
                                          let formData = { ...formValue, ...{ 'Epriydate': newDate.getTime() } }
                                          setFormValue(formData)
                                        }}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={1}
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        minDate={new Date()}
                                        filterTime={filterPassedTime}
                                        onKeyDown={(e) => {
                                          e.preventDefault();
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="d-flex px-0 justify-content-between align-items-center row mx-0">
                                    <div className="px-0 d-flex col-5">
                                      <input type="checkbox" id="stop_loss_check" checked={stopopen}
                                        onChange={(e) => {
                                          e.target.checked == true ? setStopopen(true) : setStopopen(false)
                                          setFormValue({ ...formValue, ...{ 'stopprice': price } })
                                        }}
                                      />
                                      <label className='mb-0 ml-2'>Stop Loss</label>
                                    </div >
                                    <div className='px-0 d-flex col-5'>
                                      <input type="checkbox" id="take_profit_check" checked={takeopen}
                                        onChange={(e) => {
                                          e.target.checked == true ? setTakeopen(true) : setTakeopen(false);
                                          setFormValue({ ...formValue, ...{ 'takeprofit': price } })
                                        }} />
                                      <label className='mb-0 ml-2'>Take Profit</label>
                                    </div>

                                  </div>
                                  <div className="d-flex px-0 justify-content-between align-items-center row mx-0 pt-0">
                                    <div className="px-0 d-flex col-5 pt-0">
                                      <p className='text-danger f-10'>{errors && errors.stopprice}</p>
                                    </div>
                                    <div className="px-0 d-flex col-5 pt-0">
                                      <p className='text-danger f-10'>{errors && errors.takeprofit}</p>
                                    </div>
                                  </div>
                                  <div className="row px-2 py-0">
                                    <div className="col-5 col-sm-5 py-0">
                                      {stopopen ? <div className="form_open py-0" id="form_stop">
                                        <div className="row py-0">
                                          <div className="col-12 col-md-12 py-1">
                                            <div className='input_grp_number input_typ_gere p-0'>
                                              <input className="selectcount themeselect  w-full"
                                                type='text'
                                                name="stopprice"
                                                min='0'
                                                value={stopprice}
                                                onBlur={() => { setSEdit(false) }}
                                                onFocus={(e) => { setSEdit(true) }}
                                                onChange={handleChange} />
                                              <div className='input-group-append font_sm_ap' onClick={() => { increaseTpPrice('stopprice') }}>
                                                <i className='fa fa-plus' />
                                              </div>
                                              <div className='input-group-append font_sm_ap' onClick={() => { decreaseTpPrice('stopprice') }}>
                                                <i className='fa fa-minus' />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-12 col-md-12 py-1">
                                            <div className='input_grp_number input_typ_gere p-0'>
                                              <input className="selectcount themeselect  w-full"
                                                type='text'
                                                name="spPip"
                                                min='0'
                                                value={spPip}
                                                onChange={handleChange}
                                              />
                                              <div className='input-group-append font_sm_ap' onClick={() => { increasetpPip('stopprice') }}>
                                                <i className='fa fa-plus' />
                                              </div>
                                              <div className='input-group-append font_sm_ap' onClick={() => { decreasetpPip('stopprice') }}>
                                                <i className='fa fa-minus' />
                                              </div>
                                            </div>
                                            <span className="text-danger">{errors && errors?.spPip}</span>
                                          </div>
                                          <div className="col-12 col-md-12 py-1">
                                            <div className='input_grp_number input_typ_gere p-0'>
                                              <input className="selectcount themeselect  w-full"
                                                type='text'
                                                name="spUsd"
                                                min='0'
                                                value={spUsd}
                                                onChange={handleChange}
                                              />
                                              <div className='input-group-append font_sm_ap' onClick={() => { increasetpUsd('stopprice') }}>
                                                <i className='fa fa-plus' />
                                              </div>
                                              <div className='input-group-append font_sm_ap' onClick={() => { decreasetpUsd('stopprice') }}>
                                                <i className='fa fa-minus' />
                                              </div>
                                            </div>
                                            <span className="text-danger">{errors && errors?.spUsd}</span>
                                          </div>
                                          {/* <div className="col-12 col-md-12 py-1">
                                          <div className='input_grp_number input_typ_gere p-0'>
                                            <input className="selectcount themeselect  w-full"
                                              type='text'
                                              name="quantity"
                                              min='0'
                                            // value={volume}
                                            // onChange={handleChange} 
                                            />
                                            <div className='input-group-append font_sm_ap' onClick={() => { increaseVolume(volume) }}>
                                              <i className='fa fa-plus' />
                                            </div>
                                            <div className='input-group-append font_sm_ap' onClick={() => { decreaseVolume(volume) }}>
                                              <i className='fa fa-minus' />
                                            </div>
                                          </div>
                                        </div> */}
                                        </div>
                                      </div> :
                                        ""}
                                    </div>
                                    {stopopen || takeopen ? <div className="col-2 text-center py-0">
                                      <div className="row py-0 stoptake">
                                        <div className="col-12 col-md-12 py-1">
                                          <label className='mb-0'>Price</label>
                                        </div>
                                        <div className="col-12 col-md-12 py-1">
                                          <label className='mb-0'>Pips</label>
                                        </div>
                                        <div className="col-12 col-md-12 py-1">
                                          <label className='mb-0'>USD</label>
                                        </div>
                                        {/* <div className="col-12 col-md-12 py-1">
                                        <label className='mb-0'>%</label>
                                      </div> */}
                                      </div>
                                    </div> : ""}
                                    <div className="col-5 col-sm-5 py-0">
                                      {takeopen ?
                                        <div className="form_open  py-0" id="form_take">
                                          <div className="row  py-0">
                                            <div className="col-12 col-md-12 py-1">
                                              <div className='input_grp_number input_typ_gere p-0'>
                                                <input className="selectcount themeselect  w-full"
                                                  type='text'
                                                  name="takeprofit"
                                                  min='0'
                                                  value={takeprofit}
                                                  onBlur={() => { setTEdit(false) }}
                                                  onFocus={() => { setTEdit(true) }}
                                                  onChange={handleChange} />
                                                <div className='input-group-append font_sm_ap' onClick={() => { increaseTpPrice('takeprofit') }}>
                                                  <i className='fa fa-plus' />
                                                </div>
                                                <div className='input-group-append font_sm_ap' onClick={() => { decreaseTpPrice('takeprofit') }}>
                                                  <i className='fa fa-minus' />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-md-12 py-1">
                                              <div className='input_grp_number input_typ_gere p-0'>
                                                <input className="selectcount themeselect  w-full"
                                                  type='text'
                                                  name="tpPip"
                                                  min='0'
                                                  value={tpPip}
                                                  onChange={handleChange}
                                                />
                                                <div className='input-group-append font_sm_ap' onClick={() => { increasetpPip('takeprofit') }}>
                                                  <i className='fa fa-plus' />
                                                </div>
                                                <div className='input-group-append font_sm_ap' onClick={() => { decreasetpPip('takeprofit') }}>
                                                  <i className='fa fa-minus' />
                                                </div>
                                              </div>
                                              <span className="text-danger">{errors && errors?.tpPip}</span>
                                            </div>
                                            <div className="col-12 col-md-12 py-1">
                                              <div className='input_grp_number input_typ_gere p-0'>
                                                <input className="selectcount themeselect  w-full"
                                                  type='text'
                                                  name="tpUsd"
                                                  min='0'
                                                  value={tpUsd}
                                                  onChange={handleChange}
                                                />
                                                <div className='input-group-append font_sm_ap' onClick={() => { increasetpUsd('takeprofit') }}>
                                                  <i className='fa fa-plus' />
                                                </div>
                                                <div className='input-group-append font_sm_ap' onClick={() => { decreasetpUsd('takeprofit') }}>
                                                  <i className='fa fa-minus' />
                                                </div>
                                              </div>
                                              <span className="text-danger">{errors && errors?.tpUsd}</span>
                                            </div>
                                            {/* <div className="col-12 col-md-12 py-1">
                                            <div className='input_grp_number input_typ_gere p-0'>
                                              <input className="selectcount themeselect  w-full"
                                                type='text'
                                                name="quantity"
                                                min='0'
                                              // value={volume}
                                              // onChange={handleChange}
                                              />
                                              <div className='input-group-append font_sm_ap' onClick={() => { increaseVolume(volume) }}>
                                                <i className='fa fa-plus' />
                                              </div>
                                              <div className='input-group-append font_sm_ap' onClick={() => { decreaseVolume(volume) }}>
                                                <i className='fa fa-minus' />
                                              </div>
                                            </div>
                                          </div> */}
                                          </div>
                                        </div> :
                                        ""}
                                    </div>
                                  </div>
                                  {/* <textarea
                                    placeholder="Comment"
                                    className="mt-0 mb-3 form-control text-white system_textarea"
                                    type="textarea"
                                    name='comment'
                                    vale={comment}
                                    onChange={(e) => { handleChange(e) }}
                                  /> */}
                                  {limitloader ? <button className={buyorsell == 'buy' ? "text-white system_placeorderBtn loading_btn" : "text-white system_placeorderBtn loading_btn red"} disabled>
                                    <i className="fa fa-spinner mr-2"></i>
                                    <span>Loading..</span>
                                  </button> :
                                    isAuth === true ? <button className={buyorsell == 'buy' ? "text-white system_placeorderBtn" : 'text-white system_placeorderBtn red'}
                                      disabled={LimitDisable && LimitDisable}
                                      onClick={() => {
                                        setLimitLoader(true)
                                        // setSubmited('limit')
                                        handleSubmit()
                                      }}
                                    >
                                      Place Limit Order
                                    </button> : <button className="text-white system_placeorderBtn" onClick={handleClick}>Login</button>
                                  }
                                </Tab>
                                <Tab
                                  eventKey="stop"
                                  title="Stop"
                                  className="px-0 pb-0"
                                >
                                  <div className="mt-2 px-0 d-flex flex-1 gap-2 mb-0">
                                    <button className={buyorsell == "sell" ? "btn mb-0 pb-0 system_limit_sellLimit sellbtn sellbtn_active" : "btn mb-0 pb-0 system_limit_sellLimit sellbtn"} onClick={() => {
                                      setSellLoader(true)
                                      // Setbuyorsell('sell')
                                      setFormValue({ ...formValue, ...{ 'buyorsell': 'sell', 'price': Markupprice && Markupprice?.ask ? Markupprice?.ask : pairData?.ask } })

                                    }} >
                                      <p>Sell Stop</p>
                                      <p className='mb-0'>
                                        {Markupprice && Markupprice.bid ?
                                          isNaN(Markupprice?.disbid) ? 0 : Markupprice?.disbid :
                                          isNaN(pairData?.bid) ? 0 : pairData?.bid}
                                        <span className='text lastnumber'>
                                          {Markupprice && Markupprice.bid ?
                                            isNaN(Markupprice?.pipBid) ? 0 : Markupprice?.pipBid :
                                            isNaN(BidandAskDisplay(pairData?.bid, pairData?.precision)) ? '' : BidandAskDisplay(pairsData?.current?.bid, pairsData?.current?.precision)}
                                        </span>
                                      </p>
                                    </button>
                                    <button className={buyorsell == "buy" ? "btn mb-0 pb-0 system_limit_buyLimit buybtn buybtn_active" : "btn mb-0 pb-0 system_limit_buyLimit buybtn"} onClick={() => {
                                      setBuyLoader(true)
                                      // Setbuyorsell('buy')
                                      setFormValue({ ...formValue, ...{ 'buyorsell': 'buy', 'price': Markupprice && Markupprice?.bid ? Markupprice?.bid : pairData?.bid } })
                                    }}>
                                      <p>Buy Stop</p>
                                      <p className='mb-0'>
                                        {Markupprice?.ask ?
                                          isNaN(Markupprice?.disask) ? 0 : Markupprice?.disask :
                                          isNaN(pairData?.ask) ? 0 : pairData?.ask
                                        }
                                        <span className='text lastnumber'>
                                          {Markupprice?.ask ?
                                            isNaN(Markupprice?.pipAsk) ? 0 : Markupprice?.pipAsk :
                                            isNaN(BidandAskDisplay(pairData?.ask, pairData?.precision)) ? '' : BidandAskDisplay(pairData?.ask, pairData?.precision)}
                                        </span>
                                      </p>
                                    </button>
                                  </div>
                                  <center>
                                    <small className="text-white">
                                      Spread:{Markupprice && Markupprice?.spread ? Markupprice?.spread : pairData?.spread};
                                      High: {Markupprice && Markupprice?.high ? Markupprice?.high : pairData?.high};
                                      Low: {Markupprice && Markupprice?.low ? Markupprice?.low : pairData?.low}
                                    </small>
                                  </center>

                                  <div className=" px-0 flex-1 gap-2 font-12">
                                    <div className="px-0">
                                      <p className="mb-0 font_label_sm">Entry Price</p>
                                      <input
                                        className={StopDisable ? "form-control themeinput eror_input" : "form-control themeinput"}
                                        name="price"
                                        value={price}
                                        onChange={handleChange}
                                        type="text"
                                      />
                                      <span className="text-danger">{errors && errors?.price}</span>
                                      <p className="mt-2 font_label_sm">Distance: {Distance && Distance}</p>
                                    </div>
                                    <div className="row px-2 row_redc_foint">
                                      <div className="col-lg-4">
                                        <p className="font_label_sm">Volume</p>
                                        <div className='input_grp_number input_typ_gere px-0'>
                                          <input className="selectcount themeselect  w-full"
                                            type='text'
                                            name="quantity"
                                            min='0'
                                            value={volume}
                                            onChange={handleChange} />
                                          <div className='input-group-append font_sm_ap' onClick={() => { increaseVolume(volume) }}>
                                            <i className='fa fa-plus' />
                                          </div>
                                          <div className='input-group-append font_sm_ap' onClick={() => { decreaseVolume(volume) }}>
                                            <i className='fa fa-minus' />
                                          </div>
                                        </div>
                                        <p className="font-09 font_label_sm">
                                          Pip value : <span className="font-09 font_label_sm">{pipValue.toFixed(2)}</span>
                                        </p>
                                      </div>
                                      <div className="col-lg-4 px-lg-0">
                                        <p className="font_label_sm">
                                          <div className="d-flex jc-between px-0 pt-0"> Contract value <span className="f-10">{pairData.firstCurrencySymbol}</span></div>
                                          <div className="inputboxbg"> {quantity}</div> </p>
                                        <p className="font_label_sm">Commission : {pairData && pairData.commission} </p>
                                      </div>
                                      <div className="col-lg-4">
                                        <p className="font_label_sm">
                                          <div className="d-flex jc-between px-0 pt-0"> Margin
                                            <span className="f-10">USD</span></div>
                                          <div className="inputboxbg">{Est_Margin}</div> </p>
                                      </div>
                                    </div>
                                    <span className="text-danger">{errors && errors?.quantity}</span>
                                  </div>
                                  <div className="px-0 font-12 pt-0">
                                    <p className="font_label_sm">Leverage</p>
                                    <div className="inputboxbg inputboxbg_gery_txt">{leverage && leverage}</div>
                                    {/* <select className="selectcount themeselect w-full"
                                    name="leverage"
                                    onChange={handleChange}
                                    disabled
                                  >
                                    <option value={leverage && leverage}>{leverage && leverage}</option>
                                    <span className="text-danger">{errors && errors?.leverage}</span>
                                  </select> */}
                                  </div>

                                  <div className="px-0">
                                    <div className='p-0 d-flex align-items-center'>
                                      <input type="checkbox"
                                        checked={isExpriy}
                                        onChange={(e) => { e.target.checked == true ? setExpriy(true) : setExpriy(false) }}
                                      />
                                      <label className='mb-0 ml-2 font-12'>Expiry</label>

                                      {/* <Datetime
                                        popperPlacement="top-end"
                                        input={true}
                                        // closeOnSelect={true}
                                        // isValidDate={valid}

                                        value={Epriydate}
                                        className="w-100 rdt_new"
                                        timeFormat="HH:mm:ss"
                                        timeConstraints={{
                                          hours: { min: new Date(Date.now()).getHours(), max: 23 },
                                        }}
                                        isValidDate={disablePastDt}
                                        inputProps={{ onKeyDown: (e) => { e.preventDefault() } }}
                                        onChange={(e) => {
                                          let newDate = new Date(e._d)
                                          setFormValue({ ...formValue, 'Epriydate': newDate.getTime() })
                                          console.log("event", e._d)
                                        }}
                                      // onChange={(value) => {
                                      //   Auctionset(value);
                                      // }}
                                      /> */}
                                      {/* 
                                    <Form.Control 
                                 type="date" 
                           

                                selected={Epriydate}
                                placeholder="Enter Expiry"
                                className="example-custom-input input_date_new_pick ml-2"
                                name="dob"
                                onChange={(date) => {
                                  let newDate = new Date(date)
                                  newDate.setMilliseconds(0)
                                  let formData = { ...formValue, ...{ 'Epriydate': newDate.getTime() } }
                                  setFormValue(formData)
                                }}
                               
                                dateFormat="MMMM d, yyyy "

                                minDate={new Date()}
                                
                               
                             
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                                 /> */}

                                      <DatePicker
                                        disabled={!isExpriy}
                                        popperClassName="some-custom-class"
                                        popperPlacement="top-end"
                                        className="example-custom-input"
                                        selected={Epriydate}
                                        onChange={(date) => {
                                          let newDate = new Date(date)
                                          newDate.setMilliseconds(0)
                                          let formData = { ...formValue, ...{ 'Epriydate': newDate.getTime() } }
                                          setFormValue(formData)
                                        }}
                                        showTimeSelect
                                        showIcon
                                        timeFormat="HH:mm"
                                        timeIntervals={1}
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        minDate={new Date()}
                                        filterTime={filterPassedTime}
                                        onKeyDown={(e) => {
                                          e.preventDefault();
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="d-flex px-0 justify-content-between align-items-center row mx-0">
                                    <div className="px-0 d-flex col-5">
                                      <input type="checkbox" id="stop_loss_check" checked={stopopen}
                                        onChange={(e) => {
                                          e.target.checked == true ? setStopopen(true) : setStopopen(false)
                                          setFormValue({ ...formValue, ...{ 'stopprice': price } })
                                        }}
                                      />
                                      <label className='mb-0 ml-2'>Stop Loss</label>

                                    </div >
                                    <div className='px-0 d-flex col-5'>
                                      <input type="checkbox" id="take_profit_check" checked={takeopen}
                                        onChange={(e) => {
                                          e.target.checked == true ? setTakeopen(true) : setTakeopen(false);
                                          setFormValue({ ...formValue, ...{ 'takeprofit': price } })
                                        }} />
                                      <label className='mb-0 ml-2'>Take Profit</label>

                                    </div>

                                  </div>
                                  <div className="d-flex px-0 justify-content-between align-items-center row mx-0 pt-0">
                                    <div className="px-0 d-flex col-5 pt-0">
                                      <p className='text-danger f-10'>{errors && errors.stopprice}</p>
                                    </div>
                                    <div className="px-0 d-flex col-5 pt-0">
                                      <p className='text-danger f-10'>{errors && errors.takeprofit}</p>
                                    </div>
                                  </div>
                                  <div className="row px-2 py-0">
                                    <div className="col-5 col-sm-5 py-0">
                                      {stopopen ? <div className="form_open py-0" id="form_stop">
                                        <div className="row py-0">
                                          <div className="col-12 col-md-12 py-1">
                                            <div className='input_grp_number input_typ_gere p-0'>
                                              <input className="selectcount themeselect  w-full"
                                                type='text'
                                                name="stopprice"
                                                min='0'
                                                value={stopprice}
                                                onBlur={() => { setSEdit(false) }}
                                                onFocus={(e) => { setSEdit(true) }}
                                                onChange={handleChange} />
                                              <div className='input-group-append font_sm_ap' onClick={() => { increaseTpPrice('stopprice') }}>
                                                <i className='fa fa-plus' />
                                              </div>
                                              <div className='input-group-append font_sm_ap' onClick={() => { decreaseTpPrice('stopprice') }}>
                                                <i className='fa fa-minus' />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-12 col-md-12 py-1">
                                            <div className='input_grp_number input_typ_gere p-0'>
                                              <input className="selectcount themeselect  w-full"
                                                type='text'
                                                name="spPip"
                                                min='0'
                                                value={spPip}
                                                onChange={handleChange}
                                              />
                                              <div className='input-group-append font_sm_ap' onClick={() => { increasetpPip('stopprice') }}>
                                                <i className='fa fa-plus' />
                                              </div>
                                              <div className='input-group-append font_sm_ap' onClick={() => { decreasetpPip('stopprice') }}>
                                                <i className='fa fa-minus' />
                                              </div>
                                            </div>
                                            <span className="text-danger">{errors && errors?.spPip}</span>
                                          </div>
                                          <div className="col-12 col-md-12 py-1">
                                            <div className='input_grp_number input_typ_gere p-0'>
                                              <input className="selectcount themeselect  w-full"
                                                type='text'
                                                name="spUsd"
                                                min='0'
                                                value={spUsd}
                                                onChange={handleChange}
                                              />
                                              <div className='input-group-append font_sm_ap' onClick={() => { increasetpUsd('stopprice') }}>
                                                <i className='fa fa-plus' />
                                              </div>
                                              <div className='input-group-append font_sm_ap' onClick={() => { decreasetpUsd('stopprice') }}>
                                                <i className='fa fa-minus' />
                                              </div>
                                            </div>
                                            <span className="text-danger">{errors && errors?.spUsd}</span>
                                          </div>
                                          {/* <div className="col-12 col-md-12 py-1">
                                          <div className='input_grp_number input_typ_gere p-0'>
                                            <input className="selectcount themeselect  w-full"
                                              type='text'
                                              name="quantity"
                                              min='0'
                                            // value={volume}
                                            // onChange={handleChange} 
                                            />
                                            <div className='input-group-append font_sm_ap' onClick={() => { increaseVolume(volume) }}>
                                              <i className='fa fa-plus' />
                                            </div>
                                            <div className='input-group-append font_sm_ap' onClick={() => { decreaseVolume(volume) }}>
                                              <i className='fa fa-minus' />
                                            </div>
                                          </div>
                                        </div> */}
                                        </div>
                                      </div> :
                                        ""}
                                    </div>
                                    {stopopen || takeopen ? <div className="col-2 text-center py-0">
                                      <div className="row py-0 stoptake">
                                        <div className="col-12 col-md-12 py-1">
                                          <label className='mb-0'>Price</label>
                                        </div>
                                        <div className="col-12 col-md-12 py-1">
                                          <label className='mb-0'>Pips</label>
                                        </div>
                                        <div className="col-12 col-md-12 py-1">
                                          <label className='mb-0'>USD</label>
                                        </div>
                                        {/* <div className="col-12 col-md-12 py-1">
                                        <label className='mb-0'>%</label>
                                      </div> */}
                                      </div>
                                    </div> : ""}
                                    <div className="col-5 col-sm-5 py-0">
                                      {takeopen ?
                                        <div className="form_open  py-0" id="form_take">
                                          <div className="row  py-0">
                                            <div className="col-12 col-md-12 py-1">
                                              <div className='input_grp_number input_typ_gere p-0'>
                                                <input className="selectcount themeselect  w-full"
                                                  type='text'
                                                  name="takeprofit"
                                                  min='0'
                                                  value={takeprofit}
                                                  onBlur={() => { setTEdit(false) }}
                                                  onFocus={() => { setTEdit(true) }}
                                                  onChange={handleChange} />
                                                <div className='input-group-append font_sm_ap' onClick={() => { increaseTpPrice('takeprofit') }}>
                                                  <i className='fa fa-plus' />
                                                </div>
                                                <div className='input-group-append font_sm_ap' onClick={() => { decreaseTpPrice('takeprofit') }}>
                                                  <i className='fa fa-minus' />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-md-12 py-1">
                                              <div className='input_grp_number input_typ_gere p-0'>
                                                <input className="selectcount themeselect  w-full"
                                                  type='text'
                                                  name="tpPip"
                                                  min='0'
                                                  value={tpPip}
                                                  onChange={handleChange}
                                                />
                                                <div className='input-group-append font_sm_ap' onClick={() => { increasetpPip('takeprofit') }}>
                                                  <i className='fa fa-plus' />
                                                </div>
                                                <div className='input-group-append font_sm_ap' onClick={() => { decreasetpPip('takeprofit') }}>
                                                  <i className='fa fa-minus' />
                                                </div>
                                              </div>
                                              <span className="text-danger">{errors && errors?.tpPip}</span>
                                            </div>
                                            <div className="col-12 col-md-12 py-1">
                                              <div className='input_grp_number input_typ_gere p-0'>
                                                <input className="selectcount themeselect  w-full"
                                                  type='text'
                                                  name="tpUsd"
                                                  min='0'
                                                  value={tpUsd}
                                                  onChange={handleChange}
                                                />
                                                <div className='input-group-append font_sm_ap' onClick={() => { increasetpUsd('takeprofit') }}>
                                                  <i className='fa fa-plus' />
                                                </div>
                                                <div className='input-group-append font_sm_ap' onClick={() => { decreasetpUsd('takeprofit') }}>
                                                  <i className='fa fa-minus' />
                                                </div>
                                              </div>
                                              <span className="text-danger">{errors && errors?.tpUsd}</span>
                                            </div>
                                            {/* <div className="col-12 col-md-12 py-1">
                                            <div className='input_grp_number input_typ_gere p-0'>
                                              <input className="selectcount themeselect  w-full"
                                                type='text'
                                                name="quantity"
                                                min='0'
                                              // value={volume}
                                              // onChange={handleChange} 
                                              />
                                              <div className='input-group-append font_sm_ap' onClick={() => { increaseVolume(volume) }}>
                                                <i className='fa fa-plus' />
                                              </div>
                                              <div className='input-group-append font_sm_ap' onClick={() => { decreaseVolume(volume) }}>
                                                <i className='fa fa-minus' />
                                              </div>
                                            </div>
                                          </div> */}
                                          </div>
                                        </div> :
                                        ""}
                                    </div>
                                  </div>
                                  {/* <textarea
                                    placeholder="Comment"
                                    className="mt-2 mb-3 form-control text-white system_textarea"
                                    type="textarea"
                                    name='comment'
                                    vale={comment}
                                    onChange={(e) => { handleChange(e) }}
                                  /> */}
                                  {stoploader ?
                                    <button className={buyorsell == 'buy' ? "text-white system_placeorderBtn loading_btn" : "text-white system_placeorderBtn loading_btn red"} disabled>
                                      <i className="fa fa-spinner mr-2"></i>
                                      <span>Loading..</span>
                                    </button>
                                    :
                                    isAuth === true ? <button className={buyorsell == 'buy' ? "text-white system_placeorderBtn" : 'text-white system_placeorderBtn red'}
                                      disabled={StopDisable && StopDisable}
                                      onClick={() => {
                                        setstoploader(true)
                                        // setSubmited('limit')
                                        handleSubmit()
                                      }}
                                    >
                                      Place Stop Order
                                    </button> : <button className="text-white system_placeorderBtn" onClick={handleClick}>Login</button>}
                                </Tab>
                                <Tab
                                  eventKey="stop_limit"
                                  title="Stop Limit"
                                  className="px-0 pb-0"
                                >
                                  <div className="mt-2 px-0 d-flex flex-1 gap-2 mb-0">
                                    <button className={buyorsell == "sell" ? "btn mb-0 pb-0 system_limit_sellLimit sellbtn sellbtn_active" : "btn mb-0 pb-0 system_limit_sellLimit sellbtn"} onClick={() => {
                                      setSellLoader(true)
                                      // Setbuyorsell('sell')
                                      setFormValue({ ...formValue, ...{ 'buyorsell': 'sell', 'price': Markupprice && Markupprice?.ask ? Markupprice?.ask : pairData?.ask } })
                                    }} >
                                      <p>Sell Stop Limit</p>
                                      <p className='mb-0'>
                                        {Markupprice && Markupprice.bid ?
                                          isNaN(Markupprice?.disbid) ? 0 : Markupprice?.disbid :
                                          isNaN(pairData?.bid) ? 0 : pairData?.bid}
                                        <span className='text lastnumber'>
                                          {Markupprice && Markupprice.bid ?
                                            isNaN(Markupprice?.pipBid) ? 0 : Markupprice?.pipBid :
                                            isNaN(BidandAskDisplay(pairData?.bid, pairData?.precision)) ? '' : BidandAskDisplay(pairsData?.current?.bid, pairsData?.current?.precision)}
                                        </span>
                                      </p>
                                    </button>
                                    <button className={buyorsell == "buy" ? "btn mb-0 pb-0 system_limit_buyLimit buybtn buybtn_active" : "btn mb-0 pb-0 system_limit_buyLimit buybtn"} onClick={() => {
                                      setBuyLoader(true)
                                      // Setbuyorsell('buy')
                                      setFormValue({ ...formValue, ...{ 'buyorsell': 'buy', 'price': Markupprice && Markupprice?.ask ? Markupprice?.ask : pairData?.ask } })
                                    }}>
                                      <p>Buy Stop Limit</p>
                                      <p className='mb-0'>
                                        {Markupprice?.ask ?
                                          isNaN(Markupprice?.disask) ? 0 : Markupprice?.disask :
                                          isNaN(pairData?.ask) ? 0 : pairData?.ask
                                        }
                                        <span className='text lastnumber'>
                                          {Markupprice?.ask ?
                                            isNaN(Markupprice?.pipAsk) ? 0 : Markupprice?.pipAsk :
                                            isNaN(BidandAskDisplay(pairData?.ask, pairData?.precision)) ? '' : BidandAskDisplay(pairData?.ask, pairData?.precision)}
                                        </span>
                                      </p>
                                    </button>
                                  </div>
                                  <center>
                                    <small className="text-white">
                                      Spread:{Markupprice && Markupprice?.spread ? Markupprice?.spread : pairData?.spread};
                                      High: {Markupprice && Markupprice?.high ? Markupprice?.high : pairData?.high};
                                      Low: {Markupprice && Markupprice?.low ? Markupprice?.low : pairData?.low}
                                    </small>
                                  </center>

                                  <div className="font-12">
                                    <div className="px-0">
                                      <p className="mb-0 font_label_sm">Entry Price</p>
                                      <input
                                        className={StopDisable ? "form-control themeinput eror_input" : "form-control themeinput"}
                                        name="price"
                                        value={price}
                                        onChange={handleChange}
                                        type="text"
                                      />
                                      <span className="text-danger">{errors && errors?.price}</span>
                                      <p className="mt-2 font_label_sm">Distance: {Distance && Distance}</p>
                                    </div>
                                    <div className="row px-2 row_redc_foint">
                                      <div className="col-lg-4">
                                        <p className="font_label_sm">Volume</p>
                                        <div className='input_grp_number input_typ_gere px-0'>
                                          <input className="selectcount themeselect  w-full"
                                            type='text'
                                            name="quantity"
                                            min='0'
                                            value={volume}
                                            onChange={handleChange} />
                                          <div className='input-group-append font_sm_ap' onClick={() => { increaseVolume(volume) }}>
                                            <i className='fa fa-plus' />
                                          </div>
                                          <div className='input-group-append font_sm_ap' onClick={() => { decreaseVolume(volume) }}>
                                            <i className='fa fa-minus' />
                                          </div>
                                        </div>
                                        <p className="font-09 font_label_sm">
                                          Pip value : <span className="font-09 font_label_sm">{pipValue.toFixed(2)}</span>
                                        </p>
                                      </div>
                                      <div className="col-lg-4 px-lg-0">
                                        <p className="font_label_sm">
                                          <div className="d-flex jc-between px-0 pt-0"> Contract value <span className="f-10">{pairData.firstCurrencySymbol}</span></div>
                                          <div className="inputboxbg"> {quantity}</div>
                                        </p>
                                        <p className="font_label_sm">Commission : {pairData && pairData.commission} </p>
                                      </div>
                                      <div className="col-lg-4">
                                        <p className="font_label_sm">
                                          <div className="d-flex jc-between px-0 pt-0"> Margin
                                            <span className="f-10">USD</span></div>
                                          <div className="inputboxbg">{Est_Margin}</div> </p>
                                      </div>
                                    </div>
                                    <span className="text-danger">{errors && errors?.quantity}</span>
                                  </div>

                                  <div className="px-0 font-12 pt-0">
                                    <p className="mb-0 font_label_sm">Limit Range</p>
                                    <input type='text' className="form-control themeinput" name="Limit_Range" value={Limit_range_pips} onChange={handleChange} />
                                    <span className="text-danger">{errors && errors?.Limit_Range}</span>
                                    <p className="font_label_sm mt-2 mb-0">Leverage</p>
                                    <div className="inputboxbg inputboxbg_gery_txt">{leverage && leverage}</div>

                                    {/* <select className="selectcount themeselect w-full"
                                    name="leverage"
                                    onChange={handleChange}
                                    disabled
                                  >
                                    <option value={leverage && leverage}>{leverage && leverage}</option>
                                  </select> */}
                                    <span className="text-danger">{errors && errors?.leverage}</span>
                                  </div>

                                  <div className="px-0">
                                    <div className=' p-0 d-flex align-items-center'>
                                      <input type="checkbox"
                                        checked={isExpriy}
                                        onChange={(e) => { e.target.checked == true ? setExpriy(true) : setExpriy(false) }}
                                      />
                                      <label className='mb-0 ml-2'>Expiry</label>

                                      {/* <Datetime
                                        popperPlacement="top-end"
                                        input={true}
                                        // isValidDate={valid}
                                        value={Epriydate}
                                        className="w-100 rdt_new"
                                        timeFormat="HH:mm:ss"
                                        timeConstraints={{
                                          hours: { min: new Date(Date.now()).getHours(), max: 23 },
                                        }}
                                        isValidDate={disablePastDt}
                                        inputProps={{ onKeyDown: (e) => { e.preventDefault() } }}
                                        onChange={(e) => {
                                          let newDate = new Date(e._d)
                                          setFormValue({ ...formValue, 'Epriydate': newDate.getTime() })
                                          console.log("event", e._d)
                                        }}
                                      // onChange={(value) => {
                                      //   Auctionset(value);
                                      // }}
                                      /> */}

                                      {/* <Form.Control 
                                 type="date" 
                               

                                selected={Epriydate}
                                placeholder="Enter Expiry"
                                className="example-custom-input input_date_new_pick ml-2"
                                name="dob"
                                onChange={(date) => {
                                  let newDate = new Date(date)
                                  newDate.setMilliseconds(0)
                                  let formData = { ...formValue, ...{ 'Epriydate': newDate.getTime() } }
                                  setFormValue(formData)
                                }}
                               
                                dateFormat="MMMM d, yyyy "

                                minDate={new Date()}
                                
                              
                             
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                                 /> */}


                                      <DatePicker
                                        disabled={!isExpriy}
                                        popperClassName="some-custom-class"
                                        popperPlacement="top-end"
                                        selected={Epriydate}
                                        className="example-custom-input"
                                        onChange={(date) => {
                                          let newDate = new Date(date)
                                          newDate.setMilliseconds(0)
                                          let formData = { ...formValue, ...{ 'Epriydate': newDate.getTime() } }
                                          setFormValue(formData)
                                        }}
                                        showTimeSelect
                                        showIcon
                                        timeFormat="HH:mm"
                                        timeIntervals={1}
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        minDate={new Date()}
                                        filterTime={filterPassedTime}
                                        onKeyDown={(e) => {
                                          e.preventDefault();
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="d-flex px-0 justify-content-between align-items-center row mx-0">
                                    <div className="px-0 d-flex col-5">
                                      <input type="checkbox" id="stop_loss_check" checked={stopopen}
                                        onChange={(e) => {
                                          e.target.checked == true ? setStopopen(true) : setStopopen(false);
                                          setFormValue({ ...formValue, ...{ 'stopprice': price } })
                                        }} />
                                      <label className='mb-0 ml-2'>Stop Loss</label>

                                    </div >
                                    <div className='px-0 d-flex col-5'>
                                      <input type="checkbox" id="take_profit_check" checked={takeopen}
                                        onChange={(e) => {
                                          e.target.checked == true ? setTakeopen(true) : setTakeopen(false);
                                          setFormValue({ ...formValue, ...{ 'takeprofit': price } })
                                        }} />
                                      <label className='mb-0 ml-2'>Take Profit</label>

                                    </div>

                                  </div>
                                  <div className="d-flex px-0 justify-content-between align-items-center row mx-0 pt-0">
                                    <div className="px-0 d-flex col-5 pt-0">
                                      <p className='text-danger f-10'>{errors && errors.stopprice}</p>
                                    </div>
                                    <div className="px-0 d-flex col-5 pt-0">
                                      <p className='text-danger f-10'>{errors && errors.takeprofit}</p>
                                    </div>
                                  </div>
                                  <div className="row px-2 py-0">
                                    <div className="col-5 col-sm-5 py-0">
                                      {stopopen ? <div className="form_open py-0" id="form_stop">
                                        <div className="row py-0">
                                          <div className="col-12 col-md-12 py-1">
                                            <div className='input_grp_number input_typ_gere p-0'>
                                              <input className="selectcount themeselect  w-full"
                                                type='text'
                                                name="stopprice"
                                                min='0'
                                                value={stopprice}
                                                onBlur={() => { setSEdit(false) }}
                                                onFocus={(e) => { setSEdit(true) }}
                                                onChange={handleChange} />
                                              <div className='input-group-append font_sm_ap' onClick={() => { increaseTpPrice('stopprice') }}>
                                                <i className='fa fa-plus' />
                                              </div>
                                              <div className='input-group-append font_sm_ap' onClick={() => { decreaseTpPrice('stopprice') }}>
                                                <i className='fa fa-minus' />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-12 col-md-12 py-1">
                                            <div className='input_grp_number input_typ_gere p-0'>
                                              <input className="selectcount themeselect  w-full"
                                                type='text'
                                                name="spPip"
                                                min='0'
                                                value={spPip}
                                                onChange={handleChange}
                                              />
                                              <div className='input-group-append font_sm_ap' onClick={() => { increasetpPip('stopprice') }}>
                                                <i className='fa fa-plus' />
                                              </div>
                                              <div className='input-group-append font_sm_ap' onClick={() => { decreasetpPip('stopprice') }}>
                                                <i className='fa fa-minus' />
                                              </div>
                                            </div>
                                            <span className="text-danger">{errors && errors?.spPip}</span>
                                          </div>
                                          <div className="col-12 col-md-12 py-1">
                                            <div className='input_grp_number input_typ_gere p-0'>
                                              <input className="selectcount themeselect  w-full"
                                                type='text'
                                                name="spUsd"
                                                min='0'
                                                value={spUsd}
                                                onChange={handleChange}
                                              />
                                              <div className='input-group-append font_sm_ap' onClick={() => { increasetpUsd('stopprice') }}>
                                                <i className='fa fa-plus' />
                                              </div>
                                              <div className='input-group-append font_sm_ap' onClick={() => { decreasetpUsd('stopprice') }}>
                                                <i className='fa fa-minus' />
                                              </div>
                                            </div>
                                            <span className="text-danger">{errors && errors?.spUsd}</span>
                                          </div>
                                          {/* <div className="col-12 col-md-12 py-1">
                                          <div className='input_grp_number input_typ_gere p-0'>
                                            <input className="selectcount themeselect  w-full"
                                              type='text'
                                              name="quantity"
                                              min='0'
                                            // value={volume}
                                            // onChange={handleChange} 
                                            />
                                            <div className='input-group-append font_sm_ap' onClick={() => { increaseVolume(volume) }}>
                                              <i className='fa fa-plus' />
                                            </div>
                                            <div className='input-group-append font_sm_ap' onClick={() => { decreaseVolume(volume) }}>
                                              <i className='fa fa-minus' />
                                            </div>
                                          </div>
                                        </div> */}
                                        </div>
                                      </div> :
                                        ""}
                                    </div>
                                    {stopopen || takeopen ? <div className="col-2 text-center py-0">
                                      <div className="row py-0 stoptake">
                                        <div className="col-12 col-md-12 py-1">
                                          <label className='mb-0'>Price</label>
                                        </div>
                                        <div className="col-12 col-md-12 py-1">
                                          <label className='mb-0'>Pips</label>
                                        </div>
                                        <div className="col-12 col-md-12 py-1">
                                          <label className='mb-0'>USD</label>
                                        </div>
                                        {/* <div className="col-12 col-md-12 py-1">
                                        <label className='mb-0'>%</label>
                                      </div> */}
                                      </div>
                                    </div> : ""}
                                    <div className="col-5 col-sm-5 py-0">
                                      {takeopen ?
                                        <div className="form_open  py-0" id="form_take">
                                          <div className="row  py-0">
                                            <div className="col-12 col-md-12 py-1">
                                              <div className='input_grp_number input_typ_gere p-0'>
                                                <input className="selectcount themeselect  w-full"
                                                  type='text'
                                                  name="takeprofit"
                                                  min='0'
                                                  value={takeprofit}
                                                  onBlur={() => { setTEdit(false) }}
                                                  onFocus={(e) => { setTEdit(true) }}
                                                  onChange={handleChange} />
                                                <div className='input-group-append font_sm_ap' onClick={() => { increaseTpPrice('takeprofit') }}>
                                                  <i className='fa fa-plus' />
                                                </div>
                                                <div className='input-group-append font_sm_ap' onClick={() => { decreaseTpPrice('takeprofit') }}>
                                                  <i className='fa fa-minus' />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-md-12 py-1">
                                              <div className='input_grp_number input_typ_gere p-0'>
                                                <input className="selectcount themeselect  w-full"
                                                  type='text'
                                                  name="tpPip"
                                                  min='0'
                                                  value={tpPip}
                                                  onChange={handleChange}
                                                />
                                                <div className='input-group-append font_sm_ap' onClick={() => { increasetpPip('takeprofit') }}>
                                                  <i className='fa fa-plus' />
                                                </div>
                                                <div className='input-group-append font_sm_ap' onClick={() => { decreasetpPip('takeprofit') }}>
                                                  <i className='fa fa-minus' />
                                                </div>
                                              </div>
                                              <span className="text-danger">{errors && errors?.tpPip}</span>
                                            </div>
                                            <div className="col-12 col-md-12 py-1">
                                              <div className='input_grp_number input_typ_gere p-0'>
                                                <input className="selectcount themeselect  w-full"
                                                  type='text'
                                                  name="tpUsd"
                                                  min='0'
                                                  value={tpUsd}
                                                  onChange={handleChange}
                                                />
                                                <div className='input-group-append font_sm_ap' onClick={() => { increasetpUsd('takeprofit') }}>
                                                  <i className='fa fa-plus' />
                                                </div>
                                                <div className='input-group-append font_sm_ap' onClick={() => { decreasetpUsd('takeprofit') }}>
                                                  <i className='fa fa-minus' />
                                                </div>
                                              </div>
                                              <span className="text-danger">{errors && errors?.tpUsd}</span>
                                            </div>
                                            {/* <div className="col-12 col-md-12 py-1">
                                            <div className='input_grp_number input_typ_gere p-0'>
                                              <input className="selectcount themeselect  w-full"
                                                type='text'
                                                name="quantity"
                                                min='0'
                                              // value={volume}
                                              // onChange={handleChange}
                                              />
                                              <div className='input-group-append font_sm_ap' onClick={() => { increaseVolume(volume) }}>
                                                <i className='fa fa-plus' />
                                              </div>
                                              <div className='input-group-append font_sm_ap' onClick={() => { decreaseVolume(volume) }}>
                                                <i className='fa fa-minus' />
                                              </div>
                                            </div>
                                          </div> */}
                                          </div>
                                        </div> :
                                        ""}
                                    </div>
                                  </div>
                                  {/* <textarea
                                    placeholder="Comment"
                                    className="mt-2 mb-3 form-control text-white system_textarea"
                                    type="textarea"
                                    name='comment'
                                    vale={comment}
                                    onChange={(e) => { handleChange(e) }}
                                  /> */}
                                  {stoplimitloader ?
                                    <button className={buyorsell == 'buy' ? "text-white system_placeorderBtn loading_btn" : "text-white system_placeorderBtn loading_btn red"} disabled>
                                      <i className="fa fa-spinner mr-2"></i>
                                      <span>Loading..</span>
                                    </button>
                                    :
                                    isAuth === true ? <button className={buyorsell == 'buy' ? "text-white system_placeorderBtn" : 'text-white system_placeorderBtn red'}
                                      disabled={StopDisable && StopDisable}
                                      onClick={() => {
                                        setstoplimitloader(true)
                                        // setSubmited('limit')
                                        handleSubmit()
                                      }}
                                    >
                                      Place Stop Limit Order
                                    </button> : <button className="text-white system_placeorderBtn" onClick={handleClick}>Login</button>}
                                </Tab>
                              </Tabs>
                            </div>

                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                    <Accordion className="calendar_accordion p-0">
                      <Card>
                        <Card.Header className="p-0">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                          >
                            <div className="py-0 card_symbol_acc">
                              <button onClick={() => setShrinkcont(true)} className="btn removeinexpand btn-link text-white card_symbol_acc text-center">
                                <i className="fa fa-info-circle f-12 text-light"></i>
                              </button>
                              <button className="btn btn-link removeinshrink text-white card_symbol_acc">
                                <i className="fa fa-info-circle mr-2 f-12 text-light"></i>
                                <span className="">Symbol Info</span>
                              </button>
                            </div>
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body className="p-0">
                            <ul className="pl-0 symbolTab_list">
                              <li>
                                <div className="d-flex p-0 justify-content-between">
                                  <small className="text-light">Base Asset</small>
                                  <small className="text-white">{pairData && pairData.firstCurrencySymbol}</small>
                                </div>
                              </li>
                              <li>
                                <div className="d-flex p-0 justify-content-between">
                                  <small className="text-light">
                                    Quote Asset
                                  </small>
                                  <small className="text-white">{pairData && pairData.secondCurrencySymbol}</small>
                                </div>
                              </li>
                              <li>
                                <div className="d-flex p-0 justify-content-between">
                                  <small className="text-light">
                                    Pip Position
                                  </small>
                                  <small className="text-white">{pairData && pairData.pip_position}</small>
                                </div>
                              </li>
                              <li>
                                <div className="d-flex p-0 justify-content-between">
                                  <small className="text-light">
                                    Precision
                                  </small>
                                  <small className="text-white">{pairData && pairData.precision}</small>
                                </div>
                              </li>
                              <li>
                                <div className="d-flex p-0 justify-content-between">
                                  <small className="text-light">Lot Size</small>
                                  <small className="text-white">
                                    {pairData.firstCurrencySymbol}    {pairData && pairData.lotsize ? toLocaleString(parseFloat(pairData.lotsize)) : 0}
                                  </small>
                                </div>
                              </li>
                              <li>
                                <div className="d-flex p-0 justify-content-between">
                                  <small className="text-light">
                                    Minimum Trade Volume
                                  </small>
                                  <small className="text-white">
                                    {pairData && pairData.minQuantity ? toLocaleString(parseFloat(pairData.minQuantity)) : 0} Lots
                                  </small>
                                </div>
                              </li>
                              <li>
                                <div className="d-flex p-0 justify-content-between">
                                  <small className="text-light">
                                    Maximum Trade Volume
                                  </small>
                                  <small className="text-white">
                                    {pairData && pairData.maxQuantity ? toLocaleString(parseFloat(pairData.maxQuantity)) : 0} Lots
                                  </small>
                                </div>
                              </li>
                              <li>
                                <div className="d-flex p-0 justify-content-between">
                                  <small className="text-light">
                                    Buy Positions
                                  </small>
                                  <small className="text-white">
                                    {pairData && pairData.buyposition?.sum ? lotDisplay(parseFloat(pairData.buyposition?.sum), pairData, pairData.ask).toFixed(2) : 0} Lots
                                  </small>
                                </div>
                              </li>
                              <li>
                                <div className="d-flex p-0 justify-content-between">
                                  <small className="text-light">
                                    Sell Positions
                                  </small>
                                  <small className="text-white">
                                    {pairData && pairData.sellposition?.sum ? lotDisplay(parseFloat(pairData.sellposition?.sum), pairData, pairData.bid).toFixed(2) : 0} Lots
                                  </small>
                                </div>
                              </li>
                              <li>
                                <div className="d-flex p-0 justify-content-between">
                                  <small className="text-light">
                                    Commission
                                  </small>
                                  <small className="text-white">
                                    {pairData && pairData.commission}
                                  </small>
                                </div>
                              </li>
                              <li>
                                <div className="d-flex p-0 justify-content-between">
                                  <small className="text-light">
                                    Swap Long
                                  </small>
                                  <small className="text-white">
                                    {pairData && pairData.swapLong}
                                  </small>
                                </div>
                              </li>
                              <li>
                                <div className="d-flex p-0 justify-content-between">
                                  <small className="text-light">
                                    Swap Short
                                  </small>
                                  <small className="text-white">
                                    {pairData && pairData.swapShort}
                                  </small>
                                </div>
                              </li>
                              {pairData.fees && pairData.fees.length > 0 && pairData.fees.map((item, key) => (
                                <li>
                                  <div className="d-flex p-0 justify-content-between">
                                    <small className="text-light">{Object.keys(item)}</small>
                                    <small className="text-white">{Object.values(item)} pip</small>
                                  </div>
                                </li>
                              ))
                              }
                              <li>
                                <div className="d-flex p-0 justify-content-between">
                                  <small className="text-light">
                                    FIX Symbol ID
                                  </small>
                                  <small className="text-white">{pairData && pairData.quoteId}</small>
                                </div>
                              </li>
                            </ul>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                    <Accordion className="calendar_accordion p-0">
                      <Card>
                        <Card.Header className="p-0">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                          >
                            <div className="py-0 card_symbol_acc">

                              <button onClick={() => setShrinkcont(true)} className="btn removeinexpand btn-link text-white card_symbol_acc text-center">
                                <i className="fas fa-chart-pie"></i>
                              </button>
                              <button className="btn btn-link removeinshrink text-white card_symbol_acc">
                                <i className="fas fa-chart-pie me-2"></i>
                                <span className=""> Depth of Market (Standard)</span>
                              </button>

                            </div>
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body className="p-0">
                            <div className="d-flex gap-1 px-0">
                              <div className="darkbox h-100-calc w-full">
                                <p className="f-12 font_12_must bg-success px-0 br-4">
                                  <div className="p-0 div_scrol_mobi_sym">
                                    {
                                      buyOrder && buyOrder.length > 0 && buyOrder.map((item, key) => {
                                        return (
                                          <div className="row mx-0 px-0 div_scrol_mobi_sym_row">
                                            <span className="col-4 darkgreenText px-1 div_scrol_mobi_span">{toLocaleString(parseFloat(item._id.toFixed(pairData.secondFloatDigit)))}</span>
                                            <span className="col-4 px-1 div_scrol_mobi_span">{toLocaleString(parseFloat(item.quantity.toFixed(pairData.firstFloatDigit)))}</span>
                                            <span className="col-4 px-1 div_scrol_mobi_span">{toLocaleString(parseFloat(item.total.toFixed(pairData.firstFloatDigit)))}</span>
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                </p>
                              </div>
                              <div className="darkbox h-100-calc w-full">
                                <p className="d-flex font_12_must f-12 bg-orange px-0 br-4">
                                  <div className="p-0 div_scrol_mobi_sym">
                                    {
                                      sellOrder && sellOrder.length > 0 && sellOrder.map((item, key) => {
                                        return (
                                          <div className="row mx-0 px-0 div_scrol_mobi_sym_row">
                                            <span className="col-4 pinkTexttext_white_pink px-1 div_scrol_mobi_span">
                                              {toLocaleString(parseFloat(item._id.toFixed(pairData.secondFloatDigit)))}
                                            </span>
                                            <span className="col-4 px-1 div_scrol_mobi_span">{toLocaleString(parseFloat(item.quantity.toFixed(pairData.firstFloatDigit)))}</span>
                                            <span className="col-4 px-1 div_scrol_mobi_span">{toLocaleString(parseFloat(item.total.toFixed(pairData.firstFloatDigit)))}</span>
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                </p>
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>

                    <Accordion className="calendar_accordion p-0">
                      <Card>
                        <Card.Header className="p-0">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                          >
                            <div className="py-0 card_symbol_acc">
                              <button onClick={() => setShrinkcont(true)} className="btn removeinexpand btn-link text-white card_symbol_acc text-center">
                                <i className="fa fa-clock mr-2 f-12 text-light"></i>
                              </button>
                              <button className="btn btn-link removeinshrink text-white card_symbol_acc">
                                <i className="fa fa-clock mr-2 text-light f-12"></i>
                                <span className="">Market Hours</span>
                              </button>
                            </div>

                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body className="p-0">
                            {/* <button className="btn btn-link text-white card_symbol_acc"> */}
                            <p>  {markethoures && markethoures}</p>
                            {/* </button> */}
                            {/* {markethoures && markethoures.length > 0 ? markethoures.map((val, index) => (
                            <>
                              <button className="btn btn-link text-white card_symbol_acc">
                                {val}
                              </button>
                            </>
                          )) : ''} */}
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>

                    <Accordion className="calendar_accordion p-0">
                      <Card>
                        <Card.Header className="p-0">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                          >
                            <div className="py-0 card_symbol_acc">
                              <button onClick={() => setShrinkcont(true)} className="btn removeinexpand btn-link text-white card_symbol_acc text-center">
                                <i class="fa fa-credit-card text-light f-12"></i>
                              </button>
                              <button className="btn btn-link removeinshrink text-white card_symbol_acc">
                                <i class="fa fa-credit-card text-light f-12 mr-2"></i>
                                <span className="">Leverage</span>
                              </button>
                            </div>
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body className="p-0">
                            <div className="darkbox">
                              <div className="table-responsive p-0 table_scroll_color_new">
                                <table className="w-full text-white">
                                  <tr>
                                    <th className="text-center">
                                      <label>Volume</label>
                                    </th>
                                    <th className="text-center">
                                      <label>Leverage</label>
                                    </th>
                                  </tr>
                                  <tr>
                                    <td className="text-center">Any</td>
                                    <td className="text-center">{leverage && leverage}</td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div>
                </Tab>
                <Tab eventKey="dom" title="DoM">
                  <div className="d-flex jc-between align-items-center wishdrop gap-2 px-0">
                    <div className='custdrop w-full px-0'>
                      <div className='d-flex gap-1 p-0 '>
                        <div className='drop w-full p-0' onClick={() => setSymbolTab(!symbolTab)}>
                          <p className='d-flex jc-between align-items-center p-1 f-12'>{pairData && pairData.displayname}<span className="fa fa-chevron-down"></span></p>
                          {/* {console.log("ppairdata2", pairData.displayname)} */}
                        </div>
                      </div>
                      <div className={symbolTab ? "d-block wishdrop themetab" : "d-none wishdrop themetab"}>
                        <Symboltab onClose={() => { setSymbolTab(false) }} />
                      </div>
                    </div>
                  </div>
                  <div className="innerdomtab">
                    <Tabs defaultActiveKey="standarddom" id="uncontrolled-tab-example" className="withot_wrap_blk graytab pt-2 px-0">
                      <Tab eventKey="standarddom" title="Standard DoM" className="px-0">
                        <div className="d-flex gap-1 px-0">
                          <div className="darkbox h-100-calc w-full">
                            <p className="f-12 font_12_must bg-success px-0 br-4">
                              <div className="p-0 div_scrol_mobi_sym">
                                {
                                  buyOrder && buyOrder.length > 0 && buyOrder.map((item, key) => {
                                    return (
                                      <div
                                        className={clsx("tradeTableBodyRow row mx-0 px-0 div_scrol_mobi_sym_row", { "odd": key % 2 == 1 }, { "even": key % 2 == 0 }, "row mx-auto")}
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        data-html="true"
                                      // title="<b>Avg.Price:</b> = 7,138.75<br /><b>Sum BTC:</b> 1.302019<br /><b>Sum USDT:</b> 14,279.6600000"
                                      >
                                        <span className="col-4 darkgreenText px-1 div_scrol_mobi_span">{toLocaleString(parseFloat(item._id.toFixed(pairData.precision)))}</span>
                                        <span className="col-4 darkgreenText px-1 div_scrol_mobi_span">{toLocaleString(parseFloat(item.quantity.toFixed(pairData.firstFloatDigit)))}</span>
                                        <span className="col-4 darkgreenText px-1 div_scrol_mobi_span">{toLocaleString(parseFloat(item.total.toFixed(pairData.firstFloatDigit)))}</span>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            </p>
                          </div>
                          <div className="darkbox h-100-calc w-full">
                            <p className="d-flex jc-between f-12 bg-orange px-0 br-4">
                              <div className="p-0 div_scrol_mobi_sym">
                                <span className="">

                                  {
                                    sellOrder && sellOrder.length > 0 && sellOrder.map((item, key) => {
                                      return (
                                        <div
                                          className={clsx("tradeTableBodyRow row mx-0 px-0 div_scrol_mobi_sym_row", { "odd": key % 2 == 1 }, { "even": key % 2 == 0 }, "row mx-auto")}
                                        >
                                          <span className="col-4 pinkTexttext_white_pink px-1 div_scrol_mobi_span">{toLocaleString(parseFloat(item._id.toFixed(pairData.precision)))}</span>
                                          <span className="col-4 pinkTexttext_white_pink px-1 div_scrol_mobi_span">{toLocaleString(parseFloat(item.quantity.toFixed(pairData.firstFloatDigit)))}</span>
                                          <span className="col-4 pinkTexttext_white_pink px-1 div_scrol_mobi_span">{toLocaleString(parseFloat(item.total.toFixed(pairData.firstFloatDigit)))}</span>
                                        </div>
                                      )
                                    })
                                  }
                                </span>
                              </div>
                            </p>
                          </div>
                        </div>
                      </Tab>
                    </Tabs>
                    <div className="tab_footer_dom py-0">
                      <div className="row py-0">
                        <div className="col-5 py-0">
                          <button class="text-light btn mt-0 mx-0 mb-1 py-1 px-1 f-11" disabled={!CancelSell} onClick={() => { CancelTrade('sell') }}>Cancel Sell Orders{!CancelSell}</button>
                          <button class="text-light btn mt-0 mx-0 mb-1 py-1 px-1 f-11" disabled={!CloseSell} onClick={() => { ClosePosition('sell') }}>Close Sell Positions</button>
                        </div>
                        <div className="col-2 py-0 d-flex align-items-center justify-content-center">
                          <p className="text-silver-sm mb-0">{PandLSingel && PandLSingel.toFixed(2)}</p>
                        </div>
                        <div className="col-5 py-0">
                          <button class="text-light btn mt-0 mx-0 mb-1 py-1 px-1 f-11" disabled={!CancelBuy} onClick={() => { CancelTrade('buy') }}>Cancel Buy Orders{!CancelBuy}</button>
                          <button class="text-light btn mt-0 mx-0 mb-1 py-1 px-1 f-11" disabled={!CloseBuy} onClick={() => { ClosePosition('buy') }}>Close Buy Positions</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="calendar" title="Calendar">
                  <div className="d-flex jc-between align-items-center wishdrop gap-2 px-0">
                    <div className='custdrop w-full px-0'>
                      <div className='d-flex gap-1 p-0 '>
                        <div className='drop w-full p-0' onClick={() => setSymbolTab(!symbolTab)}>
                          <p className='d-flex jc-between align-items-center p-1 f-12'>{pairData && pairData.displayname} <span className="fa fa-chevron-down"></span></p>
                          {/* {console.log("ppairdata3", pairData.displayname)} */}
                        </div>
                        <select className="darkselect selectcount py-1" name='weeks' onChange={(e) => { Dateformate(e) }} >
                          <option value="Today" >Today</option>
                          <option value="Tomorrow" >Tomorrow</option>
                          <option value="Yesterday" >Yesterday</option>
                          <option value="Previous Week" >Previous Week</option>
                          <option value="Next Week" >Next Week</option>
                          <option value="This Week" >This Week</option>
                        </select>
                      </div>
                      <div className={symbolTab ? "d-block wishdrop themetab" : "d-none wishdrop themetab"}>
                        <Symboltab onClose={() => { setSymbolTab(false) }} />
                      </div>
                    </div>
                  </div>
                  <div className="darkbox h-100-percent px-0">
                    <p className="text-silver-sm mb-0"> {filterdatas ? filterdatas : "Today"} {Dates ? Dates : new Date().toLocaleDateString()}</p>
                    <hr className="mt-0 mb-0" />

                    <div className="pt-0">


                      <div className='acc-item w-full mb-1 calendar_row p-0'>

                        {calender && calender.length > 0 && calender.map((val, index) => {

                          let Dates = new Date(new Date(val.time).getTime() - new Date().getTime()).getHours()
                          return (<Accordion className='calendar_accordion py-0 px-1'>
                            <Card>
                              <Card.Header className='p-0'>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                  <div className="w-full p-0 w-full-cus">
                                    <div className='text-light p-0'>
                                      <div className="d-flex justify-content-between p-0 align-items-center">
                                        <div className="p-0">
                                          <i className="fas fa-clock text-grey-sm pr-1"></i>
                                          <span className="text-silver-sm mb-0">{momentFormat(val.time, 'HH:mm')} (UTC+3)</span>
                                          <span className="text-grey-sm mb-0 pl-2">Time:</span>
                                          <span className="text-silver-sm mb-0">{Dates ? Dates : "-"}hours ago</span>
                                        </div>
                                        <div className="pl-2 d-flex align-items-center">
                                          {/* <img src="https://lipis.github.io/flag-icon-css/flags/48x32/gb.svg" style="height:100"></img> */}
                                          <img src={`https://flagsapi.com/${val.country}/flat/64.png`} className="mr-1 flag_width" />
                                          <span className="text-grey-sm mb-0">{val.country ? val.country : "-"}</span>
                                        </div>
                                      </div>
                                      <p className="text-silver-sm mb-0 text-left">{val.title ? val.title : "-"}</p>
                                      <div className="row p-0 row_rate mt-1">
                                        <div className="col-3 text-center">
                                          <div className="d-flex justify-content-center align-items-end">
                                            <span className={val.impact == 1 ? "graph_1 yellow" : val.impact == 2 ? "graph_1 yellow" : val.impact == 3 ? "graph_1 yellow" : 'graph_1'}></span>
                                            <span className={val.impact == 2 ? "graph_2 yellow" : val.impact == 3 ? "graph_2 yellow" : val.impact == 1 ? "graph_2 " : "graph_2 yellow"}></span>
                                            <span className={val.impact == 3 ? "graph_3 yellow" : "graph_3"}></span>
                                          </div>
                                          <p className="text-grey-sm mb-0">Impact</p>
                                        </div>
                                        <div className="col-3 text-center">
                                          <p className="text-silver-sm mb-0 minh_22">{val.impact ? val.impact : "-"}</p>
                                          <p className="text-grey-sm mb-0">Impact</p>
                                        </div>
                                        <div className="col-3 text-center">
                                          <p className="text-silver-sm mb-0 minh_22">-</p>
                                          <p className="text-grey-sm mb-0">Consensus</p>
                                        </div>
                                        <div className="col-3 text-center">
                                          <p className="text-silver-sm mb-0 minh_22">{val.previous ? val.previous : "-"}</p>
                                          <p className="text-grey-sm mb-0">Previous</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Accordion.Toggle>
                              </Card.Header>
                              <Accordion.Collapse eventKey="0">
                                <Card.Body className='p-0'>
                                  <div class="acc_cal_desc p-0">The Unemployment Rate released by the <a target="_blank" href="#">Statistical Office of the Slovak Republic</a> is the number of unemployed workers divided by the total civilian labor force. It is a leading indicator for the Slovakian economy. If the rate is up, it indicates a lack of expansion within the Slovakian labor market. As a result, a rise leads to weakening in the economy.</div>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          </Accordion>)
                        })}
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
        {Markupprice && infoorderdata && infoorder && <InfoOrder Markupprice={Markupprice} infodatas={infoorderdata} pairData={InfopairData} onDismiss={() => setInfoOrder(false)} />}
        {/* {infoorderdata && <InfoOrder infodatas={infoorderdata}/>} */}
      </div>
    </>
  );
};



export default ShowcaseLayout;