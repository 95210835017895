import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "classnames";
import { useTranslation } from 'react-i18next';
import _ from "lodash";
import "./styles.css";
import "./example-styles.css";
import { Tabs, Tab, InputGroup, FormControl, Form, Button, Dropdown, Accordion, Card, Col, Row } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Strategy from '../SportTrade/newtrade/Modals/Strategy'
import StartCopying from './newtrade/Modals/StartCopying'
import ReactHtmlParser from 'react-html-parser';

//import action 
import { stopStrategy, getSingelStrategy, RestartProviding, TraderDetails } from "actions/copyTradeAction";
import { toastAlert } from 'lib/toastAlert';


// import component
import OrderPlace from "./OrderPlace";
import MarketPrice from "./MarketPrice";
import OrderBook from "./OrderBook";
import UserOrderList from "./UserOrderList";
import MarketPriceTable from "./Markerpricetable";
import NewOrder from './newtrade/Modals/NewOrder';
import DoneOrder from './newtrade/Modals/DoneOrder';
import Chart from "react-apexcharts";
// import Chart from "./Chart";
import RecentTrade from "./RecentTrade";
import Sidebar from "./newtrade/Sidebar";
// import Sidebar from "./newtrade/Sidebar";

import info from '../../assets/images/newtrade/info.svg'
import setting from '../../assets/images/newtrade/setting.svg'
import shield from '../../assets/images/newtrade/shield.svg'
import reverse from '../../assets/images/newtrade/reverse.svg'
import double from '../../assets/images/newtrade/double.svg'
import close from '../../assets/images/newtrade/close.svg'
import star from '../../assets/images/newtrade/star.svg'
import flag from '../../assets/images/newtrade/flag.svg'
import Wishlisttab from "./newtrade/elements/Wishlisttab";
import Symboltab from "./newtrade/elements/Symboltab";
import RoiEquidity from "./CopyElements/RoiEquidity";
import Details from "./CopyElements/Details";
import RoiChart from "./CopyElements/RoiChart";
import Summary from "./CopyElements/Summary";
import Performance from "./CopyElements/Perfomance";
import PerformanceStat from "./CopyElements/PerformanceStat";
import VolumeBreak from "./CopyElements/VolumeBreak";
import Volume from "./CopyElements/Volume";
import Trades from "./CopyElements/Trades";
import CopierStat from "./CopyElements/CopierStat";
import Symbols from "./CopyElements/Symbols";
import Position from "./CopyElements/Position";
import History from "./CopyElements/History";

//lib
import { strategyAge } from '../../lib/bybit'
//import model 
import ConfirmModal from "./newtrade/Modals/ConfirmModal";
import EditStartegy from "./newtrade/Modals/EditStrategy";
import { getproviderSummary } from "actions/copyTradeAction";
const CopyTradeProviderDetails = (props) => {
  console.log("props_propspropsvpropsvv", props);
  const dispatch = useDispatch()
  const [chart, setchart] = useState(data)
  const orderBookRef = useRef();
  const [startCopying, setStartCopying] = useState(false)
  const { t, i18n } = useTranslation();
  const { state } = useLocation()
  console.log(state, 'state--->')
  // state
  const [expandScreen, setExpandScreen] = useState("");
  const [strategy, setStrategy] = useState(false)
  const [Accountdata, setAccountdata] = useState()
  console.log("Accountdata_Accountdata_Accountdata_", Accountdata);
  const [CopyingData, setCopyingData] = useState({})
  console.log("CopyingData_CopyingData_V", CopyingData);
  const [currentBreakpoint, setCurrentBreakpoint] = useState("lg");
  const [compactType, setCompactType] = useState("vertical");
  const [mounted, setMounted] = useState(false);
  const [layouts, setLayouts] = useState({
    lg: props.initialLayout,
  });
  const [neworder, setNewOrder] = useState(false)
  const [doneorder, setDoneOrder] = useState(false)
  const [positiondata, setPositiondata] = useState(0)
  const [flatnav, setFlatNav] = useState(false)
  const [symbolTab, setSymbolTab] = useState(false)
  const [shrink, setShrink] = useState(false)
  const [providered, setprovidered] = useState({})
  // console.log("providered_providered_providered_V", providered);
  const [provider, setprovider] = useState({})
  const [conFirmed, setconFirmed] = useState(false)
  const [conFirm, setconFirm] = useState(false)
  console.log("conFirm_conFirm_conFirm_", conFirm);
  const [isEdit, setEdit] = useState(false)
  const [show, setShow] = useState(false)
  const [notProvider, setNotProvider] = useState(false)
  const [summaryDetails, setSummaryDetails] = useState()
  console.log("notProvider_notProvider_notProvider", notProvider);
  //redux
  const { strategyDetails } = useSelector(state => state.copy)
  const { accounts, useraccount } = useSelector(state => state.account)
  console.log("accounts_accounts_accounts", accounts);
  const position = <>Position <span className="badge badge-secondary px-2">{positiondata && positiondata}</span></>

  const orders = <>Orders <span className="badge badge-secondary px-2">0</span></>
  const priceallert = <>Price alert <span className="badge badge-secondary px-2">0</span></>


  const setWhole = (value) => {
    console.log(value, "value");
    // 👇️ take parameter passed from Child component
    setShrink(value)
  }
  // function
  const onBreakpointChange = (breakpoint) => {
    setCurrentBreakpoint(breakpoint);
  };

  useEffect(() => {
    setMounted(true);
    setNotProvider(false)
    setShow(false)
    getStrategy()
    getproviderSummaryDetails()
    console.log("strategyDetails_strategyDetails_V", strategyDetails);

    let provider = strategyDetails?.find((val) => val.accountId == state?.accountId)
    console.log("provider_provider_provider_providerv", provider);
    setprovidered(provider)

  }, [state, strategyDetails]);

  const getStrategy = async () => {
    try {
      let data = {
        traderAccountId: state?.accountId,
        traderId: strategyDetails[0]?.userId
      }
      let { status, message, error, result } = await getSingelStrategy(state?.accountId)
      console.log("message_messagemessageV_", data, result, message);
      if (result?.length > 0) {
        let vali = accounts?.find((val) => val.accountId == result[0]?.accountId);
        console.log("vali_vali_vali", vali, notProvider);
        if (vali == undefined || vali == null || vali == '' || result[0].status == 'Inactive') {
          setShow(false)
        } else {
          console.log("hiii");
          setShow(true)
        }
        if (message == "There is no strategy") {
          console.log("hiii", message);
          setprovider({})
          setNotProvider(true)
        }
        console.log("vali_vali", vali);
        setprovider(result[0])
      }
      else if (message == "There is no strategy") {
        console.log("hiii", message);
        setprovider({})
        setNotProvider(true)
      }
    } catch (err) {
      console.log(err, 'getStrategy__err')
    }
  }
  const favorites = <>Favotites <span className="badge badge-secondary px-2">0</span></>


  const [showdrop, setShowDrop] = useState(false)
  const [showdropdate, setShowDropDate] = useState(false)


  const [info, setInfo] = useState(false)
  const [active, isActive] = useState(1)
  const [expand, setExpand] = useState(false)

  // data reuse
  const [mode, setMode] = useState("live")
  const [confirmLoader,setconfirmLoader] = useState(false)
  const getproviderSummaryDetails = async () => {
    let provider = strategyDetails?.find((val) => val.accountId == state?.accountId)
    console.log('Summary_provider_details_01', provider);
    const getData = await getproviderSummary({ accountId: provider?.accountId, userId: provider?.userId }, dispatch);
    setSummaryDetails(getData.result)
    console.log('Summary_provider_details', getData);
  }
  const openAcc = (e) => {
    console.log(e.target.nextElementSibling, "e");
    var cont = e.target.parentNode.nextElementSibling
    if (!(cont.classList.contains("d-none"))) {
      cont.classList.add("d-none")
    } else {
      cont.classList.remove("d-none")
    }
  }

  // const strategyAge = (createdDate) => {
  //   let differ = new Date(createdDate).getTime() - new Date().getTime()
  //   var daydiff = Math.abs(differ / (1000 * 60 * 60 * 24), 0).toFixed(0);
  //   var hrsdiff = new Date().getHours()
  //   console.log(new Date(hrsdiff * 1000), 'hrsdiff')
  //   var age = daydiff + 'd ' + hrsdiff + 'h'
  //   return age
  // }

  const StopProvidingStrategy = async (accountId) => {
    console.log("accountId_accountId_Vcvddvsd", accountId);
    setconfirmLoader(true)
    try {
      let data = {
        accountId: accountId,
        statue: 'Inactive'
      }
      console.log(data, 'StopProvidingStrategy')
      let { status, loading, message } = await stopStrategy(data, dispatch);
      if (status) {
        setconfirmLoader(false)
        toastAlert('success', message, 'stopStrategy');
        //  setShow(false)
      } else {
        setconfirmLoader(false)
        toastAlert('error', message, 'stopStrategy');
      }
    } catch (err) {

      console.log(err, "errrrrrrrrrrrrrrr")
      toastAlert('error', 'error occurred', 'stopStrategy');
    }
  }

  const RestartProvidingStrategy = async (accountId) => {
    console.log("accountId_accountId_V", accountId);
    setconfirmLoader(true)
    try {
      console.log("higiigihgihi");
      let data = {
        accountId: accountId,
        status: 'active'
      }

      console.log("datatdadadadtadda", data);
      let { status, loading, message } = await RestartProviding(data, dispatch);
      console.log("message_Vmessagemessage", status);
      if (status) {
        setconfirmLoader(false)
        toastAlert('success', message, 'RestartStrategy');
        //  setShow(false)
      } else {
        setconfirmLoader(false)
        toastAlert('error', message, 'RestartStrategy');
      }
    } catch (err) {
      console.log("err_err_err_", err);

      toastAlert('error', 'error occurred', 'RestartStrategy');
    }
  }

  const data = {
    options: {
      chart: {
        id: "basic-bar",
        foreColor: "#ccc",
        toolbar: {
          autoSelected: "pan",
          show: false
        }
      },
      colors: ["#2769ba"],
      stroke: {
        width: 3
      },
      grid: {
        borderColor: "#555",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: false
          },
        }
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        gradient: {
          enabled: true,
          opacityFrom: 0.75,
          opacityTo: 0
        }
      },
      markers: {
        size: 2,
        colors: ["#2769ba"],
        strokeColor: "#2769ba",
        strokeWidth: 3
      },
      tooltip: {
        theme: "dark"
      },
      xaxis: {
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
      }
    },
    series: [
      {
        name: "series-1",
        data: [30, 40, 45, 50, 49, 60, 70, 91]
      },

    ]
  };
  // const data1 = {
  //   options1: {
  //     chart: {
  //       id: "basic-bar",
  //       foreColor: "#ccc",
  //       toolbar: {
  //         autoSelected: "pan",
  //         show: false
  //       }
  //     },
  //     colors: ["#2769ba"],
  //     stroke: {
  //       width: 3
  //     },
  //     grid: {
  //       borderColor: "#555",
  //       clipMarkers: false,
  //       yaxis: {
  //         lines: {
  //           show: false
  //         },
  //       }
  //     },
  //     dataLabels: {
  //       enabled: false
  //     },
  //     fill: {
  //       gradient: {
  //         enabled: true,
  //         opacityFrom: 0.75,
  //         opacityTo: 0
  //       }
  //     },
  //     markers: {
  //       size: 2,
  //       colors: ["#2769ba"],
  //       strokeColor: "#2769ba",
  //       strokeWidth: 3
  //     },
  //     tooltip: {
  //       theme: "dark"
  //     },
  //     xaxis: {
  //       categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
  //     },
  //     yaxis: {
  //       labels: {
  //         show: false
  //       }
  //     }
  //   },
  //   series1: [
  //     {
  //       name: "series-1",
  //       data: [30, 40, 45, 50, 49, 60, 70, 91]
  //     },

  //   ]
  // };

  // const data2 = {
  //   options2: {
  //     chart: {
  //       id: "basic-bar",
  //       foreColor: "#ccc",
  //       toolbar: {
  //         autoSelected: "pan",
  //         show: false
  //       }
  //     },
  //     colors: ['#009345', '#f05824'],
  //     stroke: {
  //       width: 3
  //     },
  //     grid: {
  //       borderColor: ["#555"],
  //       clipMarkers: false,
  //       yaxis: {
  //         lines: {
  //           show: false
  //         },
  //       }
  //     },
  //     dataLabels: {
  //       enabled: false
  //     },
  //     fill: {
  //       // gradient: {
  //       //   enabled: true,
  //       //   opacityFrom: 0.75,
  //       //   opacityTo: 0
  //       // }
  //       colors: ['#009345', '#f05824']
  //     },
  //     markers: {
  //       size: 2,
  //       colors: ["#2769ba"],
  //       strokeColor: "#2769ba",
  //       strokeWidth: 3
  //     },
  //     tooltip: {
  //       theme: "dark"
  //     },
  //     xaxis: {
  //       categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002]
  //     },
  //     yaxis: {
  //       labels: {
  //         show: false
  //       }
  //     }
  //   },
  //   series2: [{
  //     name: 'Winning Trades',
  //     type: 'column',
  //     data: [440, 505, 414, 571, 227, 413, 201, 352, 652, 320, 257, 160]
  //   }, {
  //     name: 'Losing Trades',
  //     type: 'column',
  //     data: [400, 450, 500, 300, 100, 500, 210, 300, 652, 300, 300, 50]
  //   }],
  // };


  // const data3 = {
  //   options3: {
  //     chart: {
  //       width: '100%',
  //       type: 'donut',
  //       foreColor: "#ccc",
  //       toolbar: {
  //         autoSelected: "pan",
  //         show: false
  //       }
  //     },
  //     colors: ["#fdac9c", "#ab70f4", "#35df91", "#fbc662", "#34a8ff", "#eb11d6"],
  //     fill: {
  //       // gradient: {
  //       //   enabled: true,
  //       //   opacityFrom: 0.75,
  //       //   opacityTo: 0
  //       // }
  //       colors: ["#fdac9c", "#ab70f4", "#35df91", "#fbc662", "#34a8ff", "#eb11d6"]
  //     },
  //     markers: {
  //       size: 2,
  //       colors: ["#fdac9c", "#ab70f4", "#35df91", "#fbc662", "#34a8ff", "#eb11d6"],
  //       strokeColor: "#2769ba",
  //       strokeWidth: 3
  //     },
  //     tooltip: {
  //       theme: "dark"
  //     },
  //     labels: ['US TECH 100', 'XAUUSD', 'Germany 30', 'EURUSD', 'Other', 'GBPJPY'],
  //     responsive: [{
  //       breakpoint: 480,
  //       options: {
  //         chart: {
  //           width: 230
  //         },
  //         dataLabels: {
  //           enabled: false,
  //         },
  //         legend: {
  //           position: 'bottom'
  //         }
  //       }
  //     }]
  //   },
  //   series3:
  //     //  [  
  //     [44, 55, 13, 43, 22, 80]
  //   // {
  //   //   name: "series-1",
  //   //   data: [44, 55, 13, 43, 22]
  //   // },

  //   // ]
  // };

  // const data4 = {
  //   options4: {
  //     chart: {
  //       id: "basic-bar",
  //       foreColor: "#ccc",
  //       toolbar: {
  //         autoSelected: "pan",
  //         show: false
  //       }
  //     },
  //     colors: ["#2769ba"],
  //     stroke: {
  //       width: 3
  //     },
  //     grid: {
  //       borderColor: "#555",
  //       clipMarkers: false,
  //       yaxis: {
  //         lines: {
  //           show: false
  //         },
  //       }
  //     },
  //     dataLabels: {
  //       enabled: false
  //     },
  //     fill: {
  //       gradient: {
  //         enabled: true,
  //         opacityFrom: 0.75,
  //         opacityTo: 0
  //       }
  //     },
  //     markers: {
  //       size: 2,
  //       colors: ["#2769ba"],
  //       strokeColor: "#2769ba",
  //       strokeWidth: 3
  //     },
  //     tooltip: {
  //       theme: "dark"
  //     },
  //     xaxis: {
  //       categories: [1991, 1992, 1993]
  //     },
  //     yaxis: {
  //       labels: {
  //         show: false
  //       }
  //     }
  //   },
  //   series4: [
  //     {
  //       name: "series-1",
  //       data: [30, 40, 45]
  //     },

  //   ]
  // };
  console.log(provider, "&nsbp;__&nsbp;")

  const history = useHistory();
  return (
    <div className={shrink ? "sticky_sidebar d-flex gap-3 shrink_div main_div_col" : "sticky_sidebar d-flex gap-3 main_div_col"}>
      <Sidebar setWhole={setWhole} activetabkey={"1"} id="side_copy" />
      {startCopying && <StartCopying
        onDismiss={() => setStartCopying(false)}
        copyingData={CopyingData}
        startedAccount={useraccount}
      />
      }
      {conFirm &&
        <ConfirmModal title='Stop Providing Strategy'
          message='If you stop providing strategy,all traders will be automatically stopped copying it.'
          onDismiss={() => setconFirm(false)}
          onCofirm={(accountId) => { StopProvidingStrategy(accountId) }}
          id={state.accountId}
          lodaer={confirmLoader && confirmLoader}
          />
      }
      {conFirmed &&
        <ConfirmModal title='Restart Strategy Providing'
          message='If you stop providing strategy, all traders will be automatically Restart Strategy Providing  it.'
          onDismiss={() => setconFirmed(false)}
          onCofirm={(accountId) => { RestartProvidingStrategy(accountId) }}
          id={state.accountId}
          lodaer={confirmLoader && confirmLoader}
          />
      }
      {isEdit && <EditStartegy onDismiss={() => setEdit(false)} strategy={provider} />}
      {neworder && <NewOrder onDismiss={() => setNewOrder(false)} openinfo={() => { setInfo(true); setNewOrder(false) }} />}
      {doneorder && <DoneOrder onDismiss={() => setDoneOrder(false)} />}
      {strategy && <Strategy accountDetails={Accountdata} onDismiss={() => setStrategy(false)} openinfo={() => { setInfo(true); setStrategy(false) }} />}
      <div className="w-full wfull_new calc_width">
        <div className="text-right-web-btn">
          <button class="margin_top_banck_bn btn btn-outline-secondary f-12 icon-50 p-0 px-2 mx-2 back_bt_det" onClick={() => history.push("/copy")}>Back</button>
        </div>
        <div className="darkbox darkbx_bg_new">
          <div className="inner_box_bg favscroll">
            <div className="w-full">
              <div className="darkbox">
                <div className="d-flex w-full block_mob_res_det justify-content-between h5-align align-items-center p-2 gap-2">
                  <div className="d-flex gap-1">
                    <span className="fa fa-star star_align"></span>
                    <div className="d-inline-block  pr-3 flex-1 jc-between p-0 ">
                      <div className="d-flex gap-4 jc-between p-0 mb-3">
                        <p>{provider && provider.strategyName ? provider.strategyName : 'Strategy name will be displayed here'}</p>
                        {console.log("provider?.type_provider?.type", provider)}
                        {provider && provider?.type == "live" ?
                          <button className="btn btn-outline-success f-12 icon-50 p-0 px-2 my-0">Live</button>
                          :
                          <button className="btn btn-outline-info f-12 icon-50 p-0 px-2 my-0">Demo</button>
                        }
                      </div>
                      <div className="d-flex jc-between p-0 flex_widh_min">
                        <span>Strategy Provider</span>
                        <div className="text-center">
                          <span>&nbsp;{provider && provider?.accountId}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab_content text-center align-items-center pr-3  pl-3 justify-content-center">
                    <span>All Time ROI</span>
                    <h5 className="text-success">{provider && provider?.returnOfInvestment ? provider?.returnOfInvestment?.toFixed(4) : "-"} %</h5>
                  </div>
                  <div className="tab_content text-center pr-3  pl-3 justify-content-center">
                    <span>Copiers</span>
                    <h5>{provider && provider?.curFollow ? provider.curFollow : "-"}</h5>
                  </div>
                  <div className="tab_content text-center align-items-center pr-3  pl-3 justify-content-center">
                    <span>Strategy Age</span>
                    <h5>{provider && provider?.createdDate ? strategyAge(provider.createdDate) : '-'}</h5>
                  </div>
                  {show && show == true ?
                    <div className="btn_bot_sps">
                      <button className="btn align-items-center text-center strat_copy_text btn-success bg-orange pt-0 justify-content-center" onClick={() => { setconFirm(true) }}>
                        <h6 className="text-white my-1 text-center">Stop Providing Strategy</h6>
                      </button>
                      <button className="btn align-items-center text-center strat_copy_text btn-success pt-0 justify-content-center" onClick={() => { setEdit(true) }}>
                        <h6 className="text-white my-1 text-center">Strategy Settings</h6>
                      </button>
                    </div>
                    :
                    notProvider == true ?
                      <button className="btn align-items-center text-center strat_copy_text btn-success pt-0 justify-content-center"
                        onClick={() => {
                          let accountdetails = accounts.find((value) => value.accountId == state?.accountId)
                          console.log("accountdetails_accountdetails_cdsfsd", accountdetails);
                          setAccountdata(accountdetails)
                          // Accountdata={Accountdata}
                          setStrategy(true)
                        }}
                      >
                        <h6 className="text-white" >Become a Strategy Provider</h6>
                      </button>
                      :
                      // providered == undefined || providered == null || providered == {} ?
                      //   <button className="btn align-items-center text-center strat_copy_text btn-success pt-0 justify-content-center">
                      //     <h6 className="text-white" onClick={(e) => { setCopyingData(provider); setStartCopying(true); e.stopPropagation() }} >Start Copying</h6>
                      //   </button>
                      // :
                      <button className="btn align-items-center text-center strat_copy_text btn-success bg-orange pt-0 justify-content-center">
                        <h6 className="text-white" onClick={() => { setconFirmed(true) }} >Restart Providing Strategy</h6>
                      </button>
                  }
                  {/* EITHER ONE */}
                  {/* <span className="text-light">Free</span> */}

                  {/* <span className="text-light">M:0.00%&nbsp;P:15.00%&nbsp;V:USD 2.50</span> */}
                  {/* EITHER ONE */}
                </div>
              </div>
            </div>
            <div className="m-1 boxstyles">
              <div className="row mb-0">
                <div className="col-12 col-lg-8 pr-3 pr-lg-0">
                  <div className="p-0 mt-1 gray_tab_marg_1 h-100">
                    <RoiEquidity TrdaerDetail={providered} Followerdetails='' type='provider' />
                    {/* <Tabs defaultActiveKey="roi" id="uncontrolled-tab-example" className="graytab pt-2 px-0">
                  <Tab eventKey="roi" title="ROI (%)" className="px-0">
                  <Tabs defaultActiveKey="1w" id="uncontrolled-tab-example2" className="graytab pt-2 px-0 tab_style_inner_grey">
                  <Tab eventKey="1w" title="1w" className="px-0">
                  <Chart options={data?.options}
                          series={data?.series}
                          type="area" height={200} width="100%" className="px-0" />
                  <p className="graph_desc_data">* ROI is updated every 15 minutes.</p>
                  </Tab>               
                  <Tab eventKey="1m" title="1m" className="px-0">
                  <Chart options={data?.options}
                          series={data?.series}
                          type="area" height={200} width="100%" className="px-0"/>
                  <p className="graph_desc_data">* ROI is updated every 15 minutes.</p>
                 
                  </Tab> 
                  <Tab eventKey="3m" title="3m" className="px-0">
                  <Chart options={data?.options}
                          series={data?.series}
                          type="area" height={200} width="100%" className="px-0"/>
                  <p className="graph_desc_data">* ROI is updated every 15 minutes.</p>
                 
                 </Tab> 
                 <Tab eventKey="6m" title="6m" className="px-0">
                 <Chart options={data?.options}
                          series={data?.series}
                          type="area" height={200} width="100%" className="px-0"/>
                  <p className="graph_desc_data">* ROI is updated every 15 minutes.</p>
                 
                 </Tab> 
                 <Tab eventKey="1y" title="1y" className="px-0">
                 <Chart options={data?.options}
                          series={data?.series}
                          type="area" height={200} width="100%" className="px-0"/>
                  <p className="graph_desc_data">* ROI is updated every 15 minutes.</p>
                 
                 </Tab>   
                 <Tab eventKey="all" title="All" className="px-0">
                 <Chart options={data?.options}
                          series={data?.series}
                          type="area" height={200} width="100%" className="px-0"/>
                  <p className="graph_desc_data">* ROI is updated every 15 minutes.</p>
                 
                 </Tab>               
                </Tabs>
                  </Tab>               
                  <Tab eventKey="equity" title="Equity" className="px-0">
                  <Tabs defaultActiveKey="1w" id="uncontrolled-tab-example1" className="graytab pt-2 px-0 tab_style_inner_grey">
                  <Tab eventKey="1w" title="1w" className="px-0">
                  <Chart options={data?.options}
                          series={data?.series}
                          type="area" height={200} width="100%" className="px-0"/>
                  <p className="graph_desc_data">* Equity is updated every 15 minutes and does not include bonus.</p>
                  </Tab>               
                  <Tab eventKey="1m" title="1m" className="px-0">
                  <Chart options={data?.options}
                          series={data?.series}
                          type="area" height={200} width="100%" className="px-0"/>
                  <p className="graph_desc_data">* Equity is updated every 15 minutes and does not include bonus.</p>
                 
                  </Tab> 
                  <Tab eventKey="3m" title="3m" className="px-0">
                  <Chart options={data?.options}
                          series={data?.series}
                          type="area" height={200} width="100%" className="px-0"/>
                  <p className="graph_desc_data">* Equity is updated every 15 minutes and does not include bonus.</p>
                 
                 </Tab> 
                 <Tab eventKey="6m" title="6m" className="px-0">
                 <Chart options={data?.options}
                          series={data?.series}
                          type="area" height={200} width="100%" className="px-0"/>
                  <p className="graph_desc_data">* Equity is updated every 15 minutes and does not include bonus.</p>
                 
                 </Tab> 
                 <Tab eventKey="1y" title="1y" className="px-0">
                 <Chart options={data?.options}
                          series={data?.series}
                          type="area" height={200} width="100%" className="px-0"/>
                  <p className="graph_desc_data">* Equity is updated every 15 minutes and does not include bonus.</p>
                 
                 </Tab>   
                 <Tab eventKey="all" title="All" className="px-0">
                 <Chart options={data?.options}
                          series={data?.series}
                          type="area" height={200} width="100%" className="px-0"/>
                  <p className="graph_desc_data">* Equity is updated every 15 minutes and does not include bonus.</p>
                 
                 </Tab>               
                </Tabs>
                  </Tab>                 
                </Tabs> */}
                  </div>
                </div>
                <div className="col-12 col-lg-4 pl-3 pl-lg-1 detail_tab_height">
                  <div className="graytab mt-5-det">
                    <Details TrdaerDetail={providered} />
                  </div>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-12 col-lg-8 pr-3 pr-lg-0 mb-roi-mon">
                  <div className="graytab h-100">
                    <RoiChart TrdaerDetail={providered} Followerdetails='' type='provider' />
                    {/* <div className="tab-content h-100">
                    <p className="boxheadings mb-1">ROI (Monthly)</p>
                    <div className="darkbox p-3 bg_trans_tab">
                      <button className="btn_only_tab btn_tab_pos">
                        2022
                      </button>
                 
                  <Chart options={data1?.options1}
                          series={data1?.series1}
                          type="bar" height={248} width="100%" className="px-0" />
               
                </div>
                 
                          
                          </div> */}
                  </div>
                </div>
                <div className="col-12 col-lg-4 pl-3 pl-lg-1 mb-sum-mon">
                  <div className="graytab">
                    <Summary summaryDetails={summaryDetails} TrdaerDetail={providered} />
                  </div>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-12 col-lg-8 pr-3 pr-lg-0 mb-roi-mon">
                  <div className="graytab">
                    <div className="tab-content">
                      <p className="boxheadings mb-1">Performance</p>
                      <div className="darkbox p-3 bg_trans_tab">

                        <Performance TrdaerDetail={providered} Followerdetails='' type='provider' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 pl-3 pl-lg-1 detail_tab_height_1 mb-sum-mon">
                  <div className="graytab">
                    <PerformanceStat summaryDetails={summaryDetails?.ClosedPosition[0]} />
                  </div>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-12 col-lg-8 pr-3 pr-lg-0 mb-roi-mon">
                  <div className="graytab">
                    <div className="tab-content">
                      <p className="boxheadings mb-1">Volume Breakdown</p>
                      <div className="darkbox p-3 bg_trans_tab">
                        <VolumeBreak TrdaerDetail={provider} Followerdetails='' type='provider' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 pl-3 pl-lg-1 detail_tab_height_1">
                  <div className="graytab">
                    <Volume summaryDetails={summaryDetails} />
                  </div>
                </div>
              </div>

              <div className="row mb-0">
                <div className="col-12 col-lg-12 pr-3 mb-sum-mon">
                  <Tabs defaultActiveKey="trades" id="uncontrolled-tab-example" className="graytab pt-2 px-0">
                    <Tab eventKey="trades" title="Trades">
                      <Trades summaryDetails={summaryDetails} TrdaerDetail={provider} Followerdetails='' type='provider' />
                    </Tab>
                  </Tabs>
                </div>
                <div className="col-12 col-lg-12 pl-3">
                  <CopierStat TrdaerDetail={provider} />
                </div>
              </div>

              <div className="row mb-0">
                <div className="col-12 col-lg-12 pr-3 mb-sum-mon">
                  <Tabs defaultActiveKey="symbols" id="uncontrolled-tab-example" className="graytab pt-2 px-0">
                    <Tab eventKey="symbols" title="Symbols">
                      <Symbols summaryDetails={summaryDetails} TrdaerDetail={provider} Followerdetails='' type='provider' />
                    </Tab>
                  </Tabs>
                </div>

              </div>




              <div className="row mb-0 pb-2">
                <div className="col-12">
                  <div className=" p-0 mt-1">
                    <Tabs defaultActiveKey="position" id="uncontrolled-tab-example" className="graytab pt-2 px-0">
                      <Tab eventKey="position" title={position} >
                        <Position TrdaerDetail={provider} Followerdetails='' type='provider' onSubmit={(data) => { setPositiondata(data) }} />
                      </Tab>
                      <Tab eventKey="history" title="History">
                        <History TrdaerDetail={provider} Followerdetails='' type='provider' />
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="graytab">
                    <div className="tab-content tab_cont_box_border">
                      <p className="boxheadings mb-1">Strategy Description</p>
                      <div className="darkbox">
                        <ul className="pl-0 symbolTab_list">
                          <div className="sybm_tab_div">
                            {ReactHtmlParser(providered?.Descriptions)}

                            {/* <p>Hope this can help you.</p>
                            <p>Investing isn't just a game of hitting winners, it's also a game of avoiding losers</p>
                            <p>Don't ever get trapped into chasing short-term gain while taking on long-term risk.</p>
                            <p>it may work out once or twice but if you make a habit of doing that it's only matter of time before you crashing down</p>
                            <p>if you can do the opposite way you take on some sort of short-term volatility for long-term gain, you want to hit that button again and again</p> */}
                          </div>
                        </ul>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};



export default CopyTradeProviderDetails;