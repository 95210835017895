import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import ProgressBar from 'react-bootstrap/ProgressBar';
import ReactDatatable from '@ashvin27/react-datatable';
import isEmpty from "lib/isEmpty";
import Pagination from "../components/Dashboard/pagination";
import CopyToClipboard from "react-copy-to-clipboard";
// import action
import { gettradeHistory } from "../actions/dashboardAction";
import { getRecentTransaction ,getRecentdemoTransaction} from '../actions/dashboardAction'
import { toastAlert } from "lib/toastAlert";
// import lib
import { transactionStatus } from "lib/displayStatus";
import { dateTimeFormat } from "../lib/dateTimeHelper";
import { useDispatch, useSelector } from 'react-redux'
import { Filter2Sharp } from "@material-ui/icons";

const Requeststatus = () => {
  const {referralCode} = useSelector(state => state.auth);
  const accountData = useSelector(state => state.account);
  const {status,useraccount}=accountData

  //state
  const [fiatdata, setFiatData] = useState([]);
  // console.log('fiatdata',fiatdata)
  const[cryptodata,setCryptoData] = useState([]);
  // console.log('cryptoarray',cryptodata)
  
  
  const [copy, setCopy] = useState('');
  // console.log('data----------->', data)
  const [loader, setLoader] = useState(true);
  const [count, setcount] = useState(0);


  const [reportData, setreportData] = useState([]);
  const [filter, setFilter] = useState({
    'page': 1,
    'limit': 5,
    'LiveorDemo' : 'LIVE',
    'transac' : 'all',
    'type' :'DEPOSIT',
    'paymentType' : 'fiat',
  })
  const [filters, setFilters] = useState({
    'page': 1,
    'limit': 5,
    'LiveorDemocrypto' :'LIVE',
    'transac' : 'all',
    'type' :'DEPOSIT',
    'paymentType' : 'crypto',
  })
  // console.log("filterss--->",filters);
  const [display,setDisplay] = useState('All')
  // console.log("filter----------->",filter);
  const [displayed,setDisplayed] = useState('All')
 

  const {LiveorDemo , LiveorDemocrypto} = filter;
  // console.log('filter hihi2',filter)
  function handleChange(index) {
    let filterData = { ...filter, ...{ 'page': index.page_number, 'limit': index.page_size } }


    setFilter(filterData);
    fetchFiatTransaction(filterData);
  }
  const setLiveorDemocrypto = async (data)=>
  {
    const formDatass = {...filters,...{'LiveorDemocrypto' : data}}
    console.log("formDatass------>",formDatass);
    setFilters(formDatass)
  }

  const setLiveorDemo = async(data)=>
{
  const formDatas = {...filter,...{'LiveorDemo':data}}
  setFilter(formDatas)

}  

const depositwithdraw= async(data)=>
{
  const formDatas = {...filter,...{'type':data}}
  console.log("formDatas->",formDatas);
  setFilter(formDatas)
}  

const depositwithdrawcrypto= async(data)=>
{
  const formDatas = {...filters,...{'type':data}}
  console.log("formDatas->",formDatas);
  setFilter(formDatas)
} 


  const fetchFiatTransaction = async () => {
    try {
      // console.log('hello fiat hihi2')
      if(LiveorDemo == 'LIVE')
      {
        // console.log('hello fiat live hihi2')
        // console.log('filter jiji',filter)
let Data = { ...filter, ...{ 'AccountsId':useraccount && useraccount?.accountId} }
// console.log("datasdasds=---",Data);

        const { status, loading, result } = await getRecentTransaction(Data);
        // console.log('result----------------->', result,status,loading)
        setLoader(loading);
        if (status == "success") {
        //  console.log('resu;tttttttt',result)
  
         if(result && result && result.length > 0)
         {

            let resultArr = [];
            result.map((item)=>{
            if (item.paymentType == 'fiat_deposit') {
            //  console.log("fgfggfgf");
              resultArr.push({
                'date': dateTimeFormat(item.createdAt, 'YYYY-MM-DD HH:mm'),
                'type': transactionStatus(item.paymentType),
                'currency': item.coin,
                'amount': item.amount,
                'transRef': item._id,
                'bankAccount': isEmpty(item.bankDetail) ? '-' : `${item.bankDetail.bankName}-${item.bankDetail.accountNo}`,
                'status': <div className="textStatusGreen">{item.status}</div>
              })
            }
            })
            // console.log('fiatdataarr',resultArr)
            setFiatData(resultArr);
         }       
         else
         {
          setFiatData([])
         }
        }
        
      }
     else if(LiveorDemo == 'DEMO')
     {
      console.log('hello fiat demo hihi2')
      const { status, loading, result } = await getRecentdemoTransaction(filter);
      console.log('result hihi----------------->', result)
      setLoader(loading);
      if (status == "success") {
      //  console.log('resu;tttttttt',result)

       if(result && result && result.length > 0)
       {
          let resultArr = [];
          result.map((item)=>{
            if(item.paymentType == 'fiat_deposit'||item.paymentType == 'fiat_withdraw')

            resultArr.push({
              'date': dateTimeFormat(item.createdAt, 'YYYY-MM-DD HH:mm'),
              'type': transactionStatus(item.paymentType),
              'currency': item.coin,
              'amount': item.amount,
              'transRef': item._id,
              'bankAccount': isEmpty(item.bankDetail) ? '-' : `${item.bankDetail.bankName}-${item.bankDetail.accountNo}`,
              'status': <div className="textStatusGreen">{item.status}</div>
            })
          })
          // console.log('fiatdataarr',resultArr)
          setFiatData(resultArr);
       }  
       else{
        setFiatData([])
       }     
      }
     }
     
    } catch (err) {
      console.log("errrrrrrrrrrrrrr",err);
     }
  };

  const fetchCryptoTransaction = async () => {
    try {
      // console.log()
      if(LiveorDemocrypto == "LIVE")
    
      {
        const { status, loading, result } = await getRecentTransaction('coin');
        console.log('result<-----------------', result)
        setLoader(loading);
        if (status == "success") {
          if(result && result && result.length > 0)
          {
             let resultArr = [];
             result.map((item)=>{
            if (item.paymentType == 'crypto_withdraw'||item.paymentType == 'crypto_deposit' || item.paymentType == 'coin_deposit' || item.paymentType == 'coin_withdraw') {
console.log("hiiiiiiiiiiiii");
               resultArr.push({
                 'date': dateTimeFormat(item.createdAt, 'YYYY-MM-DD HH:mm'),
                 'type': transactionStatus(item.paymentType),
                 'currency': item.coin,
                 'amount': item.amount,
                 'transRef': item._id,
                 'address' : item.toAddress,
                 'status': <div className="textStatusGreen">{item.status}</div>
               })
              }
              else {
                // console.log('enterq 1')
                resultArr.push({
                  'date': dateTimeFormat(item.createdAt, 'YYYY-MM-DD HH:mm'),
                  'type': transactionStatus(item.paymentType),
                  'currency': item.coin,
                  'amount': item.amount,
                  'transRef': item._id,
                  'address': '-',
                  'bankAccount': isEmpty(item.bankDetail) ? '-' : `${item.bankDetail.bankName}-${item.bankDetail.accountNo}`,
                  'status': <div className="textStatusGreen">{item.status}</div>
                })
              }
             })
             console.log('resultArr hihi',resultArr)
             setCryptoData(resultArr);
          }
          
        }
      }
      else if(LiveorDemocrypto == "DEMO")
      {
        console.log('haiii hello')
        const { status, loading, result } = await getRecentdemoTransaction(filters);
        console.log('result<-----------------', result)
        setLoader(loading);
        if (status == "success") {
          if(result && result && result.length > 0)
          {
             let resultArr = [];
             result.map((item)=>{
               resultArr.push({
                 'date': dateTimeFormat(item.createdAt, 'YYYY-MM-DD HH:mm'),
                 'type': transactionStatus(item.paymentType),
                 'currency': item.coin,
                 'amount': item.amount,
                 'transRef': item._id,
                 'address' : item.toAddress,
                 'status': <div className="textStatusGreen">{item.status}</div>
               })
             })
             console.log('resultArr',resultArr)
             setCryptoData(resultArr);
          }
          else
          {
           setCryptoData([])
          }
        }
      }
    
      
    } catch (err) { }
  };
  const copyCode = (data) => {
    if (data) {
      toastAlert('success', 'Referral Code copied')
    }
    else {
      toastAlert('error', 'no respond can found')
    }


  }
  const handleChanges = async (e) => {
    setCopy(e.target.value);
  }
  useEffect(() => {
    fetchFiatTransaction();
    fetchCryptoTransaction();
  }, [filter,filters,useraccount]);


  const fiatcolumn = [
    {
      key: "date",
      text: "DATE",
      className: "request",
      align: "center",
      sortable: true
    },
    {
      key: "type",
      text: "TYPE",
      className: "request",
      align: "center",
      sortable: true
    },
    {
      key: "currency",
      text: "CURRENCY",
      className: "date",
      align: "center",
      sortable: true
    },
    {
      key: "amount",
      text: "AMOUNT",
      className: "status",
      align: "center",
      sortable: true
    },
    {
      key: "transRef",
      text: "TRANSACTION REF.",
      className: "id",
      align: "center",
      sortable: true,
    },
    {
      key: "bankAccount", 
      text: "BANK & DETAILS",
      className: "id",
      align: "center",
      sortable: true,
    },
    {
      key: "status",
      text: "STATUS",
      className: "request",
      align: "center",
      sortable: true
    },
   
  ];
  const fiatconfig = {
    page_size: 5,
    length_menu: [5, 10, 20, 50],
    filename: "Order",
    no_data_text: 'No Records found!',
    button: {
      extra: true,
    },
    language: {
      length_menu: "Show _MENU_ Entries",
      filter: "Search",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: <i class="fa fa-backward" aria-hidden="true" />,
        previous: <i class="fa fa-chevron-left" />,
        next: <i class="fa fa-chevron-right" />,
        last: <i class="fa fa-forward" aria-hidden="true" />
      }
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: false,
  };


const fiatextraButtons = [
  {
    className : "btn extrabtn",
  //  title: "Export TEst",
    children: [
      <div class="dropdown">
        {/* <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {filter?.LiveorDemo ? filter?.LiveorDemo : 'LIVE'}
        </button> */}
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" href="#" onClick={()=>{setLiveorDemo('LIVE')}}>LIVE</a>
          <a class="dropdown-item" href="#" onClick={()=>{setLiveorDemo('DEMO')}}>DEMO</a>
        </div>
      </div>
    ],onClick: (event) => {
      console.log(event);
    },
  },
  {
    className: "btn extrabtn",
   // title: "Export TEst",
    children: [
      <div class="dropdown">
        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {display}
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" href="#" hidden>all tranasaction</a>
          <a class="dropdown-item" href="#" onClick={()=>{depositwithdraw('fiat');setDisplay('All')}}>All</a>
          <a class="dropdown-item" href="#" onClick={()=>{depositwithdraw('fiat_deposit');setDisplay('Deposit') }}>Deposit</a>
          <a class="dropdown-item" href="#" onClick={()=>{depositwithdraw('fiat_withdraw');setDisplay('withdraw')}}>withdraw</a>
        </div>
      </div>
    ],
    onClick: (event) => {
      console.log(event);
    },
  }
]


  const cryptocolumn = [
    {
      key: "date",
      text: "DATE",
      className: "request",
      align: "center",
      sortable: true
    },
    {
      key: "type",
      text: "TYPE",
      className: "request",
      align: "center",
      sortable: true
    },
    {
      key: "currency",
      text: "CURRENCY",
      className: "date",
      align: "center",
      sortable: true
    },
    {
      key: "amount",
      text: "AMOUNT",
      className: "status",
      align: "center",
      sortable: true
    },
    {
      key: "address", 
      text: "ADDRESS",
      className: "id",
      align: "center",
      sortable: true,
    },
    {
      key: "status",
      text: "STATUS",
      className: "request",
      align: "center",
      sortable: true
    },
   
  ];
  const cryptoconfig = {
    page_size: 5,
    length_menu: [5, 10, 20, 50],
    filename: "Order",
    no_data_text: 'No Records found!',
    button: {
      extra: true,
    },
    language: {
      length_menu: "Show _MENU_ Entries",
      filter: "Search",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: <i class="fa fa-backward" aria-hidden="true" />,
        previous: <i class="fa fa-chevron-left" />,
        next: <i class="fa fa-chevron-right" />,
        last: <i class="fa fa-forward" aria-hidden="true" />
      }
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: false,
  };
  const cryptoextraButtons = [
    {
      className : "btn extrabtn",
    //  title: "Export TEst",
      children: [
        <div class="dropdown">
          
          {/* <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {filters?.LiveorDemocrypto ? filters?.LiveorDemocrypto : 'LIVE'}
          </button> */}
          {/* <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" href="#" onClick={()=>{setLiveorDemocrypto('LIVE')}}>LIVE</a>
            <a class="dropdown-item" href="#" onClick={()=>{setLiveorDemocrypto('DEMO')}}>DEMO</a>
          </div> */}
        </div>
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn extrabtn",
     // title: "Export TEst",
      children: [
        <div class="dropdown">
          <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {displayed}
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" >
          <a class="dropdown-item" href="#" onClick={()=>{depositwithdrawcrypto('crypto');setDisplayed('All')}}>All</a>
            <a class="dropdown-item" href="#" onClick={()=>{depositwithdrawcrypto('crypto_deposit');setDisplayed('Deposit')}}>Deposit</a>
            <a class="dropdown-item" href="#" onClick={()=>{depositwithdrawcrypto('crypto_withdraw');setDisplayed('Withdraw')}}>withdraw</a>
          </div>
        </div>
      ],
      onClick: (event) => {
        console.log(event);
      },
    }
  ]
  return (
    <div className='referrallist'>
      <div className='container custom-container1'>
        <div className="headsec my-3">
          <div>
            <h5 >Fiat history</h5>
          </div>
          <div className="referralcodecopy">
                            <div > <label htmlFor="minimum">Referral code:</label></div>
                            <div  className="inputcopy"> <input name="minimumAmount" value={referralCode} type="text" className="form-control" onChange={(e) => handleChanges(e)} /><span><CopyToClipboard text={referralCode} onCopy={() => copyCode(referralCode)}><div><button class="fa fa-copy copybtn"> </button></div></CopyToClipboard></span>  </div>
                            
                        </div>
        </div>



        <div className='row my-3 px-3 px-md-0'>
          <div className="table-responsive">

            <ReactDatatable className="table table-bordered table-striped"
              config={fiatconfig}
              records={fiatdata}
              columns={fiatcolumn}
              dynamic={false}
              total_record={count}
              onChange={handleChange}
              extraButtons={fiatextraButtons}
            />
          </div>
        </div>

        
      </div>

      <div className='container custom-container1'>
        <div className="headsec my-3">
          <div>
            {/* <h5 >Crypto history</h5> */}
          </div>
          <div className="referralcodecopy">
          </div>
        </div>



        {/* <div className='row my-3 px-3 px-md-0'>
          <div className="table-responsive">

            <ReactDatatable className="table table-bordered table-striped"
              config={cryptoconfig}
              records={cryptodata}
              columns={cryptocolumn}
              dynamic={false}
              total_record={count}
              onChange={handleChange}
              extraButtons={cryptoextraButtons}
            />
          </div>
        </div> */}

        
      </div>
    </div>

  );

}

export default Requeststatus;