import React, { Component, useState, useEffect, useContext } from 'react';
import { NavLink, Router, Link, useHistory } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { Col, Nav, Row, Tab, Tabs, Table, Badge } from 'react-bootstrap';
import logo from '../../assets/images/Land/logo.png'
import '../../assets/css/Header.css'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "../../assets/css/Home.css"

import Bannerimage from "../../assets/images/Land/banner.png";
import Aboutimage from "../../assets/images/Land/about.png";
import lock from "../../assets/images/Land/lock.png";
import candle from "../../assets/images/Land/candle.png";
import dollar from "../../assets/images/Land/dollar.png";
import bitcoins from "../../assets/images/Land/bitcoin.png"

import candle2 from "../../assets/images/Land/candle2.png"
import headphone from "../../assets/images/Land/headphone.png"
import zero from "../../assets/images/Land/zero.png"
import chat from "../../assets/images/Land/chat.png"
import money from "../../assets/images/Land/money.png"
import copytrade from "../../assets/images/Land/copytrade.png"
import spreadassets from "../../assets/images/Land/spreadassets.png"

// import { getPairList } from 'actions/perpetualTradeAction';
import { getPairList } from '../../actions/spotTradeAction'
import { getstocksymbolaction, setCategory } from '../../actions/currencycategoryAction'
import Landing from '../Landing/Landing'

import isEmpty from '../../lib/isEmpty'
import { toastAlert } from '../../lib/toastAlert';
// import validation from './validation';
// import validation from 'components/SupportTicket/validation';
import Landingvalidation from '../SupportTicket/Landingvalidation'






import univ from "../../assets/images/Land/univ.png"
import isLogin from 'lib/isLogin';
import { setPairList } from 'actions/perpetualTradeAction';
// import SocketContext from 'components/Context/SocketContext';
import SocketContext from '../../components/Context/SocketContext';
import { setMarketPrice } from 'actions/perpetualTradeAction';
import { newContact } from '../../actions/commonAction'

import Footer from "components/Footer/Footer.js";

//import selector and dispatch 
import { getcategoryPair, useShallowEqualSelector } from '../../Hooks/useSelectorHooks'

const options = {
    margin: 20,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    navText: ["<div class='circlearrow-left'><i class='fa fa-arrow-left' aria-hidden='true'></i></div>", "<div class='circlearrow-right'><i class='fa fa-arrow-right' aria-hidden='true'></i></div>"],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        575: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 4,

        },
        1250: {
            items: 5,

        }
    },
};
const initialFormValue = {
    'name': '',
    'email': '',
    // 'phone': '',
    'PhoneNo': '',
    'attachment': '',
    'message': '',
    'phoneCode': ""

}
console.log("im=mni",initialFormValue);

function Land() {

    const [formValue, setFormValue] = useState(initialFormValue);
    console.log("formvalurdataaooooo", formValue);
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch()
    const category = useSelector(state => state.tradePair)
    // console.log('category-----dat', category && category)
    useEffect(() => {
        window.addEventListener('scroll', onScroll);
    }, []);
    const socketContext = useContext(SocketContext)
    const { name, email, PhoneNo, attachment, message, phoneCode } = formValue;
    console.log("formavakue----", formValue);

    const [scroll, setscroll] = useState(false)
    const [check, setcheck] = useState(false)
    const [buttonIndex, setButtonIndex] = useState("button0")
    const [pair, setPair] = useState()
    const [toched, setToched] = useState({});
    // console.log("pair------------------", pair);
    const [marketprice, setMarketPrice] = useState()
    // console.log("marketprice------------", marketprice);
    const [validateError, setValidateError] = useState({});
    // console.log("validateError---------------", validateError);
    // const categoryPair = useShallowEqualSelector(((state) => state.categoryPair)).value
    const categoryPair = useSelector(state => state.categoryPair)
    const [pageSize, setPagesize] = useState(5)
    const { isAuth } = useSelector(state => state.auth);
    const socialMedia = useSelector(state => state.socialMedia);
    const checkout = (val) => {
        setcheck(val.target.checked);
    }

    let history = useHistory();
    const handleClick = () => {
        if (isAuth === true)
            history.push('/trade/');
        else {
            history.push('/login');
        }
    }

    const fetchdata = async () => {
        try {
            let { success, result } = await getstocksymbolaction()
            if (success) {
                console.log("symbal--------------", result);
                setPair(result)
                setCategory(result, dispatch)
                console.log(result[0].categoryName, 'categoryPair[0].categoryName')
                // var datatest = JSON.parse(localStorage.getItem("totalSymbols"));
                // if(!datatest){
                localStorage.setItem("totalSymbols", JSON.stringify(result));
                // }
            } else {
                console.log("symbal--------------", "there is no data ");
            }
            // const categoryPair = await getcategoryPair(dispatch)
            // setPair(categoryPair)
        } catch (err) {
            console.log(err)
        }
    }



    const onScroll = () => {

        if (window.pageYOffset > 100) {
            setscroll(true);

        }
        else {
            setscroll(false);

        }

    }

    const addActive = (buttonid) => {

        var a = document.querySelectorAll(".tabcarousel .owl-carousel .owl-stage-outer .owl-stage .item button.active")
        console.log(a, "viji")

        document.getElementById(buttonid).classList.add("active")

    }

    const activeclass = (removeid, buttonid) => {
        console.log("dshkjskj", buttonIndex)
        document.getElementById(removeid).classList.remove("active1")
        document.getElementById(buttonid).classList.add("active1")
    }
    const handlePhoneNumber = (value, country) => {
        console.log("value------", value,country);
        const { dialCode } = country;
        let newPhoneNo = value;
        let formData = formValue;
        if (dialCode) {
            formData = {
                ...formData,
                phoneCode: dialCode,
                PhoneNo: newPhoneNo.slice(dialCode.length)
            }
            console.log("formData------", formData);
        } else if (value) {
            formData = { ...formData, PhoneNo: value }
        }

        setFormValue(formData)
        
    }
    const handleBlurPhone = (e) => {

        setToched({ ...toched, 'phoneNo': true, 'phoneCode': true })
    }
    //contactUs


    const handleChange = (e) => {
        console.log("cdcdscs=------", e);
        e.preventDefault();
        const { name, value } = e.target;
        let formData = { ...formValue, ...{ [name]: value } }
        setFormValue(formData)
        if (!isEmpty(validateError)) {
            setValidateError({})
        }
    }
    const handleFile = async (e) => {
        e.preventDefault()
        const { name, files } = e.target
        const formData = { ...formValue, ...{ [name]: files[0] } }
        setFormValue(formData)
    };
    const handleFormSubmit = async (e) => {
        console.log("e-------------", e);
        e.preventDefault();
        const phone = formValue.phoneNo
        const phoneCode = formValue.phoneCode

        let reqData = {
            name,
            email,
            PhoneNo,
            phoneCode,
            attachment,
            message
        }
        console.log("reqData=-----------", reqData);
        let validationError = Landingvalidation(reqData, 'createTicket')
        if (!isEmpty(validationError)) {
            console.log("validadasdada", validationError);
            setValidateError(validationError)
            return
        }
        // setLoader(true)

        // let formData = new FormData();
        // formData.append('name', names)
        // formData.append('email', email)
        // formData.append('phone',phone)
        // formData.append('attachment', attachment)
        // formData.append('message', message)

        // console.log("fromData-----------",formData.get('name'));
        // console.log("fromData-----------",formData.get('email'));
        // console.log("fromData-----------",formData.get('phone'));
        // console.log("fromData-----------",formData.get('attachment'));
        // console.log("fromData-----------",formData.get('message'));
        try {
            const { status, loading, message, error } = await newContact(reqData);
            console.log("statassas-------", status);
            // setLoader(loading)
            if (status == 'success') {
                console.log("status-------0", status);
                setFormValue(initialFormValue)
            //    window.location.reload()
                if (formValue.attachment != '') {
                    setFormValue(initialFormValue)
                    toastAlert('success', message, 'createTicket')
                }
                // handleTicketList({
                //     'callFrom': 'createTicket'
                // })
            } else {
                if (error) {
                    setValidateError(error)
                    return
                }
            }
        } catch (err) {
            console.log("ererererere",err);
        }
    }


    useEffect(() => {
        fetchdata()
    }, [])

    useEffect(() => {
        console.log('categoryPair[0].categoryName')
        if (categoryPair.length > 0) {
            setPair(categoryPair)
            console.log(categoryPair[0].categoryName, 'categoryPair[0].categoryName')
            var datatest = JSON.parse(localStorage.getItem("totalSymbols"));
            if (!datatest) {
                localStorage.setItem("totalSymbols", JSON.stringify(categoryPair));
            }
        } else {
            fetchdata()
        }
        socketContext.socket.on('perpetualmarketprice', (result) => {
            // console.log("result--------dfd--------", result);
            try {
                setMarketPrice(result)
            } catch (err) {
                console.log(err, 'perpetualmarketprice__err')
            }
        })
    }, [SocketContext.Socket])


    const goTotop = () => {
        console.log("inner");
        const tableoffset = document.getElementById("table_card_1").offsetTop;
        console.log(tableoffset, "tableoffset")
        window.scrollTo(0, tableoffset - 100);
    }

    return (<>
        {/* <Header/> */}
        <div className='      '>
            <div className='header_page'>
                <div className='container'>
                    <div className={scroll ? "nav position_fixed" : "nav"}>
                        <input type="checkbox" id="nav-check" onChange={(e) => { checkout(e) }} />

                        <div className="nav-header">
                            <div className="nav-title">
                                <img src={logo} className="img-fluid" />
                            </div>
                        </div>
                        <div className="nav-btn">
                            <label htmlFor="nav-check" className={check ? "d-none" : "d-block"}>
                                <span />
                                <span />
                                <span />
                            </label>

                            <label htmlFor="nav-check" className={check ? "d-block " : "d-none "}>
                                <i className='fa fa-times'></i>
                            </label>

                        </div>
                        <div className="nav-links">
                            {/* <a href="" target="_blank">COINS</a>
                <a href="" target="_blank">ABOUT</a>
                <a href="" target="_blank">PLATFORM RULES</a>
                <a href="" target="_blank">REVIEWS</a>
                <a href="" target="_blank">FEEDBACK FORM</a> */}



                            {/* <button class="gradient-button">Get Started</button> */}
                            {(isLogin() == true) ? <Link to="/tradedashboard" color="transparent" className="nav-link home_menu_btn1 d-inline-flex loginbtn">

                                <div className="d-flex afterlogin_profile afterlogin_profile_hone">  <i class="fa fa-home mr-2" aria-hidden="true"> </i><span>Dashboard</span>
                                    <div>
                                        {/* <img src={Profileicon}  alt="profileicon"/> */}
                                    </div></div></Link> :
                                <Link to="/login" color="transparent" className="nav-link home_menu_btn1 d-inline-flex loginbtn">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z" /></svg>
                                    Login
                                </Link>
                            }
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div className='homepage'>
            <div className=''>
                <div className='banner_sec'>
                    <div className='container'>
                        <div className='row align-items-center fullban'>
                            <div className='col-lg-6'>
                                {/* <p className='banner_top'>Lorem Ipsum is simply dummy text of the printing</p> */}
                                <h1 className='banner_title'>Welcome to <span>TRADING ONE CAPITAL</span> where you can trade CFDs on forex, indices, commodities, and stocks.
                                </h1>
                                <p className='banner_para'>
                                    With our user-friendly trading platform, you can trade CFDs from anywhere in the world, 24 hours a day, five days a week. Our platform offers a range of advanced trading tools, including real-time market data, technical analysis, and risk management tools, to help you make informed trading decisions.
                                </p>
                                <div className='mt-3'>
                                    <button className="gradient-button" onClick={handleClick}>Start Today!</button>
                                </div>
                            </div>
                            <div className='col-lg-6 mt-4 mt-lg-0'>
                                <div className='text-center text-lg-right'>
                                    <img src={Aboutimage} className="img-fluid" alt="Bannerimage" width={500} height={500} />
                                </div>

                            </div>

                        </div>
                        <div className='container pt-5 coincarousel'>
                            <div className='row coincaro_btn'>
                                {/* <OwlCarousel className='owl-theme' loop  nav {...options}> */}

                                {pair && pair.length > 0 && pair.map((item) => {
                                    return (
                                        <div class='item col-12 col-sm-6 col-md-3' onClick={() => {
                                            console.log(document.getElementById(`controlled-tab-example-tab-${item.categoryName}`), 'document.getElementById(item.categoryName)')
                                            document.getElementById(`controlled-tab-example-tab-${item.categoryName}`).click()
                                            // 
                                        }}>
                                            {/* <a href="https://www.xtb.com/en/forex" target='_blank'> */}
                                            <div class="card" >
                                                <div class="card-body">
                                                    <div>
                                                        <div className='p-3'>
                                                            <p className='cardhead text-center'>Trade {item.categoryName}  CFD</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* </a> */}
                                        </div>
                                    )

                                })}




                                {/* <div class='item col-12 col-sm-6 col-md-3'>
                                    <a href="https://www.xtb.com/en/commodities" target='_blank'>
                                        <div class="card">


                                            <div class="card-body">
                                                <div>

                                                    <div className='p-3'>
                                                        <p className='cardhead text-center'>Trade Commodities CFD</p>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </a>

                                </div>


                                <div class='item col-12 col-sm-6 col-md-3'>
                                    <a href="https://www.xtb.com/en/indices" target='_blank'>
                                        <div class="card">


                                            <div class="card-body">
                                                <div>

                                                    <div className='p-3'>
                                                        <p className='cardhead text-center'>Trade Indices CFD</p>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </a>

                                </div>

                                <div class='item col-12 col-sm-6 col-md-3'>
                                    <a href="https://www.xtb.com/ro/monede-virtuale" target='_blank'>
                                        <div class="card">


                                            <div class="card-body">
                                                <div>

                                                    <div className='p-3'>
                                                        <p className='cardhead text-center'>Trade Crypto CFD</p>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </a>

                                </div> */}
                            </div>
                            {/* </OwlCarousel> */}
                        </div>
                    </div>
                </div>
                {/* tabcarousel */}
                <div className='container'>
                    <div className='tabcarousel mt-5'>

                        <div className='themetabs'>
                            <Tabs
                                id="controlled-tab-example"
                                className="mb-3"
                            >
                                {pair && pair.length > 0 && pair.map((item, index) => {
                                    // console.log("item-----------------", index, pageSize);
                                    // if (index < pageSize) {
                                    let Index
                                    return (
                                        <Tab name={item.categoryName} id={item.categoryName} eventKey={item.categoryName} title={item.categoryName}>
                                            <div className='tabletab table_home '>

                                                <div className='drree'>
                                                    <div className='row'>
                                                        <div className='col-md-5'>
                                                            <ul className='uls'>
                                                                {item.content.length > 0 && item.content.map((val, index) => {
                                                                    if (index < 3) {
                                                                        return (
                                                                            <li>{val}</li>
                                                                        )
                                                                    }
                                                                })}
                                                                {/* <li>{item?.content1}</li>
                                                                    <li>{item?.content2}</li>
                                                                    <li>{item?.content3}</li> */}
                                                                {/* <li>{item?.content}</li> */}
                                                            </ul>
                                                        </div>
                                                        {/* <div className='col-md-6'>
                                                                <ul className='uls'>
                                                                    <li>{item?.content4}</li>
                                                                    <li>{item?.content5}</li>
                                                                    <li>{item?.content6}</li>
                                                                </ul>
                                                            </div> */}
                                                        <div className='col-md-6'>
                                                            <ul className='uls'>
                                                                {item.content.length > 0 && item.content.map((val, index) => {
                                                                    if (index > 2) {
                                                                        return (
                                                                            <li>{val}</li>
                                                                        )
                                                                    }
                                                                })}
                                                            </ul>
                                                        </div>

                                                    </div>
                                                </div>
                                                <Table responsive className='tablescard' id="table_card_1">
                                                    <thead>
                                                        <tr>
                                                            <th>Instrument </th>
                                                            <th>Sell</th>
                                                            <th>Buy</th>
                                                            <th>Spread</th>
                                                            <th>Change</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            item.categorydata && item.categorydata.length && item.categorydata.map((data, i) => {
                                                                // console.log("dataasddas---------", data);
                                                                if (i < pageSize) {
                                                                    Index = i
                                                                    return (
                                                                        <Landing data={data} ></Landing>
                                                                    )
                                                                }
                                                            })
                                                        }

                                                    </tbody>
                                                </Table>
                                                <div className='text-center mt-3 btn_show_lefr'>
                                                    {(item.categorydata.length <= 5 || item.categorydata.length - 1 == Index) ? '' :
                                                        <button className='gradient-button' onClick={() => { setPagesize(pageSize + 5); goTotop(); }}>Show More</button>}
                                                    {pageSize == 5 ? '' : <button className='gradient-button ml-3' onClick={() => { setPagesize(pageSize - 5); goTotop(); }}>Show Less</button>}
                                                </div>
                                            </div>
                                            {/* {item.categorydata && <Landing data ={item.categorydata} ></Landing>} */}
                                        </Tab>

                                    )
                                    // }
                                })


                                }
                                {/* <Tab eventKey="crypto" title="Crypto">
                                    <Table responsive className='tablescard'>
                                        <thead>
                                            <tr>
                                                <th>Instrument</th>
                                                <th>Sell</th>
                                                <th>Buy</th>
                                                <th>Spread</th>
                                                <th>Change</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div class="table_first d-flex align-items-center ">

                                                        <div class="flex-grow ms-3">
                                                            <p className='cardhead'>EUR/USD</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td><Badge className='red_badges' bg="secondary">1.07105</Badge></td>
                                                <td><Badge className='green_badges' bg="secondary">1.07107</Badge></td>
                                                <td>0.2 pips</td>

                                                <td className='text_red_change'>0.21%</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="table_first d-flex align-items-center ">

                                                        <div class="flex-grow ms-3">
                                                            <p className='cardhead'>EUR/USD</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td><Badge className='red_badges' bg="secondary">1.07105</Badge></td>
                                                <td><Badge className='green_badges' bg="secondary">1.07107</Badge></td>
                                                <td>0.2 pips</td>

                                                <td className='text_green_change'>0.21%</td>
                                            </tr>

                                        </tbody>
                                    </Table>
                                </Tab> */}
                                {/* <Tab eventKey="indices" title="Indices">
                                    <Table responsive className='tablescard'>
                                        <thead>
                                            <tr>
                                                <th>Instrument</th>
                                                <th>Sell</th>
                                                <th>Buy</th>
                                                <th>Spread</th>
                                                <th>Change</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div class="table_first d-flex align-items-center ">

                                                        <div class="flex-grow ms-3">
                                                            <p className='cardhead'>EUR/USD</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td><Badge className='red_badges' bg="secondary">1.07105</Badge></td>
                                                <td><Badge className='green_badges' bg="secondary">1.07107</Badge></td>
                                                <td>0.2 pips</td>

                                                <td className='text_red_change'>0.21%</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="table_first d-flex align-items-center ">

                                                        <div class="flex-grow ms-3">
                                                            <p className='cardhead'>EUR/USD</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td><Badge className='red_badges' bg="secondary">1.07105</Badge></td>
                                                <td><Badge className='green_badges' bg="secondary">1.07107</Badge></td>
                                                <td>0.2 pips</td>

                                                <td className='text_green_change'>0.21%</td>
                                            </tr>

                                        </tbody>
                                    </Table>
                                </Tab> */}
                                {/* <Tab eventKey="leverage" title="Leverage">
                                    <Table responsive className='tablescard'>
                                        <thead>
                                            <tr>
                                                <th>Instrument</th>
                                                <th>Sell</th>
                                                <th>Buy</th>
                                                <th>Spread</th>
                                                <th>Change</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div class="table_first d-flex align-items-center ">

                                                        <div class="flex-grow ms-3">
                                                            <p className='cardhead'>EUR/USD</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td><Badge className='red_badges' bg="secondary">1.07105</Badge></td>
                                                <td><Badge className='green_badges' bg="secondary">1.07107</Badge></td>
                                                <td>0.2 pips</td>

                                                <td className='text_red_change'>0.21%</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="table_first d-flex align-items-center ">

                                                        <div class="flex-grow ms-3">
                                                            <p className='cardhead'>EUR/USD</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td><Badge className='red_badges' bg="secondary">1.07105</Badge></td>
                                                <td><Badge className='green_badges' bg="secondary">1.07107</Badge></td>
                                                <td>0.2 pips</td>

                                                <td className='text_green_change'>0.21%</td>
                                            </tr>

                                        </tbody>
                                    </Table>
                                </Tab> */}
                                {/* <Tab eventKey="share" title="Share">
                                    <Table responsive className='tablescard'>
                                        <thead>
                                            <tr>
                                                <th>Instrument</th>
                                                <th>Sell</th>
                                                <th>Buy</th>
                                                <th>Spread</th>
                                                <th>Change</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div class="table_first d-flex align-items-center ">

                                                        <div class="flex-grow ms-3">
                                                            <p className='cardhead'>EUR/USD</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td><Badge className='red_badges' bg="secondary">1.07105</Badge></td>
                                                <td><Badge className='green_badges' bg="secondary">1.07107</Badge></td>
                                                <td>0.2 pips</td>

                                                <td className='text_red_change'>0.21%</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="table_first d-flex align-items-center ">

                                                        <div class="flex-grow ms-3">
                                                            <p className='cardhead'>EUR/USD</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td><Badge className='red_badges' bg="secondary">1.07105</Badge></td>
                                                <td><Badge className='green_badges' bg="secondary">1.07107</Badge></td>
                                                <td>0.2 pips</td>

                                                <td className='text_green_change'>0.21%</td>
                                            </tr>

                                        </tbody>
                                    </Table>
                                </Tab> */}
                            </Tabs>
                            {/* {pair && <Landing data ={pair} ></Landing>} */}
                        </div>
                    </div>

                </div>


                <div className='chartbg'>
                    <div className='container'>
                        <div className='sec_2 py-5'>
                            <div className='row align-items-center'>

                                <div className='col-lg-6'>
                                    <div className='text-center text-lg-left'>
                                        <img src={Aboutimage} className="img-fluid" alt="Bannerimage" width={500} height={500} />
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    {/* <p className='banner_top'>Lorem Ipsum is simply dummy text of the printing</p> */}
                                    <h1 className='banner_title'>Sign up today to <span>start trading CFDs</span> with
                                        <span> TRADING ONE CAPITAL!</span>
                                    </h1>
                                    <p className='banner_para'>
                                        We pride ourselves on offering competitive spreads and low trading fees, so you can keep more of your profits. Our customer support team is available around the clock to answer any questions you may have, and we also offer educational resources to help you improve your trading skills and stay up-to-date with the latest market trends.
                                    </p>
                                    <p className='banner_para'>Whether you're a seasoned trader or just getting started, our platform is designed to meet your needs.

                                    </p>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='card_row'>
                                                <div className='gradient-card'>
                                                    <img src={lock} width="80" height="80" alt="Sample Image" />
                                                    <p className='px-3 text-center'>Secure transfers &  In EUR and USD</p>
                                                </div>
                                                <div className='gradient-card'>
                                                    <img src={candle} width="80" height="80" alt="Sample Image" />
                                                    <p className='px-3 text-center'>Technical analysis tools for charts</p>
                                                </div>
                                                <div className='gradient-card'>
                                                    <img src={dollar} width="80" height="80" alt="Sample Image" />
                                                    <p className='px-3 text-center'>Low trading fees & referral bonus</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-5'>
                                        <button className="gradient-button" style={{pointerEvents:'none'}}>Live Account | Demo Account</button>
                                    </div>
                                </div>

                            </div>






                        </div>

                        <div className='sec_3 py-md-5 py-0'>
                            <div className='row text-center justify-content-center mb-5'>
                                <div>
                                    <h2 className='sec_title'>
                                        Trade with us!

                                    </h2>
                                    <p className='sec_para'>
                                        Everything we do is aimed at improving your trading experience.

                                    </p>
                                </div>
                            </div>
                            <div className='row py-5 row_trans_grad'>
                                <div className='col-12 col-xl-4 col-lg-4 col-sm-6 mb-4'>
                                    <div className='gradient-card'>
                                        <div className='row wid align-items-center'>
                                            <div className='col-5'>
                                                <div className='text-center'>
                                                    <p className='card_num'>5000+</p>
                                                    <p className='card_level'>Trading <br /> Instruments</p>
                                                </div>

                                            </div>
                                            <div className='col-2'>
                                                <div class="outer">
                                                    <div class="inner"></div>
                                                </div>

                                            </div>
                                            <div className='col-5'>
                                                <div className='text-center'>
                                                    <img src={candle2} width="100%" alt="Sample Image" />
                                                </div>

                                            </div>
                                        </div>

                                        {/* <div class="outer">
                              <div class="inner"></div>
                            </div> */}

                                    </div>

                                </div>
                                <div className='col-12 col-xl-4 col-lg-4 col-sm-6 mb-4'>
                                    <div className='gradient-card'>
                                        <div className='row wid align-items-center'>
                                            <div className='col-5'>
                                                <div className='text-center'>
                                                    <p className='card_num'>25,000+	</p>
                                                    <p className='card_level'>Happy<br /> Customers</p>
                                                </div>

                                            </div>
                                            <div className='col-2'>
                                                <div class="outer">
                                                    <div class="inner"></div>
                                                </div>

                                            </div>
                                            <div className='col-5'>
                                                <div className='text-center'>
                                                    <img src={chat} width="100%" alt="Sample Image" />
                                                </div>

                                            </div>
                                        </div>

                                        {/* <div class="outer">
                              <div class="inner"></div>
                            </div> */}

                                    </div>

                                </div>
                                <div className='col-12 col-xl-4 col-lg-4 col-sm-6 mb-4'>
                                    <div className='gradient-card'>
                                        <div className='row wid align-items-center'>
                                            <div className='col-5'>
                                                <div className='text-center'>
                                                    <p className='card_num'>10%</p>
                                                    <p className='card_level'>Referral<br /> Bonus</p>
                                                </div>

                                            </div>
                                            <div className='col-2'>
                                                <div class="outer">
                                                    <div class="inner"></div>
                                                </div>

                                            </div>
                                            <div className='col-5'>
                                                <div className='text-center'>
                                                    <img src={money} width="100%" alt="Sample Image" />
                                                </div>

                                            </div>
                                        </div>

                                        {/* <div class="outer">
                              <div class="inner"></div>
                            </div> */}

                                    </div>

                                </div>
                                <div className='col-12 col-xl-4 col-lg-4 col-sm-6 mb-4'>
                                    <div className='gradient-card'>
                                        <div className='row wid align-items-center'>
                                            <div className='col-5'>
                                                <div className='text-center'>
                                                    <p className='card_num'>From 0.1 pips</p>
                                                    <p className='card_level'>Trading<br /> Spread Assets</p>
                                                </div>

                                            </div>
                                            <div className='col-2'>
                                                <div class="outer">
                                                    <div class="inner"></div>
                                                </div>

                                            </div>
                                            <div className='col-5'>
                                                <div className='text-center'>
                                                    <img src={spreadassets} width="100%" alt="Sample Image" />
                                                </div>

                                            </div>
                                        </div>

                                        {/* <div class="outer">
                              <div class="inner"></div>
                            </div> */}

                                    </div>

                                </div>
                                <div className='col-12 col-xl-4 col-lg-4 col-sm-6 mb-4'>
                                    <div className='gradient-card'>
                                        <div className='row wid align-items-center'>
                                            <div className='col-5'>
                                                <div className='text-center'>
                                                    <p className='card_num'>100+</p>
                                                    <p className='card_level'>Copy trading<br />Accounts </p>
                                                </div>

                                            </div>
                                            <div className='col-2'>
                                                <div class="outer">
                                                    <div class="inner"></div>
                                                </div>

                                            </div>
                                            <div className='col-5'>
                                                <div className='text-center'>
                                                    <img src={copytrade} width="100%" alt="Sample Image" />
                                                </div>

                                            </div>
                                        </div>

                                        {/* <div class="outer">
                              <div class="inner"></div>
                            </div> */}

                                    </div>

                                </div>
                                <div className='col-12 col-xl-4 col-lg-4 col-sm-6 mb-4'>
                                    <div className='gradient-card'>
                                        <div className='row wid align-items-center'>
                                            <div className='col-5'>
                                                <div className='text-center'>
                                                    <p className='card_num'>230</p>
                                                    <p className='card_level'>Education</p>
                                                </div>

                                            </div>
                                            <div className='col-2'>
                                                <div class="outer">
                                                    <div class="inner"></div>
                                                </div>

                                            </div>
                                            <div className='col-5'>
                                                <div className='text-center'>
                                                    <img src={headphone} width="100%" alt="Sample Image" />
                                                </div>

                                            </div>
                                        </div>

                                        {/* <div class="outer">
                              <div class="inner"></div>
                            </div> */}

                                    </div>

                                </div>
                                {/* <div className='col-12 col-xl-3 col-lg-4 col-sm-6 mb-4'>
                   <div className='gradient-card'>
                    <div className='row wid align-items-center'>
                        <div className='col-5'>
                        <div className='text-center'>
                            <p className='card_num'>1.500</p>
                            <p className='card_level'>Maximum<br/> Leverage</p>
                            </div>

                        </div>
                        <div className='col-2'>
                              <div class="outer">
                              <div class="inner"></div>
                            </div>

                        </div>
                        <div className='col-5'>
                        <div className='text-center'>
                            <img src={univ} width="100%" alt="Sample Image"/>
                            </div>

                        </div>
                    </div>
                           
                        
                          
                            </div>

                   </div> */}
                            </div>










                        </div>
                    </div>
                </div>
                {/* why */}
                {/* <div className='sec_4'>
                
                <div className='container'>
                <div className='row text-center justify-content-center'>
                    <div>
                        <h2 className='sec_title'>WHY US </h2>
                        <p className='sec_para'> Lorem Ipsum has been the industry's standard dummy text </p>
                    </div>
                </div>
                <div className='row py-md-5 py-0 mt-4'>
                    <div className='col-12 col-lg-3 col-md-6 my-sm-4 my-3 text-center'>
                        <p className='usersize mb-4'>8M+</p>
                        <p className='username'>TOTAL USERS</p>

                    </div>
                    <div className='col-12 col-lg-3 col-md-6 my-sm-4 my-3 text-center'>
                        <p className='usersize mb-4'>120+</p>
                        <p className='username'>COUNTRIES SERVICED</p>

                    </div>
                    <div className='col-12 col-lg-3 col-md-6 my-sm-4 my-3 text-center'>
                        <p className='usersize mb-4'>35K+</p>
                        <p className='username'>DAILY TRANSCATIONS</p>

                    </div>
                    <div className='col-12 col-lg-3 col-md-6 my-sm-4 my-3 text-center'>
                        <p className='usersize mb-4'>8M+</p>
                        <p className='username'>TOTAL USERS</p>

                    </div>

                </div>
                </div>
            </div> */}
                {/* contact */}
                <div className='sec_5 py-md-5 mt-md-5 py-3 mt-3'>
                    <div className='container'>
                        <div className='row sec_5_row'>
                            <div className='col-12 col-lg-5'>
                                <h2 className='sec_title'>JUST SAY HELLO !</h2>
                                <p className='sec_para mb-3'>We're committed to providing you with the best possible experience. If you have any questions, concerns, or feedback, we're here to help.</p>
                                <div className='row form_row_home_contact'>
                                    <div class="col-12 col-lg-6 mb-3">
                                        <input type="name" className="form-control" id="exampleFormControlInput1" placeholder="NAME" name={"name"}
                                            onChange={handleChange}
                                            value={name} />
                                        {
                                            validateError.name && <p className="error-message">{t(validateError.name)}</p>
                                        }
                                    </div>
                                    <div class="col-12 col-lg-6 mb-3">
                                        <input type="email" className="form-control" id="exampleFormControlInput2" placeholder="EMAIL" name={"email"}
                                            onChange={handleChange}
                                            value={email} />
                                        {
                                            validateError.email && <p className="error-message">{t(validateError.email)}</p>
                                        }

                                    </div>
                                    {/* <div class="col-12 col-lg-6 mb-3">
                                        <input type="name" className="form-control" id="exampleFormControlInput3" maxLength="10" placeholder="PHONE NUMBER"
                                            name={"phone"}
                                            onChange={handleChange}

                                            value={phone} />
                                        {
                                            validateError.phone && <p className="error-message">{t(validateError.phone)}</p>
                                        }
                                    </div> */}

                                    <div class="col-12 col-lg-6 mb-3">
                                        <PhoneInput className="phoneinput"
                                            // id="exampleInputEmail1"
                                            // aria-describedby="emailHelp"
                                            placeholder="Enter mobile number"
                                            // maxlength="10"
                                            name={"PhoneNo"}
                                          
                                            value={String(phoneCode) + "" + String(PhoneNo)}
                                            // onChange={(e) => onInputchange(e)} 
                                            onChange={handlePhoneNumber}
                                            onBlur={handleBlurPhone}
                                            specialLabel={false}
                                            // value={PhoneNo}
                                            country={'gb'}


                                        />
                                        {
                                            validateError.PhoneNo && <p className="error-message">{t(validateError.PhoneNo)}</p>
                                        }
                                    </div>



                                    {/* <div class="col-12 col-lg-6 mb-3">
                                    <input type="name" className="form-control" id="exampleFormControlInput4" placeholder="NAME OF SITE RECOMMENDER"/>
                                </div> */}
                                    <div class="col-12 col-lg-6 mb-3">

                                        <div className="custom-file fie_inout_land">
                                            <input type="file" className="custom-file-input" id="exampleFormControlInput5" placeholder="REFERRAL"
                                                onChange={handleFile}
                                                aria-describedby="inputGroupFileAddon01"
                                                name="attachment" />

                                            <label className="custom-file-label">
                                                {
                                                    <small>Choose File</small>
                                                }
                                            </label>


                                        </div>
                                        {
                                            validateError.attachment && <p className="error-message">{t(validateError.attachment)}</p>
                                        }
                                        <span className="error-message text-white">Supported Formats: jpg and png & Max. 3MB allowed </span>



                                    </div>
                                    <div class="mb-3 col-12 w-full">
                                        <textarea class="form-control w-full" id="exampleFormControlTextarea12" placeholder="YOUR MESSAGE FOR US" rows="5"
                                            name={"message"}
                                            onChange={handleChange}
                                            value={message}
                                        >

                                        </textarea>
                                        {
                                            validateError.message && <p className="error-message">{t(validateError.message)}</p>
                                        }
                                    </div>
                                </div>
                                <div className='mt-4'>
                                    {/* <button className="gradient-button">Submit</button> */}
                                    <input
                                        type="submit"
                                        className="gradient-button"
                                        value="Create Ticket"
                                        onClick={(e) => { handleFormSubmit(e) }}
                                    // disabled={loader}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-lg-7 mt-lg-0 mt-4'>
                                {/* <h2 className='sec_title'>CONTACT INFORMATION</h2> */}
                               






                                <p className='sec_para mb-4'> <a target="_blank" href="https://tradingonecapital.com/">tradingonecapital.com</a> is a website maintained and operated by <b>Trading One Capital (Pty) Ltd</b> with registration number 2015/310027/07. Registered and physical address: <br/>Unit 9b, 1a Shongweni Road, Hillcrest 3650 South Africa.</p>
                                <p className='sec_para mb-4'><b>Trading One Capital (PTY) LTD</b>, Hillcrest, South Africa, with company number 2015/310027/07 an authorized financial services provider, licensed and regulated by the <b>Financial Sector Conduct Authority (FSCA)</b>  in South Africa, with FSP No. 47701.</p>
                                <p className='sec_para mb-4'>Trading One Capital does not provide services for the residents of certain countries, such as the United States of America, Canada, Israel and the Islamic Republic of Iran.</p>
                                <p className='sec_para mb-4'><b>Risk Warning:</b> Trading on margin involves a high level of risk, including full loss of your trading funds. Before proceeding to trade, you must understand all risks involved and acknowledge your trading limits, bearing in mind the level of awareness in the financial markets, trading experience, economic capabilities and other aspects.</p>
                                {/* <p className='sec_para mb-4'>You can reach us by phone, email, or through the contact form below. Our customer support team is available to assist you with any question you may have.</p> */}
                                {/* <p className='sec_address mb-2'>Address :<span>49 NICOLEA CAREMFIL, BUCHAREST,ROMANIA</span></p>
                                <p className='sec_address mb-4'>Phone :<span>+40-720130130</span></p> */}
                                {/* <p className='sec_address mb-2'>Email Address :<span><a href="mailto:support@tradingonecapital.com" target="_blank">support@tradingonecapital.com</a></span></p>

                                <p className='sec_small_para mb-2 mt-3'>We value your feedback and are always looking for ways to improve our services. If you have any suggestions or comments, we'd love to hear them.</p>
                                <p className='sec_small_para mb-2 mt-3'>Thank you for choosing <span className='blue_text_hightlihght'>TRADING ONE CAPITAL!</span> We look forward to hearing from you.</p> */}
                                {/*<p className='sec_small_para mb-2'>CFDs (Contracts for Difference) are complex instruments and carry a high risk of losing money rapidly due to leverage. 80% of retail investor accounts lose money when trading CFDs with this provider. You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money. Contracts for Difference (CFDs) are leveraged products and carry a high level of risk, as even small price movements of the underlying asset can affect your account. The account balance can be lost in full. TRADING ONE CAPITAL acts as a counterparty in the transactions executed for the client's account. More information is available in the Investment Risk Declaration document on <a href="www.tradingonecapital.com/risk" target='_blank'>www.tradingonecapital.com/risk</a>. Trading these instruments may not be suitable for all individuals; therefore, understanding the risks and the mechanism of operation, as well as going through the dedicated educational programs or seeking personalized assistance, is recommended. CFDs are complex instruments and carry a high risk of losing money rapidly due to leverage.
                                </p>
                                <p className='sec_small_para mb-2'>TRADING ONE CAPITAL SA, Caramfil Boulevard no. 69, Sector 1, Bucharest, Romania is registered with the Trade Register under no. Jxx/12345/2023, Fiscal Identification Code xxxxxxxx, Main Activity - Other financial intermediation n.c.a. - 6499 Personal Data Processing Operator Code xxxxx CNVM Certification xxx/dd.mm.2023, Registration Number in CNVM Registry xxxxxxxxx/xxxxxxxxxxx.
                                </p> */}

                                {/* <p className='sec_social mb-2 mt-4'>Social Link :<span>
                            <a href="#">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                            <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/></svg></a>
                            <a href="#">  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"/></svg></a>
                            
                                <a href="#">  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z"/></svg>
                                    </a>
    </span></p> */}




                            </div>

                        </div>
                    </div>
                </div>


            </div>
            <hr className='footer_hr' />
            <div className='container'>
                <div className='homepage_footer pt-2 pb-3'>
                    <p>Copyright 2023 © TRADING ONE CAPITAL</p>
                </div>
                <div className='footer-text'>
                <h6 className='text-center'>Disclaimer :</h6>
                    {/* <p className='sec_small_para mb-0 text-center'>Thank you for choosing <span className='blue_text_hightlihght'>TRADING ONE CAPITAL!</span> We look forward to hearing from you.</p> */}
                    <p className='sec_small_para mb-0 pb-3 text-center'> 
                    Trading on margin involves a high level of risk, including full loss of your trading funds. Before proceeding to trade, you must understand all risks involved and acknowledge your trading limits, bearing in mind the level of awareness in the financial markets, trading experience, economic capabilities and other aspects.
                    {/* CFDs (Contracts for Difference) are complex instruments and carry a high risk of losing money rapidly due to leverage.You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money. Contracts for Difference (CFDs) are leveraged products and carry a high level of risk, as even small price movements of the underlying asset can affect your account. The account balance can be lost in full. TRADING ONE CAPITAL acts as a counterparty in the transactions executed for the client's account. More information is available in the Investment Risk Declaration document on 
                    <a href="https://tradingonecapital.com/" target='_blank'>www.tradingonecapital.com/risk</a>. Trading these instruments may not be suitable for all individuals; therefore, understanding the risks and the mechanism of operation, as well as going through the dedicated educational programs or seeking personalized assistance, is recommended. CFDs are complex instruments and carry a high risk of losing money rapidly due to leverage. */}
                    </p>
                </div>
            </div>
            <hr className='footer_hr' />
            <div className="container-fluid foot_lnks_laf">
                <div className="footerMidd wow fadeIn" data-wow-delay=".5s">
                    <div className="footerMidd_left">
                        <ul className="footer_links mb-0">
                            <li><NavLink to="/about">About us</NavLink></li>
                            <li><NavLink to="/faq">FAQs</NavLink></li>
                            <li><NavLink to="/contact">Contact Us</NavLink></li>
                            <li><NavLink to="/terms">Terms & Conditions</NavLink></li>
                            <li><NavLink to="/privacy-policy">Privacy Policy</NavLink></li>
                            <li><NavLink to="/legal">Legal</NavLink></li>
                            {/* <li onClick={()=>{history.push('/about')}}>About us</li>
                            <li onClick={()=>{history.push('/faq')}}>FAQs</li>
                            <li onClick={()=>{history.push('/contact')}}>Contact Us</li>
                            <li onClick={()=>{history.push('/terms')}}>Terms & Conditions</li>
                            <li onClick={()=>{history.push('/privacy-policy')}}>Privacy Policy</li> */}
                        </ul>
                    </div>
                    <div className="footerMidd_right">
                        <h3>Social Media withus:</h3>
                        <ul className="socialLinks">
                            <li><a href={socialMedia && socialMedia.twitterUrl} target="_blank"><i className="fab fa-twitter"></i></a></li>
                            <li><a href={socialMedia && socialMedia.facebookLink} target="_blank"><i className="fab fa-facebook"></i></a></li>
                            <li><a href={socialMedia && socialMedia.linkedinLink} target="_blank"><i class="fab fa-linkedin"></i></a></li>
                            {/*<li><a href="#" target="_blank"><i class="fab fa-medium-m"></i></a></li>*/}
                        </ul>
                    </div>
                </div>

            </div>

        </div>
    </>

    );

}

export default Land;
