// import lib
import isEmpty from '../../lib/isEmpty';


export const limitOrderValidate = (value) => {
    var errors = {};
    console.log("limit_price",value.price)
    if (isEmpty(value.price)) {
        console.log("limit_price",value.price)
        errors.price = "Price field is Required"
        // return errors;
    } else if (isNaN(value.price)) {
        errors.price = "Price only numeric value"
        // return errors;
    } else if (parseFloat(value.price) < 0) {
        errors.price = "Prices only positive numeric value";
        // return errors;
    }

    if (isEmpty(value.quantity)) {
        errors.quantity = "Quantity field is Required"
        // return errors;
    } else if (isNaN(value.quantity)) {
        errors.quantity = "Quantity only numeric value"
        // return errors;
    } else if (parseFloat(value.quantity) < 0) {
        errors.quantity = "Quantity only positive numeric value";
        // return errors;
    }

    if (isEmpty(value.buyorsell)) {
        errors.buyorsell = "Side field is Required"
        // return errors;
    } else if (!['buy', 'sell'].includes(value.buyorsell)) {
        errors.value = "Invalid side"
        // return errors;
    }

    // if (isEmpty(value.spotPairId)) {
    //     errors.spotPairId = "Pair field is Required"
    //     return errors;
    // }
    if(isEmpty(value.leverage)){
        errors.leverage = "Leverage is Required"
        // return errors;
    }
    console.log("Errroras",errors)
    return errors;
}

export const marketOrderValidate = (value) => {
    let errors = {};

    if (isEmpty(value.quantity)) {
        errors.quantity = "Quantity field is Required"
        // return errors;
    } else if (isNaN(value.quantity)) {
        errors.quantity = "Quantity only numeric value"
        // return errors;
    } else if (parseFloat(value.quantity) < 0) {
        errors.quantity = "Quantity only positive numeric value";
        // return errors;
    }

    if (isEmpty(value.buyorsell)) {
        errors.buyorsell = "Side field is Required"
        // return errors;
    } else if (!['buy', 'sell'].includes(value.buyorsell)) {
        errors.value = "Invalid side"
        // return errors;
    }

    // if (isEmpty(value.spotPairId)) {
    //     errors.spotPairId = "Pair field is Required"
    //     return errors;
    // }
    if(isEmpty(value.leverage)){
        errors.leverage = "Leverage is Required"
        // return errors;
    }
    console.log(errors, "validation_order")
    return errors
}

export const stopLimitOrdrValidate = (value) => {
    try {
        let errors = {};
        console.log(value, 'stopLimitOrdrValidate')
        if (isEmpty(value.limitRangepips)) {
            errors.Limit_Range = "Limit Range field is Required"
            // return errors;
        } else if (isNaN(value.limitRangepips)) {
            errors.Limit_Range = "Limit Range only numeric value"
            // return errors;
        } else if (parseFloat(value.limitRangepips) < 0) {
            errors.Limit_Range = "Limit Range only positive numeric value";
            // return errors;
        }

        if (isEmpty(value.price)) {
            errors.price = "Price field is Required"
            // return errors;
        } else if (isNaN(value.price)) {
            errors.price = "Price only numeric value"
            // return errors;
        } else if (parseFloat(value.price) < 0) {
            errors.price = "Price only positive numeric value";
            // return errors;
        }

        if (isEmpty(value.quantity)) {
            errors.quantity = "Quantity field is Required"
            // return errors;
        } else if (isNaN(value.quantity)) {
            errors.quantity = "Quantity only numeric value"
            // return errors;
        } else if (parseFloat(value.quantity) < 0) {
            errors.quantity = "Quantity only positive numeric value";
            // return errors;
        }

        if (isEmpty(value.buyorsell)) {
            errors.buyorsell = "Side field is Required"
            // return errors;
        } else if (!['buy', 'sell'].includes(value.buyorsell)) {
            errors.value = "Invalid side"
            // return errors;
        }

        // if (isEmpty(value.spotPairId)) {
        //     errors.spotPairId = "Pair field is Required"
        //     return errors;
        // }
        if (isEmpty(value.leverage)) {
            errors.leverage = "Leverage is Required"
            // return errors;
        }
        console.log(errors, "validation_order")
        return errors
    } catch (err) {
        console.log("stopLimitOrdrValidate___err",err)
    }
}
export const stopOrdrValidate =(value)=>{
    let errors = {};

    if (isEmpty(value.price)) {
        errors.price = "Price field is Required"
        // return errors;
    } else if (isNaN(value.price)) {
        errors.price = "Price only numeric value"
        // return errors;
    } else if (parseFloat(value.price) < 0) {
        errors.price = "Prices only positive numeric value";
        // return errors;
    }

    if (isEmpty(value.quantity)) {
        errors.quantity = "Quantity field is Required"
        // return errors;
    } else if (isNaN(value.quantity)) {
        errors.quantity = "Quantity only numeric value"
        // return errors;
    } else if (parseFloat(value.quantity) < 0) {
        errors.quantity = "Quantity only positive numeric value";
        // return errors;
    }

    if (isEmpty(value.buyorsell)) {
        errors.buyorsell = "Side field is Required"
        // return errors;
    } else if (!['buy', 'sell'].includes(value.buyorsell)) {
        errors.value = "Invalid side"
        // return errors;
    }

    // if (isEmpty(value.spotPairId)) {
    //     errors.spotPairId = "Pair field is Required"
    //     return errors;
    // }
    if(isEmpty(value.leverage)){
        errors.leverage = "Leverage is Required"
        // return errors;
    }
    return errors
}
export const StrategyValidate=(value)=>{
    let errors = {};
    if(isEmpty(value.strategyName)){
        errors.strategyName = 'Strategy Name is Required'
        // return errors;
    }
    if(isEmpty(value.minInvestment)){
        errors.minInvestment = 'Min Investment is Required'
        // return errors;
    }
    if(isEmpty(value.allowCopying)){
        errors.allowCopying = 'Allow Copying For is Required'
        // return errors;
    }
    if(isEmpty(value.visibleFor)){
        errors.visibleFor = 'visible For is Required'
        // return errors;
    }
    if(isEmpty(value.showPositions)){
        errors.showPositions = 'show Positions is Required'
        // return errors;
    }
    if(isEmpty(value.volumeFee)){
        errors.volumeFee = 'volumeFee is Required'
    }
    return errors;

}

export const StartcopyingValidation=(value,copyingData)=>{
    let errors = {};
    // if(isEmpty(value.traderAccountId)){
    //     errors.traderAccountId = 'traderAccountId Name is Required'
    //     return errors;
    // }
    console.log(value,"StartcopyingValidation")
    if(isEmpty(value.accountData)){
        errors.accountData = 'accountData is Required'
        return errors;
    }else if((copyingData.allowCopying !== 'Live and Demo' && value.accountData?.type !== copyingData?.allowCopying.toLowerCase())){
        errors.toast = 'This account type is not validate to copy the strategy'
        return errors;
    }else if(value.accountData.balanec <  copyingData.minInvestment){
        errors.toast = 'balance must be greater than minInvestment'
        return errors;
    }
    if(isEmpty(value.amountInvestment)){
        errors.amountInvestment = 'amountInvestment is Required'
        return errors;
    }
    else if(value.amountInvestment <  copyingData.minInvestment){
        errors.amountInvestment = 'amountInvestment must be greater than minInvestment'
        return errors;
    }
}

const validation = (value) => {
    console.log(value,"validation_order")
    if (value.ordertype === 'limit') {
        return limitOrderValidate(value)
    } else if (value.ordertype === 'market') {
        return marketOrderValidate(value)
    } else if (value.ordertype === 'stop_limit') {
        return stopLimitOrdrValidate(value)
    }else if(value.ordertype === 'stop'){
        return stopOrdrValidate(value)
    }
}

export default validation;