import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "classnames";
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import "./styles.css";
import "./example-styles.css";
import { Tabs, Tab, InputGroup, FormControl, Form, Button, Dropdown, Accordion, Card, Col, Row } from 'react-bootstrap'
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ReactHtmlParser from 'react-html-parser';
import { TraderDetails } from "actions/copyTradeAction";
// import component
import OrderPlace from "./OrderPlace";
import MarketPrice from "./MarketPrice";
import OrderBook from "./OrderBook";
import UserOrderList from "./UserOrderList";
import MarketPriceTable from "./Markerpricetable";
import NewOrder from './newtrade/Modals/NewOrder';
import DoneOrder from './newtrade/Modals/DoneOrder';
import Chart from "react-apexcharts";
// import Chart from "./Chart";
import RecentTrade from "./RecentTrade";
import Sidebar from "./newtrade/Sidebar";
// import Sidebar from "./newtrade/Sidebar";

import info from '../../assets/images/newtrade/info.svg'
import setting from '../../assets/images/newtrade/setting.svg'
import shield from '../../assets/images/newtrade/shield.svg'
import reverse from '../../assets/images/newtrade/reverse.svg'
import double from '../../assets/images/newtrade/double.svg'
import close from '../../assets/images/newtrade/close.svg'
import star from '../../assets/images/newtrade/star.svg'
import flag from '../../assets/images/newtrade/flag.svg'
import Wishlisttab from "./newtrade/elements/Wishlisttab";
import Symboltab from "./newtrade/elements/Symboltab";
import RoiEquidity from "./CopyElements/RoiEquidity";
import Details from "./CopyElements/Details";
import RoiChart from "./CopyElements/RoiChart";
import Summary from "./CopyElements/Summary";
import Performance from "./CopyElements/Perfomance";
import PerformanceStat from "./CopyElements/PerformanceStat";
import VolumeBreak from "./CopyElements/VolumeBreak";
import Volume from "./CopyElements/Volume";
import Trades from "./CopyElements/Trades";
import CopierStat from "./CopyElements/CopierStat";
import Symbols from "./CopyElements/Symbols";
import Position from "./CopyElements/Position";
import History from "./CopyElements/History";
import AddFund from "./newtrade/Modals/AddFund";
import StartCopying from "./newtrade/Modals/StartCopying";
import RestartCopying from "./newtrade/Modals/RestartCopying";

//lib
import { strategyAge } from '../../lib/bybit'
import { getCopyierSummary } from "actions/copyTradeAction";
import { getproviderSummary } from "actions/copyTradeAction";
import isEmpty from "lib/isEmpty";
const CopyTradeStrategy = (props) => {
  const { state } = useLocation()
  const dispatch = useDispatch()
  const { strategydetails } = state
  console.log(props, 'CopyTradeStrategy')
  const [TrdaerDetail, settradedetails] = useState({})
  const [alreadyFollower, setalreadyFollower] = useState("notFollower")
  const [addFunds, SetAddFund] = useState(false)
  const [CopyingData, setCopyingData] = useState({})
  const [startCopying, setStartCopying] = useState(false)
  const [chart, setchart] = useState(data)
  const orderBookRef = useRef();
  const { t, i18n } = useTranslation();
  // state
  const [expandScreen, setExpandScreen] = useState("");

  const [currentBreakpoint, setCurrentBreakpoint] = useState("lg");
  const [compactType, setCompactType] = useState("vertical");
  const [mounted, setMounted] = useState(false);
  const [layouts, setLayouts] = useState({
    lg: props.initialLayout,
  });
  const [neworder, setNewOrder] = useState(false)
  const [doneorder, setDoneOrder] = useState(false)
  const [positiondata, setPositiondata] = useState(0)

  const [flatnav, setFlatNav] = useState(false)
  const [symbolTab, setSymbolTab] = useState(false)
  const [shrink, setShrink] = useState(false)
  const [summaryDetails, setSummaryDetails] = useState()
  const [copiedAccount, setcopiedAccount] = useState({})
  const [showPosition, setshowPosition] = useState(true)
  const [restartCopying, setRestartCopying] = useState(false)
  const [restartData, setrestartData] = useState({})
  const [restartAccount, setrestartAccount] = useState({})
  const [copyTradeFollowID, setCopyTradeFollowID] = useState("")


  // const [setFollowersDetails,setFollowersDetails] = useState()
  const position = <>Position <span className="badge badge-secondary px-2">{positiondata}</span></>

  const orders = <>Orders <span className="badge badge-secondary px-2">0</span></>
  const priceallert = <>Price alert <span className="badge badge-secondary px-2">0</span></>

  const setWhole = (value) => {
    console.log(value, "value");
    // 👇️ take parameter passed from Child component
    setShrink(value)
  }

  const accountData = useSelector(state => state.account);
  const { accounts, useraccount } = accountData
  // function

  const onBreakpointChange = (breakpoint) => {
    setCurrentBreakpoint(breakpoint);
  };

  useEffect(() => {
    setMounted(true);
    getTradeDetails()
    getproviderSummaryDetails()
    if (strategydetails && accounts?.length > 0) {
      // accounts.map((vals,index)=>{
      //   let Follower = TrdaerDetail.Followers.find((val)=>val.followerAccountId==vals.accountId)
      //   if(Follower){
      //     setalreadyFollower(true)
      //     return
      //   }
      // })
    }
  }, [strategydetails, accounts]);
  const getTradeDetails = async () => {
    try {
      let data = {
        traderAccountId: strategydetails?.accountId,
        traderId: strategydetails?.userId
      }
      let { status, result, message } = await TraderDetails(data)
      if (status) {
        
        settradedetails(result)
        if (result && accounts?.length > 0 && accounts != undefined) {
          let alreadyFollower = false
          accounts.map((vals, index) => {
            let Follower = result.Followers.find((val) =>(val.followerAccountId == vals.accountId));
            console.log('settradedetails_09', result,Follower);
            if(Follower){
              if (Follower?.status == "active") {
                setalreadyFollower("active")
                alreadyFollower = true
                // setFollowersDetails(Follower)
                // return
              } else if (Follower?.status == "inactive") {
                console.log(Follower,"Follower__check")
                setalreadyFollower("inactive")
                alreadyFollower = true
                setrestartData(Follower)
                setCopyTradeFollowID(Follower._id)
  
              } else if (Follower?.status == "removedByProvider") {
                setalreadyFollower("removedByProvider")
                alreadyFollower = true
                return
              }
            }
          })
          let showPosition = result.showPositions == "Everyone" ? true : (result.showPositions == "Only my Copiers" && alreadyFollower) ? true : false
          setshowPosition(result.showPositions == "Everyone" ? true :
            (result.showPositions == "Only my Copiers" && alreadyFollower) ? true : false)
            console.log(showPosition,"showPosition")
          if (!showPosition) {
            document.getElementById(`controlled-tab-example-tab-new-history`).click()
          }


        }
      } else {
        console.log(message, 'TradeDetail')
      }
    } catch (err) {
      console.log(err, 'getTradeDetails--err')
    }
  }

  // const getCopySummary = async () => {
  //   let localAcc = localStorage.getItem('accounts');
  //   console.log('localAcclocalAcc',localAcc);
  //   // if(TrdaerDetail.accountId != undefined){
  //     console.log({traderAccountId: TrdaerDetail.accountId,
  //       followerAccountId: localAcc
  //       },'getCopySummary_SendData');
  //     const getData = await getCopyierSummary({
  //       traderAccountId: TrdaerDetail.accountId,
  //       followerAccountId: localAcc
  //       })
  //       console.log("getCopySummary_0101",getData)
  //       setSummaryDetails(getData.result)
  //   }

  const getproviderSummaryDetails = async () => {
    console.log('Summary_provider_details_01', strategydetails?.accountId, strategydetails?.userId);
    const getData = await getproviderSummary({ accountId: strategydetails?.accountId, userId: strategydetails?.userId }, dispatch);
    setSummaryDetails(getData.result)
    console.log('Summary_provider_details', getData);
  }

  const favorites = <>Favotites <span className="badge badge-secondary px-2">0</span></>


  const [showdrop, setShowDrop] = useState(false)
  const [showdropdate, setShowDropDate] = useState(false)


  const [info, setInfo] = useState(false)
  const [active, isActive] = useState(1)
  const [expand, setExpand] = useState(false)

  // data reuse
  const [mode, setMode] = useState("live")
  const [startedAccount, sestartedAccount] = useState({})

  const openAcc = (e) => {
    console.log(e.target.nextElementSibling, "e");
    var cont = e.target.parentNode.nextElementSibling
    if (!(cont.classList.contains("d-none"))) {
      cont.classList.add("d-none")
    } else {
      cont.classList.remove("d-none")
    }
  }

  const data = {
    options: {
      chart: {
        id: "basic-bar",
        foreColor: "#ccc",
        toolbar: {
          autoSelected: "pan",
          show: false
        }
      },
      colors: ["#2769ba"],
      stroke: {
        width: 3
      },
      grid: {
        borderColor: "#555",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: false
          },
        }
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        gradient: {
          enabled: true,
          opacityFrom: 0.75,
          opacityTo: 0
        }
      },
      markers: {
        size: 2,
        colors: ["#2769ba"],
        strokeColor: "#2769ba",
        strokeWidth: 3
      },
      tooltip: {
        theme: "dark"
      },
      xaxis: {
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
      }
    },
    series: [
      {
        name: "series-1",
        data: [30, 40, 45, 50, 49, 60, 70, 91]
      },

    ]
  };
  const data1 = {
    options1: {
      chart: {
        id: "basic-bar",
        foreColor: "#ccc",
        toolbar: {
          autoSelected: "pan",
          show: false
        }
      },
      colors: ["#2769ba"],
      stroke: {
        width: 3
      },
      grid: {
        borderColor: "#555",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: false
          },
        }
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        gradient: {
          enabled: true,
          opacityFrom: 0.75,
          opacityTo: 0
        }
      },
      markers: {
        size: 2,
        colors: ["#2769ba"],
        strokeColor: "#2769ba",
        strokeWidth: 3
      },
      tooltip: {
        theme: "dark"
      },
      xaxis: {
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
      },
      yaxis: {
        labels: {
          show: false
        }
      }
    },
    series1: [
      {
        name: "series-1",
        data: [30, 40, 45, 50, 49, 60, 70, 91]
      },

    ]
  };

  const data2 = {
    options2: {
      chart: {
        id: "basic-bar",
        foreColor: "#ccc",
        toolbar: {
          autoSelected: "pan",
          show: false
        }
      },
      colors: ['#009345', '#f05824'],
      stroke: {
        width: 3
      },
      grid: {
        borderColor: ["#555"],
        clipMarkers: false,
        yaxis: {
          lines: {
            show: false
          },
        }
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        // gradient: {
        //   enabled: true,
        //   opacityFrom: 0.75,
        //   opacityTo: 0
        // }
        colors: ['#009345', '#f05824']
      },
      markers: {
        size: 2,
        colors: ["#2769ba"],
        strokeColor: "#2769ba",
        strokeWidth: 3
      },
      tooltip: {
        theme: "dark"
      },
      xaxis: {
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002]
      },
      yaxis: {
        labels: {
          show: false
        }
      }
    },
    series2: [{
      name: 'Winning Trades',
      type: 'column',
      data: [440, 505, 414, 571, 227, 413, 201, 352, 652, 320, 257, 160]
    }, {
      name: 'Losing Trades',
      type: 'column',
      data: [400, 450, 500, 300, 100, 500, 210, 300, 652, 300, 300, 50]
    }],
  };


  const data3 = {
    options3: {
      chart: {
        width: '100%',
        type: 'donut',
        foreColor: "#ccc",
        toolbar: {
          autoSelected: "pan",
          show: false
        }
      },
      colors: ["#fdac9c", "#ab70f4", "#35df91", "#fbc662", "#34a8ff", "#eb11d6"],
      fill: {
        // gradient: {
        //   enabled: true,
        //   opacityFrom: 0.75,
        //   opacityTo: 0
        // }
        colors: ["#fdac9c", "#ab70f4", "#35df91", "#fbc662", "#34a8ff", "#eb11d6"]
      },
      markers: {
        size: 2,
        colors: ["#fdac9c", "#ab70f4", "#35df91", "#fbc662", "#34a8ff", "#eb11d6"],
        strokeColor: "#2769ba",
        strokeWidth: 3
      },
      tooltip: {
        theme: "dark"
      },
      labels: ['US TECH 100', 'XAUUSD', 'Germany 30', 'EURUSD', 'Other', 'GBPJPY'],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 230
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },
    series3:
      //  [  
      [44, 55, 13, 43, 22, 80]
    // {
    //   name: "series-1",
    //   data: [44, 55, 13, 43, 22]
    // },

    // ]
  };

  const data4 = {
    options4: {
      chart: {
        id: "basic-bar",
        foreColor: "#ccc",
        toolbar: {
          autoSelected: "pan",
          show: false
        }
      },
      colors: ["#2769ba"],
      stroke: {
        width: 3
      },
      grid: {
        borderColor: "#555",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: false
          },
        }
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        gradient: {
          enabled: true,
          opacityFrom: 0.75,
          opacityTo: 0
        }
      },
      markers: {
        size: 2,
        colors: ["#2769ba"],
        strokeColor: "#2769ba",
        strokeWidth: 3
      },
      tooltip: {
        theme: "dark"
      },
      xaxis: {
        categories: [1991, 1992, 1993]
      },
      yaxis: {
        labels: {
          show: false
        }
      }
    },
    series4: [
      {
        name: "series-1",
        data: [30, 40, 45]
      },

    ]
  };
  const CheckTradeDetails = async (accountData) => {
    try {
      if (TrdaerDetail) {
        let FollowingData = TrdaerDetail?.Followers?.find((vals) => ((vals.followerAccountId).toLowerCase() == accountData.accountId.toLowerCase()))
        console.log(FollowingData, accountData, "FollowingData")
        if (!FollowingData || FollowingData?.status == "removedByProvider") {
          StartCopyingShow(accountData.accountId)
        }
        else if (FollowingData) {
          console.log("I am worked")
          if (FollowingData.status == "inactive") {
            restartShow(accountData.accountId, FollowingData._id)
          }
          else if (FollowingData.status == "active") {
            addfundShow(accountData.accountId)
          }
        }
      }
      else {
        console.log(TrdaerDetail, 'TradeDetail__message')
      }
    } catch (err) {
      console.log(err, 'getTradeDetails--err')
    }
  }
  const restartShow = (accountId, id) => {
    try {
      let accountData = accounts.find((val) => (val.accountId == accountId));
      setCopyTradeFollowID(id)
      setrestartAccount(accountData);
      setRestartCopying(true)
    } catch (err) {
      console.log(err, "restartShow__err")
    }
  }
  const addfundShow = (accountId) => {
    try {
      let accountData = accounts.find((val) => (val.accountId == accountId));
      setcopiedAccount(accountData);
      SetAddFund(true)
    } catch (err) {
      console.log(err, "addfundShow__err")
    }
  }
  const StartCopyingShow = (accountId) => {
    try {
      let accountData = accounts.find((val) => (val.accountId == accountId));
      sestartedAccount(accountData);
      setStartCopying(true)
    } catch (err) {
      console.log(err, "StartCopyingShow__err")
    }
  }

  const history = useHistory();
  return (
    <div className={shrink ? "sticky_sidebar d-flex gap-3 shrink_div main_div_col" : "sticky_sidebar d-flex gap-3 main_div_col"}>
      {console.log(alreadyFollower,"CopyingData_alreadyFollower",restartAccount)}
      <Sidebar setWhole={setWhole} activetabkey={"1"} id="side_copy" />
      {startCopying && <StartCopying
        onDismiss={() => setStartCopying(false)}
        copyingData={CopyingData}
        // AddFund={(accountId) => {
        //   let accountData = accounts.find((val) => (val.accountId == accountId));
        //   setcopiedAccount(accountData);
        //   SetAddFund(true)
        // }}
        // restart={(accountId, id) => {
        //   let accountData = accounts.find((val) => (val.accountId == accountId));
        //   setCopyTradeFollowID(id)
        //   setrestartAccount(accountData);
        //   setRestartCopying(true)
        // }}
        AddFund={(accountId) => {addfundShow(accountId)}}
        restart = {(accountId, id)=>{restartShow(accountId, id)}}
        startedAccount={isEmpty(startedAccount) ? useraccount : startedAccount}
      />}
      {addFunds && <AddFund
        onDismiss={() => SetAddFund(false)}
        copyingData={CopyingData}
        // startCopying={(accountId) => {
        //   let accountData = accounts.find((val) => (val.accountId == accountId));
        //   sestartedAccount(accountData);
        //   setStartCopying(true)
        // }}
        // restart={(accountId, id) => {
        //   let accountData = accounts.find((val) => (val.accountId == accountId));
        //   setCopyTradeFollowID(id)
        //   setrestartAccount(accountData);
        //   setRestartCopying(true)
        // }}
        startCopying = {(accountId)=>{StartCopyingShow(accountId)}}
        restart = {(accountId, id)=>{restartShow(accountId, id)}}
        copiedAccount={isEmpty(copiedAccount) ? useraccount : copiedAccount} />
      }
      {restartCopying && <RestartCopying
        onDismiss={() => setRestartCopying(false)}
        copyingData={CopyingData}
        copyTradeFollowID={copyTradeFollowID}
        restartAccount={restartAccount}
        // startCopying={(accountId) => {
        //   let accountData = accounts.find((val) => (val.accountId == accountId));
        //   sestartedAccount(accountData);
        //   setStartCopying(true)
        // }}
        // AddFund={(accountId) => {
        //   let accountData = accounts.find((val) => (val.accountId == accountId));
        //   setcopiedAccount(accountData);
        //   SetAddFund(true)
        // }}
        startCopying = {(accountId)=>{StartCopyingShow(accountId)}}
        AddFund = {(accountId)=>{addfundShow(accountId)}}
      />}
      {neworder && <NewOrder onDismiss={() => setNewOrder(false)} openinfo={() => { setInfo(true); setNewOrder(false) }} />}
      {doneorder && <DoneOrder onDismiss={() => setDoneOrder(false)} />}

      <div className="w-full wfull_new calc_width">
        <div className="text-right-web-btn">
          <button class="margin_top_banck_bn btn btn-outline-secondary f-12 icon-50 p-0 px-2 mx-2 back_bt_det" onClick={() => history.push("/copy")}>Back</button>
        </div>
        <div className="darkbox darkbx_bg_new">
          <div className="inner_box_bg favscroll">
            <div className="w-full">
              <div className="darkbox">
                <div className="d-flex w-full block_mob_res_det justify-content-between h5-align align-items-center p-2 gap-2 flex_red_dir_sm_la">
                  <div className="d-flex gap-1">
                    <span className="fa fa-star star_align"></span>
                    <div className="d-inline-block  pr-3 flex-1 jc-between p-0 ">
                      <div className="d-flex gap-4 jc-between p-0 mb-3">
                        <p>{TrdaerDetail?.strategyName}</p>
                        {TrdaerDetail?.type == "live" ?
                          <button className="btn btn-outline-success f-12 icon-50 p-0 px-2 my-0">Live</button>
                          :
                          <button className="btn btn-outline-info f-12 icon-50 p-0 px-2 my-0">Demo</button>
                        }
                      </div>
                      {/* <div className="d-flex jc-between p-0 flex_widh_min">
                        <span></span>
                        <div className="text-center">
                          <span>116624984</span>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="tab_content text-center align-items-center pr-3  pl-3 justify-content-center">
                    <span>All Time ROI</span>
                    <h5 className="text-success">{TrdaerDetail && TrdaerDetail?.returnOfInvestment?.toFixed(4)}%</h5>
                  </div>
                  <div className="tab_content text-center pr-3  pl-3 justify-content-center">
                    <span>Copiers</span>
                    <h5>{TrdaerDetail?.Followers?.filter((val) => (val.status == "active")).length}</h5>
                  </div>
                  <div className="tab_content text-center align-items-center pr-3  pl-3 justify-content-center">
                    <span>Strategy Age</span>
                    <h5>{strategyAge(TrdaerDetail?.createdDate)}</h5>
                  </div>
                  {alreadyFollower == "active" ?
                    <div className="btn_bot_sps pos_change_sm_lar">
                      <button className="btn align-items-center text-center strat_copy_text btn-success pt-0 justify-content-center bnt_mobi_strat_align"
                        onClick={(e) => { setCopyingData(TrdaerDetail); SetAddFund(true); e.stopPropagation() }}
                      >
                        <h6 className="text-white">Add Funds</h6>
                      </button>
                      <button className="btn align-items-center text-center strat_copy_text btn-success bg-gray pt-0 justify-content-center bnt_mobi_strat_align"
                        onClick={(e) => { setCopyingData(TrdaerDetail); setStartCopying(true); e.stopPropagation() }}
                      >
                        <h6 className="text-white my-1 text-center">Start copying with another account</h6>
                      </button>
                    </div>
                    :
                    alreadyFollower == "inactive" ?
                      <div className="btn_bot_sps pos_change_sm_lar">
                        <button className="btn align-items-center text-center strat_copy_text btn-success bg-orange pt-0 justify-content-center bnt_mobi_strat_align"
                          onClick={(e) => {
                            console.log(restartData,"restartAccount")
                            setCopyingData(TrdaerDetail);
                            // setRestartCopying(true);
                            let accountData = accounts.find((val)=>(val.accountId==restartData.followerAccountId))
                            // setrestartAccount(accountData)
                            CheckTradeDetails(accountData)
                            e.stopPropagation()
                          }}
                        >
                          <h6 className="text-white">Restart Copying</h6>
                        </button>
                        <button className="btn align-items-center text-center strat_copy_text btn-success bg-gray pt-0 justify-content-center bnt_mobi_strat_align"
                          onClick={(e) => { 
                            setCopyingData(TrdaerDetail); 
                            CheckTradeDetails(isEmpty(startedAccount) ? useraccount : startedAccount)
                            // setStartCopying(true); 
                            e.stopPropagation() 
                          }}
                        >
                          <h6 className="text-white my-1 text-center">Start copying with another account</h6>
                        </button>
                      </div>
                      :
                      <button className="btn align-items-center text-center strat_copy_text btn-success pt-0 justify-content-center bnt_mobi_strat_align"
                        onClick={(e) => { 
                          setCopyingData(TrdaerDetail); 
                          CheckTradeDetails(isEmpty(startedAccount) ? useraccount : startedAccount)
                          // setStartCopying(true); 
                          e.stopPropagation() 
                        }}
                      >
                        <h6 className="text-white">Start Copying</h6>
                      </button>
                  }
                  {/* EITHER ONE */}
                  {/* <span className="text-light">Free</span> */}
                  {/* <span className="text-light">M:0.00%&nbsp;P:15.00%&nbsp;V:USD 2.50</span> */}
                  {/* EITHER ONE */}
                </div>
              </div>
            </div>
            <div className="m-1 boxstyles">

              <div className="row mb-0">
                <div className="col-12 col-lg-8 pr-3 pr-lg-0">
                  <div className="p-0 mt-1 gray_tab_marg_1 h-100">
                    <RoiEquidity TrdaerDetail={TrdaerDetail} Followerdetails='' type='strategy' />
                    {/* <Tabs defaultActiveKey="roi" id="uncontrolled-tab-example" className="graytab pt-2 px-0">
                  <Tab eventKey="roi" title="ROI (%)" className="px-0">
                  <Tabs defaultActiveKey="1w" id="uncontrolled-tab-example2" className="graytab pt-2 px-0 tab_style_inner_grey">
                  <Tab eventKey="1w" title="1w" className="px-0">
                  <Chart options={data?.options}
                          series={data?.series}
                          type="area" height={200} width="100%" className="px-0" />
                  <p className="graph_desc_data">* ROI is updated every 15 minutes.</p>
                  </Tab>               
                  <Tab eventKey="1m" title="1m" className="px-0">
                  <Chart options={data?.options}
                          series={data?.series}
                          type="area" height={200} width="100%" className="px-0"/>
                  <p className="graph_desc_data">* ROI is updated every 15 minutes.</p>
                 
                  </Tab>                     <Details accountId={state.accountId} />
assName="px-0">
                  <Chart options={data?.options}
                          series={data?.series}
                          type="area" height={200} width="100%" className="px-0"/>
                  <p className="graph_desc_data">* ROI is updated every 15 minutes.</p>
                 
                 </Tab> 
                 <Tab eventKey="6m" title="6m" className="px-0">
                 <Chart options={data?.options}
                          series={data?.series}
                          type="area" height={200} width="100%" className="px-0"/>
                  <p className="graph_desc_data">* ROI is updated every 15 minutes.</p>
                 
                 </Tab> 
                 <Tab eventKey="1y" title="1y" className="px-0">
                 <Chart options={data?.options}
                          series={data?.series}
                          type="area" height={200} width="100%" className="px-0"/>
                  <p className="graph_desc_data">* ROI is updated every 15 minutes.</p>
                 
                 </Tab>   
                 <Tab eventKey="all" title="All" className="px-0">
                 <Chart options={data?.options}
                          series={data?.series}
                          type="area" height={200} width="100%" className="px-0"/>
                  <p className="graph_desc_data">* ROI is updated every 15 minutes.</p>
                 
                 </Tab>               
                </Tabs>
                  </Tab>               
                  <Tab eventKey="equity" title="Equity" className="px-0">
                  <Tabs defaultActiveKey="1w" id="uncontrolled-tab-example1" className="graytab pt-2 px-0 tab_style_inner_grey">
                  <Tab eventKey="1w" title="1w" className="px-0">
                  <Chart options={data?.options}
                          series={data?.series}
                          type="area" height={200} width="100%" className="px-0"/>
                  <p className="graph_desc_data">* Equity is updated every 15 minutes and does not include bonus.</p>
                  </Tab>               
                  <Tab eventKey="1m" title="1m" className="px-0">
                  <Chart options={data?.options}
                          series={data?.series}
                          type="area" height={200} width="100%" className="px-0"/>
                  <p className="graph_desc_data">* Equity is updated every 15 minutes and does not include bonus.</p>
                 
                  </Tab> 
                  <Tab eventKey="3m" title="3m" className="px-0">
                  <Chart options={data?.options}
                          series={data?.series}
                          type="area" height={200} width="100%" className="px-0"/>
                  <p className="graph_desc_data">* Equity is updated every 15 minutes and does not include bonus.</p>
                 
                 </Tab> 
                 <Tab eventKey="6m" title="6m" className="px-0">
                 <Chart options={data?.options}
                          series={data?.series}
                          type="area" height={200} width="100%" className="px-0"/>
                  <p className="graph_desc_data">* Equity is updated every 15 minutes and does not include bonus.</p>
                 
                 </Tab> 
                 <Tab eventKey="1y" title="1y" className="px-0">
                 <Chart options={data?.options}
                          series={data?.series}
                          type="area" height={200} width="100%" className="px-0"/>
                  <p className="graph_desc_data">* Equity is updated every 15 minutes and does not include bonus.</p>
                 
                 </Tab>   
                 <Tab eventKey="all" title="All" className="px-0">
                 <Chart options={data?.options}
                          series={data?.series}
                          type="area" height={200} width="100%" className="px-0"/>
                  <p className="graph_desc_data">* Equity is updated every 15 minutes and does not include bonus.</p>
                 
                 </Tab>               
                </Tabs>
                  </Tab>                 
                </Tabs> */}
                  </div>
                </div>
                <div className="col-12 col-lg-4 pl-3 pl-lg-1 detail_tab_height">
                  <div className="graytab mt-5-det">
                    {console.log('theStateAccountId', state)}
                    <Details TrdaerDetail={TrdaerDetail} />
                  </div>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-12 col-lg-8 pr-3 pr-lg-0 mb-roi-mon">
                  <div className="graytab h-100">
                    <RoiChart TrdaerDetail={TrdaerDetail} Followerdetails='' type='strategy' />
                    {/* <div className="tab-content h-100">
                    <p className="boxheadings mb-1">ROI (Monthly)</p>
                    <div className="darkbox p-3 bg_trans_tab">
                      <button className="btn_only_tab btn_tab_pos">
                        2022
                      </button>
                 
                  <Chart options={data1?.options1}
                          series={data1?.series1}
                          type="bar" height={248} width="100%" className="px-0" />
               
                </div>
                 
                          
                          </div> */}
                  </div>
                </div>
                <div className="col-12 col-lg-4 pl-3 pl-lg-1 mb-sum-mon">
                  <div className="graytab">
                    <Summary summaryDetails={summaryDetails} TrdaerDetail={TrdaerDetail} />
                  </div>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-12 col-lg-8 pr-3 pr-lg-0 mb-roi-mon">
                  <div className="graytab">
                    <div className="tab-content">
                      <p className="boxheadings mb-1">Performance</p>
                      <div className="darkbox p-3 bg_trans_tab">
                        <Performance TrdaerDetail={TrdaerDetail} Followerdetails='' type='strategy' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 pl-3 pl-lg-1 detail_tab_height_1 mb-sum-mon">
                  <div className="graytab">
                    <PerformanceStat summaryDetails={summaryDetails?.ClosedPosition[0]} />
                  </div>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-12 col-lg-8 pr-3 pr-lg-0 mb-roi-mon">
                  <div className="graytab">
                    <div className="tab-content">
                      <p className="boxheadings mb-1">Volume Breakdown</p>
                      <div className="darkbox p-3 bg_trans_tab">
                        <VolumeBreak TrdaerDetail={TrdaerDetail} Followerdetails='' type='strategy' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 pl-3 pl-lg-1 detail_tab_height_1">
                  <div className="graytab">
                    <Volume summaryDetails={summaryDetails} />
                  </div>
                </div>
              </div>
              <div className="row mb-0">
                <div className="col-12  pr-3  mb-sum-mon">
                  <Tabs defaultActiveKey="trades" id="uncontrolled-tab-example" className="graytab pt-2 px-0">
                    <Tab eventKey="trades" title="Trades">
                      <Trades summaryDetails={summaryDetails} Followerdetails='' type='strategy' />
                    </Tab>
                    <Tab eventKey="symbols" title="Symbols">
                      <Symbols summaryDetails={summaryDetails} TrdaerDetail={TrdaerDetail} Followerdetails='' type='strategy' />
                    </Tab>
                  </Tabs>
                </div>
                <div className="col-12 col-lg-12 pl-3">
                  <CopierStat TrdaerDetail={TrdaerDetail} />
                </div>
              </div>
              {/* <div className="row mb-0">
                <div className="col-12">
                  <div className=" p-0 mt-1">
                    <Tabs defaultActiveKey="symbols" id="uncontrolled-tab-example" className="graytab pt-2 px-0">
                      <Symbols />
                    </Tabs>
                  </div>
                </div>
              </div> */}
              <div className="row mb-0 pb-2">
                <div className="col-12">
                  <div className=" p-0 mt-1">
                    <Tabs
                      // defaultActiveKey={showPosition && showPosition ? "position" : "history"} 
                      id="uncontrolled-tab-example-new"
                      className="graytab pt-2 px-0">
                      {/* {TrdaerDetail.showPositions == "Everyone" ?
                        <Tab eventKey="position" title={position}>
                          <Position TrdaerDetail={TrdaerDetail} Followerdetails='' type='strategy' onSubmit={(data) => { setPositiondata(data) }} />
                        </Tab> :
                        (TrdaerDetail.showPositions == "Only my Copiers" && alreadyFollower) ?
                          <Tab eventKey="position" title={position}>
                            <Position TrdaerDetail={TrdaerDetail} Followerdetails='' type='strategy' onSubmit={(data) => { setPositiondata(data) }} />
                          </Tab> :
                          <></>
                      } */}
                      {showPosition ?
                        <Tab eventKey="position" title={position} id="position">
                          <Position TrdaerDetail={TrdaerDetail} Followerdetails='' type='strategy' onSubmit={(data) => { setPositiondata(data) }} />
                        </Tab>
                        :
                        <></>
                      }
                      {/* <Tab eventKey="position" title={position}>
                        <Position TrdaerDetail={TrdaerDetail} Followerdetails='' type='strategy' onSubmit={(data) => { setPositiondata(data) }} />
                      </Tab> */}
                      <Tab eventKey="history" title="History" id="history">
                        <History TrdaerDetail={TrdaerDetail} Followerdetails='' type='strategy' />
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="graytab">
                    <div className="tab-content tab_cont_box_border">
                      <p className="boxheadings mb-1">Strategy Description</p>
                      <div className="darkbox">
                        <ul className="pl-0 symbolTab_list">
                          <div className="sybm_tab_div">
                            {ReactHtmlParser(TrdaerDetail?.Descriptions)}
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CopyTradeStrategy;