import React, { Component } from 'react';

const Otpverify = () => {

    return (
      <div className='phoneverifykyc otpverify'>
        <div className='logosec pt-5'>
         {/* <img src={require("../assets/images/logo.png")} alt="Banner" className="img-fluid logo_200" /> */}
         </div>
        <div className='row firstrow mx-auto mt-5'>
          <div className='col-md-5 col-lg-5 leftsec'>
            <h3 className='sectitle mt-5'>Phone Number</h3>
            <p  className='secpara'>Verify phone number for your KYC</p>
          </div>
          <div className='col-md-7 col-lg-6'>
          <div class="card" >
 
  <div class="card-body px-3 px-sm-5 py-5">
  <form class='col-12 mx-auto'>
  <div className='row mt-2 labelname'>
   
   <div className='wid_inp'>
<p className='otpenter'>Enter the OTP sent to this number</p>
<div className="input-group phonenumber mb-3">
    
    

    <input type="email" class="form-control" placeholder="Your Email Address" aria-label="Your Email Address" aria-describedby="basic-addon2" value={9876543210}/>
        
       
    <span class="input-group-text" id="basic-addon2"><i class="fas fa-pencil-alt"></i></span>
    </div>
</div>

 </div>
  <div className='row mt-2 labelname'>
   
     <div className='wid_inp'>
   <div className="input-group mb-3">
    
    

   <input id="partitioned" type="text" maxlength="6" />
      
     
  
  </div>
</div>

   </div>
   <div className='row mt-3 mx-0 text-center'>
 
 <button
                                  className="btn btn-grey text-uppercase py-2 m-0"
                                  type="button"
                                  
                              >
                             VERIFY OTP
                              </button>
                           
                              </div>
                              <div className='row resendrow mt-3'>
                                <p className='resendotp'>Resend OTP in 20 seconds</p>
                                <p className='resendotpcall'>Resend OTP via Call</p>
 
                    
                           
                              </div>
  </form>

  </div>
</div>
          </div>


</div>

        
      </div>
    );
  }


export default Otpverify;