import React, { useState, useEffect } from 'react';
import { Modal, Tabs, Dropdown, InputGroup, FormControl, Button, Form, Tab } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { MenuItem, Select } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Synaps from "@synaps-io/react-verify";
// import component
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem"

import { updateIdProof } from '../../../../actions/userKyc'

import isEmpty from '../../../../lib/isEmpty';
import { toastAlert } from '../../../../lib/toastAlert';
import validation from '../../../IDProof/validation';




const Kycvalidatemodal = (props) => {

    const [kycvalidate, setKycvalidate] = useState(true)
    //state
    return (
        <>
            <Modal show={kycvalidate} className="modalbg uploadmodal font-12" size="md" centered scrolable id="reee">

                <Modal.Header className='align-items-center'>
                    <Modal.Title>KYC Validation</Modal.Title>
                    <button className='closebtn btn btn-link text-light nounder' onClick={() => props.onDismiss()}>
                    <i className="fa fa-times fa-6" aria-hidden="true"></i>

                        </button>
                </Modal.Header>
                <Modal.Body className='font-12'>
                    <div className="modal_synap_cen">
                        <Synaps
                            className="synaps_width"
                            sessionId={props.Id}
                            service={"individual"}
                            lang={"en"}
                            onReady={() => console.log("component ready")}
                            onFinish={() => {console.log("user finish process");props.onDismiss()}}
                            color={{
                                primary: "212b39",
                                secondary: "ffffff"
                            }}
                            withFinishButton={true}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Kycvalidatemodal
