import React, { Component, useEffect, useState, useContext, useRef } from 'react';
import { InputGroup, Form, FormControl } from 'react-bootstrap'
import Chart from "react-apexcharts";
import setting from '../../../assets/images/newtrade/setting.svg'
import close from '../../../assets/images/newtrade/close.svg'
import { getPositionOrder } from 'actions/perpetualTradeAction';
import { useSelector } from 'react-redux';
import { getPairList } from 'actions/spotTradeAction';
import { momentFormat } from 'lib/dateTimeHelper';
import { toFixed, toLocaleString, lotDisplay } from 'lib/roundOf';
import clsx from "classnames";
import { capitalize } from '@material-ui/core';
import { getStrategyPositionOrder } from 'actions/perpetualTradeAction';
import { filterPositionOrder } from 'actions/perpetualTradeAction';
import { filterPositionOrderstrategy } from 'actions/perpetualTradeAction';
import SocketContext from '../../Context/SocketContext';
import { quantitytovolume, getPorfitCalculation } from '../../../lib/bybit';
import Positionorder from '../newtrade/Positionorder';
import isEmpty from 'lib/isEmpty';
const Position = (props) => {
  const { TrdaerDetail, Followerdetails } = props
  const {accounts,useraccount} = useSelector(state => state.account);
  const [positionPage, Setpositionpage] = useState(1)
  const [positionCount, setpositionCount] = useState(0)
  const [position, setPosition] = useState([]);
  const [pair, setPair] = useState([]);
  const pairList = useSelector(state => state.pairList);
  const [fillterposition, setfillterposition] = useState(false)
  const [positionsearch, setPositionsearch] = useState("");
  const [alldirection, setAlldirection] = useState("");
  const [positionorder, setPositionorder] = useState([])
  const [bpositionorder, setBpositionorder] = useState([]);
  const [fpositinorder, setfposition] = useState(false)
  const [aggregated, setAggregated] = useState('Not Aggregated');
  const [Equtitypre, SetEqutityper] = useState(0)
  // const [PandLSingel, SetPandLSingel] = useState()
  const [Margin, setPositionMargin] = useState(0)
  const [freemagins, SetFreemargin] = useState(0)
  const [marginlevels, Setmarginlevels] = useState(0)
  const [profitandloss, SetProLoss] = useState(0)
  const [balance, setbalance] = useState(0)
  const socketContext = useContext(SocketContext)
  const positionsorder = useRef(position)
  const pairsList = useRef(pairList)
  console.log('Positionpairpair', props);

  useEffect(() => {
    
    postionDetails();
    getpairs()
  }, [TrdaerDetail, Followerdetails]);
  useEffect(()=>{
    if(props.type == "provider" && !isEmpty(accounts)){
      let usedAccount = accounts.find((val)=>(val.accountId==TrdaerDetail?.accountId))
      console.log(usedAccount,'usedAccount',accounts,TrdaerDetail?.accountId)
      if(!isEmpty(usedAccount)){
        setbalance(usedAccount?.balance)
      }
    }
  },[accounts,TrdaerDetail])
  useEffect(() => {
    positionsorder.current = position
  }, [position])
  useEffect(() => {
    pairsList.current = pairList
  }, [pairList])
  useEffect(() => {
    socketContext.socket.on('positionmargin', (results) => {
      console.log('positionmargin__socket', results)
      try {
        // SetPandLSingel(results.prolss1)
        if (props.type == "strategy") {
          setbalance(results.balance)
        }
        SetEqutityper(results.equity)
        setPositionMargin(results.positionmargins)
        SetFreemargin(results.freemargin)
        Setmarginlevels(results.marginlevels)
        SetProLoss(results.prolss)
        // setCloseBuy(results.sellCount.length > 0 ? true : false)//This  sell buy inverse for closing trade
        // setCloseSell(results.buyCount.length > 0 ? true : false)
        // let data = {
        //   equity: results.equity,
        //   positionmargins: results.positionmargins,
        //   freemargin: results.freemargin,
        //   marginlevels: results.marginlevels
        // }
        // dispatch(setEquAndmarign(data))
      } catch (err) {
        console.log(err, 'positionmargin__err')
      }
      socketContext.socket.on('perpetualmarketprice', async (result) => {
        try {
          // if(result.type == localStorage.getItem("liveorDemo")){
            await ArrangethePositionorders(result)
          // }
        } catch (err) {
          console.log(err, 'perpetualmarketprice___err')
        }
      })
    })
  }, [socketContext.socket,useraccount])

  const ArrangethePositionorders = async (result) => {
    try {
      // console.log('perpetualmarketprice', positionsorder.current, pairsList.current)
      if (positionsorder.current?.length > 0) {
        let data = positionsorder.current.filter((val) => (val.pairName == result.tiker_root))
        data.length > 0 && data.map(async (val, i) => {
          if (val.pairName == result.tiker_root) {
            let pairDetail = pairsList.current.find((value) => (value.tiker_root === val.pairName))
            // console.log(pairDetail, 'perpetualmarketprice', pairsList.current)
            val['close_price'] = val.buyorsell === 'buy' ? result.ask : result.bid
            let volume = quantitytovolume(val.positionFilled, pairDetail, val.price)
            let profit = await getPorfitCalculation(val['close_price'], val.price, volume, pairDetail, val.buyorsell, pairsList.current)
            val['profitnloss'] = profit
            val['pAndL'] = parseFloat(profit) - parseFloat(val.totalFee)
            val['closingFee'] = parseFloat(val.closingFee) + parseFloat(val.commission)
            let data = positionsorder.current
            data[val.index] = val
            // console.log(val, 'perpetualmarketprice', data)
            setPosition(data)
          }
        })
      }
    } catch (err) {
      console.log(err, 'ArrangethePositionorders__err')
    }
  }

  const getpairs = async () => {
    if (pairList.length > 0) {
      setPair(pairList)
    } else {
      const pairList = await getPairList();
      setPair(pairList.result)
    }
  }
  const postionDetails = async () => {
    try {
      if (props.type == "provider") {
        const data = { accountId: TrdaerDetail?.accountId, pageSize: 1 }
        const getData = await getPositionOrder(data);
        setPosition(getData.result)
        setPositionorder(getData.result)
        setpositionCount(getData.count)
        props.onSubmit(getData.count)
      }
      else if (props.type == "strategy") {
        const data = { accountId: TrdaerDetail?.accountId, pageSize: 1, user: TrdaerDetail?.userId }
        const getData = await getStrategyPositionOrder(data);
        setPosition(getData.result)
        setPositionorder(getData.result)
        setpositionCount(getData.count)
        props.onSubmit(getData.count)
      }
      else if (props.type == "copier") {
        const data = {
          traderAccountId: TrdaerDetail?.accountId,
          accountId: props.Followerdetails.followerAccountId,
          pageSize: 1,
          user: props.Followerdetails?.followerId
        }
        const getData = await getStrategyPositionOrder(data);
        console.log(getData, 'getStrategyPositionOrder')
        setPosition(getData.result)
        setPositionorder(getData.result)
        setpositionCount(getData.count)
        props.onSubmit(getData.count)
      }
    } catch (e) {
      console.log('postionDetails', e);
    }
  }

  const handlpositionfilter = async (search, direction) => {
    console.log("handlpositionfilterdata", search, direction);
    var data = {};
    setfillterposition(true)
    setPositionsearch(search);
    setAlldirection(direction);
    data.pageSize = 1
    data.pair = isEmpty(search) ? '' : search
    data.direction = isEmpty(direction) ? '' : direction
    if (props.type == "provider") {
      data.accountId = TrdaerDetail?.accountId
      let filter = await getPositionOrder(data);
      // console.log("filter_V_Vfilterfilter", filter);
      setPosition(filter?.result)
      setpositionCount(filter.count)
      props.onSubmit(filter.count)

    }
    else if (props.type == "strategy") {
      data.user = TrdaerDetail?.userId
      data.accountId = TrdaerDetail?.accountId
      let filter = await getStrategyPositionOrder(data);
      console.log("strategy_V_Vfilterfilter", filter);
      setPosition(filter?.result)
      setpositionCount(filter.count)
      props.onSubmit(filter.count)

    }
    else if (props.type == "copier") {
      data.traderAccountId = TrdaerDetail?.accountId
      data.accountId = props.Followerdetails.followerAccountId
      data.user = props.Followerdetails?.followerId
      const getData = await getStrategyPositionOrder(data);
      console.log(getData, 'getStrategyPositionOrder')
      setPosition(getData.result)
      setpositionCount(getData.count)
      props.onSubmit(getData?.count)
    }
  }

  const LoadMore = async () => {
    try {
      let pageSize = positionPage + 1
      Setpositionpage(pageSize)
      let getData = []
      if (props.type == "provider") {
        const data = { accountId: TrdaerDetail?.accountId, pageSize: pageSize }
        getData = await getPositionOrder(data);
      }
      else if (props.type == "strategy") {
        const data = { accountId: TrdaerDetail?.accountId, pageSize: pageSize, user: TrdaerDetail?.userId }
        getData = await getStrategyPositionOrder(data);
      }
      else if (props.type == "copier") {
        const data = {
          traderAccountId: TrdaerDetail?.accountId,
          accountId: props.Followerdetails.followerAccountId,
          pageSize: pageSize,
          user: props.Followerdetails?.followerId
        }
        getData = await getStrategyPositionOrder(data);
        console.log(getData, 'getStrategyPositionOrder')
      }
      if (position.length > 0 && pageSize > 1) {
        setPosition([...position, ...getData.result])
      } else {
        setPosition(getData.result)
      }
      setPositionorder(getData.result)
      setpositionCount(getData.count)
      props.onSubmit(getData.count)
    } catch (err) {
      console.log(err, 'LoadMore_err')
    }
  }
  return (

    <>
      <div className="d-flex gap-2 px-0">
        <InputGroup className="themeinput">
          <FormControl placeholder="" value={positionsearch}
            onChange={(e) => {
              Setpositionpage(1);
              handlpositionfilter(e?.target?.value);
            }} />
          <InputGroup.Append>
            <InputGroup.Text id="basic-addon2"><span className="fa fa-search"></span></InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
        <select className="selectcount selctsm themeselect w-full"
          onChange={(e) => {Setpositionpage(1);  handlpositionfilter(positionsearch, e.target.value, aggregated) }}
        >
          <option value="">All Direction (Default)</option>
          <option value="Buy">Buy</option>
          <option value="Sell">Sell</option>
        </select>
        {/* <select className="selectcount selctsm themeselect w-full">
          <option value="1">Not Aggregated (Default)</option>
          <option value="2">2 LTC</option>
          <option value="3">3 LTC</option>
      </select> */}
        <div className="d-flex align-items-center">
          {position && positionCount > position?.length && positionCount > 10 ?
            <button className="text-light btn m-0 py-1 px-3"
              onClick={() => {LoadMore()}}>
              Load More
            </button>
            : ""}
        </div>
      </div>

      <div className="darkbox h-table-big">
        <div className="table-responsive p-0">
          <table className="w-full">
            <tr>
              {/* <th></th> */}
              <th><label>S NO</label></th>
              <th><label>Created </label></th>
              <th><label>Symbol </label></th>
              <th><label>Quantity </label></th>
              <th><label>Direction </label></th>
              <th><label>Entry Price </label></th>
              <th><label>Market Price</label></th>
              <th><label>T/P</label></th>
              <th><label>S/L</label></th>
              <th><label>Gross P/L</label></th>
              <th><label>Swap</label></th>
              <th><label>Commission</label></th>
              <th><label>Net USD</label></th>
              {/* <th><label>Action</label></th> */}
            </tr>

            {
              position && position.length > 0 ? position?.map((val, index) => {
                let pairDetail = pair && pair?.find((value) => (value.tiker_root === val.pairName))
                return (
                  // val && <Positionorder
                  //   val={val}
                  //   pairDetail={pairDetail}
                  //   index={index}
                  // />
                  <tr>
                    {/* <td>
                      <td><button className="graybtn" onClick={() => { setShowpositionOrderInfo(true) }}><img src={infoimg} /></button></td>
                    </td> */}
                    <td>{index + 1}</td>
                    <td>{momentFormat(val.orderDate, 'DD-MM-YYYY HH:mm')}</td>
                    <td>{val.pairName}</td>
                    <td>{lotDisplay(val.positionFilled, pairDetail, val?.price).toFixed(2)}</td>
                    <td className={clsx({ "greenText": val.buyorsell === 'sell' }, { "redText": val.buyorsell == 'buy' })}>{val.buyorsell == 'buy' ? capitalize('sell') : capitalize('buy')}</td>
                    <td>{(val?.price == undefined || val?.price == null || isNaN(val?.price)) ? val?.price : (parseFloat(val?.price?.toFixed(pairDetail?.precision)))}</td>
                    <td>{val.close_price}</td>
                    <td>{(val?.takeProfitPrice == undefined || val?.takeProfitPrice == null || isNaN(val?.takeProfitPrice)) ? 0 : val?.takeProfitPrice == 0 ? (new Number(val?.takeProfitPrice).toPrecision(3)) : (parseFloat(val?.takeProfitPrice).toFixed(pairDetail.precision))}</td>
                    <td>{(val?.stopLossPrice == undefined || val?.stopLossPrice == null || isNaN(val?.stopLossPrice)) ? 0 : val?.stopLossPrice == 0 ? (new Number(val?.stopLossPrice).toPrecision(3)) : (parseFloat(val?.stopLossPrice).toFixed(pairDetail.precision))}</td>
                    <td className={clsx({ "greenText": val.profitnloss >= 0 }, { "redText": val.profitnloss < 0 })}>{(val.profitnloss == undefined || val.profitnloss == null || isNaN(val.profitnloss)) ? 0 : val.profitnloss === 0 ? (new Number(val.profitnloss).toPrecision(3)) : (parseFloat(val.profitnloss).toFixed(2))}</td>
                    <td className={clsx({ "greenText": val.swapFee >= 0 }, { "redText": val.swapFee < 0 })}>
                      {(val?.swapFee == undefined || val?.swapFee == null || isNaN(val?.swapFee)) ?
                        0
                        : val?.swapFee == 0 ?
                          (new Number(val?.swapFee).toPrecision(3)) :
                          (parseFloat(val?.swapFee).toFixed(2))
                      }
                    </td>
                    <td className={clsx({ "greenText": val.closingFee >= 0 }, { "redText": val.closingFee < 0 })}>
                      {(val?.closingFee == undefined || val?.closingFee == null || isNaN(val?.closingFee)) ?
                        0 :
                        val?.closingFee == 0 ?
                          (new Number(val?.closingFee).toPrecision(3)) :
                          (parseFloat(val?.closingFee).toFixed(2))}
                    </td>
                    <td className={clsx({ "greenText": val.pAndL >= 0 }, { "redText": val.pAndL < 0 })}>{(val.pAndL == undefined || val.pAndL == null || isNaN(val.pAndL)) ? 0 : val.pAndL === 0 ? (new Number(val.pAndL).toPrecision(3)) : (parseFloat(val.pAndL).toFixed(2))}</td>
                  </tr>
                  // <tr>
                  //   <td>{index + 1}</td>
                  //   <td>{momentFormat(val.orderDate, 'DD-MM-YYYY HH:mm')}</td>
                  //   <td>{val.pairName}</td>
                  //   <td>{lotDisplay(val.positionFilled, pairDetail, val?.price).toFixed(2)}</td>
                  //   <td className={clsx({ "greenText": val.buyorsell === 'sell' }, { "redText": val.buyorsell == 'buy' })}>{val.buyorsell == 'buy' ? capitalize('sell') : capitalize('buy')}</td>
                  //   <td>{(val?.price == undefined || val?.price == null || isNaN(val?.price)) ? val?.price : (parseFloat(val?.price?.toFixed(pairDetail?.precision)))}</td>
                  //   <td>{val.close_price}</td>
                  //   <td>{(val?.takeProfitPrice == undefined || val?.takeProfitPrice == null || isNaN(val?.takeProfitPrice)) ? 0 : val?.takeProfitPrice == 0 ? (new Number(val?.takeProfitPrice).toPrecision(3)) : (parseFloat(val?.takeProfitPrice).toFixed(pairDetail.precision))}</td>
                  //   <td>{(val?.stopLossPrice == undefined || val?.stopLossPrice == null || isNaN(val?.stopLossPrice)) ? 0 : val?.stopLossPrice == 0 ? (new Number(val?.stopLossPrice).toPrecision(3)) : (parseFloat(val?.stopLossPrice).toFixed(pairDetail.precision))}</td>
                  //   <td className={clsx({ "greenText": val.pAndL >= 0 }, { "redText": val.pAndL < 0 })}>{(val.pAndL == undefined || val.pAndL == null || isNaN(val.pAndL)) ? 0 : val.pAndL === 0 ? (new Number(val.pAndL).toPrecision(3)) : (parseFloat(val.pAndL).toFixed(2))}</td>
                  // </tr>
                )
              })
                :
                <tr><td colspan={15}><p className="text-center">There is no data</p></td></tr>
              // <tr>
              //   <td>{index + 1}</td>
              //   <td>{momentFormat(val.orderDate, 'DD-MM-YYYY HH:mm')}</td>
              //   <td>{val.pairName}</td>
              //   <td>{lotDisplay(val.positionFilled, pairDetail, val?.price).toFixed(2)}</td>
              //   <td className={clsx({ "greenText": val.buyorsell === 'sell' }, { "redText": val.buyorsell == 'buy' })}>{val.buyorsell == 'buy' ? capitalize('sell') : capitalize('buy')}</td>
              //   <td>{(val?.price == undefined || val?.price == null || isNaN(val?.price)) ? val?.price : (parseFloat(val?.price?.toFixed(pairDetail?.precision)))}</td>
              //   <td>{val.close_price}</td>
              //   <td>{(val?.takeProfitPrice == undefined || val?.takeProfitPrice == null || isNaN(val?.takeProfitPrice)) ? 0 : val?.takeProfitPrice == 0 ? (new Number(val?.takeProfitPrice).toPrecision(3)) : (parseFloat(val?.takeProfitPrice).toFixed(pairDetail.precision))}</td>
              //   <td>{(val?.stopLossPrice == undefined || val?.stopLossPrice == null || isNaN(val?.stopLossPrice)) ? 0 : val?.stopLossPrice == 0 ? (new Number(val?.stopLossPrice).toPrecision(3)) : (parseFloat(val?.stopLossPrice).toFixed(pairDetail.precision))}</td>
              //   <td className={clsx({ "greenText": val.pAndL >= 0 }, { "redText": val.pAndL < 0 })}>{(val.pAndL == undefined || val.pAndL == null || isNaN(val.pAndL)) ? 0 : val.pAndL === 0 ? (new Number(val.pAndL).toPrecision(3)) : (parseFloat(val.pAndL).toFixed(2))}</td>
              // </tr>
            }
          </table>
        </div>
      </div>
      <div className="statlist ">
        <div className="">
          <label>Balance:</label>
          <p>USD  {props.type == "provider" ?
           balance && parseFloat(balance).toFixed(2) :
            props.type == "strategy" ?
            parseFloat(balance).toFixed(2) :
              props.type == "copier" ?
                parseFloat(props?.Followerdetails?.balanceInvestment).toFixed(2)
                :
                0}
          </p>
        </div>
        <div className="">
          <label>Equity:</label>
          <p>USD {Equtitypre ? toLocaleString(parseFloat(Equtitypre.toFixed(2))) : 0}</p>
        </div>
        <div className="">
          <label>Margin:</label>
          <p>USD {Margin ? toLocaleString(parseFloat(Margin.toFixed(2))) : 0}</p>
        </div>
        <div className="">
          <label>Free Margin:</label>
          <p>USD {freemagins ? toLocaleString(parseFloat(freemagins.toFixed(2))) : 0}</p>
        </div>
        <div className="">
          <label>Margin Level:</label>
          <p>USD {marginlevels ? toLocaleString(parseFloat(marginlevels.toFixed(2))) : 0} %</p>
        </div>
        <div className="">
          <label>Unr. Net P&L:</label>
          <p className={clsx({ "greenText": profitandloss >= 0 }, { "redText": profitandloss < 0 })}>{profitandloss ? toLocaleString(parseFloat(profitandloss.toFixed(2))) : 0}</p>
        </div>
      </div>
    </>

  )
}

export default Position