// import config
import axios , {handleResp} from '../config/axios'

// import constant
import {
    SET_USER_WALLET_LIST,
    SET_USER_FIRST_CURRENCY,
    SET_USER_SECOND_CURRENCY,
    UPDATE_USER_WALLET_STAKE,
    HIDE_ZERO
} from '../constant';

export const getAssetData = async (dispatch,data) => {
    try {
        // console.log("dispatch",dispatch);
        // console.log("dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",data);
        console.log('typelist',data.type)
        let respData = await axios({
            'method': 'get',
            'url': `/api/getAssetsDetails`,
           params:data
           
        });

        console.log("respDatarespDatarespDatarespData",respData.data)
        if(data.type=="LIVE" ){
            console.log('enter thos')
        dispatch(userWalletList(respData.data.result))
        // console.log("respData.data.result------>",respData.data.result);

        }
        else if(data.type=="DEMO" ){
            console.log('datasheloooooooooooooo')
            dispatch(userWalletList(respData.data.result.assets))
            console.log("respData.data.result.assets--->",respData.data.result.assets);
        }
        else{
            console.log('enter thos2')
            dispatch(userWalletList(respData.data.result))
            console.log("respData.data.result->",respData.data);

        }
        return true
    }
    catch (err) {
        handleResp(err, 'error')
        return false
    }
}

// export const getAssetDataDemo = async (dispatch) => {
//     try {
//         let respData = await axios({
//             'method': 'get',
//             'url': `/api/getAssetsDetailsDemo`,
//         });

//         console.log("reessssssssssssssssssssssss",respData.data)
//         dispatch(userWalletList(respData.data.result))
       
//         return true
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return false
//     }
// }

export const gethideZeroStatus=async()=>{

    try{
        let respData = await axios({
            'method': 'get',
            'url': `/api/getHideoZeroStatus`,
        });

        return {
            result:respData.data.hideZeroStatus
        }
    }catch(err){
        handleResp(err, 'error')
        return false

    }
}

export const updateHideZeroStatus=async(data)=>{

    try{
        let respData = await axios({
            'method': 'put',
            'url': `/api/getHideoZeroStatus`,
            data:data
        });
        return {
            status:true,
            message:respData.data.message
        }
    }catch(err){
        handleResp(err, 'error')
        return false

    }
}
export const checkDeposit = async (dispatch) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/check-deposit`,
        });
        return true
    }
    catch (err) {
        handleResp(err, 'error')
        return false
    }
}

export const updateWallet = async (dispatch, data, callFrom) => {
    if (callFrom == 'stake') {
        dispatch({
            type: UPDATE_USER_WALLET_STAKE,
            data
        })
    }
    return false

}

export const userWalletList = (data) => {
    return {
        type: SET_USER_WALLET_LIST,
        data
    }
}

export const withdrawRequestFiat = async (data) => {
    console.log('datasdfsd-->',data)
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/fiatWithdraw`,
            'data': data
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, ' error hihi')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const fiatRequestVerify = async (data) => {
    try {
        let respData = await axios({
            'method': 'patch',
            'url': `/api/fiatWithdraw`,
            'data': data
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
        }
    }
}

export const withdrawRequestCoin = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/coinWithdraw`,
            'data': data
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}


export const withdrawCoindemo = async (data) =>
{
     try 
     {
        let respData = await axios({
            'method' : "POST",
            'url':`/api/coinWithdrawdemo`,
            'data':data
        });
        return{
            status :'success',
            loading : false,
            message : respData.data.messsage,
            result : respData.data.result
        }
     }
     catch(err)
     {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
     }
}

export const coinRequestVerify = async (data) => {
    try {
        let respData = await axios({
            'method': 'patch',
            'url': `/api/coinWithdraw`,
            'data': data
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
        }
    }
}

export const coinWithdrawApprove = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/WithdrawApprove`,
            'data': data
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
        }
    }
}

export const coinWithdrawCancel = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/WithdrawCancel`,
            'data': data
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
        }
    }
}

export const fiatDepositRequest = async (data) => {
    console.log('kduycfdasgfc')
    console.log('undeasfdjaskld-----------',data)
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/fiatDeposit`,
            'data': data
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}


export const demoFiatDeposit = async (data) => {
    console.log('kduycfdasgfc')
    console.log('undeasfdjaskld',data)
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/demoFiatDeposit`,
            'data': data
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}
export const demoWithdrawFiat = async(data)=>
{
    console.log("data_data_data",data);
    try 
    {
      let respData = await axios({
        'method':'POST',
        'url':`/api/demoWithdrawFiat`,
        'data' : data
      })
      return {
        status: "success",
        loading: false,
        message: respData.data.message,
        result: respData.data.result
    }
    }
    
    catch(err)
    {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const walletTransfer = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/walletTransfer`,
            'data': data
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const fundTransfer = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/fundTransfer`,
            'data': data
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const getAssetByCurrency = async (currencyId) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/getAsset/` + currencyId
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
        }
    }
}

export const setUserFirstCurrency = (assetData, dispatch) => {
    dispatch({
        type: SET_USER_FIRST_CURRENCY,
        data: assetData
    })
}

export const setUserSecondCurrency = (assetData, dispatch) => {
    dispatch({
        type: SET_USER_SECOND_CURRENCY,
        data: assetData
    })
}

export const getTrnxHistory = async (params, query) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/history/transaction/` + params,
            'params': query
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
        }
    }
}