import React, { useState } from 'react';
import { Modal, Tabs, Dropdown, InputGroup, Form, Tab } from 'react-bootstrap'

const ViewInvestor = (props) => {
    const [viewinvestor, setViewInvestor] = useState(true)
    return (
        <>
            <Modal show={viewinvestor} className="modalbg font-12" size="md" centered scrolable>

                <Modal.Header className='align-items-center'>
                    <Modal.Title>Investor Link - TradingOne</Modal.Title>
                    <button className='closebtn btn btn-link text-light nounder' onClick={() => props.onDismiss()}>
                        <i className="fa fa-times fa-6" aria-hidden="true"></i>

                    </button>
                </Modal.Header>
                <Modal.Body className='font-12'>
                    <div className='themetab'>

                        <h5>Investor Link</h5>
                        <hr />



                        <p className='my-2'>Investor Link provides other users with a read-only access to your Account Profile. The users will be able to see your trading statistics, current open positions, and history.</p>
                        <div>

                        </div>
                        <div className='d-flex align-items-center pb-3'>
                            <input className='form-control themeinput mr-2' type="text" readonly value="https://tradingone.com/profiles/B5uI70Kasw" />
                            <button className='btn green-btn'>Copy Link</button>

                        </div>







                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ViewInvestor