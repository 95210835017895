import React, { Component, useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap'
import Chart from "react-apexcharts";
import { useSelector, useDispatch } from "react-redux";
import { getproviderSummary } from 'actions/copyTradeAction';
import isEmpty from 'lib/isEmpty';



const Summary = (props) => {
  const {summaryDetails,TrdaerDetail} = props
  const dispatch = useDispatch()
  const {useraccount} = useSelector(state => state.account)
  // const copy = useSelector(state => state.copy)
  const [providersummary,setProviderSummary] = useState()
  // console.log("Summary_provider",props)

  useEffect(()=> {
    setProviderSummary(props.summaryDetails)
  },[TrdaerDetail,summaryDetails])

  // const getproviderSummaryDetails = async()=> {
  //   const getData = await getproviderSummary({accountId:props?.TrdaerDetail?.accountId , userId: props?.TrdaerDetail?.userId},dispatch);
  //   setProviderSummary(getData.result)
  // }
  return (
    <div className="tab-content">
      {console.log('details_summary',providersummary)}
      <p className="boxheadings mb-1">Summary</p>
      <div className="darkbox p-3">
        <ul className="pl-0 symbolTab_list">

          <li>
            <div className="d-flex p-0 justify-content-between">
              <small className="text-light">Profit Factor</small>
              <small className="text-white">{providersummary &&  providersummary.ClosedPosition.lenght > 0 ? TrdaerDetail?.currency +" "+ providersummary?.ClosedPosition[0]?.netProfit?.toFixed(3): 0}</small>
            </div>
          </li>
          <li>
            <div className="d-flex p-0 justify-content-between">
              <small className="text-light">
              Net Profit
              </small>
              <small className="text-white">{providersummary &&  providersummary.ClosedPosition.lenght > 0 ? TrdaerDetail?.currency +" "+ providersummary?.ClosedPosition[0]?.profit?.toFixed(3): 0}</small>
            </div>
          </li>

          {/* <li>
            <div className="d-flex p-0 justify-content-between">
              <small className="text-light">
                Percent Profitable
              </small>
              <small className="text-white">77.02%</small>
            </div>
          </li> */}
          {/* <li>
            <div className="d-flex p-0 justify-content-between">
              <small className="text-light">
                Max Balance Drawdown
              </small>
              <small className="text-white">6.63%</small>
            </div>
          </li> */}
          <li>
            <div className="d-flex p-0 justify-content-between">
              <small className="text-light">Starting Balance</small>
              <small className="text-white">
               {providersummary ?  providersummary && TrdaerDetail?.currency +" "+  providersummary?.startingBalance?.toFixed(3) : 0}
              </small>
            </div>
          </li>
          <li>
            <div className="d-flex p-0 justify-content-between">
              <small className="text-light">
                Current Balance
              </small>
              <small className="text-white">{ providersummary && providersummary ? TrdaerDetail?.currency +" "+ useraccount?.balance.toFixed(3) : 0}</small>
            </div>
          </li>
          <li>
            <div className="d-flex p-0 justify-content-between">
              <small className="text-light">
                Equity
              </small>
              <small className="text-white">{providersummary &&  providersummary.ClosedPosition.lenght > 0 ? TrdaerDetail?.currency +" "+ providersummary?.ClosedPosition[0]?.profit?.toFixed(3): 0}</small>
            </div>
          </li>
          <li>
            <div className="d-flex p-0 justify-content-between">
              <small className="text-light">
                Deposits
              </small>
              <small className="text-white">{providersummary && providersummary.deposit ?  TrdaerDetail?.currency +" "+ providersummary?.deposit?.toFixed(3): 0}</small>
            </div>
          </li>
          <li>
            <div className="d-flex p-0 justify-content-between">
              <small className="text-light">
                Withdrawals
              </small>
              <small className="text-white">{providersummary && providersummary.withdraw ? TrdaerDetail?.currency +" "+ providersummary?.withdraw?.toFixed(3): 0}</small>
            </div>
          </li>
          <li>
            <div className="d-flex p-0 justify-content-between">
              <small className="text-light">
                Margin Used
              </small>
              <small className="text-white">{providersummary && providersummary.withdraw ? TrdaerDetail?.currency +" "+ providersummary?.withdraw?.toFixed(3): 0}</small>
            </div>
          </li>
          <li>
            <div className="d-flex p-0 justify-content-between">
              <small className="text-light">
                Active since
              </small>
              <small className="text-white">{TrdaerDetail && !isEmpty(TrdaerDetail) ? new Date(TrdaerDetail?.createdDate).toDateString(): ""}</small>
            </div>
          </li>



        </ul>
      </div>

    </div>
  )
}

export default Summary