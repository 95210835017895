import React, { useEffect, useState, useContext, useRef } from 'react';
import { Modal, Tabs, Dropdown, InputGroup, Form, Tab } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux';
import SocketContext from '../../../Context/SocketContext';
import Wishlisttab from '../elements/Wishlisttab';
import Symboltab from "../elements/Symboltab";
import Infomation from './Information';
import { encryptObject } from '../../../../lib/cryptoJS';
import { orderPlace } from '../../../../actions/perpetualTradeAction';
import { toastAlert } from '../../../../lib/toastAlert';
import isEmpty from "lib/isEmpty";
import { capitalize } from '../../../../lib/stringCase';
import { inverseOrderCost, orderValue, EstMargin, SpreadCal, BidandAskDisplay, volumetoquantity, priceConversion } from '../../../../lib/bybit';
import { useHistory } from 'react-router-dom';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from 'moment';

import validation from "../../validation";
const initialFormValue = {
  'price': '',
  'quantity': '0',
  'total': '',
  'leverage': '1:100',
  'isProfitLoss': false,
  'typeTIF': 'GTC',
  'pairname': '',
  'Expriydate': '',
  'comment': '',
  'takeprofit': 0,
  'stopprice': 0,
  'Limit_range_price': 0,
  'Limit_range_pips': 0,
  'orderType': 'market',
  'buyorsell': 'buy'
}
const NewOrder = (props) => {
  const socketContext = useContext(SocketContext)
  const [neworder, setNewOrder] = useState(true)
  const [formValue, setFormValue] = useState(initialFormValue);
  const [showdrop, setShowDrop] = useState(false)
  const [Markupprice, setMarkupprice] = useState()
  // const [orderType, setOrderType] = useState('market')
  const [info, setInfo] = useState(false)
  const [symbolTab, setSymbolTab] = useState(false)
  const [Distance, setDistance] = useState(0)
  const [stopopen, setStopopen] = useState(false);
  const [takeopen, setTakeopen] = useState(false);
  // const [buyorsell, Setbuyorsell] = useState('buy')
  const [trigger_type, settriggerType] = useState()
  const [triggerordertype, settriggerordertype] = useState()
  const [trigger_price, Settrigger_price] = useState()
  const [buyLoader, setBuyLoader] = useState(false)
  const [results, setResults] = useState([]);
  const [sellLoader, setSellLoader] = useState(false)
  const [limitloader, setLimitLoader] = useState(false)
  const [stoploader, setstoploader] = useState(false)
  const [stoplimitloader, setstoplimitloader] = useState(false)
  const [marketloader, setMarketLoader] = useState(false)
  const [Est_Margin, setEmargin] = useState(0)
  const [startDate, setStartDate] = useState(new Date());
  const [isExpriy, setExpriy] = useState(false)
  const [LimitDisable, SetLimitDisabel] = useState(false)
  const [StopDisable, SetStopDisable] = useState(false)
  const [Limit_Range, setLimitRange] = useState(0)
  const [contract, setContract] = useState(1000)
  const [errors, setError] = useState({})
  const [volume, setVolume] = useState(0)
  const [tpPip, settpPip] = useState(0)
  const [spPip, setspPip] = useState(0)
  const [tpUsd, settpUsd] = useState(0)
  const [spUsd, setspUsd] = useState(0)
  const [pipValue, setpipValue] = useState(0)
  const [isTEdit, setTEdit] = useState(false)
  const [isSEdit, setSEdit] = useState(false)
  const kycStatus = useSelector(state => state.userKyc.Status);
  const { verifiedstatus, Synapsverify } = kycStatus
  //const { price, quantity, leverage, isProfitLoss, typeTIF, pairname, Epriydate, comment, takeprofit, stopprice } = formValue;
  const pairData = useSelector(state => state.tradePair)
  const pairList = useSelector(state => state.pairList);
  const pairsData = useRef(pairData)
  const formsValue = useRef(formValue)
  const SpPip = useRef(spPip)
  const TpPip = useRef(tpPip)
  const Stopopen = useRef(stopopen)
  const Takeopen = useRef(takeopen)
  const TpUsd = useRef(tpUsd)
  const SpUsd = useRef(spUsd)
  const { price, quantity, buyorsell, leverage, isProfitLoss, typeTIF, pairname, Epriydate, comment, takeprofit, stopprice, orderType, Limit_range_pips, Limit_range_price } = formValue;
  useEffect(() => {
    // let pairName = window.location.pathname.split("/")[2];
    let pairName = localStorage.getItem('curpair')
    if (pairsData.current.tiker_root == pairName && (isEmpty(pairData) || pairData == undefined || pairData == null)) {
      pairsData.current = pairsData.current
    } else if (pairsData.current.tiker_root == pairData.tiker_root) {
      pairsData.current = pairData
    }
  }, [pairData])

  useEffect(() => {
    formsValue.current = formValue
  }, [formValue])

  useEffect(() => {
    Stopopen.current = stopopen
    Takeopen.current = takeopen
    SpPip.current = spPip
    TpPip.current = tpPip
    TpUsd.current = tpUsd
    SpUsd.current = spUsd
  }, [stopopen, takeopen, spPip, tpPip, tpUsd, spUsd])
  const { useraccount } = useSelector(state => state.account)
  const { isAuth } = useSelector(state => state.auth);
  // console.log(pairData, "pai")

  // const setorder = (e) => {
  //   console.log(e, "orderType1")
  //   setOrderType(e)
  // }


  const percentagePnL = (e) => {
    try {
      console.log("percentagePnL")
      var type = e.target.id
      var percent = e.target.value;
      if (type == "takeprofit" && takeopen) {
        console.log("this.state.buyorsell", takeopen)
        if (buyorsell == 'buy') {
          //this.setState({profitpercentage:percent})
          let profit = parseFloat(Markupprice.ask) * (parseFloat(percent) / 100);
          let takeprofit = parseFloat(Markupprice.ask) + parseFloat(profit);
          // console.log("percentagePnL", takeprofit)
          // setTakeprofit(takeprofit)
        }
        else {
          //this.setState({profitpercentage:percent})
          let profit = parseFloat(Markupprice.bid) * (parseFloat(percent) / 100);
          let takeprofit = parseFloat(Markupprice.bid) - parseFloat(profit);
          // console.log("percentagePnL", takeprofit)
          // setTakeprofit(takeprofit)
        }
      }
      else if (type == "stopprice" && stopopen) {
        if (buyorsell == 'buy') {
          //this.setState({stoplosspercentage : percent})
          let loss = parseFloat(Markupprice.ask) * (parseFloat(percent) / 100);
          let stopprice = parseFloat(Markupprice.ask) - parseFloat(loss);
          console.log("percentagePnL", stopprice)
          // setstopprice(stopprice)
        }
        else {
          //this.setState({stoplosspercentage : percent})
          let loss = parseFloat(Markupprice.bid) * (parseFloat(percent) / 100);
          let stopprice = parseFloat(Markupprice.bid) + parseFloat(loss);
          console.log("percentagePnL", stopprice)
          // setstopprice(stopprice)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
  let history = useHistory();
  const handleClick = () => {
    history.push('/login');
  }

  const handleChange = async (e) => {
    try {
      e.preventDefault();
      const { name, value } = e.target;
      let regex = /[ A-Za-z`!@#$%^&*()_+=\[\]{};':"\\|,<>\/?~]/
      // let regex = /^[0-9]+$/  ^-?\d+(?:\.\d+)?$
      // console.log(!['typeTIF', 'leverage', 'tpPip', 'spPip', 'tpUsd', 'spUsd','Limit_Range'].includes(name) && !/^\d*\.?\d*$/.test(value),'handleChange-check')
      // if (!['typeTIF', 'leverage', 'tpPip', 'spPip', 'tpUsd', 'spUsd','Limit_Range'].includes(name) && !/^\d*\.?\d*$/.test(value)) {
      //   return
      // }
      console.log(!['typeTIF', 'leverage'].includes(name) && regex.test(value), value?.split('.')?.length > 2, value?.split('-')?.length > 2, (value?.split('-')?.length >= 2 && !isEmpty(value?.split('-')[0])), 'handleChange-check')
      if (!['typeTIF', 'leverage'].includes(name) && regex.test(value) || value?.split('.')?.length > 2 || value?.split('-')?.length > 2 || (value?.split('-')?.length >= 2 && !isEmpty(value?.split('-')[0]))) {
        return
      }
      if (name === 'tpPip') {
        let interval
        if (pairData.secondCurrencySymbol == 'JPY') {
          interval = (1 / Math.pow(10, 5)) * Math.pow(10, 4)
        } else {
          interval = (1 / Math.pow(10, pairData.precision)) * Math.pow(10, pairData.pip_position)
        }
        // let tppip = parseFloat(value).toFixed(2)
        // tppip = (isNaN(tppip) || tppip==undefined || tppip ==null) ? 0 : tppip

        // else if(precision == 0){
        //   setError({ ...errors, ...{ [name]: '' } })
        // }
        let tpusd = (parseFloat(value) * pipValue).toFixed(2)
        tpusd = (isNaN(tpusd) || tpusd == undefined || tpusd == null) ? 0 : tpusd
        let takeprofit = (parseFloat(price) + (parseFloat(value) / Math.pow(10, pairData.pip_position))).toFixed(pairData.precision)
        settpPip(value)
        settpUsd(parseFloat(tpusd))
        let precision = (Math.abs(value) * Math.pow(10, pairData.pip_position == 0 ? 4 : pairData.pip_position)) % (interval * Math.pow(10, pairData.pip_position == 0 ? 4 : pairData.pip_position))
        console.log(precision, 'handleChange-tpPip')
        if (precision != 0) {
          setError({ ...errors, ...{ [name]: `Invalid precision : correct precision is equal to ${interval}` } })
          return false
        }
        calculateTpandSp(parseFloat(takeprofit), 'takeprofit')
        return true
      }
      if (name == 'tpUsd') {
        let pipInterval
        if (pairData.secondCurrencySymbol == 'JPY') {
          pipInterval = (1 / Math.pow(10, 5)) * Math.pow(10, 4)
        } else {
          pipInterval = (1 / Math.pow(10, pairData.precision)) * Math.pow(10, pairData.pip_position)
        }
        let toFixedlength = pipInterval.toString().split('.')[1] ? pipInterval.toString().split('.')[1].length : 0
        // else{
        //   setError({ ...errors, ...{ [name]: `` } })
        // }
        settpUsd(value)
        let tppip = (parseFloat(value) / pipValue).toFixed(2)
        let takeprofit = (parseFloat(price) + (parseFloat(tppip) / Math.pow(10, pairData.pip_position))).toFixed(pairData.precision)
        tppip = (isNaN(tppip) || tppip == undefined || tppip == null) ? 0 : parseFloat(tppip).toFixed(toFixedlength)
        settpPip(parseFloat(tppip))
        console.log('calculateTpandSp', takeprofit)
        let precision = (Math.abs(value) * Math.pow(10, pairData.pip_position == 0 ? 4 : pairData.pip_position)) % (0.01 * Math.pow(10, pairData.pip_position == 0 ? 4 : pairData.pip_position))
        if (precision != 0) {
          setError({ ...errors, ...{ [name]: `Invalid precision : correct precision is equal to ${0.01}` } })
          return false
        }
        calculateTpandSp(parseFloat(takeprofit), 'takeprofit')
        return true
      }
      if (name === 'spPip') {
        let interval
        if (pairData.secondCurrencySymbol == 'JPY') {
          interval = (1 / Math.pow(10, 5)) * Math.pow(10, 4)
        } else {
          interval = (1 / Math.pow(10, pairData.precision)) * Math.pow(10, pairData.pip_position)
        }
        let precision = (Math.abs(value) * Math.pow(10, pairData.pip_position == 0 ? 4 : pairData.pip_position)) % (interval * Math.pow(10, pairData.pip_position == 0 ? 4 : pairData.pip_position))


        // else if(precision == 0){
        //   let errorData = { ...errors, ...{ 'spPip': '' } }
        //   console.log(precision,'precision1',precision == 0,errorData)
        //   setError(errorData)
        // }
        let spusd = (parseFloat(value) * pipValue).toFixed(2)
        spusd = (isNaN(spusd) || spusd == undefined || spusd == null) ? 0 : spusd
        console.log(name, parseFloat(value) * pipValue, name === 'tpPip', 'handleChange', spusd)
        let stopprice = (parseFloat(price) + (parseFloat(value) / Math.pow(10, pairData.pip_position))).toFixed(pairData.precision)
        setspPip(value)
        setspUsd(parseFloat(spusd))
        if (precision !== 0) {
          console.log(precision, 'precision', precision !== 0)
          setError({ ...errors, ...{ [name]: `Invalid precision : correct precision is equal to ${interval}` } })
          return false
        }
        calculateTpandSp(parseFloat(stopprice), 'stopprice')
        return true
      }
      if (name == 'spUsd') {
        let pipInterval
        if (pairData.secondCurrencySymbol == 'JPY') {
          pipInterval = (1 / Math.pow(10, 5)) * Math.pow(10, 4)
        } else {
          pipInterval = (1 / Math.pow(10, pairData.precision)) * Math.pow(10, pairData.pip_position)
        }
        let toFixedlength = pipInterval.toString().split('.')[1] ? pipInterval.toString().split('.')[1].length : 0
        setspUsd(value)
        let sppip = (parseFloat(value) / pipValue).toFixed(2)
        let stopprice = (parseFloat(price) + (parseFloat(sppip) / Math.pow(10, pairData.pip_position))).toFixed(pairData.precision)
        sppip = (isNaN(sppip) || sppip == undefined || sppip == null) ? 0 : parseFloat(sppip).toFixed(toFixedlength)
        setspPip(parseFloat(sppip))
        let precision = (Math.abs(value) * Math.pow(10, pairData.pip_position == 0 ? 4 : pairData.pip_position)) % (0.01 * Math.pow(10, pairData.pip_position == 0 ? 4 : pairData.pip_position))
        if (precision != 0) {
          setError({ ...errors, ...{ [name]: `Invalid precision : correct precision is equal to ${0.01}` } })
          return false
        }
        console.log(name, value, stopprice, sppip, 'handleChange')
        calculateTpandSp(parseFloat(stopprice), 'stopprice')
        return true
      }
      if (name === 'takeprofit') {
        // setTEdit(true)
        let val = value
        // if(isNaN(value)||value==''||value==undefined){
        //   val =0
        //   let formData = { ...formValue, ...{ 'takeprofit': val } }
        //   setFormValue(formData)
        //   console.log(formData, 'value,type')
        //   setError({ ...errors, ...{ 'takeprofit': `The value must be between 0 to ${price}`} })
        // }else{
        let pipInterval
        if (pairData.secondCurrencySymbol == 'JPY') {
          pipInterval = (1 / Math.pow(10, 5)) * Math.pow(10, 4)
        } else {
          pipInterval = (1 / Math.pow(10, pairData.precision)) * Math.pow(10, pairData.pip_position)
        }
        let toFixedlength = pipInterval.toString().split('.')[1] ? pipInterval.toString().split('.')[1].length : 0
        let pip = ((value - price) * Math.pow(10, pairData.pip_position))
        pip = (isNaN(pip) || pip == undefined || pip == null) ? 0 : parseFloat(pip).toFixed(toFixedlength)
        let usd = pip * pipValue
        usd = (isNaN(usd) || usd == undefined || usd == null) ? 0 : parseFloat(usd).toFixed(2)
        settpPip(parseFloat(pip))
        settpUsd(parseFloat(usd))
        return calculateTpandSp(val, name)
        // }
      }
      if (name === 'stopprice') {
        // setSEdit(true)
        let val = value
        // if(isNaN(value)||value==''||value==undefined){
        //   val =0
        //   let formData = { ...formValue, ...{ 'stopprice': val } }
        //   setFormValue(formData)
        //   console.log(formData, 'value,type')
        //   setError({ ...errors, ...{ 'stopprice': `The value must be between 0 to ${price}`} })
        // }else{
        let pipInterval
        if (pairData.secondCurrencySymbol == 'JPY') {
          pipInterval = (1 / Math.pow(10, 5)) * Math.pow(10, 4)
        } else {
          pipInterval = (1 / Math.pow(10, pairData.precision)) * Math.pow(10, pairData.pip_position)
        }
        let toFixedlength = pipInterval.toString().split('.')[1] ? pipInterval.toString().split('.')[1].length : 0
        let pip = ((value - price) * Math.pow(10, pairData.pip_position))
        pip = (isNaN(pip) || pip == undefined || pip == null) ? 0 : parseFloat(pip).toFixed(toFixedlength)
        let usd = pip * pipValue
        usd = (isNaN(usd) || usd == undefined || usd == null) ? 0 : parseFloat(usd).toFixed(2)
        setspPip(pip)
        setspUsd(parseFloat(usd))
        return calculateTpandSp(val, name)
        // }
        // return calculateTpandSp(val, name)
      }
      if (name == 'Limit_Range') {
        let interval
        if (pairData.secondCurrencySymbol == 'JPY') {
          interval = (1 / Math.pow(10, 5)) * Math.pow(10, 4)
        } else {
          interval = (1 / Math.pow(10, pairData.precision)) * Math.pow(10, pairData.pip_position)
        }
        let precision = (Math.abs(value) * Math.pow(10, pairData.pip_position)) % (interval * Math.pow(10, pairData.pip_position))
        let Limit_range_price
        if (buyorsell == 'buy') {
          Limit_range_price = (price + (value / Math.pow(10, pairData.pip_position)))
          Limit_range_price = parseFloat(Limit_range_price).toFixed(pairData.precision)
        } else {
          Limit_range_price = (price - (value / Math.pow(10, pairData.pip_position)))
          Limit_range_price = parseFloat(Limit_range_price).toFixed(pairData.precision)
        }

        if (precision !== 0) {
          console.log(precision, 'precision', precision !== 0)
          setError({ ...errors, ...{ ['Limit_Range']: `Invalid precision : correct precision is equal to ${interval}` } })
          let formData = { ...formValue, ...{ 'Limit_range_pips': value, 'Limit_range_price': parseFloat(Limit_range_price) } }
          setFormValue(formData)
          return false
        } else {

          setError({ ...errors, ...{ ['Limit_Range']: `` } })
          let formData = { ...formValue, ...{ 'Limit_range_pips': value, 'Limit_range_price': parseFloat(Limit_range_price) } }
          console.log(formData, 'Limit_Range', Limit_range_price)
          setFormValue(formData)
          return true
        }
        // let formData = { ...formValue, ...{ 'Limit_range_pips': value } }
        // setFormValue(formData)
        // return true
      }
      if (name == 'price') {
        let interval = (1 / Math.pow(10, pairData.precision)).toString().split('.')[1] ? (1 / Math.pow(10, pairData.precision)).toString().split('.')[1] : 0
        let precision = value.split('.')[1] ? value.split('.')[1] : 0
        console.log(precision.length, interval.length, 'Invalid precision')
        if (precision?.length > interval?.length) {
          setError({ ...errors, [name]: `Invalid precision ${interval}` })
        } else {
          setError({ ...errors, [name]: `` })
        }
        PriceValidation(value, Markupprice)
        let formData = { ...formValue, ...{ [name]: value } }
        setFormValue(formData)
        let valPip = value * Math.pow(10, pairData.pip_position)
        var buyprice = !isEmpty(Markupprice) && Markupprice?.ask ? Markupprice.ask * Math.pow(10, pairData.pip_position) : pairData?.ask * Math.pow(10, pairData.pip_position)
        var sellprice = !isEmpty(Markupprice) && Markupprice?.bid ? Markupprice.bid * Math.pow(10, pairData.pip_position) : pairData?.bid * Math.pow(10, pairData.pip_position)
        let price = buyorsell == 'buy' ? buyprice : sellprice
        localStorage.setItem('price', value)
        let Distance = Math.abs(parseFloat(price) - parseFloat(valPip)).toFixed(1)
        setDistance(isNaN(Distance) ? 0 : Distance)
        return true
      }
      if (name == 'quantity') {
        let precision = (Math.abs(value) * Math.pow(10, pairData.pip_position == 0 ? 4 : pairData.pip_position)) % (pairData.minQuantity * Math.pow(10, pairData.pip_position == 0 ? 4 : pairData.pip_position))
        if (precision != 0) {
          setError({ ...errors, ...{ [name]: `Invalid precision : correct precision is equal to ${pairData.minQuantity}` } })
          setVolume(value)
          return false
        }
        if (value < pairData.minQuantity || isNaN(value)) {
          console.log(value < pairData.minQuantity, 'value < pairData.minQuantity')
          setError({ ...errors, ...{ [name]: `Volume shouldn't be less than ${pairData.minQuantity}` } })
          setVolume(value)
          return false
        } else if (value > pairData.maxQuantity || isNaN(value)) {
          console.log(value > pairData.maxQuantity, 'value > pairData.maxQuantity')
          setError({ ...errors, ...{ [name]: `Volume shouldn't be greater than ${pairData.maxQuantity}` } })
          setVolume(value)
          return false
        }
        else {
          setVolume(value)
          await calculateQuantity(value)
          return true
        }
      }
      // let formData = { ...formValue, ...{ [name]: value } }

      // setFormValue(formData)
      // calculateOrderCost(formData.price, formData.quantity, formData.leverage, formData)
      setError({ ...errors, ...{ [name]: "" } })

    } catch (err) {
      console.log(err, "Error-handleChnage")
    }
  }
  const increaseTpPrice = (type) => {
    try {
      let value
      if (type === 'stopprice') {
        value = parseFloat(stopprice) + parseFloat(1 / Math.pow(10, pairData.precision))

        let pipInterval
        if (pairData.secondCurrencySymbol == 'JPY') {
          pipInterval = (1 / Math.pow(10, 5)) * Math.pow(10, 4)
        } else {
          pipInterval = (1 / Math.pow(10, pairData.precision)) * Math.pow(10, pairData.pip_position)
        }
        let toFixedlength = pipInterval.toString().split('.')[1] ? pipInterval.toString().split('.')[1].length : 0
        let pip = ((value - price) * Math.pow(10, pairData.pip_position))
        pip = (isNaN(pip) || pip == undefined || pip == null) ? 0 : parseFloat(pip).toFixed(toFixedlength)
        let usd = pip * pipValue
        usd = (isNaN(usd) || usd == undefined || usd == null) ? 0 : parseFloat(usd).toFixed(2)
        setspPip(pip)
        setspUsd(parseFloat(usd))

        if (buyorsell == 'buy' && value > price) {
          setError({ ...errors, ...{ 'stopprice': 'Stop Loss price must not greater than price' } })
        } else {
          setError({ ...errors, ...{ 'stopprice': '' } })
        }
        setFormValue({ ...formValue, ...{ 'stopprice': isNaN(value) ? price : value.toFixed(pairData.precision) } })
      } else if (type === 'takeprofit') {
        value = parseFloat(takeprofit) + parseFloat(1 / Math.pow(10, pairData.precision))

        let pipInterval
        if (pairData.secondCurrencySymbol == 'JPY') {
          pipInterval = (1 / Math.pow(10, 5)) * Math.pow(10, 4)
        } else {
          pipInterval = (1 / Math.pow(10, pairData.precision)) * Math.pow(10, pairData.pip_position)
        }
        let toFixedlength = pipInterval.toString().split('.')[1] ? pipInterval.toString().split('.')[1].length : 0
        let pip = ((value - price) * Math.pow(10, pairData.pip_position))
        pip = (isNaN(pip) || pip == undefined || pip == null) ? 0 : parseFloat(pip).toFixed(toFixedlength)
        let usd = pip * pipValue
        usd = (isNaN(usd) || usd == undefined || usd == null) ? 0 : parseFloat(usd).toFixed(2)
        settpPip(parseFloat(pip))
        settpUsd(parseFloat(usd))

        if (buyorsell == 'sell' && value > price) {
          setError({ ...errors, ...{ 'takeprofit': 'Take profit price must not be greater than price' } })
        } else {
          setError({ ...errors, ...{ 'takeprofit': '' } })
        }
        setFormValue({ ...formValue, ...{ 'takeprofit': isNaN(value) ? price : value.toFixed(pairData.precision) } })
      }

    } catch (err) {
      console.log(err)
    }
  }

  const decreaseTpPrice = (type) => {
    try {
      let value
      if (type === 'stopprice') {
        value = parseFloat(stopprice) - parseFloat(1 / Math.pow(10, pairData.precision))

        let pipInterval
        if (pairData.secondCurrencySymbol == 'JPY') {
          pipInterval = (1 / Math.pow(10, 5)) * Math.pow(10, 4)
        } else {
          pipInterval = (1 / Math.pow(10, pairData.precision)) * Math.pow(10, pairData.pip_position)
        }
        let toFixedlength = pipInterval.toString().split('.')[1] ? pipInterval.toString().split('.')[1].length : 0
        let pip = ((value - price) * Math.pow(10, pairData.pip_position))
        pip = (isNaN(pip) || pip == undefined || pip == null) ? 0 : parseFloat(pip).toFixed(toFixedlength)
        let usd = pip * pipValue
        usd = (isNaN(usd) || usd == undefined || usd == null) ? 0 : parseFloat(usd).toFixed(2)
        setspPip(pip)
        setspUsd(parseFloat(usd))

        if (buyorsell == 'sell' && value < price) {
          setError({ ...errors, ...{ 'stopprice': 'Stop Loss price must not be less than price' } })
        } else {
          setError({ ...errors, ...{ 'stopprice': '' } })
        }
        setFormValue({ ...formValue, ...{ 'stopprice': isNaN(value) ? price : value.toFixed(pairData.precision) } })
      } else if (type === 'takeprofit') {
        value = parseFloat(takeprofit) - parseFloat(1 / Math.pow(10, pairData.precision))

        let pipInterval
        if (pairData.secondCurrencySymbol == 'JPY') {
          pipInterval = (1 / Math.pow(10, 5)) * Math.pow(10, 4)
        } else {
          pipInterval = (1 / Math.pow(10, pairData.precision)) * Math.pow(10, pairData.pip_position)
        }
        let toFixedlength = pipInterval.toString().split('.')[1] ? pipInterval.toString().split('.')[1].length : 0
        let pip = ((value - price) * Math.pow(10, pairData.pip_position))
        pip = (isNaN(pip) || pip == undefined || pip == null) ? 0 : parseFloat(pip).toFixed(toFixedlength)
        let usd = pip * pipValue
        usd = (isNaN(usd) || usd == undefined || usd == null) ? 0 : parseFloat(usd).toFixed(2)
        settpPip(parseFloat(pip))
        settpUsd(parseFloat(usd))

        if (buyorsell == 'buy' && value < price) {
          setError({ ...errors, ...{ 'takeprofit': 'Take profit price must not be less than price' } })
        } else {
          setError({ ...errors, ...{ 'takeprofit': '' } })
        }
        setFormValue({ ...formValue, ...{ 'takeprofit': isNaN(value) ? price : value.toFixed(pairData.precision) } })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const increasetpPip = async (type) => {
    try {
      let value
      let AddValue
      let interval
      if (pairData.secondCurrencySymbol == 'JPY') {
        interval = (1 / Math.pow(10, 5)) * Math.pow(10, 4)
      } else {
        interval = (1 / Math.pow(10, pairData.precision)) * Math.pow(10, pairData.pip_position)
      }
      if (type == 'stopprice') {
        if (buyorsell == 'sell') {
          value = (spPip + interval).toFixed(2)
          value = (isNaN(value) || value == undefined || value == null) ? 0 : value
          setspPip(parseFloat(value))
          AddValue = interval / Math.pow(10, pairData.pip_position)
          let Stopprice = (parseFloat(stopprice) + AddValue).toFixed(pairData.precision)
          let spusd = (parseFloat(value) * pipValue).toFixed(2)
          spusd = (isNaN(spusd) || spusd == undefined || spusd == null) ? 0 : spusd
          setspUsd(parseFloat(spusd))
          await calculateTpandSp(parseFloat(Stopprice), type)
        }
        else if (buyorsell == 'buy' && spPip < 0) {
          value = (spPip + interval).toFixed(2)
          value = (isNaN(value) || value == undefined || value == null) ? 0 : value
          setspPip(parseFloat(value))
          AddValue = interval / Math.pow(10, pairData.pip_position)
          console.log('formData--->', stopprice + AddValue, stopprice, AddValue)
          let Stopprice = (parseFloat(stopprice) + AddValue).toFixed(pairData.precision)
          let spusd = (parseFloat(value) * pipValue).toFixed(2)
          spusd = (isNaN(spusd) || spusd == undefined || spusd == null) ? 0 : spusd
          setspUsd(parseFloat(spusd))
          await calculateTpandSp(parseFloat(Stopprice), type)
        }
      }
      else if (type == 'takeprofit') {
        if (buyorsell == 'buy') {
          value = (tpPip + interval).toFixed(2)
          value = (isNaN(value) || value == undefined || value == null) ? 0 : value
          settpPip(parseFloat(value))
          AddValue = interval / Math.pow(10, pairData.pip_position)
          let Takeprofit = (parseFloat(takeprofit) + AddValue).toFixed(pairData.precision)
          let tpusd = (parseFloat(value) * pipValue).toFixed(2)
          tpusd = (isNaN(tpusd) || tpusd == undefined || tpusd == null) ? 0 : tpusd
          settpUsd(parseFloat(tpusd))
          await calculateTpandSp(parseFloat(Takeprofit), type)
        }
        else if (buyorsell == 'sell' && tpPip < 0) {
          value = (tpPip + interval).toFixed(2)
          value = (isNaN(value) || value == undefined || value == null) ? 0 : value
          settpPip(parseFloat(value))
          AddValue = interval / Math.pow(10, pairData.pip_position)
          let Takeprofit = (parseFloat(takeprofit) + AddValue).toFixed(pairData.precision)
          let tpusd = (parseFloat(value) * pipValue).toFixed(2)
          tpusd = (isNaN(tpusd) || tpusd == undefined || tpusd == null) ? 0 : tpusd
          settpUsd(parseFloat(tpusd))
          await calculateTpandSp(parseFloat(Takeprofit), type)
        }
      }
    } catch (err) {
      console.log(err, 'increasePipvalue--err')
    }
  }

  const decreasetpPip = async (type) => {
    try {
      let value
      let subValue
      let interval
      if (pairData.secondCurrencySymbol == 'JPY') {
        interval = (1 / Math.pow(10, 5)) * Math.pow(10, 4)
      } else {
        interval = (1 / Math.pow(10, pairData.precision)) * Math.pow(10, pairData.pip_position)
      }
      if (type == 'stopprice') {
        if (buyorsell == 'buy') {
          value = (spPip - interval).toFixed(2)
          value = (isNaN(value) || value == undefined || value == null) ? 0 : value
          setspPip(parseFloat(value))
          subValue = interval / Math.pow(10, pairData.pip_position)
          let Stopprice = (parseFloat(stopprice) - subValue).toFixed(pairData.precision)
          let spusd = (value * pipValue).toFixed(2)
          spusd = (isNaN(spusd) || spusd == undefined || spusd == null) ? 0 : spusd
          setspUsd(parseFloat(spusd))
          await calculateTpandSp(parseFloat(Stopprice), type)
        }
        else if (buyorsell == 'sell' && spPip > 0) {
          value = (spPip - interval).toFixed(2)
          value = (isNaN(value) || value == undefined || value == null) ? 0 : value
          setspPip(parseFloat(value))
          subValue = interval / Math.pow(10, pairData.pip_position)
          let Stopprice = (parseFloat(stopprice) - subValue).toFixed(pairData.precision)
          let spusd = (value * pipValue).toFixed(2)
          spusd = (isNaN(spusd) || spusd == undefined || spusd == null) ? 0 : spusd
          setspUsd(parseFloat(spusd))
          await calculateTpandSp(parseFloat(Stopprice), type)
        }
      }
      else if (type == 'takeprofit') {
        if (buyorsell == 'sell') {
          value = (tpPip - interval).toFixed(2)
          value = (isNaN(value) || value == undefined || value == null) ? 0 : value
          settpPip(parseFloat(value))
          subValue = interval / Math.pow(10, pairData.pip_position)
          let Takeprofit = (parseFloat(takeprofit) - subValue).toFixed(pairData.precision)
          let tpusd = (value * pipValue).toFixed(2)
          tpusd = (isNaN(tpusd) || tpusd == undefined || tpusd == null) ? 0 : tpusd
          settpUsd(parseFloat(tpusd))
          await calculateTpandSp(parseFloat(Takeprofit), type)
        }
        else if (buyorsell == 'buy' && tpPip > 0) {
          value = (tpPip - interval).toFixed(2)
          value = (isNaN(value) || value == undefined || value == null) ? 0 : value
          settpPip(parseFloat(value))
          subValue = interval / Math.pow(10, pairData.pip_position)
          let Takeprofit = (parseFloat(takeprofit) - subValue).toFixed(pairData.precision)
          let tpusd = (value * pipValue).toFixed(2)
          tpusd = (isNaN(tpusd) || tpusd == undefined || tpusd == null) ? 0 : tpusd
          settpUsd(parseFloat(tpusd))
          await calculateTpandSp(parseFloat(Takeprofit), type)
        }
      }
    } catch (err) {
      console.log(err, 'decreasetpPip--err')
    }
  }

  const increasetpUsd = async (type) => {
    try {
      let interval = 0.01
      let AddValue
      let value
      let intervalPip
      let pipInterval
      if (pairData.secondCurrencySymbol == 'JPY') {
        pipInterval = (1 / Math.pow(10, 5)) * Math.pow(10, 4)
      } else {
        pipInterval = (1 / Math.pow(10, pairData.precision)) * Math.pow(10, pairData.pip_position)
      }
      if (type == 'stopprice') {
        if (buyorsell == 'sell') {
          value = (parseFloat(spUsd) + interval)
          value = (isNaN(value) || value == undefined || value == null) ? 0 : value
          setspUsd(parseFloat(value).toFixed(2))
          let sppip = (value / pipValue)
          let toFixedlength = pipInterval.toString().split('.')[1] ? pipInterval.toString().split('.')[1].length : 0
          sppip = sppip.toFixed(toFixedlength)
          intervalPip = parseFloat(sppip) - spPip
          sppip = (isNaN(sppip) || sppip == undefined || sppip == null) ? 0 : sppip
          setspPip(parseFloat(sppip))
          AddValue = intervalPip / Math.pow(10, pairData.pip_position)
          let Stopprice = (stopprice + AddValue).toFixed(pairData.precision)
          await calculateTpandSp(parseFloat(Stopprice), type)
        }
        else if (buyorsell == 'buy' && spUsd < 0) {
          value = (parseFloat(spUsd) + interval)
          value = (isNaN(value) || value == undefined || value == null) ? 0 : value
          setspUsd(parseFloat(value).toFixed(2))
          let sppip = (value / pipValue)
          let toFixedlength = pipInterval.toString().split('.')[1] ? pipInterval.toString().split('.')[1].length : 0
          sppip = sppip.toFixed(toFixedlength)
          intervalPip = parseFloat(sppip) - parseFloat(spPip)
          sppip = (isNaN(sppip) || sppip == undefined || sppip == null) ? 0 : sppip
          setspPip(parseFloat(sppip))
          AddValue = intervalPip / Math.pow(10, pairData.pip_position)
          let Stopprice = (stopprice + AddValue).toFixed(pairData.precision)
          await calculateTpandSp(parseFloat(Stopprice), type)
        }
      } else if (type == 'takeprofit') {
        if (buyorsell == 'buy') {
          value = (parseFloat(tpUsd) + interval)
          value = (isNaN(value) || value == undefined || value == null) ? 0 : value
          settpUsd(parseFloat(value).toFixed(2))
          let tppip = (value / pipValue)
          let toFixedlength = pipInterval.toString().split('.')[1] ? pipInterval.toString().split('.')[1].length : 0
          tppip = tppip.toFixed(toFixedlength)
          intervalPip = parseFloat(tppip) - tpPip
          tppip = (isNaN(tppip) || tppip == undefined || tppip == null) ? 0 : tppip
          settpPip(parseFloat(tppip))
          AddValue = intervalPip / Math.pow(10, pairData.pip_position)
          let Takeprofit = (takeprofit + AddValue).toFixed(pairData.precision)
          console.log(Takeprofit, takeprofit, AddValue, interval, 'formData--->')
          await calculateTpandSp(parseFloat(Takeprofit), type)
        }
        else if (buyorsell == 'sell' && tpUsd < 0) {
          value = (parseFloat(tpUsd) + interval)
          value = (isNaN(value) || value == undefined || value == null) ? 0 : value
          settpUsd(parseFloat(value).toFixed(2))
          let tppip = (value / pipValue)
          let toFixedlength = pipInterval.toString().split('.')[1] ? pipInterval.toString().split('.')[1].length : 0
          tppip = tppip.toFixed(toFixedlength)
          intervalPip = parseFloat(tppip) - tpPip
          tppip = (isNaN(tppip) || tppip == undefined || tppip == null) ? 0 : tppip
          settpPip(parseFloat(tppip))
          AddValue = intervalPip / Math.pow(10, pairData.pip_position)
          let Takeprofit = (takeprofit + AddValue).toFixed(pairData.precision)
          await calculateTpandSp(parseFloat(Takeprofit), type)
        }
      }
    } catch (err) {
      console.log(err, 'increasetpUsd--err')
    }
  }

  const decreasetpUsd = async (type) => {
    try {
      let interval = 0.01
      let subValue
      let value
      let intervalPip
      let pipInterval
      if (pairData.secondCurrencySymbol == 'JPY') {
        pipInterval = (1 / Math.pow(10, 5)) * Math.pow(10, 4)
      } else {
        pipInterval = (1 / Math.pow(10, pairData.precision)) * Math.pow(10, pairData.pip_position)
      }
      if (type == 'stopprice') {
        if (buyorsell == 'buy') {
          value = (parseFloat(spUsd) - interval)
          value = (isNaN(value) || value == undefined || value == null) ? 0 : value
          setspUsd(parseFloat(value).toFixed(2))
          let sppip = (value / pipValue)
          let toFixedlength = pipInterval.toString().split('.')[1] ? pipInterval.toString().split('.')[1].length : 0
          sppip = sppip.toFixed(toFixedlength)
          intervalPip = parseFloat(sppip) - spPip
          sppip = (isNaN(sppip) || sppip == undefined || sppip == null) ? 0 : sppip
          setspPip(parseFloat(sppip))
          subValue = intervalPip / Math.pow(10, pairData.pip_position)
          let Stopprice = (stopprice + subValue).toFixed(pairData.precision)
          console.log(Stopprice, stopprice, subValue, intervalPip, value, sppip, 'decreasetpUsd')
          await calculateTpandSp(parseFloat(Stopprice), type)
        }
        else if (buyorsell == 'sell' && spUsd > 0) {
          value = (parseFloat(spUsd) - interval)
          value = (isNaN(value) || value == undefined || value == null) ? 0 : value
          setspUsd(parseFloat(value).toFixed(2))
          let sppip = (value / pipValue)
          let toFixedlength = pipInterval.toString().split('.')[1] ? pipInterval.toString().split('.')[1].length : 0
          sppip = sppip.toFixed(toFixedlength)
          intervalPip = parseFloat(sppip) - spPip
          sppip = (isNaN(sppip) || sppip == undefined || sppip == null) ? 0 : sppip
          setspPip(parseFloat(sppip))
          subValue = intervalPip / Math.pow(10, pairData.pip_position)
          let Stopprice = (stopprice + subValue).toFixed(pairData.precision)
          await calculateTpandSp(parseFloat(Stopprice), type)
        }
      }
      else if (type == 'takeprofit') {
        if (buyorsell == 'sell') {
          value = (parseFloat(tpUsd) - interval)
          value = (isNaN(value) || value == undefined || value == null) ? 0 : value
          settpUsd(parseFloat(value).toFixed(2))
          let tppip = (value / pipValue)
          let toFixedlength = pipInterval.toString().split('.')[1] ? pipInterval.toString().split('.')[1].length : 0
          tppip = tppip.toFixed(toFixedlength)
          tppip = (isNaN(tppip) || tppip == undefined || tppip == null) ? 0 : tppip
          intervalPip = parseFloat(tppip) - tpPip
          settpPip(parseFloat(tppip))
          subValue = intervalPip / Math.pow(10, pairData.pip_position)
          let Takeprofit = (takeprofit - subValue).toFixed(pairData.precision)
          console.log(Takeprofit, takeprofit, subValue, intervalPip, value, tppip, 'decreasetpUsd')
          await calculateTpandSp(parseFloat(Takeprofit), type)
        }
        else if (buyorsell == 'buy' && tpUsd > 0) {
          value = (parseFloat(tpUsd) - interval)
          value = (isNaN(value) || value == undefined || value == null) ? 0 : value
          settpUsd(parseFloat(value).toFixed(2))
          let tppip = (value / pipValue)
          let toFixedlength = pipInterval.toString().split('.')[1] ? pipInterval.toString().split('.')[1].length : 0
          tppip = tppip.toFixed(toFixedlength)
          tppip = (isNaN(tppip) || tppip == undefined || tppip == null) ? 0 : tppip
          intervalPip = parseFloat(tppip) - tpPip
          settpPip(parseFloat(tppip))
          subValue = intervalPip / Math.pow(10, pairData.pip_position)
          let Takeprofit = (takeprofit - subValue).toFixed(pairData.precision)
          await calculateTpandSp(parseFloat(Takeprofit), type)
        }
      }
    } catch (err) {
      console.log(err, 'decreasetpUsd--err')
    }
  }


  const increaseVolume = async (volume) => {
    try {
      let value
      if (volume > pairData.maxQuantity) {
        setError({ ...errors, ...{ 'quantity': `Volume shouldn't be greater than ${pairData.maxQuantity}` } })
        setVolume(volume)
        return
      }
      else if (volume >= 10) {
        value = volume + 10
        setVolume(value)
      } else if (volume >= 1) {
        value = volume + 1
        setVolume(value)
      }
      else if (volume >= 0.1) {
        value = (volume + 0.1).toFixed(1)
        setVolume(parseFloat(value))
      }
      else {
        value = (volume + 0.01).toFixed(2)
        setVolume(parseFloat(value))
      }
      await calculateQuantity(value)
    } catch (err) {
      console.log(err)
    }
  }
  const decreaseVolume = async (volume) => {
    try {
      let value
      if (volume < pairData.minQuantity) {
        value = 0
        setError({ ...errors, ...{ 'quantity': `Volume shouldn't be less than ${pairData.minQuantity}` } })
        setVolume(value)
        return
      }
      else if (volume > 10) {
        value = volume - 10
        console.log(volume, 'decreaseVolume')
        setVolume(value)
      }
      else if (volume > 1) {
        value = volume - 1
        console.log(volume, 'decreaseVolume')
        setVolume(value)
      }
      else if (volume > 0.1) {
        value = (volume - 0.1).toFixed(1)
        setVolume(parseFloat(value))
      }
      else {
        value = (volume - 0.01).toFixed(2)
        setVolume(parseFloat(value))
      }
      await calculateQuantity(value)
    } catch (err) {
      console.log(err)
    }
  }
  //calculation functions
  const calculateQuantity = async (value) => {
    try {
      let buyprice = Markupprice?.ask ? Markupprice?.ask : pairData?.ask
      let sellprice = Markupprice?.bid ? Markupprice.bid : pairData?.bid
      let Price = price ? price : buyorsell == 'buy' ? buyprice : sellprice
      let quantity = volumetoquantity(value, pairData, Price)
      let formData = { ...formValue, ...{ 'quantity': quantity.toFixed(2) } }
      setFormValue(formData)
      let Estmargin = await EstMargin(Price, quantity, leverage, pairData, buyorsell, pairList)
      setEmargin(isNaN(Estmargin) ? 0 : parseFloat(Estmargin).toFixed(2))
      await calculatepipValue(quantity, pairData, value)
      console.log('calculateQuantity', Estmargin, price, Price, quantity, leverage)
      setError({ ...errors, ...{ 'quantity': '' } })
      // let formData = { ...formValue, ...{ 'quantity': quantity.toFixed(2) } }
      // setFormValue(formData)
    } catch (err) {
      console.log(err, 'calculateQuantity--err')
    }
  }
  const calculatepipValue = async (quantity, pairData, volume) => {
    try {
      if (pairData.category.toUpperCase() == 'FOREX') {
        if (pairData.secondCurrencySymbol != 'USD' && pairData.firstCurrencySymbol != 'USD') {
          const convertquan = await priceConversion(pairData.firstCurrencySymbol, quantity, buyorsell, pairList)
          quantity = convertquan
          let Price = price ? price : localStorage.getItem('price')
          console.log(Price, price, localStorage.getItem('price'), 'calculatepipValue')
          let pipValue = (1 / Math.pow(10, pairData?.pip_position) * parseFloat(quantity)) / parseFloat(Price)
          console.log(quantity, 'calculatepipValue', pipValue)
          setpipValue(isNaN(pipValue) ? 0 : pipValue)
        }
        else if (pairData.firstCurrencySymbol == 'USD') {
          let pipValue = (1 / Math.pow(10, pairData?.pip_position) * parseFloat(quantity))
          pipValue = await priceConversion(pairData.secondCurrencySymbol, pipValue, buyorsell, pairList)
          console.log(pipValue, 'pipValue')
          setpipValue(isNaN(pipValue) ? 0 : pipValue)
        }
        else {
          let pipValue = (1 / Math.pow(10, pairData?.pip_position) * parseFloat(quantity))
          setpipValue(isNaN(pipValue) ? 0 : pipValue)
        }
      }
      else if (pairData.category.toUpperCase() == 'CRYPTO') {
        let pipValue = (pairData.lotsize * volume).toFixed(2)
        setpipValue(parseFloat(pipValue))
      }
      else {
        let pipValue = (pairData.lotsize * volume).toFixed(2)
        setpipValue(parseFloat(pipValue))
      }
    } catch (err) {
      console.log(err, 'calculatepipValue-err')
    }
  }

  const calculationDynamic = async (result) => {
    try {
      if (formsValue.current.orderType == 'market') {
        let price = buyorsell == 'buy' ? result.ask : result.bid
        localStorage.setItem('price', price)
        let valPip = parseFloat(price) * Math.pow(10, pairsData.current.pip_position)
        let Price = buyorsell == 'buy' ? result.ask * Math.pow(10, pairsData.current.pip_position) : result.bid * Math.pow(10, pairsData.current.pip_position)
        let Distance = Math.abs(parseFloat(Price) - parseFloat(valPip)).toFixed(1)
        console.log(formsValue.current, 'calculationDynamic', orderType)
        // let sptoprice
        // let takeprofit
        // if (Stopopen.current) {
        //   sptoprice = price + (SpPip.current / Math.pow(10, pairsData.current.pip_position))
        //   sptoprice = (sptoprice).toFixed(pairsData.current.precision)
        //   await calculateTpandSp(sptoprice, 'stopprice')
        // }
        // if (Takeopen.current) {
        //   takeprofit = price + (TpPip.current / Math.pow(10, pairsData.current.pip_position))
        //   takeprofit = (takeprofit).toFixed(pairsData.current.precision)
        //   await calculateTpandSp(takeprofit, 'takeprofit')
        // }
        // let formData = { ...formsValue.current, ...{ 'price': parseFloat(price), 'stopprice': sptoprice, 'takeprofit': takeprofit } }
        let formData = { ...formsValue.current, ...{ 'price': parseFloat(price) } }
        setFormValue(formData)
        // console.log(Distance, result, valPip, Price, price, 'Distance---->', pairsData.current)
        setDistance(isNaN(parseFloat(Distance)) ? 0 : Distance)
      } else {
        let price = formsValue.current.price
        PriceValidation(price, result)
        console.log(formsValue, price, 'calculationDynamic1', orderType)
        let valPip = parseFloat(price) * Math.pow(10, pairsData.current.pip_position)
        let Price = buyorsell == 'buy' ? result.ask * Math.pow(10, pairsData.current.pip_position) : result.bid * Math.pow(10, pairsData.current.pip_position)
        let Distance = Math.abs(parseFloat(Price) - parseFloat(valPip)).toFixed(1)
        setDistance(isNaN(parseFloat(Distance)) ? 0 : Distance)
      }
    } catch (err) {
      console.log(err, 'calculationDynamic')
    }
  }
  const calculateEstmargin = async (Estmargin, pairData) => {
    try {
      console.log('calculateEstmargin', Estmargin, pipValue)
      if (pairData.secondCurrencySymbol != 'USD') {
        let obj = {
          currecnysymbol: pairData.secondCurrencySymbol,
          buyorsell: buyorsell,
          amount: Estmargin
        }
        const { status, result } = await priceConversion(obj)
        if (status) {
          Estmargin = result
        }
      }
      console.log('calculateEstmargin', Estmargin)
      setEmargin(isNaN(Estmargin) ? 0 : Estmargin?.toFixed(4))
    } catch (err) {
      console.log(err, 'calculateEstmargin--err')
    }
  }

  const calculateTpandSp = (value, type) => {
    console.log(isNaN(value), type, price, 'value,type')
    try {
      if (isNaN(value) || value == '' || value == undefined || value == null) {
        let formData = { ...formValue, ...{ [type]: '' } }
        setFormValue(formData)
        let error = { ...errors, ...{ [type]: `The value must be between 0 to ${price}` } }
        console.log(formData, error, 'value,type')
        setError(error)
      }
      else {
        if (type == "takeprofit" && takeopen) {
          if (buyorsell == 'buy' && value >= price) {
            let formData = { ...formValue, ...{ 'takeprofit': isNaN(value) ? price : value } }
            setFormValue(formData)
            console.log(formData, 'value,type')
            setError({ ...errors, ...{ 'takeprofit': '', 'tpPip': '', 'tpUsd': '' } })
          } else if (buyorsell == 'sell' && value <= price) {
            let formData = { ...formValue, ...{ 'takeprofit': isNaN(value) ? price : value } }
            setFormValue(formData)
            setError({ ...errors, ...{ 'takeprofit': '', 'tpPip': '', 'tpUsd': '' } })
          } else if (buyorsell == 'buy' && value < price) {
            let formData = { ...formValue, ...{ 'takeprofit': isNaN(value) ? price : value } }
            setFormValue(formData)
            setError({ ...errors, ...{ 'takeprofit': 'Take profit price must not be less than price' } })
          }
          else if (buyorsell == 'sell' && value > price) {
            let formData = { ...formValue, ...{ 'takeprofit': isNaN(value) ? price : value } }
            setFormValue(formData)
            setError({ ...errors, ...{ 'takeprofit': 'Take profit price must not be greater than price' } })
          }
        } else if ((type == "stopprice" && stopopen)) {
          if (buyorsell == 'buy' && value <= price) {
            console.log(value, type, 'value,type')
            let formData = { ...formValue, ...{ 'stopprice': isNaN(value) ? price : value } }
            setFormValue(formData)
            setError({ ...errors, ...{ 'stopprice': '', 'spPip': '', 'spUsd': '' } })
          } else if (buyorsell == 'sell' && value >= price) {
            let formData = { ...formValue, ...{ 'stopprice': isNaN(value) ? price : value } }
            setFormValue(formData)
            setError({ ...errors, ...{ 'stopprice': '', 'spPip': '', 'spUsd': '' } })
          } else if (buyorsell == 'buy' && value > price) {
            console.log(value, type, 'value,type')
            let formData = { ...formValue, ...{ 'stopprice': isNaN(value) ? price : value } }
            setFormValue(formData)
            setError({ ...errors, ...{ 'stopprice': 'Stop loss price must not be greater than price' } })
          }
          else if (buyorsell == 'sell' && value < price) {
            let formData = { ...formValue, ...{ 'stopprice': isNaN(value) ? price : value } }
            setFormValue(formData)
            setError({ ...errors, ...{ 'stopprice': 'Stop loss price must not be less than price' } })
          }
        }
      }

    } catch (err) {
      console.log(err, 'calculateTpandSp--err')
    }

  }

  // const calculateOrderCost = (price, quantity, leverage) => {
  //   if (!isEmpty(price) && !isEmpty(quantity) && !isEmpty(leverage) && !isEmpty(pairData)) {
  //     setFormValue((el) => {
  //       return {
  //         ...el,
  //         'leverage': leverage,
  //         'buyOrderCost': inverseOrderCost({
  //           price,
  //           quantity,
  //           leverage,
  //           takerFee: pairData.taker_fees,
  //           buyorsell: 'buy'
  //         }),
  //         'buyOrderValue': orderValue({
  //           price,
  //           quantity
  //         }),
  //         'sellOrderCost': inverseOrderCost({
  //           price,
  //           quantity,
  //           leverage,
  //           takerFee: pairData.taker_fees,
  //           buyorsell: 'sell'
  //         }),
  //         'sellOrderValue': orderValue({
  //           price,
  //           quantity
  //         }),
  //       }
  //     })
  //   }
  // }

  const handleSubmit = async () => {
    try {
      if (verifiedstatus === 'PENDING') {
        toastAlert('error', "You are not able to trade until kyc is approved")
        setLimitLoader(false)
        setMarketLoader(false)
        setstoplimitloader(false)
        setstoploader(false)
        return
      }
      else if (verifiedstatus === 'REJECTED' || verifiedstatus === 'Not verified') {
        toastAlert('error', "Please submit your kyc")
        setLimitLoader(false)
        setMarketLoader(false)
        setstoplimitloader(false)
        setstoploader(false)
        return
      }
      if (takeopen && pairData.tradestatus == 'local') {
        if (buyorsell == 'buy' && takeprofit < Markupprice.ask) {
          console.log(buyorsell == 'buy' && takeprofit < Markupprice.ask, "buyorsell == 'buy' && takeprofit < Markupprice.bid")
          toastAlert('error', "Long: Take profit price must be greater than market price")
          setLimitLoader(false)
          setMarketLoader(false)
          setstoplimitloader(false)
          setstoploader(false)
          return
        }
        else if (buyorsell == 'sell' && takeprofit > Markupprice.bid) {
          console.log(buyorsell == 'sell' && takeprofit > Markupprice.bid, "buyorsell == 'sell' && takeprofit > Markupprice.ask")
          toastAlert('error', "Short: Take profit price must be less than market price")
          setLimitLoader(false)
          setMarketLoader(false)
          setstoplimitloader(false)
          setstoploader(false)
          return
        }
      }
      if (stopopen && pairData.tradestatus == 'local') {
        if (buyorsell == 'buy' && stopprice > Markupprice.ask) {
          console.log(buyorsell == 'buy' && stopprice > Markupprice.ask, "buyorsell == 'buy' && stopprice > Markupprice.bid")
          toastAlert('error', "Long: Spot loss price must be less than market price ")
          setLimitLoader(false)
          setMarketLoader(false)
          setstoplimitloader(false)
          setstoploader(false)
          return
        }
        else if (buyorsell == 'sell' && stopprice < Markupprice.bid) {
          console.log(buyorsell == 'sell' && stopprice < Markupprice.bid, "buyorsell == 'sell' && stopprice < Markupprice.ask")
          toastAlert('error', "Short: Spot loss price must be greater than market price ")
          setLimitLoader(false)
          setMarketLoader(false)
          setstoplimitloader(false)
          setstoploader(false)
          return
        }
      }
      console.log('handleSubmit2', buyorsell, orderType)
      console.log(leverage, 'leverage-->')
      let reqData = {
        price: orderType == 'market' ? buyorsell == 'buy' ?
          Markupprice.ask ? parseFloat(Markupprice.ask) : parseFloat(pairData.ask) :
          Markupprice.bid ? parseFloat(Markupprice.bid) : parseFloat(pairData.bid) : parseFloat(price),
        quantity: parseFloat(quantity),
        volume: parseFloat(volume),
        buyorsell: buyorsell,
        ordertype: (orderType == 'conditional') ? triggerordertype : orderType,
        pairId: pairData.pairId,
        leverage: parseFloat(leverage),
        'timeinforcetype': typeTIF,
        takeProfitPrice: parseFloat(takeprofit),
        stopLossPrice: parseFloat(stopprice),
        isProfitLoss: (stopopen == true || takeopen == true) ? true : false,
        trigger_price: (orderType == 'conditional') ? trigger_price : 0,
        trigger_type: (orderType == 'conditional') ? trigger_type : 'none',
        Estmargin: Est_Margin,
        pairname: pairData.tiker_root,
        'newdate': new Date(),
        isexpriy: isExpriy,
        Expriydate: Epriydate,
        limitRangepips: (orderType == 'stop_limit') ? Limit_range_pips : 0,
        limitRangeprice: (orderType == 'stop_limit') ? Limit_range_price : 0,
        type: useraccount?.type,
        accountId: useraccount?.accountId,
        comment: comment
      }
      console.log(leverage, 'leverage-->', reqData)
      if (typeof leverage === 'string') {
        let lever = leverage.split(':')
        reqData.leverage = parseFloat(lever[0]) / parseFloat(lever[1])
      }
      let vaildate = await validation(reqData)
      if (!isEmpty(vaildate)) {
        setError(vaildate)
        if (buyorsell == 'buy') {
          setBuyLoader(false)
        } 
        else if (buyorsell == 'sell') {
          setSellLoader(false)
        }
        //limit or market
        if (orderType == 'limit') {
          setLimitLoader(false)
        } 
        else if (orderType == 'market') {
          setMarketLoader(false)
        } 
        else if(orderType == 'stop'){
          setstoploader(false)
        }
        else if(orderType == 'stop_limit'){
          setstoplimitloader(false)
        }
        console.log(vaildate, 'vaildate')
        return false
      }
      let encryptToken = {
        token: encryptObject(reqData)
      }
      let { status, loading, message, error, result } = await orderPlace(encryptToken);
      setResults(result)
      if (buyorsell == 'buy') {
        setBuyLoader(loading)
      } else if (buyorsell == 'sell') {
        setSellLoader(loading)
      }
      //limit or market
      if (orderType == 'limit') {
        setLimitLoader(loading)
      } else if (orderType == 'market') {
        setMarketLoader(loading)
      }

      if (status) {
        toastAlert('success', message, 'spotOrder');
        let leverage
        if (pairData.tradestatus == 'xoh') {
          leverage = pairData.leverage
        } else {
          leverage = useraccount.leverage
        }
        props.onDismiss()
        await initialDataSet(leverage, pairData, orderType, pairList)
      } else {
        toastAlert('error', message, 'spotOrder');
        let leverage
        if (pairData.tradestatus == 'xoh') {
          leverage = pairData.leverage
        } else {
          leverage = useraccount.leverage
        }
        await initialDataSet(leverage, pairData, orderType, pairList)
      }
    }
    catch (err) {
      console.log('handleSubmit-err', err)
    }
  }

  const PriceValidation = async (price, Markupprice) => {
    try {
      let { orderType, buyorsell } = formsValue.current
      let bidPrice = Markupprice?.bid ? Markupprice?.bid : pairData?.bid
      let askPrice = Markupprice?.ask ? Markupprice?.ask : pairData?.ask
      console.log('PriceValidation', bidPrice, askPrice, price, orderType, buyorsell)
      if (buyorsell == 'buy' && orderType == 'limit' && price > askPrice) {
        console.log('PriceValidation', buyorsell == 'buy' && orderType == 'limit' && price > askPrice)
        console.log('PriceValidation', buyorsell == 'buy' && orderType == 'limit' && price > askPrice)
        SetLimitDisabel(true)
      } else if (buyorsell == 'sell' && orderType == 'limit' && price < bidPrice) {
        console.log('PriceValidation', buyorsell == 'sell' && orderType == 'limit' && price < bidPrice)
        SetLimitDisabel(true)
      } else if (buyorsell == 'buy' && (orderType == 'stop' || orderType == 'stop_limit') && price < askPrice) {
        console.log('PriceValidation', buyorsell == 'buy' && (orderType == 'stop' || orderType == 'stop_limit') && price < askPrice)
        SetStopDisable(true)
      } else if (buyorsell == 'sell' && (orderType == 'stop' || orderType == 'stop_limit') && price > bidPrice) {
        console.log('PriceValidation', buyorsell == 'sell' && (orderType == 'stop' || orderType == 'stop_limit') && price > bidPrice)
        SetStopDisable(true)
      }
      else {
        SetLimitDisabel(false)
        SetStopDisable(false)
      }
    } catch (err) {
      console.log(err, "PriceValidation")
    }
  }

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime()
  };
  useEffect(() => {

    socketContext.socket.on('perpetualmarketprice', async (result) => {
      // let pairName = window.location.pathname.split("/")[2];
      try {
        let pairName = localStorage.getItem('curpair')
        if (result.tiker_root == pairName) {
          // console.log('result.tiker_root == pairName', result)
          setMarkupprice(result)
          await calculationDynamic(result)
        }
      } catch (err) {
        console.log(err, 'perpetualmarketprice__err')
      }
    })
  }, [socketContext.socket, pairData, useraccount])

  useEffect(() => {
    let contract = pairData.minQuantity * Math.pow(10, pairData.precision)
    let leverage
    if (pairData.tradestatus === 'xoh') {
      leverage = pairData.leverage
    } else {
      leverage = useraccount?.leverage
    }
    initialDataSet(leverage, pairData, orderType, pairList)
    // setVolume(pairData.minQuantity)
    // setFormValue(formData)
    setContract(contract)
  }, [pairData, useraccount])

  //Onchange useEffect
  useEffect(() => {
    console.log(pipValue, takeopen, 'pipValue-useEffect')
    if (takeopen) {
      // if (tpPip) {
      let tpusd = (parseFloat(tpPip) * pipValue).toFixed(2)
      console.log(tpusd, 'pipValue-useEffect')
      settpUsd(parseFloat(tpusd))
      // }
    }
    if (stopopen) {
      // if (spPip) {
      let spusd = (parseFloat(spPip) * pipValue).toFixed(2)
      console.log(spusd, 'pipValue-useEffect')
      setspUsd(parseFloat(spusd))
      // }
    }
  }, [pipValue])

  useEffect(() => {
    let takeprofit = 0
    let stopprice = 0
    let formData = formValue
    if (takeopen && !isTEdit) {
      takeprofit = (parseFloat(price) + (parseFloat(tpPip) / Math.pow(10, pairData.pip_position))).toFixed(pairData.precision)
      calculateTpandSp(parseFloat(takeprofit), 'takeprofit')
    }
    if (stopopen && !isSEdit) {
      stopprice = (parseFloat(price) + (parseFloat(spPip) / Math.pow(10, pairData.pip_position))).toFixed(pairData.precision)
      calculateTpandSp(parseFloat(stopprice), 'stopprice')
    }
    if (!isTEdit) {
      formData = { ...formData, ...{ 'takeprofit': isNaN(takeprofit) ? price : takeprofit } }
      setFormValue(formData)
    } if (!isSEdit) {
      formData = { ...formData, ...{ 'stopprice': isNaN(stopprice) ? price : stopprice } }
      setFormValue(formData)
    }
  }, [price])
  // console.log(props.pairData,"props-new")
  // const {pairData}= props

  //initialDataSet function
  const initialDataSet = async (leverage, pairDetail, e, pairList) => {
    try {
      console.log(leverage, pairDetail, 'quantity,leverage,Price')
      setTEdit(false)
      setSEdit(false)
      setLimitLoader(false)
      setMarketLoader(false)
      setstoplimitloader(false)
      setstoploader(false)
      setVolume(pairDetail.minQuantity)
      setStopopen(false)
      setExpriy(false)
      setTakeopen(false)
      setError({})
      setspPip(0)
      settpPip(0)
      settpUsd(0)
      setspUsd(0)
      var buyprice = pairDetail && pairDetail?.ask
      var sellprice = pairDetail && pairDetail?.bid
      let Price = buyorsell == 'buy' ? buyprice : sellprice
      localStorage.setItem('price', Price)
      let quantity = volumetoquantity(pairDetail.minQuantity, pairDetail, Price)
      let Est_margin = await EstMargin(Price, quantity, leverage, pairDetail, buyorsell, pairList)
      setEmargin(isNaN(Est_margin) ? 0 : Est_margin.toFixed(2))
      let formData = { ...initialFormValue, ...{ 'quantity': quantity.toFixed(2), 'leverage': leverage, 'price': Price, 'orderType': e } }
      formsValue.current = formData
      setFormValue(formData)
      if (pairDetail.category.toUpperCase() == 'FOREX') {
        if (pairDetail.secondCurrencySymbol != 'USD' && pairDetail.firstCurrencySymbol != 'USD') {
          const convertquan = await priceConversion(pairDetail.firstCurrencySymbol, quantity, buyorsell, pairList)
          quantity = convertquan
          let pipValue = (1 / Math.pow(10, pairDetail?.pip_position) * parseFloat(quantity)) / parseFloat(Price)
          console.log(quantity, 'calculatepipValue', pipValue)
          setpipValue(isNaN(pipValue) ? 0 : pipValue)
        }
        else if (pairDetail.firstCurrencySymbol == 'USD') {
          let pipValue = (1 / Math.pow(10, pairDetail?.pip_position) * parseFloat(quantity))
          pipValue = await priceConversion(pairDetail.secondCurrencySymbol, pipValue, buyorsell, pairList)
          console.log(pipValue, 'pipValue')
          setpipValue(isNaN(pipValue) ? 0 : pipValue)
        }
        else {
          let pipValue = (1 / Math.pow(10, pairDetail?.pip_position) * parseFloat(quantity))
          setpipValue(isNaN(pipValue) ? 0 : pipValue)
        }
      }
      else if (pairDetail.category.toUpperCase() == 'CRYPTO') {
        let pipValue = (pairDetail.lotsize * pairDetail.minQuantity).toFixed(2)
        setpipValue(parseFloat(pipValue))
      }
      else {
        let pipValue = (pairDetail.lotsize * pairDetail.minQuantity).toFixed(2)
        setpipValue(parseFloat(pipValue))
      }
      if (Markupprice) {
        if (Markupprice?.tiker_root != pairDetail.tiker_root) {
          setMarkupprice({})
        }
      }
      console.log('Markupprice?.markprice', Price, quantity, leverage)
      let valPip = Price * Math.pow(10, pairDetail.pip_position)
      let Distance = Math.abs(parseFloat(Price * Math.pow(10, pairDetail.pip_position)) - parseFloat(valPip)).toFixed(1)
      setDistance(isNaN(Distance) ? 0 : Distance)

      // let formData = { ...initialFormValue, ...{ 'quantity': quantity.toFixed(2), 'leverage': leverage, 'price': Price ,'orderType':e} }
      // setFormValue(formData)
    } catch (err) {
      console.log(err, 'initialDataSet--err')
    }
  }

  const yesterday = moment().subtract(1, 'day');
  const disablePastDt = current => {
    return current.isAfter(yesterday);
  };

  return (
    <>{console.log(orderType && orderType, "orderType")}
      <Modal show={neworder} className="modalbg informationmodal font-12 modal_se_buy_ac_pad" size="lg" centered scrolable>
        {info && <Infomation leverage={leverage} onDismiss={() => setInfo(false)} />}
        <Modal.Header className='align-items-center'>
          <Modal.Title>{orderType && capitalize(orderType)} Order</Modal.Title>
          <button className='closebtn btn btn-link text-light nounder' onClick={() => props.onDismiss()}>
            <i className="fa fa-times fa-6" aria-hidden="true"></i>

          </button>
        </Modal.Header>
        <Modal.Body className='font-12'>

          <div className='themetab'>
            <Tabs defaultActiveKey="market" className="mb-3 "
              onSelect={async (e) => {
                let leverage
                if (pairData.tradestatus === 'xoh') {
                  leverage = pairData.leverage
                } else {
                  leverage = useraccount.leverage
                }
                await initialDataSet(leverage, pairData, e, pairList)
                // setorder(e)
              }}>
              <Tab eventKey="market" title="Market Order">
                <h5>New Market Order</h5>
                <hr />

                <div className='d-flex jc-between'>
                  <div>
                    <span className='fa fa-check text-success mr-2'></span>
                    {pairData.displayname}
                  </div>
                  <p className='text-success'>

                  </p>
                </div>
                <div className='custdrop my-2'>
                  <div className='d-flex gap-1'>
                    <button onClick={() => setInfo(true)} className='btn graybtn m-0'><i>i</i></button>
                    <div className='drop w-full' onClick={() => setSymbolTab(!symbolTab)}>
                      <p className='d-flex jc-between align-items-center p-1'>{pairData && pairData.displayname}<span className="fa fa-chevron-down"></span></p>
                    </div>
                  </div>
                  <div className={symbolTab ? "d-block wishdrop" : "d-none wishdrop"}>
                    <Symboltab onClose={() => { setSymbolTab(false) }} />
                  </div>
                </div>
                <div className='d-flex flex-1 gap-2'>
                  <button className={buyorsell == "sell" ? 'btn mb-0 pb-0 system_limit_sellLimit sellbtn sellbtn_active' : 'btn sellbtn system_limit_sellLimit mb-0 pb-0'}
                    onClick={() => {
                      setSellLoader(true)
                      // handleSubmit('sell')
                      // Setbuyorsell('sell')
                      setFormValue({ ...formValue, ...{ 'buyorsell': 'sell', 'price': Markupprice && Markupprice?.bid ? Markupprice?.bid : pairData?.bid } })
                    }}
                  >
                    <p>Sell</p>
                    <h4 className='mb-0'>
                      {Markupprice && Markupprice.bid ?
                        isNaN(Markupprice?.disbid) ? 0 : Markupprice?.disbid :
                        isNaN(pairData?.bid) ? 0 : pairData?.bid}
                      <span className='text marketnumber'>
                        {Markupprice && Markupprice.bid ?
                          isNaN(Markupprice?.pipBid) ? 0 : Markupprice?.pipBid :
                          isNaN(BidandAskDisplay(pairData?.bid, pairData?.precision)) ? '' : BidandAskDisplay(pairsData?.current?.bid, pairsData?.current?.precision)}
                      </span>
                    </h4>
                  </button>
                  <button className={buyorsell == "buy" ? 'btn mb-0 pb-0 system_limit_buyLimit buybtn buybtn_active' : 'btn buybtn mb-0 pb-0'}
                    onClick={() => {
                      // Setbuyorsell('buy')
                      setFormValue({ ...formValue, ...{ 'buyorsell': 'buy', 'price': Markupprice && Markupprice?.ask ? Markupprice?.ask : pairData?.ask } })
                    }}
                  >
                    <p>Buy</p>
                    <h4 className='mb-0'>
                      {Markupprice?.ask ?
                        isNaN(Markupprice?.disask) ? 0 : Markupprice?.disask :
                        isNaN(pairData?.ask) ? 0 : pairData?.ask
                      }
                      <span className='text marketnumber'>
                        {Markupprice?.ask ?
                          isNaN(Markupprice?.pipAsk) ? 0 : Markupprice?.pipAsk :
                          isNaN(BidandAskDisplay(pairData?.ask, pairData?.precision)) ? '' : BidandAskDisplay(pairData?.ask, pairData?.precision)}
                      </span>
                    </h4>
                  </button>
                </div>
                <p className='text-center my-2'>
                  Spread:{Markupprice && Markupprice?.spread ? Markupprice?.spread : pairData?.spread};
                  High: {Markupprice && Markupprice?.high ? Markupprice?.high : pairData?.high};
                  Low: {Markupprice && Markupprice?.low ? Markupprice?.low : pairData?.low}
                </p>
                <hr />
                <div>
                  <div className="row px-2">
                    <div className="col-md-4">
                      <p className="font_label_sm">Volume</p>
                      <div className='input_grp_number input_typ_gere px-0'>
                        <input className="selectcount themeselect  w-full"
                          type='text'
                          name="quantity"
                          min='0'
                          value={volume}
                          onChange={handleChange} />
                        <div className='input-group-append font_sm_ap' onClick={() => { increaseVolume(volume) }}>
                          <i className='fa fa-plus' />
                        </div>
                        <div className='input-group-append font_sm_ap' onClick={() => { decreaseVolume(volume) }}>
                          <i className='fa fa-minus' />
                        </div>
                      </div>
                      <p className="font_label_sm">Pip value : {pipValue.toFixed(2)} </p>
                    </div>
                    <div className="col-md-4">
                      <p className="font_label_sm">
                        <div className="d-flex jc-between px-0 pt-0"> Contract value <span className="f-10">AUD</span></div>
                        <div className="inputboxbg"> {quantity}</div>
                      </p>
                      <p className="font_label_sm">Commission : {pairData && pairData.commission} </p>
                    </div>
                    <div className="col-md-4">

                      <p className="font_label_sm">
                        <div className="d-flex jc-between px-0 pt-0"> Margin
                          <span className="f-10">USD</span></div>
                        <div className="inputboxbg">{Est_Margin}</div> </p>
                    </div>
                  </div>
                  <span className="text-danger">{errors && errors?.quantity}</span>

                </div>
                <hr />
                <div className="px-0 font-12 py-0">

                  <p className='mb-2'>Leverage</p>

                  <div className="inputboxbg">{leverage && leverage}</div>


                  {/* <select className="selectcount themeselect w-full"
                    name="leverage"
                    onChange={handleChange}
                    disabled
                  >
                    <option value={leverage && leverage}>{leverage && leverage}</option>


                  </select> */}

                  <span className="text-danger">{errors && errors?.leverage}</span>

                </div>
                <hr />

                <div>
                  <div className="d-flex px-0 justify-content-between align-items-center row mx-0 pb-1">
                    <div className="px-0 d-flex col-5">
                      <input type="checkbox" id="stop_loss_check"
                        checked={stopopen}
                        onChange={(e) => {
                          e.target.checked == true ? setStopopen(true) : setStopopen(false);
                          setFormValue({ ...formValue, ...{ 'stopprice': price } })
                        }}
                      />
                      <label className='mb-0 ml-2'>Stop Loss</label>

                    </div >
                    <div className='px-0 d-flex col-5'>
                      <input type="checkbox" id="take_profit_check"
                        checked={takeopen}
                        onChange={(e) => {
                          e.target.checked == true ? setTakeopen(true) : setTakeopen(false);
                          setFormValue({ ...formValue, ...{ 'takeprofit': price } })
                        }}
                      />
                      <label className='mb-0 ml-2'>Take Profit</label>

                    </div>

                  </div>
                  <div className="d-flex px-0 justify-content-between align-items-center row mx-0 pb-3">
                    <div className="px-0 d-flex col-5">
                      <p className='text-danger f-10'>{errors && errors.stopprice}</p>
                    </div>
                    <div className="px-0 d-flex col-5">
                      <p className='text-danger f-10'>{errors && errors.takeprofit}</p>
                    </div>
                  </div>
                  <div className="row px-2 pt-0 pb-0 row_new_order_align">
                    <div className="col-5 col-sm-5 py-0">
                      {stopopen ? <div className="form_open py-0" id="form_stop">
                        <div className="row py-0">

                          <div className="col-12 col-md-12 py-1">

                            <div className='input_grp_number input_typ_gere p-0'>
                              <input className="selectcount themeselect  w-full"
                                type='text'
                                name="stopprice"
                                min='0'
                                value={stopprice}
                                onBlur={() => { setSEdit(false) }}
                                onFocus={(e) => { setSEdit(true) }}
                                onChange={handleChange} />
                              <div className='input-group-append font_sm_ap' onClick={() => { increaseTpPrice('stopprice') }}>
                                <i className='fa fa-plus' />
                              </div>
                              <div className='input-group-append font_sm_ap' onClick={() => { decreaseTpPrice('stopprice') }}>
                                <i className='fa fa-minus' />
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-12 py-1">
                            <div className='input_grp_number input_typ_gere p-0'>
                              <input className="selectcount themeselect  w-full"
                                type='text'
                                name="spPip"
                                min='0'
                                value={spPip}
                                onChange={handleChange}
                              />
                              <div className='input-group-append font_sm_ap' onClick={() => { increasetpPip('stopprice') }}>
                                <i className='fa fa-plus' />
                              </div>
                              <div className='input-group-append font_sm_ap' onClick={() => { decreasetpPip('stopprice') }}>
                                <i className='fa fa-minus' />
                              </div>
                            </div>
                            <span className="text-danger">{errors && errors?.spPip}</span>
                          </div>
                          <div className="col-12 col-md-12 py-1">

                            <div className='input_grp_number input_typ_gere p-0'>
                              <input className="selectcount themeselect  w-full"
                                type='text'
                                name="spUsd"
                                min='0'
                                value={spUsd}
                                onChange={handleChange}
                              />
                              <div className='input-group-append font_sm_ap' onClick={() => { increasetpUsd('stopprice') }}>
                                <i className='fa fa-plus' />
                              </div>
                              <div className='input-group-append font_sm_ap' onClick={() => { decreasetpUsd('stopprice') }}>
                                <i className='fa fa-minus' />
                              </div>
                            </div>
                            <span className="text-danger">{errors && errors?.spUsd}</span>
                          </div>
                          {/* <div className="col-12 col-md-12 py-1">

                            <div className='input_grp_number input_typ_gere p-0'>
                              <input className="selectcount themeselect  w-full"
                                type='text'
                                name="quantity"
                                min='0'
                                // value={volume}
                                // onChange={handleChange} 
                                />
                              <div className='input-group-append font_sm_ap'>
                                <i className='fa fa-plus' />
                              </div>
                              <div className='input-group-append font_sm_ap'>
                                <i className='fa fa-minus' />
                              </div>
                            </div>
                          </div> */}


                        </div>
                      </div> :
                        ""}
                    </div>
                    {stopopen || takeopen ? <div className="col-2 text-center py-0">
                      <div className="row py-0 stoptake">
                        <div className="col-12 col-md-12 py-1">
                          <label className='mb-0'>Price</label>
                        </div>
                        <div className="col-12 col-md-12 py-1">
                          <label className='mb-0'>Pips</label>
                        </div>
                        <div className="col-12 col-md-12 py-1">
                          <label className='mb-0'>USD</label>
                        </div>
                        {/* <div className="col-12 col-md-12 py-1">
                          <label className='mb-0'>%</label>
                        </div> */}
                      </div>
                    </div> : ""}
                    <div className="col-5 col-sm-5 py-0">
                      {takeopen ?
                        <div className="form_open  py-0" id="form_take">
                          <div className="row  py-0">

                            <div className="col-12 col-md-12 py-1">

                              <div className='input_grp_number input_typ_gere p-0'>
                                <input className="selectcount themeselect  w-full"
                                  type='text'
                                  name="takeprofit"
                                  min='0'
                                  value={takeprofit}
                                  onBlur={() => { setTEdit(false) }}
                                  onFocus={() => { setTEdit(true) }}
                                  onChange={handleChange} />
                                <div className='input-group-append font_sm_ap' onClick={() => { increaseTpPrice('takeprofit') }}>
                                  <i className='fa fa-plus' />
                                </div>
                                <div className='input-group-append font_sm_ap' onClick={() => { decreaseTpPrice('takeprofit') }}>
                                  <i className='fa fa-minus' />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-12 py-1">

                              <div className='input_grp_number input_typ_gere p-0'>
                                <input className="selectcount themeselect  w-full"
                                  type='tpPip'
                                  name="tpPip"
                                  min='0'
                                  value={tpPip}
                                  onChange={handleChange}
                                />
                                <div className='input-group-append font_sm_ap' onClick={() => { increasetpPip('takeprofit') }}>
                                  <i className='fa fa-plus' />
                                </div>
                                <div className='input-group-append font_sm_ap' onClick={() => { decreasetpPip('takeprofit') }}>
                                  <i className='fa fa-minus' />
                                </div>
                              </div>
                              <span className="text-danger">{errors && errors?.tpPip}</span>
                            </div>
                            <div className="col-12 col-md-12 py-1">

                              <div className='input_grp_number input_typ_gere p-0'>
                                <input className="selectcount themeselect  w-full"
                                  type='text'
                                  name="tpUsd"
                                  min='0'
                                  value={tpUsd}
                                  onChange={handleChange}
                                />
                                <div className='input-group-append font_sm_ap' onClick={() => { increasetpUsd('takeprofit') }}>
                                  <i className='fa fa-plus' />
                                </div>
                                <div className='input-group-append font_sm_ap' onClick={() => { decreasetpUsd('takeprofit') }}>
                                  <i className='fa fa-minus' />
                                </div>
                              </div>
                              <span className="text-danger">{errors && errors?.tpUsd}</span>
                            </div>
                            {/* <div className="col-12 col-md-12 py-1">

                              <div className='input_grp_number input_typ_gere p-0'>
                                <input className="selectcount themeselect  w-full"
                                  type='text'
                                  name="quantity"
                                  min='0'
                                  // value={volume}
                                  // onChange={handleChange}
                                   />
                                <div className='input-group-append font_sm_ap'>
                                  <i className='fa fa-plus' />
                                </div>
                                <div className='input-group-append font_sm_ap'>
                                  <i className='fa fa-minus' />
                                </div>
                              </div>
                            </div> */}

                          </div>
                        </div> :
                        ""}
                    </div>
                  </div>
                </div>

                <hr />
                {marketloader ? <button className={buyorsell == 'buy' ? 'btn green-btn w-full' : 'btn red green-btn w-full'}>
                  <i className="fa fa-spinner mr-2"></i>
                  <span>Loading..</span>
                </button>
                  :
                  isAuth === true ? <button className={buyorsell == 'buy' ? 'btn green-btn w-full' : 'btn red green-btn w-full'}
                    onClick={() => {
                      // setBuyLoader(true)
                      setMarketLoader(true)
                      // setSubmited('market')
                      handleSubmit()
                    }}
                  >Place Market Order</button> : <button className="btn green-btn w-full" onClick={handleClick}>Login</button>}
              </Tab>
              <Tab eventKey="limit" title="Limit Order">
                <h5>New Limit Order</h5>
                <hr />
                <div className='d-flex jc-between'>
                  <div>
                    <span className='fa fa-check text-success mr-2'></span>
                    {pairData.displayname}
                  </div>
                  <p className='text-success'>

                  </p>
                </div>
                <div className='custdrop my-2'>
                  <div className='d-flex gap-1'>
                    <button onClick={() => setInfo(true)} className='btn graybtn m-0'><i>i</i></button>
                    <div className='drop w-full' onClick={() => setSymbolTab(!symbolTab)}>
                      <p className='d-flex jc-between align-items-center p-1'>{pairData && pairData.displayname} <span className="fa fa-chevron-down"></span></p>
                    </div>
                  </div>
                  <div className={symbolTab ? "d-block wishdrop" : "d-none wishdrop"}>
                    <Symboltab onClose={() => { setSymbolTab(false) }} />
                  </div>
                </div>
                <div className='d-flex flex-1 gap-2'>
                  <button className={buyorsell == "sell" ? 'btn mb-0 pb-0 system_limit_sellLimit sellbtn sellbtn_active' : 'btn sellbtn system_limit_sellLimit mb-0 pb-0'}
                    onClick={() => {
                      setSellLoader(true)
                      // Setbuyorsell('sell')
                      setFormValue({ ...formValue, ...{ 'buyorsell': 'sell', 'price': Markupprice && Markupprice?.bid ? Markupprice?.bid : pairData?.bid } })
                    }}
                  >
                    <p>Sell Limit</p>
                    <h4 className='mb-0'>
                      {Markupprice && Markupprice.bid ?
                        isNaN(Markupprice?.disbid) ? 0 : Markupprice?.disbid :
                        isNaN(pairData?.bid) ? 0 : pairData?.bid}
                      <span className='text marketnumber'>
                        {Markupprice && Markupprice.bid ?
                          isNaN(Markupprice?.pipBid) ? 0 : Markupprice?.pipBid :
                          isNaN(BidandAskDisplay(pairData?.bid, pairData?.precision)) ? '' : BidandAskDisplay(pairsData?.current?.bid, pairsData?.current?.precision)}
                      </span>
                    </h4>
                  </button>
                  <button className={buyorsell == "buy" ? 'btn mb-0 pb-0 system_limit_buyLimit buybtn buybtn_active' : 'btn buybtn mb-0 pb-0'}
                    onClick={() => {
                      setBuyLoader(true)
                      // Setbuyorsell('buy')
                      setFormValue({ ...formValue, ...{ 'buyorsell': 'buy', 'price': Markupprice && Markupprice?.ask ? Markupprice?.ask : pairData?.ask } })
                    }}
                  >
                    <p>Buy Limit</p>
                    <h4 className='mb-0'>
                      {Markupprice?.ask ?
                        isNaN(Markupprice?.disask) ? 0 : Markupprice?.disask :
                        isNaN(pairData?.ask) ? 0 : pairData?.ask
                      }
                      <span className='text marketnumber'>
                        {Markupprice?.ask ?
                          isNaN(Markupprice?.pipAsk) ? 0 : Markupprice?.pipAsk :
                          isNaN(BidandAskDisplay(pairData?.ask, pairData?.precision)) ? '' : BidandAskDisplay(pairData?.ask, pairData?.precision)}
                      </span>
                    </h4>
                  </button>
                </div>
                <hr />
                <div className=' flex-1 gap-2'>
                  <div>
                    <p className='mb-2'>Entry Price</p>
                    <input className={LimitDisable ? "form-control themeinput eror_input" : "form-control themeinput"}
                      name="price"
                      value={price}
                      onChange={handleChange}
                      type="text" />
                    <span className="text-danger">{errors && errors?.price}</span>
                    <p className='mt-2'>Distance: {Distance}</p>
                  </div>
                  <div>

                    <div className="row mt-3">
                      <div className="col-md-4">
                        <p className="font_label_sm">Volume</p>
                        <div className='input_grp_number input_typ_gere px-0'>
                          <input className="selectcount themeselect  w-full"
                            type='text'
                            name="quantity"
                            min='0'
                            value={volume}
                            onChange={handleChange} />
                          <div className='input-group-append font_sm_ap' onClick={() => { increaseVolume(volume) }}>
                            <i className='fa fa-plus' />
                          </div>
                          <div className='input-group-append font_sm_ap' onClick={() => { decreaseVolume(volume) }}>
                            <i className='fa fa-minus' />
                          </div>
                        </div>
                        <p className="font_label_sm">Pip value : {pipValue.toFixed(2)} </p>
                      </div>

                      <div className="col-md-4">
                        <p className="font_label_sm">
                          <div className="d-flex jc-between px-0 pt-0"> Contract value <span className="f-10">AUD</span></div>
                          <div className="inputboxbg"> {quantity}</div>
                        </p>
                        <p className="font_label_sm">Commission : {pairData && pairData.commission} </p>
                      </div>
                      <div className="col-md-4">

                        <p className="font_label_sm">
                          <div className="d-flex jc-between px-0 pt-0"> Margin
                            <span className="f-10">USD</span></div>
                          <div className="inputboxbg">{Est_Margin}</div> </p>
                      </div>

                    </div>



                    <span className="text-danger">{errors && errors?.quantity}</span>

                  </div>
                </div>

                <hr />
                <div className="px-0 font-12 py-0 mt-2">
                  <p className='mb-2'>Leverage</p>

                  <div className="inputboxbg">{leverage && leverage}</div>

                  {/* <select className="selectcount themeselect w-full"
                    name="leverage"
                    onChange={handleChange}
                    disabled
                  >
                    <option value={leverage && leverage}>{leverage && leverage}</option>


                  </select> */}
                  <span className="text-danger">{errors && errors?.leverage}</span>


                </div>
                <div className='d-sm-flex align-items-center gap-2 mt-3 modal_new_caldr'>
                  <div className='d-flex mb-2 mb-sm-0'><input type="checkbox"
                    onChange={(e) => { e.target.checked == true ? setExpriy(true) : setExpriy(false) }}
                  />
                    <label className='mb-0 ml-2'>Expiry</label>
                  </div>


                  {/* <Datetime
                    popperPlacement="top-end"
                    input={true}
                    // closeOnSelect={false}
                    // closeOnTab={true}
                    // isValidDate={valid}
                    value={Epriydate}
                    className="w-100 rdt_new rdt_new_ro_mod"
                    timeFormat="HH:mm:ss"
                    timeConstraints={{
                      hours: { min: new Date(Date.now()).getHours(), max: 23 },
                    }}
                    isValidDate={disablePastDt}
                    inputProps={{ onKeyDown: (e) => { e.preventDefault() } }}
                    onChange={(e) => {
                      let newDate = new Date(e._d)
                      setFormValue({ ...formValue, 'Epriydate': newDate.getTime() })
                      console.log("event", e._d)
                    }}
                  // onChange={(value) => {
                  //   Auctionset(value);
                  // }}
                  /> */}

                  {/* <Form.Control 
                                 type="date" 
                              

                                selected={Epriydate}
                                placeholder="Enter Expiry"
                                className="example-custom-input input_date_new_pick input_date_new_pick_modal ml-0 ml-sm-2"
                                name="dob"
                                onChange={(date) => {
                                  let newDate = new Date(date)
                                  newDate.setMilliseconds(0)
                                  let formData = { ...formValue, ...{ 'Epriydate': newDate.getTime() } }
                                  setFormValue(formData)
                                }}
                               
                                dateFormat="MMMM d, yyyy "

                                minDate={new Date()}
                                
                             
                             
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                                 /> */}
                  <DatePicker
                    disabled={!isExpriy}
                    popperClassName="some-custom-class mb-mob-ip"
                    className="example-custom-input"
                    popperPlacement="top-end"
                    selected={Epriydate}
                    onChange={(date) => {
                      let newDate = new Date(date)
                      newDate.setMilliseconds(0)
                      let formData = { ...formValue, ...{ 'Epriydate': newDate.getTime() } }
                      setFormValue(formData)
                    }}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    minDate={new Date()}
                    filterTime={filterPassedTime}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />

                </div>


                <hr />
                <div>

                  <div className="d-flex px-0 justify-content-between align-items-center row mx-0 pb-1">
                    <div className="px-0 d-flex col-5">
                      <input type="checkbox" id="stop_loss_check" checked={stopopen}
                        onChange={(e) => {
                          console.log(e.target.checked, "sche");
                          e.target.checked == true ? setStopopen(true) : setStopopen(false);
                          setFormValue({ ...formValue, ...{ 'stopprice': price } })
                        }}
                      />
                      <label className='mb-0 ml-2'>Stop Loss</label>

                    </div>
                    <div className='px-0 d-flex col-5'>
                      <input type="checkbox" id="take_profit_check" checked={takeopen}
                        onChange={(e) => {
                          e.target.checked == true ? setTakeopen(true) : setTakeopen(false)
                          setFormValue({ ...formValue, ...{ 'takeprofit': price } })
                        }}
                      />
                      <label className='mb-0 ml-2'>Take Profit</label>

                    </div>
                  </div>
                  <div className="d-flex px-0 justify-content-between align-items-center row mx-0 pb-3">
                    <div className="px-0 d-flex col-5">
                      <p className='text-danger f-10'>{errors && errors.stopprice}</p>
                    </div>
                    <div className="px-0 d-flex col-5">
                      <p className='text-danger f-10'>{errors && errors.takeprofit}</p>
                    </div>
                  </div>

                  <div className="row px-2 pt-0 pb-0 row_new_order_align">
                    <div className="col-5 col-sm-5 py-0">
                      {stopopen ? <div className="form_open py-0" id="form_stop">
                        <div className="row py-0">

                          <div className="col-12 col-md-12 py-1">

                            <div className='input_grp_number input_typ_gere p-0'>
                              <input className="selectcount themeselect  w-full"
                                type='text'
                                name="stopprice"
                                min='0'
                                value={stopprice}
                                onBlur={() => { setSEdit(false) }}
                                onFocus={(e) => { setSEdit(true) }}
                                onChange={handleChange} />
                              <div className='input-group-append font_sm_ap' onClick={() => { increaseTpPrice('stopprice') }}>
                                <i className='fa fa-plus' />
                              </div>
                              <div className='input-group-append font_sm_ap' onClick={() => { decreaseTpPrice('stopprice') }}>
                                <i className='fa fa-minus' />
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-12 py-1">

                            <div className='input_grp_number input_typ_gere p-0'>
                              <input className="selectcount themeselect  w-full"
                                type='text'
                                name="spPip"
                                min='0'
                                value={spPip}
                                onChange={handleChange}
                              />
                              <div className='input-group-append font_sm_ap' onClick={() => { increasetpPip('stopprice') }}>
                                <i className='fa fa-plus' />
                              </div>
                              <div className='input-group-append font_sm_ap' onClick={() => { decreasetpPip('stopprice') }}>
                                <i className='fa fa-minus' />
                              </div>
                            </div>
                            <span className="text-danger">{errors && errors?.spPip}</span>
                          </div>
                          <div className="col-12 col-md-12 py-1">

                            <div className='input_grp_number input_typ_gere p-0'>
                              <input className="selectcount themeselect  w-full"
                                type='text'
                                name="spUsd"
                                min='0'
                                value={spUsd}
                                onChange={handleChange}
                              />
                              <div className='input-group-append font_sm_ap' onClick={() => { increasetpUsd('stopprice') }}>
                                <i className='fa fa-plus' />
                              </div>
                              <div className='input-group-append font_sm_ap' onClick={() => { decreasetpUsd('stopprice') }}>
                                <i className='fa fa-minus' />
                              </div>
                            </div>
                            <span className="text-danger">{errors && errors?.spUsd}</span>
                          </div>
                          {/* <div className="col-12 col-md-12 py-1">

                            <div className='input_grp_number input_typ_gere p-0'>
                              <input className="selectcount themeselect  w-full"
                                type='text'
                                name="quantity"
                                min='0'
                                // value={volume}
                                // onChange={handleChange} 
                                />
                              <div className='input-group-append font_sm_ap'>
                                <i className='fa fa-plus' />
                              </div>
                              <div className='input-group-append font_sm_ap'>
                                <i className='fa fa-minus' />
                              </div>
                            </div>
                          </div> */}

                        </div>
                      </div> :
                        ""}
                    </div>
                    {stopopen || takeopen ? <div className="col-2 text-center py-0">
                      <div className="row py-0 stoptake">
                        <div className="col-12 col-md-12 py-1">
                          <label className='mb-0'>Price</label>
                        </div>
                        <div className="col-12 col-md-12 py-1">
                          <label className='mb-0'>Pips</label>
                        </div>
                        <div className="col-12 col-md-12 py-1">
                          <label className='mb-0'>USD</label>
                        </div>
                        {/* <div className="col-12 col-md-12 py-1">
                          <label className='mb-0'>%</label>
                        </div> */}
                      </div>
                    </div> : ""}
                    <div className="col-5 col-sm-5 py-0">
                      {takeopen ?
                        <div className="form_open  py-0" id="form_take">
                          <div className="row  py-0">
                            <div className="col-12 col-md-12 py-1">

                              <div className='input_grp_number input_typ_gere p-0'>
                                <input className="selectcount themeselect  w-full"
                                  type='text'
                                  name="takeprofit"
                                  min='0'
                                  value={takeprofit}
                                  onBlur={() => { setTEdit(false) }}
                                  onFocus={() => { setTEdit(true) }}
                                  onChange={handleChange} />
                                <div className='input-group-append font_sm_ap' onClick={() => { increaseTpPrice('takeprofit') }}>
                                  <i className='fa fa-plus' />
                                </div>
                                <div className='input-group-append font_sm_ap' onClick={() => { decreaseTpPrice('takeprofit') }}>
                                  <i className='fa fa-minus' />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-12 py-1">

                              <div className='input_grp_number input_typ_gere p-0'>
                                <input className="selectcount themeselect  w-full"
                                  type='text'
                                  name="tpPip"
                                  min='0'
                                  value={tpPip}
                                  onChange={handleChange}
                                />
                                <div className='input-group-append font_sm_ap' onClick={() => { increasetpPip('takeprofit') }}>
                                  <i className='fa fa-plus' />
                                </div>
                                <div className='input-group-append font_sm_ap' onClick={() => { decreasetpPip('takeprofit') }}>
                                  <i className='fa fa-minus' />
                                </div>
                              </div>
                              <span className="text-danger">{errors && errors?.tpPip}</span>
                            </div>
                            <div className="col-12 col-md-12 py-1">

                              <div className='input_grp_number input_typ_gere p-0'>
                                <input className="selectcount themeselect  w-full"
                                  type='text'
                                  name="tpUsd"
                                  min='0'
                                  value={tpUsd}
                                  onChange={handleChange}
                                />
                                <div className='input-group-append font_sm_ap' onClick={() => { increasetpUsd('takeprofit') }}>
                                  <i className='fa fa-plus' />
                                </div>
                                <div className='input-group-append font_sm_ap' onClick={() => { decreasetpUsd('takeprofit') }}>
                                  <i className='fa fa-minus' />
                                </div>
                              </div>
                              <span className="text-danger">{errors && errors?.tpUsd}</span>
                            </div>
                            {/* <div className="col-12 col-md-12 py-1">

                              <div className='input_grp_number input_typ_gere p-0'>
                                <input className="selectcount themeselect  w-full"
                                  type='text'
                                  name="quantity"
                                  min='0'
                                  // value={volume}
                                  // onChange={handleChange} 
                                  />
                                <div className='input-group-append font_sm_ap'>
                                  <i className='fa fa-plus' />
                                </div>
                                <div className='input-group-append font_sm_ap'>
                                  <i className='fa fa-minus' />
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div> :
                        ""}
                    </div>
                  </div>
                </div>
                <hr />
                {limitloader ? <button className={buyorsell == 'buy' ? 'btn green-btn w-full' : 'btn red green-btn w-full'}>
                  <i className="fa fa-spinner mr-2"></i>
                  <span>Loading..</span>
                </button> :
                  isAuth === true ? <button className={buyorsell == 'buy' ? 'btn green-btn w-full' : 'btn red green-btn w-full'}
                    disabled={LimitDisable && LimitDisable}
                    onClick={() => {
                      setLimitLoader(true)
                      handleSubmit()
                    }}>Place Limit Order</button> : <button className="btn green-btn w-full" onClick={handleClick}>Login</button>}
                <p className='text-center my-2'>
                  Spread:{Markupprice && Markupprice?.spread ? Markupprice?.spread : pairData?.spread};
                  High: {Markupprice && Markupprice?.high ? Markupprice?.high : pairData?.high};
                  Low: {Markupprice && Markupprice?.low ? Markupprice?.low : pairData?.low}
                </p>

              </Tab>
              <Tab eventKey="stop" title="Stop Order">
                <h5>New Stop Order</h5>
                <hr />
                <div className='d-flex jc-between'>
                  <div>
                    <span className='fa fa-check text-success mr-2'></span>
                    {pairData.displayname}
                  </div>
                  <p className='text-success'>
                  </p>
                </div>
                <div className='custdrop my-2'>
                  <div className='d-flex gap-1'>
                    <button onClick={() => setInfo(true)} className='btn graybtn m-0'><i>i</i></button>
                    <div className='drop w-full' onClick={() => setSymbolTab(!symbolTab)}>
                      <p className='d-flex jc-between align-items-center p-1'>{pairData && pairData.displayname}<span className="fa fa-chevron-down"></span></p>
                    </div>
                  </div>
                  <div className={symbolTab ? "d-block wishdrop" : "d-none wishdrop"}>
                    <Symboltab onClose={() => { setSymbolTab(false) }} />
                  </div>
                </div>
                <div className='d-flex flex-1 gap-2'>
                  <button className={buyorsell == "sell" ? 'btn mb-0 pb-0 system_limit_sellLimit sellbtn sellbtn_active' : 'btn sellbtn system_limit_sellLimit mb-0 pb-0'}
                    onClick={() => {
                      setSellLoader(true)
                      // Setbuyorsell('sell')
                      setFormValue({ ...formValue, ...{ 'buyorsell': 'sell', 'price': Markupprice && Markupprice?.ask ? Markupprice?.ask : pairData?.ask } })
                    }}
                  >
                    <p>Sell Stop</p>
                    <h4 className='mb-0'>
                      {Markupprice && Markupprice.bid ?
                        isNaN(Markupprice?.disbid) ? 0 : Markupprice?.disbid :
                        isNaN(pairData?.bid) ? 0 : pairData?.bid}
                      <span className='text marketnumber'>
                        {Markupprice && Markupprice.bid ?
                          isNaN(Markupprice?.pipBid) ? 0 : Markupprice?.pipBid :
                          isNaN(BidandAskDisplay(pairData?.bid, pairData?.precision)) ? '' : BidandAskDisplay(pairsData?.current?.bid, pairsData?.current?.precision)}
                      </span>
                    </h4>
                  </button>
                  <button className={buyorsell == "buy" ? 'btn mb-0 pb-0 system_limit_buyLimit buybtn buybtn_active' : 'btn buybtn mb-0 pb-0'}
                    onClick={() => {
                      setBuyLoader(true)
                      // Setbuyorsell('buy')
                      setFormValue({ ...formValue, ...{ 'buyorsell': 'buy', 'price': Markupprice && Markupprice?.bid ? Markupprice?.bid : pairData?.bid } })
                    }}
                  >
                    <p>Buy Stop</p>
                    <h4 className='mb-0'>
                      {Markupprice?.ask ?
                        isNaN(Markupprice?.disask) ? 0 : Markupprice?.disask :
                        isNaN(pairData?.ask) ? 0 : pairData?.ask
                      }
                      <span className='text marketnumber'>
                        {Markupprice?.ask ?
                          isNaN(Markupprice?.pipAsk) ? 0 : Markupprice?.pipAsk :
                          isNaN(BidandAskDisplay(pairData?.ask, pairData?.precision)) ? '' : BidandAskDisplay(pairData?.ask, pairData?.precision)}
                      </span>
                    </h4>
                  </button>
                </div>
                <hr />
                <div className=' flex-1 gap-3'>
                  <div>
                    <p className='mb-2'>Entry Price</p>
                    <input className={StopDisable ? "form-control themeinput eror_input" : "form-control themeinput"}
                      name="price"
                      value={price}
                      onChange={handleChange}
                      type="text" />
                    <span className="text-danger">{errors && errors?.price}</span>
                    <p className='mt-2'>Distance:{Distance} </p>
                  </div>
                  <div>
                    <div className="row mt-3">
                      <div className="col-md-4">
                        <p className="font_label_sm">Volume</p>
                        <div className='input_grp_number input_typ_gere px-0'>
                          <input className="selectcount themeselect  w-full"
                            type='text'
                            name="quantity"
                            min='0'
                            value={volume}
                            onChange={handleChange} />
                          <div className='input-group-append font_sm_ap' onClick={() => { increaseVolume(volume) }}>
                            <i className='fa fa-plus' />
                          </div>
                          <div className='input-group-append font_sm_ap' onClick={() => { decreaseVolume(volume) }}>
                            <i className='fa fa-minus' />
                          </div>
                        </div>
                        <p className="font_label_sm">Pip value : {pipValue.toFixed(2)} </p>
                      </div>

                      <div className="col-md-4">
                        <p className="font_label_sm">
                          <div className="d-flex jc-between px-0 pt-0"> Contract value <span className="f-10">AUD</span></div>
                          <div className="inputboxbg"> {quantity}</div>
                        </p>
                        <p className="font_label_sm">Commission : {pairData && pairData.commission} </p>
                      </div>
                      <div className="col-md-4">
                        {/* Est. {buyorsell} */}
                        <p className="font_label_sm">
                          <div className="d-flex jc-between px-0 pt-0"> Margin
                            <span className="f-10">USD</span></div>
                          <div className="inputboxbg">{Est_Margin}</div> </p>
                      </div>

                    </div>
                    <span className="text-danger">{errors && errors?.quantity}</span>
                  </div>
                </div>

                <hr />
                <div className="px-0 font-12 py-0 mt-2">
                  <p className='mb-2'>Leverage</p>
                  <div className="inputboxbg">{leverage && leverage}</div>


                  {/* <select className="selectcount themeselect w-full"
                    name="leverage"
                    onChange={handleChange}
                    disabled
                  >
                    <option value={leverage && leverage}>{leverage && leverage}</option>
                  </select> */}

                  <span className="text-danger">{errors && errors?.leverage}</span>

                </div>
                <div className='d-sm-flex align-items-center gap-2 mt-3 modal_new_caldr_2' >
                  <div className='d-flex mb-2 mb-sm-0'><input type="checkbox"
                    onChange={(e) => { e.target.checked == true ? setExpriy(true) : setExpriy(false) }}
                  />
                    <label className='mb-0 ml-2'>Expiry</label>
                  </div>


                  <Datetime
                    popperPlacement="top-end"
                    input={true}
                    value={Epriydate}
                    // isValidDate={valid}
                    className="w-100 rdt_new rdt_new_ro_mod"
                    timeFormat="HH:mm:ss"
                    timeConstraints={{
                      hours: { min: new Date(Date.now()).getHours(), max: 23 },
                    }}
                    isValidDate={disablePastDt}
                    inputProps={{ onKeyDown: (e) => { e.preventDefault() } }}
                    onChange={(e) => {
                      let newDate = new Date(e._d)
                      setFormValue({ ...formValue, 'Epriydate': newDate.getTime() })
                      console.log("event", e._d)
                    }}
                  // onChange={(value) => {
                  //   Auctionset(value);
                  // }}
                  />

                  {/* <Form.Control 
                                 type="date" 
                            

                                selected={Epriydate}
                                placeholder="Enter Expiry"
                                className="example-custom-input input_date_new_pick input_date_new_pick_modal ml-0 ml-sm-2"
                                name="dob"
                                onChange={(date) => {
                                  let newDate = new Date(date)
                                  newDate.setMilliseconds(0)
                                  let formData = { ...formValue, ...{ 'Epriydate': newDate.getTime() } }
                                  setFormValue(formData)
                                }}
                               
                                dateFormat="MMMM d, yyyy "

                                minDate={new Date()}
                             
                             
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                                 /> */}



                  <DatePicker
                    disabled={!isExpriy}
                    popperClassName="some-custom-class mb-mob-ip"
                    className="example-custom-input"
                    popperPlacement="top-end"
                    selected={Epriydate}
                    onChange={(date) => {
                      let newDate = new Date(date)
                      newDate.setMilliseconds(0)
                      let formData = { ...formValue, ...{ 'Epriydate': newDate.getTime() } }
                      setFormValue(formData)
                    }}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    minDate={new Date()}
                    filterTime={filterPassedTime}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                </div>
                <hr />
                <div className="d-flex px-0 justify-content-between align-items-center row mx-0 pb-1">
                  <div className="px-0 d-flex col-5">
                    <input type="checkbox" id="stop_loss_check" checked={stopopen}
                      onChange={(e) => {
                        console.log(e.target.checked, "sche");
                        e.target.checked == true ? setStopopen(true) : setStopopen(false);
                        setFormValue({ ...formValue, ...{ 'stopprice': price } })
                      }} />
                    <label className='mb-0 ml-2'>Stop Loss</label>

                  </div >
                  <div className='px-0 d-flex col-5'>
                    <input type="checkbox" id="take_profit_check" checked={takeopen}
                      onChange={(e) => {
                        e.target.checked == true ? setTakeopen(true) : setTakeopen(false);
                        setFormValue({ ...formValue, ...{ 'takeprofit': price } })
                      }}
                    />
                    <label className='mb-0 ml-2'>Take Profit</label>

                  </div>

                </div>
                <div className="d-flex px-0 justify-content-between align-items-center row mx-0 pb-3">
                  <div className="px-0 d-flex col-5">
                    <p className='text-danger f-10'>{errors && errors.stopprice}</p>
                  </div>
                  <div className="px-0 d-flex col-5">
                    <p className='text-danger f-10'>{errors && errors.takeprofit}</p>
                  </div>
                </div>

                <div className="row px-2 pt-0 pb-0 row_new_order_align">
                  <div className="col-5 col-sm-5 py-0">
                    {stopopen ? <div className="form_open py-0" id="form_stop">
                      <div className="row py-0">
                        <div className="col-12 col-md-12 py-1">

                          <div className='input_grp_number input_typ_gere p-0'>
                            <input className="selectcount themeselect  w-full"
                              type='text'
                              name="stopprice"
                              min='0'
                              value={stopprice}
                              onBlur={() => { setSEdit(false) }}
                              onFocus={(e) => { setSEdit(true) }}
                              onChange={handleChange} />
                            <div className='input-group-append font_sm_ap' onClick={() => { increaseTpPrice('stopprice') }}>
                              <i className='fa fa-plus' />
                            </div>
                            <div className='input-group-append font_sm_ap' onClick={() => { decreaseTpPrice('stopprice') }}>
                              <i className='fa fa-minus' />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12 py-1">

                          <div className='input_grp_number input_typ_gere p-0'>
                            <input className="selectcount themeselect  w-full"
                              type='text'
                              name="spPip"
                              min='0'
                              value={spPip}
                              onChange={handleChange}
                            />
                            <div className='input-group-append font_sm_ap' onClick={() => { increasetpPip('stopprice') }}>
                              <i className='fa fa-plus' />
                            </div>
                            <div className='input-group-append font_sm_ap' onClick={() => { decreasetpPip('stopprice') }}>
                              <i className='fa fa-minus' />
                            </div>
                          </div>
                          <span className="text-danger">{errors && errors?.spPip}</span>
                        </div>
                        <div className="col-12 col-md-12 py-1">

                          <div className='input_grp_number input_typ_gere p-0'>
                            <input className="selectcount themeselect  w-full"
                              type='text'
                              name="spUsd"
                              min='0'
                              value={spUsd}
                              onChange={handleChange}
                            />
                            <div className='input-group-append font_sm_ap' onClick={() => { increasetpUsd('stopprice') }}>
                              <i className='fa fa-plus' />
                            </div>
                            <div className='input-group-append font_sm_ap' onClick={() => { decreasetpUsd('stopprice') }}>
                              <i className='fa fa-minus' />
                            </div>
                          </div>
                          <span className="text-danger">{errors && errors?.spUsd}</span>
                        </div>
                        {/* <div className="col-12 col-md-12 py-1">

                          <div className='input_grp_number input_typ_gere p-0'>
                            <input className="selectcount themeselect  w-full"
                              type='text'
                              name="quantity"
                              min='0'
                              // value={volume}
                              // onChange={handleChange}
                               />
                            <div className='input-group-append font_sm_ap'>
                              <i className='fa fa-plus' />
                            </div>
                            <div className='input-group-append font_sm_ap'>
                              <i className='fa fa-minus' />
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div> :
                      ""}
                  </div>
                  {stopopen || takeopen ? <div className="col-2 text-center py-0">
                    <div className="row py-0 stoptake">
                      <div className="col-12 col-md-12 py-1">
                        <label className='mb-0'>Price</label>
                      </div>
                      <div className="col-12 col-md-12 py-1">
                        <label className='mb-0'>Pips</label>
                      </div>
                      <div className="col-12 col-md-12 py-1">
                        <label className='mb-0'>USD</label>
                      </div>
                      {/* <div className="col-12 col-md-12 py-1">
                        <label className='mb-0'>%</label>
                      </div> */}
                    </div>
                  </div> : ""}
                  <div className="col-5 col-sm-5 py-0">
                    {takeopen ?
                      <div className="form_open  py-0" id="form_take">
                        <div className="row  py-0">
                          <div className="col-12 col-md-12 py-1">

                            <div className='input_grp_number input_typ_gere p-0'>
                              <input className="selectcount themeselect  w-full"
                                type='text'
                                name="takeprofit"
                                min='0'
                                value={takeprofit}
                                onBlur={() => { setTEdit(false) }}
                                onFocus={() => { setTEdit(true) }}
                                onChange={handleChange}
                              />
                              <div className='input-group-append font_sm_ap' onClick={() => { increaseTpPrice('takeprofit') }}>
                                <i className='fa fa-plus' />
                              </div>
                              <div className='input-group-append font_sm_ap' onClick={() => { decreaseTpPrice('takeprofit') }}>
                                <i className='fa fa-minus' />
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-12 py-1">

                            <div className='input_grp_number input_typ_gere p-0'>
                              <input className="selectcount themeselect  w-full"
                                type='text'
                                name="tpPip"
                                min='0'
                                value={tpPip}
                                onChange={handleChange}
                              />
                              <div className='input-group-append font_sm_ap' onClick={() => { increasetpPip('takeprofit') }}>
                                <i className='fa fa-plus' />
                              </div>
                              <div className='input-group-append font_sm_ap' onClick={() => { decreasetpPip('takeprofit') }}>
                                <i className='fa fa-minus' />
                              </div>
                            </div>
                            <span className="text-danger">{errors && errors?.tpPip}</span>
                          </div>
                          <div className="col-12 col-md-12 py-1">

                            <div className='input_grp_number input_typ_gere p-0'>
                              <input className="selectcount themeselect  w-full"
                                type='text'
                                name="tpUsd"
                                min='0'
                                value={tpUsd}
                                onChange={handleChange}
                              />
                              <div className='input-group-append font_sm_ap' onClick={() => { increasetpUsd('takeprofit') }}>
                                <i className='fa fa-plus' />
                              </div>
                              <div className='input-group-append font_sm_ap' onClick={() => { decreasetpUsd('takeprofit') }}>
                                <i className='fa fa-minus' />
                              </div>
                            </div>
                            <span className="text-danger">{errors && errors?.tpUsd}</span>
                          </div>
                          {/* <div className="col-12 col-md-12 py-1">

                            <div className='input_grp_number input_typ_gere p-0'>
                              <input className="selectcount themeselect  w-full"
                                type='text'
                                name="quantity"
                                min='0'
                                // value={volume}
                                // onChange={handleChange}
                                 />
                              <div className='input-group-append font_sm_ap'>
                                <i className='fa fa-plus' />
                              </div>
                              <div className='input-group-append font_sm_ap'>
                                <i className='fa fa-minus' />
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div> :
                      ""}
                  </div>
                </div>
                <hr />
                {stoploader ? <button className={buyorsell == 'buy' ? 'btn green-btn w-full' : 'btn red green-btn w-full'}>
                  <i className="fa fa-spinner mr-2"></i>
                  <span>Loading..</span>
                </button> :
                  isAuth === true ? <button className={buyorsell == 'buy' ? 'btn green-btn w-full' : 'btn red green-btn w-full'}
                    disabled={StopDisable && StopDisable}
                    onClick={() => {
                      setstoploader(true)
                      handleSubmit()
                    }}>Place Stop Order</button> : <button className="btn green-btn w-full" onClick={handleClick}>Login</button>
                }
                <p className='text-center my-2'>
                  Spread:{Markupprice && Markupprice?.spread ? Markupprice?.spread : pairData?.spread};
                  High: {Markupprice && Markupprice?.high ? Markupprice?.high : pairData?.high};
                  Low: {Markupprice && Markupprice?.low ? Markupprice?.low : pairData?.low}
                </p>

              </Tab>
              <Tab eventKey="stop_limit" title="Stop Limit Order">
                <h5>New Stop Limit Order</h5>
                <hr />
                <div className='d-flex jc-between'>
                  <div>
                    <span className='fa fa-check text-success mr-2'></span>
                    {pairData.displayname}
                  </div>
                  <p className='text-success'>
                  </p>
                </div>
                <div className='custdrop my-2'>
                  <div className='d-flex gap-1'>
                    <button onClick={() => setInfo(true)} className='btn graybtn m-0'><i>i</i></button>
                    <div className='drop w-full' onClick={() => setSymbolTab(!symbolTab)}>
                      <p className='d-flex jc-between align-items-center p-1'>{pairData && pairData.displayname} <span className="fa fa-chevron-down"></span></p>
                    </div>
                  </div>
                  <div className={symbolTab ? "d-block wishdrop" : "d-none wishdrop"}>
                    <Symboltab onClose={() => { setSymbolTab(false) }} />
                  </div>
                </div>
                <div className='d-flex flex-1 gap-2'>
                  <button className={buyorsell == "sell" ? 'btn mb-0 pb-0 system_limit_sellLimit sellbtn sellbtn_active' : 'btn sellbtn system_limit_sellLimit mb-0 pb-0'}
                    onClick={() => {
                      setSellLoader(true)
                      // Setbuyorsell('sell')
                      setFormValue({ ...formValue, ...{ 'buyorsell': 'sell', 'price': Markupprice && Markupprice?.ask ? Markupprice?.ask : pairData?.ask } })
                    }} >
                    <p>Sell Stop Limit</p>
                    <h4 className='mb-0'>
                      {Markupprice && Markupprice.bid ?
                        isNaN(Markupprice?.disbid) ? 0 : Markupprice?.disbid :
                        isNaN(pairData?.bid) ? 0 : pairData?.bid}
                      <span className='text marketnumber'>
                        {Markupprice && Markupprice.bid ?
                          isNaN(Markupprice?.pipBid) ? 0 : Markupprice?.pipBid :
                          isNaN(BidandAskDisplay(pairData?.bid, pairData?.precision)) ? '' : BidandAskDisplay(pairsData?.current?.bid, pairsData?.current?.precision)}
                      </span>
                    </h4>
                  </button>
                  <button className={buyorsell == "buy" ? 'btn mb-0 pb-0 system_limit_buyLimit buybtn buybtn_active' : 'btn buybtn mb-0 pb-0'}
                    onClick={() => {
                      setBuyLoader(true)
                      // Setbuyorsell('buy')
                      setFormValue({ ...formValue, ...{ 'buyorsell': 'sell', 'price': Markupprice && Markupprice?.ask ? Markupprice?.ask : pairData?.ask } })
                    }}>
                    <p>Buy Stop Limit</p>
                    <h4 className='mb-0'>
                      {Markupprice?.ask ?
                        isNaN(Markupprice?.disask) ? 0 : Markupprice?.disask :
                        isNaN(pairData?.ask) ? 0 : pairData?.ask
                      }
                      <span className='text marketnumber'>
                        {Markupprice?.ask ?
                          isNaN(Markupprice?.pipAsk) ? 0 : Markupprice?.pipAsk :
                          isNaN(BidandAskDisplay(pairData?.ask, pairData?.precision)) ? '' : BidandAskDisplay(pairData?.ask, pairData?.precision)}
                      </span>
                    </h4>
                  </button>
                </div>
                <hr />
                <div className=' flex-1 gap-3'>
                  <div>
                    <p className='mb-2'>Entry Price</p>
                    <input
                      className={StopDisable ? "form-control themeinput eror_input" : "form-control themeinput"}
                      name="price"
                      value={price}
                      onChange={handleChange}
                      type="text"
                    />
                    <span className="text-danger">{errors && errors?.price}</span>
                    <p className='mt-2'>Distance: {Distance}</p>
                  </div>
                  <div>
                    <div className="row mt-3">
                      <div className="col-md-4">
                        <p className="font_label_sm">Volume</p>
                        <div className='input_grp_number input_typ_gere px-0'>
                          <input className="selectcount themeselect  w-full"
                            type='text'
                            name="quantity"
                            min='0'
                            value={volume}
                            onChange={handleChange} />
                          <div className='input-group-append font_sm_ap' onClick={() => { increaseVolume(volume) }}>
                            <i className='fa fa-plus' />
                          </div>
                          <div className='input-group-append font_sm_ap' onClick={() => { decreaseVolume(volume) }}>
                            <i className='fa fa-minus' />
                          </div>
                        </div>
                        <p className="font_label_sm">Pip value : {pipValue.toFixed(2)} </p>
                      </div>

                      <div className="col-md-4">
                        <p className="font_label_sm">
                          <div className="d-flex jc-between px-0 pt-0"> Contract value <span className="f-10">AUD</span></div>
                          <div className="inputboxbg"> {quantity}</div>
                        </p>
                        <p className="font_label_sm">Commission : {pairData && pairData.commission} </p>
                      </div>
                      <div className="col-md-4">
                        <p className="font_label_sm">
                          <div className="d-flex jc-between px-0 pt-0"> Margin
                            <span className="f-10">USD</span></div>
                          <div className="inputboxbg">{Est_Margin}</div> </p>
                      </div>
                    </div>
                    <span className="text-danger">{errors && errors?.quantity}</span>
                  </div>
                </div>

                <hr />
                <div className="px-0 font-12 py-0 mt-2">
                  <p className="mb-2 font_label_sm">Limit Range</p>
                  <input type='text' className="form-control themeinput" name="Limit_Range" value={Limit_Range} onChange={handleChange} />
                  <span className="text-danger">{errors && errors?.limitrange}</span>
                  <p className='mb-2'>Leverage</p>
                  <div className="inputboxbg">{leverage && leverage}</div>


                  {/* <select className="selectcount themeselect w-full"
                    name="leverage"
                    onChange={handleChange}
                    disabled
                  >
                    <option value={leverage && leverage}>{leverage && leverage}</option>

                  </select> */}
                  <span className="text-danger">{errors && errors?.leverage}</span>



                </div>
                <div className='d-sm-flex align-items-center gap-2 mt-3 modal_new_caldr_3' >
                  <div className='d-flex mb-2 mb-sm-0'><input type="checkbox"
                    onChange={(e) => { e.target.checked == true ? setExpriy(true) : setExpriy(false) }}
                  />
                    <label className='mb-0 ml-2 ml-sm-2'>Expiry</label>
                  </div>



                  <Datetime
                    popperPlacement="top-end"
                    input={true}
                    value={Epriydate}
                    // isValidDate={valid}
                    className="w-100 rdt_new rdt_new_ro_mod"
                    timeFormat="HH:mm:ss"
                    timeConstraints={{
                      hours: { min: new Date(Date.now()).getHours(), max: 23 },
                    }}
                    isValidDate={disablePastDt}
                    inputProps={{ onKeyDown: (e) => { e.preventDefault() } }}
                    onChange={(e) => {
                      let newDate = new Date(e._d)
                      setFormValue({ ...formValue, 'Epriydate': newDate.getTime() })
                      console.log("event", e._d)
                    }}
                  // onChange={(value) => {
                  //   Auctionset(value);
                  // }}
                  />
                  {/* <Form.Control 
                                 type="date" 
                          
                                selected={Epriydate}
                                placeholder="Enter Expiry"
                                className="example-custom-input input_date_new_pick input_date_new_pick_modal ml-0 ml-sm-2"
                                name="dob"
                                onChange={(date) => {
                                  let newDate = new Date(date)
                                  newDate.setMilliseconds(0)
                                  let formData = { ...formValue, ...{ 'Epriydate': newDate.getTime() } }
                                  setFormValue(formData)
                                }}
                               
                                dateFormat="MMMM d, yyyy "

                                minDate={new Date()}
                                
                           
                             
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                                 /> */}


                  <DatePicker
                    disabled={!isExpriy}
                    popperClassName="some-custom-class mb-mob-ip"
                    className="example-custom-input"
                    popperPlacement="top-end"
                    selected={Epriydate}
                    onChange={(date) => {
                      let newDate = new Date(date)
                      newDate.setMilliseconds(0)
                      let formData = { ...formValue, ...{ 'Epriydate': newDate.getTime() } }
                      setFormValue(formData)
                    }}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    minDate={new Date()}
                    filterTime={filterPassedTime}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                </div>

                <hr />
                <div className="d-flex px-0 justify-content-between align-items-center row mx-0 pb-1">
                  <div className="px-0 d-flex col-5">
                    <input type="checkbox" id="stop_loss_check" checked={stopopen}
                      onChange={(e) => {
                        console.log(e.target.checked, "sche");
                        e.target.checked == true ? setStopopen(true) : setStopopen(false);
                        setFormValue({ ...formValue, ...{ 'stopprice': price } })
                      }}
                    />
                    <label className='mb-0 ml-2'>Stop Loss</label>

                  </div >
                  <div className='px-0 d-flex col-5'>
                    <input type="checkbox" id="take_profit_check" checked={takeopen}
                      onChange={(e) => {
                        e.target.checked == true ? setTakeopen(true) : setTakeopen(false);
                        setFormValue({ ...formValue, ...{ 'takeprofit': price } })
                      }}
                    />
                    <label className='mb-0 ml-2'>Take Profit</label>

                  </div>

                </div>
                <div className="d-flex px-0 justify-content-between align-items-center row mx-0 pb-3">
                  <div className="px-0 d-flex col-5">
                    <p className='text-danger f-10'>{errors && errors.stopprice}</p>
                  </div>
                  <div className="px-0 d-flex col-5">
                    <p className='text-danger f-10'>{errors && errors.takeprofit}</p>
                  </div>
                </div>

                <div className="row px-2 pt-0 pb-0 row_new_order_align">
                  <div className="col-5 col-sm-5 py-0">
                    {stopopen ? <div className="form_open py-0" id="form_stop">
                      <div className="row py-0">

                        <div className="col-12 col-md-12 py-1">

                          <div className='input_grp_number input_typ_gere p-0'>
                            <input className="selectcount themeselect  w-full"
                              type='text'
                              name="stopprice"
                              min='0'
                              value={stopprice}
                              onBlur={() => { setSEdit(false) }}
                              onFocus={(e) => { setSEdit(true) }}
                              onChange={handleChange} />
                            <div className='input-group-append font_sm_ap' onClick={() => { increaseTpPrice('stopprice') }}>
                              <i className='fa fa-plus' />
                            </div>
                            <div className='input-group-append font_sm_ap' onClick={() => { increaseTpPrice('stopprice') }}>
                              <i className='fa fa-minus' />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12 py-1">

                          <div className='input_grp_number input_typ_gere p-0'>
                            <input className="selectcount themeselect  w-full"
                              type='text'
                              name="spPip"
                              min='0'
                              value={spPip}
                              onChange={handleChange}
                            />
                            <div className='input-group-append font_sm_ap' onClick={() => { increasetpPip('stopprice') }}>
                              <i className='fa fa-plus' />
                            </div>
                            <div className='input-group-append font_sm_ap' onClick={() => { decreasetpPip('stopprice') }}>
                              <i className='fa fa-minus' />
                            </div>
                          </div>
                          <span className="text-danger">{errors && errors?.spPip}</span>
                        </div>
                        <div className="col-12 col-md-12 py-1">

                          <div className='input_grp_number input_typ_gere p-0'>
                            <input className="selectcount themeselect  w-full"
                              type='text'
                              name="spUsd"
                              min='0'
                              value={spUsd}
                              onChange={handleChange}
                            />
                            <div className='input-group-append font_sm_ap' onClick={() => { increasetpUsd('stopprice') }}>
                              <i className='fa fa-plus' />
                            </div>
                            <div className='input-group-append font_sm_ap' onClick={() => { decreasetpUsd('stopprice') }}>
                              <i className='fa fa-minus' />
                            </div>
                          </div>
                          <span className="text-danger">{errors && errors?.spUsd}</span>
                        </div>
                        {/* <div className="col-12 col-md-12 py-1">

                          <div className='input_grp_number input_typ_gere p-0'>
                            <input className="selectcount themeselect  w-full"
                              type='text'
                              name="quantity"
                              min='0'
                              // value={volume}
                              // onChange={handleChange} 
                              />
                            <div className='input-group-append font_sm_ap'>
                              <i className='fa fa-plus' />
                            </div>
                            <div className='input-group-append font_sm_ap'>
                              <i className='fa fa-minus' />
                            </div>
                          </div>
                        </div> */}

                      </div>
                    </div> :
                      ""}
                  </div>
                  {stopopen || takeopen ? <div className="col-2 text-center py-0">
                    <div className="row py-0 stoptake">
                      <div className="col-12 col-md-12 py-1">
                        <label className='mb-0'>Price</label>
                      </div>
                      <div className="col-12 col-md-12 py-1">
                        <label className='mb-0'>Pips</label>
                      </div>
                      <div className="col-12 col-md-12 py-1">
                        <label className='mb-0'>USD</label>
                      </div>
                      {/* <div className="col-12 col-md-12 py-1">
                        <label className='mb-0'>%</label>
                      </div> */}
                    </div>
                  </div> : ""}
                  <div className="col-5 col-sm-5 py-0">
                    {takeopen ?
                      <div className="form_open  py-0" id="form_take">
                        <div className="row  py-0">
                          <div className="col-12 col-md-12 py-1">

                            <div className='input_grp_number input_typ_gere p-0'>
                              <input className="selectcount themeselect  w-full"
                                type='text'
                                name="takeprofit"
                                min='0'
                                value={takeprofit}
                                onBlur={() => { setTEdit(false) }}
                                onFocus={(e) => { setTEdit(true) }}
                                onChange={handleChange} />
                              <div className='input-group-append font_sm_ap' onClick={() => { increaseTpPrice('takeprofit') }}>
                                <i className='fa fa-plus' />
                              </div>
                              <div className='input-group-append font_sm_ap' onClick={() => { decreaseTpPrice('takeprofit') }}>
                                <i className='fa fa-minus' />
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-12 py-1">

                            <div className='input_grp_number input_typ_gere p-0'>
                              <input className="selectcount themeselect  w-full"
                                type='text'
                                name="tpPip"
                                min='0'
                                value={tpPip}
                                onChange={handleChange}
                              />
                              <div className='input-group-append font_sm_ap' onClick={() => { increasetpPip('takeprofit') }}>
                                <i className='fa fa-plus' />
                              </div>
                              <div className='input-group-append font_sm_ap' onClick={() => { decreasetpPip('takeprofit') }}>
                                <i className='fa fa-minus' />
                              </div>
                            </div>
                            <span className="text-danger">{errors && errors?.tpPip}</span>
                          </div>
                          <div className="col-12 col-md-12 py-1">

                            <div className='input_grp_number input_typ_gere p-0'>
                              <input className="selectcount themeselect  w-full"
                                type='text'
                                name="tpUsd"
                                min='0'
                                value={tpUsd}
                                onChange={handleChange}
                              />
                              <div className='input-group-append font_sm_ap' onClick={() => { increasetpUsd('takeprofit') }}>
                                <i className='fa fa-plus' />
                              </div>
                              <div className='input-group-append font_sm_ap' onClick={() => { decreasetpUsd('takeprofit') }}>
                                <i className='fa fa-minus' />
                              </div>
                            </div>
                            <span className="text-danger">{errors && errors?.tpUsd}</span>
                          </div>
                          {/* <div className="col-12 col-md-12 py-1">

                            <div className='input_grp_number input_typ_gere p-0'>
                              <input className="selectcount themeselect  w-full"
                                type='text'
                                name="quantity"
                                min='0'
                                // value={volume}
                                // onChange={handleChange} 
                                />
                              <div className='input-group-append font_sm_ap'>
                                <i className='fa fa-plus' />
                              </div>
                              <div className='input-group-append font_sm_ap'>
                                <i className='fa fa-minus' />
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div> :
                      ""}
                  </div>
                </div>
                <hr />
                {stoplimitloader ? <button className={buyorsell == 'buy' ? 'btn green-btn w-full' : 'btn red green-btn w-full'}>
                  <i className="fa fa-spinner mr-2"></i>
                  <span>Loading..</span>
                </button> :
                  isAuth === true ? <button className={buyorsell == 'buy' ? 'btn green-btn w-full' : 'btn red green-btn w-full'}
                    disabled={StopDisable && StopDisable}
                    onClick={() => {
                      setstoplimitloader(true)
                      // setSubmited('limit')
                      handleSubmit()
                    }}
                  >
                    Place Stop Limit Order
                  </button> : <button className="btn green-btn w-full" onClick={handleClick}>Login</button>
                }
                <p className='text-center my-2'>
                  Spread:{Markupprice && Markupprice?.spread ? Markupprice?.spread : pairData?.spread};
                  High: {Markupprice && Markupprice?.high ? Markupprice?.high : pairData?.high};
                  Low: {Markupprice && Markupprice?.low ? Markupprice?.low : pairData?.low}
                </p>

              </Tab>
            </Tabs>
          </div>

        </Modal.Body>
        {/* } */}
      </Modal>
    </>
  )
}

export default NewOrder
