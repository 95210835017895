import { getRIO } from 'actions/copyTradeAction';
import React, { Component ,useEffect,useState} from 'react';
import ReactApexChart from 'react-apexcharts';
const RoiChart = (props) =>{
  const [months, setMonths] = useState(['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']);
  const [chartDate, setchartData] = useState([])
  const [result, setResult] = useState([])
  const [data,setData] = useState([])
  console.log("RoiChart_monthly_props",props)
  const {TrdaerDetail,Followerdetails}=props
    // const data1 =  {
    //   series1: [{
    //     name: 'Cash Flow',
    //     data: chartDate
    //   }],
    //   options1: {
    //     chart: {
    //       type: 'bar',
    //       height: 350
    //     },
    //     grid: {
    //       borderColor: "#555",
    //       clipMarkers: false,
    //       yaxis: {
    //         lines: {
    //           show: true
    //         },
    //       }
    //     },
    //     tooltip: {
    //       theme: "dark"
    //     },
    //     plotOptions: {
    //       bar: {
    //         colors: {
    //           ranges: [{
    //             from: -100,
    //             to: -46,
    //             color: '#F15B46'
    //           }, {
    //             from: -45,
    //             to: 0,
    //             color: '#FEB019'
    //           }]
    //         },
    //         columnWidth: '80%',
    //       }
    //     },
    //     dataLabels: {
    //       enabled: false,
    //     },
    //     yaxis: {
    //       show: false,
    //       labels: {
    //         show: false,
    //       },
          
    //     },
    //     // yaxis: {
    //     //   title: {
    //     //     text: 'Growth',
    //     //   },
    //     //   labels: {
    //     //     formatter: function (y) {
    //     //       return y.toFixed(0) + "%";
    //     //     }
    //     //   }
    //     // },
    //     xaxis: {
    //       categories: months,
    //       labels: {
    //         style: {
    //           colors: "#fff",
    //       },
    //         rotate: -90
    //       }
    //     }
    //   },
    
    
  
    //   };


    const data1 =  {
      series1: [{
        name: 'ROI',
        data: chartDate
      }],
      options1: {
        chart: {
          type: 'bar',
          height: 350,
          width:'100%'
        },
        grid: {
          borderColor: "#555",
          clipMarkers: false,
          yaxis: {
            lines: {
              beginAtZero: true,
              show: false
            },
          }
        },
        
        tooltip: {
          theme: "dark"
        },
        plotOptions: {
          bar: {
            colors: {
              ranges: [{
                from: -100,
                to: -46,
                color: '#F15B46'
              }, {
                from: -45,
                to: 0,
                color: '#FEB019'
              }]
            },
            columnWidth: '80%',
          }
        },
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          show: false,
          labels: {
            show: false,
          },
          
        },
        annotations: {
          yaxis: [
            {
              y: 0,
              strokeDashArray: 0,
              borderColor: "#000",
              fillColor: "#FFF",
              opacity: 0.8,
              offsetX: 0,
              offsetY: 0
            }
          ]
        },
        // yaxis: {
        //   title: {
        //     text: 'Growth',
        //   },
        //   labels: {
        //     formatter: function (y) {
        //       return y.toFixed(0) + "%";
        //     }
        //   }
        // },
        xaxis: {
          categories: months,
          labels: {
            style: {
              colors: "#fff",
          },
            rotate: -90
          }
        }
      },
    
    
  
      };

      useEffect(()=> {
        monthlyRIO(new Date().getFullYear())
      },[TrdaerDetail,Followerdetails]);

      const monthlyRIO = async (year) => {
        let payload 
        if(props.type == 'copyier'){
          payload = {
            followerAccountId: props.Followerdetails?.followerAccountId,
            traderAccountId: props?.TrdaerDetail?.accountId,
            year: year,
            type: 'copy'
          }
        }else{
          payload = {
            accountId: props?.TrdaerDetail?.accountId,
            userId: props?.TrdaerDetail?.userId,
            year: year,
            type: props?.type
          }
        }
        
        if (Object.values(payload) != undefined || Object.values(payload) != '') {
          const getData = await getRIO(payload);
          setData(getData.result)
          let RIO = [];
          months.map((val, ind) => {
            if (getData?.result?.some((dat) => dat.month == ind + 1)) {
              getData.result.map((value, index) => {
                if (value.month == ind + 1) {
                  RIO.push(parseFloat(value?.RIO)?.toFixed(4))
                }
              })
            }
            else {
              RIO.push(0)
            }
          });
          setchartData(RIO)
          setResult(getData.result)
          console.log('monthlyRIO_getData',getData);
        }
        
      }
  return(
    <div className='roi_chart_size h-100'>
      <div className="tab-content h-100">
        <p className="boxheadings mb-1">ROI (Monthly)</p>
        <div className="darkbox p-3 bg_trans_tab chartheight">
            <div className='tab_yr'>

              <div className='chartnav'>
                <div className='chartnav_in'>
                  <button className="btn_only_tab btn_tab_pos inchart" onClick={() => { monthlyRIO(new Date().getFullYear()) }}>
                    {new Date().getFullYear()}
                  </button>
                  <button className="btn_only_tab btn_tab_pos inchart next_yr" onClick={() => { monthlyRIO(new Date().getFullYear() - 1) }}>
                    {new Date().getFullYear() - 1}
                  </button>
                </div>
              </div>
          {data && data.length > 0 ?

              <div id="chart"> 
                <ReactApexChart options={data1?.options1} series={data1?.series1} type="bar" width='100%' height={250} />
              </div> : <div className='performance_nodata_txt'> <div className='nodata'>No data found</div></div>}

            </div>
           
        </div>
      </div>
    </div>
  )
}

export default RoiChart