// import lib
import isEmpty from '../../lib/isEmpty';
let imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG)$/;
let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

const Landingvalidation = (value, callFrom) => {
    console.log("value---------------->",value);
    const PhoneNo = value.PhoneNo == undefined ? "" :value.PhoneNo
    console.log("callFrom--->",callFrom);
    if (callFrom == 'createTicket') {
        return createTicket(value)
    } else if (callFrom == 'replyMsg') {
        return replyMsg(value)
    }
}

export const createTicket = value => {
    console.log("valueiiiiiiiiiiii",value);
    const PhoneNo = value.PhoneNo == undefined ? "" :value.PhoneNo
    console.log("PhoneNNNN",PhoneNo);
    try{
    let errors = {};

    if (isEmpty(value.name)) {
        errors.name= "REQUIRED"
    }
    if (isEmpty(value.email)) {
        errors.email = "REQUIRED"    
    }
    else if (!(emailRegex.test(value.email))) {
        errors.email = "INVALID_EMAIL"
    }
    if (isEmpty(value.PhoneNo)) {
        errors.PhoneNo = "REQUIRED"
    }
    // else if(!(phoneno.test(value.PhoneNo))) {
    //     errors.PhoneNo = "Invalid Phone Number"
    // }
    
    if (isEmpty(value.message)) {
        errors.message = "REQUIRED"
    }
    
   if(value.attachment && value.attachment.size){
    if(value.attachment.size > 3145728){
        errors.attachment="TOO_LARGE"
    }else if(!imageFormat.test(value.attachment.name))
    {
        errors.attachment = "INVALID_IMAGE";

    }  
   }else{
    errors.attachment="REQUIRED";
   }



    return errors;
}
catch(err){
  
}
}

export const replyMsg = value => {
    let errors = {};

    if (isEmpty(value.message)) {
        errors.message = "REQUIRED"
    }

    // return errors;
}

export default Landingvalidation;