import React, { Component, useEffect, useState, useMemo } from 'react';
import { Tabs, Tab, Form } from 'react-bootstrap'
import Chart from "react-apexcharts";
import { getVolumeBreakdown } from 'actions/copyTradeAction';
import { filterSymbolData } from 'actions/copyTradeAction';
import { useSelector } from 'react-redux';
const Symbols = (props) => {

  const [symbolData, setSymbolData] = useState()
  const [ishistoryday, setIshistoryday] = useState(true);
  const [ishistorydate, setIshistorydate] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [day, setDay] = useState("");
  const [disablediv, setDisablediv] = useState(3);

  const { strategyDetails } = useSelector(state => state.copy)
  const { summaryDetails, TrdaerDetail, Followerdetails } = props
  // console.log('Symbols_strategyDetails',props);
  useEffect(() => {
    setSymbolData(props?.summaryDetails?.pairGroupData);
    // disableCheckDiv('check-api-radio21')
  }, [summaryDetails, TrdaerDetail, Followerdetails])

  const filterData = async (days, start, end) => {
    let payload = {}
    setEndDate(end)
    setStartDate(start)
    setDay(days)
    if (ishistoryday) {
      if (days) {
        payload.days = days
      }
    }
    if (ishistorydate) {
      if (start) {
        payload.startDate = start;
      }
      if (end) {
        payload.endDate = end;
      }
    }

    payload.day = days == "all" ? false : ishistoryday
    payload.date = days == "all" ? false : ishistorydate
    if (props.type == "copyier") {
      payload.type = 'copy'
      payload.traderAccountId = props?.TrdaerDetail.accountId
      payload.followerAccountId = props.Followerdetails?.followerAccountId
      // console.log("Symbols_filterData_copyier_payload", payload)
      const getFilterData = await filterSymbolData(payload);
      setSymbolData(getFilterData.result);
      // console.log("Symbols_filterData_copyier", getFilterData)
    }
    else {
      payload.accountId = props?.TrdaerDetail.accountId
      payload.userId = props?.TrdaerDetail.userId
      payload.type = props?.type
      const getFilterData = await filterSymbolData(payload);
      setSymbolData(getFilterData.result)
      // console.log("Symbols_filterData", getFilterData)
    }

  }



  // console.log("Symbols_props", symbolData)

  const disableCheckDiv = (e) => {
    if (e == "check-api-radio21") {
      document.getElementById("diable_check_id_21").classList.add("disable_chk");
      document.getElementById("diable_check_id_22").classList.remove("disable_chk");
      document.getElementById("check-api-radio22").classList.add("disable_chk");
      document.getElementById("check-api-radio21").classList.remove("disable_chk");
    }
    else if (e == "check-api-radio22") {
      document.getElementById("diable_check_id_22").classList.add("disable_chk");
      document.getElementById("diable_check_id_21").classList.remove("disable_chk");
      document.getElementById("check-api-radio21").classList.add("disable_chk");
      document.getElementById("check-api-radio22").classList.remove("disable_chk");
    }
  }
  const getmonth = (data) => {
    try {
      if (data == "Current Month") {
        return Date.now() - (86400000 * (new Date().getDate()))
      }
      if (data == "Last 2 Month") {
        return Date.now() - (86400000 * (new Date().getDate() + 30))
      }
      if (data == "Last 3 Month") {
        return Date.now() - (86400000 * (new Date().getDate() + 61))
      }
    } catch (err) {
      console.log('getmonth---err', err)
    }
  }

  return (
    <>
      <div className="d-flex jc-between gap-2 px-0 py-0">
        <div className="d-flex align-items-center gap-2">
          {/* {console.log('ishistorydateishistorydate',ishistorydate)} */}
          <div className="d-flex align-items-center gap-2 block_mob_res"
          // onChange={(e) => { disableCheckDiv(e.target.getAttribute("id")) }}
          >
            <p className="label_per_txt">Period</p>
            {/* <Form.Check type="radio" className="mb-0" >
              <Form.Check.Input id="check-api-radio21" type="radio" name="radio" isValid checked={ishistoryday}
                onChange={e => {
                  console.log("ishistorydateishistorydate RadioCheck",ishistoryday)
                  e.persist();
                  setIshistoryday(e.target.checked);
                  setIshistorydate(!e.target.checked);
                }} />
              <Form.Check.Label for="check-api-radio21" id='diable_check_id_22'> */}
            <div className={disablediv == 2 ? "disabled_div_new disabled_div_flex" : "disabled_div_flex"}>
              <div id="ele_3" className={ishistoryday ? 'check_cuts checked' : 'check_cuts'}
                onClick={() => {
                  setIshistoryday(true)
                  setIshistorydate(false);
                  setDisablediv(3);
                }}
              >
              </div>
              <select className="darkselect selectcount selctsm" onChange={(e) => { filterData(e.target.value, startDate, endDate) }}>
                <option value={Date.now() - (86400000 * 3)}>Last 3 Days</option>
                <option value={Date.now() - (86400000 * new Date().getDay())}>Current Week</option>
                <option value={Date.now() - (86400000 * (new Date().getDay() + 7))} >Last 2 Week</option>
                <option value={Date.now() - (86400000 * (new Date().getDay() + 14))}>Last 3 Week</option>
                <option value={getmonth("Current Month")}>Current Month</option>
                <option value={getmonth("Last 2 Month")}>Last 2 Month</option>
                <option value={getmonth("Last 3 Month")}>Last 3 Month</option>
                <option value='all' selected>All symbol data</option>
              </select>
            </div>
            {/* </Form.Check.Label>
            </Form.Check> */}
            <div className={disablediv == 3 ? "disabled_div_new disabled_div_flex" : "disabled_div_flex"}>
              <div id="ele_4" className={ishistorydate ? 'check_cuts checked' : 'check_cuts'}
                onClick={() => {
                  setIshistorydate(true)
                  setIshistoryday(false);
                  setDisablediv(2);


                }}
              ></div>
              {/* <Form.Check type="radio" className="mb-0">
              <Form.Check.Input id="check-api-radio22" type="radio" name="radio" isValid className="mb-0"
                checked={ishistorydate} onChange={e => { setIshistorydate(e.target.checked); setIshistoryday(false) }}
              />
              <Form.Check.Label for="check-api-radio22" className="mb-0" id='diable_check_id_21' > */}
              <div className="d-flex gap-2 tabinput p-0">
                <input type="date" className="themeinput py-1" max={new Date()} onChange={(e) => { setStartDate(day, e.target.value); filterData(day, e.target.value, endDate) }} />
                <input type="date" className="themeinput py-1" max={new Date()} onChange={(e) => { setEndDate(e.target.value); filterData(day, startDate, e.target.value) }} />
              </div>
              {/* </Form.Check.Label>
            </Form.Check> */}
            </div>
          </div>
        </div>


      </div>

      <div className="darkbox h-table-big">
        <div className="table-responsive symbols_table p-0">
          <table className="w-full">
            <tr>
              <th></th>
              <th colSpan={2} className='text-center'><label>Long</label></th>
              <th colSpan={2} className='text-center'><label>Short</label></th>
              <th colSpan={4} className='text-center'><label>Total</label></th>


            </tr>
            <tr>
              <td>
                Symbol
              </td>
              <td>
                Trades
              </td>
              <td>NetProfit</td>

              <td>
                Trades
              </td>
              <td>NetProfit</td>

              <td>
                Trades
              </td>
              <td>NetProfit</td>
              <td>Won(%)</td>
              <td>Loss(%)</td>

            </tr>

            {symbolData && symbolData.length > 0 ? symbolData.map((val, ind) => (
              <tr>
                <td>
                  {val._id}
                </td>
                <td>
                  {val.longTradeCount}
                </td>
                <td className="text-success">USD {parseFloat(val.longNetProfit).toFixed(2)}</td>

                <td>
                  {val.shortTradeCount}
                </td>
                <td>USD {parseFloat(val.shortNetProfit).toFixed(2)}</td>

                <td>
                  {val.tradeTotalCount}
                </td>
                <td className="text-success">USD {parseFloat(val.profit).toFixed(2)}</td>
                <td>
                  {val.allWonCount}({parseFloat(val.allWonPercentage).toFixed(2)}%)
                </td>
                <td>
                  {val.allLossTradeCount}({parseFloat(val.allLossPercentage).toFixed(2)}%)
                </td>
              </tr>
            )) : <></>
            }

          </table>
        </div>
      </div>
    </>
  )
}

export default Symbols