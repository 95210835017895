import React, { Component, useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap'
import Chart from "react-apexcharts";
import { DateRange } from '@material-ui/icons';
import { dateTimeFormat } from 'lib/dateTimeHelper';
import timezone from 'reducers/timezone';
import { getRioEquity } from 'actions/copyTradeAction';
import { getEquity } from 'actions/copyTradeAction';
const RoiEquidity = (props) => {
  // console.log('RoiEquidity_console',{props:props});
  const { TrdaerDetail, Followerdetails } = props
  const [minDate, setMinDate] = useState(0)
  const [chartDate, setChartDate] = useState([])
  const [equitySeries, setequitySeries] = useState([])
  const [minEquitySeries, setminEquitySeries] = useState([])
  const [maxEquitySeries, setmaxEquitySeries] = useState([])
  const [roiActive, setroiActive] = useState("one_months")
  const [equityActive, setequityActive] = useState("one_months")

  useEffect(() => {
    updateData('one_week');
  }, [TrdaerDetail, Followerdetails])

  const data = {
    options: {
      chart: {
        id: "chart1",
        type: "area",
        height: 350,
        foreColor: "#ccc",
        toolbar: {
          autoSelected: "pan",
          show: false
        }
      },
      colors: ["#00BAEC"],
      stroke: {
        width: 3
      },
      grid: {
        borderColor: "#555",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        gradient: {
          enabled: true,
          opacityFrom: 0.55,
          opacityTo: 0
        }
      },
      markers: {
        size: 5,
        colors: ["#000524"],
        strokeColor: "#00BAEC",
        strokeWidth: 3
      },
      tooltip: {
        theme: "dark",
        x: {
          format: 'dd MMM yyyy'
        }
      },
      yaxis: {
        tickAmount: 4
      },
      xaxis: {
        type: 'datetime',
        // min: minDate != 0 ? minDate : 1689273000000,
        min: minDate,
        max: new Date().getTime()
        // tickAmount: 7,
      },
    },
    series: [
      {
        name: 'ROI',
        data: chartDate
      },
    ]
  };


  const updateData = async (timeline) => {
    let getData
    // console.log("UpdateTimeLine", timeline)
    switch (timeline) {
      case 'one_week':
        let lastWeek = new Date()
        lastWeek.setDate(lastWeek.getDate() - 7)
        setMinDate(lastWeek.getTime())
        if (props.type == 'copyier') {
          getData = await getRioEquity({ traderAccountId: TrdaerDetail?.accountId, followerAccountId: props.Followerdetails?.followerAccountId, days: lastWeek.getTime(), type: 'copy' })
        } else {
          getData = await getRioEquity({ accountId: TrdaerDetail?.accountId, userId: TrdaerDetail?.userId, days: lastWeek.getTime(), type: props.type })
        }
        break
      case 'one_months':
        let lastMonth = new Date()
        lastMonth.setMonth(lastMonth.getMonth() - 1)
        setMinDate(lastMonth.getTime())
        if (props.type == 'copyier') {
          getData = await getRioEquity({ traderAccountId: TrdaerDetail?.accountId, followerAccountId: props.Followerdetails?.followerAccountId, days: lastMonth.getTime(), type: 'copy' })
        } else {
          getData = await getRioEquity({ accountId: TrdaerDetail?.accountId, userId: TrdaerDetail?.userId, days: lastMonth.getTime(), type: props.type })
        }
        break
      case 'two_months':
        let lastTwoMonth = new Date()
        lastTwoMonth.setMonth(lastTwoMonth.getMonth() - 2)
        setMinDate(lastTwoMonth.getTime())
        if (props.type == 'copyier') {
          getData = await getRioEquity({ traderAccountId: TrdaerDetail?.accountId, followerAccountId: props.Followerdetails?.followerAccountId, days: lastTwoMonth.getTime(), type: 'copy' })
        } else {
          getData = await getRioEquity({ accountId: TrdaerDetail?.accountId, userId: TrdaerDetail?.userId, days: lastTwoMonth.getTime(), type: props.type })
        }
        break
      case 'three_months':
        let lastThreeMonth = new Date()
        lastThreeMonth.setMonth(lastThreeMonth.getMonth() - 3)
        setMinDate(lastThreeMonth.getTime())
        if (props.type == 'copyier') {
          getData = await getRioEquity({ traderAccountId: TrdaerDetail?.accountId, followerAccountId: props.Followerdetails?.followerAccountId, days: lastThreeMonth.getTime(), type: 'copy' })
        } else {
          getData = await getRioEquity({ accountId: TrdaerDetail?.accountId, userId: TrdaerDetail?.userId, days: lastThreeMonth.getTime(), type: props.type })
        }
        break
      case 'six_months':
        let lastSixMonth = new Date()
        lastSixMonth.setMonth(lastSixMonth.getMonth() - 6)
        setMinDate(lastSixMonth.getTime())
        if (props.type == 'copyier') {
          getData = await getRioEquity({ traderAccountId: TrdaerDetail?.accountId, followerAccountId: props.Followerdetails?.followerAccountId, days: lastSixMonth.getTime(), type: 'copy' })
        } else {
          getData = await getRioEquity({ accountId: TrdaerDetail?.accountId, userId: TrdaerDetail?.userId, days: lastSixMonth.getTime(), type: props.type })
        }
        break
      case 'one_year':
        let lastYear = new Date()
        lastYear.setFullYear(lastYear.getFullYear() - 1)
        setMinDate(lastYear.getTime())
        if (props.type == 'copyier') {
          getData = await getRioEquity({ traderAccountId: TrdaerDetail?.accountId, followerAccountId: props.Followerdetails?.followerAccountId, days: lastYear.getTime(), type: 'copy' })
        } else {
          getData = await getRioEquity({ accountId: TrdaerDetail?.accountId, userId: TrdaerDetail?.userId, days: lastYear.getTime(), type: props.type })
        }
        break
      case 'all':
        if (props.type == 'copyier') {
          getData = await getRioEquity({ traderAccountId: TrdaerDetail?.accountId, followerAccountId: props.Followerdetails?.followerAccountId, days: 'all', type: 'copy' })
        } else {
          getData = await getRioEquity({ accountId: TrdaerDetail?.accountId, userId: TrdaerDetail?.userId, days: 'all', type: props.type })
        }
        break
      default:
    }
    setChartDate(getData.result)
    if (getData.min != undefined) { setMinDate(getData.min) }
    console.log("updateDataupdateData", getData)
  }

  const dataEquity = {
    options: {
      chart: {
        id: "chart1",
        type: "area",
        height: 350,
        foreColor: "#ccc",
        toolbar: {
          autoSelected: "pan",
          show: false
        }
      },
      colors: ["#00BAEC", '#4d3a96', '#FB0000'],
      stroke: {
        width: 3
      },
      grid: {
        borderColor: "#555",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        gradient: {
          enabled: true,
          opacityFrom: 0.55,
          opacityTo: 0
        }
      },
      markers: {
        size: 5,
        colors: ["#000524"],
        strokeColor: "#00BAEC",
        strokeWidth: 3
      },
      tooltip: {
        theme: "dark",
        x: {
          format: 'dd MMM yyyy'
        }
      },
      yaxis: {
        tickAmount: 4
      },
      xaxis: {
        type: 'datetime',
        min: minDate != 0 ? minDate : 1689273000000,
        max: new Date().getTime()
        // tickAmount: 7,
      },
    },
    series: [
      {
        name: 'Equity',
        data: equitySeries
      },
      {
        name: 'Minimum-Equity',
        data: minEquitySeries

      },
      {
        name: 'Maximum-Equity',
        data: maxEquitySeries

      },
    ]
  };

  const equity = async (timeline) => {
    let getData
    // console.log("equity_UpdateTimeLine", timeline)
    switch (timeline) {
      case 'one_week':
        let lastWeek = new Date()
        lastWeek.setDate(lastWeek.getDate() - 7)
        setMinDate(lastWeek.getTime())
        if (props.type == 'copyier') {
          getData = await getEquity({ traderAccountId: TrdaerDetail?.accountId, followerAccountId: props.Followerdetails?.followerAccountId, days: lastWeek.getTime(), type: 'copy', followerId: props?.Followerdetails?.followerId })
        } else {
          getData = await getEquity({ accountId: TrdaerDetail?.accountId, userId: TrdaerDetail?.userId, days: lastWeek.getTime(), type: props.type })
        }
        // getData = await getEquity({accountId: TrdaerDetail?.accountId, userId: TrdaerDetail?.userId, days:lastWeek.getTime() })
        break
      case 'one_months':
        let lastMonth = new Date()
        lastMonth.setMonth(lastMonth.getMonth() - 1)
        setMinDate(lastMonth.getTime())
        if (props.type == 'copyier') {
          getData = await getEquity({ traderAccountId: TrdaerDetail?.accountId, followerAccountId: props.Followerdetails?.followerAccountId, days: lastMonth.getTime(), type: 'copy', followerId: props?.Followerdetails?.followerId })
        } else {
          getData = await getEquity({ accountId: TrdaerDetail?.accountId, userId: TrdaerDetail?.userId, days: lastMonth.getTime(), type: props.type })
        }
        // getData = await getEquity({accountId: TrdaerDetail?.accountId, userId: TrdaerDetail?.userId, days:lastMonth.getTime() })
        break
      case 'two_months':
        let lastTwoMonth = new Date()
        lastTwoMonth.setMonth(lastTwoMonth.getMonth() - 2)
        setMinDate(lastTwoMonth.getTime())
        setMinDate(lastTwoMonth.getTime())
        if (props.type == 'copyier') {
          getData = await getEquity({ traderAccountId: TrdaerDetail?.accountId, followerAccountId: props.Followerdetails?.followerAccountId, days: lastTwoMonth.getTime(), type: 'copy', followerId: props?.Followerdetails?.followerId })
        } else {
          getData = await getEquity({ accountId: TrdaerDetail?.accountId, userId: TrdaerDetail?.userId, days: lastTwoMonth.getTime(), type: props.type })
        }
        // getData = await getEquity({accountId: TrdaerDetail?.accountId, userId: TrdaerDetail?.userId, days:lastTwoMonth.getTime() })
        break
      case 'three_months':
        let lastThreeMonth = new Date()
        lastThreeMonth.setMonth(lastThreeMonth.getMonth() - 3)
        setMinDate(lastThreeMonth.getTime())
        if (props.type == 'copyier') {
          getData = await getEquity({ traderAccountId: TrdaerDetail?.accountId, followerAccountId: props.Followerdetails?.followerAccountId, days: lastThreeMonth.getTime(), type: 'copy', followerId: props?.Followerdetails?.followerId })
        } else {
          getData = await getEquity({ accountId: TrdaerDetail?.accountId, userId: TrdaerDetail?.userId, days: lastThreeMonth.getTime(), type: props.type })
        }
        // getData = await getEquity({accountId: TrdaerDetail?.accountId, userId: TrdaerDetail?.userId, days:lastThreeMonth.getTime() })
        break
      case 'six_months':
        let lastSixMonth = new Date()
        lastSixMonth.setMonth(lastSixMonth.getMonth() - 6)
        setMinDate(lastSixMonth.getTime())
        if (props.type == 'copyier') {
          getData = await getEquity({ traderAccountId: TrdaerDetail?.accountId, followerAccountId: props.Followerdetails?.followerAccountId, days: lastSixMonth.getTime(), type: 'copy', followerId: props?.Followerdetails?.followerId })
        } else {
          getData = await getEquity({ accountId: TrdaerDetail?.accountId, userId: TrdaerDetail?.userId, days: lastSixMonth.getTime(), type: props.type })
        }
        // getData = await getEquity({accountId: TrdaerDetail?.accountId, userId: TrdaerDetail?.userId, days:lastSixMonth.getTime() })
        break
      case 'one_year':
        let lastYear = new Date()
        lastYear.setFullYear(lastYear.getFullYear() - 1)
        setMinDate(lastYear.getTime())
        if (props.type == 'copyier') {
          getData = await getEquity({ traderAccountId: TrdaerDetail?.accountId, followerAccountId: props.Followerdetails?.followerAccountId, days: lastYear.getTime(), type: 'copy', followerId: props?.Followerdetails?.followerId })
        } else {
          getData = await getEquity({ accountId: TrdaerDetail?.accountId, userId: TrdaerDetail?.userId, days: lastYear.getTime(), type: props.type })
        }
        // getData = await getEquity({accountId: TrdaerDetail?.accountId, userId: TrdaerDetail?.userId, days:lastYear.getTime() })
        break
      case 'all':
        if (props.type == 'copyier') {
          getData = await getEquity({ traderAccountId: TrdaerDetail?.accountId, followerAccountId: props.Followerdetails?.followerAccountId, days: 'all', type: 'copy', followerId: props?.Followerdetails?.followerId })
        } else {
          getData = await getEquity({ accountId: TrdaerDetail?.accountId, userId: TrdaerDetail?.userId, days: 'all', type: props.type })
        }
        // getData = await getEquity({accountId: TrdaerDetail?.accountId, userId: TrdaerDetail?.userId, days:'all' })
        break
      default:
    }
    setequitySeries(getData.result.equitySeries)
    setminEquitySeries(getData.result.minEquitySeries)
    setmaxEquitySeries(getData.result.maxEquitySeries)
    if (getData.min != undefined) { setMinDate(getData.min) }
    // console.log("equity_updateDataupdateData",getData)
  }

  const onselect = async (data) => {
    if (data == 'roi') {
      updateData('one_week')
    } else {
      equity('one_week')
    }
  }

  return (
    <>


      <Tabs defaultActiveKey="roi" id="uncontrolled-tab-example" className="graytab pt-2 px-0 tbas_bg_tran_new" onSelect={(e) => { onselect(e) }}>


        <Tab eventKey="roi" title="ROI (%)" className="px-0">

          <div className=''>
            <div className='btn_graph_tab pb-0 pr-0 pl-0'>
              <button className={roiActive == "one_week" ? "graytab tab_style_inner_grey pt-2 px-0 active" : "graytab tab_style_inner_grey pt-2 px-0"}
                onClick={() => { setroiActive("one_week"); updateData('one_week') }} >
                1w
              </button>
              <button className={roiActive == "one_months" ? "graytab tab_style_inner_grey pt-2 px-0 active" : "graytab tab_style_inner_grey pt-2 px-0"}
                onClick={() => { setroiActive("one_months"); updateData('one_months') }}  >
                1m
              </button>
              <button className={roiActive == "two_months" ? "graytab tab_style_inner_grey pt-2 px-0 active" : "graytab tab_style_inner_grey pt-2 px-0"}
                onClick={() => { setroiActive("two_months"); updateData('two_months') }}  >
                2m
              </button>
              <button className={roiActive == "three_months" ? "graytab tab_style_inner_grey pt-2 px-0 active" : "graytab tab_style_inner_grey pt-2 px-0"}
                onClick={() => { setroiActive("three_months"); updateData('three_months') }}  >
                3m
              </button>
              <button className={roiActive == "six_months" ? "graytab tab_style_inner_grey pt-2 px-0 active" : "graytab tab_style_inner_grey pt-2 px-0"}
                onClick={() => { setroiActive("six_months"); updateData('six_months') }}  >
                6m
              </button>
              <button className={roiActive == "one_year" ? "graytab tab_style_inner_grey pt-2 px-0 active" : "graytab tab_style_inner_grey pt-2 px-0"}
                onClick={() => { setroiActive("one_year"); updateData('one_year') }}  >
                1y
              </button>
              <button className={roiActive == "all" ? "graytab tab_style_inner_grey pt-2 px-0 active" : "graytab tab_style_inner_grey pt-2 px-0"}
                onClick={() => { setroiActive("all"); updateData('all') }}  >
                All
              </button>
            </div>
            <div>
              {chartDate && chartDate.length > 0 ?
                <Chart options={data?.options}
                  series={data?.series}
                  type="area" height={200} width="100%" className="px-0" /> :
                <div className='roiequidity_nodata_align'>
                  <div className='nodata'>No data found </div></div>
              }
            </div>
          </div>

        </Tab>


        <Tab eventKey="equity" title="Equity" className="px-0">
          <div className=''>
            <div className='btn_graph_tab pb-0 pr-0 pl-0'>
              <button className={equityActive == "one_week" ? "graytab tab_style_inner_grey pt-2 px-0 active" : "graytab tab_style_inner_grey pt-2 px-0"}
                onClick={() =>{setequityActive("one_week"); equity('one_week')}} >
                1w
              </button>
              <button className={equityActive == "one_months" ? "graytab tab_style_inner_grey pt-2 px-0 active" : "graytab tab_style_inner_grey pt-2 px-0"}
                onClick={() =>{setequityActive("one_months"); equity('one_months')}}  >
                1m
              </button>
              <button className={equityActive == "two_months" ? "graytab tab_style_inner_grey pt-2 px-0 active" : "graytab tab_style_inner_grey pt-2 px-0"}
                onClick={() =>{setequityActive("two_months"); equity('two_months')}}  >
                2m
              </button>
              <button className={equityActive == "three_months" ? "graytab tab_style_inner_grey pt-2 px-0 active" : "graytab tab_style_inner_grey pt-2 px-0"}
                onClick={() =>{setequityActive("three_months"); equity('three_months')}}  >
                3m
              </button>
              <button className={equityActive == "six_months" ? "graytab tab_style_inner_grey pt-2 px-0 active" : "graytab tab_style_inner_grey pt-2 px-0"}
                onClick={() =>{setequityActive("six_months"); equity('six_months')}}  >
                6m
              </button>
              <button className={equityActive == "one_year" ? "graytab tab_style_inner_grey pt-2 px-0 active" : "graytab tab_style_inner_grey pt-2 px-0"}
                onClick={() =>{setequityActive("one_year"); equity('one_year')}}  >
                1y
              </button>
              <button className={equityActive == "all" ? "graytab tab_style_inner_grey pt-2 px-0 active" : "graytab tab_style_inner_grey pt-2 px-0"}
                onClick={() =>{setequityActive("all"); equity('all')}}  >
                All
              </button>
            </div>
            <div>
              {
                equitySeries.length > 0 || minEquitySeries.length > 0 || maxEquitySeries.length > 0 ?

                  <Chart options={dataEquity?.options}
                    series={dataEquity?.series}
                    type="area" height={200} width="100%" className="px-0" /> : <div className='nodata'>No data found</div>
              }
            </div>

          </div>

        </Tab>
      </Tabs>
    </>
  )
}

export default RoiEquidity