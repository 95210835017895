// import config

import axios, { handleResp, } from '../config/axios';

// import action
import { setUserSetting } from './users';


// export const getCoinTransaction = async ()=>
// {
//     try{
//         let respData = await axios({
//             'method':'get',
//             'url':`api/allparticularcointransaction`
//         });
//         return{
//             status : "success",
//             result : respData.data.
//         }
//     }
// }

// export const gettradeHistory = async (filter) => {
//     try {
//       let respData = await axios({
//         method: "get",
//         url: `/api/gettradehistory_dash`,
//         params: filter,
//       });
//       return {
//         status: "success",
//         loading: false,
//         result: respData.data.result,
//         count: respData.data.count,
//         reportData: respData.data.reportData,
//       };
//     } catch (err) {
//         handleResp(err, 'error')
//       return {
//         status: "failed",
//         loading: false,
//       };
//     }
//   };
export const getallTransaction = async (data) => {
    // console.log('datadddddddddddddd live hihi', data)
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/alltransaction`,
            params: data,

        });
        return {
            status: "success",
            result: respData.data.result,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}



export const getalldemoTransaction = async (data) => {
    // console.log('data demo hihi', data)
    try {
        let respData = await axios({
            method: "get",
            'url': `/api/alldemotransaction`,
            params: data
        });
        return {
            status: 'success',
            result: respData.data.result,
        }
    }
    catch (err) {
        handleResp(err)
        return {
            status: 'failed',
            loading: false,
        }
    }
}
export const getTypeTransaction = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/alltypetransaction`,
            params: data,

        });
        return {
            status: "success",
            result: respData.data.result,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}
export const getRecentTransaction = async (data) => {
    // console.log('datgals---------', data)
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/recentTransaction`,
            params: data

        });
        // console.log('respData', respData)
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const getRecentdemoTransaction = async (data) => {
    // console.log('haii susu', data)
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/recentdemoTransaction`,
            params: data

        });
        // console.log('respData', respData)
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}
export const getLoginHistory = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/loginHistory`,
        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}
export const gettradeHistory = async (filter,type) => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/gettradehistory_dash`,
            params: filter,
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
            count: respData.data.count,
            reportData: respData.data.reportData,
        };
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
        };
    }
};

export const getreferralhistory = async (data) => {

    try {
        let respData = await axios({
            'method': "post",
            'url': '/api/getreferralhistory',
            data
        })

        return {
            status: "success",
            result: respData.data
        }

    }
    catch (err) {

    }
}




export const getreferraltree = async (data) => {

    try {
        let respData = await axios({
            'method': "post",
            'url': '/api/getreferraltrees',
            data
        })
        console.log("respDatarespDatarespData_respDatarespData",respData);
        return {
            status: "success",
            result: respData.data.result
        }
    }
    catch (err) {

    }
}

export const getNotificationHistory = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/notificationHistory`,
        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

/** 
 * Get User Balance
*/
export const getDashBal = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/getDashBal`,
        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

/** 
 * Update notification
*/
export const editNotif = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'put',
            'url': `/api/editNotif`,
            data
        });
        dispatch(setUserSetting(respData.data.result))
        // console.log(respData.data.message, 'respDatarespData')
        return {
            status: 'success',
            loading: false,
            message: respData.data.message,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return false
    }
}