import React, { useEffect, useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Tabs, Dropdown, InputGroup, Form, Tab } from 'react-bootstrap'
import { Startcopying } from 'actions/copyTradeAction';
import { toastAlert } from 'lib/toastAlert';
import AddFund from './AddFund';
import { StartcopyingValidation } from 'components/SportTrade/validation';
import Sidebar from '../Sidebar';
import isEmpty from 'lib/isEmpty';
import { TraderDetails } from 'actions/copyTradeAction';
const initialFormValue = {
    'accountData': {},
    'type': '',
    'leverage': '',
    'currency': 'USD',
    'amountInvestment': '',
    'minInvestment': '',
    'traderAccountId': '',
    'followerAccountId': ''
}
const StartCopying = (props) => {
    console.log("props_props_V", props);
    const [startCopying, setStartCopying] = useState(true)
    console.log("startCopying_V_startCopying", startCopying);
    const history = useHistory();
    const dispatch = useDispatch();
    const { useraccount, accounts } = useSelector(state => state.account)
    const [formValue, setFormValue] = useState(initialFormValue)
    console.log("formValueformValueformValue", formValue);
    const [addfund, setAddFund] = useState(false)
    const [datasvalue, setdatasvalue] = useState([])
    console.log("datasvaluedatasvalue_datasvalue", datasvalue);
    const [tradefollow, settradefollow] = useState()
    console.log("tradefollow_tradefollow_tradefollow", tradefollow);
    const [error, setError] = useState({})
    const { accountData, type, leverage, currency, amountInvestment, minInvestment, traderAccountId, followerAccountId } = formValue
    const { copyingData, startedAccount } = props
    console.log("copyingData_copyingData_V", copyingData);
    const kycStatus = useSelector(state => state.userKyc.Status);
    const { verifiedstatus, Synapsverify } = kycStatus
    // setdatasvalue(copyingData)
    const [Followers, setFollowers] = useState([])
    useEffect(() => {
        // console.log(props.copyingData, 'copyingData')
        if (props.copyingData) {
            let copyingData = props?.copyingData
            let formData = {
                ...formValue,
                ...{
                    'amountInvestment': copyingData?.minInvestment,
                    // 'accountData': SelectDisabled(useraccount) ? {} : useraccount,
                    'accountData': startedAccount,
                    'traderAccountId': copyingData.accountId,
                    'followerAccountId': startedAccount.accountId,
                    'type': startedAccount.type,
                    'leverage': startedAccount.leverage
                }
            };
            setFormValue(formData)
        }
        if (!copyingData.Followers) {
            getTradeDetails()
        } else {
            setFollowers(copyingData.Followers)
        }
    }, [copyingData, startedAccount])
    // useEffect(() => {
    //     if (Followers) {
    //         let FollowingData = Followers?.find((vals) => ((vals.followerAccountId).toLowerCase() == startedAccount.accountId.toLowerCase()))
    //         console.log(FollowingData, startedAccount, "FollowingData")
    //         if (FollowingData) {
    //             if (FollowingData.status == "inactive") {
    //                 if (!isEmpty(props?.restart)) {
    //                     props.restart(startedAccount.accountId, FollowingData._id)
    //                     props.onDismiss()
    //                 }
    //             }
    //             else if (FollowingData.status == "active") {
    //                 if (!isEmpty(props?.AddFund)) {
    //                     console.log("I am worked")
    //                     props.AddFund(startedAccount.accountId)
    //                     props.onDismiss()
    //                 }
    //             }
    //         }
    //     }
    // }, [Followers])
    const getTradeDetails = async () => {
        try {
            console.log("result?.Followers")
            let data = {
                traderAccountId: copyingData?.accountId,
                traderId: copyingData?.userId
            }
            let { status, result, message } = await TraderDetails(data)
            if (status) {
                console.log(result?.Followers,"result?.Followers")
                setFollowers(result?.Followers)
                // let FollowingData = result?.Followers?.find((vals) => ((vals.followerAccountId).toLowerCase() == startedAccount.accountId.toLowerCase()))
                // console.log(FollowingData, startedAccount, "FollowingData")
                // if (FollowingData) {
                //     if (FollowingData.status == "inactive") {
                //         if (!isEmpty(props?.restart)) {
                //             props.restart(startedAccount.accountId, FollowingData._id)
                //             props.onDismiss()
                //         }
                //     }
                //     else if (FollowingData.status == "active") {
                //         console.log("I am worked")
                //         if (!isEmpty(props?.AddFund)) {
                //             props.AddFund(startedAccount.accountId)
                //             props.onDismiss()
                //         }
                //     }
                // }
            }
            else {
                console.log(message, 'TradeDetail')
            }
        } catch (err) {
            console.log(err, 'getTradeDetails--err')
        }
    }
    const SelectDisabled = (val) => {
        try {
            let disable = false
            if (copyingData.allowCopying == 'Live and Demo' && val.balance >= copyingData.minInvestment) {
                return disable
            } else if (copyingData.allowCopying.toLowerCase() !== val.type || !(val.balance >= copyingData.minInvestment)) {
                console.log(copyingData.allowCopying.toLowerCase() !== val.type || !(val.balance >= copyingData.minInvestment), 'SelectDisabled')
                disable = true
                return disable
            } else {
                return disable
            }
        } catch (err) {
            console.log(err)
        }
    }

    const handleChange = (e) => {
        try {
            e.preventDefault();
            const { name, value } = e.target;
            // let val = value
            // console.log(name, JSON.parse(value), 'handleChange')
            if (name == 'accountData') {
                let val = JSON.parse(value)
                if (Followers) {
                    // console.log(name, 'handleChange', copyingData, copyingData.Followers, val.accountId, copyingData?.Followers?.find((val) => (val.followerAccountId == val.accountId)))
                    // setFollowers(copyingData.Followers)
                    let FollowingData = Followers?.find((vals) => ((vals.followerAccountId).toLowerCase() == val.accountId.toLowerCase()))
                    console.log(FollowingData, val, "FollowingData",Followers)
                    if (FollowingData) {
                        console.log("I am worked")
                        if (FollowingData.status == "inactive") {
                            if (!isEmpty(props?.restart)) {
                                props.restart(val.accountId, FollowingData._id)
                                props.onDismiss()
                            }
                        }
                        else if (FollowingData.status == "active") {
                            if (!isEmpty(props?.AddFund)) {
                                props.AddFund(val.accountId)
                                props.onDismiss()
                            }
                        }
                    }
                }
                let formData = {
                    ...formValue,
                    ...{
                        [name]: val,
                        'followerAccountId': val.accountId,
                        'type': val.type,
                        'leverage': val.leverage
                    }
                };
                setFormValue(formData)
                return
            }
            if (name == 'amountInvestment') {
                if (parseFloat(value) > parseFloat(accountData.balance)) {
                    setError({ "amountInvestment": "Insufficient balance" })
                } else {
                    setError({})
                }
            }
            let formData = { ...formValue, ...{ [name]: value } };
            setFormValue(formData)
            console.log(formValue, 'handleChange-->')
        } catch (err) {
            console.log(err, 'handleChange')
        }
    }
    const handleSubmit = async () => {
        try {
            if (verifiedstatus === 'PENDING') {
                toastAlert('error', "You are not able to trade until kyc is approved")
                props.onDismiss()
                return
            }
            else if (verifiedstatus === 'REJECTED' || verifiedstatus === 'Not verified') {
                toastAlert('error', "Please submit your kyc")
                props.onDismiss()
                return
            }
            let validationError = await StartcopyingValidation(formValue, copyingData)
            if (validationError) {
                console.log('handleSubmit-->', validationError)
                if (validationError.toast) {
                    toastAlert('error', validationError.toast, 'Startcopying')
                    return
                } else {
                    setError(validationError)
                    return
                }
            }
            console.log("formValue_formValue", formValue);
            let { status, message, error, result } = await Startcopying(formValue, dispatch);
            console.log("result_result_vsfas", result);
            if (status == 'success') {
                toastAlert('success', message, 'Startcopying')
                settradefollow(result)
                history.push({ pathname: "/copyingAccount", state: { strategydetails: copyingData, Followerdetails: formValue, accountId: formValue.followerAccountId } })
                props.onDismiss()
            } else {
                toastAlert('error', message, 'Startcopying')
            }
        } catch (err) {
            console.log(err)
        }
    }
    return (
        <>
            <Modal show={startCopying} className="modalbg font-12" size="md" centered scrolable>
                <Modal.Header className='align-items-center'>
                    <Modal.Title>Start Copying - TradingONE</Modal.Title>
                    <button className='closebtn btn btn-link text-light nounder' onClick={() => props.onDismiss()}>
                        <i className="fa fa-times fa-6" aria-hidden="true"></i>
                    </button>
                </Modal.Header>
                <Modal.Body className='font-12'>
                    <div className='themetab modal_align'>
                        <h5>Start Copying</h5>
                        <hr />
                        <div className=''>
                            <p>1. Account and Amount</p>
                            <ul className='pl-3'>
                                <li>
                                    <div className='d-flex jc-between align-items-center flex_mob_blk_new acoutn_long_inpt'>
                                        <p>Account</p>
                                        <select className='themeselect flex-05' name='accountData' onChange={handleChange}>
                                            {accounts && accounts.length > 0 && accounts.map((val, index) => (
                                                <option
                                                    value={JSON.stringify(val)}
                                                    selected={val.accountId == startedAccount.accountId ? true : false}
                                                    disabled={SelectDisabled(val)}
                                                >
                                                    {val.type} {val.accountId} - USD {val.balance.toFixed(4)} - {val.leverage}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <span className="text-danger">{error && error?.accountData}</span>
                                </li>
                                <li className='mt-1'>
                                    <div className='d-flex jc-between align-items-center flex_mob_blk_new'>
                                        <p>Amount</p>
                                        <div className='d-flex upload min_width_set_flex_inot_grp flrx_col_inf_ba'>

                                            <div className='d-flex align-items-center'>
                                                <div className='usd d-flex align-items-center'>USD <input name='amountInvestment' type="number" value={amountInvestment && amountInvestment} onChange={handleChange} /></div>
                                                <div className='icon_div_grey'
                                                    onClick={() => {
                                                        let formData = { ...formValue, ...{ 'amountInvestment': accountData.balance } };
                                                        setFormValue(formData)
                                                    }}>
                                                    <svg id="ic-max-amount" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
                                                        <path d="M6 4h7v1H6zM12 11L9.51 7.32 7 11h2v4h1v-4h2z"></path>
                                                    </svg>
                                                </div>
                                            </div>

                                            <span className="text-danger">{error && error?.amountInvestment}</span>

                                            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                        <path d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"/>
                                                    </svg> */}
                                        </div>
                                    </div>
                                </li>
                                <li className='mt-1'>
                                    <div className='d-flex jc-between align-items-center'>
                                        <p>Available Funds</p>
                                        <p>{accountData && accountData.currency} {accountData && accountData?.balance?.toFixed(4)}</p>

                                    </div>
                                </li>
                            </ul>
                        </div>
                        <hr />
                        <div className=''>
                            <p className='mb-2'>2. Fees and Conditions</p>
                            <ul className='pl-3'>
                                <li>
                                    <div className='d-flex jc-between align-items-center'>
                                        <p>Min. Investment</p>
                                        <p>{copyingData.currency} {copyingData.minInvestment}</p>
                                    </div>
                                </li>
                                {/* <li className='mt-1'>
                                        <div className='d-flex jc-between align-items-center'>
                                            <p>Performance Fee</p>
                                            <p>27.00%</p>
                                        </div>
                                    </li>
                                    <li className='mt-1'>
                                        <div className='d-flex jc-between align-items-center'>
                                            <p>Management Fee</p>
                                            <p>0.50% p.a.</p>
                                        </div>
                                    </li> */}
                                <li className='mt-1'>
                                    <div className='d-flex jc-between align-items-center'>
                                        <p>Volume Fee</p>
                                        <p>{copyingData.volumeFee}  %</p>
                                    </div>
                                </li>


                            </ul>
                            <hr />
                        </div>
                        {/* <Sidebar data ={tradefollow} activetabkey={"1"} ></Sidebar> */}
                        <div>
                            <p className='mb-3'>All the open positions of the strategy will be copied immediately</p>
                            <p className='mb-3'>Your Stop Out may differ from the Strategy Provider's Stop Out</p>
                            <p className='mb-3'>Equity Stop Loss = USD 50.00  will be automatically applied to your copy trading account</p>
                            {/* <p className='mb-3'>Please remember that you continue to be bound by the<a href="#"> End-User License Agreement for cTrader</a> , which you agreed to previously.</p> */}
                        </div>
                        <div className='d-flex align-items-center pb-3'>
                            <div className="d-flex jc-between align-items-center w-full flex1 curser" onClick={() => { }
                                // history.push({pathname:"/copyingAccount",state:{strategydetails:copyingData,Followerdetails:formValue}})
                            }
                            >
                                <button className='btn green-btn mr-2 w-full' onClick={() => {
                                    handleSubmit();
                                    // setStartCopying(false)
                                }}
                                >Start Copying</button>
                            </div>
                            <button className='btn gray-btn w-full' onClick={() => props.onDismiss()}>Cancel</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default StartCopying