import React, { Component, useEffect, useState ,useContext} from 'react';
import { InputGroup, Tab, FormControl, Form } from 'react-bootstrap'
import Chart from "react-apexcharts";
import setting from '../../../assets/images/newtrade/setting.svg'
import close from '../../../assets/images/newtrade/close.svg'
import { getuserTradeData } from 'actions/perpetualTradeAction';
import { getPairList } from 'actions/spotTradeAction';
import { useSelector } from 'react-redux';
import { capitalize } from '@material-ui/core';
import { momentFormat } from 'lib/dateTimeHelper';
import clsx from "classnames";
import { toFixed, toLocaleString, lotDisplay } from '../../../lib/roundOf'
import { getstrategyTradeData } from 'actions/perpetualTradeAction';
import { filterclosedposition } from 'actions/perpetualTradeAction';
import { filterclosedpositionstrategy } from 'actions/perpetualTradeAction';
import SocketContext from '../../Context/SocketContext';
import isEmpty from 'lib/isEmpty';
const History = (props) => {
  // console.log('History_props', props);
  const { TrdaerDetail,Followerdetails } = props
  const {accounts} = useSelector(state => state.account);
  const Type = localStorage.getItem("liveorDemo");
  const pairList = useSelector(state => state.pairList);
  const [historyData, setHistoryData] = useState([])
  const [pair, setPair] = useState([]);
  const [historysearch, setHistorysearch] = useState("");
  const [historyday, setHistoryday] = useState("");
  const [historystart, SetHistorystart] = useState("");
  const [historyend, setHistoryend] = useState("");
  const [ishistoryday, setIshistoryday] = useState(true);
  const [ishistorydate, setIshistorydate] = useState(false);
  const [Equtitypre, SetEqutityper] = useState(0)
  // const [PandLSingel, SetPandLSingel] = useState()
  const [Margin, setPositionMargin] = useState(0)
  const [freemagins, SetFreemargin] = useState(0)
  const [marginlevels, Setmarginlevels] = useState(0)
  const [profitandloss, SetProLoss] = useState(0)
  const [balance, setbalance] = useState(0)
  const [loadhistory, setLoadhistory] = useState(false)
  const [closedhistoryCnt,setClosedhistoryCnt] = useState(0)
  const [loadmoreloader,setloadmoreloader] =useState(false)
  const [closedpage,Setclosedpage] = useState(1)
  const socketContext = useContext(SocketContext)
  useEffect(() => {
    getProviderTradeData()
    getpairs()
    disableCheckDiv('check-api-radio')
  }, [TrdaerDetail,Followerdetails])

  useEffect(()=>{
    if(props.type == "provider" && !isEmpty(accounts)){
      let usedAccount = accounts?.find((val)=>(val.accountId==TrdaerDetail?.accountId))
      console.log(usedAccount,'usedAccount',accounts,TrdaerDetail?.accountId)
      if(!isEmpty(usedAccount)){
        setbalance(usedAccount?.balance)
      }
    }
  },[accounts,TrdaerDetail])

  useEffect(() => {
    socketContext.socket.on('positionmargin', (results) => {
      console.log('positionmargin__socket', results)
      try {
        // SetPandLSingel(results.prolss1)
        if (props.type == "strategy") {
          setbalance(results.balance)
        }
        SetEqutityper(results.equity)
        setPositionMargin(results.positionmargins)
        SetFreemargin(results.freemargin)
        Setmarginlevels(results.marginlevels)
        SetProLoss(results.prolss)
      } catch (err) {
        console.log(err, 'positionmargin__err')
      }
    })
  }, [socketContext.socket])
  const getProviderTradeData = async () => {
    try {
      if (props.type == "provider") {
        const reqData = { accountId: TrdaerDetail?.accountId }
        const { status, loading, result } = await getuserTradeData(reqData, Type);
        setHistoryData(result.closed_positions)
        setClosedhistoryCnt(result.closed_positions_count)
        if (result.closed_positions_count<=result.closed_positions) {
          setLoadhistory(false)
        }else{
          setLoadhistory(true)
        }
      }
      else if (props.type == "strategy") {
        const reqData = { accountId: TrdaerDetail?.accountId, user: TrdaerDetail?.userId }
        const { status, loading, result } = await getstrategyTradeData(reqData);
        // console.log("strategy_resultresult", result)
        setHistoryData(result.closed_positions)
        setClosedhistoryCnt(result.closed_positions_count)
        if (result.closed_positions_count<=result.closed_positions) {
          setLoadhistory(false)
        }else{
          setLoadhistory(true)
        }
      }else if(props.type == "copier"){
        const reqData = { 
          traderAccountId: TrdaerDetail?.accountId, 
          user: props?.Followerdetails.followerId,
          accountId:props?.Followerdetails.followerAccountId
         }
        const { status, loading, result } = await getstrategyTradeData(reqData);
        // console.log("strategy_resultresult", result)
        setHistoryData(result.closed_positions)
        setClosedhistoryCnt(result.closed_positions_count)
        if (result.closed_positions_count<=result.closed_positions) {
          setLoadhistory(false)
        }else{
          setLoadhistory(true)
        }
      }
    } catch (e) {
      console.log("getProviderTradeData", e)
    }
  }
  const getpairs = async () => {
    if (pairList.length > 0) {
      setPair(pairList)
    } else {
      const pairList = await getPairList();
      setPair(pairList.result)
    }
  }
  const getmonth = (data) => {
    try {
      if (data == "Current Month") {
        return Date.now() - (86400000 * (new Date().getDate()))
      }
      if (data == "Last 2 Month") {
        return Date.now() - (86400000 * (new Date().getDate() + 30))
      }
      if (data == "Last 3 Month") {
        return Date.now() - (86400000 * (new Date().getDate() + 61))
      }
    } catch (err) {
      console.log('getmonth---err', err)
    }
  }
  const handlehistoryfilter = async (search, day, start, end,closedpage) => {
    //console.log("handlehistory filter", search, day, start, end);

    if (ishistoryday && ishistorydate) { }
    setHistorysearch(search);
    setHistoryday(day);
    SetHistorystart(start);
    setHistoryend(end);
    var payload = {};
    if (search.length > 0) {
      payload.search = search;
    }
    if (ishistoryday) {
      payload.ishistoryday = ishistoryday
      if (day) {
        payload.days = day
      }
    }
    if (ishistorydate) {
      payload.ishistorydate = ishistorydate
      if (start) {
        payload.startdate = start;
      }
      if (end) {
        payload.enddate = end;
      }
    }
    payload.page = closedpage
    if (props.type == "provider") {
      payload.accountId = TrdaerDetail.accountId
      let filtereddata = await filterclosedposition(payload);
      // console.log("filtered data", filtereddata);
      setHistoryData(filtereddata?.result);
      setClosedhistoryCnt(filtereddata.count)
      if (filtereddata.count<=filtereddata.result.length) {
        setLoadhistory(false)
      }else{
        setLoadhistory(true)
      }
    } 
    else if (props.type == "strategy") {
      payload.accountId = TrdaerDetail.accountId
      payload.user = TrdaerDetail?.userId
      let filtereddata = await filterclosedpositionstrategy(payload);
      // console.log("filtered_data_strategy", filtereddata);
      setHistoryData(filtereddata?.result);
      setClosedhistoryCnt(filtereddata.count)
      if (filtereddata.count<=filtereddata.result.length) {
        setLoadhistory(false)
      }else{
        setLoadhistory(true)
      }
    }
    else if (props.type == "copier") {
      payload.traderAccountId = TrdaerDetail?.accountId
      payload.user = props?.Followerdetails.followerId
      payload.accountId = props?.Followerdetails.followerAccountId
      let filtereddata = await filterclosedpositionstrategy(payload);
      // console.log("strategy_resultresult", filtereddata)
      setHistoryData(filtereddata?.result)
      setClosedhistoryCnt(filtereddata.count)
      if (filtereddata.count<=filtereddata.result.length) {
        setLoadhistory(false)
      }else{
        setLoadhistory(true)
      }
    }

  }
  const handlehistoryLoadmore = async (search, day, start, end,closedpage) => {
    try{
    console.log("strategy_resultresult")
    setloadmoreloader(true)
    if (ishistoryday && ishistorydate) { }
    setHistorysearch(search);
    setHistoryday(day);
    SetHistorystart(start);
    setHistoryend(end);
    var payload = {};
    if (search.length > 0) {
      payload.search = search;
    }
    if (ishistoryday) {
      payload.ishistoryday = ishistoryday
      if (day) {
        payload.days = day
      }
    }
    if (ishistorydate) {
      payload.ishistorydate = ishistorydate
      if (start) {
        payload.startdate = start;
      }
      if (end) {
        payload.enddate = end;
      }
    }
    payload.page = closedpage
    if (props.type == "provider") {
      payload.accountId = TrdaerDetail.accountId
      let filtereddata = await filterclosedposition(payload);
      // console.log("filtered data", filtereddata);
      // setHistoryData(filtereddata?.result);
      if(filtereddata?.result.length > 0){
        setHistoryData([...historyData,...filtereddata?.result]);
        setClosedhistoryCnt(filtereddata.count)
        if (filtereddata.count<=filtereddata.result.length) {
          setLoadhistory(false)
        }else{
          setLoadhistory(true)
        }
      }  
    } 
    else if (props.type == "strategy") {
      payload.accountId = TrdaerDetail.accountId
      payload.user = TrdaerDetail?.userId
      let filtereddata = await filterclosedpositionstrategy(payload);
      // console.log("filtered_data_strategy", filtereddata);
      // setHistoryData(filtereddata?.result);
      if(filtereddata?.result.length > 0){
        setHistoryData([...historyData,...filtereddata?.result]);
        setClosedhistoryCnt(filtereddata.count)
        if (filtereddata.count<=filtereddata.result.length) {
          setLoadhistory(false)
        }else{
          setLoadhistory(true)
        }
      } 
    }
    else if (props.type == "copier") {
      payload.traderAccountId = TrdaerDetail?.accountId
      payload.user = props?.Followerdetails.followerId
      payload.accountId = props?.Followerdetails.followerAccountId
      let filtereddata = await filterclosedpositionstrategy(payload);
      console.log("strategy_resultresult", filtereddata)
      // setHistoryData(filtereddata?.result)
      if(filtereddata?.result.length > 0){
        setHistoryData([...historyData,...filtereddata?.result]);
        setClosedhistoryCnt(filtereddata.count)
        if (filtereddata.count<=filtereddata.result.length) {
          setLoadhistory(false)
        }else{
          setLoadhistory(true)
        }
      } 
    }
    setloadmoreloader(false)
  }catch(err){
    console.log(err,"handlehistoryLoadmore___err")
  }
  }
  const disableCheckDiv = (e) => {
    if (e == "check-api-radio") {
      document.getElementById("diable_check_id_2").classList.add("disable_chk");
      document.getElementById("diable_check_id_1").classList.remove("disable_chk");
      document.getElementById("check-api-radio1").classList.add("disable_chk");
      document.getElementById("check-api-radio").classList.remove("disable_chk");
    }
    else if (e == "check-api-radio1") {
      document.getElementById("diable_check_id_1").classList.add("disable_chk");
      document.getElementById("diable_check_id_2").classList.remove("disable_chk");
      document.getElementById("check-api-radio").classList.add("disable_chk");
      document.getElementById("check-api-radio1").classList.remove("disable_chk");
    }
  }
  return (
    <>
      <div className="d-flex jc-between gap-2 px-0 py-0">
        <div className="d-flex align-items-center gap-2 block_mob_res">
          <InputGroup className="themeinput" onChange={e => { handlehistoryfilter(e.target.value, historyday, historystart, historyend,1) }}>
            <FormControl placeholder="" />
            <InputGroup.Append>
              <InputGroup.Text id="basic-addon2"><span className="fa fa-search"></span></InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
          <div className="d-flex gap-2 block_mob_res" onChange={(e) => { disableCheckDiv(e.target.getAttribute("id")) }}>
            <Form.Check type="radio" className="mb-0" >
              <Form.Check.Input id="check-api-radio" type="radio" name="radio" isValid  checked={ishistoryday} onChange={e => {
                setIshistoryday(true);
                setIshistorydate(false);
              }} />
              <Form.Check.Label for="check-api-radio" id="diable_check_id_1">
                <select className="darkselect selectcount selctsm" onChange={e => handlehistoryfilter(historysearch, e.target.value, historystart, historyend,1)}>
                  <option value="">All History</option>
                  <option value={Date.now() - (86400000 * 3)}>Last 3 Days</option>
                  <option value={Date.now() - (86400000 * new Date().getDay())}>Current Week</option>
                  <option value={Date.now() - (86400000 * (new Date().getDay() + 7))} >Last 2 Week</option>
                  <option value={Date.now() - (86400000 * (new Date().getDay() + 14))}>Last 3 Week</option>
                  <option value={getmonth("Current Month")}>Current Month</option>
                  <option value={getmonth("Last 2 Month")}>Last 2 Month</option>
                  <option value={getmonth("Last 3 Month")}>Last 3 Month</option>
                </select>
              </Form.Check.Label>
            </Form.Check>
            <Form.Check type="radio" className="mb-0">
              <Form.Check.Input id="check-api-radio1" type="radio" name="radio" isValid className="mb-0"
                checked={ishistorydate} onChange={e => { setIshistorydate(e.target.checked); setIshistoryday(false) }} />
              <Form.Check.Label for="check-api-radio1" className="mb-0" id="diable_check_id_2">
                <div className="d-flex gap-2 tabinput p-0">
                  <input type="date" className="themeinput py-1" max={new Date()} onChange={e => handlehistoryfilter(historysearch, historyday, e.target.value, historyend,1)} />
                  <input type="date" className="themeinput py-1" max={new Date()} onChange={e => handlehistoryfilter(historysearch, historyday, historystart, e.target.value,1)} />
                </div>
              </Form.Check.Label>
            </Form.Check>
          </div>
        </div>
        <div className="d-flex">
          <div className="d-flex align-items-center">
            {loadhistory === true && closedhistoryCnt > historyData?.length ?
              loadmoreloader ?
                <button className="text-light btn m-0 py-1 px-3" disabled>
                  <i className="fa fa-spinner mr-2"></i>
                  <span>Loading..</span>
                </button>
                :
                <button className="text-light btn m-0 py-1 px-3" onClick={() => { 
                  handlehistoryLoadmore(historysearch, historyday, historystart, historyend, closedpage + 1) 
                }}>
                  Load More
                </button>
              : ""}
          </div>

          {/* <div className="d-flex align-items-center">
            <button className="text-light btn m-0 py-1 px-3 my-1" onClick={() => setDoneOrder(true)}>Done Orders</button>
          </div> */}
        </div>


      </div>

      <div className="darkbox h-table-big">
        <div className="table-responsive p-0">
          <table className="w-full">
            <tr>
              <th></th>
              <th><label>Created(UTC + 3)</label></th>
              <th><label>Symbol</label></th>
              <th><label>Direction</label></th>
              <th><label>Entry Price</label></th>
              <th><label>Closing Price</label></th>
              <th><label>Closing Quantity</label></th>
              <th><label>Gross P/L</label></th>
              <th><label>Swap</label></th>
              <th><label>Commission</label></th>
              <th><label>Net USD</label></th>
              <th><label>Balance USD</label></th>
              <th></th>
            </tr>

            {
              historyData && pair && historyData.length > 0 ? historyData.map((item, key) => {
                let pairDetail = pair && pair.length > 0 && pair?.find((value) => (value.tiker_root === item.pairname));
                let closingFee = parseFloat(item.closingFee) + parseFloat(item.commission)
                return (
                  <tr >
                    {/* <td>
                                    <button className="graybtn"><img src={setting} /></button>
                                  </td> */}
                    <td></td>
                    <td>{momentFormat(item.createdDate, 'DD-MM-YYYY HH:mm')}</td>
                    <td>{item.pairname}</td>
                    <td className={clsx({ "greenText": item.buyorsell === 'buy' }, { "redText": item.buyorsell == 'sell' })}>{capitalize(item.closing_direction)}</td>
                    <td>{(item?.entry_price == undefined || item?.entry_price == null || isNaN(item?.entry_price)) ? item?.entry_price : (parseFloat(item.entry_price.toFixed(pairDetail?.precision)))}</td>
                    <td>{(item?.exit_price == undefined || item?.exit_price == null || isNaN(item?.exit_price)) ? item?.exit_price : (parseFloat(item.exit_price.toFixed(pairDetail?.precision)))}</td>
                    <td>{(item?.quantity == undefined || item?.quantity == null || isNaN(item?.quantity)) ? item?.quantity : lotDisplay(item.quantity, pairDetail, item?.exit_price).toFixed(2)}</td>
                    <td className={clsx({ "greenText": item?.grossPandL >= 0 }, { "redText": item?.grossPandL < 0 })}>{(item?.grossPandL == undefined || item?.grossPandL == null || isNaN(item?.grossPandL)) ? new Number(0).toPrecision(3) : item?.grossPandL == 0 ? (new Number(item.grossPandL).toPrecision(3)) : parseFloat(item.grossPandL).toFixed(2)}</td>
                    <td className={clsx({ "greenText": item?.swapFee >= 0 }, { "redText": item?.swapFee < 0 })}>
                      {(item?.swapFee == undefined || item?.swapFee == null || isNaN(item?.swapFee))
                        ? new Number(0).toPrecision(3) :
                        item?.swapFee == 0 ?
                          (new Number(item.swapFee).toPrecision(3))
                          :
                          parseFloat(item.swapFee).toFixed(2)}
                    </td>
                    <td className={clsx({ "greenText": item?.closingFee >= 0 }, { "redText": item?.closingFee < 0 })}>
                      {((closingFee).toFixed(2) == 0 ?
                        new Number(closingFee).toPrecision(3) :
                        (closingFee).toFixed(2))}
                    </td>
                    <td className={clsx({ "greenText": item?.profitnloss >= 0 }, { "redText": item?.profitnloss < 0 })}>{(item?.profitnloss == undefined || item?.profitnloss == null || isNaN(item?.profitnloss)) ? new Number(0).toPrecision(3) : item?.profitnloss == 0 ? (new Number(item.profitnloss).toPrecision(3)) : parseFloat(item.profitnloss).toFixed(2)}</td>
                    <td>{(item?.afterBalance == undefined || item?.afterBalance == null || isNaN(item?.afterBalance)) ? item?.afterBalance : toLocaleString(parseFloat(item?.afterBalance).toFixed(2))}</td>
                    {/* <td>{toFixed(item?.afterBalance, 4)}</td> */}
                  </tr>
                )

              }) : <tr><td colSpan={12}><p className='text-center'>There is no data</p></td></tr>
            }
            {/* <tr>
              <td>
                <button className="graybtn"><img src={setting} /></button>
              </td>
              <td>
                10 Nov 2
              </td>
              <td>USDCAD</td>
              <td>0.01 Lots</td>
              <td>Buy</td>
              <td>1.2457</td>
              <td>-</td>
              <td>-</td>
              <td><span className="text-danger">-18.90</span></td>
              <td>
                <button className="bluebtn green"><img src={close} /></button>
              </td>
            </tr> */}


          </table>
        </div>
      </div>
      <div className="statlist ">
        <div className="">
          <label>Balance:</label>
          <p>USD  {props.type == "provider" ?
           balance && parseFloat(balance).toFixed(2) :
            props.type == "strategy" ?
            parseFloat(balance).toFixed(2) :
              props.type == "copier" ?
                parseFloat(props?.Followerdetails?.balanceInvestment).toFixed(2)
                :
                0}
          </p>
        </div>
        <div className="">
          <label>Equity:</label>
          <p>USD {Equtitypre ? toLocaleString(parseFloat(Equtitypre.toFixed(2))) : 0}</p>
        </div>
        <div className="">
          <label>Margin:</label>
          <p>USD {Margin ? toLocaleString(parseFloat(Margin.toFixed(2))) : 0}</p>
        </div>
        <div className="">
          <label>Free Margin:</label>
          <p>USD {freemagins ? toLocaleString(parseFloat(freemagins.toFixed(2))) : 0}</p>
        </div>
        <div className="">
          <label>Margin Leave:</label>
          <p>USD {marginlevels ? toLocaleString(parseFloat(marginlevels.toFixed(2))) : 0}</p>
        </div>
        {/* <div className="">
          <label>Smart Stop:</label>
          <p>USD {marginlevels ? toLocaleString(parseFloat(marginlevels.toFixed(2))) : 0}</p>
        </div> */}
        <div className="">
          <label>Unr. Net P&L:</label>
          <p className={clsx({ "greenText": profitandloss >= 0 }, { "redText": profitandloss < 0 })}>{profitandloss ? toLocaleString(parseFloat(profitandloss.toFixed(2))) : 0}</p>
        </div>
      </div>
    </>

  )
}

export default History