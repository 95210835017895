import React, { useEffect, useState, useContext } from 'react';
import { Modal } from 'react-bootstrap'
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from 'react-redux';
//action
import { addAccount, viewUserProfile } from 'actions/users';
import { toastAlert } from 'lib/toastAlert';

const ConfirmModal = (props) => {
    // console.log("props_propsprops", props);
    const [isShow, setShow] = useState(true)
    const [loader, setLoader] = useState(false)
    const OnSubmit = async (e) => {
        e.preventDefault();
        props.onCofirm(props.id)
        props.onDismiss()
    }

    return (
        <>
            <Modal show={isShow} className="modalbg informationmodal acc_modal font-12" size="md" centered scrolable>

                <Modal.Header className='align-items-center'>
                    <Modal.Title>{props.title}</Modal.Title>
                    <button className='closebtn btn btn-link text-light nounder' onClick={() => props.onDismiss()}>
                        <i className="fa fa-times fa-6" aria-hidden="true"></i>
                    </button>
                </Modal.Header>
                <Modal.Body className='font-12'>
                    <div>
                        <p>{props.message}</p>
                        {props && props?.message1 ? <p>{props.message1}</p> : ""}
                        {props && props?.message2 ? <p>{props.message2}</p> : ""}
                        <div className='d-flex align-iterms-center mt-2'>
                            {props.title == "Margin Level Alert" ?
                                <button className='btn green-btn w-full mr-2' onClick={() => { props.onDismiss() }}>Acknowledge</button> :
                                <>
                                    {loader && loader ?
                                        <button className="text-light btn m-0 py-1 px-3" disabled>
                                            <i className="fa fa-spinner mr-2"></i>
                                            <span>Loading..</span>
                                        </button> :
                                        <button className='btn green-btn w-full mr-2' onClick={(e) => { OnSubmit(e) }}>Confirm</button>}
                                    <button className='btn red-btn red-btn-dele w-full' onClick={() => { props.onDismiss() }}>Cancel</button>
                                </>
                            }

                            {/* <button className='btn green-btn w-full mr-2' onClick={(e) => { OnSubmit(e) }}>Confirm</button>
                            <button className='btn red-btn red-btn-dele w-full' onClick={() => { props.onDismiss() }}>Cancel</button> */}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ConfirmModal