import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ProgressBar from 'react-bootstrap/ProgressBar';
import ReactDatatable from '@ashvin27/react-datatable';
import isEmpty from "lib/isEmpty";
import { getallTransaction ,getalldemoTransaction } from "../actions/dashboardAction";
import { getTypeTransaction } from "../actions/dashboardAction";
import Pagination from "../components/Dashboard/pagination";
import CopyToClipboard from "react-copy-to-clipboard";

// import action
import { gettradeHistory } from "../actions/dashboardAction";
import { useDispatch, useSelector } from 'react-redux'

import btcIcon from '../assets/images/btcIcon.png'
// import lib
import { transactionStatus } from "../lib/displayStatus";
import { dateTimeFormat } from "../lib/dateTimeHelper";
import { toastAlert } from "lib/toastAlert";

const Transaction = () => {

  //redux-state
  const currencyOption = useSelector(state => state.currency)
  const {referralCode} = useSelector(state => state.auth);

  const accountData = useSelector(state => state.account);
  const {status,useraccount}=accountData

  

  //state
  const [data, setData] = useState([]);
  const [copy, setCopy] = useState('');
  const [reportData, setreportData] = useState([]);
  const[getdata , setGetdata] = useState({
    'transaction' : 'all'
  })
  const [filter, setFilter] = useState({
    'page': 1,
    'limit': 5,
    'type': 'all',
    'transaction' : 'all',
    'LiveorDemo' : 'LIVE',
    'AccountsId':useraccount && useraccount?.accountId
  })
// console.log('filterdata hihi',filter)

  const {LiveorDemo} = filter;

  //function
  function handlePagination(index) {
    let filterData = { ...filter, ...{ 'page': index.page_number, 'limit': index.page_size, } }
    setFilter(filterData);

  }

const setLiveorDemo = async(data)=>
{
  const formDatas = {...filter,...{'LiveorDemo':data}}
  setFilter(formDatas)
}



  const fetchTransaction = async () => {
    if(LiveorDemo == 'LIVE')
    {
      // console.log('hellodemdsdsd hihi')
    try {
let Data = { ...filter, ...{ 'AccountsId':useraccount && useraccount?.accountId} }

// console.log("Data----------",Data);
      const { status, loading, result, count, reportData } = await getallTransaction(Data);
      // console.log('dataaaaaaaaaaaaaaaaaaaa', result);
      // setLoader(loading);
      if (status == "success") {
        if (result && result && result.length > 0) {
          let resultArr = [];
          result.map((item) => {
            // console.log('resultshihi ---------',item)
            if (item.paymentType == 'crypto_withdraw'||item.paymentType == 'crypto_deposit' || item.paymentType == 'coin_deposit' || item.paymentType == 'coin_withdraw') {
              resultArr.push({
                'date': dateTimeFormat(item.createdAt, 'YYYY-MM-DD HH:mm'),
                'type': 'Crypto ' + transactionStatus(item.paymentType),
                'currency': item.coin,
                'amount': item.amount,
                'transRef': '-',
                'address': item.toAddress,
                'bankAccount': '-',
                'status': <div className="textStatusGreen">{item.status}</div>
              })
            }
            else {
              // console.log('enterq 1')
              resultArr.push({
                'date': dateTimeFormat(item.createdAt, 'YYYY-MM-DD HH:mm'),
                'type': 'Fiat ' + transactionStatus(item.paymentType),
                'currency': item.coin,
                'amount': item.amount,
                'transRef': item._id,
                'address': '-',
                'bankAccount': isEmpty(item.bankDetail) ? '-' : `${item.bankDetail.bankName}-${item.bankDetail.accountNo}`,
                'status': <div className="textStatusGreen">{item.status}</div>
              })
            }
          })
          setData(resultArr);
        }
        else
        {
          setData([]);
        }
        setreportData(reportData);
        // setcount(count)
      }

    } catch (err) { }
  }
  else if(LiveorDemo == 'DEMO')
  {
    console.log('hellodemo hihi')
    try{
      const { status, loading, result, count, reportData } = await getalldemoTransaction(filter);
      // console.log('dataaaaaaaaaaaaaaaaaaaa', result);
      if(status == 'success')
      {
        if(result && result && result.length > 0 )
        {
          let resultArr = [];
          result.map((item)=>
          {
            if(item.paymentType == 'crypto_withdraw'||item.paymentType == 'crypto_deposit')
            {
              resultArr.push({
                'date': dateTimeFormat(item.createdAt, 'YYYY-MM-DD HH:mm'),
                'type': 'Fiat ' + transactionStatus(item.paymentType),
                'currency': item.coin,
                'amount': item.amount,
                'transRef': item._id,
                'address': '-',
                'bankAccount': isEmpty(item.bankDetail) ? '-' : `${item.bankDetail.bankName}-${item.bankDetail.accountNo}`,
                'status': <div className="textStatusGreen">{item.status}</div>
              })
            }
            else if(item.paymentType == 'fiat_withdraw' || item.paymentType == 'fiat_deposit')
            {
              resultArr.push({
                'date': dateTimeFormat(item.createdAt, 'YYYY-MM-DD HH:mm'),
                'type': 'Fiat ' + transactionStatus(item.paymentType),
                'currency': item.coin,
                'amount': item.amount,
                'transRef': item._id,
                'address': '-',
                'bankAccount': isEmpty(item.bankDetail) ? '-' : `${item.bankDetail.bankName}-${item.bankDetail.accountNo}`,
                'status': <div className="textStatusGreen">{item.status}</div>
              })
            }
            else{
              resultArr.push({
                'date': dateTimeFormat(item.createdAt, 'YYYY-MM-DD HH:mm'),
                'type': 'Coin ' + transactionStatus(item.paymentType),
                'currency': item.coin,
                'amount': item.amount,
                'transRef': item._id,
                'address': '-',
                'bankAccount': isEmpty(item.bankDetail) ? '-' : `${item.bankDetail.bankName}-${item.bankDetail.accountNo}`,
                'status': <div className="textStatusGreen">{item.status}</div>
              })
            }
          })
           setData(resultArr)
        }
        else
        {
          setData([])
        }
      }
    }
    catch(err)
    {

    }
  }
  };





  const copyCode = (data) => {
    if (data) {
      toastAlert('success', 'Referral Code copied')
    }
    else {
      toastAlert('error', 'no respond can found')
    }


  }


  const getTransaction = async(data)=>{
    
    let filtertransactiondata = {...filter,...{'transaction': data}}
    setFilter(filtertransactiondata)
   }
 
  const setCurrency = async (data) => {
    let filteCurrencyData = { ...filter, ...{ 'type': data } }
    setFilter(filteCurrencyData);
  }

  const handleChanges = async (e) => {
    setCopy(e.target.value);
  }

  useEffect(() => {
    fetchTransaction();
  }, [filter,useraccount]);



  const columns = [
    {
      key: "date",
      text: "DATE",
      className: "request",
      align: "center",
      sortable: true
    },
    {
      key: "type",
      text: "TYPE",
      className: "request",
      align: "center",
      sortable: true
    },
    {
      key: "currency",
      text: "CURRENCY",
      className: "date",
      align: "center",
      sortable: true
    },
    {
      key: "amount",
      text: "AMOUNT",
      className: "status",
      align: "center",
      sortable: true
    },
    {
      key: "transRef",
      text: "TRANSACTION REF.",
      className: "id",
      align: "center",
      sortable: true,
    },
    {
      key: "bankAccount",
      text: "BANK & DETAILS",
      className: "id",
      align: "center",
      sortable: true,
    },
    {
      key: "status",
      text: "STATUS",
      className: "request",
      align: "center",
      sortable: true
    },
    // {
    //   key: "address",
    //   text: "ADDRESS",
    //   className: "id",
    //   align: "center",
    //   sortable: true,
    // },
  ];




  const config = {
    page_size: 5,
    length_menu: [5, 10, 20, 50],
    filename: "Order",
    no_data_text: 'No Records found!',
    button: {
      extra: true,
    },
    language: {
      length_menu: "Show _MENU_ Entries",
      filter: "Search",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: <i class="fa fa-backward" aria-hidden="true" />,
        previous: <i class="fa fa-chevron-left" />,
        next: <i class="fa fa-chevron-right" />,
        last: <i class="fa fa-forward" aria-hidden="true" />
      }
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: false,
  };
  const extraButtons = [
    {
      className : "btn extrabtn",
    //  title: "Export TEst",
      children: [
        <div class="dropdown">
          {/* <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {filter?.LiveorDemo ? filter?.LiveorDemo : 'LIVE'}
          </button> */}
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            {/* <a class="dropdown-item" href="#" onClick={()=>{setLiveorDemo('LIVE')}}>LIVE</a>
            <a class="dropdown-item" href="#" onClick={()=>{setLiveorDemo('DEMO')}}>DEMO</a> */}
          </div>
        </div>
      ],onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn extrabtn",
     // title: "Export TEst",
      children: [
        <div class="dropdown">
          <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {filter?.transaction}
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" href="#" hidden>all tranasaction</a>
            <a class="dropdown-item" href="#" onClick={()=>{getTransaction('all')}}>All</a>
            <a class="dropdown-item" href="#" onClick={()=>{getTransaction('deposit')}}>Deposit</a>
            <a class="dropdown-item" href="#" onClick={()=>{getTransaction('withdraw')}}>withdraw</a>
          </div>
        </div>
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    // {
    //   className: "btn extrabtn",
    //  // title: "Export TEst",
    //   children: [
    //     <div class="dropdown">
    //       <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    //         {filter.type}
    //       </button>

    //       <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
    //         <a class="dropdown-item" href="#" onClick={() => setCurrency('all')}>All</a>
    //         <a class="dropdown-item" href="#" onClick={() => { setCurrency('BTC') }}>BTC</a>
    //         <a class="dropdown-item" href="#" onClick={() => { setCurrency('INR') }}>INR</a>
    //         <a class="dropdown-item" href="#" onClick={() => { setCurrency('USD') }}>USD</a>
    //       </div>
    //     </div>
    //   ],
    //   onClick: (event) => {
    //     console.log(event);
    //   },
    // },

  ]


  const currentdata = [
    //{
    //   date: '12.08.2022',
    //   depositid: '123234',
    //   account: '23423423424',
    //   type: 'Loreum',
    //   source: 'Loreum',
    //   currency: 'BTC',
    //   amount: '$35356',
    //   status: 'Loreum',
    //   action: 'Loreum'
    // },
    // {
    //   date: '12.08.2022',
    //   depositid: '123234',
    //   account: '23423423424',
    //   type: 'Loreum',
    //   source: 'Loreum',
    //   currency: 'BTC',
    //   amount: '$35356',
    //   status: 'Loreum',
    //   action: 'Loreum'
    // },
    // {
    //   date: '12.08.2022',
    //   depositid: '123234',
    //   account: '23423423424',
    //   type: 'Loreum',
    //   source: 'Loreum',
    //   currency: 'BTC',
    //   amount: '$35356',
    //   status: 'Loreum',
    //   action: 'Loreum'
    // },
    // {
    //   date: '12.08.2022',
    //   depositid: '123234',
    //   account: '23423423424',
    //   type: 'Loreum',
    //   source: 'Loreum',
    //   currency: 'BTC',
    //   amount: '$35356',
    //   status: 'Loreum',
    //   action: 'Loreum'
    // },

  ];

  return (
    <div className='referrallist transaction'>
      <div className='container custom-container1'>
        <div className="headsec my-3">
          <div >
            <h5>Transaction</h5>
          </div>
          <div className="referralcodecopy">
                            <div > <label htmlFor="minimum">Referral code:</label></div>
                            <div  className="inputcopy"> <input name="minimumAmount" value={referralCode} type="text" className="form-control" onChange={(e) => handleChanges(e)} /><span><CopyToClipboard text={referralCode} onCopy={() => copyCode(referralCode)}><div><button class="fa fa-copy copybtn"> </button></div></CopyToClipboard></span>  </div>
                            
                        </div>


        </div>



        <div className='row my-3 px-3 px-md-0'>
          <div className="table-responsive">

            <ReactDatatable className="table table-bordered table-striped"
              config={config}
              records={data}
              columns={columns}
              dynamic={false}
              // total_record={count}
              onChange={handlePagination}
              extraButtons={extraButtons}
            />
          </div>
        </div>
      </div>

    </div>
  );

}

export default Transaction;