import React, { useEffect, useState, useContext } from 'react';
import { Modal, Tabs, Dropdown, InputGroup, Form, Tab } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux';
import SocketContext from '../../../Context/SocketContext';
import Wishlisttab from '../elements/Wishlisttab';
import Symboltab from "../elements/Symboltab";
import Infomation from './Information';
import { encryptObject } from '../../../../lib/cryptoJS'
import { orderPlace } from '../../../../actions/perpetualTradeAction';
import { toastAlert } from '../../../../lib/toastAlert';
import isEmpty from "lib/isEmpty";
import { capitalize } from '../../../../lib/stringCase';
import { inverseOrderCost, orderValue, EstMargin, SpreadCal } from '../../../../lib/bybit';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { momentFormat } from 'lib/dateTimeHelper';
import { toLocaleString, lotDisplay } from 'lib/roundOf';
const InfoOrder = (props) => {
    // const pairData = useSelector(state => state.tradePair)
    //   console.log(pairData,"paitData----------------->");
    const {useraccount} = useSelector(state => state.account);
    const socketContext = useContext(SocketContext)
    const [infoorder, setInfoOrder] = useState(true)
    const [Distance, setDistance] = useState(0)
    const [Markupprice, setmarkupprice] = useState({});
    const pairData = props.pairData
    let Datas = props.infodatas
    console.log("Datas_Datas",Datas);
    let lot = lotDisplay(Datas.quantity, pairData, Datas.price)

    console.log(lot, "lottttttttttttttttttttt");
    console.log("Datas----------", Datas);
    // let Markupprice = props.Markupprice
    let BUYORSELL = Datas.buyorsell == 'buy' ? 'Ask' : 'Bid'
    let CurrentVolume = Datas.filledQuantity - Datas.filledAmount
    //   if(Datas.price ="price")
    //   {
    //   let valPip =value * Math.pow(10,pairData.pip_position)
    //   let price =Datas.buyorsell == 'buy' ? Markupprice.bid * Math.pow(10,pairData.pip_position) : Markupprice.ask * Math.pow(10,pairData.pip_position)
    //   console.log(valPip,price,'Markupprice.markprice')
    //   let Distance = Math.abs(parseFloat(price) - parseFloat(valPip)).toFixed(1)
    //   setDistance(value== '' ? 0 :Distance)
    // }
    useEffect(()=>{
        calculateDistance(pairData)
    },[pairData])

    useEffect(()=>{
        socketContext.socket.on('perpetualmarketprice', async (result) => {
            try {
                if (result.tiker_root == pairData.tiker_root) {
                  setmarkupprice(result)
                  calculateDistance(result)
                }
            } catch (err) {
              console.log(err, 'perpetualmarketprice___err')
            }
          })
    },[socketContext.socket,useraccount])
    const calculateDistance = (Markupprice)=>{
        try{
            let wholePrice = Datas.price * Math.pow(10,pairData.precision)
            let LastPrice = Datas.buyorsell == 'buy' ? Markupprice.ask * Math.pow(10,pairData.precision) : Markupprice.bid*Math.pow(10,pairData.precision)
            
            let Distance = wholePrice - LastPrice
            console.log(Distance,'Distance',LastPrice,wholePrice,Markupprice.ask * Math.pow(10,pairData.precision),Markupprice.ask)
            setDistance(isNaN(Distance) ? 0 : Distance) 
        }catch(err){
            console.log(err,'calculateDistance__err')
        }
    }
    const FilledPriceCal = (filled) => {
        try {
            let filledPrice = filled.reduce((total, currentValue) => {
                return total = parseFloat(total) + parseFloat(currentValue.Price);
            }, 0)
            filledPrice = parseFloat(filledPrice) / filled.length
            return filledPrice
        } catch (err) {
            console.log(err,'FilledPriceCal___err')
        }
    }
    return (
        <>
            <Modal show={infoorder} className="modalbg informationmodal font-12" size="lg" centered scrolable>

                <Modal.Header className='align-items-center'>
                    <Modal.Title>Order Info {Datas._id}</Modal.Title>
                    <button className='closebtn btn btn-link text-light nounder' onClick={() => props.onDismiss()}>
                        <i className="fa fa-times fa-6" aria-hidden="true"></i>

                    </button>
                </Modal.Header>
                <Modal.Body className='font-12'>
                    <div className='themetab'>
                        <h5 className='mb-0'>{capitalize(Datas.orderType)} Order {Datas._id}  {Datas.pairName}</h5>
                        <h6 className='text_normal'>{pairData.description}</h6>
                        <hr />
                        <div>
                            <p className='mb-2'>Information</p>
                            <input className="form-control themeinput" disabled name="information" type="text" value={`${lot} Lot = ${Datas.quantity} , Lot Unit = ${Datas.firstCurrency} `}></input>

                            <hr />
                        </div>
                        <div className="d-flex flex-1 gap-2">
                            <div>
                                <p className="mb-2">Order ID</p>
                                <input className="form-control themeinput" name="orderid" disabled type="text" value={Datas._id} />
                            </div>
                            <div>
                                <p className="mb-2">Status</p>
                                <input className="form-control themeinput" name="status" disabled type="text" value={Datas.status} />
                            </div>
                        </div>
                        <div className='mt-2'>
                            <p className='mb-2'>Requesting Position</p>
                            <input className="form-control themeinput" disabled name="requestingposition" type="text" value="-"></input>

                            <hr />
                        </div>
                        <div className="d-flex flex-1 gap-2">
                            <div>
                                <p className="mb-2">Symbol</p>
                                <input className="form-control themeinput" name="symbol" disabled type="text" value={Datas.pairName ? Datas.pairName : "--"} />
                            </div>
                            <div>
                                <p className="mb-2">Direction</p>
                                <input className="form-control themeinput" name="direction" disabled type="text" value={Datas.buyorsell ? Datas.buyorsell : "--"} />
                            </div>
                        </div>

                        <div className="d-flex flex-1 gap-2 mt-2">
                            <div>
                                <p className="mb-2">Submitted Volume</p>
                                <input className="form-control themeinput" name="submittedvolume" disabled type="text" value={Datas.quantity ? Datas.quantity : "--"} />
                            </div>
                            <div>
                                <p className="mb-2">Current Volume</p>
                                <input className="form-control themeinput" name="currentdvolume" disabled type="text" value={Datas.quantity ?parseFloat(Datas.quantity) - parseFloat(Datas.filledAmount) : "--"} />
                            </div>
                        </div>

                        <div className="d-flex flex-1 gap-2 mt-2">
                            <div>
                                <p className="mb-2">Filled Volume</p>
                                <input className="form-control themeinput" name="filledvolume" disabled type="text" value={Datas.filledAmount ? toLocaleString(Datas.filledAmount) : "--"} />
                            </div>
                            <div>
                                <p className="mb-2">Order Type</p>
                                <input className="form-control themeinput" name="ordertype" disabled type="text" value={Datas.orderType ? Datas.orderType : "--"} />
                            </div>
                        </div>

                        <div className='mt-2'>
                            <p className='mb-2'>Filled Price</p>
                            <input className="form-control themeinput" disabled name="filledprice" type="text" value={Datas.filled?.length > 0 ? FilledPriceCal(Datas.filled) : "--"}></input>
                            <hr />
                        </div>

                        <div>
                            <p className='mb-2'>Submitted Time</p>
                            <input className="form-control themeinput" disabled name="submittedtime" type="text" value={momentFormat(Datas.orderDate, 'DD-MM-YYYY HH:mm')}></input>
                            {/*                    
                       <hr /> */}
                            {/* <div className="d-flex flex-1 gap-2"> */}
                            {/* <div>
        <p className="mb-2">Channel</p>
        <input className="form-control themeinput" name="channel" disabled type="text" value="Trader Web" />
    </div> */}
                            {/* <div>
        <p className="mb-2">Label</p>
        <input className="form-control themeinput" name="label" disabled  type="text" value="--" />
    </div> */}
                            {/* </div>
<p className='mb-2 mt-2'>Comment</p>
                        <input className="form-control themeinput" disabled name="comment" type="text" value="--"></input> */}

                            <hr />

                            <p className='text_heading_sub_modal'>{Datas.orderType} Order Details</p>

                            <div className="d-flex flex-1 gap-2 mt-2">
                                <div>
                                    <p className="mb-2">Submitted TIF</p>
                                    <input className="form-control themeinput" name="submittedTIF" disabled type="text" value="Good Till Cancel" />
                                </div>
                                <div>
                                    <p className="mb-2">Expiry</p>
                                    <input className="form-control themeinput" name="expiry" disabled type="text" value={Datas.expriy_date ? Datas.expriy_date : "--"} />
                                </div>
                            </div>

                            <div className="d-flex flex-1 gap-2 mt-2">
                                <div>
                                    <p className="mb-2">Submitted Price</p>
                                    <input className="form-control themeinput" name="submittedprice" disabled type="text" value={Datas.price ? Datas.price : "-"} />
                                </div>
                                <div>
                                    <p className="mb-2">Spot Price</p>
                                    <input className="form-control themeinput" name="spotprice" disabled type="text" value={Datas.buyorsell == 'buy' ? Markupprice.ask:Markupprice.bid} />
                                </div>
                            </div>

                            <div className="d-flex flex-1 gap-2 mt-2">
                                <div>
                                    {console.log('datage', Datas.buyorsell, BUYORSELL)}
                                    <p className="mb-2">Trigger Side</p>
                                    <input className="form-control themeinput" name="triggerside" disabled type="text" value={BUYORSELL} />
                                </div>
                                <div>
                                    <p className="mb-2">Current Distance</p>
                                    <input className="form-control themeinput" name="spotprice" disabled type="text" value={Distance} />
                                </div>
                            </div>

                            <div className="d-flex flex-1 gap-2 mt-2">
                                <div>
                                    <p className="mb-2">Submitted SL</p>
                                    <input className="form-control themeinput" name="submittedSL" disabled type="text" value={Datas.stopLossPrice ? Datas.stopLossPrice : "--"} />
                                </div>
                                <div>
                                    <p className="mb-2">Current SL</p>
                                    <input className="form-control themeinput" name="currentSL" disabled type="text" value={Datas.stopLossPrice ? Datas.stopLossPrice : "--"} />
                                </div>
                            </div>

                            <div className="d-flex flex-1 gap-2 mt-2">
                                <div>
                                    <p className="mb-2">Submitted TP</p>
                                    <input className="form-control themeinput" name="submittedTP" disabled type="text" value={Datas.takeProfitPrice ? Datas.takeProfitPrice : "--"} />
                                </div>
                                <div>
                                    <p className="mb-2">Current TP</p>
                                    <input className="form-control themeinput" name="currentTP" disabled type="text" value={Datas.takeProfitPrice ? Datas.takeProfitPrice : "--"} />
                                </div>
                            </div>
                        </div>


                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default InfoOrder