import React, { Component, useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Tabs, Tab } from 'react-bootstrap'
import Chart from "react-apexcharts";
import isEmpty from 'lib/isEmpty';

const Details = (props) => {
  const { strategyDetails } = useSelector(state => state.copy)

  console.log('new_provider', props, "-----------------");
  const {TrdaerDetail}=props
  const {useraccount} = useSelector(state => state.account)
  //state
  const [provider, setprovider] = useState({})
  //redux
  const strategy = useSelector(state => state.copy)
  useEffect(() => {
    setprovider(TrdaerDetail)
  }, [TrdaerDetail]);
  return (
    <div className="tab-content">
      <p className="boxheadings mb-1">Details</p>
      <div className="darkbox p-3">
        <ul className="pl-0 symbolTab_list">
          <li>
            <p className="headings">Strategy Details</p>
          </li>
          <li>
            <div className="d-flex p-0 justify-content-between">
              <small className="text-light">Currency</small>
              <small className="text-white">{provider && provider.currency} { useraccount && useraccount?.balance}</small>
            </div>
          </li>
          <li>
            <div className="d-flex p-0 justify-content-between">
              <small className="text-light">
                Leverage
              </small>
              <small className="text-white">{provider && !isEmpty(provider) ?  provider.leverage : 0}</small>
            </div>
          </li>
          <li>
            <p className="headings">Conditions</p>
          </li>
          <li>
            <div className="d-flex p-0 justify-content-between">
              <small className="text-light">
                Minimum Investment
              </small>
              <small className="text-white">{ provider && !isEmpty(provider) ?  provider.currency + " " +provider.minInvestment : 0} </small>
            </div>
          </li>
          {/* <li>
            <div className="d-flex p-0 justify-content-between">
              <small className="text-light">
                Performance Fee
              </small>
              <small className="text-white">30.00%</small>
            </div>
          </li>
          <li>
            <div className="d-flex p-0 justify-content-between">
              <small className="text-light">Management Fee</small>
              <small className="text-white">
                0.00% p.a.
              </small>
            </div>
          </li>
          <li>
            <div className="d-flex p-0 justify-content-between">
              <small className="text-light">
                Volume Fee
              </small>
              <small className="text-white">USD 0.00 per mil.</small>
            </div>
          </li> */}



        </ul>
      </div>

    </div>
  )
}

export default Details