//import package
import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';


//action
import { changePassword, changepwd } from "../actions/users";

//import lib
import validation from "../components/ChangePassword/validation"
import isEmpty from "lib/isEmpty";
import { toastAlert } from "lib/toastAlert";


const initialFormValue = {
  'oldPassword': "",
  'password': "",
  'confirmPassword': "",
}

const Changepwd = () => {
  const { t, i18n } = useTranslation();


  //state
  const [formValue, setFormValue] = useState({ initialFormValue })
  const [validateError, setValidateError] = useState({});
  const [loader, setLoader] = useState();
  const [isPasswordview,setIsPasswordview] = useState(false);
  const [isNewPasswordview,setIsNewPasswordview] = useState(false);
  const [isConfirmPasswordview,setIsConfirmPasswordview] = useState(false);

  
  const [isPasswordviewtwo,setIsPasswordviewtwo] = useState(false);
  const [isNewPasswordviewtwo,setIsNewPasswordviewtwo] = useState(false);
  const [isConfirmPasswordviewtwo,setIsConfirmPasswordviewtwo] = useState(false);

  const { oldPassword, password, confirmPassword } = formValue;

  //function
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } }
    setFormValue(formData)
    if (!isEmpty(validateError)) {
      setValidateError({})
    }
  }


  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoader(true)
    e.preventDefault();
    let reqData = {
      oldPassword,
      password,
      confirmPassword
    }
    let validationError = validation(reqData)
    console.log('validationerror', validationError)
    if (!isEmpty(validationError)) {
      setValidateError(validationError)
      setLoader(false)
      return
    }


    try {
      let { status, loading, error, message } = await changePassword(reqData);
      setLoader(loading)
      if (status == 'success') {
        setFormValue(initialFormValue)
        toastAlert('success', t(message), 'changepassword')
      }
      else {
        if (error) {
          setValidateError(error);
        }
        else if (message) {
          toastAlert('success', t(message), 'changepassword')
        }
      }
    }
    catch (err) {

    }
  }




  return (
    <div className='changepwd'>
      <div className='container custom-container1'>

        <div className="card text-white">


          <div className="card-body">
            <h5 className="mb-4">Change Password</h5>
            <div className="row mt-3">
              <div className="col-md-10">
                <form>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Old Password</label>
                    <div class="input-group input_grp_dashboard">
                 
                    <input type={isPasswordview ? "text":"password"} class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Old Password" name="oldPassword" value={oldPassword} onChange={(e) => handleChange(e)} />
                    <div class="input-group-append">
                    <div class="input-group-text cur_pointer_eye" onClick={()=>{setIsPasswordview(!isPasswordview)}}>
                      {isPasswordview?
                      <i class="fa fa-eye" aria-hidden="true" ></i>:
                      <i class="fa fa-eye-slash" aria-hidden="true"></i>
                      }
                    
                    </div>
                  </div>
                   </div>
                   {
                      validateError.oldPassword && <p className="error-message">{t(validateError.oldPassword)}</p>
                    }
                  </div>
                </form>

              </div>

            </div>
            <div className="row mt-3">
              <div className="col-md-10">
                <form>
                  <div class="form-group">
                    <label for="exampleInputEmail1">New Password</label>
                    <div class="input-group input_grp_dashboard">
                    <input type={isNewPasswordview ? "text":"password"} class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter New Password" name="password" value={password} onChange={(e) => handleChange(e)} />
                    <div class="input-group-append">
                    <div class="input-group-text cur_pointer_eye" onClick={()=>{setIsNewPasswordview(!isNewPasswordview)}}>
                      {isNewPasswordview?
                      <i class="fa fa-eye" aria-hidden="true" ></i>:
                      <i class="fa fa-eye-slash" aria-hidden="true"></i>
                      }
                    
                    </div>
                  </div>
                    </div>
                    {
                      validateError.password && <p className="error-message">{t(validateError.password)}</p>
                    }
                  </div>
                </form>

              </div>

            </div>
            <div className="row mt-3">
              <div className="col-md-10">
                <form>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Retype Password</label>
                    <div class="input-group input_grp_dashboard">
                    <input type={isConfirmPasswordview ? "text":"password"} class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Retype Password" name="confirmPassword" value={confirmPassword} onChange={(e) => handleChange(e)} />
                    <div class="input-group-append">
                    <div class="input-group-text cur_pointer_eye" onClick={()=>{setIsConfirmPasswordview(!isConfirmPasswordview)}}>
                      {isConfirmPasswordview?
                      <i class="fa fa-eye" aria-hidden="true" ></i>:
                      <i class="fa fa-eye-slash" aria-hidden="true"></i>
                      }
                    
                    </div>
                  </div>
                    </div>
                    {validateError.confirmPassword && <p className="error-message">{t(validateError.confirmPassword)}</p>
                    }
                  </div>
                </form>

              </div>

            </div>
            <div className="row mt-3">
              <div className="col-md-6 btncenter">
                <form>
                  <button type="button" className="btn btn-light mt-3" onClick={handleFormSubmit}>Update Password</button>
                </form>
              </div>

            </div>


          </div>

        </div>

        {/* <div className="card second text-white mt-5"> */}


          {/* <div className="card-body">
            <h5 className="mb-4">Change Trading Account Password</h5>
            <div className="row mt-3">
              <div className="col-md-10">
                <form>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Old Password</label>
                    <div class="input-group input_grp_dashboard">
                    <input type={isPasswordviewtwo ? "text":"password"} class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Old Password" />
                    <div class="input-group-append">
                    <div class="input-group-text cur_pointer_eye" onClick={()=>{setIsPasswordviewtwo(!isPasswordviewtwo)}}>
                      {isPasswordviewtwo?
                      <i class="fa fa-eye" aria-hidden="true" ></i>:
                      <i class="fa fa-eye-slash" aria-hidden="true"></i>
                      }
                    
                    </div>
                  </div>
                    </div>
                  </div>
                </form>
              </div>

            </div>
            <div className="row mt-3">
              <div className="col-md-10">
                <form>
                  <div class="form-group">
                    <label for="exampleInputEmail1">New Password</label>
                    <div class="input-group input_grp_dashboard">
                    <input type={isNewPasswordviewtwo ? "text":"password"} class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter New Password" />
                    <div class="input-group-append">
                    <div class="input-group-text cur_pointer_eye" onClick={()=>{setIsNewPasswordviewtwo(!isNewPasswordviewtwo)}}>
                      {isNewPasswordviewtwo?
                      <i class="fa fa-eye" aria-hidden="true" ></i>:
                      <i class="fa fa-eye-slash" aria-hidden="true"></i>
                      }
                    
                    </div>
                  </div>
                    </div>
                  </div>
                </form>
              </div>

            </div>
            <div className="row mt-3">
              <div className="col-md-10">
                <form>
                  <div class="form-group">
                  
                    <label for="exampleInputEmail1">Retype Password</label>
                    <div class="input-group input_grp_dashboard">
                    <input type={isConfirmPasswordviewtwo ? "text":"password"} class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Retype Password" />
                    <div class="input-group-append">
                    <div class="input-group-text cur_pointer_eye" onClick={()=>{setIsConfirmPasswordviewtwo(!isConfirmPasswordviewtwo)}}>
                      {isConfirmPasswordviewtwo?
                      <i class="fa fa-eye" aria-hidden="true" ></i>:
                      <i class="fa fa-eye-slash" aria-hidden="true"></i>
                      }
                    
                    </div>
                  </div>
                    </div>
                  </div>
                </form>
              </div>

            </div>
            <div className="row mt-3">
              <div className="col-md-6 btncenter">
                <form>
                  <button className="btn btn-light mt-3" >Update Password</button>
                </form>
              </div>

            </div>


          </div> */}

        {/* </div> */}



      </div>

    </div>
  );

}

export default Changepwd;