import React, { Component } from 'react';

const Phonenumberkyc = () => {

    return (
      <div className='phoneverifykyc'>
        <div className='logosec pt-5'>
         {/* <img src={require("../assets/images/logo.png")} alt="Banner" className="img-fluid logo_200" /> */}
         </div>
        <div className='row firstrow mx-auto mt-5'>
          <div className='col-md-5 col-lg-5 leftsec'>
            <h3 className='sectitle mt-5'>Phone Number</h3>
            <p  className='secpara'>Verify phone number for your KYC</p>
          </div>
          <div className='col-md-7 col-lg-6'>
          <div class="card" >
 
  <div class="card-body px-3 px-sm-5 py-5">
  <form class='col-12 mx-auto'>
  <div className='row mt-2 labelname'>
   
     <div className='wid_inp'>
   <div className="input-group mb-3">
    
    

  <input type="email" class="form-control" placeholder="Your Email Address" aria-label="Your Email Address" aria-describedby="basic-addon2"/>
      
     
  
  </div>
</div>

   </div>
   <div className='row mt-3 text-center'>
 
 <button
                                  className="btn btn-primary text-uppercase py-2 m-0"
                                  type="button"
                                  
                              >
                              PROCEED
                              </button>
                           
                              </div>
  </form>

  </div>
</div>
          </div>


</div>

        
      </div>
    );
  }

export default Phonenumberkyc;