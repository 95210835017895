import React, { useEffect, useContext, useState, useRef } from 'react'
import SocketContext from '../../Context/SocketContext';
import { momentFormat } from '../../../lib/dateTimeHelper'
import { toFixed, toLocaleString, lotDisplay } from '../../../lib/roundOf'
import isEmpty from 'lib/isEmpty';
import clsx from "classnames";
import { capitalize } from '../../../lib/stringCase';
import { useTranslation } from 'react-i18next';
import { getPorfitCalculation, quantitytovolume } from '../../../lib/bybit'
import { useSelector } from 'react-redux';
import PositionOrderInfo from '../newtrade/Modals/PositionOrderInfo'
import infoimg from '../../../assets/images/newtrade/info.svg'
const Positionorder = ({ val, pairDetail, handleMarketSubmit, loader, index, setpAndLarr, pAndLarr }) => {
  // const {val, pairDetail, handleMarketSubmit, loader,index,setpAndLarr,pAndLarr} = props
  const pairList = useSelector(state => state.pairList);
  const socketContext = useContext(SocketContext)
  const [Markupprice, setmarkupprice] = useState({})
  const [pAndL, setpAndL] = useState(val.pAndL)
  const pairName = useRef(val.pairName)
  const vals = useRef(val)
  const pAndLs = useRef(val.pAndL)
  const [ShowpositionOrderInfo, setShowpositionOrderInfo] = useState(false)
  // let  pAndL = 0
  const { t, i18n } = useTranslation();
  // console.log('Positionorder-perpetualmarketprice1',val.pAndL,pairName,Markupprice)
  // useEffect(() => {
  //   pairName.current = val.pairName
  //   vals.current = val
  //   calculatePAndL(pairDetail)
  // }, [val])
  // useEffect(() => {
  //   setmarkupprice({})
  //   socketContext.socket.on('perpetualmarketprice', async (result) => {
  //     // console.log('Positionorder-perpetualmarketprice',val,index,Markupprice)
  //     if (pairName.current == result.tiker_root) {
  //       // console.log('Positionorder-perpetualmarketprice2', result)
  //       setmarkupprice(result)
  //       calculatePAndL(result)
  //     }
  //   })
  // }, [socketContext.socket,val])

  const calculatePAndL = async (result) => {
    try {
      let arr = pAndLarr
      let colse_price = vals.current.buyorsell === 'buy' ? result.ask : result.bid
      let volume = quantitytovolume(vals.current.positionFilled, pairDetail, vals.current.price)
      let profit = await getPorfitCalculation(colse_price, vals.current.price, volume, pairDetail, vals.current.buyorsell, pairList)
      if (pairDetail.socketsymbol == 'AUDUSD' && vals.current.price == 0.6652) {
        console.log('getPorfitCalculation', profit)
      }
      pAndLs.current = profit
      setpAndL(profit)
      let obj = {
        pAndL: profit,
        pairName: pairDetail.tiker_root
      }
      arr[index] = obj
      setpAndLarr(arr)
      // console.log(pAndLarr,'pAndLarr',arr)
    } catch (err) {
      console.log(err, 'calculatePAndL-err')
    }
  }
  // console.log('pairDetail--Positionorder',val)
  return (
    <>
      {ShowpositionOrderInfo && <PositionOrderInfo pairData={pairDetail} InfoData={val} onDismiss={()=>{setShowpositionOrderInfo(false)}}/>}
      <tr>
        <td>
        <td><button className="graybtn" onClick={() => { setShowpositionOrderInfo(true) }}><img src={infoimg} /></button></td>
        </td>
        <td>{index + 1}</td>
        <td>{momentFormat(val.orderDate, 'DD-MM-YYYY HH:mm')}</td>
        <td>{val.pairName}</td>
        <td>{lotDisplay(val.positionFilled, pairDetail, val?.price).toFixed(2)}</td>
        <td className={clsx({ "greenText": val.buyorsell === 'sell' }, { "redText": val.buyorsell == 'buy' })}>{val.buyorsell == 'buy' ? capitalize('sell') : capitalize('buy')}</td>
        <td>{(val?.price == undefined || val?.price == null || isNaN(val?.price)) ? val?.price : (parseFloat(val?.price?.toFixed(pairDetail?.precision)))}</td>
        {/* <td>{!isEmpty(Markupprice) ? val.buyorsell === 'buy' ? Markupprice.ask : Markupprice.bid : val.buyorsell === 'buy' ? pairDetail.ask : pairDetail.bid}</td> */}
        <td>{val.close_price}</td>
        <td>{(val?.takeProfitPrice == undefined || val?.takeProfitPrice == null || isNaN(val?.takeProfitPrice)) ? 0 : val?.takeProfitPrice == 0 ? (new Number(val?.takeProfitPrice).toPrecision(3)) : (parseFloat(val?.takeProfitPrice).toFixed(pairDetail.precision))}</td>
        <td>{(val?.stopLossPrice == undefined || val?.stopLossPrice == null || isNaN(val?.stopLossPrice)) ? 0 : val?.stopLossPrice == 0 ? (new Number(val?.stopLossPrice).toPrecision(3)) : (parseFloat(val?.stopLossPrice).toFixed(pairDetail.precision))}</td>
        <td className={clsx({ "greenText": val.profitnloss > 0 }, { "redText": val.profitnloss < 0 })}>{(val.profitnloss == undefined || val.profitnloss == null || isNaN(val.profitnloss)) ? 0 : val.profitnloss === 0 ? (new Number(val.profitnloss).toPrecision(3)) : (parseFloat(val.profitnloss).toFixed(2))}</td>
        
        <td className={clsx({ "greenText": val.swapFee > 0 }, { "redText": val.swapFee < 0 })}>{(val?.swapFee == undefined || val?.swapFee == null || isNaN(val?.swapFee)) ?
            0
            : val?.swapFee == 0 ?
              (new Number(val?.swapFee).toPrecision(3)) :
              (parseFloat(val?.swapFee).toFixed(2))
          }
        </td>
        

        <td className={clsx({ "greenText": val.closingFee > 0 }, { "redText": val.closingFee < 0 })}>  {(val?.closingFee == undefined || val?.closingFee == null || isNaN(val?.closingFee)) ?
            0 :
            val?.closingFee == 0 ?
              (new Number(val?.closingFee).toPrecision(3)) :
              (parseFloat(val?.closingFee).toFixed(2))}
        </td>
        <td className={clsx({ "greenText": val.pAndL > 0 }, { "redText": val.pAndL < 0 })}>{(val.pAndL == undefined || val.pAndL == null || isNaN(val.pAndL)) ? 0 : val.pAndL === 0 ? (new Number(val.pAndL).toPrecision(3)) : (parseFloat(val.pAndL).toFixed(2))}</td>
        <td>
          <button type="button" onClick={() => { handleMarketSubmit(val, index) }} className="btn btn-primary text-uppercase py-2 btn-sm-blue-table btn_rem_focus">
            {(loader.status == true && loader.index == index) && <i class="fas fa-spinner fa-spin"></i>}
            {t("CLOSE")}
          </button>
        </td>
      </tr>
    </>
  )
}

export default Positionorder