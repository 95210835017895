import React, { useState } from 'react';
import {Modal,Tabs,Dropdown,InputGroup, Form, Tab} from 'react-bootstrap'

const DeleteInvestor = (props) => {
    console.log("props_v_props",props);
    const[deleteinvestor, setDeleteInvestor] = useState(true)
    return(
        <>
            <Modal show={deleteinvestor} className="modalbg font-12" size="md" centered scrolable>
   
            <Modal.Header className='align-items-center'> 
               <Modal.Title>Delete Investor Link - TradingOne</Modal.Title>
               <button className='closebtn btn btn-link text-light nounder' onClick={() => props.onDismiss()}>
               <i className="fa fa-times fa-6" aria-hidden="true"></i>

                </button>
            </Modal.Header>
                <Modal.Body className='font-12'>
                    <div className='themetab'>
                          
                                <h5>Delete Investor Link</h5>
                                <hr />
                            
                               
                              
                                <p className='my-2'>Are you sure you want to delete Investor Link? 
The users will not be able to view your Account Profile anymore.</p>
                                <div>
                                  
                                </div>


                                <div className='d-flex align-items-center pb-3'>
                                <button className='btn green-btn mr-2 w-full'>Delete</button>

                                <button className='btn red-btn red-btn-dele w-full'>Cancel</button>

                                </div>
                            
                             
                            
                                
                 
                         
                         
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default DeleteInvestor