import React, { useState,useEffect } from 'react';
import { InputGroup, Tabs, Tab, Form, Accordion, Card, Button, } from 'react-bootstrap'
import { getstocksymbolaction ,setCategory } from 'actions/currencycategoryAction';
import { getuserdatahook } from 'actions/currencycategoryAction';
import { Link, NavLink, useHistory,useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { userPair } from 'actions/users';
import isEmpty from 'lib/isEmpty';

const Symboltab = (props) => {
    const dispatch = useDispatch()
    const { isAuth } = useSelector(state => state.auth);
    const [pair, setPair] = useState([]);
    const [ocategory, setOcategory] = useState([]);
    const [userwishlist, setUserwishlist] = useState([])
    //console.log("userwishlist-------->>",userwishlist);
    const [isSearch,setIssearch]=useState(false)
    const [searchpair,setSearchpair]=useState([])
    const [search,setSearch]=useState()
    const [wishsearch, setWishsearch] = useState(false);
    const [searchwish, setSearchwish] = useState([])
    const [wished, setWished] = useState(false);
    const { tikerRoot } = useParams()
    const history = useHistory();
    const categoryPair = useSelector(state => state.categoryPair)
    // console.log(categoryPair, 'SET_CATEGORY_PAIR')
    const fetchWish = async () => {
        try {
            let wishlist = await getuserdatahook();
            console.log("wishlist in fetchdartasidebar22", wishlist, wishlist?.result?.wishlist);
            setUserwishlist(wishlist?.result?.wishlist);
            // var wish = []
            // await wishlist?.result?.wishlist.map((val, i) => {
            //     console.log("result", val);
            //     wish = wish.concat(val.wished);
            // })
            // console.log("wishlist in fetchdartasidebar", wish);
            // setWished(wish);
            localStorage.setItem("wishlist", JSON.stringify(wishlist?.result?.wishlist));
            // console.log("wishlist in symbol", wishlist?.result?.wishlist);
        } catch (err) {
            console.log(err, 'fetchWish')
        }
    }
    const handlePairChange = async (pairData) => {
        let pair = `${pairData.tiker_root}`
        localStorage.setItem('curpair',pair)
        history.push('/trade/' + pair)
        await userPair({pair:pair},dispatch)
        if (tikerRoot != pair) {
            //   await fetchAssetByCurrency(pairData.firstCurrencyId, 'firstCurrency')
            //   await fetchAssetByCurrency(pairData.secondCurrencyId, 'secondCurrency')
            //   await setMarketPrice(pairData, dispatch)
            //   await setTradePair(pairData, dispatch)
            //   socketContext.socket.off("marketPrice");
            //   socketContext.socket.off("orderBook");
            //   socketContext.socket.off("recentTrade");
        }
    }
    useEffect(() => {
        // console.log(categoryPair,categoryPair.length > 0,'SET_CATEGORY_PAIR1')
        if (categoryPair.length > 0) {
            setPair(categoryPair)
            // console.log(categoryPair,'SET_CATEGORY_PAIR1')
            var datatest = JSON.parse(localStorage.getItem("totalSymbols"));
            if(!datatest){
                localStorage.setItem("totalSymbols", JSON.stringify(categoryPair));
            }
            if(isAuth){
                fetchWish()
            }
            
        }
        if(isAuth){
            fetchWish()
        }
    }, [categoryPair])

    const handlesearch = async (search) => {
        setSearch(search)
        // let result = await filtersymboldatahook(data);
        // console.log("handlesearchresult" , result);
        // setPair(result?.result)
        if (search.length == 0) {
            setIssearch(false);
            // setPair(ocategory);
        }
        else if (search.length > 0) {
            setIssearch(true);
            var datatest = JSON.parse(localStorage.getItem("totalSymbols"));
            // var category = pair;
            console.log(" datacategory categories tts : ", datatest[0]?.categorydata)
            for (var i = 0; i < datatest.length; i++) {
                // for(var j =0 ; j < category[i]?.categorydata?.length ; j++){
                let data = datatest[i]?.categorydata
                console.log("datacategory", datatest, search, data);
                let result = await data.filter(value => ((value?.displayname)?.toLowerCase()).includes(search?.toLowerCase()));
                // console.log("result-->", result);
                datatest[i].categorydata = result;
                console.log("datacategory result", datatest);
                //  Object.assign(category[i],{"result" : result});
                // }
            }
            setSearchpair(datatest);
            console.log("datacategory categories []", searchpair);
        }

    }

    const handlesearch2 = async (search) => {
        try {
            setSearch(search)
            if (search.length == 0) {
                setWishsearch(false);
                // setPair(ocategory);
            }
            else if (search.length > 0) {
                setWishsearch(true);
                var wishdata = JSON.parse(localStorage.getItem("wishlist"));
                console.log(" wishdata_wishdata ", wishdata[0]?.wished)
                for (var i = 0; i < wishdata.length; i++) {
                    let data = wishdata[i]?.wished
                    console.log("dataname", wishdata, search, data);
                    let resultsearch = await data?.filter(value => ((value?.socketsymbol)?.toLowerCase()).includes(search?.toLowerCase()));
                    console.log("result", resultsearch);
                    wishdata[i].wished = resultsearch;
                    console.log("searching", wishdata);
                }
                setSearchwish(wishdata)
            }
        } catch (err) {
            console.log("error", err)
        }
    }

    return (
        <div>
            <Tabs defaultActiveKey="allsymbol" className="px-0">
                <Tab eventKey="watchlist" title="Wishlist" className='px-0'>
                    <div className='p-0'>
                        <InputGroup className="whishlitsearch p-0">
                            <Form.Control placeholder="" value={search} onChange={(e)=>{handlesearch2(e?.target?.value)}}/>
                            <InputGroup.Text className='search_text' id="basic-addon2"><span className='fa fa-search'></span></InputGroup.Text>
                        </InputGroup>
                    </div>
                    <div className='marketdrop scrolablediv px-0 symbol_tab_no_hover'>

                        {!wishsearch &&
                            <>
                            {userwishlist && userwishlist.length > 0 ? userwishlist.map((val, index) => {
                                return (
                                    <Accordion className='calendar_accordion p-0'>
                                        <Card>
                                            <Card.Header className='p-0'>
                                                <Accordion.Toggle as={Button} variant="link" eventKey='0'>
                                                    <div className="py-0 card_symbol_acc">
                                                        <button className='btn btn-link text-white card_symbol_acc'>{val.name}</button>
                                                    </div>
                                                </Accordion.Toggle>
                                            </Card.Header>

                                            <Accordion.Collapse eventKey='0'>
                                                <Card.Body className='p-0'>
                                                    <ul className='pl-0 symbolTab_list'>
                                                        {val.wished.map((lis, i) => {
                                                            let categoryData = categoryPair.find((val) => (val.categoryName == val.category))
                                                            let disable = isEmpty(categoryData) ? true : (lis.status == 'deactive') ? true : false
                                                            return (
                                                                <li className={disable ? "disabling_li" :""} onClick={() => { handlePairChange(lis); props.onClose() }}>{lis.socketsymbol}</li>
                                                            )
                                                        })}
                                                    </ul>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                )
                            }) : ""}
                            </>
                        }
                        {wishsearch &&
                            <>
                                {wishsearch && searchwish.length > 0 ? searchwish.map((val, index) => (
                                    <div className='calendar_accordion p-0'>
                                        <div className='p-0'>
                                            <div className='p-0'>
                                                <div className='p-0'>
                                                    <div className="py-0 card_symbol_acc">
                                                        <button className='btn btn-link text-white card_symbol_acc'>{val.name}</button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='p-0'>
                                                <div className='p-0'>
                                                    <ul className='pl-0 symbolTab_list'>
                                                        {val.wished.map((lis, i) => {
                                                             let categoryData = categoryPair.find((val) => (val.categoryName == val.category))
                                                             let disable = isEmpty(categoryData) ? true : (lis.status == 'deactive') ? true : false
                                                            return (
                                                                <li className={disable ? "disabling_li" :""} onClick={() => { handlePairChange(lis); props.onClose() }}>{lis.socketsymbol}</li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )) : ''}
                            </>
                        }
                            
                    </div>
                    
                </Tab>
                <Tab eventKey="allsymbol" title="All Symbols" className='px-0'>
                    <div className='p-0'>
                        <InputGroup className=" whishlitsearch p-0">
                            <Form.Control placeholder="" value={search} onChange={(e)=>{handlesearch(e?.target?.value)}}/>
                            <InputGroup.Text className='search_text' id="basic-addon2"><span className='fa fa-search'></span></InputGroup.Text>
                        </InputGroup>
                    </div>
                    <div className='marketdrop scrolablediv px-0'>
                        {!isSearch &&
                            <>
                                {pair && pair.length > 0 ? pair.map((val, index) => {
                                    return (
                                        <Accordion className='calendar_accordion p-0'>
                                            <Card>
                                                <Card.Header className='p-0'>
                                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                                        <div className="py-0 card_symbol_acc">

                                                            <button className='btn btn-link text-white card_symbol_acc'>{val.categoryName}</button>
                                                        </div>
                                                    </Accordion.Toggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="0">
                                                    <Card.Body className='p-0'>
                                                        <ul className='pl-0 symbolTab_list'>
                                                            {val.categorydata.map((lis, i) => {
                                                                let disable = lis.status == 'deactive' ? true : false
                                                                return (
                                                                    <li className={disable ? "disabling_li" :""} onClick={() => { handlePairChange(lis); props.onClose() }}>{lis.displayname}</li>
                                                                )
                                                            })}
                                                        </ul>

                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    )
                                }) : ''}
                            </>
                        }
                        {isSearch &&
                            <>
                                {isSearch && searchpair.length > 0 ? searchpair.map((val, index) => (
                                    <div className='calendar_accordion p-0'>
                                        <div className='p-0'>
                                            <div className='p-0'>
                                                <div variant="link" eventKey='0'  className='p-0'>
                                                    <div className="py-0 card_symbol_acc">
                                    
                                                        <button className='btn btn-link text-white card_symbol_acc'>{val.categoryName}</button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div eventKey='0' className='p-0'>
                                                <div className='p-0'>
                                                    <ul className='pl-0 symbolTab_list'>
                                                        {val.categorydata.map((lis, i) => {
                                                            let disable = lis.status == 'deactive' ? true : false
                                                            return (
                                                                <li className={disable ? "disabling_li" :""}  onClick={() => { handlePairChange(lis); props.onClose() }}>{lis.displayname}</li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )) : ''}
                            </>
                        }
                    </div>
                </Tab>
            </Tabs>
        </div>
    )
}
export default Symboltab
