// import config
import { data } from 'jquery';
import axios,{handleResp} from '../config/axios';

// import constant
import {
    SET_SPOT_TRADE_PAIR_LIST,
    SET_PERPETUAL_PAIR_TRADE,
    SET_PERPETUAL_MARKET_PRICE
} from '../constant';
let Type=localStorage.getItem("liveorDemo")

export const getPairList = async (type) => {
    try {
        // if(Type=="LIVE"){

        
        let respData = await axios({
            'method': 'get',
            'url': `/api/perpetual/tradePair`,
        });
        console.log('-----',respData.data.result)
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    // }else{
    //     let respData = await axios({
    //         'method': 'get',
    //         'url': `/api/perpetual/demotradePair`,
    //     });
    //     return {
    //         status: 'success',
    //         loading: false,
    //         result: respData.data.result,
    //     }

    // }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',

            loading: false,
        }
    }
}

export const setPairList = (data, dispatch) => {
    console.log("data-------------------->",data);
    dispatch({
        type: SET_SPOT_TRADE_PAIR_LIST,
        data
    })
}

export const setTradePair = ({
    firstCurrencySymbol,
    firstCurrencyId,
    firstFloatDigit,
    secondCurrencySymbol,
    secondCurrencyId,
    secondFloatDigit,
    botstatus,
    maker_rebate,
    taker_fees,
    firstCurrencyImage,
    _id,
}, dispatch) => {
    dispatch({
        type: SET_PERPETUAL_PAIR_TRADE,
        data: {
            firstCurrencyImage,
            firstCurrencySymbol,
            firstCurrencyId,
            firstFloatDigit,
            secondCurrencySymbol,
            secondCurrencyId,
            secondFloatDigit,
            botstatus,
            maker_rebate,
            taker_fees,
            pairId: _id
        }
    })
}

export const orderPlace = async (data) => {
    try {
      
       

        // if(Type=="LIVE"){
        let respData = await axios({
            'method': 'post',
            'url': `/api/perpetual/orderPlacing`,
            data
        });
        console.log("data---------3333333333>",respData);
        return {
            status: respData.data.status,
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
            key:  respData?.data?.key ? respData?.data?.key :''
        }
    // }
    // else{
    //     let respData = await axios({
    //         'method': 'post',
    //         'url': `/api/perpetual/demoorderPlacing`,
    //         data
    //     });
    //     return {
    //         status: 'success',
    //         loading: false,
    //         message: respData.data.message,
    //         result: respData.data.result,
    //     }
    // }
        
        
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: false,
            loading: false,
            message: err.response.data.message,
        }
    }
}

export const getOrderBook = async (data,type) => {
    console.log("data------------------------------------------",data);
    try {
        // if(Type=="LIVE"){
        let respData = await axios({

            'method': 'post',
            'url': `/api/perpetual/getTradeData`,
            data

        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    // }
    // else{
    //     let respData = await axios({

    //         'method': 'post',
    //         'url': `/api/perpetual/demogetTradeData`,
    //         data

    //     });
    //     return {
    //         status: 'success',
    //         loading: false,
    //         result: respData.data.result,
    //     }

    // }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const getuserTradeData = async (data,type) => {
//    console.log("type----------->>",type);
    // console.log("data-------------ssss>",data);
    try {
        // if(Type=="LIVE")
        // {
        let respData = await axios({
            'method': 'post',
            'url': `/api/perpetual/getuserTradeData`,
              data
        });
     
        return {
            status: 'success',
            loading: false,
            result: respData.data,
            
        }
    // }
    // else{
    //     let respData = await axios({
    //         'method': 'post',
    //         'url': `/api/perpetual/demogetuserTradeData`,
    //           data
    //     });
     
    //     return {
    //         status: 'success',
    //         loading: false,
    //         result: respData.data,
            
    //     }
    // }
    
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
    
}

export const getstrategyTradeData = async (data) => {
    try {
        {
            let respData = await axios({
                'method': 'post',
                'url': `/api/perpetual/getCopystrategyTradeData`,
                data
            });

            return {
                status: 'success',
                loading: false,
                result: respData.data,
            }
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }

}

export const allOpenOrder = async (data,type) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/perpetual/allOpenOrder`,
            'params': data
        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const allOpenOrderDoc = async (data,type) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/perpetual/allOpenOrderDoc`,
            'params': data
        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const getFilledOrder = async (data, pairId,type) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/perpetual/filledOrder/${pairId}`,
            'params': data
        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const getTradeHistory = async (data, pairId,type) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/perpetual/tradeHistory/${pairId}`,
            'params': data
        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const allTradeHist = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/perpetual/allTradeHist`,
            'params': data
        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}
export const histrade = async (data,type) => {
    // console.log("gdfgdbbd",data);
    try {
        // if(Type=="LIVE")
 
        // {
            // console.log("{gghvh");
        let respData = await axios({
            'method': 'get',
            'url': `/api/perpetual/histrade`,
            params: data,
        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
//     }else{
        
//             console.log("mnmnmnmnmnmnmnm");
//             let respData = await axios({
//                 'method': 'get',
//                 'url': `/api/perpetual/demohistrade`,
//                 params: data,
//             });
//             return {
//                 status: 'success',
//                 loading: false,
//                 result: respData.data.result,
//             }
    
// }
    } catch (err) {
        handleResp(err, ',,,,,,,,,,,,,,error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}


export const balances = async (data,types) => {
    // console.log("data->",data);
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/perpetual/balances`,
            'params': data
        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}







export const allTradeHistDoc = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/perpetual/allTradeHistDoc`,
            'params': data
        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const getPositionOrder = async (data,type) => {
    // console.log("xvxvcsdfvdfbhdghert",data);
    try {
        // if(Type=="LIVE"){
        let respData = await axios({
            'method': 'post',
            'url': `/api/perpetual/positionOrder`,
            data
        });
        console.log("getPositionOrder_details",respData)
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
            count:respData.data.count
        }
    // }
    // else{
    //     let respData = await axios({
    //         'method': 'post',
    //         'url': `/api/perpetual/demopositionOrder`,
    //         data
    //     });
    //     return {
    //         status: 'success',
    //         loading: false,
    //         result: respData.data.result,
    //     }
        
    // }
        
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
             }
    }
}


export const getStrategyPositionOrder = async (data, type) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/perpetual/strategypositionOrder`,
            data
        });
        console.log("getStrategyPositionOrder_details", respData)
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
            count: respData.data.count
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}



export const getMarketPrice = async (pairId, dispatch) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/perpetual/marketPrice/${pairId}`,
        });
        setMarketPrice(respData.data.result, dispatch)
        return true
    } catch (err) {
        handleResp(err, 'error')
        return false
    }
}

export const setMarketPrice = ({
    last,
    markPrice,
    low,
    high,
    firstVolume,
    secondVolume,
    changePrice,
    change,
    botstatus,
    _id,
}, dispatch) => {
    dispatch({
        type: SET_PERPETUAL_MARKET_PRICE,
        data: {
            last,
            markPrice,
            low,
            high,
            firstVolume,
            secondVolume,
            changePrice,
            change,
            botstatus,
            _id,
        }
    })
}

export const getRecentTrade = async (pairId,type) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/perpetual/recentTrade/${pairId}`,
        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const cancelOrder = async (data,type) => {
    // console.log("orderId,,,,,,,,,,,,,,,,,,",data);
    // console.log("typer88888",type);
    try {
        // if(Type=="LIVE"){
        
        
        let respData = await axios({
            'method': 'post',
            'url': `/api/perpetual/cancelTrade`,
            data
        });
        return {
            status: 'success',
            loading: false,
            message: respData.data.message,
        }
    // }else{
    //     let respData = await axios({
    //         'method': 'post',
    //         'url': `/api/perpetual/democancelTrade`,
    //         data
    //     });
    //     return {
    //         status: 'success',
    //         loading: false,
    //         message: respData.data.message,
    //     }

    // }
    } catch (err) {
        handleResp(err, 'errorhhhhh')
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message,
        }
    }
}

export const cancelAllOrder = async (data,type) => {
    // console.log("orderId,,,,,,,,,,,,,,,,,,",data);
    try {
        // if(Type=="LIVE"){
        
        
        let respData = await axios({
            'method': 'post',
            'url': `/api/perpetual/cancelAllTrade`,
            data
        });
        return {
            status: 'success',
            loading: false,
            respone: respData.data.respone,
        }
    // }else{
    //     let respData = await axios({
    //         'method': 'post',
    //         'url': `/api/perpetual/democancelTrade`,
    //         data
    //     });
    //     return {
    //         status: 'success',
    //         loading: false,
    //         message: respData.data.message,
    //     }

    // }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message,
        }
    }
}

export const getTradeData = async (data,type) => {
    // console.log("pairId---------->",data);
    try {
        // if(Type=="LIVE"){
        let respData = await axios({
            'method': 'post',
            'url': `/api/perpetual/getTradeData`,
            data
        });
        // console.log("resut------------------------<,--------->",respData.data);

        return {
            status: 'success',
            loading: false,
            result: respData.data,
        }
    // }
    // else{
    //     let respData = await axios({
    //         'method': 'post',
    //         'url': `/api/perpetual/demogetTradeData`,
    //         data
    //     });
    //     // console.log("resut------------------------<,--------->",respData.data);

    //     return {
    //         status: 'success',
    //         loading: false,
    //         result: respData.data,
    //     }
    // }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const getTransaction = async (data)=>{
    try {
        // if(Type=="LIVE")
        // {
        let respData = await axios({
            'method': 'post',
            'url': `/api/perpetual/getTransaction`,
            data
            
        });
        // console.log("resut-->",respData.data);

        return respData.data
    // }else{
    //     let respData = await axios({
    //         'method': 'post',
    //         'url': `/api/perpetual/demogetTransaction`,
    //          data
    //     });
    //     console.log("resut-->",respData.data,data);

    //     return respData.data  
    // }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}


export const filterPositionOrder = async (data) => {
    // console.log("xvxvcsdfvdfbhdghert",data);
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/perpetual/filterposition`,
            data
        });
        // console.log("result----------------------------->",respData);
    
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
        
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
             }
    }
}

export const filterPositionOrderstrategy = async (data) => {
    // console.log("xvxvcsdfvdfbhdghert",data);
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/perpetual/filterpositionstrategy`,
            data
        });
        // console.log("result----------------------------->",respData);
    
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
        
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
             }
    }
}


export const filterTransaction = async (data) => {
    // console.log("xvxvcsdfvdfbhdghert",data);
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/perpetual/filterTransaction`,
            data
        });
        console.log("result----------------------------->",respData);
    
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
            count : respData.data.count
        }
        
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
             }
    }
}


export const filterclosedposition = async (data) => {
    // console.log("xvxvcsdfvdfbhdghert",data);
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/perpetual/filterclosedposition`,
            data
        });
        console.log("result----------------------------->",respData);
    
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
            count : respData.data.count
        }
        
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
             }}}

export const filterclosedpositionstrategy = async (data) => {
    // console.log("xvxvcsdfvdfbhdghert",data);
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/perpetual/filterClosedPositionStrategy`,
            data
        });
        // console.log("result----------------------------->",respData);
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}


export const getcalenderData =async (data) =>{
   
    try{
       
        // console.log("getcalenderData_getcalenderData",data);
        // console.log("startDate_startDate",new Date(data.start));
        // console.log("EndDate_ENDDATE",new Date(data.end));
        // console.log("----------------------------------");

        let resDatas = await axios ({
            'method':'post',
            'url':`/api/perpetual/getcalenderData`,
            data : data
        })

        // console.log("resDatas-------",resDatas);
  
        return resDatas.data

    }catch(err){
 console.log(err,"errrrr");
    }
}

export const getMarketHours =async (data) =>{
   
    try{
       
        // console.log("getMarketHours_getMarketHours",data);
        let resDatas = await axios ({
            'method':'post',
            'url':`/api/perpetual/getMarketHours`,
            data : data
        })
        // console.log("resDatas----dfdd---",resDatas);
  
        return resDatas.data

    }catch(err){
 console.log(err,"errrrr");
    }
}

export const filterdoneorders = async (data) => {
    // console.log("xvxvcsdfvdfbhdghert", data);
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/perpetual/filterdoneorders`,
            data
        });
        // console.log("result----------------------------->",respData);

        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }

    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const priceConversion = async (data) =>{
    try{
        let respData = await axios({
            'method': 'post',
            'url': `/api/priceconvert`,
            data
        });
        return {
            status: respData.data.status,
            loading: false,
            result: respData.data.usdvalue,
        }
    }catch(err){
        console.log(err)
    }
}
export const priceConversiontosecond = async (data) =>{
    try{
        let respData = await axios({
            'method': 'post',
            'url': `/api/priceconvertsecond`,
            data
        });
        return {
            status: respData.data.status,
            loading: false,
            result: respData.data.usdvalue,
        }
    }catch(err){
        console.log(err)
    }
}

export const CloseAll = async(data)=>{
    try{
        let respData = await axios({
            'method': 'post',
            'url': `/api/perpetual/closeAll`,
            data
        });
        console.log(respData,'respData--CloseAll')
        return {
            respone:respData.data.respone
        }
    }catch(err){
        console.log(err)
        return {
            respone: err.response.data.response,
        }
    } 
}
export const historyloadmore = async (data)=>{
    console.log("respps",data)
    try{
        let respData = await axios({
            'method': 'post',
            'url': `/api/perpetual/historyloadmore`,
            data
        });
        console.log("respData",respData)
        return respData
       
    }catch(err){
        console.log(err)
    }

}

export const Transactionloadmore = async (data)=>{
    console.log("respps",data)
    try{
        let respData = await axios({
            'method': 'post',
            'url': `/api/perpetual/transactionloadmore`,
            data
        });
        console.log("respData",respData)
        return respData
       
    }catch(err){
        console.log(err)
    }

}

export const Orderloadmore = async (data)=>{
    console.log("resppsorder",data)
    try{
        let respData = await axios({
            'method': 'post',
            'url': `/api/perpetual/orderloadmore`,
            data
        });
        console.log("respData",respData)
        return respData
       
    }catch(err){
        console.log(err)
    }

}

export const Doneloadmore = async (data)=>{
    console.log("resppsorder",data)
    try{
        let respData = await axios({
            'method': 'post',
            'url': `/api/perpetual/doneloadmore`,
            data
        });
        console.log("respData",respData)
        return respData
       
    }catch(err){
        console.log(err)
    }

}