import {
    STRATEGY_DETAILS,
    FOLLOWERS_DETAILS,
    PROVIDER_SUMMARY
} from '../constant';

const initialValue = {
    strategyDetails:[],
    followerDetails:[],
    providersummary:{}
}

const CopyTradeReducer = (state = initialValue, action) => {
    console.log("action-ssssssss------->", action);
    switch (action.type) {
        case STRATEGY_DETAILS:
            return {
                ...state,
                strategyDetails: action.data
            };
        case FOLLOWERS_DETAILS:
            return {
                ...state,
                followerDetails: action.data
            };
        case PROVIDER_SUMMARY:
            return {
                ...state,
                providersummary: action.data
            };
        default:
            return state;
    }
}

export default CopyTradeReducer;