import React, { useEffect, useState, useContext } from 'react';
import { Modal } from 'react-bootstrap'
const ErrorModal = (props) => {
    const [isShow, setShow] = useState(true)
    // console.log(props.respone,'props.respone.')
    return (
        <div className='closed_modal'>
            <Modal show={isShow} className="modalbg informationmodal acc_modal font-12" size="md" centered scrolable>
                <Modal.Header className='align-items-center'>
                    <Modal.Title>Closed All position</Modal.Title>
                    <button className='closebtn btn btn-link text-light nounder' onClick={() => props.onDismiss()}>
                        <i className="fa fa-times fa-6" aria-hidden="true"></i>
                    </button>
                </Modal.Header>
                <Modal.Body className='font-12'>
                    <div className='table-responsive'>
                        <table className="table table-bordered table_error_pair">
                            <thead>
                                <tr>
                                    <th scope="col">S.No</th>
                                    <th scope="col">orderId</th>
                                    <th scope="col">Response</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props && props.respone.length > 0 && props.respone.map((item, index) =>
                                (
                                    <>
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{item.orderId}</td>
                                            <td>{item.message}</td>
                                        </tr>
                                    </>
                                )
                                )}
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        className='btn red-btn red-btn-dele w-full'
                        onClick={() => {props.onDismiss()}}
                    >
                        Close
                    </button>
                    {/* <button
                            onClick={this.handleClose}
                            className="btn btn-primary"
                            disabled={loader}
                        >
                            {loader && <i class="fas fa-spinner fa-spin"></i>}
                            Submit
                        </button> */}
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ErrorModal