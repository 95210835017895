import React, { useEffect, useContext } from "react";
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom';

// import components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";


// import context
import SocketContext from '../components/Context/SocketContext';

// import action
import { updateTradeAsset } from '../actions/spotTradeAction'

import CopyTradeProviderDetails from "components/SportTrade/CopyTradeProviderDetails";
import CopyTradeStrategy from "components/SportTrade/CopyTradeStrategy";
import CopyTradecopyingAccount from "components/SportTrade/CopyTradecopyingAccount";
// import CopyTrade from "components/SportTrade/newtrade/Modals/CopyTrade";

const dashboardRoutes = [];

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
const CopyingAccount = (props) => {
  const socketContext = useContext(SocketContext);
  const dispatch = useDispatch();
  const {state} = useLocation()
  const { ...rest } = props;
console.log("CopyingAccount",state);
  useEffect(() => {
    // socket
    socketContext.socket.on('updateTradeAsset', (result) => {
      updateTradeAsset(dispatch, result)
    })
  }, [])

  return (
    <div className="dashboard_container page_wrap">
      <ScrollToTopOnMount />
      <div className="dashboardMain">
        <div className="dashboardRight afterLoginHeader">
          <Header className="header"
            color="transparent"
            routes={dashboardRoutes}
            brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
            rightLinks={<HeaderLinksAfterlogin />}
            fixed
            changeColorOnScroll={{
              height: 20,
              color: "dark",
            }}
            {...rest} />
          <div className="settingsContent userPages tradePages">
            <CopyTradecopyingAccount />
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default CopyingAccount;