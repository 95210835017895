import React, { Component, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap'
import Chart from "react-apexcharts";
const PerformanceStat = (props) => {
  console.log('PerformanceStat', props);
  const { summaryDetails } = props
  return (
    <div className="tab-content">
      <p className="boxheadings mb-1">Performance Stats</p>
      <div className="darkbox p-3">
        <ul className="pl-0 symbolTab_list">
          <li className="boxheadings my-2">Trades</li>

            <li>
              <div className="d-flex p-0 justify-content-between">
                <small className="text-light">Total Trades</small>
                <small className="text-white">{summaryDetails ? summaryDetails?.count : 0}</small>
              </div>
            </li>
            <li>
              <div className="d-flex p-0 justify-content-between">
                <small className="text-light">
                  Winning Trades
                </small>
                <small className="text-white"> {summaryDetails ? summaryDetails?.profitCount : 0} ({summaryDetails ? summaryDetails?.winingPercentage.toFixed(4): 0}%)</small>
              </div>
            </li>
          
            <li>
              <div className="d-flex p-0 justify-content-between">
                <small className="text-light">
                  Long
                </small>
                <small className="text-white">{summaryDetails ?  summaryDetails?.longW : 0} ({summaryDetails ?  summaryDetails?.longWiningPercentage.toFixed(4): 0}%)</small>
              </div>
            </li>
            <li>
              <div className="d-flex p-0 justify-content-between">
                <small className="text-light">
                 Short
                </small>
                <small className="text-white">{summaryDetails ? summaryDetails?.shortW : 0} ({summaryDetails ?  summaryDetails?.shortWiningPercentage.toFixed(4): 0}%)</small>
              </div>
            </li>
            <li>
              <div className="d-flex p-0 justify-content-between">
                <small className="text-light">Losing Trades</small>
                <small className="text-white">
                {summaryDetails ?  summaryDetails?.lossCount : 0} ({summaryDetails ?  summaryDetails?.losingPercentage.toFixed(4): 0}%)
                </small>
              </div>
            </li>
            <li>
              <div className="d-flex p-0 justify-content-between">
                <small className="text-light">
                  Long
                </small>
                <small className="text-white">{summaryDetails ? summaryDetails?.longL : 0} ({summaryDetails ?  summaryDetails?.longLosingPercentage.toFixed(4): 0}%)</small>
              </div>
            </li>
            <li>
              <div className="d-flex p-0 justify-content-between">
                <small className="text-light">
                  Short
                </small>
                <small className="text-white">{summaryDetails ?  summaryDetails?.shortL: 0} ({summaryDetails ?  summaryDetails?.shortLosingPercentage.toFixed(4): 0}%)</small>
              </div>
            </li>
      
        
          </ul>
          </div>
          
          </div>
  )
}

export default PerformanceStat