// import lib
import isEmpty from '../../lib/isEmpty';
let imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG|BMP|bnp|SVG|svg)$/;

const validation = (value, callFrom) => {
    console.log("value---------------->",value);
    console.log("callFrom--->",callFrom);
    if (callFrom == 'createTicket') {
        return createTicket(value)
    } else if (callFrom == 'replyMsg') {
        return replyMsg(value)
    }
}

export const createTicket = value => {
    let errors = {};

    if (isEmpty(value.categoryId)) {
        errors.categoryId = "REQUIRED"
    }

    if (isEmpty(value.message)) {
        errors.message = "REQUIRED"
    }
    
   if(value.attachment && value.attachment.size){
    if(value.attachment.size > 25000000){
        errors.attachment="Please ensure that the file size does not exceed 25MB"
    }else if(!imageFormat.test(value.attachment.name))
    {
        errors.attachment = "INVALID_IMAGE2";

    }  
   }else{
    errors.attachment="REQUIRED";
   }



    return errors;
}

export const replyMsg = value => {
    let errors = {};

    if (isEmpty(value.message)) {
        errors.message = "REQUIRED"
    }

    // return errors;
}

export default validation;