import React, { useState, useEffect, useRef } from "react";
import Chart from "react-apexcharts";
import { momentFormat } from "lib/dateTimeHelper";
import { getProviderAllAccountDetails } from "actions/copyTradeAction";
import { Link, NavLink, useHistory } from 'react-router-dom';


const CopyProvidingAccounts = (props)=> {
    console.log("copyProvidingAccounts",props);
    const history = useHistory();

    const {details} = props
    const [providersData, setProvidersData] = useState([])
    // const data = {
    //   options: {
    //     chart: {
    //       id: "chart1",
    //       type: "area",
    //       height: 350,
    //       foreColor: "#ccc",
    //       background:"#3b3b3b",
    //       toolbar: {
    //         autoSelected: "pan",
    //         show: false
    //       }
    //     },
    //     markers: {
    //       size: 0,
    //       colors: undefined,
    //       strokeColors: '#fff',
    //       strokeWidth: 2,
    //       strokeOpacity: 0.9,
    //       strokeDashArray: 0,
    //       fillOpacity: 1,
    //       discrete: [],
    //       shape: "circle",
    //       radius: 2,
    //       offsetX: 0,
    //       offsetY: 0,
    //       onClick: undefined,
    //       onDblClick: undefined,
    //       showNullDataPoints: true,
    //       hover: {
    //         size: undefined,
    //         sizeOffset: 0
    //       }
    //   },
    //     colors: ["#00BAEC"],
    //     stroke: {
    //       width: 3
    //     },
    //     grid: {
    //       borderColor: "#555",
    //       clipMarkers: false,
    //       yaxis: {
    //         lines: {
    //           show: true
    //         }
    //       },
    //       xaxis: {
    //         lines: {
    //           show: true
    //         }
    //       }
    //     },
    //     dataLabels: {
    //       enabled: false
    //     },
    //     fill: {
    //       gradient: {
    //         enabled: true,
    //         opacityFrom: 0.55,
    //         opacityTo: 0
    //       }
    //     },
    //     markers: {
    //       size: 5,
    //       colors: ["#000524"],
    //       strokeColor: "#00BAEC",
    //       strokeWidth: 3
    //     },
    //     yaxis: {
    //       tickAmount: 4,
    //       labels: {
    //         show: false
    //       },
    //       tooltip: {
    //         enabled: false
    //       }
    //     },
    //     xaxis: {
    //       type: 'datetime',
    //       min: 1687962272801,
    //       max: new Date().getTime(),
    //       show: false,
    //       labels: {
    //         show: false
    //       },
    //       axisBorder: {
    //         show: false
    //       },
    //       axisTicks: {
    //         show: false
    //       },
    //       tooltip: {
    //         enabled: false
    //       }
    //     },
    //   },
    // };
    const data = {
      options: {
        chart: {
          id: "chart1",
          type: "area",
          height: 350,
          foreColor: "#ccc",
          background:"#3b3b3b",
          toolbar: {
            autoSelected: "pan",
            show: false
          }
        },
        colors: ["#00BAEC"],
        stroke: {
          width: 3
        },
        grid: {
          borderColor: "#555",
          clipMarkers: false,
          yaxis: {
            lines: {
              show: true
            }
          },
          xaxis: {
            lines: {
              show: true
            }
          }
        },
        markers: {
          size: 0,
          colors: undefined,
          strokeColors: '#fff',
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [],
          shape: "circle",
          radius: 2,
          offsetX: 0,
          offsetY: 0,
          onClick: undefined,
          onDblClick: undefined,
          showNullDataPoints: true,
          hover: {
            size: undefined,
            sizeOffset: 0
          }
      },
        dataLabels: {
          enabled: false
        },
        fill: {
          gradient: {
            enabled: true,
            opacityFrom: 0.55,
            opacityTo: 0
          }
        },
        // markers: {
        //   size: 5,
        //   colors: ["#000524"],
        //   strokeColor: "#00BAEC",
        //   strokeWidth: 3
        // },
        yaxis: {
          tickAmount: 4,
          labels: {
            show: false
          },
          tooltip: {
            enabled: false
          }
        },
        xaxis: {
          type: 'datetime',
          min: 1687962272801,
          max: new Date().getTime(),
          show: false,
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          tooltip: {
            enabled: false
          }
        },
      },
    };
      useEffect(()=> {
        getAllAccountDatas()
      },[props])

      const getAllAccountDatas = async () => {
        const getData = await getProviderAllAccountDetails({userId: props?.details?.userId});
        setProvidersData(getData.result)
        console.log("getAllAccountDatas",getData.result)
      }
    return(
        
        <div className="darkbox" onClick={() => history.push({ pathname: "/strategy", state: {strategydetails:details} })}>
          <h5 className="my-0">{details.userData[0].firstName == "" ? details.strategyName : details.userData[0].firstName}</h5>
          <div className="d-lg-flex flex-1">
            <div className="mb-lg-0 mb-2">
              <div className="p-0">
                <div className="d-flex jc-between flex1 p-0">
                  <p className="lightgray nowrap">Total Copying Capital (Live):</p>
                  <p className="nowrap">USD {details.totalCopyingCapital ? details.totalCopyingCapital : 0}</p>
                </div>
                <hr className="themehr" />
                <div className="d-flex jc-between p-0">
                  <p className="lightgray nowrap">Active since:</p>
                  <p className="nowrap">{details.createdDate ? momentFormat(details.createdDate, 'DD-MM-YYYY') : 0}</p>
                </div>
              </div>
            </div>
            <div className="mb-lg-0 mb-2">
              <div className="p-0">
                <div className="d-flex jc-between flex1 p-0">
                  <p className="lightgray nowrap">Average Profit:</p>
                  <p className="nowrap">{details.profitDetails ? details.profitDetails.toFixed(2) : 0} %</p>
                </div>
                <hr className="themehr" />
                <div className="d-flex jc-between flex1 p-0">
                  <p className="lightgray nowrap">All Time Copiers:</p>
                  <p className="nowrap">{details.Followers ? details.Followers.length : 0}</p>
                </div>
              </div>
            </div>
            <div className="mb-lg-0 mb-2">
              <div className="p-0">
                <div className="d-flex jc-between flex1 p-0">
                  <p className="lightgray nowrap">Current Copiers:</p>
                  <p className="nowrap">{details.curFollow ? details.curFollow : 0}</p>
                </div>
                <hr className="themehr" />
                <div className="d-flex jc-between flex1 p-0">
                  <p className="lightgray nowrap">Active Live Copiers:</p>
                  <p className="nowrap">{details.Followers ? details.Followers.filter((value) => (value.status == "active" && value.type == "live")).length : 0}</p>
                </div>
              </div>
            </div>
          </div>
          {/* ........ */}
        {providersData && providersData.length > 0 && providersData.map((value, index) => (
          <>
            <div className="lightbox br-4" style={{ maxHeight: 150, overflow: "auto" }}>
              <p className="pb-1">Strategies</p>
              <div className="d-lg-flex p-0 darkbox mb-1 br-4">
                <div className="d-flex flex-1 mw-610 w-full">
                  <div className="d-flex align-items-start gap-2">
                    <span className="fa fa-star"></span>
                    <div className="p-0 w-full">
                      <div className="d-flex jc-between p-0 mb-3">
                        <p>{value.strategyName}</p>
                        <button className="btn btn-outline-success f-12 icon-50 p-0 px-2 my-0">{value.type}</button>
                      </div>
                      <div className="font-11 p-0">
                        <div className="d-flex jc-between p-0 position-relative ">
                          <p className="lightgray nowrap text-right">Strategy Provider</p>
                          {value.userData[0].firstName == "" ? value.strategyName : value.userData[0].firstName}
                        </div>
                        <hr className="themehr" />
                        <div className="d-flex jc-between gap-1 p-0">
                          <p className="lightgray nowrap" title="The strategy creation date">The strategy creation date</p>
                          <p className="nowrap text-right">{momentFormat(value.createdDate, 'DD-MM-YYYY HH:mm')}</p>
                        </div>
                        <hr className="themehr" />
                        <div className="d-flex gap-1 jc-between p-0">
                          <p className="lightgray">Min. Investment</p>
                          <p className="nowrap text-right">{value.minInvestment}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="p-0 w-full">
                      <div className="font-11 p-0 pt-2">
                        <div className="d-flex jc-between p-0">
                          <p className="lightgray gap-1 nowrap">All Time ROI</p>
                          <p className="nowrap text-right">{value.allTimeRoi ? value.allTimeRoi : 0}</p>
                        </div>
                        <hr className="themehr" />
                        <div className="d-flex gap-1 jc-between p-0">
                          <p className="lightgray nowrap">Own Funds</p>
                          <p className="nowrap text-right">USD {value.OwnFunds.balance ? value.OwnFunds.balance.toFixed(2) : 0}</p>
                        </div>
                        <hr className="themehr" />
                        <div className="d-flex gap-1 jc-between p-0">
                          <p className="lightgray nowrap" title="The strategy creation date"> The strategy creation date</p>
                          <p className="nowrap text-right">USD {value.totalCopyingCapital ? value.totalCopyingCapital : 0}</p>
                        </div>
                        <hr className="themehr" />
                        <div className="d-flex gap-1 jc-between p-0">
                          <p className="lightgray nowrap">Leverage</p>
                          <p className="nowrap text-right">{value.leverage}</p>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>
                <div className="d-flex w-full align-items-center">
                  <div className="nodata_found_align" style={{ width: "100%", flex: 1 }}>
                    {
                      value?.returnOfInvestmentChart?.length > 0 ?
                        <Chart options={data?.options} className="tradechart copymain"
                          series={[{ name: "ROI", data: value?.returnOfInvestmentChart }]}
                          type="area" height="150" width="300" /> :
                        <p className="nodata_found_txt">No chart data found</p>
                    }
                  </div>

                </div>
              </div>
            </div>
          </>
        ))
        }
          
          {/* ........... */}
        </div>
    )
}

export default CopyProvidingAccounts;