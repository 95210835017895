import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link, useParams } from "react-router-dom";
import { toFixed } from "lib/roundOf";
//import package
import { toFixedDown } from "lib/roundOf";
import { useTranslation } from 'react-i18next';
import QRCode from "qrcode.react";
import CopyToClipboard from "react-copy-to-clipboard";

import upiimage from "../assets/images/upi.png"
import INR from '../assets/images/inr.png';
import USD from '../assets/images/usd.png';
import ADA from '../assets/images/ada.png';
import TESTTOKEN from '../assets/images/testtoken.png';
import BTC from '../assets/images/btcIcon.png';


// import action
import { withdrawRequestCoin, withdrawRequestFiat, withdrawCoindemo } from "../actions/walletAction";
import { getAssetData } from "../actions/walletAction";
import { fiatDepositRequest } from "../actions/walletAction";
import { demoFiatDeposit } from "../actions/walletAction";
import { demoWithdrawFiat } from "../actions/walletAction";


//import validation 
import { fiatDepositValidation, fiatDepositValidationDemo, fiatdemoValidation } from "../components/WalletList/validation";
import { coinValidation } from "../components/WalletList/validation";
import { fiatValidation } from "../components/WalletList/validation";

//import lib
import { toastAlert } from "../lib/toastAlert";
import isEmpty from "../lib/isEmpty";
import { precentConvetPrice } from "../lib/calculation";
import { encryptObject } from "../lib/cryptoJS";
import { toLocaleString } from "lib/roundOf";



import qrcode from "../assets/images/qrcode.png"
import { setCurrencyOption } from "actions/commonAction";
import wallet from "reducers/wallet";



const initialFormValue = {
    'image': '',
    'amount': '',
    'userAssetId': '',
    'currency': 'select',
    'showcurrency': '',
    'wallet': {},
    'fiatcoin': '',
}

const initialFormvalues = {
    'currencyId': '',
    'amounts': '',
    'twoFACode': '',
    'bankId': '',
    'finalAmount': '',
    'coin': 'Select',
    'spotBalance': '',
    'minimumWithdraw': '',
    'currencycoin': '',
    'receiverAddress': '',
    'currencytypes': 'fiat',
    'currencytype': 'fiat'
}
const reqData = {
    'firstCurrencySymbol': "BTC",
    'secondCurrencySymmol': "USD"
}
const Fundaccount = ({ setRedirected }) => {

    let dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    // state
    const [totalBals, setTotalBals] = useState(0);  // Balance Loader
    const [estBal, setEstBal] = useState(0);  // Estimated Balance
    const [balLoader, setBalLoader] = useState(true);  // Balance Loader
    const [estLoader, setEstLoader] = useState(true);  // Estimated Loader

    //state

    const [formValue, setFormValue] = useState(initialFormValue)
    // console.log('fromvvalue', formValue)
    const [bankValue, setBankValue] = useState(initialFormvalues)

    const [copy, setCopy] = useState('');
    const [validateError, setValidateError] = useState({});
    console.log("validateError_validateError", validateError);
    const [selectcrypto, setSelectcrypto] = useState(false)
    // console.log('selectcrypto', selectcrypto)
    const [selectfiat, setSelectfiat] = useState(false)
    const [loader, setLoader] = useState(false)
    const [Deposit, setDeposit] = useState(true)
    const [bankOption, setBankOption] = useState({})
    // console.log('bankOption', bankOption)
    const [bankInfo, setBankInfo] = useState('')
    const [originalData, setOriginal] = useState([])
    const [currencyType, setCurrencyType] = useState('Fiat')//for flow change
    //const [currency,setCurrency]=useState('USD')
    const liveorDemos = localStorage.getItem("liveorDemo");
    const [filter, setFilter] = useState({ type: liveorDemos })
    // console.log("filter------------->", filter);
    // console.log("liveorDemos-------->", liveorDemos);
    const [type, setType] = useState(liveorDemos);
    const params = useParams()
    // console.log('demo------', type)
    // currencys(liveorDemos);

    const demolive = async () => {
        // console.log("walletData Filter : de,olove", filter);
        let { status, loading, result, count, reportData } = await getAssetData(dispatch, filter);
    }


    useEffect(() => {
        demolive();
    }, [filter])


    //redux_state
    const walletData = useSelector(state => state.wallet);
    // console.log("walletData------------>", walletData);
    const priceConversion = useSelector(state => state.priceConversion)
    const userSetting = useSelector(state => state.userSetting)
    const currencyData = useSelector(state => state.currency)
    const { referralCode } = useSelector(state => state.auth);

    const bankDetails = useSelector(state => state.bankDetail.result)
    // console.log('bankDetails>>>>>>>>>...', bankDetails)
    const currencyDoc = useSelector(state => state.currency)

    // console.log('currencyDoc', currencyDoc)
    const { useraccount, email, emailStatus } = useSelector(state => state.account)
    console.log("useraccountuseraccountuseraccount", useraccount, email);
    const kycStatus = useSelector(state => state.userKyc.Status);
    const { verifiedstatus, Synapsverify } = kycStatus
    const {
        amounts,
        twoFACode,
        bankId,
        finalAmount,
        coin,
        spotBalance,
        receiverAddress,
        currencytype,
        currencytypes,
    } = bankValue;

    console.log("finalAmount_finalAmount_V", finalAmount);
    const {
        image,
        amount,
        userAssetId,
        currency,
        showcurrency,
        wallet,
        fiatcoin,
        cryptocoin,
    } = formValue;

    // useEffect(()=>{
    //     setType(liveorDemos)

    // },[liveorDemos])
    // useEffect(()=>{
    //     setFilter(liveorDemos)

    // },[liveorDemos])


    useEffect(() => {
        if (Deposit) {
            // console.log('hai hello')
            // if (walletData && walletData.length > 0 && bankDetails && bankDetails?.result && bankDetails?.result?.length > 0) {
            //     try {
            //         console.log('bankDetials hai hello')
            //         setOriginal(walletData)
            //         setBankOption(bankDetails?.result)
            //         let defaultBank = bankDetails.result.find(el => el.isPrimary == true);
            //         if (defaultBank) {
            //             setBankValue((el) => {
            //                 console.log('hello hai', defaultBank._id)
            //                 return { ...el, 'bankId': defaultBank._id }

            //             })
            //         }
            //     }
            //     catch (err) {
            //         console.log('err', err)
            //     }

            // }
            if (currencyDoc && currencyDoc.length > 0) {

                let bankDetails = currencyDoc.find((val) => val.coin === 'USD')
                //setOriginal(walletData)
                // console.log(bankDetails,'bankdeytails')
                setBankOption(bankDetails.bankDetails)
            }

        }

        // else {
        //     try {
        //         console.log('hello hai')
        //         if (bankDetails && bankDetails?.result && bankDetails?.result?.length > 0) {
        //             setBankOption(bankDetails.result)
        //             let defaultBank = bankDetails.result.find(el => el.isPrimary == true);
        //             if (defaultBank) {
        //                 setBankValue((el) => {
        //                     console.log('hello hai', defaultBank._id)
        //                     return { ...el, 'bankId': defaultBank._id }

        //                 })
        //             }
        //         }
        //     } catch (err) {
        //         console.log('err', err)
        //     }
        // }

    }, [walletData, bankDetails, Deposit])


    //function
    const handleClose = () => {
        setBankValue(initialFormvalues)
        setValidateError({})
    }

    const handleChange = async (e) => {
        console.log("handleChange_amount", e)
        const { name, value } = e.target;
        if (name == 'amount') {
            if (!/^\d*\.?\d*$/.test(value)) {
                return
            }
        }
        let formData = { ...formValue, ...{ [name]: value } }
        setFormValue(formData)
        if (!isEmpty(validateError)) {
            console.log('')
            setValidateError({})
        }
    }


    const onInputChange = async (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        if (!isEmpty(validateError)) {
            setValidateError(e.target.name)
        }

        if (name == 'amounts') {
            if (!/^\d*\.?\d*$/.test(value)) {
                return
            }
            console.log('values------->', value)
            let withdrawFee;
            let minimumWithdraw;
            let currencycoin;

            currencyData.map((item) => {
                if (item.coin == bankValue.coin) {
                    console.log('enter now', item)
                    withdrawFee = item.withdrawFee;
                    minimumWithdraw = item.minimumWithdraw;
                    currencycoin = item.coin
                }
            })
            console.log('withdraw fee ', withdrawFee)
            let finalAmountBal = parseFloat(value ? value : 0) + parseFloat(precentConvetPrice(value, withdrawFee));
            console.log('values ------->', finalAmountBal)
            // let amountValue = isNaN(value) ? value : 0
            let formData = { ...bankValue, ...{ [name]: value, 'finalAmount': finalAmountBal, 'minimumWithdraw': minimumWithdraw, 'currencycoin': currencycoin } }
            console.log('sdtfjfdjkhasgtf', formData)
            setBankValue(formData)
            return
        }
        if (name == 'twoFACode') {
            if (!(value == '' || (/^[0-9\b]+$/.test(value) && value.length <= 6))) {
                return
            }
            let formData = { ...bankValue, ...{ [name]: value } }
            setBankValue(formData)
        }
        if (name == 'receiverAddress') {
            let formData = { ...bankValue, ...{ [name]: value } }
            setBankValue(formData)
        }
        if (name == 'receiverAddress') {
            let formData = { ...bankValue, ...{ [name]: value } }
            setBankValue(formData)
        }
    }
    const handlesubmitwithdraw = async () => {
        console.log('conituiomnnn', selectfiat)
        if (emailStatus === "unverified") {
            toastAlert('error', "Activation link already sent to your email...Please activate your email account.")
            return
        }
        else if (verifiedstatus === 'PENDING') {
            toastAlert('error', "You are not able to trade until kyc is approved")
            return
        }
        else if (verifiedstatus === 'REJECTED' || verifiedstatus === 'Not verified') {
            toastAlert('error', "Please submit your kyc")
            return
        }
        if (selectfiat == false) {
            setLoader(true)
            let reqData = {
                currencyId: bankValue._id,
                minimumWithdraw: bankValue.minimumWithdraw,
                coin: bankValue.currencycoin,
                amounts,
                bankId,
                twoFACode,
                finalAmount,
                spotBal: useraccount.balance,
                timeStamp: new Date().getTime(),
                currencytypes,
                accountId: useraccount?.accountId,
                type: useraccount?.type
            }
            console.log('bankId_hihi', reqData)
            let encryptToken;
            try {

                let validationError = useraccount.type == 'live' ? fiatValidation(reqData, t) : fiatdemoValidation(reqData, t)
                if (!isEmpty(validationError)) {
                    setValidateError(validationError)
                    setLoader(false)
                    console.log('I am here hihi', validationError)
                    return
                }
                encryptToken = {
                    token: encryptObject(reqData)
                }
            }
            catch (err) {
                console.log('hihi', err)
            }

            try {
                console.log("userdata---------", useraccount.type);
                // if (useraccount.type == 'live') {
                console.log("usrlogin222");
                const { status, loading, error, message } = await withdrawRequestFiat(encryptToken, dispatch)
                { console.log('status,loading,error,message', status, loading, error, message) }
                console.log('errors hihi', error)
                console.log("message_messagemessage", message);
                setLoader(loading)
                if (status == 'success') {
                    // setBankValue(initialFormvalues)
                    toastAlert('success', t(message), 'withdraw');
                    // handleClose()
                } else {
                    if (error) {
                        setValidateError(error)
                        return
                    }
                    toastAlert('error', t(message), 'withdraw');
                }
                // }
                // else if (useraccount.type == 'demo') {
                //     console.log("userlogin");
                //     console.log('encryptoken', encryptToken)
                //     const { status, loading, error, message } = await demoWithdrawFiat(encryptToken, dispatch)
                //     { console.log('status ------', status, loading, error, message) }
                //     if (status == 'success') {
                //         // setBankValue(initialFormvalues)
                //         toastAlert('success', t(message), 'withdraw');
                //         // handleClose()
                //     }
                //     else {
                //         if (error) {console.log("errroe--",error);
                //             setValidateError(error)
                //             return
                //         }
                //         toastAlert('error', t(message), 'withdraw');
                //     }
                // }


            }
            catch (err) {
            }
        }

        else if (selectfiat == true) {

            let reqData = {
                currencyId: bankValue._id,
                minimumWithdraw: bankValue.minimumWithdraw,
                coin: bankValue.currencycoin,
                tokenType: bankValue.tokenType,
                amounts,
                receiverAddress,
                twoFACode,
                finalAmount,
                spotBal: bankValue.spotBalance,
            }
            console.log("reqData_reqData_reqData", reqData);
            let validationError = coinValidation(reqData, t);
            if (!isEmpty(validationError)) {
                setValidateError(validationError);
                setLoader(false);
                return;
            }

            let encryptToken = {
                token: encryptObject(reqData)
            }
            try {
                if (type == 'LIVE') {
                    console.log("encryptToken------", encryptToken);
                    const { status, loading, error, message } = await withdrawRequestCoin(encryptToken)
                    setLoader(loading);
                    if (status == 'success') {
                        // setBankValue(initialFormvalues)
                        // handleClose();
                        toastAlert("success", t(message), "withdraw");
                    }
                    else {
                        if (error) {
                            setValidateError(error);
                            return;
                        }
                        toastAlert("error", t(message), "withdraw");
                    }
                }
                else if (type == 'DEMO') {
                    const { status, loading, error, message } = await withdrawCoindemo(encryptToken)
                    setLoader(loading);
                    if (status == 'success') {
                        // setBankValue(initialFormvalues)
                        // handleClose();
                        toastAlert("success", t(message), "withdraw");
                    }
                    else {
                        if (error) {
                            setValidateError(error);
                            return;
                        }
                    }
                }
            }
            catch (err) {

            }
        }
    }


    const handleFile = async (e) => {
        const { files, name } = e.target;
        let formData = { ...formValue, ...{ [name]: files[0] } }
        setFormValue(formData)
        if (!isEmpty(validateError)) {
            setValidateError({})
        }
    }

    // const handleSubmitdeposit = async (e) => {
    //     e.preventDefault();
    //     let reqData = {
    //         userAssetId, amount, image, fiatcoin
    //     }

    //     console.log('typeeeeeeeeeeeeeeeeeeeee',type)
    //     let validationError = fiatDepositValidation(reqData)
    //     console.log('validationerror hihi', validateError)
    //     if (!isEmpty(validationError)) {
    //         setValidateError(validationError)
    //         return
    //     }
    //     const formData = new FormData();
    //     formData.append('image', image)
    //     formData.append('amount', amount)
    //     formData.append('userAssetId', useraccount.accountId)
    //     formData.append('currency', currency)
    //     try {
    //         if (useraccount?.type == 'live') {
    //             console.log('ullai poo hihi')
    //             let validationError = fiatDepositValidation(reqData)
    //             console.log('validationerror hihi', validateError)
    //             if (!isEmpty(validationError)) {
    //                 setValidateError(validationError)
    //                 return
    //             }
    //             let { status, loading, message, error } = await fiatDepositRequest(formData)
    //             if (status == 'success') {
    //                 setFormValue(initialFormValue)
    //                 toastAlert('success', t(message), 'fiatDeposit')
    //             }
    //             else {
    //                 if (error) {
    //                     setValidateError(error)
    //                 }
    //                 toastAlert('error', t(message), 'fiatDeposit')
    //             }
    //         }
    //         else if (useraccount?.type == 'demo') {
    //             console.log('uallai vai daa')
    //             let validationError = fiatDepositValidationDemo(reqData)
    //             console.log('validationerror hihi', validateError)
    //             if (!isEmpty(validationError)) {
    //                 setValidateError(validationError)
    //                 return
    //             }
    //             console.log('demossssssssssss')
    //             let { status, loading, message, error } = await demoFiatDeposit(formData)
    //             if (status == 'success') {
    //                 setFormValue(initialFormValue)
    //                 toastAlert('success', t(message), 'fiatDeposit')
    //             }
    //             else {
    //                 if (error) {
    //                     setValidateError(error)
    //                 }
    //                 toastAlert('error', t(message), 'fiatDeposit')
    //             }
    //         }





    //     }
    //     catch (err) {

    //     }

    // }
    const handleSubmitdeposit = async (e) => {
        e.preventDefault();
        let reqData = {
            amount, image, currency
        }

        const formData = new FormData();
        formData.append('image', image)
        formData.append('amount', amount)
        formData.append('userAssetId', useraccount.accountId)
        formData.append('currency', currency)
        try {
            //if (useraccount?.type == 'live') {
            let validationError = useraccount?.type == 'live' ? fiatDepositValidation(reqData) : fiatDepositValidationDemo(reqData)
            if (emailStatus === "unverified") {
                toastAlert('error', "Activation link already sent to your email...Please activate your email account.")
                return
            }
            else if (verifiedstatus === 'PENDING') {
                toastAlert('error', "You are not able to trade until kyc is approved")
                return
            }
            else if (verifiedstatus === 'REJECTED' || verifiedstatus === 'Not verified') {
                toastAlert('error', "Please submit your kyc")
                return
            }
            if (!isEmpty(validationError)) {
                setValidateError(validationError)
                return
            }
            let { status, loading, message, error } = await fiatDepositRequest(formData)
            console.log("status000-0-0", status, loading, message, error);
            if (status == 'success') {
                console.log("vbvgvbvghjbjhb");
                setFormValue(initialFormValue)
                toastAlert('success', t(message), 'fiatDeposit')
            }
            else {
                if (error) {
                    setValidateError(error)
                }
                toastAlert('error', t(message), 'fiatDeposit')
            }
            // }
            // else if (useraccount?.type == 'demo') {
            //     console.log('uallai vai daa')
            //     let validationError = fiatDepositValidationDemo(reqData)
            //     console.log('validationerror hihi', validateError)
            //     if (!isEmpty(validationError)) {
            //         setValidateError(validationError)
            //         return
            //     }
            //     console.log('demossssssssssss')
            //     let { status, loading, message, error } = await demoFiatDeposit(formData)
            //     if (status == 'success') {
            //         setFormValue(initialFormValue)
            //         toastAlert('success', t(message), 'fiatDeposit')
            //     }
            //     else {
            //         if (error) {
            //             setValidateError(error)
            //         }
            //         toastAlert('error', t(message), 'fiatDeposit')
            //     }
            // }
        }
        catch (err) {
            console.log(err, handleSubmitdeposit)
        }
    }

    const copyCode = (data) => {
        if (data) {
            toastAlert('success', 'Referral Code copied')
        }
        else {
            toastAlert('error', 'no respond can found')
        }


    }

    const setFiat = async (data) => {
        let currencytypes;
        console.log('fiat hihi', data, currencytypes)
        currencyDoc.map((item) => {
            console.log('fiat hihi2', item, item.type)
            if (item.type == data) {
                currencytypes = item.type;
                console.log('fiat hihi3', currencytypes)
                let formDatas = { ...bankValue, ...{ 'currencytypes': currencytypes } }
                setBankValue(formDatas)
            }
        })
    }
    const setCrypto = async (data) => {
        console.log('setcrypto', data)
        let currencytype;
        currencyDoc.map((item) => {
            if (item.type == data) {
                currencytype = item.type

                let formDatas = { ...bankValue, ...{ 'currencytype': currencytype } }
                setBankValue(formDatas)
            }

        })
    }

    const setBTC = async (data) => {
        let currency, cryptocoin;
        currencyDoc.map((item) => {
            if (item.coin == data) {
                cryptocoin = item.coin;
            }
        })
        let userAssetId;
        let wallet;
        console.log('walletData hihihi', walletData)
        walletData.map((item) => {
            if (item.coin == data) {
                wallet = item;
                userAssetId = item._id;
                let formDatas = { ...formValue, ...{ 'currency': currency, 'userAssetId': userAssetId, 'wallet': wallet, 'cryptocoin': cryptocoin, showcurrency: 'Select Type', } }
                setFormValue(formDatas)
            }
        })
    }

    const setUSD = async (data) => {
        let currency = data;
        // currencyDoc.map((item) => {
        //     console.log('itefdmfd,ap]]', currency)

        //     if (item.coin == data) {
        //         currency = item;
        //         fiatcoin = item.coin;
        //         console.log('alksdjflkasjf', fiatcoin, data)
        //     }
        // })
        // let userAssetId;
        // let wallet;
        // walletData.map((item) => {
        //     if (item.coin == data) {
        //         wallet = item;
        //         userAssetId = item._id;

        //     }

        // })
        let formDatas = { ...formValue, ...{ 'currency': currency } }
        setFormValue(formDatas)
    }




    const selectCurrency = async (data) => {
        console.log('item.coin == data')
        let coin = useraccount.currency, balance = useraccount.balance;
        let id
        currencyDoc.map((item) => {
            console.log('item.coin == data1', item._id)
            if (item.coin == data) {
                console.log('item.coin == data', item._id)
                id = item._id
            }
        })
        let formDatas = { ...bankValue, ...{ 'coin': coin, 'spotBalance': balance, '_id': id, 'amounts': 0 } }
        setBankValue(formDatas)
    }
    const handleChanges = async (e) => {
        setCopy(e.target.value);
    }
    const currencys = async (data) => {
        let filters = { ...filter, ...{ 'type': data } }
        console.log("filters------------>", filters);
        setFilter(filters)
    }




    //allbalance
    useEffect(() => {
        if (userSetting && priceConversion && priceConversion.length > 0 && walletData && walletData.length > 0 && currencyData && currencyData.length > 0) {

            let estBal = 0;
            walletData.map(item => {
                let currency = currencyData.find(el => el.coin == item.coin && el.type == 'fiat')
                if (currency) {
                    if (item.coin == userSetting.currencySymbol) {
                        estBal = estBal + item.derivativeBal
                    } else {
                        let CNVPriceData = priceConversion.find(el => el.baseSymbol == reqData.firstCurrencySymbol && el.convertSymbol == item.coin)
                        console.log("itemssssssssssss", item.derivativeBal, item.coin, CNVPriceData)

                        if (CNVPriceData) {

                            let bal = (item.derivativeBal / CNVPriceData.convertPrice)
                            let CNVPrice = priceConversion.find(el => el.baseSymbol == reqData.firstCurrencySymbol && el.convertSymbol == userSetting.currencySymbol)
                            if (CNVPrice) {
                                estBal = estBal + (bal * CNVPrice.convertPrice)
                            }
                        }
                    }
                } else {
                    let CNVPriceData = priceConversion.find(el => el.baseSymbol == item.coin && el.convertSymbol == userSetting.currencySymbol)
                    // console.log("CNVPriceDataCNVPriceDataCNVPriceData",CNVPriceData,item.coin)
                    if (CNVPriceData) {
                        estBal = estBal + (item.derivativeBal * CNVPriceData.convertPrice)
                    }
                }
            })
            setEstBal(estBal)

            console.log("estBalestBalestBal", estBal)

            console.log("proiceconversionssssssssssssssssssssssss", priceConversion)
            priceConversion.find(el => {

            }


            )
            let firPriceCNV = priceConversion.find(el => el.baseSymbol == reqData.firstCurrencySymbol && el.convertSymbol == userSetting.currencySymbol)
            if (firPriceCNV) {
                setTotalBals((estBal / firPriceCNV.convertPrice))
                setEstLoader(false)
                setBalLoader(false)
            }
        }
    }, [userSetting, priceConversion, walletData, currencyData])

    useEffect(() => {

        if (params.id === 'deposit') {
            document.getElementById("nav-deposit-tab").click()
        }
        else if (params.id === 'withdraw') {
            document.getElementById("nav-withdraw-tab").click()
        }
    }, [params])


    return (
        <div className='fundaccount'>
            <div className='container custom-container1'>
                <div className="headsec my-3">
                    {/* {console.log('likuhulighl', formValue)} */}
                    <div >
                        <h5>Your Trading Accounts</h5>
                    </div>
                    <div className="referralcodecopy">
                        <div> <label htmlFor="minimum">Referral code:</label></div>
                        <div className="inputcopy"> <input name="minimumAmount" value={referralCode} type="text" className="form-control" onChange={(e) => handleChanges(e)} /><span>
                            <CopyToClipboard text={referralCode} onCopy={() => copyCode(referralCode)}><div><button class="fa fa-copy copybtn"> </button></div></CopyToClipboard></span>  </div>

                    </div>
                </div>
                <div className='row dis_flex mt-4'>
                    <div className="col-lg-5 mb-4 leftcard pr-md-0">

                        <div className="card text-white firstcard mt-0 mb-3">


                            <div className="totalbal" >
                                <div className="row">
                                    <div className="col-sm-6 mt-1 col-lg-8">
                                        <p className="labelname mb-2">Balance Available</p>
                                        <p className="labelname mb-2">{toLocaleString(parseFloat(useraccount?.balance?.toFixed(2)))}  USD</p>
                                        {/* {!balLoader && <p className="totalbal_bal" style={{lineHeight:1.5}}>{toFixed(totalBals, 4)}<span className="pl-1">{reqData.firstCurrencySymbol}</span></p>} */}
                                        {/* {walletData.map((val)=>{if(val.coin=='USD'){return val.derivativeBal}})}   {walletData.map((val)=>{if(val.coin=='USD'){return val.coin}})} */}



                                    </div>
                                    <div className="col-sm-6 pr-2 col-lg-4 mt-1">
                                        <div className="headsec headsec_width_red justify-content-end mb-0">
                                            <div class="dropdown">
                                                <p className="labelname mb-2">Account Id</p>
                                                <p className="labelname mb-2 text-left">{useraccount?.accountId}</p>
                                                {/* <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    {type}
                                                </button>

                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <a class="dropdown-item" href="#" onClick={() => { currencys("LIVE"); localStorage.setItem("liveorDemo","LIVE") ;setType("LIVE")}} >LIVE</a>
                                                    <a class="dropdown-item" href="#" onClick={() => { currencys("DEMO"); localStorage.setItem("liveorDemo","DEMO");setType("DEMO") }}>DEMO</a>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>




                            </div>
                        </div>

                        <div className="card text-white my-3">
                            <div className="card-body">
                                <h5> Wallet</h5>
                                <a href="javascript:void(0)">
                                    <div className="my-4">
                                        {currencyDoc && currencyDoc.length > 0 ? currencyDoc.map((val, index) => {
                                            if (val && ['USD'].includes(val.symbol)) {
                                                return (
                                                    <div className="d-flex justify-content-between my-4">
                                                        <div>

                                                            {/* <p className="labelname"> <img src={val && val.image}  width="20" onClick={() => { setUSD(val.coin); setCrypto('fiat'); setSelectcrypto(false) }} />  <span>{val.coin}</span></p> */}
                                                            <p className="labelname"> <img src={val && val.image} width="20" />  <span>{val.coin}</span></p>
                                                        </div>
                                                        <div>
                                                            <p className="labelname">{toFixedDown(useraccount?.balance)}</p>

                                                        </div>
                                                    </div>
                                                )
                                            }
                                        }) :
                                            ''}
                                        {/* {

                                            currencyDoc && currencyDoc.length > 0 && originalData && originalData.length > 0 && originalData.map((item, key) => {
                                                console.log("originaldata haii-------------------->", originalData);
                                                let curData = currencyDoc.find(el => el.coin == item.coin)
                                                console.log("curData haii------->", curData);
                                                if (curData && ['USD'].includes(curData.symbol)) {
                                                   
                                                    return (
                                                
                                                        <div className="d-flex justify-content-between my-4">


                                                            <div>
                                                                
                                                                <p className="labelname"> <img src={curData && curData.image} alt="USD" width="20" onClick={() => { setUSD(item.coin); setCrypto('fiat'); setSelectcrypto(false) }} />  <span>{item.coin}</span></ p>

                                                            </div>
                                                            <div>
                                                                <p className="labelname">{toFixedDown(item.derivativeBal)}</p>

                                                            </div>
                                                        </div>

                                                    )
                                                }
                                            })
                                        } */}

                                    </div>
                                </a>

                            </div>
                        </div>


                        <div className="card text-white my-3">
                            <div className="card-body">
                                <div className="row px-3 my-4">
                                    <div >
                                        <p className="labelname">Balance Available</p>
                                    </div>
                                    {/* <div >
                                        <p className="labelname"> {!balLoader && <Fragment>{toFixed(totalBals, 8)}<span>{reqData.firstCurrencySymbol}</span></Fragment>}</p>
                                    </div> */}

                                    {/* {walletData.[]derivativeBal} */}
                                    {/* {walletData.map((val)=>{if(val.coin=='USD'){return val.derivativeBal}})}   {walletData.map((val)=>{if(val.coin=='USD'){return val.coin}})} */}
                                    {toLocaleString(parseFloat(useraccount?.balance?.toFixed(2)))} {useraccount?.currency}
                                </div>
                                <hr />
                                <div className="row px-3 my-4">
                                    <div >
                                        <p className="labelname">All Transactions</p>
                                    </div>
                                    <div >

                                        <div className="text-right" onClick={() => { console.log("xcvxcvcx"); setRedirected("transcation") }}><i class="pointer fa fa-chevron-right"> </i></div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="col-lg-6 custom-col-lg-4 rightcard mb-4 pr-md-0">
                        <div className="card text-white">
                            <nav>
                                <div class="nav nav-tabs" id="nav-tab1" role="tablist">
                                    <a class="nav-item nav-link  active" id="nav-deposit-tab" data-toggle="tab" href="#nav-deposit" role="tab" aria-controls="nav-deposit" aria-selected="true" onClick={() => setDeposit(false)}>DEPOSIT</a>
                                    <a class="nav-item nav-link" id="nav-withdraw-tab" data-toggle="tab" href="#nav-withdraw" role="tab" aria-controls="nav-withdraw" aria-selected="false" onClick={() => setDeposit(true)}>WITHDRAW</a>
                                </div>
                            </nav>
                            <hr />
                            <div className="card-body">
                                <div class="tab-content" id="nav-tabContent1">
                                    {useraccount && useraccount?.type == "live" ? isEmpty(email) ?
                                        <>
                                            <p className="notable_text">Please Submit email</p>
                                        </>
                                        :
                                        <div class="tab-pane fade show active" id="nav-deposit" role="tabpanel" aria-labelledby="nav-deposit-tab">
                                            <div className="row depositsecond">
                                                <div className="col-md-6 mb-2">
                                                    <label for="exampleInputEmail1">Select Types</label>
                                                    <div class="dropdown">
                                                        <button class="btn btn-addmoney dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            {currencyType}
                                                        </button>
                                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <a class="dropdown-item" href="#" onClick={() => { setCurrencyType('Fiat') }}>Fiat</a>
                                                            {/* <a class="dropdown-item" href="#" onClick={() => { setCrypto('crypto'); setSelectcrypto(true) }}>Crypto</a> */}
                                                        </div>
                                                        {validateError?.currencytype && <p className="error-message text-left">{t(validateError?.currencytype)}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6 ">
                                                    <label for="exampleInputEmail1">Select Currency</label>
                                                    <div class="dropdown">
                                                        <button class="btn btn-addmoney dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            {formValue?.currency ? formValue?.currency : 'Select'}
                                                        </button>
                                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <a class="dropdown-item" href="#" onClick={() => { setUSD('USD'); }}>USD</a>
                                                            {/* <a class="dropdown-item" href="#" onClick={() => { setUSD('INR') }}>INR</a> */}
                                                        </div>
                                                    </div>
                                                    {validateError.fiatcoin && <p className="error-message text-left">{t(validateError.fiatcoin)}</p>}
                                                </div>
                                                {/* <div className="col-md-6 ">
                                                <label for="exampleInputEmail1">Select Currency</label>
                                                <div class="dropdown">
                                                    <button class="btn btn-addmoney dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        {formValue?.wallet?.coin == 'BTC' || formValue?.wallet?.coin == 'ADA' || formValue?.wallet?.coin == 'TEST Token' ? formValue?.wallet?.coin : 'BTC'}
                                                    </button>
                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <a class="dropdown-item" href="#" onClick={() => { setBTC('BTC') }}>BTC</a>
                                                        <a class="dropdown-item" href="#" onClick={() => { setBTC('ADA') }}>ADA</a>
                                                        <a class="dropdown-item" href="#" onClick={() => { setBTC('TEST Token') }}>TEST TOKEN</a>
                                                    </div>
                                                </div>
                                            </div> */}
                                            </div>
                                            <div className="row depositfirst px-3 mb-2">

                                                <div class="group">
                                                    <label for="exampleInputEmail1">Enter Amount</label>
                                                    <input type="text" name='amount' value={amount} class="form-control" placeholder={currency || ''} maxlength="15" onChange={(e) => { handleChange(e) }} />
                                                    <i class=""></i>
                                                </div>
                                                {validateError.amount && <p className="error-message text-left">{t(validateError.amount)}</p>}
                                            </div>
                                            {useraccount && useraccount?.type == "live" ?
                                                <div className="row  px-3 depositthird">
                                                    <div className="wallet_balance_div">
                                                        <h5 className="mb-2 mt-2">Deposit Bank Info</h5>
                                                    </div>
                                                    <div className="wallet_balance_div">
                                                        <p className="labelname">Bank Name</p>
                                                        {/* <span>{currency && currency.bankDetails && currency.bankDetails.bankName}</span> */}
                                                        <span> {bankOption && bankOption?.bankName}</span>
                                                    </div>
                                                    <div className="wallet_balance_div">
                                                        <p className="labelname">Account Number</p>
                                                        {/* <span>{currency && currency.bankDetails && currency.bankDetails.accountNo}</span> */}
                                                        <span> {bankOption && bankOption?.accountNo}</span>
                                                    </div>
                                                    <div className="wallet_balance_div">
                                                        <p className="labelname">Account Holder Name</p>
                                                        {/* <span className="holder_name">{currency && currency.bankDetails && currency.bankDetails.holderName}</span> */}
                                                        <span> {bankOption && bankOption?.holderName}</span>
                                                    </div>
                                                    <div className="wallet_balance_div">
                                                        {/* <p className="labelname">Iban swif</p> */}
                                                        {/* <span>{currency && currency.bankDetails && currency.bankDetails.bankcode}</span> */}
                                                        {/* <span> {bankOption && bankOption[0]?.bankName}</span> */}
                                                    </div>
                                                    <div className="wallet_balance_div">
                                                        <p className="labelname">Country</p>
                                                        {/* <span>{currency && currency.bankDetails && currency.bankDetails.country}</span> */}
                                                        <span> {bankOption && bankOption?.country}</span>
                                                    </div>
                                                </div> : ''}
                                            {selectcrypto ? false : true && useraccount?.type == 'live' && <h5> Upload proof</h5>}
                                            {selectcrypto ? false : true && useraccount?.type == 'live' &&
                                                <> <div className="custom-file mb-4">
                                                    <input type="file" className="custom-file-input" aria-describedby="inputGroupFileAddon01" name="image" onChange={(e) => { handleFile(e) }} />
                                                    <label className="custom-file-label">
                                                        {
                                                            image && image.name ? <small>{image.name}</small> : <small>{t("MAX_1MB_IMG")}</small>
                                                        }
                                                    </label>
                                                    {
                                                        validateError.image && <p className="error-message">{t(validateError.image)}</p>
                                                    }

                                                </div>

                                                </>
                                            }
                                            {selectcrypto && <div className="row depositfirst px-3 mb-2">
                                                <div class="group">
                                                    <label for="exampleInputEmail1">Your {currency && currency.coin} Wallet Address</label>
                                                    <div className="d-flex align-items-center" >
                                                        <input type="text" class="form-control" placeholder='' value={wallet && wallet.address} onChange={(e) => { handleChange(e) }} />
                                                        <div><CopyToClipboard text={wallet && wallet.address} onCopy={() => copyCode(wallet && wallet.address)}><div>
                                                            <button class="fa fa-copy copybtn"> </button></div></CopyToClipboard></div>
                                                    </div>
                                                    <i class=""></i>
                                                </div>
                                            </div>}
                                            {selectcrypto && <div className="qr_cenet">
                                                {!isEmpty(wallet.address) && (<QRCode value={wallet.address} />)}
                                            </div>}
                                            <hr />
                                            {selectcrypto ? false : true && <div className="row my-3 depositsecond">

                                                <div className="depositbtnsec px-3">
                                                    <button className="btn btn-primary text-uppercase m-0" type="button" onClick={handleSubmitdeposit}>Deposit Money</button>
                                                </div>
                                            </div>}
                                        </div>
                                        :
                                        <div class="tab-pane fade show active" id="nav-deposit" role="tabpanel" aria-labelledby="nav-deposit-tab">
                                            <div className="row depositsecond">
                                                <div className="col-md-6 mb-2">
                                                    <label for="exampleInputEmail1">Select Types</label>
                                                    <div class="dropdown">
                                                        <button class="btn btn-addmoney dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            {currencyType}
                                                        </button>
                                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <a class="dropdown-item" href="#" onClick={() => { setCurrencyType('Fiat') }}>Fiat</a>
                                                            {/* <a class="dropdown-item" href="#" onClick={() => { setCrypto('crypto'); setSelectcrypto(true) }}>Crypto</a> */}
                                                        </div>
                                                        {validateError?.currencytype && <p className="error-message text-left">{t(validateError?.currencytype)}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6 ">
                                                    <label for="exampleInputEmail1">Select Currency</label>
                                                    <div class="dropdown">
                                                        <button class="btn btn-addmoney dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            {formValue?.currency ? formValue?.currency : 'Select'}
                                                        </button>
                                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <a class="dropdown-item" href="#" onClick={() => { setUSD('USD') }}>USD</a>
                                                            {/* <a class="dropdown-item" href="#" onClick={() => { setUSD('INR') }}>INR</a> */}
                                                        </div>
                                                    </div>
                                                    {validateError.fiatcoin && <p className="error-message text-left">{t(validateError.fiatcoin)}</p>}
                                                </div>
                                                {/* <div className="col-md-6 ">
                                                <label for="exampleInputEmail1">Select Currency</label>
                                                <div class="dropdown">
                                                    <button class="btn btn-addmoney dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        {formValue?.wallet?.coin == 'BTC' || formValue?.wallet?.coin == 'ADA' || formValue?.wallet?.coin == 'TEST Token' ? formValue?.wallet?.coin : 'BTC'}
                                                    </button>
                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <a class="dropdown-item" href="#" onClick={() => { setBTC('BTC') }}>BTC</a>
                                                        <a class="dropdown-item" href="#" onClick={() => { setBTC('ADA') }}>ADA</a>
                                                        <a class="dropdown-item" href="#" onClick={() => { setBTC('TEST Token') }}>TEST TOKEN</a>
                                                    </div>
                                                </div>
                                            </div> */}
                                            </div>
                                            <div className="row depositfirst px-3 mb-2">

                                                <div class="group">
                                                    <label for="exampleInputEmail1">Enter Amount</label>
                                                    <input type="text" name='amount' value={amount} class="form-control" placeholder={currency || ''} maxlength="15" onChange={(e) => { handleChange(e) }} />
                                                    <i class=""></i>
                                                </div>
                                                {validateError.amount && <p className="error-message text-left">{t(validateError.amount)}</p>}
                                            </div>
                                            {useraccount && useraccount.type == 'live' ? <div className="row  px-3 depositthird">
                                                <div className="wallet_balance_div">
                                                    <h5 className="mb-2 mt-2">Deposit Bank Info</h5>
                                                </div>
                                                <div className="wallet_balance_div">
                                                    <p className="labelname">Bank Name</p>
                                                    {/* <span>{currency && currency.bankDetails && currency.bankDetails.bankName}</span> */}
                                                    <span> {bankOption && bankOption?.bankName}</span>
                                                </div>
                                                <div className="wallet_balance_div">
                                                    <p className="labelname">Account Number</p>
                                                    {/* <span>{currency && currency.bankDetails && currency.bankDetails.accountNo}</span> */}
                                                    <span> {bankOption && bankOption?.accountNo}</span>
                                                </div>
                                                <div className="wallet_balance_div">
                                                    <p className="labelname">Account Holder Name</p>
                                                    {/* <span className="holder_name">{currency && currency.bankDetails && currency.bankDetails.holderName}</span> */}
                                                    <span> {bankOption && bankOption?.holderName}</span>
                                                </div>
                                                <div className="wallet_balance_div">
                                                    {/* <p className="labelname">Iban swif</p> */}
                                                    {/* <span>{currency && currency.bankDetails && currency.bankDetails.bankcode}</span> */}
                                                    {/* <span> {bankOption && bankOption[0]?.bankName}</span> */}
                                                </div>
                                                <div className="wallet_balance_div">
                                                    <p className="labelname">Country</p>
                                                    {/* <span>{currency && currency.bankDetails && currency.bankDetails.country}</span> */}
                                                    <span> {bankOption && bankOption?.country}</span>
                                                </div>
                                            </div> : ''}
                                            {selectcrypto ? false : true && useraccount?.type == 'live' && <h5> Upload proof</h5>}
                                            {selectcrypto ? false : true && useraccount?.type == 'live' &&
                                                <> <div className="custom-file mb-4">
                                                    <input type="file" className="custom-file-input" aria-describedby="inputGroupFileAddon01" name="image" onChange={(e) => { handleFile(e) }} />
                                                    <label className="custom-file-label">
                                                        {
                                                            image && image.name ? <small>{image.name}</small> : <small>{t("MAX_1MB_IMG")}</small>
                                                        }
                                                    </label>
                                                    {
                                                        validateError.image && <p className="error-message">{t(validateError.image)}</p>
                                                    }

                                                </div>

                                                </>
                                            }
                                            {selectcrypto && <div className="row depositfirst px-3 mb-2">
                                                <div class="group">
                                                    <label for="exampleInputEmail1">Your {currency && currency.coin} Wallet Address</label>
                                                    <div className="d-flex align-items-center" >
                                                        <input type="text" class="form-control" placeholder='' value={wallet && wallet.address} onChange={(e) => { handleChange(e) }} />
                                                        <div><CopyToClipboard text={wallet && wallet.address} onCopy={() => copyCode(wallet && wallet.address)}><div>
                                                            <button class="fa fa-copy copybtn"> </button></div></CopyToClipboard></div>
                                                    </div>
                                                    <i class=""></i>
                                                </div>
                                            </div>}
                                            {selectcrypto && <div className="qr_cenet">
                                                {!isEmpty(wallet.address) && (<QRCode value={wallet.address} />)}
                                            </div>}
                                            <hr />
                                            {selectcrypto ? false : true && <div className="row my-3 depositsecond">

                                                <div className="depositbtnsec px-3">
                                                    <button className="btn btn-primary text-uppercase m-0" type="button" onClick={handleSubmitdeposit}>Deposit Money</button>
                                                </div>
                                            </div>}
                                        </div>
                                    }

                                    {/* WITHDRAW-tab */}

                                    <div class="tab-pane fade " id="nav-withdraw" role="tabpanel" aria-labelledby="nav-withdraw-tab">
                                        <div className="row depositfirst">
                                            <div className="col-md-6 mb-2">
                                                <label for="exampleInputEmail1">Select Type</label>
                                                <div class="dropdown">
                                                    <button class="btn btn-addmoney dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        {currencyType}
                                                    </button>
                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <a class="dropdown-item" href="#" onClick={() => { setCurrencyType('Fiat') }}>Fiat</a>
                                                        {/* <a class="dropdown-item" href="#" onClick={() => { setFiat('crypto'); setSelectfiat(true) }}>Crypto</a> */}
                                                    </div>
                                                    {validateError.currencytypes && <p className="error-message text-left">{t(validateError.currencytypes)}</p>}
                                                </div>
                                            </div>
                                            {selectfiat ? false : true && <div className="col-md-6 mb-2">
                                                <label for="exampleInputEmail1">Select Currency</label>
                                                <div class="dropdown">
                                                    <button class="btn btn-addmoney dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        {coin ? coin : 'Select'}
                                                    </button>
                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <a class="dropdown-item" href="#" onClick={() => selectCurrency('USD')}>USD</a>
                                                        {/* <a class="dropdown-item" href="#" onClick={() => selectCurrency("INR")}>INR</a> */}
                                                    </div>
                                                </div>
                                            </div>}
                                            {selectfiat && <div className="col-md-6 ">
                                                <label for="exampleInputEmail1">Select Currency</label>
                                                <div class="dropdown">
                                                    <button class="btn btn-addmoney dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        {bankValue?.coin == 'BTC' || bankValue?.coin == 'ADA' || bankValue?.coin == 'TEST Token' ? bankValue?.coin : 'BTC'}
                                                    </button>{console.log(' bankValue?.coin ', bankValue?.coin)}
                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <a class="dropdown-item" href="#" onClick={() => { selectCurrency('BTC') }}>BTC</a>
                                                        <a class="dropdown-item" href="#" onClick={() => { selectCurrency('ADA') }}>ADA</a>
                                                        <a class="dropdown-item" href="#" onClick={() => { selectCurrency('TEST Token') }}>TEST TOKEN</a>
                                                    </div>
                                                </div>
                                            </div>}
                                            {selectfiat ? false : true && useraccount?.type == 'live' && <div className="col-xl-6">
                                                <div >
                                                    <p className="labelname mb-2">Bank Account</p>
                                                </div>
                                                <div >
                                                    <div class="dropdown chec_nput_w">
                                                        <button class="btn btn-addmoney dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <span className="overhide_tetx">{bankInfo}</span>
                                                        </button>
                                                        <div class="dropdown-menu addbankdrop" aria-labelledby="dropdownMenuButton">

                                                            {bankDetails && bankDetails.length > 0 ? bankDetails.map((item) =>

                                                                <>
                                                                    <a class="dropdown-item" href="#" onClick={() => {
                                                                        setBankInfo(item.bankName + " " + item.accountNo);
                                                                        let formDatas = { ...bankValue, ...{ 'bankId': item._id } };
                                                                        setBankValue(formDatas)
                                                                    }}>
                                                                        {item.bankName + ' ' + item.accountNo}
                                                                    </a>
                                                                </>
                                                            ) : <p className="text-white">Add Bank Account</p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                            {selectfiat && <div className="col-xl-6 mt-3">
                                                <div >
                                                    <p className="labelname mb-2">Withdraw Address</p>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" placeholder="" aria-label="Username" aria-describedby="basic-addon1" name="receiverAddress" value={receiverAddress} onChange={onInputChange} />
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" id="basic-addon1">{bankValue?.coin}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {validateError.receiverAddress && <p className="error-message text-left">{t(validateError.receiverAddress)}</p>}
                                            </div>}
                                            <div className="col-xl-6 mt-3">
                                                <div >
                                                    <p className="labelname mb-2">Enter Amount</p>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" placeholder="" aria-label="Username" aria-describedby="basic-addon1" name="amounts" value={amounts} onChange={onInputChange} />
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" id="basic-addon1">$</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {validateError.amounts && <p className="error-message">{t(validateError.amounts)}</p>}
                                            </div>
                                        </div>

                                        <div className="totalbal my-3">
                                            <p className="labelname">Wallet Ballance : <span> {useraccount?.balance ? useraccount?.balance : 0} {useraccount && useraccount.currency}</span></p>
                                        </div>

                                        <div className="row depositfirst secrow mb-4">
                                            <div className="col-md-6">
                                                <div >
                                                    <p className="labelname mb-2">Withdraw Amount With Fee</p>
                                                    <div class="input-group mb-3">
                                                        <input type="text" class="form-control" placeholder="" value={finalAmount ? finalAmount : ' '} aria-label="Username" aria-describedby="basic-addon1" />
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" id="basic-addon1">{useraccount && useraccount.currency}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {validateError.finalAmount && <p className="error-message">{t(validateError.finalAmount)}</p>}
                                            </div>
                                            {useraccount && useraccount.type == "demo" ? "" : <div className="col-md-6">
                                                <div >
                                                    <p className="labelname mb-2">Enter  2FA Code</p>
                                                    <div class="input-group mb-3">
                                                        <input type="text" class="form-control" name="twoFACode" value={twoFACode} onChange={onInputChange} placeholder="" aria-label="Username" aria-describedby="basic-addon1" />
                                                    </div>

                                                </div>
                                                {validateError.twoFACode && <p className="error-message">{t(validateError.twoFACode)}</p>}
                                            </div>}
                                        </div>
                                        <hr />
                                        <div className="depositbtnsec my-3">
                                            <button
                                                className="btn btn-primary text-uppercase py-2 m-0"
                                                type="button" onClick={() => handlesubmitwithdraw()}
                                            >
                                                Withdraw Money
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );

}

export default Fundaccount
