import React, { Component } from 'react';

const Signin = () => {
    return (
      <div className='signinpage'>
        <div className='logosec pt-5'>
         <img src={require("../assets/images/logo.png")} alt="Banner" className="img-fluid logo_200" />
         </div>
        <div className='row'>

<div class='card mx-auto d-flex flex-row px-0'>
  <div className='row max_size'>
    <div className='col-12 col-md-6'>
  
<div class="img-left d-md-flex">
  <h3 className='title'>Simple,Free<br/>
  Investing.</h3>
  <div className='overline'>
  <p className='bottom title'>SGBs</p>
  </div>
</div>
</div>
<div className='col-12 col-md-6'>
  <div class="card-body d-flex flex-column justify-content-center">
    <h3 class="title text-center mt-4 pb-3">Welcome to Trading One</h3>
<form class='col-12 mx-auto'>
  <div className='row mb-4 '>
    
   <button className="btn btn-google  py-2 m-0 mx-auto"
                                    type="button"
                                    
                                >
                            <i class="fab fa-google fa-3x"></i> Continue with Google
                                </button>

  </div>


  <div className='row middle my-5'>
  <p><span>Or</span></p>
    
 
   </div>

   <div className='row mt-2 labelname'>
   
     <div className='wid_inp'>
   <div className="input-group mb-3">
    
    

  <input type="email" class="form-control" placeholder="Your Email Address" aria-label="Your Email Address" aria-describedby="basic-addon2"/>
      
     
  
  </div>
</div>

   </div>
  
  
   <div className='row mt-3 text-center'>
 
   <button
                                    className="btn btn-primary text-uppercase py-2 m-0"
                                    type="button"
                                    
                                >
                                 Continue
                                </button>
                                <p className='agreement mt-2'>By proceeding, I agree to <a href="">T&C</a>,<a href="">PrivacyPolicay</a> & <a href="">Tariff Rates</a></p>
                                </div>
</form>
    </div>
    </div>

</div>
</div>

</div>

        
      </div>
    );
  }


export default Signin;