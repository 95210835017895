// import config
import axios,{handleResp} from '../config/axios';

// import constant
import {
    SET_SPOT_TRADE_PAIR_LIST,
    SET_SPOT_PAIR_TRADE,
    SET_SPOT_MARKET_PRICE,
    UPDATE_SPOT_ASSET,
    SET_SPOT_ORDER_BOOK
} from '../constant';
//SPOT 
export const getPairList = async () => {
    // console.log("bcvbd-------------");
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/perpetual/tradePair`,
        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const topGainList = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/top-gain`,
        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}


export const setPairList = (data, dispatch) => {
    // console.log("data--->",data);
    dispatch({
        type: SET_SPOT_TRADE_PAIR_LIST,
        data:data
    })
}

export const setTradePair = ({
    firstCurrencySymbol,
    // firstCurrencyId,
    firstFloatDigit,
    secondCurrencySymbol,
    // secondCurrencyId,
    secondFloatDigit,
    // botstatus,
    high,
    low,
    change,
    spread,
    tiker_root,
    displayname,
    // maker_rebate,
    // taker_ffees,
    // markPrice,
    _id,
    buyposition,
    sellposition,
    minQuantity,
    maxQuantity,
    lotsize,
    pip_position,
    precision,
    bid,
    ask,
    description,
    quoteId,
    fees,
    leverage,
    tradestatus,
    socketsymbol,
    tradehours,
    category,
    commission,
    swapLong,
    swapShort
}, dispatch) => {
    try{
        dispatch({
            type: SET_SPOT_PAIR_TRADE,
            data: {
                firstCurrencySymbol,
                // firstCurrencyId,
                firstFloatDigit,
                secondCurrencySymbol,
                // secondCurrencyId,
                secondFloatDigit,
                // botstatus,
                pairId: _id,
                high,
                low,
                change,
                spread,
                tiker_root,
                displayname,
                // maker_rebate,
                // taker_ffees,
                // markPrice,
                buyposition,
                sellposition,
                minQuantity,
                maxQuantity,
                lotsize,
                pip_position,
                precision,
                bid,
                ask,
                description,
                quoteId,
                fees,
                leverage,
                tradestatus,
                socketsymbol,
                tradehours,
                category,
                commission,
                swapLong,
                swapShort
            }
        })
        return true
    }catch(err){
        console.log(err,'setTradePair----err')
    }
}

export const orderPlace = async (data,type) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/spot/orderPlace`,
            data
        });
        return {
            status: 'success',
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message,
        }
    }
}

export const getOrderBook = async (pairId,type) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/spot/ordeBook/${pairId}`,
        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const getOpenOrder = async (data, pairId,type) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/spot/openOrder/${pairId}`,
            'params': data
        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const allOpenOrder = async (data,type) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/spot/allOpenOrder`,
            'params': data
        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const allOpenOrderDoc = async (data,type) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/spot/allOpenOrderDoc`,
            'params': data
        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const getFilledOrder = async (data, pairId) => {
    
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/spot/filledOrder/${pairId}`,
            'params': data
        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const getOrderHistory = async (data, pairId,type) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/spot/orderHistory/${pairId}`,
            'params': data
        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const getTradeHistory = async (data, pairId,type) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/spot/tradeHistory/${pairId}`,
            'params': data
        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const allTradeOrder = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/spot/allTradeOrder`,
            'params': data
        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const allTradeOrderDoc = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/spot/allTradeOrderDoc`,
            'params': data
        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const getMarketPrice = async (pairId, dispatch) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/spot/marketPrice/${pairId}`,
        });
        setMarketPrice(respData.data.result, dispatch)
        return true
    } catch (err) {
        handleResp(err, 'error')
        return false
    }
}

export const setMarketPrice = ({
    last,
    markPrice,
    low,
    high,
    firstVolume,
    secondVolume,
    changePrice,
    change,
    botstatus,
    _id,
}, dispatch) => {
    dispatch({
        type: SET_SPOT_MARKET_PRICE,
        data: {
            last,
            markPrice,
            low,
            high,
            firstVolume,
            secondVolume,
            changePrice,
            change,
            botstatus,
            _id,
        }
    })

    return true
}

export const getRecentTrade = async (pairId,type) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/spot/recentTrade/${pairId}`,
        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const cancelOrder = async (orderId,type) => {
    try {
        let respData = await axios({
            'method': 'delete',
            'url': `/api/spot/cancelOrder/${orderId}`,
        });
        return {
            status: 'success',
            loading: false,
            message: respData.data.message,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message,
        }
    }
}

export const updateTradeAsset = (dispatch, data) => {
    dispatch({
        type: UPDATE_SPOT_ASSET,
        data
    })
    return true
}

export const orderBookDetail = (dispatch, data) => {
    dispatch({
        type: SET_SPOT_ORDER_BOOK,
        data
    })
    return true
}