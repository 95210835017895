import React, { useState } from 'react';
import { Modal, Tabs, Dropdown, InputGroup, Form, Tab } from 'react-bootstrap'
import { Stopcopying } from 'actions/copyTradeAction';
import { toastAlert } from 'lib/toastAlert';
import { useDispatch } from 'react-redux';
const StopCopying = (props) => {
    console.log("stopCoping_props",props);
    const dispatch = useDispatch()
    const { copyingData, stopAccountId, balanceInvestment } = props
    const [stopCopying, setStopCopying] = useState(true)
    const handleSubmit =async()=>{
        try{
            let data = {
                traderId : copyingData?.userId,
                traderAccountId : copyingData?.accountId,
                followerAccountId : stopAccountId,
                amount: parseFloat(balanceInvestment)
            }
            let {status,message}=await Stopcopying(data,dispatch)
            console.log(status,message,'handleSubmit')
            if(status){
                console.log(status,message,'handleSubmit')
                toastAlert('success', message, 'StopCopying')
                props.onDismiss()
            }else{
                toastAlert('error', message, 'StopCopying')
            }
        }catch(err){
            console.log(err,'handleSubmit--err')
        }
    }
    return (
        <>
            <Modal show={stopCopying} className="modalbg font-12" size="md" centered scrolable>

                <Modal.Header className='align-items-center'>
                    <Modal.Title>Stop Copying - TradingOne</Modal.Title>
                    <button className='closebtn btn btn-link text-light nounder' onClick={() => props.onDismiss()}>
                        <i className="fa fa-times fa-6" aria-hidden="true"></i>

                    </button>
                </Modal.Header>
                <Modal.Body className='font-12'>
                    <div className='themetab modal_align'>

                        <h5>Stop Copying</h5>
                        <hr />
                        <div className='px-0 mt-1 d-flex justify-content-between '>
                            <div className='px-0 content_box'>
                                <small className='text-white'>Strategy</small>
                            </div>
                            <div className='px-0 content_box'>
                                <small className='text-white' >{copyingData?.strategyName}</small>
                            </div>
                        </div>

                        <div className='px-0 mt-1 d-flex justify-content-between '>
                            <div className='px-0  content_box'>
                                <small className='text-white'>Equity</small>
                            </div>
                            <div className='px-0 content_box'>
                                <small className='text-white' >{copyingData?.currency} {balanceInvestment}</small>
                            </div>
                        </div>
                        {/* <div className='px-0 mt-1 d-flex justify-content-between '>
                                    <div className='px-0  content_box'>
                                    <small className='text-white'>To Account</small>

                                    </div>
                                    <div className='px-0 content_box'>
                                    <small className='text-white d-flex text-primary' >Demo &nbsp; <span className='text_align text-white'> - 7000091 - USD 911.20 - 1:200 - XTE - DM</span></small>
                                        
                                    </div>
                                    
                                    
                                </div> */}
                            <div className='px-0 mt-1 d-flex justify-content-between '>
                                <div className='px-0  content_box'>
                                    <small className='text-white'>Volume Fee</small>
                                </div>
                                <div className='content_box'>
                                    <div class="input-group input-group-h25 bg-dark text-white ">
                                        <div class="input-group-prepend ">
                                            <span class="input-group-text text-white " id="basic-addon1">{copyingData.volumeFee} %</span>
                                        </div>
                                        <input type="text" readOnly class="form-control bg-dark readOnly input-group-input" aria-label="Username" aria-describedby="basic-addon1" />
                                    </div>
                                </div>
                            </div>
                            {/* <div className='px-0  content_box'>
                                <small className='text-white'>Performance Fee</small>
                            </div>
                            <div className='content_box'>
                                <div class="input-group input-group-h25 bg-dark text-white ">
                                    <div class="input-group-prepend ">
                                        <span class="input-group-text text-white " id="basic-addon1">USD 0</span>
                                    </div>
                                    <input type="text" readOnly class="form-control bg-dark readOnly input-group-input" aria-label="Username" aria-describedby="basic-addon1" />
                                </div>
                            </div>
                        </div>
                        <div className='px-0 mt-1 d-flex justify-content-between '>
                            <div className='px-0  content_box'>
                                <small className='text-white'>Management Fee</small>
                            </div>
                            <div className='content_box'>
                                <div class="input-group input-group-h25 bg-dark text-white ">
                                    <div class="input-group-prepend ">
                                        <span class="input-group-text text-white " id="basic-addon1">USD 0</span>
                                    </div>
                                    <input type="text" readOnly class="form-control bg-dark readOnly input-group-input" aria-label="Username" aria-describedby="basic-addon1" />
                                </div>
                            </div> */}
                        <p className='my-2'>Existing positions can be changed due to the recalculation of position sizes according to the updated Equity to Equity ration.</p>
                        <p className='my-2'>Your Equity Stop Loss will be removed.</p>
                        <div className='d-flex align-items-center pb-3'>
                            <button className='btn green-btn mr-2 w-full' onClick={()=>{handleSubmit()}}>Stop Copying</button>
                            <button className='btn red-btn w-full' onClick={() => props.onDismiss()}>Cancel</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default StopCopying