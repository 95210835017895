// import config
import isEmpty from 'lib/isEmpty';
import axios, { handleResp } from '../config/axios';


// import constant
import {
    STRATEGY_DETAILS,
    FOLLOWERS_DETAILS,
    PROVIDER_SUMMARY,
} from '../constant'

export const createStrategy = async (data, dispatch) => {
    console.log("data_data_data", data);
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/copytrade/createStrategy`,
            'data': data
        });
        // console.log('-----',respData.data.result)
        dispatch(setStrategyDetails(respData.data.result))
        return {
            status: 'success',
            loading: false,
            message: respData.data.message,
            // result: respData.data.result,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const updateStrategy = async (data) => {
    try {
        let respData = await axios({
            'method': 'put',
            'url': `/api/copytrade/updateStrategy`,
            'data': data
        });
        console.log('-----', respData.data.result)
        return {
            status: 'success',
            loading: false,
            message: respData.data.message,
            // result: respData.data.result,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',

            loading: false,
        }
    }
}

export const getStrategy = async (dispatch) => {
    try {
        console.log('call')
        let respData = await axios({
            'method': 'get',
            'url': `/api/copytrade/getStrategy`
        });
        // console.log('respData.data.result-----',respData.data.result)
        if (respData.data.status) {
            dispatch(setStrategyDetails(respData.data.result))
        } else {
            return {
                status: 'success',
                loading: false,
                message: respData.data.message,
                //result: respData.data.result,
            }
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',

            loading: false,
        }
    }
}

export const getSingelStrategy = async (accountId) => {
    try {
        console.log('call')
        let data = { accountId: accountId }
        let respData = await axios({
            'method': 'get',
            'url': `/api/copytrade/getSingelStrategy`,
            'params': data
        });
        // console.log('respData.data.result-----',respData.data.result)
        return {
            status: 'success',
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',

            loading: false,
        }
    }
}

export const setStrategyDetails = (data) => {
    return {
        type: STRATEGY_DETAILS,
        data
    }
}
export const setProviderSummary = (data) => {
    return {
        type: PROVIDER_SUMMARY,
        data
    }
}

export const stopStrategy = async (data, dispatch) => {
    console.log("data_datadataV_datav", data);
    try {
        let respData = await axios({
            'method': 'put',
            'url': `/api/copytrade/stopStrategy`,
            'data': data
        });
        dispatch(setStrategyDetails(respData.data.result))
        return {
            status: respData.data.status,
            loading: false,
            message: respData.data.message,
            // result: respData.data.result,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',

            loading: false,
        }
    }
}
export const RestartProviding = async (data, dispatch) => {
    console.log("data_data_data_vxxsx", data);
    try {
        let respData = await axios({
            'method': 'put',
            'url': `/api/copytrade/RestartProvidingStrategy`,
            'data': data
        });
        if (respData.data.result) {
            dispatch(setStrategyDetails(respData.data.result))
        }
        if (respData.data.FolloweDetalis) {
            dispatch(setFollowerDetails(respData.data.FolloweDetalis))
        }

        return {
            status: respData.data.status,
            loading: false,
            message: respData.data.message,
            // result: respData.data.result,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',

            loading: false,
        }
    }
}

export const fetchStrategy = async (page) => {
    try {
        console.log('call')
        let respData = await axios({
            'method': 'get',
            'url': `/api/copytrade/fetchStrategy`,
            params: { page: page }
        });
        // console.log('respData.data.result-----',respData.data.result)
        if (respData.data.status) {
            return {
                status: 'success',
                loading: false,
                message: respData.data.message,
                result: respData.data.result,
                count: respData.data.strategyCount,
                favourList: respData.data.favourList
            }
        } else {
            return {
                status: 'success',
                loading: false,
                message: respData.data.message,
                //result: respData.data.result,
            }
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',

            loading: false,
        }
    }
}

export const Startcopying = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/copytrade/Startcopying`,
            'data': data
        });
        console.log('Startcopying-----', respData.data.result)
        if (!isEmpty(respData.data.result)) {
            dispatch(setFollowerDetails(respData.data.result))
        }
        return {
            status: respData.data.status ? 'success' : "Fail",
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const copyfavouriteList = async (data) => {
    console.log("copyfavouriteList", data);
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/copytrade/CopyFavouriteList`,
            'data': data
        });
        console.log('CopyFavouriteList-----', respData.data)
        return {
            status: 'success',
            loading: false,
            message: respData.data.message,
            // result: respData.data.result,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const Tradefollowers = async (dispatch, data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/copytrade/Tradefollowers`,
            'data': data
        });
        console.log("respData_V_respData_v", respData.data.result, respData.data);
        if (respData.data.status == 'success') {
            dispatch(setFollowerDetails(respData.data.result))
        }

        return {
            status: 'success',
            result: respData.data.result,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const setFollowerDetails = (data) => {
    return {
        type: FOLLOWERS_DETAILS,
        data
    }
}

export const AddFunds = async (data, dispatch) => {
    console.log("dataa_dadasdasdasd", data);
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/copytrade/addFunds`,
            'data': data
        });
        console.log("respData_respDatadascsd_cvsdvs", respData, data);
        if(!isEmpty(respData.data.result)){
            dispatch(setFollowerDetails(respData.data.result))
        }
        return {
            status: 'success',
            result: respData.data
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const TraderDetails = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/copytrade/traderDetails`,
            'data': data
        });
        console.log("respData_respDatadascsd_cvsdvs", respData, data);
        return {
            status: respData.data.status,
            result: respData.data.result,
            message: respData.data.message
        }
    } catch (err) {
        console.log(err, 'TraderDetails--err')
    }
}

export const Stopcopying = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/copytrade/stopcopying`,
            'data': data
        });
        console.log("respData_respDatadascsd_cvsdvs", respData.data, respData.data.result);
        dispatch(setFollowerDetails(respData.data.result))
        return {
            status: respData.data.status,
            message: respData.data.message
        }
    } catch (err) {
        console.log(err, 'StopCopying--err')
    }
}

export const SetEquitystopLoss = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/copytrade/setequitystoploss`,
            'data': data
        });
        console.log("respData_respDatadascsd_cvsdvs", respData, data);
        return {
            status: respData.data.status,
            message: respData.data.message
        }
    } catch (err) {
        console.log(err, 'SetEquitystop--err')
    }
}
export const ReStartcopying = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/copytrade/restartCopying`,
            'data': data
        });
        console.log('Restartcopying_Restartcopying', respData.data)
        dispatch(setFollowerDetails(respData.data.result))
        return {
            status: 'success',
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const getproviderSummary = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/copytrade/providerSummary`,
            'data': data
        });
        console.log('getproviderSummary_getproviderSummary', respData.data)
        dispatch(setProviderSummary(respData.data.result))
        return {
            status: 'success',
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const getCopyierSummary = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/copytrade/copyingSummary`,
            'data': data
        });
        console.log('getproviderSummary_getproviderSummary', respData.data.result)
        // dispatch(setProviderSummary(respData.data.result))
        return {
            status: 'success',
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const getCopierChartData = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/copytrade/copyingChartDetails`,
            'data': data
        });
        console.log('getCopierChartData_getCopierChartData', respData.data)
        return {
            status: 'success',
            loading: false,
            message: respData.data.message,
            result: respData.data,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const getVolumeBreakdown = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/copytrade/getProviderVolumeBreakdown`,
            'data': data
        });
        console.log('getVolumeBreakdown', respData.data)
        return {
            status: 'success',
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}


export const filterSymbolData = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/copytrade/filterSymbolData`,
            'data': data
        });
        // console.log('filterSymbolData', respData.data)
        return {
            status: 'success',
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const getProviderPerformanceData = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/copytrade/getProviderPerformance`,
            'data': data
        });
        console.log('getProviderPerformanceData', respData.data)
        return {
            status: 'success',
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
            type: respData.data.type,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const getRIO = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/copytrade/getRoi`,
            'data': data
        });
        console.log('getRIO', respData.data)
        return {
            status: 'success',
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const getRioEquity = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/copytrade/getRioEquity`,
            'data': data
        });
        console.log('getRIO', respData.data)
        return {
            status: 'success',
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
            min: respData?.data?.min
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const getEquity = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/copytrade/getProviderEquity`,
            'data': data
        });
        console.log('getRIO', respData.data)
        return {
            status: 'success',
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
            min: respData?.data?.min
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const getTradeFilter = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/copytrade/getFilterTraderDetails`,
            'data': data
        });
        console.log('getTradeFilter', respData.data)
        return {
            status: 'success',
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const getStrategyFilter = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/copytrade/filterStrategy`,
            'data': data
        });
        console.log('getStrategyFilter', respData.data)
        return {
            status: 'success',
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const getProviderAllAccountDetails = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/copytrade/userProviderAccounts`,
            'data': data
        });
        console.log('getProviderAllAccountDetails', respData.data)
        return {
            status: 'success',
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
        }
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const removeFundAction =async(data)=>{
    try{
        let respData = await axios({
            'method': 'post',
            'url': `/api/copytrade/removeFund`,
            'data': data
        });
        console.log('removeFund', respData.data)
        return {
            status: respData.data.status,
            loading: false,
            message: respData.data.message
        }
    }catch(err){
        console.log(err,"removeFund__err")
        handleResp(err, 'error')
        return {
            status: err.response.data.message,
            loading: false,
            message:  err.response.data.message
        }
    }
}