import React, { Component, useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap'
import Chart from "react-apexcharts";
import { getProviderPerformanceData } from 'actions/copyTradeAction';
const Performance = (props) => {
  const {TrdaerDetail,Followerdetails}=props
  console.log('Performance', props);
  const [loss, setloss] = useState([]);
  const [won, setWon] = useState([]);
  const [months, setMonths] = useState(['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']);
  const [days, setDays] = useState(['Monday', 'Tuesday', 'Wednesday', 'Thrushday', 'Friday'])
  const [type, setType] = useState("monthly")
  const [year, setYear] = useState(2023)
  const [button, setButton] = useState(false)
  const [chartData, setChartData] = useState([])
  useEffect(() => {
    getPerformance(2023, 'monthly')
  }, [TrdaerDetail,Followerdetails])

  const data2 = {
    options2: {
      chart: {
        id: "basic-bar",
        foreColor: "#ccc",
        toolbar: {
          autoSelected: "pan",
          show: false
        }
      },
      colors: ['#009345', '#f05824'],
      stroke: {
        width: 3
      },
      grid: {
        borderColor: ["#555"],
        clipMarkers: false,
        yaxis: {
          lines: {
            show: false
          },
        }
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        // gradient: {
        //   enabled: true,
        //   opacityFrom: 0.75,
        //   opacityTo: 0
        // }
        colors: ['#009345', '#f05824']
      },
      markers: {
        size: 2,
        colors: ["#2769ba"],
        strokeColor: "#2769ba",
        strokeWidth: 3
      },
      tooltip: {
        theme: "dark",
        x: {
          format: function(value,series){
            return parseFloat(value)
          }
        }
      },
      xaxis: {
        categories: type == 'monthly' ? months : days
      },
      yaxis: {
        labels: {
          show: false
        }
      }
    },
    series2: [{
      name: 'Winning Trades',
      type: 'column',
      data: type == 'monthly' ? won : won,
    }, {
      name: 'Losing Trades',
      type: 'column',
      data: type == 'monthly' ? loss : loss
    }],
  };

  const getPerformance = async (year, type) => {
    // console.log("getPerformance_data", year,type)
    setType(type)
    setYear(year)
    let payload
    if(props?.type == 'copyier'){
      payload = {
        followerAccountId: props.Followerdetails?.followerAccountId,
        traderAccountId: props?.TrdaerDetail?.accountId,
        year: year,
        type: type,
        accountType: 'copy'
      }
    }else{
      payload = {
        accountId: props?.TrdaerDetail?.accountId,
        userId: props?.TrdaerDetail?.userId,
        year: year,
        type: type,
        accountType: props.type
      }
    }
    console.log('getPerformancepayload',payload);
    if (Object.values(payload) != undefined || Object.values(payload) != '') {
      const getData = await getProviderPerformanceData(payload);
      let won = [];
      let loss = [];
      if (getData.type == 'monthly') {
        months.map((val, ind) => {
          if (getData?.result?.some((dat) => dat.month == ind + 1)) {
            getData.result.map((value, index) => {
              if (value.month == ind + 1) {
                won.push(value.totalWiningTrades)
                loss.push(value.totalLosingTrades)
              }
            })
          }
          else {
            won.push(0)
            loss.push(0)
          }
        })
      }
      else {
        days.map((val, ind) => {
          if (getData?.result?.some((dat) => dat.date == ind + 1)) {
            getData.result.map((value, index) => {
              if (value.date == ind + 1) {
                won.push(value.totalWiningTrades)
                loss.push(value.totalLosingTrades)
              }
            })
          }
          else {
            won.push(0)
            loss.push(0)
          }
        })
      }

      setWon(won)
      setloss(loss)
      setChartData(getData.result)
      console.log("getData_performancedata",{getData: getData, won: won, loss: loss})
    }

  }
  return (

    <div className='tab_yr height_tabyr_align'>
      <Tabs defaultActiveKey="monthly" id="uncontrolled-tab-example3" className="graytab tab_style_inner_grey pt-2 px-0" onSelect={(el) => { getPerformance(year, el) }}>

        <Tab eventKey="monthly" title="Monthly" className="px-0">

          {chartData.length > 0 ?
            <Chart options={data2?.options2}
              series={data2?.series2}
              type="bar" height={200} width="100%" className="px-0" />
            :<div className='performance_nodata_txt'> <div className='nodata'>No data found</div></div>
          }
        </Tab>


        <Tab eventKey="daily" title="Daily" className="px-0">
          {chartData.length > 0 ?
            <Chart options={data2?.options2}
              series={data2?.series2}
              type="bar" height={200} width="100%" className="px-0" />
            : <div className='performance_nodata_txt'> <div className='nodata'>No data found</div></div>
          }
        </Tab>

      </Tabs>

      <div className='chartnav'>
        <div className='chartnav_in'>

          <button className={button == false ? "btn_only_tab btn_tab_pos inchart active" : "btn_only_tab btn_tab_pos inchart"} onClick={() => { setButton(false); getPerformance(new Date().getFullYear(), type) }}>
            {new Date().getFullYear()}
          </button>
          <button className={button == true ? "btn_only_tab btn_tab_pos inchart next_yr active" : "btn_only_tab btn_tab_pos inchart next_yr"} onClick={() => { setButton(true); getPerformance(new Date().getFullYear() - 1, type) }}>
            {new Date().getFullYear() - 1}
          </button>
        </div>
      </div>
    </div>

  )
}

export default Performance