import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "classnames";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import "./styles.css";
import "./example-styles.css";
import { Link, NavLink, useHistory } from 'react-router-dom';
import {
  Tabs,
  Tab,
  InputGroup,
  FormControl,
  Form,
  Button,
  Dropdown,
  Accordion,
  Card,
  Col,
  Row,
} from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// import component
import OrderPlace from "./OrderPlace";
import MarketPrice from "./MarketPrice";
import OrderBook from "./OrderBook";
import UserOrderList from "./UserOrderList";
import MarketPriceTable from "./Markerpricetable";
import NewOrder from "./newtrade/Modals/NewOrder";
import DoneOrder from "./newtrade/Modals/DoneOrder";
import Chart from "react-apexcharts";
// import Chart from "./Chart";
import RecentTrade from "./RecentTrade";
import Sidebar from "./newtrade/Sidebar";
// import Sidebar from "./newtrade/Sidebar";

import info from "../../assets/images/newtrade/info.svg";
import setting from "../../assets/images/newtrade/setting.svg";
import shield from "../../assets/images/newtrade/shield.svg";
import reverse from "../../assets/images/newtrade/reverse.svg";
import double from "../../assets/images/newtrade/double.svg";
import close from "../../assets/images/newtrade/close.svg";
import star from "../../assets/images/newtrade/star.svg";
import flag from "../../assets/images/newtrade/flag.svg";
import Wishlisttab from "./newtrade/elements/Wishlisttab";
import Symboltab from "./newtrade/elements/Symboltab";
import Slider from "@material-ui/core/Slider";
import MoreFilter from "./newtrade/Modals/MoreFilter";
import StartCopying from "./newtrade/Modals/StartCopying";
import AddFund from "./newtrade/Modals/AddFund";
import RestartCopying from "./newtrade/Modals/RestartCopying";
import { momentFormat } from '../../lib/dateTimeHelper'

//import action
import { fetchStrategy, copyfavouriteList } from "actions/copyTradeAction";
import CopyProvidingAccounts from "./CopyElements/copyProviderAccounts";
import { getStrategyFilter } from "actions/copyTradeAction";
import isEmpty from "lib/isEmpty";
import { TraderDetails } from 'actions/copyTradeAction';
// import { copyfavouriteList } from "../../actions/copyTradeAction";
const Copytrade = (props) => {
  const { isAuth } = useSelector(state => state.auth);
  const { useraccount, accounts } = useSelector(state => state.account)
  const [chart, setchart] = useState();
  const [filterbtn, setFilterbtn] = useState(false);
  const [favArray, setfavArray] = useState();
  const [Strategycount, setStrategycount] = useState(0)
  const [page, Setpage] = useState(1)
  const orderBookRef = useRef();
  const { t, i18n } = useTranslation();
  const [pageNumbers, setpageNumbers] = useState([])
  // state
  const [expandScreen, setExpandScreen] = useState("");

  const [currentBreakpoint, setCurrentBreakpoint] = useState("lg");
  const [compactType, setCompactType] = useState("vertical");
  const [mounted, setMounted] = useState(false);
  const [layouts, setLayouts] = useState({
    lg: props.initialLayout,
  });
  const [neworder, setNewOrder] = useState(false);
  const [doneorder, setDoneOrder] = useState(false);

  const [flatnav, setFlatNav] = useState(false);
  const [symbolTab, setSymbolTab] = useState(false);
  const [condition,Setcondition] =  useState([])
  const favorites = (
    <>
      Favorites <span className="badge badge-secondary px-2">{favArray?.length}</span>
    </>
  );
  const [morefilter, setMoreFilter] = useState(false)
  const [strat, setStrat] = useState(false)
  const [startCopying, setStartCopying] = useState(false)
  const [CopyingData, setCopyingData] = useState({})
  const [strategys, setstrategys] = useState([])

  //filter-states
  const [search, setSearch] = useState("")
  const [accountType, setAccountType] = useState("All")
  const [roi, setRoi] = useState("Any")
  const [days, setDays] = useState("Any")
  const [filterData, setFilterData] = useState({})
  const [restartCopying, setRestartCopying] = useState(false)
  const [addFunds, SetAddFund] = useState(false)
  const [restartAccount, setrestartAccount] = useState({})
  const [copyTradeFollowID, setCopyTradeFollowID] = useState("")
  const [startedAccount, sestartedAccount] = useState({})
  const [copiedAccount, setcopiedAccount] = useState({})
  const [time,setTime]=useState("Any")
  console.log("strategys_V_strategysV", strategys);
  const strategy = (e) => {
    let a = e.target.nextElementSibling.classList
    if (a.contains("d-block")) {
      e.target.nextElementSibling.classList.add("d-none")
      e.target.nextElementSibling.classList.remove("d-block")

    } else {
      e.target.nextElementSibling.classList.remove("d-none")
      e.target.nextElementSibling.classList.add("d-block")
    }

  }
  const closeallstrat = () => {
    if (document.getElementById("stratbtn").classList.contains("d-block")) {
      document.getElementById("stratbtn").classList.add("d-none")
      document.getElementById("stratbtn").classList.remove("d-block")
      setStrat(!strat)
    } else {
      document.getElementById("stratbtn").classList.remove("d-none")
      document.getElementById("stratbtn").classList.add("d-block")
      setStrat(!strat)
    }
  }
  // function

  const onBreakpointChange = (breakpoint) => {
    setCurrentBreakpoint(breakpoint);
  };

  useEffect(() => {
    setMounted(true);
    if (isAuth) {
      getStrategys(page)
    }

  }, [isAuth]);
  const history = useHistory();

  const [showdrop, setShowDrop] = useState(false);
  const [showdropdate, setShowDropDate] = useState(false);

  const [info, setInfo] = useState(false);
  const [active, isActive] = useState(1);
  const [expand, setExpand] = useState(false);
  const [shrink, setShrink] = useState(false);



  const setWhole = (value) => {
    console.log(value, "value");
    // 👇️ take parameter passed from Child component
    setShrink(value);
  };

  const getStrategys = async (page) => {
    try {
      let { status, loading, message, result, favourList, count } = await fetchStrategy(page)
      console.log("getStrategys_getStrategys", result, favourList, count);
      if (status) {
        if (result.length > 0) {
          const nPages = Math.ceil(count / 10)
          const pageNumbers = [...Array(nPages + 1).keys()].slice(1)
          setpageNumbers(pageNumbers)
        }
        setStrategycount(count)
        setstrategys(result)
        setfavArray(favourList)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const openAcc = (e) => {
    console.log(e.target.nextElementSibling, "e");
    var cont = e.target.parentNode.nextElementSibling;
    if (!cont.classList.contains("d-none")) {
      cont.classList.add("d-none");
    } else {
      cont.classList.remove("d-none");
    }
  };

  const data = {
    options: {
      chart: {
        id: "chart1",
        type: "area",
        height: 350,
        foreColor: "#ccc",
        background: "#3b3b3b",
        toolbar: {
          autoSelected: "pan",
          show: false
        }
      },
      colors: ["#00BAEC"],
      stroke: {
        width: 3
      },
      grid: {
        borderColor: "#555",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: true
          }
        },
        xaxis: {
          lines: {
            show: true
          }
        }
      },
      markers: {
        size: 0,
        colors: undefined,
        strokeColors: '#fff',
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
        discrete: [],
        shape: "circle",
        radius: 2,
        offsetX: 0,
        offsetY: 0,
        onClick: undefined,
        onDblClick: undefined,
        showNullDataPoints: true,
        hover: {
          size: undefined,
          sizeOffset: 0
        }
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        gradient: {
          enabled: true,
          opacityFrom: 0.55,
          opacityTo: 0
        }
      },
      // markers: {
      //   size: 5,
      //   colors: ["#000524"],
      //   strokeColor: "#00BAEC",
      //   strokeWidth: 3
      // },
      yaxis: {
        tickAmount: 4,
        labels: {
          show: false
        },
        tooltip: {
          enabled: false
        }
      },
      xaxis: {
        type: 'datetime',
        min: 1687962272801,
        max: new Date().getTime(),
        show: false,
        labels: {
          show: true
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        tooltip: {
          enabled: true
        }
      },
    },
  };


  const addfavour = async (val) => {
    const data = { favourId: val._id }
    const favourList = await copyfavouriteList(data);
    console.log("addfavour", favourList);
    if (favourList.status = "success") {
      getStrategys(page)
    }
  }


  const filter = async (search, type, roi, days, moreFilter) => {
    setSearch(search);
    setAccountType(type);
    setRoi(roi);
    setDays(days);
    let moreFilterstat = false
    if (!isEmpty(moreFilter)) {
      moreFilterstat = true
    }
    // payload['search'] = search,
    // payload['accountType'] = type,
    // payload['roi'] = roi,
    // payload['days'] = days,
    // payload['date'] = days == 'Any' ? false : true
    var payload = {
      search: search,
      accountType: type,
      roi: roi,
      days: days,
      date: days == 'Any' ? false : true,
      ownFunds: moreFilter.ownFunds ? moreFilter.ownFunds : 0,
      copyier: moreFilter.copyier ? moreFilter.copyier : 0,
      copyingCapital: moreFilter.copyingCapital ? moreFilter.copyingCapital : 0,
      volumeFee: moreFilter.volumeFee ? moreFilter.volumeFee : 0,
      moreFilter: moreFilterstat
    }
    setFilterData(payload)
    console.log('getStrategyFilter_frontEnd', payload);

    const getData = await getStrategyFilter(payload);
    setstrategys(getData.result)

    console.log("getStrategyFilter_getData", getData)
  }
  const getmonth = (data) => {
    try {
      if (data == "Current Month") {
        let lastOneMonth = new Date()
        lastOneMonth.setMonth(lastOneMonth.getMonth() - 1)
        return lastOneMonth.getTime()
      }
      if (data == "Last 2 Month") {
        return Date.now() - (86400000 * (new Date().getDate() + 30))
      }
      if (data == "Last 3 Month") {
        return Date.now() - (86400000 * (new Date().getDate() + 61))
      }
      if (data == "Last 6 Month") {
        let lastSixMonth = new Date()
        lastSixMonth.setMonth(lastSixMonth.getMonth() - 6)
        return lastSixMonth.getTime()
      }
      if (data == "Last Year") {
        let lastYear = new Date()
        lastYear.setFullYear(lastYear.getFullYear() - 1)
        return lastYear.getTime()
      }
    } catch (err) {
      console.log('getmonth---err', err)
    }
  }
  const getTradeDetails = async (val, accountData) => {
    try {
      let data = {
        traderAccountId: val?.accountId,
        traderId: val?.userId
      }
      let { status, result, message } = await TraderDetails(data)
      if (status) {
        let FollowingData = result?.Followers?.find((vals) => ((vals.followerAccountId).toLowerCase() == accountData.accountId.toLowerCase()))
        console.log(FollowingData, accountData, "FollowingData")
        if (!FollowingData || FollowingData?.status == "removedByProvider") {
          StartCopyingShow(accountData.accountId)
        }
        else if (FollowingData) {
          console.log("I am worked")
          if (FollowingData.status == "inactive") {
            restartShow(accountData.accountId, FollowingData._id)
          }
          else if (FollowingData.status == "active") {
            addfundShow(accountData.accountId)
          }
        }
      }
      else {
        console.log(message, 'TradeDetail')
      }
    } catch (err) {
      console.log(err, 'getTradeDetails--err')
    }
  }
  const restartShow = (accountId, id) => {
    try {
      let accountData = accounts.find((val) => (val.accountId == accountId));
      setCopyTradeFollowID(id)
      setrestartAccount(accountData);
      setRestartCopying(true)
    } catch (err) {
      console.log(err, "restartShow__err")
    }
  }
  const addfundShow = (accountId) => {
    try {
      let accountData = accounts.find((val) => (val.accountId == accountId));
      setcopiedAccount(accountData);
      SetAddFund(true)
    } catch (err) {
      console.log(err, "addfundShow__err")
    }
  }
  const StartCopyingShow = (accountId) => {
    try {
      let accountData = accounts.find((val) => (val.accountId == accountId));
      sestartedAccount(accountData);
      setStartCopying(true)
    } catch (err) {
      console.log(err, "StartCopyingShow__err")
    }
  }
  return (
    <div
      className={
        shrink
          ? "sticky_sidebar d-flex gap-3 shrink_div main_div_col"
          : "sticky_sidebar d-flex gap-3 main_div_col"
      }
    >
      {startCopying && <StartCopying
        onDismiss={() => setStartCopying(false)}
        copyingData={CopyingData}
        startedAccount={!isEmpty(startedAccount) ? startedAccount : useraccount}
        // restart={(accountId, id) => {
        //   let accountData = accounts.find((val) => (val.accountId == accountId));
        //   setCopyTradeFollowID(id)
        //   setrestartAccount(accountData);
        //   setRestartCopying(true)
        // }}
        // AddFund={(accountId) => {
        //   let accountData = accounts.find((val) => (val.accountId == accountId));
        //   setcopiedAccount(accountData);
        //   SetAddFund(true)
        // }}
        restart={(accountId, id) => { restartShow(accountId, id) }}
        AddFund={(accountId) => { addfundShow(accountId) }}
      />
      }
      {addFunds && <AddFund
        onDismiss={() => SetAddFund(false)}
        copyingData={CopyingData}
        // startCopying={(accountId) => {
        //   let accountData = accounts.find((val) => (val.accountId == accountId));
        //   sestartedAccount(accountData);
        //   setStartCopying(true)
        // }}
        // restart={(accountId, id) => {
        //   let accountData = accounts.find((val) => (val.accountId == accountId));
        //   setCopyTradeFollowID(id)
        //   setrestartAccount(accountData);
        //   setRestartCopying(true)
        // }}
        startCopying={(accountId) => { StartCopyingShow(accountId) }}
        restart={(accountId, id) => { restartShow(accountId, id) }}
        copiedAccount={isEmpty(copiedAccount) ? useraccount : copiedAccount} />
      }
      {restartCopying && <RestartCopying
        onDismiss={() => setRestartCopying(false)}
        copyingData={CopyingData}
        copyTradeFollowID={copyTradeFollowID}
        restartAccount={restartAccount}
        // startCopying={(accountId) => {
        //   let accountData = accounts.find((val) => (val.accountId == accountId));
        //   sestartedAccount(accountData);
        //   setStartCopying(true)
        // }}
        // AddFund={(accountId) => {
        //   let accountData = accounts.find((val) => (val.accountId == accountId));
        //   setcopiedAccount(accountData);
        //   SetAddFund(true)
        // }}
        startCopying={(accountId) => { StartCopyingShow(accountId) }}
        AddFund={(accountId) => { addfundShow(accountId) }}
      />}
      <Sidebar setWhole={setWhole} activetabkey={"1"} id="side_copy" />
      {neworder && (
        <NewOrder
          onDismiss={() => setNewOrder(false)}
          openinfo={() => {
            setInfo(true);
            setNewOrder(false);
          }}
        />
      )}
      {doneorder && <DoneOrder onDismiss={() => setDoneOrder(false)} />}

      <div className="w-full calc_width">
        <Tabs
          defaultActiveKey="Startegies"
          id="uncontrolled-tab-example"
          className="graytab pt-0 px-0 withot_wrap"
        >
          <Tab eventKey="Startegies" title="Strategies" className="p-0">
            <div>
              <div className="d-lg-flex align-items-center p-0 topmenus">
                <div className="d-flex align-items-center p-0 leftmenu">
                  <div className="pl-0">
                    <div className="d-flex align-items-center bg-black gap-2 p-0">
                      <InputGroup className="themeinput bg-black">
                        <FormControl placeholder="Enter Startegy Name" className="bg-black" onChange={(e) => { filter(e.target.value, accountType, roi, days, {}) }} />
                        <InputGroup.Append>
                          <InputGroup.Text id="basic-addon2">
                            <span className="fa fa-search"></span>
                          </InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                    </div>
                  </div>
                  <div className="d-flex align-items-center p-0">
                    <p className="f-12">Account type</p>
                    <Dropdown onSelect={(type) => { filter(search, type, roi, days, {}) }}>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="themebtn bg-black w-60 py-1 px-2 m-0"
                      >
                        {accountType}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item eventKey='All'>All</Dropdown.Item>
                        <Dropdown.Item eventKey='demo'>Demo</Dropdown.Item>
                        <Dropdown.Item eventKey='live'>Live</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="d-flex align-items-center p-0">
                    <p className="f-12">ROI</p>
                    <Dropdown onSelect={(e) => { filter(search, accountType, e, days, {}) }}>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="themebtn bg-black  py-1 w-72 px-2 m-0"
                      >
                        {roi == 'Any' ? roi : roi + "%"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item eventKey='0'>   &gt; 0%</Dropdown.Item>
                        <Dropdown.Item eventKey='5'>   &gt; 5%</Dropdown.Item>
                        <Dropdown.Item eventKey='10'>  &gt; 10% </Dropdown.Item>
                        <Dropdown.Item eventKey='15'>  &gt; 15% </Dropdown.Item>
                        <Dropdown.Item eventKey='25'>  &gt; 25% </Dropdown.Item>
                        <Dropdown.Item eventKey="50">  &gt; 50% </Dropdown.Item>
                        <Dropdown.Item eventKey="Any"> Any      </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="d-flex align-items-center p-0">
                    <p className="f-12">Time</p>
                    <Dropdown onSelect={(e) => { filter(search, accountType, roi, e, {}) }}>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="themebtn bg-black  py-1 w-72 px-2 m-0"
                      >
                        {time}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item eventKey={Date.now() - (86400000 * new Date().getDay())} onClick={()=>{setTime("1 week")}}>1 week</Dropdown.Item>
                        <Dropdown.Item eventKey={getmonth("Current Month")} onClick={()=>{setTime("1 Month")}}>1 Month</Dropdown.Item>
                        <Dropdown.Item eventKey={getmonth("Last 3 Month")} onClick={()=>{setTime("3 Months")}}> 3 Months</Dropdown.Item>
                        <Dropdown.Item eventKey={getmonth("Last 6 Month")} onClick={()=>{setTime("6 Months")}}>6 Months</Dropdown.Item>
                        <Dropdown.Item eventKey={getmonth("Last Year")} onClick={()=>{setTime("1 Year")}}>1 Year</Dropdown.Item>
                        <Dropdown.Item eventKey='Any' onClick={()=>{setTime("Any")}}>Any</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    {/* //       <Form.Check.Label>
              //     <select className="themebtn bg-black  py-1 w-72 px-2 m-0 selctsm" onChange={e => {filter(search,accountType,roi,e)}}>
              //          <option eventKey={Date.now() - (86400000 * new Date().getDay())}>1 weak</option>
              //           <option eventKey={getmonth("Current Month")}>1 Month</option>
              //           <option eventKey={getmonth("Last 3 Month")}> 3 Months</option>
              //           <option eventKey={getmonth("Last 6 Month")}>6 Months</option>
              //           <option eventKey={getmonth("Last Year")}>1 Year</option>
              //           <option eventKey='Any'>Any</option>
              //   </select>
              // </Form.Check.Label> */}
                  </div>
                  <div className="ml-3 topcheck">
                    <Form.Check
                      type="checkbox"
                      label="Demo copying allowed"
                      className="mb-0"
                    />
                  </div>
                  <div className="">
                    <button
                      className="btn bg-dark py-1"
                      onClick={() => { setFilterbtn(!filterbtn); setMoreFilter(true) }}
                    >
                      More filter
                    </button>
                    <div className={filterbtn ? "d-block filter_button" : "d-none"}>
                      {morefilter && <MoreFilter onDismiss={() => setMoreFilter(false)} onSet={(data) => { setFilterData(data); filter(search, accountType, roi, days, data) }} />}
                    </div>
                  </div>
                </div>
                {/* <div className="d-flex align-items-center ml-auto p-0 rightmenu">
                  <p className="f-12">Sort result by</p>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="w-60 px-2  py-1 bg-black"
                    >
                      Rank
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Another action
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Something else
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div> */}
              </div>
              <div className="darkbox p-0">
                <ul className="pl-0 mb-0 font-12 copytrade copyscroll">
                  {strategys && strategys.length > 0 ?
                    strategys.map((val, index) => (
                      <li className="px-2">
                        <div className="d-lg-flex copytradeboxes p-0">
                          <div className="d-lg-flex  flex-1 mw-610 w-full align-items-center">
                            <div className="d-flex align-items-start gap-2">
                              {/* <span className="fa fa-star cutr_poinrt_fav mt-1 active" onClick={()=>{addfavour(val)} }></span> */}
                              <span className={val.isFav == true ? "fa fa-star cutr_poinrt_fav mt-1 active" : "fa fa-star cutr_poinrt_fav mt-1"} onClick={() => { addfavour(val) }}></span>
                              <div className="p-0 w-full"
                                onClick={() => history.push({ pathname: "/strategy", state: { strategydetails: val } })}
                              >
                                <div className="d-flex jc-between p-0 mb-3">
                                  <p>{val.strategyName}</p>
                                  {val.type == "live" ? <button className="btn btn-outline-success f-12 icon-50 p-0 px-2 my-0">{val.type}</button> :
                                    <button className="btn btn-outline-info f-12 icon-50 p-0 px-2 my-0">{val.type}</button>
                                  }
                                  {/* <button className="btn btn-outline-success f-12 icon-50 p-0 px-2 my-0">{val.type}</button> */}
                                </div>
                                <div className="font-11 p-0">
                                  <div className="d-md-flex jc-between p-0 position-relative d-none">
                                    <p className="lightgray">Strategy Provider</p>
                                    <button
                                      type="button"
                                      onClick={(e) => {
                                        setStrat(!strat);
                                        e.stopPropagation()
                                        console.log(document.getElementById(`stratbtn${index}`), "document.getElementById(`stratbtn${index}`)")
                                        if (!strat) {
                                          document.getElementById(`stratbtn${index}`).classList.remove("d-none");
                                          document.getElementById(`stratbtn${index}`).classList.add("d-block");
                                          document.getElementById(`overlay${index}`).classList.remove("d-none");
                                          document.getElementById(`overlay${index}`).classList.add("provider-overlay");
                                        }
                                      }}
                                      className="strategydrop mb-1">
                                      {val.strategyName}
                                      <span className="fa fa-chevron-down"></span>
                                    </button>
                                    <div className={"provider lightbox d-none"} id={`stratbtn${index}`} >
                                      {/* onClick={(e) => { e.stopPropagation() }} */}
                                      {strat && <CopyProvidingAccounts details={val} />}
                                    </div>
                                    <div className={"d-none"} id={`overlay${index}`}
                                      onClick={(e) => {
                                        // closeallstrat(e); 
                                        if (strat) {
                                          document.getElementById(`stratbtn${index}`).classList.remove("d-block");
                                          document.getElementById(`stratbtn${index}`).classList.add("d-none")
                                          document.getElementById(`overlay${index}`).classList.remove("provider-overlay");
                                          document.getElementById(`overlay${index}`).classList.add("d-none");
                                        }
                                        e.stopPropagation()
                                      }}>

                                    </div>
                                  </div>
                                  <hr className="themehr" />
                                  <div className="d-flex  gap-1 jc-between p-0">
                                    <p className="lightgray" title="The strategy creation date"> The strategy creation date</p>
                                    <p className="text-right">{momentFormat(val.createdDate, 'DD-MM-YYYY HH:mm')}</p>
                                  </div>
                                  <hr className="themehr" />
                                  <div className="d-flex  gap-1 jc-between p-0">
                                    <p className="lightgray">Min. Investment</p>
                                    <p className="text-right">{val.currency} {val.minInvestment}</p>
                                  </div>
                                </div>

                              </div>
                            </div>
                            <div className="mt-lg-2 mt-2" onClick={() => history.push({ pathname: "/strategy", state: { strategydetails: val } })}>
                              <div className="p-0 w-full">
                                <div className="pl-4 pl-lg- 4font-11 pb-0 pt-2">
                                  <div className="d-flex  gap-1 jc-between p-0">
                                    <p className="lightgray">All Time ROI</p>
                                    <p className={val?.allTimeRoi > 0 ? "text-right roi roi_plus" : "text-right roi roi_minus"}>
                                      {val?.allTimeRoi ? val?.allTimeRoi > 0 ? "+" + val?.allTimeRoi?.toFixed(4) : val?.allTimeRoi?.toFixed(4) : 0} %
                                    </p>
                                  </div>
                                  <hr className="themehr" />
                                  <div className="d-flex  gap-1 jc-between p-0">
                                    <p className="lightgray">Own Funds</p>
                                    <p className="text-right">USD {val.OwnFunds.balance ? val.OwnFunds.balance.toFixed(2) : 0}</p>
                                  </div>
                                  <hr className="themehr" />
                                  <div className="d-flex  gap-1 jc-between p-0">
                                    <p className="lightgray" title="The strategy creation date">Copying Capital(live)</p>
                                    <p className="text-right">USD {val.totalCopyingCapital ? val.totalCopyingCapital : 0}</p>
                                  </div>
                                  <hr className="themehr" />
                                  <div className="d-flex  gap-1 jc-between p-0">
                                    <p className="lightgray">Leverage</p>
                                    <p className="text-right">{val.leverage}</p>
                                  </div>
                                </div>

                              </div>
                            </div>

                          </div>
                          <div className="d-md-flex w-full align-items-center" onClick={() => history.push({ pathname: "/strategy", state: { strategydetails: val, accountId: val.accountId } })}>
                            <div className="nodata_found_align" style={{ width: "100%", flex: 1 }}>
                              {console.log("Chart Series ::::  ", val, val?.returnOfInvestmentChart)}
                              {/* {val?.returnOfInvestmentChart?.length > 0 ? */}
                              <div className="border_graph">
                                <Chart options={data?.options} className="tradechart copymain"
                                  series={[{ name: "ROI", data: val?.returnOfInvestmentChart?.length > 0 ? val?.returnOfInvestmentChart : [] }]}
                                  type="area" height="150" />
                              </div>
                              {/* : */}
                              {/* <p className="nodata_found_txt">No chart data found</p> */}

                              {/* } */}

                            </div>

                            {val.type == 'demo' ?
                              <div className="cursor"
                                onClick={(e) => {
                                  getTradeDetails(val, !isEmpty(startedAccount) ? startedAccount : useraccount)
                                  setCopyingData(val);
                                  // setStartCopying(true);
                                  e.stopPropagation()
                                }}
                                style={{ background: "rgb(0, 131, 199)", flex: 0.5, width: 245, height: 120, textAlign: "center" }}
                              >
                                <div>
                                  <h5 className="my-2 fw-600">Start Copying</h5>
                                  {/* <p className="text-center"><span>Free </span><span className="me-2">0.00%</span>|<span>P: </span><span className="me-2">0.00%</span>|
                                <span>V: <br /></span><span>USD 2.5</span></p> */}
                                  <p className="text-center">Free</p>
                                </div>
                              </div>
                              :
                              <div className="cursor"
                                onClick={(e) => {
                                  getTradeDetails(val, !isEmpty(startedAccount) ? startedAccount : useraccount)
                                  setCopyingData(val);
                                  // setStartCopying(true); 
                                  e.stopPropagation()
                                }}
                                style={{ background: "green", flex: 0.5, width: 245, height: 120, textAlign: "center" }}>
                                <div>
                                  <h5 className="my-2 fw-600">Start Copying</h5>
                                  <p className="text-center"><span>M: </span><span className="me-2">0.00%</span>|<span>P: </span><span className="me-2">0.00%</span>|
                                    <span>V: <br /></span><span>USD 2.5</span></p>
                                </div>
                              </div>}
                            {/* <div className="cursor" onClick={(e) => { setCopyingData(val); setStartCopying(true); e.stopPropagation() }} style={{ background: "green", flex: 0.5, width: 245, height: 120, textAlign: "center" }}>
                              <div>
                                <h5 className="my-2 fw-600">Start Copying</h5>
                                <p className="text-center"><span>M: </span><span className="me-2">0.00%</span>|<span>P: </span><span className="me-2">0.00%</span>|
                                  <span>V: <br /></span><span>USD 2.5</span></p>
                              </div>
                            </div> */}
                          </div>
                        </div>

                        {/* <hr className="themehr" /> */}
                      </li>
                    ))
                    : <div className="whole_datasub_txt">
                      <p>There is no strategy</p>
                    </div>}
                </ul>
                <div className="paginations lightbox p-0">
                  <div className="d-flex justify-content-between p-0 align-items-center pt-1">
                    <p className="ml-2 f-12">Shown Strategies <b>{page && strategys.length > 0 ? (page - 1) * 10 + 1 : (page - 1) * 10}</b> -
                      <b>{((page - 1) * 10) + strategys.length}</b> of <b>{Strategycount && Strategycount ? Strategycount : 0}</b>
                    </p>
                    <p className="d-flex align-items-center">
                      <button className="movebtn"
                        onClick={() => {
                          Setpage(page - 1)
                          getStrategys(page - 1)
                        }}
                        disabled={page == 1 ? true : false}
                      >
                        <span className="fa fa-chevron-left f-12">
                        </span>
                      </button>
                      {pageNumbers && pageNumbers.length > 0 ? pageNumbers.map((val, index) => {
                        return (
                          <>
                            <button
                              className={val == page ? "pages active" : "pages"}
                              onClick={() => {
                                Setpage(val)
                                getStrategys(val)
                              }}
                            >
                              {val}
                            </button>
                          </>
                        )
                      }) : ""}
                      {/* <button className="pages active">1</button>
                      <button className="pages">2</button>
                      <button className="pages">3</button>
                      <span>...</span>
                      <button className="pages">46</button> */}
                      <button className="movebtn"
                        onClick={() => {
                          Setpage(page + 1)
                          getStrategys(page + 1)

                        }}
                        disabled={pageNumbers.length > 0 ?
                          page == pageNumbers[pageNumbers.length - 1] ? true : false :
                          true}
                      >
                        <span className="fa fa-chevron-right f-12">
                        </span>
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="Favorites" title={favorites}>
            <div className="darkbox p-0">
              <ul className="pl-0 mb-0 font-12 copytrade favscroll">
                {
                  favArray && favArray.length > 0 && favArray.map((val, i) =>
                    <li className="px-2">
                      <div className="d-lg-flex copytradeboxes p-0">
                        <div className="d-lg-flex  flex-1 mw-610 w-full align-items-center">
                          <div className="d-flex align-items-start gap-2">
                            <span className={val.isFav == true ? "fa fa-star cutr_poinrt_fav mt-1 active" : "fa fa-star cutr_poinrt_fav mt-1"} onClick={() => { addfavour(val) }}></span>
                            <div className="p-0 w-full" onClick={() => history.push({ pathname: "/strategy", state: { strategydetails: val } })}>
                              <div className="d-flex jc-between p-0 mb-3">
                                <p>{val.strategyName}</p>
                                <button className="btn btn-outline-success f-12 icon-50 p-0 px-2 my-0">{val.type}
                                </button>
                              </div>

                              <div className="font-11 p-0">
                                <div className="d-md-flex jc-between p-0 position-relative d-none">
                                  <p className="lightgray">Strategy Provider</p>
                                  <button
                                    onClick={(e) => {
                                      strategy(e);
                                      setStrat(!strat);
                                      e.stopPropagation();
                                      if (!strat) {
                                        document.getElementById(`stratbtn${i}`).classList.remove("d-none");
                                        document.getElementById(`stratbtn${i}`).classList.add("d-block");
                                        document.getElementById(`overlay${i}`).classList.remove("d-none");
                                        document.getElementById(`overlay${i}`).classList.add("provider-overlay");
                                      }
                                    }}
                                    className="strategydrop mb-1">
                                    {val.strategyName}
                                    <span className="fa fa-chevron-down"></span>
                                  </button>
                                  <div className={"d-none provider lightbox"} id={`stratbtn${i}`} onClick={(e) => { e.stopPropagation() }}>
                                    {/* <div className="darkbox">
                                      <h5 className="my-0">ctid4996761</h5>
                                      <div className="d-lg-flex flex-1">
                                        <div className="mb-lg-0 mb-2">
                                          <div className="p-0">
                                            <div className="d-flex jc-between flex1 p-0">
                                              <p className="lightgray nowrap">Total Copying Capital (Live):</p>
                                              <p className="nowrap">USD 172k</p>
                                            </div>
                                            <hr className="themehr" />
                                            <div className="d-flex jc-between p-0">
                                              <p className="lightgray nowrap">Total Copying Capital (Live):</p>
                                              <p className="nowrap">USD 172k</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="mb-lg-0 mb-2">
                                          <div className="p-0">
                                            <div className="d-flex jc-between flex1 p-0">
                                              <p className="lightgray nowrap">Average Profit:</p>
                                              <p className="nowrap">USD 172k</p>
                                            </div>
                                            <hr className="themehr" />
                                            <div className="d-flex jc-between flex1 p-0">
                                              <p className="lightgray nowrap">All Time Copiers:</p>
                                              <p className="nowrap">{val.Followers.length}</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="mb-lg-0 mb-2">
                                          <div className="p-0">
                                            <div className="d-flex jc-between flex1 p-0">
                                              <p className="lightgray nowrap">Current Copiers:</p>
                                              <p className="nowrap">{val.curFollow}</p>
                                            </div>
                                            <hr className="themehr" />
                                            <div className="d-flex jc-between flex1 p-0">
                                              <p className="lightgray nowrap">Active Live Copiers:</p>
                                              <p className="nowrap">{val.Followers.filter((value) => (value.status == "active" && value.type == "live")).length}</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      ........
                                      <div className="lightbox br-4" style={{ maxHeight: 150, overflow: "auto" }}>
                                        <p className="pb-1">Strategies</p>
                                        <div className="d-lg-flex p-0 darkbox mb-1 br-4">
                                          <div className="d-flex flex-1 mw-610 w-full">
                                            <div className="d-flex align-items-start gap-2">
                                              <span className="fa fa-star"></span>
                                              <div className="p-0 w-full">
                                                <div className="d-flex jc-between p-0 mb-3">
                                                  <p>Healthy Trades</p>
                                                  <button className="btn btn-outline-success f-12 icon-50 p-0 px-2 my-0">Live</button>
                                                </div>

                                                <div className="font-11 p-0">
                                                  <div className="d-flex jc-between p-0 position-relative ">
                                                    <p className="lightgray nowrap">Strategy Provider</p>
                                                    bxfgh
                                                  </div>
                                                  <hr className="themehr" />
                                                  <div className="d-flex jc-between gap-1 p-0">
                                                    <p className="lightgray nowrap" title="The strategy creation date"> The strategy creation date</p>
                                                    <p className="nowrap">22 Mar 2021</p>
                                                  </div>
                                                  <hr className="themehr" />
                                                  <div className="d-flex gap-1 jc-between p-0">
                                                    <p className="lightgray">Min. Investment</p>
                                                    <p className="nowrap">22 Mar 2021</p>
                                                  </div>
                                                </div>

                                              </div>
                                            </div>
                                            <div className="">
                                              <div className="p-0 w-full">


                                                <div className="font-11 p-0 pt-2">
                                                  <div className="d-flex jc-between p-0">
                                                    <p className="lightgray gap-1 nowrap">Strategy Provider</p>
                                                    <p className="nowrap">22 Mar 2021</p>
                                                  </div>
                                                  <hr className="themehr" />
                                                  <div className="d-flex gap-1 jc-between p-0">
                                                    <p className="lightgray nowrap">Strategy Provider</p>
                                                    <p className="nowrap">22 Mar 2021</p>
                                                  </div>
                                                  <hr className="themehr" />
                                                  <div className="d-flex gap-1 jc-between p-0">
                                                    <p className="lightgray nowrap" title="The strategy creation date"> The strategy creation date</p>
                                                    <p className="nowrap">22 Mar 2021</p>
                                                  </div>
                                                  <hr className="themehr" />
                                                  <div className="d-flex gap-1 jc-between p-0">
                                                    <p className="lightgray nowrap">Min. Investment</p>
                                                    <p className="nowrap">22 Mar 2021</p>
                                                  </div>
                                                </div>

                                              </div>
                                            </div>

                                          </div>
                                          <div className="d-flex w-full">
                                            <div style={{ width: "100%" }}>
                                              <Chart options={data?.options} className="tradechart copymain"
                                                series={data?.series}
                                                type="area" height="150" width="300" />
                                            </div>

                                          </div>
                                        </div>
                                      </div>
                                      ...........
                                    </div> */}
                                    {strat && <CopyProvidingAccounts details={val} />}
                                  </div>
                                  <div className={"d-none"} id={`overlay${i}`}
                                    onClick={(e) => {
                                      // closeallstrat(e); 
                                      if (strat) {
                                        document.getElementById(`stratbtn${i}`).classList.remove("d-block");
                                        document.getElementById(`stratbtn${i}`).classList.add("d-none")
                                        document.getElementById(`overlay${i}`).classList.remove("provider-overlay");
                                        document.getElementById(`overlay${i}`).classList.add("d-none");
                                      }
                                      e.stopPropagation()
                                    }}>

                                  </div>
                                </div>
                                <hr className="themehr" />
                                <div className="d-flex  gap-1 jc-between p-0">
                                  <p className="lightgray" title="The strategy creation date"> The strategy creation date</p>
                                  <p className="text-right">{momentFormat(val.createdDate, 'DD-MM-YYYY HH:mm')}</p>
                                </div>
                                <hr className="themehr" />
                                <div className="d-flex  gap-1 jc-between p-0">
                                  <p className="lightgray">Min. Investment</p>
                                  <p className="text-right">{val.currency} {val.minInvestment}</p>
                                </div>
                              </div>

                            </div>
                          </div>
                          <div className="mt-lg-2 mt-2" onClick={() => history.push({ pathname: "/strategy", state: { strategydetails: val } })}>
                            <div className="p-0 w-full">
                              <div className="pl-4 pl-lg-4 font-11 pb-0 pt-2">
                                <div className="d-flex  gap-1 jc-between p-0">
                                  <p className="lightgray">All Time ROI</p>
                                  <p className={val?.allTimeRoi > 0 ? "text-right roi roi_plus" : "text-right roi roi_minus"}>
                                    {val?.allTimeRoi ? val?.allTimeRoi > 0 ? "+" + val?.allTimeRoi?.toFixed(4) : val?.allTimeRoi?.toFixed(4) : 0} %
                                  </p>
                                </div>
                                <hr className="themehr" />
                                <div className="d-flex  gap-1 jc-between p-0">
                                  <p className="lightgray">Own Funds</p>
                                  <p className="text-right">  USD {val.OwnFunds.balance ? val.OwnFunds.balance.toFixed(2) : 0} </p>
                                </div>
                                <hr className="themehr" />
                                <div className="d-flex  gap-1 jc-between p-0">
                                  <p className="lightgray" title="The strategy creation date"> Copying Capital(live)</p>
                                  <p className="text-right">USD {val.totalCopyingCapital ? val.totalCopyingCapital : 0}</p>
                                </div>
                                <hr className="themehr" />
                                <div className="d-flex  gap-1 jc-between p-0">
                                  <p className="lightgray">Leverage</p>
                                  <p className="text-right">{val.leverage}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-md-flex w-full align-items-center" onClick={() => history.push({ pathname: "/strategy", state: { strategydetails: val, accountId: val.accountId } })}>
                          <div className="nodata_found_align" style={{ width: "100%", flex: 1 }}>
                            {/* {val?.returnOfInvestmentChart?.length > 0 ? */}
                            <Chart options={data?.options} className="tradechart copymain"
                              series={[{ name: "ROI", data: val?.returnOfInvestmentChart?.length > 0 ? val?.returnOfInvestmentChart : [] }]}
                              type="area" height="150" />
                            {/* :
                              <p className="nodata_found_txt">No chart data found</p> */}
                            {/* } */}
                          </div>
                          {
                            val.type == 'demo' ?
                              <div className="cursor" onClick={(e) => { setCopyingData(val); setStartCopying(true); e.stopPropagation() }} style={{ background: "green", flex: 0.5, width: 245, height: 120, textAlign: "center" }}>
                                <div>
                                  <h5 className="my-2 fw-600">Start Copying</h5>
                                  {/* <p className="text-center"><span>M: </span><span className="me-2">0.00%</span>|<span>P: </span><span className="me-2">0.00%</span>|
                                    <span>V: <br /></span><span>USD 2.5</span></p> */}
                                  <p className="text-center">Free</p>
                                </div>
                              </div>
                              :
                              <div className="cursor" onClick={(e) => { setCopyingData(val); setStartCopying(true); e.stopPropagation() }} style={{ background: "green", flex: 0.5, width: 245, height: 120, textAlign: "center" }}>
                                <div>
                                  <h5 className="my-2 fw-600">Start Copying</h5>
                                  <p className="text-center"><span>M: </span><span className="me-2">0.00%</span>|<span>P: </span><span className="me-2">0.00%</span>|
                                    <span>V: <br /></span><span>USD 2.5</span></p>
                                </div>
                              </div>
                          }

                        </div>
                      </div>
                      <hr className="themehr" />
                    </li>
                  )
                }
              </ul>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Copytrade;
