// import lib
import isEmpty from '../../lib/isEmpty';

export const fiatValidation = (value, t) => {
    let errors = {};
    console.log('fiatvalidation hihi', value)

    if (isEmpty(value.currencyId)) {
        errors.currencyId = "REQUIRED"
    }

    if (isEmpty(value.bankId)) {
        errors.bankId = "REQUIRED"
    }

    if (isEmpty(value.amounts)) {
        errors.amounts = "REQUIRED"
    } else if (isNaN(value.amounts)) {
        errors.amount = "ALLOW_NUMERIC"
    } else if (value.amounts <= 0) {
        errors.amounts = "Please Enter Valid Amount"
    } else if (value.minimumWithdraw > value.amounts) {
        // errors.amounts = t("MINIMUM_WITHDRAW", { "AMOUNT": value.minimumWithdraw, "COIN": value.coin })
        errors.amounts = `Amount should be greater than or equal to minimum withdraw value ${value.minimumWithdraw} ${value.coin}`
    }
    if (isEmpty(value.amounts) && !isNaN(value.amounts)) {
        errors.finalAmount = "Please Select Currency"
    }

    if (value.finalAmount > value.spotBal) {
        errors.finalAmount = "INSUFFICIENT_BALANCE"
    }

    if (isEmpty(value.twoFACode)) {
        errors.twoFACode = "REQUIRED"
    } else if (isNaN(value.twoFACode)) {
        errors.twoFACode = "ALLOW_NUMERIC"
    }

    // if(isEmpty(value.cryptocoin))
    // {
    //     errors.cryptocoin = "REQUIRED"
    // } 

    // if(isEmpty(value.currencytypes))
    // {
    //     errors.currencytypes = "REQUIRED"
    // }
    return errors;
}

export const fiatdemoValidation = (value, t) => {
    console.log("valueeeeeeeeeeeee", value);
    let errors = {};
    console.log('fiatvalidation hihi', value)

    if (isEmpty(value.currencyId)) {
        errors.currencyId = "REQUIRED"
    }

    // if (isEmpty(value.bankId)) {
    //     errors.bankId = "REQUIRED"
    // }

    if (isEmpty(value.amounts)) {
        errors.amounts = "REQUIRED"
    } else if (isNaN(value.amounts)) {
        errors.amount = "ALLOW_NUMERIC"
    } else if (value.amounts <= 0) {
        errors.amounts = "Please Enter Valid Amount"
    } else if (value.minimumWithdraw > value.amounts) {
        errors.amounts = `Amount should be greater than or equal to minimum withdraw value ${value.minimumWithdraw} ${value.coin}`
    }

    if (!isEmpty(value.amounts) && !isNaN(value.amounts) && value.finalAmount > value.spotBal) {
        errors.finalAmount = "INSUFFICIENT_BALANCE"
    }
    // if (isEmpty(value.twoFACode == "live")) {
    //     if (isEmpty(value.twoFACode)) {
    //         errors.twoFACode = "REQUIRED"
    //     } else if (isNaN(value.twoFACode)) {
    //         errors.twoFACode = "ALLOW_NUMERIC"
    //     }
    // }

    // if(isEmpty(value.cryptocoin))
    // {
    //     errors.cryptocoin = "REQUIRED"
    // } 

    // if(isEmpty(value.currencytypes))
    // {
    //     errors.currencytypes = "REQUIRED"
    // }
    console.log("errors---------------", errors);
    return errors;
}


export const fiatDepositValidation = value => {
    console.log('hello hihi', value)
    let errors = {};
    let imageFormat = /\.(jpg|JPG|png|PNG|pdf|PDF)$/;

    if (isEmpty(value.amount)) {
        errors.amount = "REQUIRED"
    } else if (isNaN(value.amount)) {
        errors.amount = "ALLOW_NUMERIC"
    } else if (value.amount <= 0) {
        errors.amount = "Enter Valid Amount"
    }


    console.log('value.image 222', value.image && value.image.size > 1000000)
    console.log('value.image 233', !imageFormat.test(value.image.name))
    console.log('value.image 244', value.image.size)
    if (value.image && value.image.size) {
        if (value.image.size > 1000000) {
            errors.image = "MAX_1MB_IMG"
        } else if (!imageFormat.test(value.image.name)) {
            errors.image = "INVALID_IMAGE"
        }
    } else {
        errors.image = "REQUIRED";
    }

    if (isEmpty(value.currency) || value.currency == 'select') {
        errors.fiatcoin = "REQUIRED"
    }

    // if(isEmpty(value.currencytype))
    // {
    //     errors.currencytype = "REQUIRED"
    // }
    return errors;

}


export const fiatDepositValidationDemo = value => {
    console.log('hello hihi', value)
    let errors = {};

    if (isEmpty(value.amount)) {
        errors.amount = "REQUIRED"
    } else if (isNaN(value.amount)) {
        errors.amount = "ALLOW_NUMERIC"
    } else if (value.amount <= 0) {
        errors.amount = "Enter Valid Amount"
    }


    if (isEmpty(value.currency) || value.currency=='select' ) {
        errors.fiatcoin = "REQUIRED"
    }

    // if(isEmpty(value.currencytype))
    // {
    //     errors.currencytype = "REQUIRED"
    // }
    return errors;

}
export const coinValidation = (value, t) => {
    let errors = {};

    if (isEmpty(value.currencyId)) {
        errors.currencyId = "REQUIRED"
    }

    if (isEmpty(value.receiverAddress)) {
        errors.receiverAddress = "REQUIRED"
    }

    if (isEmpty(value.amounts)) {
        errors.amounts = "REQUIRED"
    } else if (isNaN(value.amounts)) {
        errors.amounts = "ALLOW_NUMERIC"
    } else if (value.minimumWithdraw > value.amounts) {
        errors.amounts = t("MINIMUM_WITHDRAW", { "AMOUNT": value.minimumWithdraw, "COIN": value.coin })
    } else if (value.amounts <= 0) {
        errors.amounts = "Pleae Enter Valid Amount"
    }

    if (!isEmpty(value.amounts) && !isNaN(value.amounts) && value.finalAmount > value.spotBal) {
        errors.amounts = "INSUFFICIENT_BALANCE"
    }

    if (isEmpty(value.twoFACode)) {
        errors.twoFACode = "REQUIRED"
    } else if (isNaN(value.twoFACode)) {
        errors.twoFACode = "ALLOW_NUMERIC"
    }


    return errors;
}

export const walletTransferValidation = value => {
    let errors = {};

    if (isEmpty(value.fromType)) {
        errors.fromType = "REQUIRED"
    } else if (!['spot', 'derivative', 'p2p'].includes(value.fromType)) {
        errors.fromType = "INVALID_WALLET_TYPE"
    }

    if (isEmpty(value.toType)) {
        errors.toType = "REQUIRED"
    } else if (!['spot', 'derivative', 'p2p'].includes(value.toType)) {
        errors.toType = "INVALID_WALLET_TYPE"
    } else if (value.fromType == value.toType) {
        errors.toType = "WALLET_MIS_MATCH"
    }

    if (isEmpty(value.userAssetId)) {
        errors.userAssetId = "REQUIRED"
    }

    if (isEmpty(value.amount)) {
        errors.amount = "REQUIRED"
    } else if (isNaN(value.amount)) {
        errors.amount = "ALLOW_NUMERIC"
    } else if (parseFloat(value.amount) <= 0) {
        errors.amount = "MUST_BE_GREATER"
    }



    return errors;
}

export const fundValidation = value => {
    let errors = {},
        emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;

    if (isEmpty(value.currencyId)) {
        errors.currencyId = "REQUIRED"
    }

    if (isEmpty(value.amount)) {
        errors.amount = "REQUIRED"
    } else if (isNaN(value.amount)) {
        errors.amount = "ALLOW_NUMERIC"
    } else if (value.amount <= 0) {
        errors.amount = "INSUFFICIENT_BALANCE"
    }

    if (isEmpty(value.toUserEmail)) {
        errors.toUserEmail = "REQUIRED"
    } else if (!(emailRegex.test(value.toUserEmail))) {
        errors.toUserEmail = "INVALID_EMAIL"
    }

    if (isEmpty(value.twoFACode)) {
        errors.twoFACode = "REQUIRED"
    } else if (isNaN(value.twoFACode)) {
        errors.twoFACode = "ALLOW_NUMERIC"
    }

    return errors;
}