import React, { useEffect, useState } from 'react';
import {Modal,Tabs,Dropdown,InputGroup, Form, Tab, FormControl} from 'react-bootstrap'
import Wishlisttab from '../elements/Wishlisttab';
import Infomation from './Information';
import { momentFormat } from '../../../../lib/dateTimeHelper'
import { filterdoneorders, Doneloadmore } from 'actions/perpetualTradeAction';
import { useDispatch, useSelector } from "react-redux";
import { toLocaleString ,lotDisplay} from 'lib/roundOf';

const DoneOrder = (props) => {
  const [doneorder, setDoneOrder] = useState(true)
  const [showdrop, setShowDrop] = useState(false)
  const [historysearch, setHistorysearch] = useState("");
  const [historyday, setHistoryday] = useState("");
  const [historystart, setHistorystart] = useState("");
  const [historyend, setHistoryend] = useState("");
  console.log("prpd=--------------->>", props);
  const [orderdata, setOrderdata] = useState([]);
  const [ishistoryday, setIshistoryday] = useState(true);
  const [ishistorydate, setIshistorydate] = useState(false);
  const [orderstatus, setOrderstatus] = useState("");
  const [ordertype, setOrdertype] = useState("");
  const [filledDetailsPage , setFilledDetailsPage] = useState(0)
  const [loadDone,setLoadDone] =useState(false)
  const [page , setPage] = useState(1);

  // redux-state demo 
  const pairList = useSelector(state => state.pairList);

  useEffect(() => {
    console.log("done orders data", props);
    setOrderdata(props.Data)
  }, [])

  const disableCheckDivDone = (e) => {
    console.log(e.target.getAttribute("id"), "e event");
    if (e.target.getAttribute("id") == "check-api-radio0") {
      // alert(1)
      document.getElementById("diable_check_id_2_done").classList.add("disable_chk_done");
      document.getElementById("diable_check_id_1_done").classList.remove("disable_chk_done");
      document.getElementById("check-api-radio01").classList.add("disable_chk_done");
      document.getElementById("check-api-radio0").classList.remove("disable_chk_done");

    }
    if (e.target.getAttribute("id") == "check-api-radio01") {
      // alert(2)
      document.getElementById("diable_check_id_1_done").classList.add("disable_chk_done");
      document.getElementById("diable_check_id_2_done").classList.remove("disable_chk_done");
      document.getElementById("check-api-radio0").classList.add("disable_chk_done");
      document.getElementById("check-api-radio01").classList.remove("disable_chk_done");
    }
  }

  const getmonth = (data) => {
    // if(data){
    if (data == "Current Month") {
      let newdate = Date.now() - (86400000 * (new Date().getDate()))
      return newdate;
    }
    if (data == "Last 2 Month") {
      let newdate = Date.now() - (86400000 * (new Date().getDate() + 30))
      return newdate;
    }
    if (data == "Last 3 Month") {
      let newdate = Date.now() - (86400000 * (new Date().getDate() + 61))
      return newdate;
    }
    // }
  }


  const handledoneorderfilter = async (search, day, start, end, orst, orty) => {
    console.log("handle doneorder filter", search, day, start, end, orst, orty);
    setHistorysearch(search);
    setHistoryday(day);
    setHistorystart(start);
    setHistoryend(end);
    setOrderstatus(orst);
    setOrdertype(orty);
    var payload = { accountId: localStorage.getItem("accounts") , page : 1};
    setPage(1);
    if (orst) {
      payload.orderstatus = orst
    }
    if (orty) {
      payload.ordertype = orty
    }
    if (search.length > 0) {
      payload.search = search;
    }
    if (ishistoryday) {
      payload.ishistoryday = ishistoryday
      if (day) {
        payload.days = day
      }
    }
    if (ishistorydate) {
      payload.ishistorydate = ishistorydate
      if (start) {
        payload.startdate = start;
      }
      if (end) {
        payload.enddate = end;
      }
    }
    let filtereddata = await filterdoneorders(payload);
    // setPage(page+1)
    console.log("filtered data", filtereddata , parseFloat(filtereddata?.count) > 10);
    setOrderdata(filtereddata?.result);
    if(parseFloat(filtereddata?.count) > 10){
      setLoadDone(true)
    }
  }

  const handleloadmoredoneorder = async() => {
    var payload = { accountId: localStorage.getItem("accounts") , page :page + 1};
    setPage(page+1)
    if (orderstatus) {
      payload.orderstatus = orderstatus
    }
    if (ordertype) {
      payload.ordertype = ordertype
    }
    if (historysearch.length > 0) {
      payload.search = historysearch;
    }
    if (ishistoryday) {
      payload.ishistoryday = ishistoryday
      if (historyday) {
        payload.days = historyday
      }
    }
    if (ishistorydate) {
      payload.ishistorydate = ishistorydate
      if (historystart) {
        payload.startdate = historystart;
      }
      if (historyend) {
        payload.enddate = historyend;
      }
    }
    let filtereddata = await filterdoneorders(payload);
    // setPage(page+1)
    console.log("filtered data", filtereddata);
    let newdata = orderdata.concat(filtereddata?.result);
    setOrderdata(newdata);
    if(filtereddata?.count > newdata.length){
      setLoadDone(true)
    }
    else{
      setLoadDone(false);
    }
  }

  const fetchDoneOrder = async () => {
    let data = {
      page: orderdata.length,  
      accountId: localStorage.getItem("accounts") 
    }
   var resp = await Doneloadmore(data)
   console.log("resp",resp)
      if (resp && resp.data.length > 0) {  
        console.log("closed_position",resp.data,orderdata)   
         setOrderdata([...orderdata, ...resp.data]) 
          setFilledDetailsPage( filledDetailsPage + 1)
          console.log("closed_position1",resp.data,orderdata)
     }
     if(resp.data.length<=0){
      setLoadDone(false)
     }
    
    
};

  return (
    <>
      <Modal show={doneorder} className="modalbg font-12 modal_big" size="lg" centered scrolable>

        <Modal.Header className='align-items-center'>
          <Modal.Title>Done Orders</Modal.Title>
          <button className='closebtn btn btn-link text-light nounder' onClick={() => props.onDismiss()}>
          <i className="fa fa-times fa-6" aria-hidden="true"></i>

            </button>
        </Modal.Header>
        <Modal.Body className='font-12'>
          <div className="d-flex align-items-center gap-2 py-0 mob_res_sm block_mob_res_scroll_on_ipad block_mob_res_scroll_on_laptop">
            <InputGroup className="themeinput min_w_input">
              <FormControl placeholder="" onChange={e => { console.log("e", e); handledoneorderfilter(e.target.value, historyday, historystart, historyend, orderstatus, ordertype) }} />
              <InputGroup.Append>
                <InputGroup.Text id="basic-addon2"><span className="fa fa-search"></span></InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
            <div className="d-flex gap-2 py-0 mob_res_sm">
              <select className="darkselect selectcount selctsm slect_width_min" value={orderstatus} onChange={e => { setOrderstatus(e.target.value); handledoneorderfilter(historysearch, historyday, historystart, historyend, e.target.value, ordertype) }}>
                <option value="">All Order Statuses (Default)</option>
                <option value="completed">completed</option>
                <option value="open">open</option>
                <option value="pending">pending</option>
                <option value="conditional">conditional</option>
                <option value="cancel">cancel</option>

              </select>

              <select className="darkselect selectcount selctsm slect_width_min" value={ordertype} onChange={e => { setOrdertype(e.target.value); handledoneorderfilter(historysearch, historyday, historystart, historyend, orderstatus, e.target.value) }}>
                <option value="">All Order Types (Default)</option>
                <option value="limit">limit</option>
                <option value="market">market</option>
                <option value="stop">Stop</option>
                <option value="stop_limit">Stop Limit</option>
                {/* <option value="6">Stop Limit / Take Profit</option>
                                <option value="7">Market Range</option>
                                <option value="8">Stop Out</option> */}


              </select>
              {/* <p className='d-flex align-items-center'>Period</p> */}
              {/* <div className="d-flex align-items-center gap-2 py-0 block_mob_res mob_res_scrol_blk block_mob_res_scroll_on_ipad"> */}
              {/* <InputGroup className="themeinput">
                        <FormControl placeholder="" />
                        <InputGroup.Append>
                          <InputGroup.Text id="basic-addon2"><span className="fa fa-search"></span></InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup> */}
              <div className="d-flex gap-2 py-0 block_mob_res mt-2 mt-lg-0" onChange={(e) => { disableCheckDivDone(e) }}>

                <Form.Check type="radio" className="mb-0" >
                  <Form.Check.Input id="check-api-radio0" type="radio" name="radio1" checked={ishistoryday} isValid onChange={e => {
                    console.log("ae.target.checked", e.target.checked); setIshistoryday(e.target.checked); setIshistorydate(false);
                    // setOpenorder(setBopenorder);
                  }} />
                  <Form.Check.Label for="check-api-radio0" id="diable_check_id_1_done">
                    <select className="darkselect selectcount selctsm ml-1" onChange={e => handledoneorderfilter(historysearch, e.target.value, historystart, historyend, orderstatus, ordertype)}>
                      <option value="">All History</option>
                      <option value={Date.now() - (86400000 * 3)}>Last 3 Days</option>
                      <option value={Date.now() - (86400000 * new Date().getDay())}>Current Week</option>
                      <option value={Date.now() - (86400000 * (new Date().getDay() + 7))} >Last 2 Week</option>
                      <option value={Date.now() - (86400000 * (new Date().getDay() + 14))}>Last 3 Week</option>
                      <option value={getmonth("Current Month")}>Current Month</option>
                      {/* <option value={}>Previous Month(Calendar)</option> */}
                      <option value={getmonth("Last 2 Month")}>Last 2 Month</option>
                      <option value={getmonth("Last 3 Month")}>Last 3 Month</option>
                    </select>
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check type="radio" className="mb-0" >
                  <Form.Check.Input id="check-api-radio01" type="radio" name="radio1" isValid className="mb-0 disable_chk_done"
                    checked={ishistorydate} onChange={e => { console.log("e.target.value", e.target.checked); setIshistorydate(e.target.checked); setIshistoryday(false) }} />
                  <Form.Check.Label for="check-api-radio01" className="mb-0 disable_chk_done" id="diable_check_id_2_done">
                    <div className="d-flex gap-2 tabinput p-0">
                      <input type="date" className="themeinput py-1" max={new Date()} onChange={e => handledoneorderfilter(historysearch, historyday, e.target.value, historyend, orderstatus, ordertype)} />
                      <input type="date" className="themeinput py-1" max={new Date()} onChange={e => handledoneorderfilter(historysearch, historyday, historystart, e.target.value, orderstatus, ordertype)} />
                      {loadDone === true  ?<button className="text-light load_more_done_ord btn m-0 py-1 px-3" onClick={() => {handleloadmoredoneorder()}}>Load More</button>: ""}
                    </div>
                    {/* //&& props.Count > orderdata.length */}

                  </Form.Check.Label>
                </Form.Check>
              </div>
              {/* </div> */}
            </div>
          </div>

          <div className="darkbox h-200 mt-2">
            <div className="table-responsive p-0">
              <table className="w-full">
                <tr>
                  <th><label>ID</label></th>
                  <th><label>Status</label></th>
                  <th><label>Symbol</label></th>
                  <th><label>Direction</label></th>
                  <th><label>Filled Time</label></th>
                  <th><label>Order Type</label></th>
                  <th><label>Submitted Price</label></th>
                  <th><label>Filled Volume</label></th>
                  <th><label>Filled Quantity</label></th>
                  <th><label>Comment</label></th>
                </tr>
                <tbody>
                  {orderdata.length > 0 ? orderdata.map((val, index) => {
                    let pairDetail = pairList.find((value)=>(value.tiker_root===val.pairName))
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{val.status}</td>
                        <td>{val.pairName}</td>
                        <td>{val.buyorsell}</td>
                        <td>{val.filled.length > 0 ? momentFormat(val.filled[val.filled.length - 1].created_at, 'DD-MM-YYYY HH:mm') : '-'}</td>
                        <td>{val.orderType}</td>
                        <td>{toLocaleString(parseFloat(val.price.toFixed(pairDetail.precision)))}</td>
                        <td>{toLocaleString(parseFloat(val.filledAmount))}-{val.secondCurrency}</td>
                        <td>{lotDisplay(val.filledAmount,pairDetail,val.price).toFixed(2)} Lots</td>
                      </tr>
                    )
                  }) : ""
                  }

                </tbody>
                {/* </tr>
                          <tr>
                          
                            <td>
                              1
                            </td>
                            <td>
                              Pending
                            </td>
                            <td>USDCAD</td>
                            <td>Buy</td>

                            <td>10 Nov 2</td>
                            <td>Market</td>
                            <td>1.2457</td>
                            <td>0.01</td>
                            <td>0.01 Lots</td>

                            <td><span>Lorem ipsum</span></td>
                           
                          </tr>
                          <tr>
                          
                          <td>
                            2
                          </td>
                          <td>
                            Pending
                          </td>
                          <td>USDCAD</td>
                          <td>Buy</td>

                          <td>10 Nov 2</td>
                          <td>Market</td>
                          <td>1.2457</td>
                          <td>0.01</td>
                          <td>0.01 Lots</td>

                          <td><span>Lorem ipsum</span></td>
                         
                        </tr>
                        <tr>
                          
                          <td>
                            3
                          </td>
                          <td>
                            Pending
                          </td>
                          <td>USDCAD</td>
                          <td>Buy</td>

                          <td>10 Nov 2</td>
                          <td>Market</td>
                          <td>1.2457</td>
                          <td>0.01</td>
                          <td>0.01 Lots</td>

                          <td><span>Lorem ipsum</span></td>
                         
                        </tr>
                        <tr>
                          
                          <td>
                            4
                          </td>
                          <td>
                            Pending
                          </td>
                          <td>USDCAD</td>
                          <td>Buy</td>

                          <td>10 Nov 2</td>
                          <td>Market</td>
                          <td>1.2457</td>
                          <td>0.01</td>block_mob_res_scroll_on_ipad
                          <td>0.01 Lots</td>

                          <td><span>Lorem ipsum</span></td>
                         
                        </tr>
                        <tr>
                          
                          <td>
                            5
                          </td>
                          <td>
                            Pending
                          </td>
                          <td>USDCAD</td>
                          <td>Buy</td>

                          <td>10 Nov 2</td>
                          <td>Market</td>
                          <td>1.2457</td>
                          <td>0.01</td>
                          <td>0.01 Lots</td>

                          <td><span>Lorem ipsum</span></td>
                         
                        </tr> */}

              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default DoneOrder