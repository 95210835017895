/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link, useHistory, NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// import component
import NewsSubscribe from '../NewsSubscribe';

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

// import action
import { getLanguage } from '../../actions/commonAction';

// import lib
import { capitalize } from '../../lib/stringCase';
import { setLang, getLang } from '../../lib/localStorage';
import isEmpty from "../../lib/isEmpty";
const useStyles = makeStyles(styles);

export default function BeforeLogin(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });

  // state
  const [langOption, setLangOption] = useState([])
  const [language, setLanguage] = useState('')

  // redux-state
  const { isAuth } = useSelector(state => state.auth);
  const socialMedia = useSelector(state => state.socialMedia);

  // function
  const handleLanguage = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setLanguage(value)
    setLang(value)
    i18n.changeLanguage(value);
  }

  const fetchLanguage = async () => {
    try {
      const { status, loading, result } = await getLanguage(dispatch);
      if (status == 'success') {
        setLangOption(result);
        let lang = getLang();
        if (isEmpty(lang)) {
          let primaryData = result && result.length > 0 && result.find((el => el.isPrimary == true))
          if (primaryData) {
            setLanguage(primaryData.code)
            setLang(primaryData.code)
            i18n.changeLanguage(primaryData.code);
          }
        } else {
          setLanguage(lang)
        }
      }
    } catch (err) { }
  }

  useEffect(() => {
    fetchLanguage();
  }, [])

  return (
    <footer className="mainFooter">
      <div className="container-fluid">
        {/* <div className="footerTop wow fadeInDown">
          <div className="ftLeft">
            <NavLink to="/">
              <img src={require("../../assets/images/logo.png")} className="img-fluid" alt="Logo" />
            </NavLink>
          
          </div>
          <div className="ftRight">
            <div className="d-flex justify-content-between align-items-start">
              <div className="ftRight_left">
                <h3>Join Newsletter</h3>
                <div className="form-group form_grp_newsletr mt-3">
                      <div className="input-group">
                        
                        <input type="email" className="form-control" placeholder="Mail Id" />
                        <div className="input-group-append">
                        <button className="btn btn_green_su" href="/">Submit</button>
                        </div>
                      </div>
                    </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="footerMidd wow fadeIn pt-4 pb-0" data-wow-delay=".5s">
          <div className="footerMidd_left">
            <ul className="footer_links mb-1">
              <li><NavLink to="/about">About us</NavLink></li>
              <li><NavLink to="/faq">FAQs</NavLink></li>
              <li><NavLink to="/contact">Contact Us</NavLink></li>
              <li><NavLink to="/terms">Terms & Conditions</NavLink></li>
              <li><NavLink to="/privacy-policy">Privacy Policy</NavLink></li>
              <li><NavLink to="/legal">Legal</NavLink></li>
              {/* <li onClick={() => { history.push('/about') }}>About us</li>
              <li onClick={() => { history.push('/faq') }}>FAQs</li>
              <li onClick={() => { history.push('/contact') }}>Contact Us</li>
              <li onClick={() => { history.push('/terms') }}>Terms & Conditions</li>
              <li onClick={() => { history.push('/privacy-policy') }}>Privacy Policy</li> */}
            </ul>
          </div>  
          <div className="footerMidd_right">
            <h3>Social Media withus:</h3>
            <ul className="socialLinks mb-1">
              <li><a href={socialMedia && socialMedia.twitterUrl} target="_blank"><i className="fab fa-twitter"></i></a></li>
              <li><a href={socialMedia && socialMedia.facebookLink} target="_blank"><i className="fab fa-facebook"></i></a></li>
              <li><a href={socialMedia && socialMedia.linkedinLink} target="_blank"><i class="fab fa-linkedin"></i></a></li>
              {/*<li><a href="#" target="_blank"><i class="fab fa-medium-m"></i></a></li>*/}
            </ul>
          </div>        
        </div> 
        <hr />
        <p class="mt-2 cpy_txt text-center pb-4">Copyright 2023 © TRADING ONE CAPITAL</p>

      </div>
    </footer>
  );
}

BeforeLogin.propTypes = {
  whiteFont: PropTypes.bool
};
