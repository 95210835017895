import React, { useEffect, useState, useContext } from 'react';
import envelope from "../../../assets/images/newtrade/envelope.svg"
import candle from "../../../assets/images/newtrade/candle.svg"
import { Accordion, Card, Tabs, Tab, Button, InputGroup, Form, Dropdown } from 'react-bootstrap'
import { Link, NavLink, useHistory, useParams } from 'react-router-dom';
import CreateWishlist from '../newtrade/Modals/CreateWishlist';
import file from '../../../assets/images/newtrade/file.svg'
import chart from '../../../assets/images/newtrade/chart.svg'
import star from '../../../assets/images/newtrade/star.svg'
import deposit from '../../../assets/images/newtrade/deposit.svg'
import withdraw from '../../../assets/images/newtrade/withdraw.svg'
import setting from '../../../assets/images/newtrade/setting.svg'
import CopyTrade from '../newtrade/Modals/CopyTrade';
import NewOrder from '../newtrade/Modals/NewOrder';
import Infomation from './Modals/Information';
import ViewInvestor from './Modals/ViewInvestor';
import DeleteInvestor from './Modals/DeleteInvestor';
import EquityStoploss from './Modals/EquityStoploss';
import AddFund from './Modals/AddFund';
import StopCopying from './Modals/StopCopying';
import RemoveFund from './Modals/RemoveFund';
import SocketContext from '../../Context/SocketContext';
import Strategy from './Modals/Strategy';
import { getstocksymbolaction } from 'actions/currencycategoryAction';
import { addwishlisthook } from 'actions/currencycategoryAction';
import Singelsymbol from './elements/Singelsymbol'
import { useSelector } from 'react-redux';
import isEmpty from 'lib/isEmpty';

const Allsymbols = (props) => {
    // console.log("Allsymbols_prpos-------",props);
    const {wishlist}=props
    const socketContext = useContext(SocketContext)
    const history = useHistory();
    const { tikerRoot } = useParams()
    const [mini, setMini] = useState(false)
    const [grid, setGrid] = useState(false)
    const [wishes, setWishes] = useState([{ id: 0, name: "" }])
    const [wishescount, setWishescount] = useState(0)

    const [whishlist, setWishlist] = useState(false)
    const [wishname, setWishname] = useState("Wishlist")
    const [flatnav, setFlatNav] = useState(false)
    const [openacc, setOpenAcc] = useState(false)
    const [expand, setExpand] = useState(false)
    const [live, setLive] = useState(false)


    const [copycount, setCopycount] = useState(0)
    const [copydata, setCopyData] = useState([{ id: 0, status: "" }])

    // modals
    const [neworder, setNewOrder] = useState(false)
    const [copytrade, setCopyTrade] = useState(false)
    const [info, setInfo] = useState(false)
    const [viewinvestor, setViewInvestor] = useState(false)
    const [deleteinvestor, setDeleteInvestor] = useState(false)
    const [equitystop, SetEquitystop] = useState(false)
    const [addfund, setAddFund] = useState(false)
    const [removeFund, setRemoveFund] = useState(false)
    const [stopCopying, setStopCopying] = useState(false)

    const [strategy, setStrategy] = useState(false)

    const [category, setCategory] = useState([]);
    const [pair, setPair] = useState([]);
    // console.log("pair------cdf------------",pair);
    const [wishref, setWishref] = useState("");
    const categoryPair = useSelector(state => state.categoryPair)

    useEffect(() => {
        async function fetchdata() {
            setPair(props?.data?.categorydata)
        }
        if (props?.issearch) {
            document.getElementById(`acc-body-p${props?.index}`).classList.remove("d-none")
        }
        fetchdata();
    }, [props?.data])



    const handlewishlist = async (data, id) => {
        let payload = {
            name: data,
            id: id
        }
        console.log("payload", payload);
        let result = await addwishlisthook(payload);
        console.log("result", result);
    }

    useEffect(() => {
        socketContext.socket.on('perpetualmarketprice', (result) => {
            //console.log('perpetualmarketprice', result)
        })
    }, [SocketContext.socket])

    // const handlePairChange = async (pairData) => {
    //     let pair = `${pairData.firstCurrencySymbol}_${pairData.secondCurrencySymbol}`
    //     history.push('/trade/' + pair)
    //     if (tikerRoot != pair) {
    //     //   await fetchAssetByCurrency(pairData.firstCurrencyId, 'firstCurrency')
    //     //   await fetchAssetByCurrency(pairData.secondCurrencyId, 'secondCurrency')
    //     //   await setMarketPrice(pairData, dispatch)
    //     //   await setTradePair(pairData, dispatch)
    //     //   socketContext.socket.off("marketPrice");
    //     //   socketContext.socket.off("orderBook");
    //     //   socketContext.socket.off("recentTrade");
    //     }
    //   }
    const openAcc = (e) => {
        var cont = e.target.parentNode.parentNode.nextElementSibling
        if (!(cont.classList.contains("d-none"))) {
            cont.classList.add("d-none")
        } else {
            cont.classList.remove("d-none")
        }
    }

    const toGrid = (e) => {
        if (e.target.classList.contains("fa-bars")) {
            e.target.setAttribute("class", "fa fa-th")
            setExpand(true)

        } else {
            e.target.setAttribute("class", "fas fa-bars")
            setExpand(false)
        }
    }
    return (
        <div>
            {whishlist && <CreateWishlist onDismiss={() => setWishlist(false)}
                refresh={() => props.refresh()}
                id={wishref}
                wishname={(data) => { setWishescount(wishescount + 1); setWishes((val) => [...val, { id: wishescount + 1, name: data }]) }} />}
            <div className={props?.data?.categorydata?.length == 0 ? "d-none" : ""}>
                <div className={flatnav ? 'scrolablediv extendsec scrolablediv_without_h over_redu_scr' : 'scrolablediv scrolablediv_without_h over_redu_scr'} >
                    <div className='custdacc inneracc'>
                        <div>
                            <div className='acc-item w-full mb-1'>
                                <div className="d-flex jc-between align-items-center w-full">

                                    <button className='btn btn-link nounder text-light flex1 text-left' onClick={(e) => openAcc(e)}>{props?.data?.categoryName}</button>
                                    {/* <div> 
                                <button className='fas fa-bars' onClick={(e) => {toGrid(e); e.stopPropagation()}}></button></div>  */}
                                </div>
                            </div>
                            <div className='acc-body d-none' id={`acc-body-p${props?.index}`} >
                                <div className='coinlists'>
                                    <ul className='pl-0 ul_without_hover'>
                                        {pair && pair.map((data, index) => {
                                            let categoryData = categoryPair.find((val) => (val.categoryName.toUpperCase() == data.category.toUpperCase()))
                                            
                                                // let disable = !isEmpty(categoryData) ? (data.status == 'active') ? false : true : true 
                                                let disable = isEmpty(categoryData) ? true : (data.status == 'deactive') ? true : false
                                                if(data.category.toUpperCase()  == 'FOREX'){
                                                    // console.log("disable_categoryData", categoryData,disable,data.status)
                                                }
                                                
                                                return (
                                                    <li className={disable ? 'disabling_li' : ''}>
                                                        <Singelsymbol index={index} data={data} setWishlist={setWishlist} wishlist={wishlist && wishlist}
                                                            refresh={() => props.refresh()} id={(val) => { setWishref(val) }} />
                                                    </li>
                                                )
                                        }
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Allsymbols;