import React, { useState, useEffect } from 'react';
import { Modal, Tabs, Dropdown, InputGroup, Form, Tab } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AddFunds } from 'actions/copyTradeAction';
import { toast } from 'react-toastify';
import { TraderDetails } from 'actions/copyTradeAction';
import isEmpty from 'lib/isEmpty';
import { toastAlert } from 'lib/toastAlert';


const initialFormValue = {
    'accountData': {},
    // 'type': '',
    // 'leverage': '',
    'currency': 'USD',
    'amountInvestment': '',
    'minInvestment': '',
    'traderAccountId': '',
    'followerAccountId': '',
    'currentInvestment': ''
}
const AddFund = (props) => {
    const dispatch =useDispatch()
    const { copyingData,copiedAccount } = props
    console.log(copiedAccount,"copiedAccount")
    const [addfund, setAddFund] = useState(true)
    const [formValue, setFormValue] = useState(initialFormValue);
    const [Followers,setFollowers]=useState([])
    const [error, setError] = useState({})
    const { useraccount, accounts } = useSelector(state => state.account)
    // const accountId = state.accountId.formValue
    const { amountInvestment, accountData, currentInvestment } = formValue
    // const data = {
    //     accountId: accountId?.accountData.accountId,
    //     balance: accountId?.accountData.balance,
    //     traderAccountId: accountId?.traderAccountId,
    //     tradefollower: accountId.tradefollower,
    //     amount: formValue.price
    // }
    const SelectDisabled = (val) => {
        try {
            let disable = false
            if (copyingData.allowCopying == 'Live and Demo' && val.balance >= copyingData.minInvestment) {
                return disable
            } else if (copyingData.allowCopying.toLowerCase() !== val.type || !(val.balance >= copyingData.minInvestment)) {
                console.log(copyingData.allowCopying.toLowerCase() !== val.type || !(val.balance >= copyingData.minInvestment), 'SelectDisabled')
                disable = true
                return disable
            } else {
                return disable
            }
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        console.log(copiedAccount, 'copyingData')
        if (copyingData.Followers) {
            setFollowers(copyingData.Followers)
            let FollowingData = copyingData?.Followers?.find((val) => (val.followerAccountId == copiedAccount.accountId))
            // if(!FollowingData || FollowingData.status == "removedByProvider"){
            //     console.log("I am worked")
            //     props.startCopying()
            //     props.onDismiss()
            // }
            // else{
                let formData = {
                    ...formValue,
                    ...{
                        'amountInvestment': copyingData?.minInvestment,
                        'accountData': copiedAccount,
                        'traderAccountId': copyingData.accountId,
                        'followerAccountId': copiedAccount.accountId,
                        // 'type': useraccount.type,
                        // 'leverage': useraccount.leverage,
                        'currentInvestment': FollowingData ? FollowingData.amountInvestment : 0
                    }
                };
                setFormValue(formData)
            // } 
        }else{
            getTradeDetails()
        }
    }, [copyingData, copiedAccount])

    const getTradeDetails = async()=>{
        try{
          let data= {
            traderAccountId:copyingData?.accountId,
            traderId:copyingData?.userId
          } 
          let {status,result,message} = await TraderDetails(data)
          if(status){
            setFollowers(result?.Followers)
            let FollowingData = result?.Followers?.find((val) => (val.followerAccountId == copiedAccount.accountId))
            // if(!FollowingData || FollowingData.status == "removedByProvider"){
            //     props.startCopying(copiedAccount.accountId)
            //     props.onDismiss()
            // }
            // else if(FollowingData.status == "inactive"){
            //     props.restart(copiedAccount.accountId,FollowingData._id)
            //     props.onDismiss()
            // }
            // else{
                let formData = {
                    ...formValue,
                    ...{
                        'amountInvestment': result?.minInvestment,
                        'accountData': copiedAccount,
                        'traderAccountId': result.accountId,
                        'followerAccountId': copiedAccount.accountId,
                        // 'type': useraccount.type,
                        // 'leverage': useraccount.leverage,
                        'currentInvestment': FollowingData ? FollowingData.balanceInvestment : 0
                    }
                };
                setFormValue(formData)
            // }
          }else{
            console.log(message,'TradeDetail')
          }
        }catch(err){
          console.log(err,'getTradeDetails--err')
        }
      }
    const handleChange = async (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        if (name == 'accountData') {
            let val = JSON.parse(value)
            let FollowingData = Followers?.find((vals) => (vals.followerAccountId == val.accountId))
            console.log(FollowingData,val,"FollowingData__FollowingData")
            if(!FollowingData || FollowingData.status == "removedByProvider"){
                props.startCopying(val.accountId)
                props.onDismiss()
            }
            else if(FollowingData.status == "inactive"){
                props.restart(val.accountId,FollowingData._id)
                props.onDismiss()
            }
            else{
                let formData = {
                    ...formValue,
                    ...{
                        [name]: val,
                        'followerAccountId': val.accountId,
                        'currentInvestment': FollowingData ? FollowingData.balanceInvestment : 0
                        // 'type': val.type,
                        // 'leverage': val.leverage
                    }
                };
                setFormValue(formData)
                return true
            }
        }
        if(name == 'amountInvestment'){
            if(parseFloat(value) > parseFloat(accountData.balance)){
                setError({"amountInvestment":"Insufficient balance"})
            }else{
                setError({})
            }
            setFormValue({ ...formValue, ...{ 'amountInvestment': e.target.value } })
        }
    }
    const handlesubmit = async () => {
        if(isEmpty(formValue.amountInvestment)){
            setError({"amountInvestment":"Insufficient balance"})
            return
        }
        let { result } = await AddFunds(formValue,dispatch)
        if (result.status == true) {
            toast("Amount added successfully")
            props.onDismiss()
        }else{
            toastAlert('error',result.message, 'idproof')
        }
    }
    return (
        <>
            <Modal show={addfund} className="modalbg font-12" size="md" centered scrolable>
                <Modal.Header className='align-items-center'>
                    <Modal.Title>AddFunds - TradingOne</Modal.Title>
                    <button className='closebtn btn btn-link text-light nounder' onClick={() => props.onDismiss()}>
                        <i className="fa fa-times fa-6" aria-hidden="true"></i>
                    </button>
                </Modal.Header>
                <Modal.Body className='font-12'>
                    <div className='themetab modal_align'>
                        <h5>Strategy : {copyingData?.strategyName}</h5>
                        <hr />
                        {/* <div className='px-0 mt-1 d-flex justify-content-between '>
                            <div className='px-0 content_box'>
                                <small className='text-white'>Strategy</small>
                            </div>
                            <div className='px-0 content_box'>
                                <small className='text-white' >{copyingData.strategyName}</small>
                            </div>
                        </div> */}
                        {/* <div className='px-0 mt-1 d-flex justify-content-between '>
                            <div className='px-0  content_box'>
                                <small className='text-white'>Equity</small>
                            </div>
                            <div className='px-0 content_box'>
                                <small className='text-white' >USD 112.90</small>
                            </div>
                        </div> */}
                        <div className='px-0 mt-1 d-flex sho_accu_div_full justify-content-between '>
                            <div className='px-0  content_box'>
                                <small className='text-white'>From Account</small>
                            </div>
                            <div className='px-0 content_box'>
                                <select className='themeselect flex-05' name='accountData' onChange={handleChange}>
                                    {accounts && accounts.length > 0 && accounts.map((val, index) => (
                                        <option
                                            value={JSON.stringify(val)}
                                            selected={val.accountId == copiedAccount.accountId ? true : false}
                                            disabled={SelectDisabled(val)}
                                        >
                                            {val.type} {val.accountId} - USD {val.balance.toFixed(4)} - {val.leverage}
                                        </option>
                                    ))}
                                </select>
                                {/* <small className='text-white d-flex text-primary' >{accountId?.accountData.type} &nbsp;<span className='text_align text-white'> {accountId?.accountData.accountId} - {accountId?.accountData.currency} {accountId?.accountData.balance} - {accountId?.accountData.leverage} - XTE - DM</span></small> */}
                            </div>
                        </div>
                        <div className='px-0 mt-1 d-flex justify-content-between '>
                            <div className='px-0  content_box'>
                                <small className='text-white'>Amount</small>
                            </div>
                            <div className='content_box'>
                                <div class="input-group input-group-h25 bg-dark text-white ">
                                    <div class="input-group-prepend ">
                                        <span class="input-group-text text-white " id="basic-addon1">USD</span>
                                    </div>
                                    <input type="number" class="form-control   input-group-input" aria-label="Username" aria-describedby="basic-addon1"
                                        name="amountInvestment"
                                        value={amountInvestment}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </div>
                                <span className="text-danger">{error && error?.amountInvestment}</span>
                            </div>
                        </div>
                        <div className='px-0 mt-1 d-flex justify-content-between '>
                            <div className='px-0  content_box'>
                                <small className='text-white'>Available Funds</small>
                            </div>
                            <div className='px-0 content_box'>
                                <small className='text-white'>USD {accountData.balance}</small>
                            </div>
                        </div>
                        <div className='px-0 mt-1 d-flex justify-content-between '>
                            <div className='px-0  content_box'>
                                <small className='text-white'>Current Investment</small>
                            </div>
                            <div className='px-0 content_box'>
                                <small className='text-white'>USD {currentInvestment}</small>
                            </div>
                        </div>
                        <p className='my-2'>Existing positions can be changed due to the recalculation of position sizes according to the updated Equity to Equity ration.</p>
                        <div className='d-flex align-items-center pb-3'>
                            <button className='btn green-btn mr-2 w-full' onClick={() => { handlesubmit() }}>Add Funds</button>
                            <button className='btn red-btn w-full' onClick={() => props.onDismiss()}>Cancel</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddFund