// import lib
import isEmpty from './isEmpty';

export const toFixed = (item, type = 2) => {
    try {
        if (!isEmpty(item) && !isNaN(item)) {
            item = parseFloat(item)
            return item.toFixed(type)
        }
        return ''
    } catch (err) {
        return ''
    }
}
export const toLocaleString = (item) => {
    try {
        if (!isEmpty(item) && !isNaN(item)) {
            item = parseFloat(item)
            item = item.toLocaleString('fr-CA').split(',')
            if(item[1]){
                // console.log(item[0]+'.'+item[1],'item[0]++item[1]')
                return item[0]+'.'+item[1]
            }else{
                // console.log(item[0],'item[0]++item[1]')
                return item[0]
            }
            
        }
        return ''
    } catch (err) {
        return ''
    }
}
export const toFixedDown = (item, type = 2) => {
    try {
        if (!isEmpty(item) && !isNaN(item)) {
            item = parseFloat(item)
            let decReg = new RegExp("(\\d+\\.\\d{" + type + "})(\\d)"),
                m = item.toString().match(decReg);
            return m ? parseFloat(m[1]) : item.valueOf();
        }
        return ''
    } catch (err) {
        return ''
    }
}


export const currencyFormat = (item) => {
    try {
        if (!isEmpty(item) && !isNaN(item)) {
            item = item.toString();
            let splitValue = item.split('.')
            return splitValue[1] ? `${splitValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${splitValue[1]}` : splitValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        return ''
    } catch (err) {
        return ''
    }
}
export const lotDisplay = (quantity,pairData,price)=>{
    try{
        let volume
        if (pairData?.category.toUpperCase() == 'FOREX') {
          if (pairData.precision < 5) {
            volume = quantity / pairData.lotsize
          } else {
            volume = quantity / Math.pow(10, pairData.precision)
          }
        }
        else if (pairData?.category.toUpperCase() == 'CRYPTO') {
          volume = quantity / pairData.lotsize
        }
        else {
          volume = quantity / pairData.lotsize /price
        }
        // console.log(volume,'volume')
        return volume
    }catch(err){
        console.log(err,'lotDisplay')
        return 0
    }
}