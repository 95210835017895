// Authentication
export const SET_AUTHENTICATION = 'SET_AUTHENTICATION';
export const REMOVE_AUTHENTICATION = 'REMOVE_AUTHENTICATION';

// Modal
export const TOGGLE_SIDE_BAR = "TOGGLE_SIDE_BAR";
export const SETTING_MODAL = "SETTING_MODAL"

// User Register
export const USER_REGISTER_START = 'USER_REGISTER_START';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_ERROR = 'USER_REGISTER_ERROR';

// Current User
export const SET_USER_ACCOUNT = 'SET_USER_ACCOUNT';
export const UPDATE_USER_ACCOUNT = 'UPDATE_USER_ACCOUNT';
export const SET_BANK_DETAIL = 'SET_BANK_DETAIL';
export const SET_BANK_FORM = 'SET_BANK_FORM';
export const SET_USER_ACCOUNTS = 'SET_USER_ACCOUNTS'

// Common
export const SET_LANGUAGE_OPTION = "SET_LANGUAGE_OPTION";
export const SET_CURRENCY = "SET_CURRENCY";
export const SET_TIME_ZONE = "SET_TIME_ZONE";
export const SET_USER_KYC = "SET_USER_KYC";
export const SET_KYC = "SET_KYC";
export const SET_ID_PROOF_KYC = "SET_ID_PROOF_KYC";
export const SET_ADDRESS_PROOF_KYC = "SET_ADDRESS_PROOF_KYC";
export const SET_USER_SETTING = "SET_USER_SETTING";
export const SET_THEME = 'SET_THEME';
export const SET_TRADE_THEME = 'SET_TRADE_THEME'
export const SET_PRICE_CONVERSION = 'SET_PRICE_CONVERSION'
export const SET_ANNOUNCEMENT = 'SET_ANNOUNCEMENT'
export const SET_MEDIA = 'SET_MEDIA'

// Spot Trade
export const SET_LAST_PRICE = 'SET_LAST_PRICE';
export const SET_SPOT_PAIR_TRADE = 'SET_SPOT_PAIR_TRADE';
export const SET_SPOT_TRADE_PAIR_LIST = 'SET_SPOT_TRADE_PAIR_LIST'
export const SET_SPOT_MARKET_PRICE = 'SET_SPOT_MARKET_PRICE';
export const UPDATE_SPOT_ASSET = 'UPDATE_SPOT_ASSET';
export const SET_SPOT_ORDER_BOOK = 'SET_SPOT_ORDER_BOOK';
export const SET_EQUTIY_MARGIN = 'SET_EQUTIY_MARGIN'
export const SET_CATEGORY_PAIR = 'SET_CATEGORY_PAIR'

// Perpetual Trade
export const SET_PERPETUAL_MARKET_PRICE = 'SET_PERPETUAL_MARKET_PRICE';
export const SET_PERPETUAL_PAIR_TRADE = 'SET_PERPETUAL_PAIR_TRADE';

// Currency
export const SET_USER_FIRST_CURRENCY = 'SET_USER_FIRST_CURRENCY';
export const SET_USER_SECOND_CURRENCY = 'SET_USER_SECOND_CURRENCY';

// Wallet
export const SET_USER_WALLET_LIST = "SET_USER_WALLET_LIST";
export const HIDE_ZERO = "HIDE_ZERO";
export const UPDATE_USER_WALLET_STAKE = 'UPDATE_USER_WALLET_STAKE';

// Stake Order
export const STAKE_ORDER_LIST_SUCCESS = 'STAKE_ORDER_LIST_SUCCESS';
export const STAKE_ORDER_LIST_ERROR = 'STAKE_ORDER_LIST_ERROR';
export const ADD_NEW_STAKE_ORDER = 'ADD_NEW_STAKE_ORDER';
export const CANCEL_STAKE_ORDER = 'CANCEL_STAKE_ORDER';

// P2P
export const P2P_POST_LIST_SUCCESS = 'P2P_POST_LIST_SUCCESS';
export const P2P_POST_LIST_ERROR = 'P2P_POST_LIST_ERROR';
export const P2P_ORDER_DETAIL_SUCCESS = 'P2P_ORDER_DETAIL_SUCCESS';
export const P2P_ORDER_DETAIL_ERROR = 'P2P_ORDER_DETAIL_ERROR';
export const P2P_ORDER_DETAIL_RESET = 'P2P_ORDER_DETAIL_RESET';
export const P2P_ORDER_DETAIL_CHAT = 'P2P_ORDER_DETAIL_CHAT';
export const P2P_ORDER_CANCEL = 'P2P_ORDER_CANCEL';
export const P2P_ORDER_TRANSFER_PAYMENT = 'P2P_ORDER_TRANSFER_PAYMENT';
export const P2P_ORDER_DISPUTE = 'P2P_ORDER_DISPUTE';
export const P2P_CHAT_UPDATE_SOCKET = 'P2P_CHAT_UPDATE_SOCKET';
export const P2P_ORDER_DETAIL_SOCKET = 'P2P_ORDER_DETAIL_SOCKET';
export const P2P_ORDER_STOP_TIMER = 'P2P_ORDER_STOP_TIMER';
export const P2P_SET_PAIR_LIST = 'P2P_SET_PAIR_LIST';

// ICON BUTTON
export const SUPPORT_ICON_BTN = 'SUPPORT_ICON_BTN';

// NOTICE
export const SET_UNREAD_NOTICE = 'SET_UNREAD_NOTICE';
export const UPDATE_NOTICE_POPUP = 'UPDATE_NOTICE_POPUP';
export const RESET_NOTICE = 'RESET_NOTICE'

//2FA
export const UPDATE_2FA_STATUS = 'UPDATE_2FA_STATUS'

// Launchpad
export const PURCHASE_TOKEN_LIST_SUCCESS = 'PURCHASE_TOKEN_LIST_SUCCESS';
export const PURCHASE_TOKEN_LIST_ERROR = 'PURCHASE_TOKEN_LIST_ERROR';
export const PURCHASE_TOKEN_ADD_LIST = 'PURCHASE_TOKEN_ADD_LIST';

//copyTrade
export const STRATEGY_DETAILS = 'STRATEGY_DETAILS';
export const FOLLOWERS_DETAILS = 'FOLLOWERS_DETAILS';
export const PROVIDER_SUMMARY = 'PROVIDER_SUMMARY';