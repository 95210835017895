import React, { Component, useEffect, useState,useRef } from 'react';
import { Tabs, Tab } from 'react-bootstrap'
import Chart from "react-apexcharts";
import { getVolumeBreakdown } from 'actions/copyTradeAction';
import isEmpty from 'lib/isEmpty';
const VolumeBreak = (props) => {
  console.log("VolumeBreak", props);
  const { TrdaerDetail,Followerdetails } = props
  const [series, setSeries] = useState([])
  const [lable, setLable] = useState([])
  const [show, setShow] = useState(false)
  const [volumeActive, setvolumeActive] = useState("1m")
  useEffect(() => {
    // filterData(Date.now() - (86400000 * new Date().getDay())
      filterData(getmonth("Current Month"))
  }, [TrdaerDetail,Followerdetails])

  const filterData = async (value) => {
    console.log(value,'filterDatacall')
    let data 
    if(props?.type == 'copyier'){
      data = { traderAccountId: TrdaerDetail?.accountId, followerAccountId: props.Followerdetails?.followerAccountId, date: value, day: value == 'all' ? false : true, type: 'copy'}
    }else{
     data = { accountId: TrdaerDetail?.accountId, user: TrdaerDetail?.userId, date: value, day: value == 'all' ? false : true, type: props?.type}
    }
    console.log("filterData_data", data);
    const getData = await getVolumeBreakdown(data);
    const filterSeries = getData?.result?.map((val) => val.percentageOfUSD)
    const filterLables = getData?.result?.map((val) => val.pairs)
    setTimeout(() => {
      setSeries(filterSeries)
      setLable(filterLables)
      setShow(true)
      // console.log("VolumeBreak_getData 2", getData.result,filterSeries,filterLables)
    }, 100)
  }

  const data3 = {
    // series3: series.length > 0 ? series : [21,21],
    options3: {
      chart: {
        width: '100%',
        type: 'donut',
        foreColor: "#ccc",
        toolbar: {
          autoSelected: "pan",
          show: false
        }
      },
      colors: ["#fdac9c", "#ab70f4", "#35df91", "#fbc662", "#34a8ff", "#eb11d6"],
      fill: {
        // gradient: {
        //   enabled: true,
        //   opacityFrom: 0.75,
        //   opacityTo: 0
        // }
        colors: ["#fdac9c", "#ab70f4", "#35df91", "#fbc662", "#34a8ff", "#eb11d6"]
      },
      markers: {
        size: 2,
        colors: ["#fdac9c", "#ab70f4", "#35df91", "#fbc662", "#34a8ff", "#eb11d6"],
        strokeColor: "#2769ba",
        strokeWidth: 3
      },
      tooltip: {
        theme: "dark"
      },
      labels: lable.length > 0 ? lable : ['pair', 'pair'],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 230
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },
  };

  const getmonth = (data) => {
    try {
      if (data == "Current Month") {
        return Date.now() - (86400000 * (new Date().getDate()))
      }
      if (data == "Last 2 Month") {
        return Date.now() - (86400000 * (new Date().getDate() + 30))
      }
      if (data == "Last 3 Month") {
        return Date.now() - (86400000 * (new Date().getDate() + 61))
      }
      if (data == "Last 6 Month") {
        return Date.now() - (86400000 * (new Date().getDate() + (61 * 2)))
      }
      if (data == "Last 1 Year") {
        return Date.now() - (86400000 * (new Date().getDate() + (30 * 12)))
      }
    } catch (err) {
      console.log('getmonth---err', err)
    }
  }

  return (
    <>
      <div className='btn_graph_tab'>
        <button className={volumeActive == "1w" ? "graytab tab_style_inner_grey pt-2 px-0 active":"graytab tab_style_inner_grey pt-2 px-0"} 
        onClick={() => { setvolumeActive("1w");filterData(Date.now() - (86400000 * new Date().getDay()))}} > 
        1w 
        </button>
        <button className={volumeActive == "1m" ? "graytab tab_style_inner_grey pt-2 px-0 active":"graytab tab_style_inner_grey pt-2 px-0"} 
        onClick={() =>{setvolumeActive("1m"); filterData(getmonth("Current Month"))}}  > 
        1m 
        </button>
        <button className={volumeActive == "2m" ? "graytab tab_style_inner_grey pt-2 px-0 active":"graytab tab_style_inner_grey pt-2 px-0"} 
        onClick={() =>{setvolumeActive("2m"); filterData(getmonth("Last 2 Month"))}}  > 
        2m 
        </button>
        <button className={volumeActive == "3m" ? "graytab tab_style_inner_grey pt-2 px-0 active":"graytab tab_style_inner_grey pt-2 px-0"} 
        onClick={() =>{ setvolumeActive("3m"); filterData(getmonth("Last 3 Month"))}}  > 
        3m 
        </button>
        <button className={volumeActive == "6m" ? "graytab tab_style_inner_grey pt-2 px-0 active":"graytab tab_style_inner_grey pt-2 px-0"} 
        onClick={() =>{setvolumeActive("6m"); filterData(getmonth("Last 6 Month"))}}  > 
        6m 
        </button>
        <button className={volumeActive == "1y" ? "graytab tab_style_inner_grey pt-2 px-0 active":"graytab tab_style_inner_grey pt-2 px-0"} 
        onClick={() =>{setvolumeActive("1y"); filterData(getmonth("Last 1 Year"))}}  > 
        1y 
        </button>
        <button className={volumeActive == "all" ? "graytab tab_style_inner_grey pt-2 px-0 active":"graytab tab_style_inner_grey pt-2 px-0"} 
        onClick={() => {setvolumeActive("all");filterData(getmonth("all"))}}  > 
        All 
        </button>
      </div>
      {show && series.length > 0 ?
    <>
    <Chart options={data3?.options3}
        series={series && series}
        type="donut" height={230} width="100%" className="px-0" />
    </>  : <div className='nodata_grp_div'> 
    <p className='no_data_div_txt'>No data found</p>
    </div>
    }
    </>
    
  )
}

export default VolumeBreak