import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { Form } from 'react-bootstrap';
import { UserdetailsUpdate, changeNewPhone, verifyNewPhone } from '../actions/users';
import isEmpty from '../lib/isEmpty';
import validation from '../components/ProfileDetail/validation';
import { useTranslation } from 'react-i18next';
import { CountryDropdown } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';
import upiimage from "../assets/images/upi.png"
import { toastAlert } from "lib/toastAlert";
import $ from "jquery"
import moment from 'moment';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const initialFormValue = {
  'phoneCode': '',
  'firstname': "",
  'lastname': "",
  'Email': "",
  'dob': "",
  'PhoneNo': "",
  'City': "",
  'Country': "",
  'citizenship': "",
  'otp': 0
}

const Basicdetail = (props) => {
  console.log('props------------->', props)
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch()
  //state
  const [formValue, setFormValue] = useState(initialFormValue);
  console.log("formValue_formValue_formValue", formValue);
  const [toched, setToched] = useState({});
  const [phonedatas, setphonedata] = useState({})
  const [userdetailsUpdate, setUserdetailUpdate] = useState(initialFormValue)
  console.log('userupdatesdetails hihi----->', userdetailsUpdate)
  const [validateError, setValidateError] = useState({});
  const [sentOpt, SetSentotp] = useState(false)
  const [showotp, setshowotp] = useState(false)
  const [rtd,setrtd]=useState(false)
  // console.log('val8idate',validateError)




  const { firstname, lastname, dob, PhoneNo, City, Email, Country, citizenship, } = userdetailsUpdate;
  console.log("userdetailsUpdateuserdetailsUpdate", userdetailsUpdate);

  const { frontImage } = props?.props;
  const { frontImages } = props?.props;

  // console.log('frontImges1 -->',frontImages)
  // console.log('frontImges2 -->',frontImage)
  // redux_state
  const accountData = useSelector(state => state.account);

  const { firstName, lastName, DOB, phoneNo, phoneCode, city, email, country, Citizenship, emailStatus } = accountData;
  console.log('accountData<----------------', accountData)

  // useEffect(() => {
  //   const phonedata = {
  //      phone: accountData.phoneCode + accountData.phoneNo }
  //   console.log("phonedata--------", phonedata,phoneCode,phoneNo);
  //   setphonedata(phonedata)
  // }, [phoneCode, phoneNo])
  //function
  const onInputchange = (e) => {
    let regx = /^\d+$/

    if (e.target.name == 'otp' && !regx.test(e.target.value)) {
      return
    }
    console.log(e.target.value, e.target.name, 'onInputchange', e.target.name == 'otp' && !regx.test(e.target.value))
    setUserdetailUpdate({ ...userdetailsUpdate, [e.target.name]: e.target.value })

    if (!isEmpty(validateError)) {
      setValidateError({})
    }

  }

  const handleCountry = (value) => {
    setUserdetailUpdate({ ...userdetailsUpdate, ...{ 'Country': value } })
    if (!isEmpty(validateError)) {
      setValidateError({})
    }
  }
  // $(function () {
  //   $(".dob").datepicker({
  //     startDate: new Date()
  //   });
  // })

  const handlePhoneNumber = (value, country) => {
    console.log("value------", value);
    const { dialCode } = country;
    let newPhoneNo = value;
    let formData = formValue;
    if (dialCode) {
      console.log("value------2", value);
      SetSentotp(true)
      formData = {
        ...formData,
        phoneCode: dialCode,
        PhoneNo: newPhoneNo.slice(dialCode.length)
      }
      console.log("formData------", formData);
      setValidateError({'PhoneNo':''})
    } else if (value) {
      formData = { ...formData, PhoneNo: value }
    }

    setFormValue(formData)
    // setValidateError(validation(formData))

  }

  const handleBlurPhone = (e) => {

    setToched({ ...toched, 'phoneNo': true, 'phoneCode': true })
  }

  const handleMobileSubmit = async (e) => {
    e.preventDefault()
    const phoneCode = formValue.phoneCode == "" ? accountData.phoneCode : formValue.phoneCode
    const PhoneNo = formValue.PhoneNo == "" ? accountData.phoneNo : formValue.PhoneNo
    let reqData = {
      newPhoneCode: phoneCode,
      newPhoneNo: PhoneNo
    }
    try {
      let { status, loading, error, message } = await changeNewPhone(reqData);

      if (status == "success") {
        setshowotp(true)
        SetSentotp(false)
        // setMobileDetail({
        //     ...mobileDetail, ...{
        //         'isLoading': false,
        //         'isDisable': true,
        //         'timer': mobileDetail.timer - 1,
        //         'timerStart': true,
        //         'type': 'resend'
        //     }
        // })
        // setDisablePh(true)
        toastAlert('success', message, 'editPhoneNumber');
      } else {
        setValidateError({ ...validateError, ...{ 'PhoneNo': error.newPhoneNo } })
        // setMobileDetail({
        //     ...mobileDetail, ...{
        //         'isLoading': false,
        //         'isDisable': false
        //     }
        // })
        // if (!isEmpty(error)) {
        //     setValidateError(error);
        //     return
        // }
        toastAlert('error', message, 'editPhoneNumber');
      }
    } catch (err) {
      console.log("change Phone", err)
    }
  }

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    try {
      // setLoader(true)
      const phoneCode = formValue.phoneCode == "" ? accountData.phoneCode : formValue.phoneCode
      const PhoneNo = formValue.PhoneNo == "" ? accountData.phoneNo : formValue.PhoneNo
      let reqData = {
        newPhoneCode: phoneCode,
        newPhoneNo: PhoneNo,
        otp: userdetailsUpdate.otp
      }
      // let reqData = {
      //     newPhoneCode,
      //     newPhoneNo,
      //     otp
      // }
      let { status, loading, error, message, result } = await verifyNewPhone(reqData);
      // setLoader(loading)
      if (status == "success") {
        setshowotp(false)
        SetSentotp(false)
        // setUserdetailUpdate({ ...userdetailsUpdate, "phoneCode": result.phoneCode, 'PhoneNo': result.phoneNo })
        // setDisablePh(true)
        // setFormValue({
        //     'newPhoneCode': result.phoneCode,
        //     'newPhoneNo': result.phoneNo,
        //     'otp': ''
        // })
        // setMobileDetail(mobileInitialValue)
        toastAlert('success', message, 'editPhoneNumber');
      } else {
        if(error.otp == "Expiry OTP" ){
          SetSentotp(true)
        }
        setValidateError(error);
      }
    }
    catch (err) {
      console.log(err,"handleOtpSubmit_err")
    }
  }
  //onsubmit
  const onsubmit = async (e) => {
    console.log("e------------", e);
    e.preventDefault();
    if (emailStatus === "unverified") {
      toastAlert('error', "Activation link already sent to your email...Please activate your email account.")
      return
  }
    const phoneCode = formValue.phoneCode == "" ? accountData.phoneCode : formValue.phoneCode
    const PhoneNo = formValue.PhoneNo == "" ? accountData.phoneNo : formValue.PhoneNo

    let updatedata = {
      firstname,
      lastname,
      phoneCode,
      dob,
      Email,
      PhoneNo,
      City,
      Country,
      citizenship,
      phone: ''
    }
    console.log("updatedataupdatedata", updatedata, email, phoneNo, formValue.PhoneNo, accountData.phoneNo);
    if (!isEmpty(email)) {
      updatedata.key = email
    }
    updatedata.frontImage = props.props.frontImage;
    updatedata.frontImages = props.props.frontImages

    console.log('updatedata hihi', updatedata)
    let validationerror = validation(updatedata);
    console.log('validationerror-------------->', validationerror)
    if (!isEmpty(validationerror)) {
      setValidateError(validationerror)
      // props.setValidateErrors(validationerror)
      return
    }else if(sentOpt){
      let errors = {}
      errors.PhoneNo = "Please click Send OTP button to receive OTP"
      setValidateError(errors)
      return
    }else if(showotp){
      let errors = {}
      errors.otp = "Please enter the otp and click the verify to update mobile no"
      setValidateError(errors)
      return
    }
    const formData = new FormData();
    formData.append('firstname', updatedata.firstname)
    formData.append('lastname', updatedata.lastname)
    formData.append('dob', updatedata.dob)
    formData.append('Email', updatedata.Email)
    formData.append('phoneCode', updatedata.phoneCode)
    formData.append('PhoneNo', updatedata.PhoneNo)
    formData.append('City', updatedata.City)
    formData.append('citizenship', updatedata.citizenship)
    formData.append('Country', updatedata.Country)
    formData.append('key', updatedata.key)
    formData.append('frontImage', updatedata.frontImage)
    try {
      console.log("hiii");
      let { status, loading, message, error, result } = await UserdetailsUpdate(formData, dispatch)
      console.log('resultss_hihi', result)
      if (status) {
        // setUserdetailUpdate({ ...result, ...{ 'dob': new Date(parseFloat(result.DOB)).getTime(),'PhoneNo':result.phoneNo} })
        // setUserdetailUpdate(result)
        let data = {
          'phoneCode': result.phoneCode,
          'firstname': result.firstName,
          'lastname': result.lastName,
          'Email': result.email,
          'dob': new Date(parseFloat(result.DOB)).getTime(),
          'PhoneNo': result.phoneNo,
          'City': result.city,
          'Country': result.country,
          'citizenship': result.Citizenship
        }
        setUserdetailUpdate(data)
        toastAlert('success', t(message));

      }
      else {
        if (error) {
          setValidateError(error)
          return
        }
        toastAlert('error', t(message), 'editprofile')
      }
    }
    catch (err) {
      console.log('err----->', err)
    }
  }
  const datass = String(accountData.phoneCode) + "" + String(accountData.phoneNo)
  console.log("atassasa", datass);

  useEffect(() => {
    if (email || phoneNo) {
      console.log("email_email", email);
      setUserdetailUpdate({
        'firstname': firstName,
        'lastname': lastName,
        'Email': email,
        'dob': DOB == null ? '' : new Date(parseFloat(DOB)).getTime(),
        // 'phoneNo': phoneNo,
        // 'phoneCode':phoneCode,
        'PhoneNo': String(accountData.phoneNo),
        'City': city,
        'Country': country,
        'citizenship': Citizenship
      })
    }
  }, [email])

  const today = moment();
  const disableFutureDt = current => {
    return current.isBefore(today)
  }

  return (
    <div className='basicdetail'>
      {/* {console.log('userdetailsUpdate deatails: ',userdetailsUpdate)} */}
      <div className='container custom-container1'>

        <div className="card text-white">


          <div className="card-body">
            <div className="row mt-3">
              <div className="col-md-6">
                <form>
                  <div class="form-group">
                    <label for="exampleInputEmail1">First Name</label>
                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Enter First Name' name="firstname" maxlength="20" value={firstname} onChange={(e) => onInputchange(e)} />
                    {validateError.firstname && <p className="error-message">{t(validateError.firstname)}</p>}
                  </div>
                </form>
              </div>
              <div className="col-md-6">
                <form>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Last Name</label>
                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter LastName" name="lastname" maxlength="20" value={lastname} onChange={(e) => onInputchange(e)} />
                    {validateError.lastname && <p className="error-message">{t(validateError.lastname)}</p>}
                  </div>
                </form>
              </div>
            </div>


            <div className="row mt-3">
              <div className="col-md-6">
                <form>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Email</label>
                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Email" name="Email" value={Email} onChange={(e) => onInputchange(e)} />
                    {validateError.Email && <p className="error-message">{t(validateError.Email)}</p>}
                  </div>
                </form>
              </div>
              <div className="col-md-6">
                <form>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Date Of Birth (DD/MM/YYYY)</label>

                    {/* <Datetime input={true} timeFormat={false}/> */}

                    {/* <DatePicker
                 
                      popperClassName="some-custom-class mb-mob-ip datr_pick_dateonly"
                      className="example-custom-input"
                      popperPlacement="bottom-end"
                      selected={dob}
                      placeholder="Enter DOB"
                  
                      name="dob"
                      onChange={(date) => {
                        let newDate = new Date(date)
                       
                        setUserdetailUpdate({ ...userdetailsUpdate, 'dob': newDate.getTime() })
                     
                      }}
                      // showTimeSelect
                      // timeFormat="HH:mm"
                      // timeIntervals={1}
                      dateFormat="MMMM d, yyyy"
                                            
                      maxDate={new Date()}
                   
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    /> */}
                    <Datetime
                      popperPlacement="top-end"
                      timeFormat={false}
                      input={true}
                      closeOnSelect={true}
                      value={dob}
                      // isValidDate={valid}  
                      className="w-100 rdt_new rd_new_upload_date selectdate_pick"
                      isValidDate={disableFutureDt}
                      inputProps={{ onKeyDown: (e) => { e.preventDefault() } }}
                      onChange={(e) => {
                        let newDate = new Date(e._d)
                        setrtd(false)
                        setUserdetailUpdate({ ...userdetailsUpdate, 'dob': newDate.getTime() })
                        // console.log("event", e._d)
                      }}

                    // timeFormat="HH:mm:ss"
                    // timeConstraints={{
                    //   hours: { min: new Date(Date.now()).getHours(), max: 23 },
                    // }}
                    // onChange={(value) => {
                    //   Auctionset(value);
                    // }}
                    />

                    {/* <Form.Control 
                                 type="date" 
                            

                                selected={dob}
                                placeholder="Enter DOB"
                            
                                name="dob"
                                onChange={(date) => {
                                  let newDate = new Date(date)
                                 
                                  setUserdetailUpdate({ ...userdetailsUpdate, 'dob': newDate.getTime() })
                               
                                }}
                               
                                dateFormat="MMMM d, yyyy "
                                
                            
                                maxDate='now'
                             
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                                 /> */}

                    {validateError.dob && <p className="error-message">{t(validateError.dob)}</p>}
                  </div>
                </form>
              </div>
            </div>



            <div className="row mt-3">
              <div className="col-md-6">
                <form>
                  <div className="form-group">
                    {/* <label for="exampleInputEmail1">Mobile</label> */}
                    <label className="login_label mb-2">{t('MOBILE_NO')}</label>
                    {/* <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" country={'gb'} placeholder="Enter Mobile" maxlength="10" name="PhoneNo"   value={phoneNo} onChange={(e) => onInputchange(e)} /> */}
                    {/* <div className="input_box_poa inout_with_cout mt-2"> */}
                    <div className="d-flex">
                      <PhoneInput className="phoneinput"
                        // id="exampleInputEmail1"
                        // aria-describedby="emailHelp"
                        placeholder="Enter mobile number"
                        // maxlength="10"
                        name="PhoneNo"
                        value={String(phoneCode) + "" + String(PhoneNo)}
                        // onChange={(e) => onInputchange(e)} 
                        onChange={handlePhoneNumber}
                        onBlur={handleBlurPhone}
                        specialLabel={false}
                        country={'gb'}
                      />
                      {sentOpt && <button className="basic_sendotp_btn" onClick={(e) => { handleMobileSubmit(e) }}>Send OTP</button>}
                    </div>
                    {/* </div> */}
                    {validateError.PhoneNo && <p className="error-message">{t(validateError.PhoneNo)}</p>}
                  </div>
                </form>
                {showotp &&
                <>
                <div className="basic_otp_verifywhole">
                    <input className="basic_opt_input" name="otp" placeholder="Enter OTP here..." type="text" onChange={onInputchange} />
                    <button className="basic_sendotp_btn otpverified" onClick={(e) => { handleOtpSubmit(e) }}>Verify</button>
                  </div>
                  {validateError.otp && <p className="error-message">{validateError.otp}</p>}
                </>
                  }
                  
              </div>
              <div className="col-md-6">
                <form>
                  <div class="form-group">
                    <label for="exampleInputEmail1">City</label>
                    <input type='text' class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter City" maxlength="20" name="City" value={City} onChange={(e) => onInputchange(e)} />
                    {validateError.City && <p className="error-message">{t(validateError.City)}</p>}
                  </div>
                </form>
              </div>
            </div>


            <div className="row dis_order mt-3">
              <div className="col-md-6">
                <form>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Country</label>
                    <CountryDropdown class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Country" name="Country" value={Country} onChange={handleCountry} />
                    {validateError.Country && <p className="error-message">{t(validateError.Country)}</p>}
                  </div>
                </form>
              </div>
              <div className="col-md-6">
                <form>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Citizenship</label>
                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Citizenship" maxlength="20" name="citizenship" value={citizenship} onChange={(e) => onInputchange(e)} />
                    {validateError.citizenship && <p className="error-message">{t(validateError.citizenship)}</p>}
                  </div>
                </form>
              </div>
            </div>
            <div className="row dis_order mt-3">
              <div className="col-md-6 btncenter">
                <form>
                  <button type='button' className="btn btn-light mt-3" onClick={(e) => onsubmit(e)}>Submit</button>
                </form>
              </div>

            </div>

          </div>

        </div>



      </div>

    </div>
  );

}

export default Basicdetail;
