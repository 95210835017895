import { getTradeFilter } from 'actions/copyTradeAction';
import React, { Component, useEffect, useState } from 'react';
import { Tabs, Tab, Form } from 'react-bootstrap'
const Trades = (props) => {
  const [data, setData] = useState();
  const [USD, setUSD] = useState();
  const [ishistoryday, setIshistoryday] = useState(true);
  const [ishistorydate, setIshistorydate] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [day, setDay] = useState("");
  const {summaryDetails,Followerdetails}=props;
  const [disablediv, setDisablediv] = useState(1);
  useEffect(() => {
    setData(props?.summaryDetails?.ClosedPosition[0]);
    setUSD(props?.summaryDetails);
    setDisablediv(1);
  }, [summaryDetails,Followerdetails]);

  console.log("Trade_props", props);
  const getmonth = (data) => {
    try {
      if (data == "Current Month") {
        return Date.now() - (86400000 * (new Date().getDate()))
      }
      if (data == "Last 2 Month") {
        return Date.now() - (86400000 * (new Date().getDate() + 30))
      }
      if (data == "Last 3 Month") {
        return Date.now() - (86400000 * (new Date().getDate() + 61))
      }
    } catch (err) {
      console.log('getmonth---err', err)
    }
  }

  const disableCheckDiv = (e) => {
    if (e == "check-api-radio10") {
      document.getElementById("diable_check_id_11").classList.add("disable_chk");
      document.getElementById("diable_check_id_10").classList.remove("disable_chk");
      document.getElementById("check-api-radio11").classList.add("disable_chk");
      document.getElementById("check-api-radio10").classList.remove("disable_chk");
    }
    else if (e == "check-api-radio11") {
      document.getElementById("diable_check_id_10").classList.add("disable_chk");
      document.getElementById("diable_check_id_11").classList.remove("disable_chk");
      document.getElementById("check-api-radio10").classList.add("disable_chk");
      document.getElementById("check-api-radio11").classList.remove("disable_chk");
    }
  }


  const filterData = async(days, start, end) => {
    console.log("filterData_getData", days, start, end)

    let payload = {}
    setEndDate(end)
    setStartDate(start)
    setDay(days)
    if (ishistoryday) {
      if (days) {
        payload.days = days
      }
    }
    if (ishistorydate) {
      if (start) {
        payload.startDate = start;
      }
      if (end) {
        payload.endDate = end;
      }
    }
  
    payload.day = days == "all" ? false : ishistoryday
    payload.date = days == "all" ? false : ishistorydate
    let getData
    if (props.type == "copyier") {
      payload.type = 'copy'
      payload.traderAccountId = props?.TrdaerDetail.accountId
      payload.followerAccountId = props.Followerdetails?.followerAccountId
      console.log("Trade_filterData_copyier_payload", payload);
       getData = await getTradeFilter(payload);
      console.log("getData_filterData",getData)
    }else{
      payload.accountId = props?.TrdaerDetail?.accountId
      payload.userId = props?.TrdaerDetail?.userId
      getData = await getTradeFilter(payload);
      console.log("getData_filterData",getData)
    }
    if(getData){
      setData(getData?.result?.ClosedPosition[0]);
      setUSD(getData?.result);
    }
  }

  return (
    <>
      <div className="d-flex jc-between gap-2 px-0 py-0">
        <div className="d-flex align-items-center gap-2 block_mob_res input_multi_rade">
          <p className='d-flex align-items-center px-0 label_per_txt mr-1'>Period</p>
          <div className="d-flex block_mob_res" onChange={(e) => { 
            // disableCheckDiv(e.target.getAttribute("id") )
             }}>
            {/* <Form.Check type="radio" className="mb-0" >
              <Form.Check.Input id="check-api-radio10" type="radio" name="radio" isValid checked={ishistoryday}
                onChange={e => {
                  setIshistoryday(true);
                  setIshistorydate(false);
                }}
              />
              <Form.Check.Label for="check-api-radio10" id="diable_check_id_10"> */}
               <div className={disablediv == 0 ?"disabled_div_new disabled_div_flex":"disabled_div_flex"}>
           
              <div id="ele_1" className={ishistoryday?'check_cuts checked':'check_cuts'}
              onClick={()=>{
                setIshistoryday(true)
                setIshistorydate(false);
                setDisablediv(1);
              }}
              >

              </div>
                <select className="darkselect selectcount selctsm selct_max_widt_sm" onChange={(e) => { filterData(e.target.value, startDate, endDate) }}>
                  <option value="">All History</option>
                  <option value={Date.now() - (86400000 * 3)}>Last 3 Days</option>
                  <option value={Date.now() - (86400000 * new Date().getDay())}>Current Week</option>
                  <option value={Date.now() - (86400000 * (new Date().getDay() + 7))} >Last 2 Week</option>
                  <option value={Date.now() - (86400000 * (new Date().getDay() + 14))}>Last 3 Week</option>
                  <option value={getmonth("Current Month")}>Current Month</option>
                  <option value={getmonth("Last 2 Month")}>Last 2 Month</option>
                  <option value={getmonth("Last 3 Month")}>Last 3 Month</option>
                </select>
                </div>
              {/* </Form.Check.Label>
            </Form.Check> */}
            {/* <Form.Check type="radio" className="mb-0 ml-2">
              <Form.Check.Input id="check-api-radio11" type="radio" name="radio" isValid className="mb-0"
                checked={ishistorydate} onChange={e => { setIshistorydate(e.target.checked); setIshistoryday(false) }}
              />
              <Form.Check.Label for="check-api-radio11" className="mb-0" id="diable_check_id_11"> */}
               <div className={disablediv == 1 ?"disabled_div_new disabled_div_flex":"disabled_div_flex"}>
                 <div id="ele_2" className={ishistorydate?'check_cuts checked':'check_cuts'}
              onClick={()=>{
                setIshistorydate(true)
                setIshistoryday(false);
                setDisablediv(0);


              }}
              ></div>
                <div className="d-flex gap-2 tabinput p-0">
                   <input type="date" className="themeinput py-1" max={new Date()} onChange={(e) => { setStartDate(day, e.target.value); filterData(day, e.target.value, endDate) }} />
                  <input type="date" className="themeinput py-1" max={new Date()} onChange={(e) => { setEndDate(e.target.value); filterData(day, startDate, e.target.value) }} />
                </div>
                </div>
              {/* </Form.Check.Label>
            </Form.Check> */}
          </div>
        </div>


      </div>

      <div className="darkbox h-table-big">
        <div className="table-responsive p-0">
          <table className="w-full">
            <tr>
              <th><label>Summary</label></th>
              <th><label>All Trades</label></th>
              <th><label>Long Trades</label></th>
              <th><label>Short Trades</label></th>
            </tr>
            <tr>
              <td>
              Profit Factor
              </td>
              <td>{data ? data?.netProfit?.toFixed(4) : 0}</td>
              <td>{data ? data?.longNetprofit?.toFixed(4) : 0}</td>
              <td>{data ? data?.shortNetprofit?.toFixed(4) : 0}</td>
            </tr>
            <tr>
              <td>
                Net Profit
              </td>
              <td>{data ? data?.profit?.toFixed(4) : 0}</td>
              <td>{data ? data?.longProfit?.toFixed(4) : 0}</td>
              <td>{data ? data?.shortProfit?.toFixed(4) : 0}</td>
            </tr>
            <tr>
              <td>
                Commission
              </td>
              <td>{data ? data?.performanceFee?.toFixed(3) : 0}</td>
              <td>{data ? data?.performanceFeeLong?.toFixed(3) : 0}</td>
              <td>{data ? data?.performanceFeeShort?.toFixed(3) : 0}</td>
            </tr>
            {/* <tr>
              <td>
                Max Balance
              </td>
              <td>0.54%</td>
              <td>3.57%</td>
              <td>118.78%</td>
            </tr> */}
            <tr>
              <td>
                Total Trades
              </td>
              <td>{data ? data?.count : 0}</td>
              <td>{data ? data?.longCount : 0}</td>
              <td>{data ? data?.shortCount : 0}</td>
            </tr>
            <tr>
              <td>
                Winning Trades
              </td>
              <td>{data ? data?.profitCount : 0}</td>
              <td>{data ? data?.longW : 0}</td>
              <td>{data ? data?.shortW : 0}</td>
            </tr>
            <tr>
              <td>
                Largest Winning Trades
              </td>
              <td>{data ? data?.largestWinningTrades?.toFixed(4) : 0}</td>
              <td>{data ? data?.largestLongTrades?.toFixed(4) : 0}</td>
              <td>{data ? data?.largestShortTrades?.toFixed(4) : 0}</td>
            </tr>
            <tr>
              <td>
                Losing Trades
              </td>
              <td>{data ? data?.lossCount : 0}</td>
              <td>{data ? data?.longL : 0}</td>
              <td>{data ? data?.shortL : 0}</td>
            </tr>
            <tr>
              <td>
                Largest Losing Trades
              </td>
              <td>{data ? data?.largestLosingTrades?.toFixed(4) : 0}</td>
              <td>{data ? data?.largestLosingLongTrades?.toFixed(4) : 0}</td>
              <td>{data ? data?.largestLosingShortTrades?.toFixed(4) : 0}</td>
            </tr>
            <tr>
              <td>
                Average Trade
              </td>
              <td>{data ? data?.avgAllTrade?.toFixed(4) : 0}</td>
              <td>{data ? data?.avgLongTrade?.toFixed(4) : 0}</td>
              <td>{data ? data?.avgShortTrade?.toFixed(4) : 0}</td>
            </tr>
            {/* <tr>
              <td>
                Average Trade Division
              </td>
              <td>2h 8m 1s 316ms</td>
              <td>2h 8m 1s 294ms</td>
              <td>2h 8m 1s 655ms</td>
            </tr> */}
            <tr>
              <td>
                USD Volume Total
              </td>
              <td>{data ? USD?.tradedTotal?.toFixed(4) : 0}</td>
              <td>{data ? USD?.tradedLongTotal?.toFixed(4) : 0}</td>
              <td>{data ? USD?.tradedShortTotal?.toFixed(4) : 0}</td>
            </tr>
          </table>
        </div>
      </div>
    </>

  )
}

export default Trades