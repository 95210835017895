import React, { useState, useEffect, Redirect, useContext, Fragment } from "react";
import { useLocation, Link ,useParams} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import ProgressBar from 'react-bootstrap/ProgressBar';
import ReactDatatable from '@ashvin27/react-datatable';
import Carousel from 'react-bootstrap/Carousel'

import Pagination from "../components/Dashboard/pagination";
import { toFixed } from "lib/roundOf";
import { currencySymbol } from "lib/pairHelper";
// import Fundaccount from "./fundaccount";
import Hammericon from '../assets/images/hammer.png'
import Tickicon from '../assets/images/tick.png'
import Copyicon from '../assets/images/copy.png'
import Adminicon from '../assets/images/pf_icon.png'


import SocketContext from '../components/Context/SocketContext'
import { getKycDetail } from '../actions/userKyc'
import { getEqutity } from "../actions/users";
import { getPairList,setPairList } from 'actions/spotTradeAction';

// import Tooltip from 'react-bootstrap/Tooltip';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import ReactTooltip from "react-tooltip";

import { toastAlert } from "lib/toastAlert";

import { momentFormat } from '../lib/dateTimeHelper'

import CopyToClipboard from "react-copy-to-clipboard";


// import action
import { histrade } from "../actions/perpetualTradeAction";

import { balances } from "../actions/perpetualTradeAction";
import { lotDisplay } from '../lib/roundOf'


// import lib
import { dateTimeFormat } from "../lib/dateTimeHelper";
import { balanceConvention } from "lib/calculation";
import { toLocaleString } from "lib/roundOf";
const reqData = {
  'firstCurrencySymbol': "BTC",
  'secondCurrencySymmol': "USD"
}

const Myaccount = ({ setRedirected }) => {

  // const tooltip = (
  //   <Tooltip id="tooltip">
  //     <strong>Your Equity</strong> 
  //   </Tooltip>
  // );
  const [Equtitypre, SetEqutityper] = useState(0)
  // const [PandLSingel, SetPandLSingel] = useState()
  const [Margin, setPositionMargin] = useState(0)
  const [freemagins, SetFreemargin] = useState(0)
  const [marginlevels, Setmarginlevels] = useState(0)
  // const [profitandloss, SetProLoss] = useState(0)
  const { tikerRoot } = useParams()
  const accountData = useSelector(state => state.account);
  const resdux = useSelector(state => state)
  // console.log("accountData---jj------------>", accountData);
  const { status, useraccount, equtity } = accountData
  // console.log("equtity_equtity_", equtity);
  const [totalBals, setTotalBals] = useState(0);  // Balance Loader
  const [estBal, setEstBal] = useState(0);  // Estimated Balance
  const [balLoader, setBalLoader] = useState(true);  // Balance Loader
  const [estLoader, setEstLoader] = useState(true);  // Estimated Loader

  const [data, setData] = useState([]);
  const socketContext = useContext(SocketContext)
  const walletData = useSelector(state => state.wallet);
  const priceConversion = useSelector(state => state.priceConversion)
  const userSetting = useSelector(state => state.userSetting)
  // console.log("userSetting-0--------------", userSetting);
  const currencyData = useSelector(state => state.currency)
  const { referralCode } = useSelector(state => state.auth);

  const pairList = useSelector(state => state.pairList);
  // console.log("pairList_pairlist",pairList)

  const [show, setShow] = React.useState(false);

  const [count, setcount] = useState(0);
  const [loader, setLoader] = useState(true);
  const [type, setType] = useState();
  // console.log("type------->>", type);
  const dispatch = useDispatch();
  const [copy, setCopy] = useState('');
  const [reportData, setreportData] = useState([]);
  const [filter, setFilter] = useState({
    'page': 1,
    'limit': 5,
    'AccountsId': useraccount && useraccount?.accountId,
    "type":"all"
  })
  const [Cindex, setCIndex] = useState(0)
  const types = "LIVE"

  if (localStorage.getItem("liveorDemo")) {
    var seting = localStorage.getItem("liveorDemo")
    localStorage.setItem("liveorDemo", seting);
  }
  else {
    localStorage.setItem("liveorDemo", "LIVE");

  }
  const typess = localStorage.getItem("liveorDemo")

  const handleSelect = (selectedIndex, e) => {
    setCIndex(selectedIndex);
  };
  function handlePagination(index) {
    let filterData = { ...filter, ...{ 'page': index.page_number, 'limit': index.page_size } }
    setFilter(filterData);
    fetchTransaction(filterData);
  }
  const fetchTransaction = async () => {
    try {

      // console.log("useraccount && useraccount?.accountId", useraccount && useraccount?.accountId);
      // console.log("filter------", filter);
      let Data = { ...filter, ...{ 'AccountsId': useraccount && useraccount?.accountId } }
      // console.log("Data----------", Data);
      // setFilter(Data);
      const { status, loading, result, count, reportData } = await histrade(Data);
      // console.log("result------------->", result)
      const { results } = await balances();
      setLoader(loading);
      if (status == "success") {

        setData(result);
      }


    } catch (err) { }
  };

  const Currency = async (data) => {
    const filtercount = { ...filter, ...{ 'type': data } }
    setFilter(filtercount)
    fetchTransaction()
  }

  const copyCode = (data) => {
    if (data) {
      toastAlert('success', 'Referral Code copied')
    }
    else {
      toastAlert('error', 'No respond can found')
    }
  }

  const handleChanges = async (e) => {
    setCopy(e.target.value);
  }

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  useEffect(() => {
    fetchTransaction();
    getKycDetail(dispatch)
    let data = {
      accountId: useraccount?.accountId
    }
  //   if(!(pairList.length > 0)){
  //     fetchPairList()
  // }
    getEqutity(data, dispatch)
  }, [filter, useraccount]);

  useEffect(() => {
    socketContext.socket.on('positionmargin', (results) => {
      // console.log('positionmargin__socket',results)'
      try {
        SetEqutityper(results.equity)
        setPositionMargin(results.positionmargins)
        SetFreemargin(results.freemargin)
        Setmarginlevels(results.marginlevels)
      } catch (err) {
        console.log(err, 'positionmargin__err')
      }
    })
  },[socketContext.socket])
  
  // const fetchPairList = ()=>{
  //   try{
  //     const {status,result}=getPairList()
  //     if(status=='success'){
  //       setPairList(result,dispatch)
  //     }
  //   }catch(err){
  //     console.log(err)
  //   }
  // }
  const columns = [
    {
      key: "orderDate",
      text: "Date",
      className: "Date",
      align: "center",
      sortable: true,
      cell: record => {
        return momentFormat(record.orderDate, 'DD-MM-YYYY HH:mm')
      }
    },
    {
      key: "pairName",
      text: "Pair",
      className: "Pair",
      align: "center",
      sortable: true
    },
    {
      key: "buyorsell",
      text: "Side",
      className: "Side",
      align: "center",
      sortable: true
    },
    {
      key: "quantity",
      text: "Quantity",
      className: "Filled",
      align: "center",
      sortable: true,
      cell: record => {
        console.log(record.quantity,'record.filledAmount')
        let pairDetail = pairList.find((val)=>(val.tiker_root==record.pairName))
        return lotDisplay(record?.quantity,pairDetail,record.price).toFixed(2)
      }
    },
    {
      key: "price",
      text: "Price",
      className: "Price",
      align: "center",
      sortable: true
    },
    {
      key: "takerFee",
      text: "Fee",
      className: "Fee",
      sortable: true,
      align: "center",
      cell: record => {
        return record?.takerFee
      }
    },
    {
      key: "orderValue",
      text: "Contract Value (USD)",
      className: "Total",
      sortable: true,
      align: "center",
      cell: record => {
        
        return (record?.orderValue).toFixed(2)
      }
    },
  ];
  const config = {
    page_size: 5,
    length_menu: [5, 10, 20, 50],
    filename: "Order",
    no_data_text: 'No Records found!',
    button: {
      extra: true,
    },
    language: {
      length_menu: "Show _MENU_ Entries",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: <i class="fa fa-backward" aria-hidden="true" />,
        previous: <i class="fa fa-chevron-left" />,
        next: <i class="fa fa-chevron-right" />,
        last: <i class="fa fa-forward" aria-hidden="true" />
      }
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: false,
  };
  const extraButtons = [
    {
      className: "btn extrabtn",

      children: [
        <div class="dropdown">
          <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {type ? type : "ALL"}
          </button>
          <div class="dropdown-menu scroll" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" href="#" onClick={() => { Currency('all');setType('ALL')}}>ALL</a>
            {pairList && pairList.length > 0 && pairList.map((val) => {
              // console.log("pairList_map", pairList)
              return (
                <>
                  <a class="dropdown-item" onClick={() => { Currency(val.tiker_root);setType(val.tiker_root)}}>{val.tiker_root}</a>
                </>
              )
            }
            )}
            
            {/* <a class="dropdown-item" href="#" onClick={() => { Currency('all'); setType('All') }}>ALL</a>
            <a class="dropdown-item" href="#" onClick={() => { Currency('BTCUSD'); setType('BTCUSD') }}>BTCUSD</a>
            <a class="dropdown-item" href="#" onClick={() => { Currency('BTCINR'); setType('BTCINR') }}>BTCINR</a> */}
          </div>
        </div>
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
  ]
  useEffect(() => {
    if (userSetting && priceConversion && priceConversion.length > 0 && walletData && walletData.length > 0 && currencyData && currencyData.length > 0) {

      let estBal = 0;
      walletData.map(item => {
        let currency = currencyData.find(el => el.coin == item.coin && el.type == 'fiat')
        if (currency) {
          if (item.coin == userSetting.currencySymbol) {
            estBal = estBal + item.derivativeBal

          } else {
            let CNVPriceData = priceConversion.find(el => el.baseSymbol == reqData.firstCurrencySymbol && el.convertSymbol == item.coin)
            console.log("itemssssssssssss", item.derivativeBal, item.coin, CNVPriceData)

            if (CNVPriceData) {

              let bal = (item.derivativeBal / CNVPriceData.convertPrice)
              let CNVPrice = priceConversion.find(el => el.baseSymbol == reqData.firstCurrencySymbol && el.convertSymbol == userSetting.currencySymbol)
              if (CNVPrice) {
                estBal = estBal + (bal * CNVPrice.convertPrice)
              }
            }
          }
        } else {
          let CNVPriceData = priceConversion.find(el => el.baseSymbol == item.coin && el.convertSymbol == userSetting.currencySymbol)
          if (CNVPriceData) {
            estBal = estBal + (item.derivativeBal * CNVPriceData.convertPrice)
          }
        }
      })
      setEstBal(estBal)

      console.log("estBalestBalestBal", estBal)

      console.log("proiceconversionssssssssssssssssssssssss", priceConversion)
      priceConversion.find(el => {
      }
      )
      let firPriceCNV = priceConversion.find(el => el.baseSymbol == reqData.firstCurrencySymbol && el.convertSymbol == userSetting.currencySymbol)
      if (firPriceCNV) {
        setTotalBals((estBal / firPriceCNV.convertPrice))
        setEstLoader(false)
        setBalLoader(false)
      }
    }
  }, [userSetting, priceConversion, walletData, currencyData])
  return (
    <div className='myaccount'>
      <div className='container custom-container1'>
        <div className="headsec my-3">
          <div >
            <h5 className="d-none">Your Trading Accounts</h5>
          </div>
          <div className="referralcodecopy">
            <div > <label htmlFor="minimum">Referral code:</label></div>
            <div className="inputcopy"> <input name="minimumAmount" value={referralCode} type="text" className="form-control" onChange={(e) => handleChanges(e)} /><span><CopyToClipboard text={referralCode} onCopy={() => copyCode(referralCode)}><div><button class="fa fa-copy copybtn"> </button></div></CopyToClipboard></span>  </div>

          </div>
        </div>
        <div className='row topsec mt-3'>

          <div className="col-md-6 col-xl-3 mb-4">
            <div className="card text-white">
              <div className="card-body balancecard">
                <h5 className="card-title dashtitle text-uppercase">Your Balance</h5>
                <div className="new_balance_section p-0" style={{ lineHeight: 1.5 }}>

                  {
                    // console.log("balLoaderbalLoader", balLoader, toFixed(totalBals, 4))
                  }
                  {/* {balLoader && <i class="fas fa-spinner fa-spin"></i>}
                    {!balLoader && <Fragment>{toFixed(totalBals, 4)}<span className="mx-0 pl-1">{reqData.firstCurrencySymbol}</span></Fragment>} */}
                  {toLocaleString(parseFloat(useraccount?.balance?.toFixed(2)))}  USD
                  {/* {walletData.map((val)=>{if(val.coin=='USD'){return val.derivativeBal}})}   {walletData.map((val)=>{if(val.coin=='USD'){return val.coin}})} */}
                </div>

              </div>

              <div className="text-right mr-2 pb-2" onClick={() => setRedirected("fundaccount")}>
                <Link to="#" className="btn btn-light" >Deposit</Link>
              </div>

            </div>
          </div>
          <div className="col-md-6 col-xl-3 mb-4" >
            <div class="" data-tip data-for='delete-btn01'>
              <div className="card text-white">
                <div className="card-body">
                  <h5 className="card-title dashtitle text-uppercase">Your Equity</h5>
                  <p className="card-text mb-3">{Equtitypre ? toLocaleString(parseFloat(Equtitypre.toFixed(2))) : 0}</p>
                  <div className='text-right' onClick={() => setRedirected('trade')}>
                    <Link to="#" >View your trading history</Link>

                    <div className="balance_details_left">
                    </div>
                  </div>
                </div>
              </div>
              <ReactTooltip id='delete-btn01' place='bottom'>
                Equity = Balance - Unrealized Net Loss + Unrealized Net Profit + Bonus
              </ReactTooltip>          
            </div>
          </div>


          <div className="col-md-6 col-xl-3 mb-4">        
            <div class="" data-tip data-for='delete-btn02'>
              <div className="card text-white">
                <div className="card-body">
                  <Carousel onSelect={handleSelect}>
                    <Carousel.Item>

                      <h5 className="card-title dashtitle text-uppercase">Margin Used</h5>
                      <p className="card-text mb-3">{Margin? toLocaleString(parseFloat(Margin.toFixed(2))) : "0"}</p>

                    </Carousel.Item>
                    <Carousel.Item>

                      <h5 className="card-title dashtitle text-uppercase">Free Margin</h5>
                      <p className="card-text mb-3">{freemagins ? toLocaleString(parseFloat(freemagins.toFixed(2))) : "0"}</p>

                    </Carousel.Item>
                    <Carousel.Item>

                      <h5 className="card-title dashtitle text-uppercase">Margin Level</h5>
                      <p className="card-text mb-3">{marginlevels ? toLocaleString(parseFloat(marginlevels.toFixed(2))) : "0"}%</p>

                    </Carousel.Item>
                  </Carousel>
                </div>             
              </div>
              <ReactTooltip id='delete-btn02' place='bottom'>
              {Cindex === 0 ? <span>Margin Used : This is based on the positions open,<br />if no position open shall be 0</span> :
                    Cindex === 1 ? <span>Free Margin : Equity - Margin Used,<br />if no position open the value shall be 0</span> :
                      Cindex === 2 ? <span>Margin Level : Equity/Margin Used,<br />if no position open the value shall be 0</span> : ''
                  }
              </ReactTooltip>    
            </div>
          </div>

          <div className="col-md-6 col-xl-3 mb-4">
            <div className="card text-white">
              <div className="card-body">
                <h5 className="card-title dashtitle text-uppercase">Account Status  </h5>
                <tr><p>{status ? "Active" : "Deactive"}</p></tr>
                {/* <div>
                  <ProgressBar now={60} />
                </div> */}
                {/* <div className="progressimgsec pt-3" >
                  <img src={Hammericon} alt="" className="img-fluid" width="20" height="20" />
                  <img src={Tickicon} alt="" className="img-fluid" width="20" height="20" />
                  <img src={Copyicon} alt="" className="img-fluid" width="20" height="20" />
                  <img src={Adminicon} alt="" className="img-fluid" width="20" height="20" />
                </div> */}
              </div>

            </div>
          </div>
        </div>

        <h5>Account Trading Activity</h5>
        <div className='row my-3 px-3 px-md-0'>
          <div className="table-responsive">

            <ReactDatatable
              config={config}
              records={data}
              columns={columns}
              dynamic={false}
              total_record={count}
              onChange={handlePagination}
              extraButtons={extraButtons}
            />

          </div>
        </div>
      </div>

    </div>
  );

}

export default Myaccount;
