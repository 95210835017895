// import lib
import isEmpty from '../../lib/isEmpty';

const validation = value => {
    console.log('value-------->',value)
    let errors = {},
        passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{6,18}(?!\S)/;
        let imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG|pdf|PDF)$/;

        // if (value.frontImage && value.frontImage.size) {
        //     if (value.frontImage.size > 10000000) {   // 10 MB
        //         errors.frontImage = "TOO_LARGE"
        //     } else if (!imageFormat.test(value.frontImage.name)) {
        //         errors.frontImage = "INVALID_IMAGE"
        //     }
        // } else {
        //     errors.frontImage = "REQUIRED";
        // }
        
    if (isEmpty(value.oldPassword)) {
        errors.oldPassword = "REQUIRED"
    } 
    // if(isEmpty(value.oldpassword)){
    //     errors.oldpassword = "REQUIRED"
    // }
    // else if (value.oldPassword.length > 18) {
    //     errors.oldPassword = "PASSWORD_MIN_MAX"
    // } else if (value.oldPassword.length < 6) {
    //     errors.oldPassword = "PASSWORD_MIN_MAX"
    // } else if (!(passwordRegex.test(value.oldPassword))) {
    //     errors.oldPassword = "REGEX_PASSWORD"
    // }
    // console.log('console.log()',isEmpty(value.newpassword))
    // if (isEmpty(value.newpassword)) {
    //     errors.newpassword = "REQUIRED"
    // } else if (value.newpassword.length > 18) {
    //     errors.newpassword = "PASSWORD_MIN_MAX"
    // } else if (value.newpassword.length < 6) {
    //     errors.newpassword = "PASSWORD_MIN_MAX"
    // } else if (!(passwordRegex.test(value.newpassword))) {
    //     errors.newpassword = "REGEX_PASSWORD"
    // }

    if (isEmpty(value.password)) {
        errors.password = "REQUIRED"
    } else if (value.password.length > 18) {
        errors.password = "PASSWORD_MIN_MAX"
    } else if (value.password.length < 6) {
        errors.password = "PASSWORD_MIN_MAX"
    } else if (!(passwordRegex.test(value.password))) {
        errors.password = "REGEX_PASSWORD"
    }


    //retype or confirm password
    // if (isEmpty(value.retypepassword)) {
    //     errors.retypepassword = "REQUIRED"
    // } else if (value.retypepassword.length > 18) {
    //     errors.retypepassword = "PASSWORD_MIN_MAX"
    // } else if (value.retypepassword.length < 6) {
    //     errors.retypepassword= "PASSWORD_MIN_MAX"
    // } else if (!(isEmpty(value.retypepassword)) && value.password != value.retypepassword) {
    //     errors.retypepassword = "CONFIRM_PASSWORD_MISMATCH"
    // }
    // else if (!(passwordRegex.test(value.confirmPassword))) {
    //     errors.retypepassword = "REGEX_PASSWORD"
    // }

    
    if (isEmpty(value.confirmPassword)) {
        errors.confirmPassword = "REQUIRED"
    } else if (value.confirmPassword.length > 18) {
        errors.confirmPassword = "PASSWORD_MIN_MAX"
    } else if (value.confirmPassword.length < 6) {
        errors.confirmPassword = "PASSWORD_MIN_MAX"
    } else if (!(isEmpty(value.confirmPassword)) && value.password != value.confirmPassword) {
        errors.confirmPassword = "CONFIRM_PASSWORD_MISMATCH"
    }
    else if (!(passwordRegex.test(value.confirmPassword))) {
        errors.confirmPassword = "REGEX_PASSWORD"
    }

    return errors;
}

export default validation;