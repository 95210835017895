import React, { Component, useState, useEffect, useContext } from 'react';
import { NavLink, Router, Link } from 'react-router-dom';
import '../../assets/css/Header.css'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "../../assets/css/Home.css"
import { Col, Nav, Row, Tab, Tabs, Table, Badge } from 'react-bootstrap';
import SocketContext from '../../components/Context/SocketContext';
import { useDispatch, useSelector } from "react-redux";
import { BidandAskDisplay } from 'lib/bybit';

function Landing(props) {
    // console.log("props---------------------------", props);
    const [Markupprice, setMarketPrice] = useState()
    const pairList = useSelector(state => state.pairList);
    const [marketclose, setMarketclose] = useState({})

    let data = props.data
    // console.log("prpos-----cfsdgs--ZAcvsdvsdvjn------", data);
    let spread = data?.spread
    // console.log("spread_spread", spread);
    const socketContext = useContext(SocketContext)


    useEffect(() => {

        socketContext.socket.on('perpetualmarketprice', (result) => {
            // console.log('perpetualmarketprice', result.type)
            try {
                if (props.data.tiker_root == result.tiker_root) {
                    let pairDetail = pairList.find((value) => (value.tiker_root === result.tiker_root))
                    // console.log("pairDetail_pairDetail_pairDetail",pairDetail);
                    setMarketclose(pairDetail)
                    setMarketPrice(result)
                }
            } catch (err) {
                console.log(err, 'perpetualmarketprice__err')
            }
        })

    }, [SocketContext.Socket])

    return (
        <tr>
            <td><p className='cardhead'> {data.tiker_root} </p></td>
            <td>
                <Badge className='red_badges' bg="secondary">
                    {Markupprice && Markupprice.bid ? Markupprice?.disbid : data?.bid}
                    <span className='text-danger lastnumber'>
                        {Markupprice && Markupprice?.bid ? Markupprice?.pipBid : BidandAskDisplay(props?.data?.bid, props.data.precision)}
                    </span>
                </Badge>
            </td>
            <td>
                <Badge className='green_badges' bg="secondary">
                    {Markupprice && Markupprice.ask ? Markupprice?.disask : data?.ask}
                    <span className='text lastnumber'>
                        {Markupprice && Markupprice?.ask ? Markupprice?.pipAsk: BidandAskDisplay(props?.data?.ask, props.data.precision)}
                    </span>
                </Badge>
            </td>
            <td>{Markupprice && Markupprice?.spread ? Markupprice?.spread : data?.spread}</td>
            <td className='text_red_change'>{Markupprice && Markupprice.change ? Markupprice?.change : data?.change}</td>
        </tr>
    )

}
export default Landing