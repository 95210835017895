import React, { useState, useEffect } from "react";
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { setBankForm, setPrimaryBank, deleteBankDetail } from '../actions/users';

// import lib
import { toastAlert } from '../lib/toastAlert';

import clsx from 'classnames';



import ProgressBar from 'react-bootstrap/ProgressBar';
import Form from 'react-bootstrap/Form';

import { kycStatus, userStatus } from '../lib/displayStatus'

import { kycStatusupload } from '../lib/displayStatus'

import Upload from "components/SportTrade/newtrade/Modals/Upload";

import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"


import ListBankDetail from '../components/BankAccount/ListBankDetail';
import EditBankDetail from '../components/BankAccount/EditBankDetail';
import ViewBankDetail from '../components/BankAccount/ViewBankDetail';




const Bankdetails = (props) => {

  const [upload, setUpload] = useState(false)
  const [count, setcount] = useState(0);
  // const [load,setload]=useState("passport")
  const { t, i18n } = useTranslation();

  // const {Aadhar,VoterId,passport}= load

  // redux-state
  const { idProof } = useSelector(state => state.userKyc);


  const dispatch = useDispatch();

  // redux-state
  const { result } = useSelector(state => state.bankDetail);

  const type = localStorage.getItem("liveorDemo")
  // function
  const handlePrimaryBank = async (item) => {
    try {
      let reqData = {
        'bankId': item._id
      }
      const { status, loading, message } = await setPrimaryBank(reqData, dispatch);
      if (status == 'success') {
        toastAlert('success', t(message), 'viewBank')
      } else {
        toastAlert('error', t(message), 'viewBank')
      }
    } catch (err) { }
  }

  const handleDelete = async (item) => {
    try {
      let reqData = {
        'bankId': item._id
      }
      const { status, loading, message } = await deleteBankDetail(reqData, dispatch);
      if (status == 'success') {
        toastAlert('success', t(message), 'deleteBank')
      } else {
        toastAlert('error', t(message), 'deleteBank')
      }
    } catch (err) { }
  }



  console.log("idProof", idProof);




  return (
    <div className='uploaddocument'>
      {upload && <Upload onDismiss={() => setUpload(false)} />}
      <div className='container custom-container1'>

        <div className="card text-white">


          <div className="card-body">
            <h5 className="mb-0">My Bank Accounts</h5>
            <div className="row">
              <div className="col-md-12 mb-3">

                <div className='row my-3 px-3 px-md-0'>
                  <ViewBankDetail />
                </div>
              </div>
            </div>




          </div>

        </div>

        <div className="card text-white mt-5-card">


          <div className="card-body">
            <h5 className="mb-0">Bank</h5>
            <ListBankDetail />
            <EditBankDetail />

          </div>
        </div>



      </div>

    </div>
  );

}

export default Bankdetails;