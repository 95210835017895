import React, { useState, useEffect } from "react";
// import { useSelector ,useDispatch} from 'react-redux'
import { Link ,useParams} from "react-router-dom";
import ProgressBar from 'react-bootstrap/ProgressBar';
import ReactDatatable from '@ashvin27/react-datatable';
import { useTranslation } from 'react-i18next';
import CopyToClipboard from "react-copy-to-clipboard";
import { toastAlert } from "lib/toastAlert";
import Basicdetail from './Basicdetail';
import Changepwd from './changepwd';
import Uploaddocument from './Uploaddocument'
import Bankdetails from './Bankdetails'
// import toast, { Toaster } from 'react-hot-toast';
// import { toastAlert } from "lib/toastAlert";
import upiimage from "../assets/images/upi.png"
import profileimg from "../assets/images/prifileimg.png"
import isEmpty from "lib/isEmpty";
import { useSelector,useDispatch } from "react-redux";
import config from "../config";
import validation from "components/NewsSubscribe/validation";
import  {getkycDetails,getverifystatus}  from "../actions/userKyc";
const initialFormValue = {
    'frontImage': '',
}
const Accountsetting = () => {
    const { t, i18n } = useTranslation();
    const params = useParams()
    //state
    const { referralCode } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [formValue, setFormValue] = useState(initialFormValue)
    // console.log('formValuie<-----', formValue);
    const [validationError, setValidateError] = useState({})
    console.log('validationerrrrrrrrrrrrrrrrrr', validationError)
    const [copy, setCopy] = useState('');

    //redux-state
    const accountData = useSelector(state => state.account)
    const userId = useSelector(state => state.auth)
    // console.log('accountdataaaaaaaaaaa', accountData)
    const { Image } = accountData
    // console.log('Image kiki', Image)

    //function
    const handleFile = async (e) => {
        const { name, files } = e.target;

        console.log('filsesssssssssssss', files[0], files[0].type)
        if (files[0].type !== "image/png" && files[0].type !== "image/jpg" && files[0].type !== "image/jpeg") {
            console.log("Testing",files[0].type )
            return  toastAlert('error',"Only jpg, png, jpeg image formats are supported", 'Profile')
        }
        let formData = { ...formValue, ...{ [name]: files[0] } }
        setFormValue(formData)
        if (!isEmpty(validationError)) {
            setValidateError({})
        }
    }

    var ImageError = (val) => {
        setValidateError(val)
    }

    const copyCode = (data) => {
        if (data) {
            toastAlert('success', 'Referral Code copied')
        }
        else {
            toastAlert('error', 'no respond can found')
        }
    }
    const handleChanges = async (e) => {
        console.log('etarget', e.target.value)
        setCopy(e.target.value);
    }

    useEffect(() => {
        
        if (Image) {
            console.log('Images enter', Image)
            let formDatas = { ...formValue, ...{ 'frontImages': Image } }
            setFormValue(formDatas)
        }
        
    }, [accountData?.Image])
    useEffect(()=>{
        getStatus()
        if(params.id==='entity'||params.id==='individual'){
            document.getElementById("v-pills-upload-tab").click()
          }
          else if(params.id === "settings"){
            document.getElementById("v-pills-tab").click()
          }
    },[params])
    const getStatus = async () => {
        await getverifystatus(dispatch);
    }
    return (

        <div className='accountsetting'>
            <div className='container custom-container1'>
                <div className="headsec">
                    <div >
                        <h5>Your Trading Activity</h5>
                    </div>
                    <div className="referralcodecopy">
                        <div > <label htmlFor="minimum">Referral code:</label></div>
                        <div className="inputcopy"> <input name="minimumAmount" value={referralCode} type="text" className="form-control" onChange={(e) => handleChanges(e)} /><span><CopyToClipboard text={referralCode} onCopy={() => copyCode(referralCode)}><div><button class="fa fa-copy copybtn"> </button></div></CopyToClipboard></span>  </div>

                    </div>
                </div>
                <div className='row dis_flex mt-4 pr-3 px-md-0'>
                    <div className="col-lg-3 mb-4 leftcard pr-0">
                        <div className="card text-white">


                            <div className="card-body">
                                <div className="row profilesec mt-3">
                                    <div className="imagesec imgfile">

                                        {(formValue.frontImage === '') ?
                                            ((Image && Image) ?
                                                (<img className="profileimg" src={`${config.API_URL}/images/kyc/${Image}`} />) :
                                                <img className="profileimg" src={profileimg} />) : (<img className="profileimg" src={window.URL.createObjectURL(formValue?.frontImage)} />)
                                        }
                                        <input type="file" name="frontImage" onChange={handleFile} />
                                        <div className="editarrow">
                                            <i class='fas fa-pencil-alt'></i>
                                        </div>
                                        {
                                            validationError.frontImage && <p className="error-message">{t(validationError.frontImage)}</p>
                                        }

                                    </div>
                                </div>

                                {/* <p className="text-center mt-2">{accountData.firstName ? accountData.firstName :"" }</p> */}
                                <div className="leftcardoption mt-3">
                                    <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                        <a class="nav-link active" id="v-pills-details-tab" data-toggle="pill" href="#v-pills-details" role="tab" aria-controls="v-pills-details" aria-selected="true">
                                            <div className="row px-3">
                                                <div >
                                                    <p className="labelname">Basic Details</p>
                                                </div>
                                                <div >
                                                    <i class="fa fa-chevron-right"></i>
                                                </div>
                                            </div>
                                        </a>
                                        <a class="nav-link" id="v-pills-password-tab" data-toggle="pill" href="#v-pills-password" role="tab" aria-controls="v-pills-password" aria-selected="false">
                                            <div className="row px-3">
                                                <div >
                                                    <p className="labelname">Change Password</p>
                                                </div>
                                                <div >
                                                    <i class="fa fa-chevron-right"></i>
                                                </div>
                                            </div>
                                        </a>
                                        <a class="nav-link" id="v-pills-upload-tab" data-toggle="pill" href="#v-pills-upload" role="tab" aria-controls="v-pills-upload" aria-selected="false">
                                            <div className="row px-3">
                                                <div >
                                                    <p className="labelname">KYC</p>
                                                </div>
                                                <div >
                                                    <i class="fa fa-chevron-right"></i>
                                                </div>
                                            </div>
                                        </a>

                                        <a class="nav-link" id="v-pills-bank-tab" data-toggle="pill" href="#v-pills-bank" role="tab" aria-controls="v-pills-upload" aria-selected="false">
                                            <div className="row px-3">
                                                <div >
                                                    <p className="labelname">Bank Details</p>
                                                </div>
                                                <div >
                                                    <i class="fa fa-chevron-right"></i>
                                                </div>
                                            </div>
                                        </a>

                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                    <div className="col-lg-8 custom-col-lg-8 custom-col-md-4 rightcard mb-4 right_sd_prof_align pr-0">

                        <div class="tab-content" id="v-pills-tabContent">
                            <div class="tab-pane fade show active" id="v-pills-details" role="tabpanel" aria-labelledby="v-pills-details-tab"><Basicdetail props={formValue} setValidateErrors={ImageError} /></div>
                            <div class="tab-pane fade" id="v-pills-password" role="tabpanel" aria-labelledby="v-pills-password-tab"><Changepwd /></div>
                            <div class="tab-pane fade" id="v-pills-upload" role="tabpanel" aria-labelledby="v-pills-upload-tab"><Uploaddocument /></div>
                            <div class="tab-pane fade" id="v-pills-bank" role="tabpanel" aria-labelledby="v-pills-bank-tab">
                                <Bankdetails />
                            </div>

                        </div>

                    </div>


                </div>



            </div>

        </div>
    );

}

export default Accountsetting;