import React, { Component } from 'react';

const Signup = () => {

    return (
      <div className='signinpage'>
        <div className='logosec pt-5'>
         <img src={require("../assets/images/logo.png")} alt="Banner" className="img-fluid logo_200" />
         </div>
        <div className='row'>

<div class='card mx-auto d-flex flex-row px-0'>
  <div className='row max_size'>
    <div className='col-12 col-md-6'>
  
<div class="img-left d-md-flex">
  <h3 className='title'>Simple,Free<br/>
  Investing.</h3>
  <div className='overline'>
  <p className='bottom title'>SGBs</p>
  </div>
</div>
</div>
<div className='col-12 col-md-6'>
  <div class="card-body d-flex flex-column justify-content-center">
    <h3 class="title text-center mt-4 pb-3">JoinTrading One</h3>
<form class='col-12 mx-auto'>
   <div className='row mt-2 labelname'>
   <div className='col-12'>
     <label for="email">Your Email Address</label>
     </div>
     <div className='col-12'>
   <div className="input-group mb-3">
    
    

  <input type="email" class="form-control" placeholder="Your Email Address" aria-label="Your Email Address" aria-describedby="basic-addon2"/>
      
     
  <span class="input-group-text" id="basic-addon2"><i class="fas fa-pencil-alt"></i></span>
  </div>
</div>

   </div>
   <div className='row mt-2'>
   <div className='col-12 labelname'>
     <label for="email">Set Password</label>
     </div>
     <div className='col-12'>
   <div className="input-group mb-3">
    
    

  <input type="password" class="form-control" placeholder="Set Password" aria-label="Set Password" aria-describedby="basic-addon2"/>
      
     
  <span class="input-group-text" id="basic-addon2"><i class="fa fa-eye" aria-hidden="true"></i></span>
  </div>
</div>

   </div>
   <div className='row mt-2 labelname'>
   <div className='col-12'>
     <label for="email">Referral Code</label>
     </div>
     <div className='col-12'>
   <div className="input-group mb-3">
    
    

  <input type="text" class="form-control" placeholder="Referral Code" aria-label="Referral Code" aria-describedby="basic-addon2"/>
      
     
  {/* <span class="input-group-text" id="basic-addon2">@example.com</span> */}

  </div>
</div>

   </div>
   <div className='mt-3 text-center'>
   <div class="badge badge-light my-3">Minimum 8 alphanumeric characters required</div>
   <button
                                    className="btn btn-primary text-uppercase py-2 m-0"
                                    type="button"
                                    
                                >
                                 Continue
                                </button>
                                </div>
</form>
    </div>
    </div>

</div>
</div>

</div>

        
      </div>
    );
  }

export default Signup;