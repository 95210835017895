// import package
import React, { useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SocketContext from '../Context/SocketContext';
import { useHistory } from 'react-router-dom';

// import action
import { getBankDetail, getUserSetting, viewUserProfile ,logout} from '../../actions/users';
import { getPriceConversion, getANNC, getCurrency, getMedia } from '../../actions/commonAction';
import { getAssetData } from '../../actions/walletAction';
import { unReadNotice, FetchunReadNotice } from '../../actions/notificationAction';

import { getPairList ,setPairList} from "../../actions/spotTradeAction";
import {getstocksymbolaction,setCategory} from '../../actions/currencycategoryAction'
import isEmpty from 'lib/isEmpty';
import { getverifystatus } from 'actions/userKyc';
const HelperRoute = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const socketContext = useContext(SocketContext);
    // redux-state
    const { isAuth } = useSelector(state => state.auth);
    const currencyOption = useSelector(state => state.currency)
    const type=localStorage.getItem("liveorDemo")
    // console.log("bgbbbbbbg",type);
    // function
    window.addEventListener('click', (event) => {
        localStorage.setItem("ActiveTime", new Date().getTime())
    })
    useEffect(() => {
        if (isAuth) {
            getverifystatus(dispatch)
            let oldintervalId = localStorage.getItem('ActiveCheck')
            if (oldintervalId) {
                clearInterval(oldintervalId);
                localStorage.removeItem('ActiveCheck')
            }
            const intervalId = setInterval(async () => {
                let lastactivetime = localStorage.getItem("ActiveTime")
                let activeTime = new Date().getTime()
                let CheckTime = (parseFloat(activeTime) - parseFloat(lastactivetime)) / 1000 / 60
                let token = localStorage.getItem('user_token');
                // console.log(token, 'user_token', CheckTime, lastactivetime, activeTime)
                if (!isEmpty(token) && CheckTime > 30) {
                    let oldintervalId = localStorage.getItem('getProfitCalculation')
                    if (oldintervalId) {
                        clearInterval(oldintervalId);
                    }
                    let oldintervalId2 = localStorage.getItem('ActiveCheck')
                    if (oldintervalId2) {
                        clearInterval(oldintervalId2);
                    }
                    logout(history, dispatch)
                }
            }, 2000)
            localStorage.setItem('ActiveCheck', intervalId)
            // setTimeout(() => {
            //     let lastactivetime = localStorage.getItem("ActiveTime")
            //     let activeTime = new Date().getTime()
            //     let CheckTime = (parseFloat(activeTime) - parseFloat(lastactivetime)) /1000 / 60
            //     let token = localStorage.getItem('user_token');
            //     console.log(token, 'user_token',CheckTime,lastactivetime,activeTime)
            //     if (!isEmpty(token) && CheckTime > 10) {
            //         logout(history, dispatch)
            //     }
            // }, [60000])
            // 1800000 //30min
            getUserSetting(dispatch)
            getBankDetail(dispatch)
            getAssetData(dispatch)
            viewUserProfile(dispatch)
            getPriceConversion(dispatch)
            getANNC(dispatch)
            unReadNotice(dispatch)
        }
        fetchData()
    }, [isAuth])
    
    const fetchData =async()=>{
        try{
            let {result,status}=await getPairList()
            if(status=='success'){
                await setPairList(result, dispatch)
            }
            let  categoryData =await getstocksymbolaction()
            if(categoryData.success=='success'){
                await setCategory(categoryData.result, dispatch)
            }
        }catch(err){
            console.log(err,'fetchData__err')
        }
    }
    useEffect(() => {
        getMedia(dispatch)
        if (!(currencyOption && currencyOption.length > 0)) {
            getCurrency(dispatch);
        }

        socketContext.socket.on('notice', (result) => {
            FetchunReadNotice(dispatch, result)
        })

        socketContext.socket.on('FORCE_LOGOUT', (result) => {
            let token = localStorage.getItem('user_token');
            if(result && token != result){
                let oldintervalId = localStorage.getItem('getProfitCalculation')
                if (oldintervalId) {
                    clearInterval(oldintervalId);
                }
                let oldintervalId2 = localStorage.getItem('ActiveCheck')
                if (oldintervalId2) {
                    clearInterval(oldintervalId2);
                }
                logout(history, dispatch)
            }
        })
    }, [])

    return <div />
}

export default HelperRoute;