import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ProgressBar from 'react-bootstrap/ProgressBar';
import ReactDatatable from '@ashvin27/react-datatable';
import { useDispatch, useSelector } from 'react-redux'
import Pagination from "../components/Dashboard/pagination";
// import action
import { gettradeHistory } from "../actions/dashboardAction";
import CopyToClipboard from "react-copy-to-clipboard";
// import lib
import { dateTimeFormat } from "../lib/dateTimeHelper";

// import action
import { histrade } from "../actions/perpetualTradeAction";

import { momentFormat } from '../lib/dateTimeHelper'

import { toastAlert } from "lib/toastAlert";
import { lotDisplay } from '../lib/roundOf'

const Accounttrade = () => {
  const { referralCode } = useSelector(state => state.auth);
  const accountData = useSelector(state => state.account);
  const { status, useraccount } = accountData
  // console.log("accountdata----->",useraccount);
  const [data, setData] = useState([]);
  const [count, setcount] = useState(0);
  const [loader, setLoader] = useState(true);
  const [copy, setCopy] = useState('');

  const [type, setType] = useState()

  const pairList = useSelector(state => state.pairList);
  // console.log("pairList_pairlist",pairList)

  const [reportData, setreportData] = useState([]);
  const [filter, setFilter] = useState({
    'page': 1,
    'limit': 5,
    'AccountsId': useraccount && useraccount?.accountId,
    'type': "all"
  })
  console.log("useraccount && useraccount?.accountId---?//", useraccount && useraccount?.accountId);
  const types = localStorage.getItem("liveorDemo")
  function handlePagination(index) {
    let filterData = { ...filter, ...{ 'page': index.page_number, 'limit': index.page_size } }
    console.log("filterData----------------->", filter);

    setFilter(filterData);
    fetchTransaction(filterData);
  }
  const copyCode = (data) => {
    if (data) {
      toastAlert('success', 'Referral Code copied')
    }
    else {
      toastAlert('error', 'no respond can found')
    }




  }
  const fetchTransaction = async () => {
    try {
      // console.log("filter----ccdd",filter
      // );
      let Data = { ...filter, ...{ 'AccountsId': useraccount && useraccount?.accountId } }
      // console.log("Data--------dsds--",Data);
      const { status, loading, result, count, reportData } = await histrade(Data, types);
      // console.log('result------------>',result)
      setLoader(loading);
      if (status == "success") {
        setData(result);
        // setreportData(reportData);
        // setcount(count)

        // const headers = [
        //   [
        //     "Date",
        //     "Transaction Id",
        //     "Trade Type",
        //     "Contract",
        //     "Price",
        //     "Quantity",
        //     "Order Value",
        //   ],
        // ];

        // const download_data =
        //   reportData &&
        //   reportData.length > 0 &&
        //   reportData.map((elt) => [
        //     dateTimeFormat(elt.createdAt, "YYYY-MM-DD HH:mm"),
        //     elt._id,
        //     elt.orderType == "limit" ? "Limit" : "Market",
        //     elt.firstCurrency + elt.secondCurrency,
        //     elt.price,
        //     elt.quantity,
        //     elt.orderValue && elt.orderValue.toFixed(4),
        //   ]);
        // let download_csv = headers.concat(download_data);

        // if (reportData &&
        //   reportData.length > 0) {
        //   setDownloadData(download_csv);

        // }
      }
    } catch (err) { }
  };


  const currencys = async (data) => {
    let filters = { ...filter, ...{ 'type': data } }
    console.log("currency--------?", data);
    setFilter(filters)
  }

  const handleChanges = async (e) => {
    setCopy(e.target.value);
  }

  useEffect(() => {
    fetchTransaction();
  }, [filter, useraccount]);
  const columns = [
    {
      key: "orderDate",
      text: "Date",
      className: "Date",
      align: "center",
      sortable: true,
      cell: record => {
        return momentFormat(record.orderDate, 'DD-MM-YYYY HH:mm')
      }
    },
    {
      key: "pairName",
      text: "Pair",
      className: "center",
      align: "center",
      sortable: true
    },
    {
      key: "buyorsell",
      text: "Side",
      className: "Side",
      align: "center",
      sortable: true
    },
    {
      key: "quantity",
      text: "Quantity",
      className: "Filled",
      align: "center",
      sortable: true,
      cell: record => {
        let pairDetail = pairList.find((val) => (val.tiker_root == record.pairName))
        return lotDisplay(record?.quantity, pairDetail, record.price).toFixed(2)
      }
    },
    {
      key: "price",
      text: "Price",
      className: "openprice",
      align: "center",
      sortable: true
    },
    {
      key: "takerFee",
      text: "Fee",
      className: "Fee",
      sortable: true,
      align: "center",
      cell: record => {
        return record?.takerFee
      }
    },
    {
      key: "orderValue",
      text: "Contract Value (USD)",
      className: "Total",
      sortable: true,
      align: "center",
      cell: record => {

        return (record?.orderValue).toFixed(2)
      }
    },
    // {
    //   key: "stoploss",
    //   text: "Stop Loss",
    //   className: "stoploss",
    //   sortable: true,
    //   align: "center"
    // }, {
    // key: "takeprofit",
    // text: "Take Profit",
    // className: "takeprofit",
    // sortable: true,
    // align: "center"
    // },


  ];
  const config = {
    page_size: 5,
    length_menu: [5, 10, 20, 50],
    filename: "Order",
    no_data_text: 'No Records found!',
    button: {
      extra: true,
    },
    language: {
      length_menu: "Show _MENU_ Entries",
      filter: "Search",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: <i class="fa fa-backward" aria-hidden="true" />,
        previous: <i class="fa fa-chevron-left" />,
        next: <i class="fa fa-chevron-right" />,
        last: <i class="fa fa-forward" aria-hidden="true" />
      }
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: false,
  };
  const extraButtons = [
    {
      className: "btn extrabtn",
      title: "Export Pairs",
      children: [
        <div class="dropdown">
          <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
          >
            {type ? type : 'All'}
          </button>
          
          <div class="dropdown-menu scroll" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" href="#"onClick={()=>{currencys('all'); setType("ALL")}}>ALL</a>
          {pairList && pairList.length > 0 && pairList.map((val) => {
              // console.log("pairList_map", pairList)
              return (
                <>
                  <a class="dropdown-item" onClick={() => { currencys(val.tiker_root); setType(val.tiker_root) }}>{val.tiker_root}</a>
                </>

              )
            }
            )}
            {/* <a class="dropdown-item" href="#"onClick={()=>{currencys('all'); setType("ALL")}}>ALL</a>
            <a class="dropdown-item" href="#"onClick={()=>{currencys('BTCUSD');setType("BTCUSD")}}>BTCUSD</a>
            <a class="dropdown-item" href="#"onClick={()=>{currencys('BTCINR');setType("BTCINR")}}>BTCINR</a> */}
          </div>
        </div>
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
  ]


  // const currentdata = [{
  //   tradeid:'12233',
  //   opentime:'20:23',
  //   symbol:'BTC',
  //   type:'Loreum',
  //   openprice:'12,000',
  //   currentprice:'12,000',
  //   volume:'$35356',
  //   stoploss:'Loreum',
  //   takeprofit:'Loreum'
  // },
  // {
  //   tradeid:'12233',
  //   opentime:'20:23',
  //   symbol:'BTC',
  //   type:'Loreum',
  //   openprice:'12,000',
  //   currentprice:'12,000',
  //   volume:'$35356',
  //   stoploss:'Loreum',
  //   takeprofit:'Loreum'
  // },
  // {
  //   tradeid:'12233',
  //   opentime:'20:23',
  //   symbol:'BTC',
  //   type:'Loreum',
  //   openprice:'12,000',
  //   currentprice:'12,000',
  //   volume:'$35356',
  //   stoploss:'Loreum',
  //   takeprofit:'Loreum'
  // },
  // {
  //   tradeid:'12233',
  //   opentime:'20:23',
  //   symbol:'BTC',
  //   type:'Loreum',
  //   openprice:'12,000',
  //   currentprice:'12,000',
  //   volume:'$35356',
  //   stoploss:'Loreum',
  //   takeprofit:'Loreum'
  // },


  // ];


  return (
    <div className='referrallist'>
      <div className='container custom-container1'>
        <div className="headsec my-3">
          <div >
            <h5 >Account Trading Activity</h5>
          </div>
          <div className="referralcodecopy">
            <div > <label htmlFor="minimum">Referral code:</label></div>
            <div className="inputcopy"> <input name="minimumAmount" value={referralCode} type="text" className="form-control" onChange={(e) => handleChanges(e)} /><span><CopyToClipboard text={referralCode} onCopy={() => copyCode(referralCode)}>
              <div><button class="fa fa-copy copybtn"> </button></div></CopyToClipboard></span>  </div>
          </div>
        </div>
        <div className='row my-3 px-3 px-md-0'>
          <div className="table-responsive">
            <ReactDatatable className="table table-bordered table-striped custome_table_responsive"
              config={config}
              records={data}
              columns={columns}
              dynamic={false}
              total_record={count}
              onChange={handlePagination}
              extraButtons={extraButtons}
            />
          </div>
        </div>
      </div>

    </div>
  );

}

export default Accounttrade;