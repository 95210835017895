import React, { useEffect, useState, useContext } from 'react';
import { Modal, Tabs, Dropdown, InputGroup, Form, Tab } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux';
import SocketContext from '../../../Context/SocketContext';
import Wishlisttab from '../elements/Wishlisttab';
import Symboltab from "../elements/Symboltab";
import Infomation from './Information';
import { encryptObject } from '../../../../lib/cryptoJS'
import { orderPlace } from '../../../../actions/perpetualTradeAction';
import { toastAlert } from '../../../../lib/toastAlert';
import isEmpty from "lib/isEmpty";
import { capitalize } from '../../../../lib/stringCase';
import { inverseOrderCost, orderValue, EstMargin, SpreadCal } from '../../../../lib/bybit';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { momentFormat } from 'lib/dateTimeHelper';
import { toLocaleString, lotDisplay } from 'lib/roundOf';
import clsx from "classnames";
const PositionOrderInfo = (props) => {
    // const pairData = useSelector(state => state.tradePair)
    //   console.log(pairData,"paitData----------------->");
    const [infoorder, setInfo] = useState(true)
    const [Margin, setMargin] = useState(0)
    const { pairData, InfoData } = props
    console.log(pairData,"paitData----------------->");
    const socketContext = useContext(SocketContext)
    const pairList = useSelector(state => state.pairList);
    useEffect(() => {
        CalculateMargin()
    }, [InfoData, pairData])
    const CalculateMargin = async () => {
        try {
            let margin = await EstMargin(InfoData.price, InfoData.positionFilled, InfoData.leverage, pairData, InfoData.buyorsell, pairList)
            console.log('CalculateMargin', margin, pairData)
            setMargin(margin.toFixed(2))
        } catch (err) {
            console.log(err, 'CalculateMargin__err')
        }
    }

    return (
        <>
            <Modal show={infoorder} className="modalbg informationmodal position_modal font-12" size="md" centered scrolable>

                <Modal.Header className='align-items-center'>
                    <Modal.Title><h2>{pairData.tiker_root}</h2>
                        <h2>{pairData.description}</h2>
                    </Modal.Title>
                    <button className='closebtn btn btn-link text-light nounder' onClick={() => props.onDismiss()}>
                        <i className="fa fa-times fa-6" aria-hidden="true"></i>
                    </button>

                </Modal.Header>
                <Modal.Body className='font-12'>

                    <div className='row first_row'>
                        <div className='col-3'>
                            <p className='label'>Type</p>
                            <p className='value'>{InfoData.buyorsell == 'buy' ? 'Sell' : 'Buy'}</p>
                        </div>
                        <div className='col-3'>
                            <p className='label'>Volume</p>
                            <p className='value'>{lotDisplay(InfoData.positionFilled, pairData, InfoData?.price).toFixed(2)}</p>
                        </div>
                        <div className='col-3'>
                            <p className='label'>Margin</p>
                            <p className='value'>{InfoData.margin.toFixed(2)}</p>
                        </div>
                        <div className='col-3'>
                            <p className='label'>Net profit</p>
                            <p className={`value ${clsx({ "greenText": InfoData.pAndL >= 0 }, { "redText": InfoData.pAndL < 0 })}`}>{InfoData.pAndL.toFixed(2)}</p>
                            <p className={`value`}>{InfoData.profitnloss.toFixed(2)} Gross</p>
                        </div>
                    </div>
                    <div className='tabrow'>
                        <Tabs
                            defaultActiveKey="Profit/Loss"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey="Profit/Loss" title="Profit/Loss">
                                <div className='row'>
                                    <div className='col-6 inside_row' >
                                        <div className='mb-3'>
                                            <p className='label'>Open Price</p>
                                            <p className='value'>{InfoData.price}</p>
                                        </div>
                                        <div className='mb-3'>
                                            <p className='label'>Commission</p>
                                            <p className='value'>{pairData.commission}</p>
                                        </div>
                                        <div className='mb-3'>
                                            <p className='label'>Swap</p>
                                            <p className='value'>{InfoData.swapFee > 0 ? InfoData.swapFee.toFixed(2) : InfoData.swapFee.toFixed(2)}</p>
                                        </div>
                                    </div>
                                    <div className='col-6 inside_row'>
                                        <div className='mb-3'>
                                            <p className='label'>Market Price</p>
                                            <p className='value'>{InfoData.close_price}</p>
                                        </div>
                                        {/* {pairData.fees && pairData.fees.length > 0 && pairData.fees.map((item, key) => (
                                            <div className='mb-3'>
                                                <p className='label'>{Object.keys(item)}</p>
                                                <p className='value'>{Object.values(item)} %</p>
                                            </div>
                                        ))} */}
                                        <div className='mb-3'>
                                            <p className='label'>Close Commission</p>
                                            <p className='value'>{InfoData.closingFee > 0 ? -InfoData.closingFee.toFixed(2) : InfoData.closingFee.toFixed(2)}</p>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="FeeDetails" title="Fee Details">
                                <div className='row'>
                                    <div className='col-6 inside_row'>
                                        <div className='mb-3'>
                                            <p className='label'>Swap Long</p>
                                            <p className='value'>{pairData && pairData.swapLong}%</p>
                                        </div>
                                        <div className='mb-3'>
                                            <p className='label'>Swap Short</p>
                                            <p className='value'>{pairData && pairData.swapShort}%</p>
                                        </div>

                                    </div>
                                    <div className='col-6 inside_row'>
                                        {pairData.fees && pairData.fees.length > 0 && pairData.fees.map((item, key) => (
                                            <div className='mb-3'>
                                                <p className='label'>{Object.keys(item)}</p>
                                                <p className='value'>{Object.values(item)} Pip</p>
                                            </div>
                                        ))}
                                        {/* <div className='mb-3'>
                                            <p className='label'>Net profit</p>
                                            <p className='value'>-1.06</p>
                                        </div>
                                        <div className='mb-3'>
                                            <p className='label'>Net profit</p>
                                            <p className='value'>-1.06</p>
                                        </div> */}
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>






                    {/* <div>
                    <table className="w-full">
                          <tr>
                            <th><label>Type</label></th>
                            <th><label>Volume</label></th>
                            <th><label>Margin</label></th>
                            <th><label>Net profit</label></th>
                          </tr>
                          <tbody>
                                  <tr>
                                    <td>Buy</td>
                                    <td>0.01</td>
                                    <td>6.68</td>
                                    <td>-0.96</td>
                                  </tr>
                          </tbody>
                        </table>
                    </div> */}
                    {/* <div className='themetab'>
                        <h5 className='mb-0'>{capitalize(Datas.orderType)} Order {Datas._id}  {Datas.pairName}</h5>
                        <h6 className='text_normal'>{pairData.description}</h6>
                        <hr />
                        <div>
                            <p className='mb-2'>Information</p>
                            <input className="form-control themeinput" disabled name="information" type="text" value={`${lot} Lot = ${toLocaleString(Datas.quantity)} , Lot Unit = ${Datas.firstCurrency} `}></input>

                            <hr />
                        </div>
                        <div className="d-flex flex-1 gap-2">
                            <div>
                                <p className="mb-2">Order ID</p>
                                <input className="form-control themeinput" name="orderid" disabled type="text" value={Datas._id} />
                            </div>
                            <div>
                                <p className="mb-2">Status</p>
                                <input className="form-control themeinput" name="status" disabled type="text" value={Datas.status} />
                            </div>
                        </div>
                        <div className='mt-2'>
                            <p className='mb-2'>Requesting Position</p>
                            <input className="form-control themeinput" disabled name="requestingposition" type="text" value="-"></input>

                            <hr />
                        </div>
                        <div className="d-flex flex-1 gap-2">
                            <div>
                                <p className="mb-2">Symbol</p>
                                <input className="form-control themeinput" name="symbol" disabled type="text" value={Datas.pairName ? Datas.pairName : "--"} />
                            </div>
                            <div>
                                <p className="mb-2">Direction</p>
                                <input className="form-control themeinput" name="direction" disabled type="text" value={Datas.buyorsell ? Datas.buyorsell : "--"} />
                            </div>
                        </div>

                        <div className="d-flex flex-1 gap-2 mt-2">
                            <div>
                                <p className="mb-2">Submitted Volume</p>
                                <input className="form-control themeinput" name="submittedvolume" disabled type="text" value={Datas.quantity ? toLocaleString(Datas.quantity) : "--"} />
                            </div>
                            <div>
                                <p className="mb-2">Current Volume</p>
                                <input className="form-control themeinput" name="currentdvolume" disabled type="text" value={Datas.quantity ? toLocaleString(parseFloat(Datas.quantity) - parseFloat(Datas.filledAmount)) : "--"} />
                            </div>
                        </div>

                        <div className="d-flex flex-1 gap-2 mt-2">
                            <div>
                                <p className="mb-2">Filled Volume</p>
                                <input className="form-control themeinput" name="filledvolume" disabled type="text" value={Datas.filledAmount ? toLocaleString(Datas.filledAmount) : "--"} />
                            </div>
                            <div>
                                <p className="mb-2">Order Type</p>
                                <input className="form-control themeinput" name="ordertype" disabled type="text" value={Datas.orderType ? Datas.orderType : "--"} />
                            </div>
                        </div>

                        <div className='mt-2'>
                            <p className='mb-2'>Filled Price</p>
                            <input className="form-control themeinput" disabled name="filledprice" type="text" value={Datas.filled?.length > 0 ? FilledPriceCal(Datas.filled) : "--"}></input>
                            <hr />
                        </div>

                        <div>
                            <p className='mb-2'>Submitted Time</p>
                            <input className="form-control themeinput" disabled name="submittedtime" type="text" value={momentFormat(Datas.orderDate, 'DD-MM-YYYY HH:mm')}></input>

                            <hr />

                            <p className='text_heading_sub_modal'>{Datas.orderType} Order Details</p>

                            <div className="d-flex flex-1 gap-2 mt-2">
                                <div>
                                    <p className="mb-2">Submitted TIF</p>
                                    <input className="form-control themeinput" name="submittedTIF" disabled type="text" value="Good Till Cancel" />
                                </div>
                                <div>
                                    <p className="mb-2">Expiry</p>
                                    <input className="form-control themeinput" name="expiry" disabled type="text" value={Datas.expriy_date ? Datas.expriy_date : "--"} />
                                </div>
                            </div>

                            <div className="d-flex flex-1 gap-2 mt-2">
                                <div>
                                    <p className="mb-2">Submitted Price</p>
                                    <input className="form-control themeinput" name="submittedprice" disabled type="text" value={Datas.price ? Datas.price : "-"} />
                                </div>
                                <div>
                                    <p className="mb-2">Spot Price</p>
                                    <input className="form-control themeinput" name="spotprice" disabled type="text" value={Datas.buyorsell == 'buy' ? Markupprice.ask:Markupprice.bid} />
                                </div>
                            </div>

                            <div className="d-flex flex-1 gap-2 mt-2">
                                <div>
                                    {console.log('datage', Datas.buyorsell, BUYORSELL)}
                                    <p className="mb-2">Trigger Side</p>
                                    <input className="form-control themeinput" name="triggerside" disabled type="text" value={BUYORSELL} />
                                </div>
                                <div>
                                    <p className="mb-2">Current Distance</p>
                                    <input className="form-control themeinput" name="spotprice" disabled type="text" value={Distance} />
                                </div>
                            </div>

                            <div className="d-flex flex-1 gap-2 mt-2">
                                <div>
                                    <p className="mb-2">Submitted SL</p>
                                    <input className="form-control themeinput" name="submittedSL" disabled type="text" value={Datas.stopLossPrice ? Datas.stopLossPrice : "--"} />
                                </div>
                                <div>
                                    <p className="mb-2">Current SL</p>
                                    <input className="form-control themeinput" name="currentSL" disabled type="text" value={Datas.stopLossPrice ? Datas.stopLossPrice : "--"} />
                                </div>
                            </div>

                            <div className="d-flex flex-1 gap-2 mt-2">
                                <div>
                                    <p className="mb-2">Submitted TP</p>
                                    <input className="form-control themeinput" name="submittedTP" disabled type="text" value={Datas.takeProfitPrice ? Datas.takeProfitPrice : "--"} />
                                </div>
                                <div>
                                    <p className="mb-2">Current TP</p>
                                    <input className="form-control themeinput" name="currentTP" disabled type="text" value={Datas.takeProfitPrice ? Datas.takeProfitPrice : "--"} />
                                </div>
                            </div>
                        </div>


                    </div> */}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PositionOrderInfo