import React, { useEffect, useState, useContext } from 'react';
import { Modal, Tabs, Dropdown, InputGroup, Form, Tab } from 'react-bootstrap'

import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from 'react-redux';
//action
import { addAccount, viewUserProfile } from 'actions/users';
import { toastAlert } from 'lib/toastAlert';

const initialFormValue = {
    'currency': 'USD',
    'leverage': '1:200',
    'amount': '1000'
}
const Accounttype = (props) => {
    const{liveCount,demoCount}=props
    const dispatch = useDispatch()
    const [acctype, setAcctype] = useState(true)
    const [type, setType] = useState('live')
    const [formValue, setFormValue] = useState(initialFormValue)
    const { currency, leverage, amount } = formValue
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let formData = { ...formValue, ...{ [name]: value } }
        setFormValue(formData)
    }
    const OnSubmit = async (e) => {
        e.preventDefault();
        let data = {
            Type: type,
            amount: type == 'demo' ? parseFloat(amount) : 0,
            leverage: leverage,
            currency: currency
        }
        let { status, message } = await addAccount(data)
        if (status) {
            toastAlert('success', message, 'spotOrder');
            viewUserProfile(dispatch)
        } else {
            toastAlert('error', message, 'spotOrder')
        }
        props.onDismiss()
    }
    useEffect(()=>{
        if(liveCount >= 5){
            setType('demo')
        }
    },[liveCount,demoCount])
    return (
        <>
            <Modal show={acctype} className="modalbg informationmodal acc_modal font-12" size="md" centered scrolable>
                <Modal.Header className='align-items-center'>
                    <Modal.Title>New Trading Account</Modal.Title>
                    <button className='closebtn btn btn-link text-light nounder' onClick={() => props.onDismiss()}>
                        <i className="fa fa-times fa-6" aria-hidden="true"></i>
                        {/* X */}
                    </button>
                </Modal.Header>
                <Modal.Body className='font-12'>
                    <div className='themetab'>
                        {console.log(type,liveCount,'type---')}
                        <Tabs defaultActiveKey={liveCount && liveCount >= 5 ? "demo" : 'live'} className="mb-3" onSelect={(e) => { setType(e) }}>
                            {liveCount == 5 ? "" : 
                            <Tab eventKey="live" title="Live">
                                <div className='row mx-0'>
                                    <div className='col-6'>
                                        <p>Deposit Currency</p>
                                        <select className="selectcount themeselect my-2 w-full"
                                            name="currency" onChange={(e) => { handleChange(e) }}
                                        >
                                            {/* <option>select</option> */}
                                            <option value="USD">USD</option>
                                            {/* <option value="2">BTC</option> */}
                                        </select>
                                    </div>
                                    <div className='col-6'>
                                        <p>Leverage</p>
                                        <select className="selectcount themeselect my-2 w-full"
                                            name="leverage" onChange={(e) => { handleChange(e) }}
                                        >
                                            <option value='1:200'>1:200 (Default)</option>
                                            <option value="1:1">1:1</option>
                                            <option value="1:2">1:2</option>
                                            <option value="1:5">1:5</option>
                                            <option value="1:10">1:10</option>
                                            <option value="1:20">1:20</option>
                                            <option value="1:50">1:50</option>
                                            <option value="1:75">1:75</option>
                                            <option value="1:100">1:100</option>
                                            {/* <option value="8">1:200 (Default)</option> */}
                                        </select>
                                    </div>
                                </div>
                                <hr />
                                <button className='btn green-btn w-full' onClick={(e) => { OnSubmit(e) }}>Create Live Account</button>
                            </Tab>}
                            {demoCount == 5 ? "" : <Tab eventKey="demo" title="Demo">
                                <div className='row mx-0'>

                                    <div className='col-6 col-sm-4'>
                                        <p>Deposit Currency</p>
                                        <select className="selectcount themeselect my-2 w-full"
                                            name="amount" onChange={(e) => { handleChange(e) }}
                                        >
                                            {/* <option>1000</option> */}
                                            <option value="1000">1000</option>
                                            <option value="3000">3000</option>
                                            <option value="5000">5000</option>
                                            <option value="10000">10000</option>
                                            <option value="25000">25000</option>
                                            <option value="50000">50000</option>
                                            <option value="100000">100000</option>
                                            <option value="500000">500000</option>
                                            <option value="1000000">1000000</option>
                                            {/* <option value="2">2000</option> */}
                                        </select>

                                    </div>

                                    <div className='col-6 col-sm-4'>
                                        <p>Currency</p>
                                        <select className="selectcount themeselect my-2 w-full"
                                            name="currency" onChange={(e) => { handleChange(e) }}
                                        >
                                            {/* <option hidden></option> */}
                                            <option value="USD">USD</option>
                                            {/* <option value="2">BTC</option> */}
                                        </select>

                                    </div>

                                    <div className='col-12 col-sm-4'>
                                        <p>Leverage</p>
                                        <select className="selectcount themeselect my-2 w-full"
                                            name="leverage" onChange={(e) => { handleChange(e) }}
                                        >
                                            <option value='1:200'>1:200 (Default)</option>
                                            <option value="1:1">1:1</option>
                                            <option value="1:2">1:2</option>
                                            <option value="1:5">1:5</option>
                                            <option value="1:10">1:10</option>
                                            <option value="1:20">1:20</option>
                                            <option value="1:50">1:50</option>
                                            <option value="1:75">1:75</option>
                                            <option value="1:100">1:100</option>
                                            {/* <option value="8">1:200 (Default)</option> */}


                                        </select>

                                    </div>
                                </div>
                                <hr />



                                <button className='btn green-btn w-full' onClick={(e) => { OnSubmit(e) }}>Create Demo Account</button>


                            </Tab>}
                        </Tabs>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Accounttype