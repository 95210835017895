import React, { useState, useEffect } from 'react';
import { Modal, Tabs, Dropdown, InputGroup, FormControl, Button, Form, Tab } from 'react-bootstrap'
import { useHistory } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import {getverifystatus} from '../../../../actions/userKyc'

const Kycinitialmodal = (props) => {
    //    var navigate = useNavigate();
    const history = useHistory()
    const [kycinitialvalidate, setKycinitialvalidate] = useState(false)
    const kycStatus = useSelector(state => state.userKyc.Status);
    const { verifiedstatus, Synapsverify } = kycStatus 
    //state
    const dispatch = useDispatch();

    const gotoKyc = () => {

        setKycinitialvalidate(false);
        // navigate.push("/Kycvalidation");

    }
    useEffect(()=>{
        // getStatus()
    },[])
    useEffect(() => {
        if(verifiedstatus === 'Not verified'  ||verifiedstatus === 'REJECTED' ){
            setKycinitialvalidate(true)
        }else if(verifiedstatus === 'APPROVED'||verifiedstatus === 'PENDING'){
            setKycinitialvalidate(false)
        }
    }, [verifiedstatus])
    const getStatus = async () => {
        await getverifystatus(dispatch);
    }



    return (
        <>
            <Modal show={kycinitialvalidate} className="modalbg uploadmodal font-12" size="md" centered scrolable>

                <Modal.Header className='align-items-center'>
                    <Modal.Title>KYC Verification</Modal.Title>
                    <button className='closebtn btn btn-link text-light nounder' onClick={() => props.onDismiss()}>
                    <i className="fa fa-times fa-6" aria-hidden="true"></i>

                    </button>
                </Modal.Header>
                <Modal.Body className='font-12'>
                    <p className="text-center font_14_kyc_he">Please validate your KYC to start trading</p>
                    <div className="mt-4 mb-3 text-center">
                        <button className="btn-light mr-3 btn_ver_blue_go" onClick={() => { history.push('/tradedashboard/individual');setKycinitialvalidate(false)}}>Individual Verification</button>
                        <button className="btn-light btn_ver_blue_go" onClick={() => { history.push('/tradedashboard/entity');setKycinitialvalidate(false) }} >Entity Verification</button>


                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Kycinitialmodal

