import React, { useEffect, useState, useContext } from 'react'
import { Link, NavLink, useHistory, useParams } from 'react-router-dom';
import { Accordion, Card, Tabs, Tab, Button, InputGroup, Form, Dropdown } from 'react-bootstrap'
import { addwishlisthook } from 'actions/currencycategoryAction';
import SocketContext from '../../../Context/SocketContext';
import { useDispatch, useSelector } from "react-redux";
import { setMarketPrice } from 'actions/spotTradeAction';
import { EstMargin, BidandAskDisplay, pipDisplay } from 'lib/bybit'
import { userPair } from 'actions/users';
const Singelsymbol = (props) => {
  // console.log(props.wishlist,'props.wishlist')
  const {wishlist}=props
  const { useraccount } = useSelector(state => state.account);
  const dispatch = useDispatch()
  const socketContext = useContext(SocketContext)
  const { tikerRoot } = useParams()
  const history = useHistory();
  const [wished, setWished] = useState(false)
  // const [whishlist, setWishlist] = useState(false)
  const [expand, setExpand] = useState(false)
  const [wisharray, setWisharray] = useState([])
  const [Markupprice, setmarkupprice] = useState({})
  const handlewishlist = async (data, id) => {
    let payload = {
      name: data,
      id: id
    }
    console.log("payload", payload);
    let result = await addwishlisthook(payload);
    props.refresh();
    console.log("result", result);
  }
  useEffect(() => {
    // setTimeout(wisheddata(), 10000);
    console.log(props.wishlist,wishlist,'props.wishlist')
    wisheddata(wishlist);
  }, [wishlist])
  useEffect(() => {
    socketContext.socket.on('perpetualmarketprice', (result) => {
      // console.log('perpetualmarketprice', result,useraccount)
      try {
        if (props.data.tiker_root == result.tiker_root) {
          // console.log('perpetualmarketprice', result)
          setmarkupprice(result)
        }
      } catch (err) {
        console.log(err, 'perpetualmarketprice__err')
      }
    })
  }, [socketContext.socket, useraccount])
  const handlePairChange = async (pairData) => {
    let pair = `${pairData.tiker_root}`
    localStorage.setItem('curpair', pair)
    history.push('/trade/' + pair)
    await userPair({ pair: pair }, dispatch)
    if (tikerRoot != pair) {
      //   await fetchAssetByCurrency(pairData.firstCurrencyId, 'firstCurrency')
      //   await fetchAssetByCurrency(pairData.secondCurrencyId, 'secondCurrency')
      //   await setMarketPrice(pairData, dispatch)
      //   await setTradePair(pairData, dispatch)
      //   socketContext.socket.off("marketPrice");
      //   socketContext.socket.off("orderBook");
      //   socketContext.socket.off("recentTrade");
    }
  }

  const wisheddata = async (WishData) => {
    console.log("datasin wished",WishData);
    if(WishData?.length > 0){
      WishData.map((val) => {
        console.log("datasin wishedin map" , val);
        let data = val.wished.find((e) => e._id == props.data._id)
        // console.log("datasin wished" , data);
        if (data) {
          setWished(true);
        }else{
          setWished(false);
        }
      })
    }else{
      setWished(false)
    }
  }

  return (
    <>
      <div className={expand ? "d-none" : 'shrinked d-flex jc-between'} onClick={() => { handlePairChange(props.data) }} >
        <div className='d-flex gap-2 align-items-center' >
          <Dropdown className='stardropdown'>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <div className='starfont'>
                {/* {props.wishlist && props.wishlist.map((val) => {
                  data = val.wished.find((e)=> e._id ==props.data._id)
                  if(data){
                    setWished(true);
                   }
               }) } */}
                {wished && wished
                  ? <span class="fas fa-star fa-hover-show show-wishlist"></span> : <>
                    <span class="fas fa-star fa-hover-show"></span>
                    <span class="far fa-star fa-hover-hidden"></span>
                  </>
                  }





              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>

              {props.wishlist && props.wishlist.map((val) => <>
                <Dropdown.Item href="#">
                  <i className={val.wished.find((e) => e._id === props.data._id) ? "fa fa-check" : "d-none"}></i>&nbsp;&nbsp;<p onClick={() => handlewishlist(val?.name, props.data?._id)}>{val?.name}</p></Dropdown.Item>
              </>)}
              {/* props.wishlist.wished.includes(props.data)?: "d-none" */}

              <hr className='my-2' />
              <Dropdown.Item onClick={() => { props.setWishlist(true); props.id(props?.data?._id) }} href="#">New Wishlist</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <span className='fa fa-check text-light'></span>
          <p>{props.data?.displayname}</p>
        </div>
        <div className='d-flex gap-2'>
          <p className='text-danger'>{Markupprice && (Markupprice?.disbid ? Markupprice?.disbid : props?.data?.bid)}
            <span className='text-danger lastnumber'>
              {Markupprice && Markupprice?.bid ? Markupprice?.pipBid : BidandAskDisplay(props?.data?.bid, props.data.precision)}
            </span>
          </p>
          {/* on else part we have to take our order book last price */}
          <p className='text-success'>{Markupprice && (Markupprice?.disask ? Markupprice?.disask : props?.data?.ask)}
            <span className='text-success lastnumber'>
              {Markupprice && Markupprice?.ask ? Markupprice?.pipAsk : BidandAskDisplay(props?.data?.ask, props.data.precision)}
            </span>
          </p>
        </div>

      </div>
    </>
  )
}

export default Singelsymbol