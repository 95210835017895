import React, { useEffect, useState } from 'react';
import envelope from "../../../assets/images/newtrade/envelope.svg"
import candle from "../../../assets/images/newtrade/candle.svg"
import { Accordion, Card, Tabs, Tab, Button, InputGroup, Form, Dropdown } from 'react-bootstrap'
import { Link, NavLink, useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import CreateWishlist from '../newtrade/Modals/CreateWishlist';
import file from '../../../assets/images/newtrade/file.svg'
import chart from '../../../assets/images/newtrade/chart.svg'
import star from '../../../assets/images/newtrade/star.svg'
import deposit from '../../../assets/images/newtrade/deposit.svg'
import withdraw from '../../../assets/images/newtrade/withdraw.svg'
import setting from '../../../assets/images/newtrade/setting.svg'
import CopyTrade from '../newtrade/Modals/CopyTrade';
import NewOrder from '../newtrade/Modals/NewOrder';
import Infomation from './Modals/Information';
import ViewInvestor from './Modals/ViewInvestor';
import DeleteInvestor from './Modals/DeleteInvestor';
import EquityStoploss from './Modals/EquityStoploss';
import ConfirModal from './Modals/ConfirmModal'
import AddFund from './Modals/AddFund';
import StopCopying from './Modals/StopCopying';
import RemoveFund from './Modals/RemoveFund';
import Allsymbol from './Allsymbol';
import Whislist from './Whislist';
import Searchsymbol from './searchsymbol.js'
import EditStrategy from './Modals/EditStrategy'

import Strategy from './Modals/Strategy';
import { getPairList, setPairList, setTradePair, setMarketPrice } from '../../../actions/spotTradeAction';
import { getStrategy, RestartProviding, stopStrategy } from '../../../actions/copyTradeAction';
import { Tradefollowers } from '../../../actions/copyTradeAction'
import { getstocksymbolaction, setCategory } from 'actions/currencycategoryAction';
import { getuserdatahook } from 'actions/currencycategoryAction';
import { filtersymboldatahook } from 'actions/currencycategoryAction';
import { addwishlisthook } from 'actions/currencycategoryAction';
import Accounttype from './Modals/Accounttype';
import { toastAlert } from 'lib/toastAlert';
import Fundaccount from '../../../pages/fundaccount'
import Accountsetting from '../../../pages/Accountsetting';
import StartCopying from './Modals/StartCopying';
import RestartCopying from './Modals/RestartCopying'
import { FOLLOWERS_DETAILS } from 'constant';
import isEmpty from 'lib/isEmpty';

const Sidebar = ({ setWhole, activetabkey }) => {
    const params = useParams()
    // console.log(params,"params")
    const { isAuth } = useSelector(state => state.auth);
    const dispatch = useDispatch()
    const history = useHistory();
    const [mini, setMini] = useState(false)
    const [grid, setGrid] = useState(false)
    const [wishes, setWishes] = useState([{ id: 0, name: "" }])
    const [wishescount, setWishescount] = useState(0)
    const [conFirmed, setconFirmed] = useState(false)
    const [whishlist, setWishlist] = useState(false)
    const [wishname, setWishname] = useState("Wishlist")
    const [flatnav, setFlatNav] = useState(false)
    const [openacc, setOpenAcc] = useState(false)
    const [expand, setExpand] = useState(false)
    const [live, setLive] = useState(false)


    const [copycount, setCopycount] = useState(0)
    const [copydata, setCopyData] = useState([{ id: 0, status: "" }])

    const [activetab, setactivetab] = useState(activetabkey ? activetabkey : "0");

    // modals
    const [neworder, setNewOrder] = useState(false)
    const [copytrade, setCopyTrade] = useState(false)
    const [info, setInfo] = useState(false)
    const [viewinvestor, setViewInvestor] = useState(false)
    const [deleteinvestor, setDeleteInvestor] = useState(false)
    const [equitystop, SetEquitystop] = useState(false)
    const [addFunds, setAddFund] = useState(false)
    const [removeFund, setRemoveFund] = useState(false)
    const [stopCopying, setStopCopying] = useState(false)

    const [strategy, setStrategy] = useState(false)
    const [ocategory, setOcategory] = useState([]);
    const [pair, setPair] = useState([]);
    // console.log("pair-fdfd-----------------------",pair);
    const [userwishlist, setUserwishlist] = useState([]);
    const [issearch, setIssearch] = useState(false);
    const [wishsearch, setWishsearch] = useState(false);
    const [searchwish, setSearchwish] = useState([])
    const [searchpair, setSearchpair] = useState([]);
    const [search, setSearch] = useState("");
    const [wished, setWished] = useState(false);//it's not used
    const [accountDetails, setAccountDetails] = useState('')
    const [searchshow, setSearchShow] = useState(false);
    const [conFirm, setconFirm] = useState(false)
    const [isEdit, setEdit] = useState(false)
    const [EditData, setEditData] = useState({})
    const [tradefollower, settradefollower] = useState()
    const [copyingData, setCopyingData] = useState({})
    const [balanceInvestment, setBalanceInvestment] = useState("")
    const [followerId, setFollowerId] = useState('')
    const [stopId, setStopId] = useState()
    const [startCopying, setStartCopying] = useState(false)
    const [EquityData, setEquityData] = useState({})
    const [Redirected, setRedirected] = useState("fundaccount")
    const [restartCopying, setRestartCopying] = useState(false)
    const [copyTradeFollowID, setCopyTradeFollowID] = useState("")
    const [account, setAccount] = useState()
    const [copiedAccount, setcopiedAccount] = useState({})
    const [restartAccount, setrestartAccount] = useState({})
    const [startedAccount, sestartedAccount] = useState({})
    const [removedData, setRemovedData] = useState({})
    const [removedAccount, setremovedAccount] = useState({})
    const [liveCount, setLivecount] = useState(0)
    const [demoCount, setDemocount] = useState(0)
    // console.log("tradefollower_v_vtradefollower", tradefollower);
    // const []
    //redux
    // const accounts  = useSelector(state => state.account);
    const { accounts, useraccount } = useSelector(state => state.account);
    const { strategyDetails, followerDetails } = useSelector(state => state.copy)
    // console.log("strategyDetails_strategyDetails_strategyDetails", accounts);
    const categoryPair = useSelector(state => state.categoryPair)
    const checkOne = useSelector(state => state)
    console.log(followerDetails, 'checkOne_01_checkOne', strategyDetails)
    useEffect(() => {
        let liveCount = accounts?.filter((val) => (val.type == 'live'))
        let demoCount = accounts?.filter((val) => (val.type == 'demo'))
        setDemocount(demoCount?.length)
        setLivecount(liveCount?.length)
    }, [account])
    useEffect(() => {
        // async function fetchdata(){
        //     let symbols =  await getstocksymbolaction();
        //     console.log("symbols" , symbols);
        //     // setCategory(symbols?.result?.category);
        //     setPair(symbols?.result)
        //     let wishlist = await getuserdatahook();
        //     console.log("wishlist" , wishlist , wishlist?.result?.wishlist?.length);
        //     setUserwishlist(wishlist?.result?.wishlist)
        // }
        console.log("repeatedly call")
        setAccount(accounts)
        if (categoryPair.length > 0) {
            setPair(categoryPair)
            var datatest = JSON.parse(localStorage.getItem("totalSymbols"));
            if (!datatest) {
                localStorage.setItem("totalSymbols", JSON.stringify(categoryPair));
            }
            if (isAuth) {
                fetchWish()
            }
        } else {
            fetchdata()
            // console.log("Auth",isAuth)
            if (isAuth) {
                fetchWish()
            }
        }
    }, [isAuth, accounts])

    useEffect(() => {
        if (isAuth) {
            getStrategy(dispatch);
            CopyTradefollowers('')
        }
    }, [isAuth, restartCopying, stopCopying])
    useEffect(() => {
        if (isAuth) {
            settradefollower(followerDetails)
        }
    }, [isAuth, followerDetails])

    // useEffect(()=>{
    //     if(params.id ==='deposit'||params.id==='withdraw'){
    //         document.getElementById("deposit").click()
    //       }
    // })


    // const fetchStrategy = async () => {
    //     try{
    //         await getStrategy(dispatch)
    //     }catch(err){
    //         console.log(err,'fetchStrategy')
    //     }
    // }

    const fetchdata = async () => {
        try {
            let { success, result } = await getstocksymbolaction();
            if (success) {
                console.log("symbols", result);
                // setCategory(symbols?.result?.category);
                setPair(result);
                setCategory(result, dispatch)
                // var datatest = JSON.parse(localStorage.getItem("totalSymbols"));
                // if(!datatest){
                localStorage.setItem("totalSymbols", JSON.stringify(result));
                // }
                // setOcategory(symbols?.result);
                // console.log(" datacategory : ",result)
                // var datatest = JSON.parse(localStorage.getItem("totalSymbols"));
                // console.log(" datacategory local : ", datatest)
            }

            // await fetchWish()
            // let wishlist = await getuserdatahook();
            // console.log("wishlist in fetchdartasidebar", wishlist, wishlist?.result?.wishlist);
            // setUserwishlist(wishlist?.result?.wishlist);
            // //var userwhistlisted = await datatest.map((index)=> index.categorydata.map((item)=> wishlist?.result?.wishlist.find((item))));
            // //console.log("userwhistlisted" , userwhistlisted);
            // var wish = []
            // await wishlist?.result?.wishlist.map((val , i)=>{
            //     console.log("result" , val);
            //     wish = wish.concat(val.wished);
            // })
            // console.log("wishlist in fetchdartasidebar", wish);
            // setWished(wish);
        }
        catch (err) {
            console.log(err, 'fetchdata')
        }
    }
    const fetchWish = async () => {
        try {
            let wishlist = await getuserdatahook();
            console.log("wishlist in fetchdartasidebar", wishlist, wishlist?.result?.wishlist);
            setUserwishlist(wishlist?.result?.wishlist);
            var wish = []//it's not used
            await wishlist?.result?.wishlist.map((val, i) => {
                // console.log("wishlist--result", val);
                wish = wish.concat(val.wished);
            })
            // console.log("wishlist in fetchdartasidebar", wish);
            setWished(wish);
            localStorage.setItem("wishlist", JSON.stringify(wishlist?.result?.wishlist));
        } catch (err) {
            console.log(err, 'fetchWish')
        }
    }
    // const setFollowerDetails = (data) => {
    //     return {
    //         type: FOLLOWERS_DETAILS,
    //         data
    //     }
    // }
    const CopyTradefollowers = async (data) => {
        try {
            let { result, status } = await Tradefollowers(dispatch, { status: data })
            console.log('CopyTradefollowers_folloewersList', result);
            // dispatch(setFollowerDetails(result))
            settradefollower(result)
        } catch (err) {
            console.log(err, 'CopyTradefollowers--err')
        }
    }
    const handlesearch = async (search) => {
        setSearchShow(true);
        setSearch(search)
        // let result = await filtersymboldatahook(data);
        // console.log("handlesearchresult" , result);
        // setPair(result?.result)
        if (search.length == 0) {
            setIssearch(false);
            // setPair(ocategory);
        }
        else if (search.length > 0) {
            setIssearch(true);
            var datatest = JSON.parse(localStorage.getItem("totalSymbols"));
            // var category = pair;
            console.log(" datacategory categories tts : ", datatest[0]?.categorydata)
            for (var i = 0; i < datatest.length; i++) {
                // for(var j =0 ; j < category[i]?.categorydata?.length ; j++){
                let data = datatest[i]?.categorydata
                console.log("datacategory", datatest, search, data);
                let result = await data.filter(value => ((value?.displayname)?.toLowerCase()).includes(search?.toLowerCase()));

                // for(var i= 0 ; i<result.length ; i++){
                //     let data = await wisheddata(result[i]._id)
                //     if(data){
                //         result[i].iswished = true
                //     }
                //     else{
                //         result[i].iswished = false
                //     }
                //     // userwishlist.map((val)=>{
                //     //     console.log("datasin wishedin mapin sidebar" , val , result[i]._id);
                //     //     let data = val.wished.find(e=>e._id ==result[i]._id) // e._id ==id

                //     //    if(data){

                //     //     result[i].iswished = true
                //     //    }
                //     //   })
                // }
                console.log("result", result);
                datatest[i].categorydata = result;
                console.log("datacategory result", datatest);
                //  Object.assign(category[i],{"result" : result});
                // }
            }
            setSearchpair(datatest);
            //
            console.log("datacategory categories []", searchpair);
        }

    }

    const handlesearch2 = async (search) => {
        try {
            setSearch(search)
            if (search.length == 0) {
                setWishsearch(false);
                // setPair(ocategory);
            }
            else if (search.length > 0) {
                setWishsearch(true);
                var wishdata = JSON.parse(localStorage.getItem("wishlist"));
                console.log(" wishdata_wishdata ", wishdata[0]?.name)
                for (var i = 0; i < wishdata.length; i++) {
                    let data = wishdata[i]?.wished
                    console.log("dataname", wishdata, search, data);
                    let resultsearch = await data?.filter(value => ((value?.socketsymbol)?.toLowerCase()).includes(search?.toLowerCase()));
                    console.log("result", resultsearch);
                    wishdata[i].wished = resultsearch;
                    console.log("dataname result", wishdata);
                }
                setSearchwish(wishdata)
            }
        } catch (err) {
            console.log("error", err)
        }
    }

    const handlewishlist = async (data, id) => {
        let payload = {
            name: data,
            id: id
        }
        console.log("payload", payload);
        let result = await addwishlisthook(payload);
        await fetchdata();
        console.log("result", result);
    }

    const wisheddata = (id) => {
        // console.log("datasin wishedin sidebar",);
        userwishlist.map((val) => {
            //   console.log("datasin wishedin mapin sidebar" , val , id);
            //   let data = val.wished.find(e=>console.log("mapping indexdata" , e._id , id)) // e._id ==id
            let data = val.wished.includes(id)

            if (data) {
                console.log("searchwished data", data, id);
                return true
            }
        })

        return false
    }


    const toGrid = (e) => {
        if (e.target.classList.contains("fa-bars")) {
            e.target.setAttribute("class", "fa fa-th")
            setExpand(true)

        } else {
            e.target.setAttribute("class", "fas fa-bars")
            setExpand(false)
        }
    }
    const openAcc = (e) => {
        var cont = e.target.parentNode.parentNode.nextElementSibling
        if (!(cont.classList.contains("d-none"))) {
            cont.classList.add("d-none")
        } else {
            cont.classList.remove("d-none")
        }

    }

    const StopProvidingStrategy = async (accountId) => {
        try {
            let data = {
                accountId: accountId,
                statue: 'Inactive'
            }
            console.log(data, 'StopProvidingStrategy')
            let { status, loading, message } = await stopStrategy(data, dispatch);
            if (status) {
                toastAlert('success', message, 'stopStrategy');
            } else {
                toastAlert('error', message, 'stopStrategy');
            }
        } catch (err) {
            console.log(err)
            toastAlert('error', 'error occurred', 'stopStrategy');
        }
    }

    const RestartProvidingStrategy = async (accountId) => {
        console.log("RestartProvidingStrategy_accountId", accountId);
        try {
            console.log("higiigihgihi");
            let data = {
                accountId: accountId,
                status: 'active'
            }

            console.log("datatdadadadtadda", data);
            let { status, loading, message } = await RestartProviding(data, dispatch);
            console.log("message_Vmessagemessage", status);
            if (status) {
                toastAlert('success', message, 'RestartStrategy');
                //  setShow(false)
            } else {

                toastAlert('error', message, 'RestartStrategy');
            }
        } catch (err) {
            console.log("err_err_err_", err);

            toastAlert('error', 'error occurred', 'RestartStrategy');
        }
    }

    const filterAccount = async (data) => {
        console.log("filterAccount_dataLength", data.length, data);
        if (data.length > 0) {
            const filterData = account.filter((el) => ((el.accountId).includes(data)))
            setAccount(filterData)
        } else {
            console.log("filterAccount_dataLength", accounts);
            setAccount(accounts)
        }
    }

    return (
        <div id="sidebar" className={mini ? "mini font-12" : "font-12"}>
            {restartCopying && <RestartCopying
                onDismiss={() => setRestartCopying(false)}
                copyingData={copyingData}
                copyTradeFollowID={copyTradeFollowID}
                restartAccount={restartAccount}
                AddFund={(accountId) => {
                    let accountData = account.find((val) => (val.accountId == accountId));
                    setcopiedAccount(accountData);
                    setAddFund(true)
                }}
                startCopying={(accountId) => {
                    let accountData = accounts.find((val) => (val.accountId == accountId));
                    sestartedAccount(accountData);
                    setStartCopying(true)
                }}
            />
            }

            {conFirmed &&
                <ConfirModal title='Restart Strategy Providing '
                    message='If you stop providing strategy, all traders will be automatically Restart Strategy Providing  it.'
                    onDismiss={() => setconFirmed(false)}
                    onCofirm={(accountId) => { RestartProvidingStrategy(accountId) }}
                    id={accountDetails.accountId} />
            }
            {startCopying && <StartCopying
                onDismiss={() => setStartCopying(false)}
                copyingData={copyingData}
                AddFund={(accountId) => {
                    let accountData = account.find((val) => (val.accountId == accountId));
                    setcopiedAccount(accountData);
                    setAddFund(true)
                }}
                restart={(accountId, id) => {
                    let accountData = accounts.find((val) => (val.accountId == accountId));
                    setCopyTradeFollowID(id)
                    setrestartAccount(accountData);
                    setRestartCopying(true)
                  }}
                startedAccount={isEmpty(startedAccount) ? useraccount : startedAccount}
            // AddFund ={() => setAddFund(true)}
            />}

            {info && <Infomation onDismiss={() => setInfo(false)} />}
            {neworder && <NewOrder onDismiss={() => setNewOrder(false)} openinfo={() => { setInfo(true); setNewOrder(false) }} />}
            {copytrade && <Accounttype onDismiss={() => setCopyTrade(false)} liveCount={liveCount} demoCount={demoCount} />}
            {/* {copytrade && <CopyTrade onDismiss={() => setCopyTrade(false)} stateacc={(data) => { setLive(data); setCopyData((val) => [...val, { id: copycount + 1, status: data }]) }} />} */}
            {whishlist && <CreateWishlist onDismiss={() => setWishlist(false)}
                refresh={() => fetchWish()}
                wishname={(data) => {
                    setWishescount(wishescount + 1);
                    setWishes((val) => [...val, { id: wishescount + 1, name: data }]);
                }} />}
            {viewinvestor && <ViewInvestor onDismiss={() => setViewInvestor(false)} />}
            {deleteinvestor && <DeleteInvestor onDismiss={() => setDeleteInvestor(false)} />}
            {equitystop && <EquityStoploss onDismiss={() => SetEquitystop(false)} EquityData={EquityData} />}

            {addFunds && <AddFund
                onDismiss={() => setAddFund(false)}
                copyingData={copyingData}
                startCopying={(accountId) => {
                    let accountData = accounts.find((val) => (val.accountId == accountId));
                    sestartedAccount(accountData);
                    setStartCopying(true)
                }}
                restart={(accountId, id) => {
                    let accountData = accounts.find((val) => (val.accountId == accountId));
                    setCopyTradeFollowID(id)
                    setrestartAccount(accountData);
                    setRestartCopying(true)
                  }}
                copiedAccount={copiedAccount}
            />}
            {removeFund && <RemoveFund onDismiss={() => setRemoveFund(false)} removedData={removedData} removedAccount={removedAccount} />}
            {stopCopying && <StopCopying onDismiss={() => setStopCopying(false)} copyingData={copyingData} stopAccountId={stopId} balanceInvestment={balanceInvestment} followerId={followerId} />}
            {conFirm &&
                <ConfirModal title='Stop Providing Strategy'
                    message='If you stop providing strategy,all traders will be automatically stopped copying it.'
                    onDismiss={() => setconFirm(false)}
                    onCofirm={(accountId) => { StopProvidingStrategy(accountId) }}
                    id={accountDetails.accountId} />
            }
            {/* {console.log("accountDetails_accountDetails_v", accountDetails)} */}
            {strategy && <Strategy accountDetails={accountDetails} onDismiss={() => setStrategy(false)} openinfo={() => { setInfo(true); setStrategy(false) }} />}
            {isEdit && <EditStrategy onDismiss={() => setEdit(false)} strategy={EditData} />}
            <div className='toggle d-flex jc-between p-2'>
                <button className='btn btn-link p-0 btn_toggle_blue' onClick={() => (setMini(!mini), setWhole(!mini))}>
                    {mini ? <span className='fas fa-arrow-right'></span> :
                        <span className='fas fa-arrow-left'></span>}
                </button>
                <div className={mini ? "d-none neworderbtn" : "d-block neworderbtn"}>
                    <button className='btn btn-outline-secondary f-12 icon-50 p-0 px-2 btn_banc_old' onClick={() => setNewOrder(true)}>New Order</button>
                </div>
            </div>
            <div className={mini ? "d-block sidebar_mob_con" : "d-none sidebar_mob_con"}>
                <Link to="/trade/" className='sidebar_icon_btn btn-primary btn'>
                    <img src={candle} className="icon" />
                </Link>
                <Link to="/copy" className='sidebar_icon_btn btn-primary btn'>
                    <i className="fa fa-copy" aria-hidden="true"></i>
                </Link>
                <div className='bottom-nav-mob'>
                    <button className={mini ? "d-none btn btn-variant navbtn" : "d-block btn btn-variant navbtn"} onClick={() => setFlatNav(!flatnav)}><span className='fa fa-chevron-up'></span></button>
                    <ul className='pl-0 mb-1'>

                        {/* <Link to="/tradedashboard" className='sidebar_icon_btn btn-primary btn'>
                            <img src={deposit} className="smallicon" />
                        </Link>

                        <Link to="/tradedashboard" className='sidebar_icon_btn btn-primary btn'>
                            <img src={withdraw} className="smallicon" />
                        </Link>

                        <Link to="/tradedashboard" className='sidebar_icon_btn btn-primary btn'>
                            <img src={setting} className="smallicon" />
                        </Link> */}
                    </ul>
                </div>
                <div className={flatnav ? 'bottom-nav flatnav' : 'bottom-nav'}>
                    <button className='btn btn-variant navbtn' onClick={() => setFlatNav(!flatnav)}><span className={flatnav ? "fa fa-chevron-up" : 'fa fa-chevron-down'}></span></button>
                    <ul className='pl-0 mb-0'>
                        <li> <NavLink to="/tradedashboard/deposit"><img src={deposit} id="deposit" className="smallicon" /> </NavLink></li>
                        <li> <NavLink to="/tradedashboard/withdraw"><img src={withdraw} className="smallicon" /></NavLink> </li>
                        <li> <NavLink to="/tradedashboard/settings"><img src={setting} className="smallicon" /></NavLink> </li>
                    </ul>
                </div>
            </div>
            <div className={mini ? "sidebarcont d-none" : "sidebarcont d-block"}>

                {/* {console.log(activetabkey, activetab, "activetabkey")} */}

                <Accordion defaultActiveKey={activetab} className='sidbaracc'>
                    <Card className={flatnav ? 'mb-4' : 'mb-1'}>
                        <Card.Header className=''>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0" className='curser'
                                onClick={() => history.push("/trade/")}
                            >
                                <img src={candle} className="icon" /> Trade
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body className='pr-0 pl-1 py-1 '>
                                <Tabs defaultActiveKey="symbols" className="mb-1" >
                                    <Tab eventKey="whishlist" title="Wishlist" className=''>
                                        <div>
                                            <InputGroup className="mb-1 whishlitsearch">
                                                <Form.Control placeholder="Recipient's username" value={search} onChange={(e) => { handlesearch2(e?.target?.value) }} />
                                                <InputGroup.Text id="basic-addon2" className='search_text' ><span className='fa fa-search'></span></InputGroup.Text>
                                                {/* <InputGroup.Text id="basic-addon2">Bid</InputGroup.Text>
                                                <InputGroup.Text id="basic-addon2">Ask</InputGroup.Text> */}
                                            </InputGroup>
                                        </div>
                                        {/* {console.log(userwishlist,'userwishlist ;')} */}
                                        {!wishsearch && <div className='scrolablediv scroll_heig_need'>
                                            {userwishlist && userwishlist.map((data, index) => <>
                                                <Whislist data={data}
                                                    refresh={() => fetchWish()}
                                                    wishlist={userwishlist}
                                                    wishsearch={wishsearch}
                                                    index={index}
                                                />
                                            </>)}
                                            <button onClick={() => { setWishlist(true) }} className="watchlist">+ Create Wishlist</button>
                                        </div>
                                        }
                                        {wishsearch && <div className='scrolablediv scroll_heig_need'>
                                            {wishsearch && searchwish.map((data, index) => <>
                                                <Whislist data={data}
                                                    refresh={() => fetchWish()}
                                                    wishlist={searchwish}
                                                    wishsearch={wishsearch}
                                                    index={index}
                                                />
                                            </>)}
                                            <button onClick={() => { setWishlist(true) }} className="watchlist">+ Create Wishlist</button>
                                        </div>
                                        }
                                        {/* {issearch &&
                                            <div className='scrolablediv_symobl_h'>
                                                {issearch && searchpair && searchpair?.map((data) =>
                                                    <>
                                                        <div className=''>
                                                            <Allsymbol data={data}
                                                                refresh={() => fetchWish()}
                                                                wishlist={userwishlist} />
                                                        </div>
                                                    </>)}
                                            </div>
                                        } */}
                                    </Tab>

                                    <Tab eventKey="symbols" title="All Symbols">
                                        <InputGroup className="mb-1 whishlitsearch">
                                            <Form.Control placeholder="Type Symbol Name " value={search} onChange={async (e) => { await handlesearch(e?.target?.value) }} />
                                            <InputGroup.Text id="basic-addon2" className='search_text'

                                            ><span className='fa fa-search'></span></InputGroup.Text>
                                            <InputGroup.Text id="basic-addon2">Bid</InputGroup.Text>
                                            <InputGroup.Text id="basic-addon2">Ask</InputGroup.Text>
                                        </InputGroup>
                                        {!issearch &&
                                            <div className='scrolablediv_symobl_h'>
                                                {pair && pair.map((data, index) =>
                                                    <div className=''>
                                                        <Allsymbol data={data}
                                                            refresh={() => fetchWish()}
                                                            wishlist={userwishlist}
                                                            issearch={issearch}
                                                            index={index}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        }

                                        {issearch &&
                                            <div className='scrolablediv_symobl_h'>
                                                {issearch && searchpair && searchpair?.map((data, index) =>
                                                    <>
                                                        <div className=''>
                                                            <Allsymbol data={data}
                                                                refresh={() => fetchWish()}
                                                                wishlist={userwishlist}
                                                                issearch={issearch}
                                                                index={index}
                                                            />
                                                        </div>

                                                    </>)}
                                            </div>
                                        }

                                    </Tab>
                                </Tabs>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card className={flatnav ? 'pos_copy_flat' : 'pos_copy'}>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="1" className='copy_icon_btn_side curser' onClick={() => history.push("/copy")}>
                                <i className="fa fa-copy" aria-hidden="true"></i>  Copy
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body className='pr-0 pl-1 py-1'>
                                <div>
                                    <div>
                                        <InputGroup className="mb-1 whishlitsearch">
                                            <Form.Control placeholder="Recipient's username" onChange={(e) => { filterAccount(e.target.value) }} />
                                            <InputGroup.Text id="basic-addon2" className='search_text'><span className='fa fa-search'></span></InputGroup.Text>
                                            <InputGroup.Text id="basic-addon2">Balance</InputGroup.Text>
                                            <InputGroup.Text id="basic-addon2">ROI</InputGroup.Text>
                                            <InputGroup.Text id="basic-addon2" className='settingic'>
                                                <Dropdown autoClose="outside" className='stardropdown' onSelect={(data) => { CopyTradefollowers(data) }}>
                                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                        <span className='fa fa-cog ml-2 plus_common'></span>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item eventKey="Show_Copied_Strategies" className='text-light noevent fw-bold bg-secondary'  >Show Copied Strategies : </Dropdown.Item>
                                                        <Dropdown.Item eventKey="active" className='text-light f-12'>Active</Dropdown.Item>
                                                        <Dropdown.Item eventKey="stopped" className='text-light f-12'>Stopped</Dropdown.Item>
                                                        <Dropdown.Item eventKey="removedByProvider" className='text-light f-12'>Removed by Provider</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </InputGroup.Text>
                                        </InputGroup>
                                    </div>
                                    <div className='scrolablediv'>

                                        <div className='custdacc inneracc'>
                                            <div className='pl-0'>
                                                <div>
                                                    {/* {console.log(account, 'accountData_accountData_accountData_V_V')} */}
                                                    {account && account?.length > 0 && account?.map((item, index) => {
                                                        // console.log("item_V_V_V", item);
                                                        const provider = strategyDetails && strategyDetails.length > 0 && strategyDetails?.find((val) => val.accountId == item.accountId)
                                                        // console.log("provider_V_provider", provider);
                                                        const followers = tradefollower && tradefollower.length > 0 && tradefollower?.filter((val) => val.followerAccountId == item.accountId)
                                                        // console.log("followers_followers_followers_followers_v", followers);
                                                        // console.log("provider_V_provider", provider);
                                                        return (
                                                            <>
                                                                <div className='acc-item w-full mb-1'>
                                                                    <div className="d-flex jc-between align-items-center w-full ">
                                                                        <div className='d-flex align-items-center gap-1 curser flex1' onClick={() => { history.push({ pathname: "/providingstrategy", state: { accountId: item.accountId } }) }}>
                                                                            <span className={item.type === 'demo' ? 'ml-2 px-1 text-blue plus_common' : 'ml-2 px-1 text-green plus_common'}>{item.type === 'demo' ? 'D' : 'L'} </span>
                                                                            <p>{item.accountId} - USD {item.balance.toFixed(4)} - {item.leverage}</p>
                                                                        </div>
                                                                        <div className='d-flex'>
                                                                            {/* {console.log('new_check_provider',accountDetails)} */}
                                                                            {provider && <button className='btn btn-outline-secondary'>Provider</button>}
                                                                            {/* <button className='btn btn-outline-secondary'>DM</button> */}

                                                                            <Dropdown autoClose="outside" alignRight className='stardropdown'>
                                                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                                    <span className='fa fa-ellipsis-v ml-2 plus_common'></span>
                                                                                </Dropdown.Toggle>
                                                                                <Dropdown.Menu>
                                                                                    {provider ?
                                                                                        provider.status == "active" ?
                                                                                            <>
                                                                                                <Dropdown.Item onClick={() => { setAccountDetails(item); setconFirm(true) }} className='text-light'>Stop Providing Strategy</Dropdown.Item>
                                                                                                <Dropdown.Item onClick={() => { setEditData(provider); setEdit(true) }} className='text-light'>Strategy Settings</Dropdown.Item>
                                                                                                {/* <Dropdown.Item onClick={() => setViewInvestor(true)} className='text-light'>View Investor Link</Dropdown.Item>
                                                                                                <Dropdown.Item onClick={() => setDeleteInvestor(true)} className='text-light'>Delete Investor Link</Dropdown.Item> */}
                                                                                            </>
                                                                                            : <>
                                                                                                <Dropdown.Item onClick={() => { setAccountDetails(item); setconFirmed(true) }} className='text-light'>Restart Providing Strategy</Dropdown.Item>
                                                                                                <Dropdown.Item onClick={() => { setEditData(provider); setEdit(true) }} className='text-light'>Strategy Settings</Dropdown.Item>
                                                                                                {/* <Dropdown.Item onClick={() => setViewInvestor(true)} className='text-light'>View Investor Link</Dropdown.Item>
                                                                                                <Dropdown.Item onClick={() => setDeleteInvestor(true)} className='text-light'>Delete Investor Link</Dropdown.Item> */}
                                                                                            </>
                                                                                        :
                                                                                        <>
                                                                                            <Dropdown.Item onClick={() => { setAccountDetails(item); setStrategy(true) }} className='text-light'>Become a Strategy provider</Dropdown.Item>
                                                                                            {/* <Dropdown.Item onClick={() => setViewInvestor(true)} className='text-light'>create Investor Link</Dropdown.Item> */}
                                                                                        </>
                                                                                    }
                                                                                    {/* <Dropdown.Item onClick={() => setViewInvestor(true)} className='text-light'>View Investor Link</Dropdown.Item>
                                                                            <Dropdown.Item onClick={() => setDeleteInvestor(true)} className='text-light'>Delete Investor Link</Dropdown.Item> */}
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* sattic div */}
                                                                {followers && followers?.length > 0 ?
                                                                    followers.map((val, index) => {
                                                                        console.log('followersfolwerss_followersfolwerss', followers)
                                                                        return (
                                                                            <>
                                                                                <div className='acc-item w-full mb-1'>
                                                                                    <div className='d-flex jc-between w-full'>
                                                                                        <div className={val.status == 'active' ? "d-flex jc-between align-items-center w-full flex1 curser" : val.status == 'removedByProvider' ? "d-flex jc-between align-items-center w-full flex1 curser disabled" :
                                                                                            "d-flex jc-between align-items-center w-full flex1 curser"} onClick={() => history.push({ pathname: "/copyingAccount", state: { strategydetails: val?.trader[0], Followerdetails: val, accountId: val.followerAccountId } })}>
                                                                                            <div className='d-flex align-items-center' >
                                                                                                <span className={val.status == 'active' ? 'ml-2 px-1 text-green-dot plus_common' : val.status == 'removedByProvider' ? 'ml-2 px-1 text-red-dot plus_common' : 'ml-2 px-1 text-red-dot plus_common'}></span>
                                                                                                <p className='ml-3'>{val?.trader[0].strategyName}</p>
                                                                                            </div>
                                                                                            <div className='d-flex pricelearn'>
                                                                                                <p className='balance_white'>{val?.balanceInvestment}</p>
                                                                                                <p className='roi roi_minus'>{val?.returnOfInvestment?.toFixed(4)}</p>
                                                                                                {/* <p className='roi roi_plus'>+74.80%</p> */}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            <Dropdown autoClose="outside" alignRight className='stardropdown'>
                                                                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                                                    <span className='fa fa-ellipsis-v ml-2 plus_common'></span>
                                                                                                </Dropdown.Toggle>
                                                                                                <Dropdown.Menu className='max_he_ddrp_me'>
                                                                                                    {val.status == 'active' ?
                                                                                                        <>
                                                                                                            <Dropdown.Item
                                                                                                                onClick={() => {
                                                                                                                    let accountData = account.find((vals) => (vals.accountId == val.followerAccountId))
                                                                                                                    console.log(accountData, "accountData")
                                                                                                                    setcopiedAccount(accountData)
                                                                                                                    setAddFund(true);
                                                                                                                    setCopyingData(val?.trader[0]);
                                                                                                                }}
                                                                                                                className='text-light'>
                                                                                                                Add Funds
                                                                                                            </Dropdown.Item>
                                                                                                            <Dropdown.Item onClick={() => { setStartCopying(true); setCopyingData(val?.trader[0]) }} className='text-light'>Start Copying with another account</Dropdown.Item>
                                                                                                            <Dropdown.Item
                                                                                                                onClick={() => {
                                                                                                                    setRemovedData(val)
                                                                                                                    setRemoveFund(true);
                                                                                                                    let accountData = accounts.find((vals) => (vals.accountId == val.followerAccountId))
                                                                                                                    setremovedAccount(accountData)
                                                                                                                    // setCopyingData(val?.trader[0])
                                                                                                                }} className='text-light'>
                                                                                                                Remove Funds
                                                                                                            </Dropdown.Item>
                                                                                                            <Dropdown.Item onClick={() => { SetEquitystop(true); setEquityData(val) }} className='text-light'>Set Equity Stop Loss</Dropdown.Item>
                                                                                                            <Dropdown.Item
                                                                                                                onClick={() => {
                                                                                                                    setCopyingData(val?.trader[0]);
                                                                                                                    setStopId(item.accountId);
                                                                                                                    setStopCopying(true);
                                                                                                                    setBalanceInvestment(val.balanceInvestment);
                                                                                                                    setFollowerId(val.followerId)

                                                                                                                }}
                                                                                                                className='text-light'>
                                                                                                                Stop Copying
                                                                                                            </Dropdown.Item>
                                                                                                            <Dropdown.Item
                                                                                                                className='text-light'
                                                                                                                onClick={() => history.push({ pathname: "/strategy", state: { strategydetails: val?.trader[0] } })}
                                                                                                            >
                                                                                                                Open Strategy Page
                                                                                                            </Dropdown.Item>
                                                                                                            {/* <Dropdown.Item onClick={() => setViewInvestor(true)} className='text-light'>Create Investor Link</Dropdown.Item> */}
                                                                                                            {/* <Dropdown.Item href="#/action-2" className='text-light'>Email Statements</Dropdown.Item> */}
                                                                                                        </>
                                                                                                        :
                                                                                                        val.status == 'removedByProvider' ?
                                                                                                            <>
                                                                                                                <Dropdown.Item onClick={() => {
                                                                                                                    let accountData = account.find((vals) => (vals.accountId == val.followerAccountId))
                                                                                                                    console.log(accountData, "accountData")
                                                                                                                    setrestartAccount(accountData)
                                                                                                                    setCopyingData(val?.trader[0]);
                                                                                                                    setCopyTradeFollowID(val?._id); setRestartCopying(true)
                                                                                                                }}
                                                                                                                    className='text-light disabled'
                                                                                                                >
                                                                                                                    Restart Copying
                                                                                                                </Dropdown.Item>
                                                                                                                <Dropdown.Item className='text-light disabled' onClick={() => history.push({ pathname: "/strategy", state: { strategydetails: val?.trader[0] } })}> Open Strategy Page</Dropdown.Item>
                                                                                                                {/* <Dropdown.Item onClick={() => setViewInvestor(true)} className='text-light'>Create Investor Link</Dropdown.Item> */}
                                                                                                                {/* <Dropdown.Item href="#/action-2" className='text-light'>Email Statements</Dropdown.Item> */}
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                <Dropdown.Item onClick={() => {
                                                                                                                    setCopyingData(val?.trader[0]);
                                                                                                                    let accountData = account.find((vals) => (vals.accountId == val.followerAccountId))
                                                                                                                    console.log(accountData, "accountData")
                                                                                                                    setrestartAccount(accountData)
                                                                                                                    setCopyTradeFollowID(val?._id);
                                                                                                                    setRestartCopying(true)
                                                                                                                }}
                                                                                                                    className='text-light'
                                                                                                                >
                                                                                                                    Restart Copying
                                                                                                                </Dropdown.Item>
                                                                                                                <Dropdown.Item onClick={() => { setStartCopying(true); setCopyingData(val?.trader[0]) }} className='text-light'>Start Copying with another account</Dropdown.Item>
                                                                                                                <Dropdown.Item
                                                                                                                    className='text-light'
                                                                                                                    onClick={() => history.push({ pathname: "/strategy", state: { strategydetails: val?.trader[0] } })}
                                                                                                                >
                                                                                                                    Open Strategy Page
                                                                                                                </Dropdown.Item>
                                                                                                                {/* <Dropdown.Item onClick={() => setViewInvestor(true)} className='text-light'>Create Investor Link</Dropdown.Item> */}
                                                                                                                {/* <Dropdown.Item href="#/action-2" className='text-light'>Email Statements</Dropdown.Item> */}
                                                                                                            </>
                                                                                                    }

                                                                                                </Dropdown.Menu>
                                                                                            </Dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    }) : ''}
                                                            </>
                                                        )
                                                    })}
                                                    {/* <div className='acc-item w-full mb-1'>
                                                        <div className="d-flex jc-between align-items-center w-full ">
                                                            <div className='d-flex align-items-center gap-1 curser flex1' onClick={() => history.push("/providingstrategy")}>
                                                                <span className='ml-2 px-1 text-green plus_common'>L </span>

                                                                <p>1002172 - USD 0.00 - 1:200 - XTE</p>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <button className='btn btn-outline-secondary'>DM</button>

                                                                <Dropdown autoClose="outside" alignRight className='stardropdown'>
                                                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                        <span className='fa fa-ellipsis-v ml-2 plus_common'></span>
                                                                    </Dropdown.Toggle>

                                                                    <Dropdown.Menu>

                                                                        <Dropdown.Item onClick={() => setStrategy(true)} className='text-light'>Become a Strategy provider</Dropdown.Item>
                                                                        <Dropdown.Item onClick={() => setViewInvestor(true)} className='text-light'>View Investor Link</Dropdown.Item>
                                                                        <Dropdown.Item onClick={() => setDeleteInvestor(true)} className='text-light'>Delete Investor Link</Dropdown.Item>

                                                                    </Dropdown.Menu>
                                                                </Dropdown>


                                                            </div>
                                                        </div>

                                                    </div> */}
                                                    {/* <div className='acc-item w-full mb-1'>
                                                        <div className='d-flex jc-between w-full'>
                                                            <div className="d-flex jc-between align-items-center w-full flex1 curser" onClick={() => history.push("/copyingAccount")}>
                                                                <div className='d-flex align-items-center' >
                                                                    <span className='ml-2 px-1 text-green-dot plus_common'></span>

                                                                    <p className='ml-3'>learn</p>
                                                                </div>
                                                                <div className='d-flex pricelearn'>
                                                                    <p className='balance'>112.90</p>
                                                                    <p className='roi'>+12.90%</p>




                                                                </div>
                                                            </div>
                                                            <div>
                                                                <Dropdown autoClose="outside" alignRight className='stardropdown'>
                                                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                        <span className='fa fa-ellipsis-v ml-2 plus_common'></span>
                                                                    </Dropdown.Toggle>

                                                                    <Dropdown.Menu className='max_he_ddrp_me'>

                                                                        <Dropdown.Item onClick={() => setAddFund(true)} className='text-light'>Add Funds</Dropdown.Item>
                                                                        <Dropdown.Item onClick={() => setRemoveFund(true)} className='text-light'>Remove Funds</Dropdown.Item>
                                                                        <Dropdown.Item onClick={() => SetEquitystop(true)} className='text-light'>Set Equity Stop Loss</Dropdown.Item>
                                                                        <Dropdown.Item onClick={() => setStopCopying(true)} className='text-light'>Stop Copying</Dropdown.Item>
                                                                        <Dropdown.Item href="#/action-2" className='text-light'>Open Strategy Page</Dropdown.Item>
                                                                        <Dropdown.Item onClick={() => setViewInvestor(true)} className='text-light'>View Investor Link</Dropdown.Item>
                                                                        <Dropdown.Item onClick={() => setDeleteInvestor(true)} className='text-light'>Delete Investor Link</Dropdown.Item>
                                                                        <Dropdown.Item href="#/action-2" className='text-light'>Email Statements</Dropdown.Item>

                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </div>

                                                    </div> */}
                                                    {/* {[...Array(copydata.length)].map((i, index) => {

                                                        return
                                                        //  <div className='acc-item w-full mb-1'>
                                                        //     <div className="d-flex jc-between align-items-center w-full">
                                                        //         <div className='d-flex align-items-center gap-1 curser flex1' onClick={() => history.push("/strategy")}>
                                                        //             <span className='ml-2 px-1 text-danger plus_common'> D</span>
                                                        //             <p>1002172 - USD 0.00 - 1:200 - XTE</p>
                                                        //         </div>
                                                        //         <div className='d-flex'>
                                                        //             <button className='btn btn-outline-secondary'>DM</button>
                                                        //             <Dropdown className='stardropdown'>
                                                        //                 <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                        //                     <div className='starfont'>
                                                        //                         <span className='fa fa-ellipsis-v ml-2 plus_common'></span>

                                                        //                     </div>
                                                        //                 </Dropdown.Toggle>

                                                        //                 <Dropdown.Menu>
                                                        //                     <Dropdown.Item href="#/action-1" className='text-light'>Become a Strategy provider</Dropdown.Item>
                                                        //                     <Dropdown.Item onClick={() => setViewInvestor(true)} className='text-light'>View Investor Link</Dropdown.Item>
                                                        //                     <Dropdown.Item onClick={() => setDeleteInvestor(true)} className='text-light'>Delete Investor Link</Dropdown.Item>
                                                        //                 </Dropdown.Menu>
                                                        //             </Dropdown>
                                                        //         </div>
                                                        //     </div>

                                                        // </div>
                                                    })} */}
                                                    {liveCount == 5 && demoCount == 5 ? "" :
                                                        <button className='watchlist' onClick={() => setCopyTrade(true)}>+ Open Live Account</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>




                <div className={flatnav ? 'bottom-nav flatnav' : 'bottom-nav'}>
                    <button className='btn btn-variant navbtn' onClick={() => setFlatNav(!flatnav)}><span className={flatnav ? "fa fa-chevron-up" : 'fa fa-chevron-down'}></span></button>
                    <ul className='pl-0 mb-0'>
                        <li> <NavLink to="/tradedashboard/deposit"><img src={deposit} id="deposit" className="smallicon" /><span className='ml-2'>Deposit</span> </NavLink></li>
                        <li> <NavLink to="/tradedashboard/withdraw"><img src={withdraw} className="smallicon" /><span className='ml-2'>Withdraw</span></NavLink> </li>
                        <li> <NavLink to="/tradedashboard/settings"><img src={setting} className="smallicon" /><span className='ml-2'>Settings</span></NavLink> </li>
                    </ul>
                </div>





            </div>
        </div>
    )
}

export default Sidebar
