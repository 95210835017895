// import package
import io from 'socket.io-client';

// import lib
import config from './index';
// import package
import jwt, { decode } from "jsonwebtoken";
import { toastAlert } from 'lib/toastAlert';
const socket = io(config.SOCKET_URL,{transports: ['polling']});

const createSocketUser = (userId) => {
    socket.emit('CREATEROOM', userId)
}

socket.on('userAsset', function (data) {
})
socket.on('disconnect', (reason) => {
  console.log(reason,'disconnect_reason',socket.room)
    //  alert('socket dissconnect')
    // if (reason === 'transport error') {
      // the disconnection was initiated by the server, you need to reconnect manually
      // alert('socket dissconnect2')
      toastAlert('error','Please reload the page')
      // let token =localStorage.getItem('jwtToken')
      // token = token.replace('Bearer ', '')
      // const decoded = jwt.verify(token, config.secretOrKey);
      // if (decoded) {
      //   createSocketUser(decoded._id)
      // }
      // socket.connect();
    //   socket.emit('CREATEROOM', userId)
    // }
    // else the socket will automatically try to reconnect
  });
// socketContext.socket.on('openOrder', (data) => {
//     console.log("----data--", data)
//     if (data.pairId == tradePair.pairId) {
//         // setPendingOrder(data.result)
//     }
// })

export {
    socket,
    createSocketUser
}