// import constant
import {
    SET_USER_ACCOUNT,
    UPDATE_USER_ACCOUNT,
    UPDATE_2FA_STATUS,
    SET_USER_ACCOUNTS,
    SET_EQUTIY_MARGIN
} from '../constant';

const initialValue = {
    isAuthenticated: false,
    useraccount:{}
}
//  console.log("initialValue----->>>",initialValue);
const account = (state = initialValue, action) => {
    switch (action.type) {
        case SET_USER_ACCOUNT:
            return {
                ...state,
                ...action.data
            };
        case UPDATE_USER_ACCOUNT:
            return {
                ...state,
                ...action.data
            };
        case UPDATE_2FA_STATUS:
            return {
                ...state,
                twoFAStatus: action.data
            };
        case SET_USER_ACCOUNTS:{
            return{
                ...state,
                useraccount:action.data
            }
        }
        case SET_EQUTIY_MARGIN:{
            return{
                ...state,
                equtity:action.data
            }
        }
        default:
            return state;
    }
}

export default account;