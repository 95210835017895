import axios,{  handleResp} from '../config/axios';
import { setUserSetting } from './users';
// import constant
import {
    SET_CATEGORY_PAIR
} from '../constant';
export const getstocksymbolaction = async(data)=>{
    try{
        // console.log('call--socketsymbol',Date.now())
        let respData = await axios({
            'method':'get',
            'url': `/api/ccctrl/stocksymbols`,
            // params : data,
            
        });
        // console.log('res--socketsymbol',Date.now())
        return{
            success:respData.data.success,
            result:respData.data.result,
        }
        }
        catch(err)
        {
            console.log("error" , err);
            // handleResp(err, 'error')
            // return {
            //     status: 'failed',
            //     loading: false,
            // }
        }
}
export const setCategory = (data, dispatch) => {
    dispatch({
        type:SET_CATEGORY_PAIR ,
        data: data
    })
    return true
}

export const addwishlisthook = async(data)=>{
    try{console.log("result>>>>>>" , data);
        var formdata = new FormData();
        if(data){
            formdata.append("name" , data?.name);
           data?.id && formdata.append("id" , data?.id);
          }
        let respData = await axios({
            'method':'post',
            'url': `/api/ccctrl/addwishlist`,
           
            // params : data,
            data 
        });
        return{
            status:"success",
            result:respData.data.result,
        }
        }
        catch(err)
        {
            console.log("error" , err);
            // handleResp(err, 'error')
            // return {
            //     status: 'failed',
            //     loading: false,
            // }
        }
}

export const removewishlisthook = async(data)=>{
    try{console.log("result>>>>>>" , data);
        var formdata = new FormData();
        if(data){
            formdata.append("name" , data?.name);
           data?.id && formdata.append("id" , data?.id);
          }
        let respData = await axios({
            'method':'post',
            'url': `/api/ccctrl/removewishlist`,
           
            // params : data,
            data 
        });
        return{
            status:"success",
            result:respData.data.result,
        }
        }
        catch(err)
        {
            console.log("error" , err);
            // handleResp(err, 'error')
            // return {
            //     status: 'failed',
            //     loading: false,
            // }
        }
}

export const getuserdatahook = async(data)=>{
    try{
        let respData = await axios({
            'method':'get',
            'url': `/api/ccctrl/getuserdata`,
            // params : data,
        });
        return{
            status:"success",
            result:respData.data.result,
        }
        }
        catch(err)
        {
            console.log("error" , err);
            // handleResp(err, 'error')
            // return {
            //     status: 'failed',
            //     loading: false,
            // }
        }
}

export const filtersymboldatahook = async(data)=>{
    try{console.log("result>>>>>>" , data);
        var formdata = new FormData();
        if(data){
            formdata.append("search" , data);
        //    data?.id && formdata.append("id" , data?.id);
          }
        let respData = await axios({
            'method':'post',
            'url': `/api/ccctrl/filtersymboldata`,
           
            // params : data,
            data : {search : data}
        });
        return{
            status:"success",
            result:respData.data.result,
        }
        }
        catch(err)
        {
            console.log("error" , err);
            // handleResp(err, 'error')
            // return {
            //     status: 'failed',
            //     loading: false,
            // }
        }
}

export const deletewishlisthook = async(data) => {
    try{console.log("result>>>>>>" , data);
        var formdata = new FormData();
        if(data){
            formdata.append("name" , data);
        //    data?.id && formdata.append("id" , data?.id);
          }
        let respData = await axios({
            'method':'post',
            'url': `/api/ccctrl/delete-wishlist`,
           
            // params : data,
            data : {search : data}
        });
        return{
            status:"success",
            result:respData.data.result,
        }
        }
        catch(err)
        {
            console.log("error" , err);
            // handleResp(err, 'error')
            // return {
            //     status: 'failed',
            //     loading: false,
            // }
        }
}