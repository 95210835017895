import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import clsx from 'classnames';

import { useSelector, useDispatch } from 'react-redux'

import ProgressBar from 'react-bootstrap/ProgressBar';

import { kycStatus, userStatus } from '../lib/displayStatus'

import { kycStatusupload } from '../lib/displayStatus'

import classnames from "classnames";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

import Upload from "components/SportTrade/newtrade/Modals/Upload";


import Kycvalidatemodal from "components/SportTrade/newtrade/Modals/Kycvalidatemodal";

import Checkboxvalidatemodal from "components/SportTrade/newtrade/Modals/Checkboxvalidatemodal";

import Kycvalidatemodal1 from "components/SportTrade/newtrade/Modals/Kycvalidatemodal1";

import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import { getkycDetails, getverifystatus,getSessionId } from "../actions/userKyc";
import isEmpty from "lib/isEmpty";
import europiancountries from '../constant/eu-country';
import moment from 'moment';


const UserKycDetail = (props) => {
  // console.log("kycUserData_kycUserData", props);
  const param = useParams()
  const kycStatus = useSelector(state => state.userKyc.Status);
  const { verifiedstatus, Synapsverify } = kycStatus
  const dispatch = useDispatch();
  // console.log(kycStatus, "state_redux");
  const accountData = useSelector(state => state.auth);
  const { userId } = accountData;
   console.log(accountData, "accountData_data");

  const email = useSelector(state => state.account.email);
  console.log( email, "state_state");

  const [upload, setUpload] = useState(false)
  const [kycvalidate, setKycvalidate] = useState(false)
  const [checkboxvalidate, setCheckboxvalidate] = useState(false)
  const [kycvalidate1, setKycvalidate1] = useState(false)
  const [viewupload, setViewupload] = useState(false)
  const [error, setError] = useState({})
  // const [verifiedstatus,setVerifiedstatus]= useState("")
  const [user, setUserId] = useState("")
  // const [statuschecking,setStatuschecking]= useState("individual")
  const [individual, setIndividual] = useState({
    firstname: "",
    lastname: "",
    birthplace: "",
    street: "",
    city: "",
    country: "",
    residencystreet: "",
    residencycity: "",
    residencycountry: "",
    currentOccupation: "",
    sourceOfFunds: "",
    othersourcesofincome: "",
    function: "",
    employerName: "",
    companyName: "",
    natureOfActivity: "",
    nameOfEntity: "",
    typeOfActivity: "",
    specifyActivity: "",
    monthlyIncome: "",
    businessRelationship: "",
    estimatedAnnualVolume: "",
    dateOfBirth: "",
    personalIdentifierCode: "",
    customSwitch1: false,
    chkbox1: false,
    chkbox2: false,
    chkbox3: false,
    chkbox4: false,
    chkbox5: false,
    chkbox6: false,
    chkbox7: false,
  })

  const [entity, setEntity] = useState({
    companyname: "",
    regno: "",
    regdate: "",
    registration: "",
    address: "",
    businesstype: "",
    fullname: "",
    personalIdNo: "",
    legalStreet: "",
    legalCity: "",
    legalCountry: "",
    companySourceOfFunds: "",
    othercompanySourceOfFunds: "",
    companyAnnualTurnover: "",
    companyBusinessRelationship: [],
    companyAnnualVolume: "",
    businessPartnerName: "",
    partnerCountry: "",
    businessActivity: "",
    customSwitch2: false,
    entrychkbox1: false,
    entrychkbox2: false,
    entrychkbox3: false,
    entrychkbox4: false,
    entrychkbox5: false,
    entrychkbox6: false,
    entrychkbox7: false,
    entrychkbox8: false,
    entrychkbox9: false,
    entrychkbox10: false,
    entrychkbox11: false,
  })

  // const [load,setload]=useState("passport")
  const { t, i18n } = useTranslation();

  // const {Aadhar,VoterId,passport}= load

  // redux-state
  const { idProof } = useSelector(state => state.userKyc);

  const [issuccess, setIssuccess] = useState(false);

  const [selectstate, setSelectstate] = useState();

  const [sessionId, setSessionId] = useState();
  const [kycon, setKycon] = useState(false);
  const [kycon1, setKycon1] = useState(false);
  const [checkboxon, setCheckboxon] = useState(false);
  const [type,setType]=useState('individual')

  useEffect(() => {
    window.scrollTo(0, 0);
    // let details = props.data.userId
    getStatus()
    // console.log("kycUserData_kycUserData", kycUserData);
    // setVerified(verifiedstatus)

  }, [])
  useEffect(() => {
    verifiedstatus && SwitchTab()
  }, [param, verifiedstatus])
  const SwitchTab = () => {
    if (param.id == 'individual' && (verifiedstatus == 'Not verified' || verifiedstatus == 'REJECTED')) {
      // document.getElementById('individual-verification-tab').click()
      console.log(verifiedstatus, 'verifiedstatus')
      setType('individual')
      document.getElementById("individual-verification-tab").click()
    } else if (param.id == 'entity' && (verifiedstatus == 'Not verified' || verifiedstatus == 'REJECTED')) {
      setType('entity')
      document.getElementById("entry-verification-tab").click()
    }
  }
  const getStatus = async () => {
    await getverifystatus(dispatch);
  }

  const options1 = [

    'Employee',
    'Retiree',
    'Partner/Shareholder/Director',
    'Independent Professional',
    'Self-employed',
    'Student',
    'Unemployed',
    'Homemaker',
    'Other'

  ];

  const options2 = [

    'Salaries and other salary entitlements (awards, bonuses, etc.)',
    'Pension',
    'Business income/dividends',
    'Savings/interest',
    'Loans and credits',
    'Sale/rent of movable or immovable property/sale of shares;',
    'Sources of social protection (allowance, scholarship, unemployment, other social benefits, etc.)',
    'Inheritances, donations, gifts',
    'Investments (capital market, digital assets, etc.)',
    'Mining',
    'Other sources of income.Please give details:'

  ];

  const options3 = [
    'under 1.000 EURO',
    'between 1.001 EURO and 2.000 EURO',
    'between 2.001 EURO and 5.000 EURO',
    'more than 5.001 EURO'
  ];

  const options4 = [

    'Trading digital assets',
    'Transactions (receipts/transfers) of own digital assets',
    'Transactions (receipts/transfers) of digital assets from/to third parties',
    'Copy trading',
    'Saving/stocking of digital assets (savings)'

  ];

  const options5 = [
    // 'under 1.000 EURO',
    // 'between 1.001 EURO and 5.000 EURO',
    // 'between 5.001 EURO and 10.000 EURO',
    // 'between 10.000 EURO and 50.000 EURO',
    // 'over 50.001 EURO'
    'below 10,000 EURO',
    'between 10,001 EURO and 50,000 EURO',
    'over 50,001 EURO'

  ];


  const options6 = [
    // 'Current activity',
    // 'Sale of assets',
    // 'Financing of the company by the shareholders',
    // 'Other sources of income. Please provide details:'
    'Art/Architecture/Design',
    'Automotive / Transportation',
    'Education / Consulting / Training',
    'Finance / Banking / Financial Markets',
    'Food / Retail / Service',
    'Government',
    'Health / Medical',
    'Law / Legal',
    'Media / Entertainment',
    'Technology / Electronics / IT services',
    'Weapon manufacturers',
    'Arms Trade and Defense',
    'Art and antique dealers',
    'Unregulated charities',
    'Construction',
    'Unregulated "non-for-profit" organizations',
    'Electronic gambling /gaming through the internet',
    'Dealers in high value or precious goods',
    'Remittance housess',
    'Exchange houses',
    'Betting',
    'Gambling related',
    'Bank note traders',
    'Cash intensive business',
    'Pharmaceutical',
    'Adult industry'
  ];

  const options7 = [
    // 'below 10,000 EURO',
    // 'between 10,001 EURO and 50,000 EURO',
    // 'between 50,001 EURO and 100,000 EURO',
    // 'between 100,001 EURO and 500,000 EURO',
    // 'over 500,001 EURO'
    'Current activity',
    'Sale of assets',
    'Financing of the company by the shareholders',
    'Other sources of income. Please provide details:'
  ];


  const options8 = [
    // 'Trading digital assets',
    // 'Operations (receipts/transfers) of own digital assets',
    // 'Operations (receipts/transfers) of third-party digital assets',
    // 'Copy trading',
    // 'Saving/storing digital assets (savings)'
    'below 10,000 EURO',
    'between 10,001 EURO and 50,000 EURO',
    'between 50,001 EURO and 100,000 EURO',
    'between 100,001 EURO and 500,000 EURO',
    'over 500,001 EURO'

  ];


  const options9 = [
    // 'below 10,000 EURO',
    // 'between 10,001 EURO and 50,000 EURO',
    // 'over 50,001 EURO'
    'Trading digital assets',
    'Transactions (receipts/transfers) of own digital assets',
    'Transactions (receipts/transfers) of digital assets from/to third parties',
    'Copy trading',
    'Saving/stocking of digital assets (savings)'
  ];


  const options10 = [
    'below 10,000 EURO',
    'between 10,001 EURO and 50,000 EURO',
    'over 50,001 EURO'
  ];

  const options11 = [
    'Art/Architecture/Design',
    'Automotive / Transportation',
    'Education / Consulting / Training',
    'Finance / Banking / Financial Markets',
    'Food / Retail / Service',
    'Government',
    'Health / Medical',
    'Law / Legal',
    'Media / Entertainment',
    'Technology / Electronics / IT services',
    'Weapon manufacturers',
    'Arms Trade and Defense',
    'Art and antique dealers',
    'Unregulated charities',
    'Construction',
    'Unregulated "non-for-profit" organizations',
    'Electronic gambling /gaming through the internet',
    'Dealers in high value or precious goods',
    'Remittance housess',
    'Exchange houses',
    'Betting',
    'Gambling related',
    'Bank note traders',
    'Cash intensive business',
    'Pharmaceutical',
    'Adult industry'
  ];
  //  setVerifiedstatus(kycStatus.verifiedstatus)

  const showKycmodal = (val) => {
    setKycon(val);
    if (kycon == false) {
      setKycvalidate(true)
      getData()

    }
  }

  const showKycmodal1 = (val) => {
    setKycon1(val)
    if (kycon1 == false) {
      setKycvalidate1(true)
      getData()
    }
  }


  const showCheckboxmodal = (val) => {
    setCheckboxon(val)
    if (checkboxon == false) {
      setCheckboxvalidate(true)
      console.log("checkbox", setCheckboxvalidate)
    }
  }



  var onChangefn = (e) => {
    const { id, value } = e.target;
    console.log("target", e.target.value, id);
    let KYCData = { ...individual, ...{ [id]: value } };
    console.log("KYCData_KYCData", KYCData);
    setIndividual(KYCData);
    setError({ ...error, ...{ [id]: "" } })
  };

  var onChangeentity = (e) => {
    const { id, value } = e.target;
    console.log("target", e.target);
    let KYCentity = { ...entity, ...{ [id]: value } };
    console.log("KYCentity_KYCentity", KYCentity);
    setEntity(KYCentity);
    setError({ ...error, ...{ [id]: "" } })
  };


  var getData = async() => {
    // const id = userId;
    const res = await getSessionId(type)
    console.log('getkycsession',res.data)
    setSessionId(res.data)
    // axios.get("api/getkycsession").then((res) => {
    //   console.log("getkycsession : ", res?.data)
    //   setSessionId(res?.data?.data)
    // })
  }



  var validations = (data) => {

    //  e.preventDefault()
    let errors = {}
    let regex = /^[A-Za-z\s]*$/
    let regex_no = /^[ A-Za-z0-9_@./#&+-]*$/
    let num_let_com = /^[ A-Za-z0-9,_@./#&+-]*$/

    let checkStatus = false
    if (data === "individual") {
      if (individual.firstname == '' || individual.firstname == null || individual.firstname == undefined) {
        errors['firstname'] = "First Name field is required"
      }
      else if (!regex.test(individual.firstname)) {
        console.log("regex", !individual.firstname !== /^[A-Za-z\s]*$/)
        errors['firstname'] = "First Name should contain only alphabets"
      }


      if (individual.lastname == '' || individual.lastname == null || individual.lastname == undefined) {
        errors['lastname'] = "Last Name field is required"
      }
      else if (!regex.test(individual.lastname)) {
        errors['lastname'] = "Last Name should contain only alphabets"
      }

      if (individual.personalIdentifierCode == '' || individual.personalIdentifierCode == null || individual.personalIdentifierCode == undefined) {
        errors['personalIdentifierCode'] = "Personal Identifier Code field is required"
      }
      else if (!regex_no.test(individual.personalIdentifierCode)) {
        errors['personalIdentifierCode'] = "Personal Identifier Code field not in a correct format"
      }

      if (individual.dateOfBirth == '' || individual.dateOfBirth == null || individual.dateOfBirth == undefined) {
        errors['dateOfBirth'] = "Date Of Birth field is required"
      }

      if (individual.birthplace == '' || individual.birthplace == null || individual.birthplace == undefined) {
        errors['birthplace'] = "Birth Place field is required"
      }
      else if (!regex.test(individual.birthplace)) {
        errors['birthplace'] = "Birth Place field not in a correct format"
      }

      if (individual.street == '' || individual.street == null || individual.street == undefined) {
        errors['street'] = "Address field is required"
      }
      else if (!num_let_com.test(individual.street)) {
        errors['street'] = "Address field not in a correct format"
      }

      if (individual.city == '' || individual.city == null || individual.city == undefined) {
        errors['city'] = "City field is required"
      }
      else if (!regex.test(individual.city)) {
        errors['city'] = "City field should contain only alphabets"
      }

      if (individual.country == '' || individual.country == null || individual.city == undefined) {
        errors['country'] = "Country field is required"
      }
      else if (!regex.test(individual.country)) {
        errors['country'] = "Country field should contain only alphabets"
      }

      if (individual.residencycity == '' || individual.residencycity == null || individual.residencycity == undefined) {
        errors['residencycity'] = "Residency City field is required"
      }
      else if (!regex.test(individual.residencycity)) {
        errors['residencycity'] = "Residency City field should contain only alphabets"
      }

      if (individual.residencycountry == '' || individual.residencycountry == null || individual.residencycountry == undefined) {
        errors['residencycountry'] = "Residency Country field is required"
      }
      else if (!regex.test(individual.residencycountry)) {
        errors['residencycountry'] = "Residency Country field should contain only alphabets"
      }

      if (individual.residencystreet == '' || individual.residencystreet == null || individual.residencystreet == undefined) {
        errors['residencystreet'] = "Residency Street field is required"
      }
      else if (!num_let_com.test(individual.residencystreet)) {
        errors['residencystreet'] = "Residency Street not in a correct format"
      }

      if (individual.currentOccupation == '' || individual.currentOccupation == null || individual.currentOccupation == undefined) {
        errors['currentOccupation'] = "Current Occupation field is required"
      }

      if (individual.currentOccupation === "Employee" && individual.function == '' || individual.function == null || individual.function == undefined) {
        errors['function'] = "Function field is required"
      }
      else if (individual.currentOccupation === "Employee" && !regex.test(individual.function)) {
        errors['function'] = "Function field should contain only alphabets"
      }

      if (individual.currentOccupation === "Employee" && individual.employerName == '' || individual.employerName == null || individual.employerName == undefined) {
        errors['employerName'] = "Employer Name field is required"
      }
      else if (individual.currentOccupation === "Employee" && !regex.test(individual.employerName)) {
        errors['employerName'] = "Employer Name field should contain only alphabets"
      }

      if (individual.currentOccupation === "Partner/Shareholder/Director" && individual.companyName == '' || individual.companyName == null || individual.companyName == undefined) {
        errors['companyName'] = "Company Name field is required"
      }
      else if (individual.currentOccupation === "Partner/Shareholder/Director" && !regex.test(individual.companyName)) {
        errors['companyName'] = "Company Name field should contain only alphabets"
      }

      if (individual.currentOccupation === "Partner/Shareholder/Director" && individual.natureOfActivity == '' || individual.natureOfActivity == null || individual.natureOfActivity == undefined) {
        errors['natureOfActivity'] = "Nature Of Activity field is required"
      }
      else if (individual.currentOccupation === "Partner/Shareholder/Director" && !regex.test(individual.natureOfActivity)) {
        errors['natureOfActivity'] = "Nature Of Activity field should contain only alphabets"
      }

      if (individual.currentOccupation === "Independent Professional" && individual.nameOfEntity == '' || individual.nameOfEntity == null || individual.nameOfEntity == undefined) {
        errors['nameOfEntity'] = "Name Of Entity field is required"
      }
      else if (individual.currentOccupation === "Independent Professional" && !regex.test(individual.nameOfEntity)) {
        errors['nameOfEntity'] = "Name Of Entity field should contain only alphabets"
      }

      if (individual.currentOccupation === "Self-employed" && individual.typeOfActivity == '' || individual.typeOfActivity == null || individual.typeOfActivity == undefined) {
        errors['typeOfActivity'] = "Type Of Activity field is required"
      }
      else if (individual.currentOccupation === "Self-employed" && !regex.test(individual.typeOfActivity)) {
        errors['typeOfActivity'] = "Type Of Activityfield should contain only alphabets"
      }

      if (individual.currentOccupation === "Other" && individual.specifyActivity == '' || individual.specifyActivity == null || individual.specifyActivity == undefined) {
        errors['specifyActivity'] = "Specify Activity field is required"
      }
      else if (individual.currentOccupation === "Other" && !regex.test(individual.specifyActivity)) {
        errors['specifyActivity'] = "Specify Activityfield should contain only alphabets"
      }

      if (individual.sourceOfFunds == '' || individual.sourceOfFunds == null || individual.sourceOfFunds == undefined) {
        errors['sourceOfFunds'] = "Source Of Funds field is required"
      }

      if (individual.sourceOfFunds === 'Other sources of income.Please give details:' && individual.othersourcesofincome == "" || individual.othersourcesofincome == null || individual.othersourcesofincome == undefined) {
        errors['othersourcesofincome'] = "Other sources of income is required"
      }
      else if (individual.sourceOfFunds === "Other sources of income.Please give details:" && !regex.test(individual.othersourcesofincome)) {
        errors['othersourcesofincome'] = "Other sources of income should contain only alphabets"
      }

      if (individual.monthlyIncome === '' || individual.monthlyIncome == null || individual.monthlyIncome == undefined) {
        errors['monthlyIncome'] = "Monthly Income field is required"
      }
      if (individual.businessRelationship == '' || individual.businessRelationship == null || individual.businessRelationship == undefined) {
        errors['businessRelationship'] = "Business Relationship field is required"
      }
      if (individual.estimatedAnnualVolume === '' || individual.estimatedAnnualVolume === null || individual.estimatedAnnualVolume === undefined) {
        errors['estimatedAnnualVolume'] = "Estimated Annual Volume field is required"
      }
      if (individual.customSwitch1 === false) {
        errors['customSwitch1'] = "SYNAPS required"
      }
      if (individual.chkbox1 === false || individual.chkbox2 === false || individual.chkbox3 === false || individual.chkbox4 === false || individual.chkbox5 === false || individual.chkbox6 === false || individual.chkbox7 === false) {
        console.log(checkboxon, "checkboxon check")
        showCheckboxmodal(true)
        checkStatus = true
      }
    }
    else {
      if (entity.companyname === '' || entity.companyname === null || entity.companyname === undefined) {
        errors['companyname'] = "Company Name field is required"
      }
      else if (!regex.test(entity.companyname)) {
        errors['companyname'] = "Company Name field should contain only alphabets"
      }

      if (entity.regno === '' || entity.regno === null || entity.regno === undefined) {
        errors['regno'] = "Registration Number field is required"
      }
      else if (!regex_no.test(entity.regno)) {
        errors['regno'] = "Registration Number field not in a correct format"
      }

      if (entity.regdate === '' || entity.regdate === null || entity.regdate === undefined) {
        errors['regdate'] = "Registration Date field is required"
      }

      if (entity.registration === '' || entity.registration === null || entity.registration === undefined) {
        errors['registration'] = "Registration field is required"
      }
      else if (!regex_no.test(entity.registration)) {
        errors['registration'] = "Registration field not in a correct format"
      }

      if (entity.address === '' || entity.address === null || entity.address === undefined) {
        errors['address'] = "Company Address field is required"
      }
      else if (!num_let_com.test(entity.address)) {
        errors['address'] = "Company Address field not in a correct format"
      }

      if (entity.businesstype === '' || entity.businesstype === null || entity.businesstype === undefined) {
        errors['businesstype'] = "Business type field is required"
      }

      if (entity.fullname === '' || entity.fullname === null || entity.fullname === undefined) {
        errors['fullname'] = "Full Name field is required"
      }
      else if (!regex.test(entity.fullname)) {
        errors['fullname'] = "Full Name field not in a correct format"
      }

      if (entity.personalIdNo === '' || entity.personalIdNo === null || entity.personalIdNo === undefined) {
        errors['personalIdNo'] = "Personal ID No field is required"
      }
      else if (!regex_no.test(entity.personalIdNo)) {
        errors['personalIdNo'] = "personal Id No field not in a correct format"
      }

      if (entity.legalStreet === '' || entity.legalStreet === null || entity.legalStreet === undefined) {
        errors['legalStreet'] = "Address field is required"
      }
      else if (!num_let_com.test(entity.legalStreet)) {
        errors['legalStreet'] = "Address field not in a correct format"
      }

      if (entity.legalCity === '' || entity.legalCity === null || entity.legalCity === undefined) {
        errors['legalCity'] = "City field is required"
      }
      else if (!regex.test(entity.legalCity)) {
        errors['legalCity'] = "City field not in a correct format"
      }

      if (entity.legalCountry === '' || entity.legalCountry === null || entity.legalCountry === undefined) {
        errors['legalCountry'] = "Country field is required"
      }
      else if (!regex.test(entity.legalCountry)) {
        errors['legalCountry'] = "Country field not in a correct format"
      }

      if (entity.companySourceOfFunds === '' || entity.companySourceOfFunds === null || entity.companySourceOfFunds === undefined) {
        errors['companySourceOfFunds'] = "Source Of Funds field is required"
      }

      if (entity.companySourceOfFunds === 'Other sources of income. Please provide details:' && entity.othercompanySourceOfFunds === '' || entity.othercompanySourceOfFunds === null || entity.othercompanySourceOfFunds === undefined) {
        errors['othercompanySourceOfFunds'] = "Other Source of income field is required"
      }
      else if (!regex.test(entity.othercompanySourceOfFunds)) {
        errors['othercompanySourceOfFunds'] = "Other Source of income field not in a correct format"
      }

      if (entity.companyAnnualTurnover === '' || entity.companyAnnualTurnover === null || entity.companyAnnualTurnover === undefined) {
        errors['companyAnnualTurnover'] = "Company Annual Turnover field is required"
      }
      if (entity.companyBusinessRelationship && entity.companyBusinessRelationship.length <= 0) {
        errors['companyBusinessRelationship'] = "Company Business Relationship field is required"
      }
      if (entity.companyAnnualVolume === '' || entity.companyAnnualVolume === null || entity.companyAnnualVolume === undefined) {
        errors['companyAnnualVolume'] = "Company Annual Volume field is required"
      }
      if (entity.businessPartnerName === '' || entity.businessPartnerName === null || entity.businessPartnerName === undefined) {
        errors['businessPartnerName'] = "Business Partner Name field is required"
      }
      else if (!regex.test(entity.businessPartnerName)) {
        errors['businessPartnerName'] = "Business Partner Name field not in a correct format"
      }

      if (entity.partnerCountry === '' || entity.partnerCountry === null || entity.partnerCountry === undefined) {
        errors['partnerCountry'] = "Partner Country field is required"
      }
      else if (!regex.test(entity.partnerCountry)) {
        errors['partnerCountry'] = "Partner Country field not in a correct format"
      }

      if (entity.businessActivity === '' || entity.businessActivity === null || entity.businessActivity === undefined) {
        errors['businessActivity'] = "Business Activity field is required"
      }

      if (entity.customSwitch2 === false) {
        errors['customSwitch2'] = "SYNAPS required"
      }
      if (entity.entrychkbox1 === false || entity.entrychkbox2 === false || entity.entrychkbox3 === false || entity.entrychkbox4 === false || entity.entrychkbox5 === false || entity.entrychkbox6 === false || entity.entrychkbox7 === false || entity.entrychkbox8 === false || entity.entrychkbox9 === false || entity.entrychkbox10 === false || entity.entrychkbox11 === false) {
        showCheckboxmodal(true)
        checkStatus = true

        errors['entry'] = "Proceed required"
      }

    }
    console.log("ERRORS", errors);
    setError(errors)
    window.scrollTo(0, 0);
    return { errors: errors, checkStatus: checkStatus }

  }

  const Verification = async (e, form) => {
    e.preventDefault()
    try {
      console.log("form", form)
      var val = await validations(form);
      console.log("val", val)
      if (isEmpty(val.errors) && !val.checkStatus) {
        var details;
        console.log("type", type)
        if (form === "individual") {
          details = {
            personalInformation: {
              firstname: individual.firstname,
              lastname: individual.lastname,
              dateOfBirth: individual.dateOfBirth,
              personalIdentifierCode: individual.personalIdentifierCode,
              birthplace: individual.birthplace,
              street: individual.street,
              city: individual.city,
              country: individual.country,
              residencystreet: individual.residencystreet,
              residencycity: individual.residencycity,
              residencycountry: individual.residencycountry,
            },
            financialInformation: {
              currentOccupation: individual.currentOccupation,
              sourceOfFunds: individual.sourceOfFunds,
              othersourcesofincome: individual.othersourcesofincome,
              function: individual.function,
              employerName: individual.employerName,
              companyName: individual.companyName,
              natureOfActivity: individual.natureOfActivity,
              nameOfEntity: individual.nameOfEntity,
              typeOfActivity: individual.typeOfActivity,
              specifyActivity: individual.specifyActivity,
              monthlyIncome: individual.monthlyIncome,
              businessRelationship: individual.businessRelationship,
              estimatedAnnualVolume: individual.estimatedAnnualVolume,

            },
            type: type,
            userId: userId,
            verifiedstatus: verifiedstatus,
            email:email

          }
        }
        else {
          console.log("entitychecking")
          details = {
            companyRegistrationDetails: {
              companyname: entity.companyname,
              regno: entity.regno,
              regdate: entity.regdate,
              registration: entity.registration,
              address: entity.address,
              businesstype: entity.businesstype,
            },
            LegalRepresentative: {
              fullname: entity.fullname,
              personalIdNo: entity.personalIdNo,
              legalStreet: entity.legalStreet,
              legalCity: entity.legalCity,
              legalCountry: entity.legalCountry,
            },
            companyFinancialDetails: {
              companySourceOfFunds: entity.companySourceOfFunds,
              othercompanySourceOfFunds: entity.othercompanySourceOfFunds,
              companyAnnualTurnover: entity.companyAnnualTurnover,
              companyBusinessRelationship: entity.companyBusinessRelationship,
              companyAnnualVolume: entity.companyAnnualVolume,
              businessPartnerName: entity.businessPartnerName,
              partnerCountry: entity.partnerCountry,
              businessActivity: entity.businessActivity
            },
            type: "entity",
            userId: userId,
            verifiedstatus: verifiedstatus,
            email:email
          }
        }

        console.log("The KYC should not be empty", error)

        console.log("ddd", details)
        getkycDetails(details, dispatch)

      } else {
        return false
      }
    }
    catch (err) {
      console.log("Catcherrr", error)
    }
  }
  const today = moment();
  const disableFutureDt = current => {
    return current.isBefore(today)
  }


  console.log("idProof", type);
  return (
    <div className='uploaddocument'>
      {upload && <Upload onDismiss={() => setUpload(false)} />}

      {kycon && <Kycvalidatemodal onDismiss={() => setKycon(false)} Id={sessionId} />}
      {checkboxon && <Checkboxvalidatemodal onDismiss={() => setCheckboxon(false)} />}
      {kycon1 && <Kycvalidatemodal1 onDismiss={() => setKycon1(false)} Id={sessionId} />}

      <div className='container custom-container1'>
        <div className="card text-white">

          <div className="card-body card_kyc_new">
          {verifiedstatus && verifiedstatus === "REJECTED" ?<p className="rejectedkyc_txt"><i class="fas fa-times-circle mr-2"/>Rejected</p> :'' }
            {/* {console.log(verifiedstatus && verifiedstatus === "REJECTED" || verifiedstatus === "Not verified", "efrcvertgegtrgtg", verifiedstatus)} */}
            {verifiedstatus && verifiedstatus === "REJECTED" || verifiedstatus === "Not verified" ?
              <>
                <ul class="nav nav-tabs primary_tab tab_bottom_border no_brder_kyc_ver" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation"  onClick={()=>{ console.log('Type','individual'); setType('individual')}}>
                    <a class="nav-link active" id="individual-verification-tab" data-toggle="pill" href="#individual-verification" role="tab" aria-controls="pills-ranking" aria-selected="true">Individual Verification</a>
                  </li>
                  <li class="nav-item" role="presentation" onClick={()=>{ console.log('Type','entity'); setType('entity')}}>
                    <a class="nav-link" id="entry-verification-tab" data-toggle="pill" href="#entry-verification" role="tab" aria-controls="pills-copytrade" aria-selected="false">Entity Verification</a>
                  </li>

                </ul>
                <div class="tab-content pb-3" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="individual-verification" role="tabpanel" aria-labelledby="individual-verification-tab">
                    <div className="py-4">

                      <form className="stoForm">
                        <p className="tab_ver_heading">Personal Information</p>
                        <p className="form_val_text">The data provided must be identical to those in the identification document</p>
                        <div className='row'>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <label>First Name</label>
                              <input id="firstname" type="text" className={classnames("form-control")} maxlength="30" onChange={(e) => onChangefn(e)} />
                              <span className="text-danger">{error.firstname}</span>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <label>Last Name</label>
                              <input id="lastname" type="text" className={classnames("form-control")} maxlength="30" onChange={(e) => onChangefn(e)} />
                              <span className="text-danger">{error.lastname}</span>
                            </div>
                          </div>

                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <label>Personal Identifier Code</label>
                              <input id="personalIdentifierCode" type="text" className={classnames("form-control")} maxlength="30" onChange={(e) => onChangefn(e)} />
                              <span className="text-danger">{error.personalIdentifierCode}</span>
                            </div>
                          </div>

                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                              <label for="exampleInputEmail1">Date Of Birth (DD/MM/YYYY)</label>


                              <Datetime
                                popperPlacement="top-end"
                                timeFormat={false}
                                input={true}
                                // isValidDate={valid}
                                closeOnSelect={true}
                                className="w-100 rdt_new rd_new_upload_date"
                                isValidDate={disableFutureDt}
                                inputProps={{onKeyDown: (e) => { e.preventDefault() } }}
                                onChange={(e) =>{
                                   let newDate= new Date(e._d)
                                  setIndividual({ ...individual, 'dateOfBirth': newDate.getTime() })
                                  console.log("event",e._d)
                                  }}
                              // timeFormat="HH:mm:ss"
                              // timeConstraints={{
                              //   hours: { min: new Date(Date.now()).getHours(), max: 23 },
                              // }}
                              // onChange={(value) => {
                              //   Auctionset(value);
                              // }}
                              />
                              {/* <Form.Control 
                                 type="date" 
                            

                                id="dateOfBirth" 
                                selected={individual.dateOfBirth}
                                placeholder="Enter Expiry"
                                className="example-custom-input input_date_new_pick input_date_new_pick_kyc ml-0 ml-sm-2"
                                name="dob"
                                onChange={(date) => {
                                  let newDate = new Date(date)
                               
                                   setIndividual({ ...individual, 'dateOfBirth': newDate.getTime() })
                                 
                                }}
                               
                                dateFormat="MMMM d, yyyy "

                              
                                maxDate={new Date()}
                               
                              
                             
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                                 /> */}



                              {/* <DatePicker
                      popperClassName="some-custom-class mb-mob-ip datr_pick_dateonly"
                      className="example-custom-input"
                      popperPlacement="bottom-end"
                      id="dateOfBirth" 
                      selected={individual.dateOfBirth}
                     onChange={(date) => {
                       let newDate = new Date(date)
                        setIndividual({ ...individual, 'dateOfBirth': newDate.getTime() })
                     }}
                      dateFormat="MMMM d, yyyy"
                      maxDate={new Date()}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    /> */}
                              {/* <input type="date" class="form-control" id="dateOfBirth" aria-describedby="emailHelp" placeholder="Enter DOB" max={new Date().toISOString().substr(0, 10)} name="dob" onChange={(e) => onChangefn(e)} /> */}
                              <span className="text-danger">{error.dateOfBirth}</span>
                            </div>
                          </div>

                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <label>Birth Place</label>
                              <input id="birthplace" type="text" className={classnames("form-control")} maxlength="30" onChange={(e) => onChangefn(e)} />
                              <span className="text-danger">{error.birthplace}</span>
                            </div>
                          </div>

                        </div>
                        <p className="form_val_text">Address (address from ID document)</p>

                        <div className='row'>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <label>Address</label>
                              <input id="street" type="text" className={classnames("form-control")} maxlength="75" onChange={(e) => onChangefn(e)} />
                              <span className="text-danger">{error.street}</span>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <label>City</label>
                              <input id="city" type="text" className={classnames("form-control")} maxlength="30" onChange={(e) => onChangefn(e)} />
                              <span className="text-danger">{error.city}</span>
                            </div>
                          </div>

                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <label>Country</label>
                              <input id="country" type="text" className={classnames("form-control")} maxlength="30" onChange={(e) => onChangefn(e)} />
                              <span className="text-danger">{error.country}</span>
                            </div>
                          </div>

                        </div>

                        <p className="form_val_text">Residency Address</p>

                        <div className='row'>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <label>Address</label>
                              <input id="residencystreet" type="text" className={classnames("form-control")} maxlength="75" onChange={(e) => onChangefn(e)} />
                              <span className="text-danger">{error.residencystreet}</span>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <label>City</label>
                              <input id="residencycity" type="text" className={classnames("form-control")} maxlength="30" onChange={(e) => onChangefn(e)} />
                              <span className="text-danger">{error.residencycity}</span>
                            </div>
                          </div>

                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <label>Country</label>
                              <input id="residencycountry" type="text" className={classnames("form-control")} maxlength="30" onChange={(e) => onChangefn(e)} />
                              <span className="text-danger">{error.residencycountry}</span>
                            </div>
                          </div>

                        </div>


                        <p className="tab_ver_heading">Financial Information</p>
                        <div className='row'>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <label>Current Occupation<span className="style_span_grey pl-1">(Please select the closest option)</span></label>
                              <select id="currentOccupation" className="darkselect selectcount dark_selc_kyc ml-1" onChange={(e) => onChangefn(e)} >
                                <option hidden>Select Current Occupation</option>
                                {options1.map((item, key) => {

                                  return <option value={item}>{item}</option>

                                })}


                              </select>
                              {/* <input id="currentOccupation" type="text" className={classnames("form-control")} onChange={(e) => { this.setState({ currentOccupation: e.target.value }) }} /> */}
                              <span className="text-danger">{error.currentOccupation}</span>
                            </div>

                            {individual.currentOccupation && individual.currentOccupation === "Employee" ? <>
                              {/* employer */}
                              <div className="form-group">
                                <label>Function</label>
                                <input id="function" type="text" className={classnames("form-control")} maxlength="30" onChange={(e) => onChangefn(e)} />
                                <span className="text-danger">{error.function}</span>
                              </div>

                              <div className="form-group">
                                <label>Employer Name</label>
                                <input id="employerName" type="text" className={classnames("form-control")} maxlength="30" onChange={(e) => onChangefn(e)} />
                                <span className="text-danger">{error.employerName}</span>
                              </div>
                              {/* end employer */}
                            </>

                              : ""}

                            {individual.currentOccupation === "Partner/Shareholder/Director" ? <>
                              {/* Associate/shareholder/director */}
                              <>
                                <div className="form-group">
                                  <label>Company Name</label>
                                  <input id="companyName" type="text" className={classnames("form-control")} maxlength="30" onChange={(e) => onChangefn(e)} />
                                  <span className="text-danger">{error.companyName}</span>
                                </div>

                                <div className="form-group">
                                  <label>Nature of Activity</label>
                                  <input id="natureOfActivity" type="text" className={classnames("form-control")} maxlength="30" onChange={(e) => onChangefn(e)} />
                                  <span className="text-danger">{error.natureOfActivity}</span>
                                </div>
                              </>
                              {/* end Associate/shareholder/director */}
                            </> : ""}

                            {individual.currentOccupation === "Independent Professional" ? <>
                              {/* Liberal profession */}
                              <>
                                <div className="form-group">
                                  <label>Name of Entity</label>
                                  <input id="nameOfEntity" type="text" className={classnames("form-control")} maxlength="30" onChange={(e) => onChangefn(e)} />
                                  <span className="text-danger">{error.nameOfEntity}</span>
                                </div>

                              </>
                              {/* end Liberal profession */}
                            </> : ""}

                            {individual.currentOccupation === "Self-employed" ?
                              <>

                                {/* Self-employed */}
                                <>
                                  <div className="form-group">
                                    <label>Type of Activity</label>
                                    <input id="typeOfActivity" type="text" className={classnames("form-control")} maxlength="30" onChange={(e) => onChangefn(e)} />
                                    <span className="text-danger">{error.typeOfActivity}</span>
                                  </div>

                                </>
                                {/* end Self-employed */}
                              </> : ""}

                            {individual.currentOccupation === "Other" ?
                              <>

                                {/* Other */}
                                <>
                                  <div className="form-group">
                                    <label>Specify Activity</label>
                                    <input id="specifyActivity" type="text" className={classnames("form-control")} maxlength="30" onChange={(e) => onChangefn(e)} />
                                    <span className="text-danger">{error.specifyActivity}</span>
                                  </div>

                                </>
                                {/* end Other */}
                              </> : ""}



                            {/* employer */}
                            {/* <>
                              <div className="form-group">
                                <label>Function</label>
                                <input id="Function" type="text" className={classnames("form-control")} />
                              </div>

                              <div className="form-group">
                                <label>Employer Name</label>
                                <input id="employername" type="text" className={classnames("form-control")} />
                              </div>
                            </> */}
                            {/* end employer */}

                            {/* Associate/shareholder/director */}
                            {/* <>
                              <div className="form-group">
                                <label>Company Name</label>
                                <input id="companyname" type="text" className={classnames("form-control")} />
                              </div>

                              <div className="form-group">
                                <label>Nature of Activity</label>
                                <input id="nature" type="text" className={classnames("form-control")} />
                              </div>
                            </> */}
                            {/* end Associate/shareholder/director */}

                            {/* Liberal profession */}
                            {/* <>
                              <div className="form-group">
                                <label>Name of Entity</label>
                                <input id="entityname" type="text" className={classnames("form-control")} />
                              </div>

                            </> */}
                            {/* end Liberal profession */}

                            {/* Self-employed */}
                            {/* <>
                              <div className="form-group">
                                <label>Type of Activity</label>
                                <input id="activity" type="text" className={classnames("form-control")} />
                              </div>

                            </> */}
                            {/* end Self-employed */}

                            {/* Other */}
                            {/* <>
                              <div className="form-group">
                                <label>Specify Activity</label>
                                <input id="specifyactivity" type="text" className={classnames("form-control")} />
                              </div>

                            </> */}
                            {/* end Other */}


                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <label>Source of Funds<span className="style_span_grey pl-1">(Please select the closest option)</span>
                                {/* <span className="style_span_grey pl-1 invisible">(Please select the closest option)</span>
              </label> */}
                              </label>
                              <select id="sourceOfFunds" className="darkselect selectcount dark_selc_kyc ml-1" onChange={(e) => onChangefn(e)} >
                                <option hidden>Select Source of Funds</option>
                                {options2.map((item, key) => {

                                  return <option value={item}>{item}</option>

                                })}



                              </select>
                              {/* <input id="sourceOfFunds" type="text" className={classnames("form-control")} onChange={(e) => { this.setState({ sourceOfFunds: e.target.value }) }} /> */}
                              <span className="text-danger">{error.sourceOfFunds}</span>

                              {individual.sourceOfFunds === "Other sources of income.Please give details:" ?
                                <>
                                  <div className="form-group mt-3">
                                    <label>Other Sources of Income</label>
                                    <input id="othersourcesofincome" type="text" className={classnames("form-control")} maxlength="30"
                                      onChange={(e) => onChangefn(e)}
                                    />
                                    <span className="text-danger">{error.othersourcesofincome}
                                    </span>
                                  </div>
                                </> : ""}
                            </div>
                          </div>

                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <label>Net Monthly Income<span className="style_span_grey pl-1">(Please select the option referring to the net monthly income, expressed in euro)</span></label>
                              <select id="monthlyIncome" className="darkselect selectcount dark_selc_kyc ml-1" onChange={(e) => onChangefn(e)}>
                                <option hidden>Select Net Monthly Income</option>
                                {options3.map((item, key) => {

                                  return <option value={item}>{item}</option>

                                })}


                              </select>
                              {/* <input id="monthlyIncome" type="text" className={classnames("form-control")} onChange={(e) => { this.setState({ monthlyIncome: e.target.value }) }} /> */}
                              <span className="text-danger">{error.monthlyIncome}</span>
                            </div>
                          </div>

                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <label>Purpose and nature of the business relationship<span className="style_span_grey pl-1 invisible">(Please select the option referring to the net monthly income)</span></label>
                              <select id="businessRelationship" className="darkselect selectcount dark_selc_kyc ml-1" onChange={(e) => onChangefn(e)}>
                                <option hidden>Select Purpose and nature of the business relationship</option>
                                {options4.map((item, key) => {
                                  console.log("item", item)
                                  return <option value={item}>{item}</option>

                                })}


                              </select>
                              {/* <input id="businessRelationship" type="text" className={classnames("form-control")} onChange={(e) => { this.setState({ businessRelationship: e.target.value }) }} /> */}
                              <span className="text-danger">{error.businessRelationship}</span>
                            </div>
                          </div>

                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <label>Estimated annual volume to be traded</label>
                              <select id="estimatedAnnualVolume" className="darkselect selectcount dark_selc_kyc ml-1" onChange={(e) => onChangefn(e)}>
                                <option hidden>Select Estimated annual volume to be traded</option>
                                {options5.map((item, key) => {

                                  return <option value={item}>{item}</option>

                                })}


                              </select>
                              {/* <input id="estimatedAnnualVolume" type="text" className={classnames("form-control")} onChange={(e) => { this.setState({ estimatedAnnualVolume: e.target.value }) }} /> */}
                              <span className="text-danger">{error.estimatedAnnualVolume}</span>
                            </div>
                          </div>

                        </div>


                        <p className="tab_ver_heading">KYC</p>
                        <div className='row'>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <div className="custom-control custom-switch switch_new_kyc">
                                <input type="checkbox" className="custom-control-input" id="customSwitch1"
                                  onChange={(e) => { getData(); showKycmodal(e.target.checked); setIndividual({ ...individual, ...{ customSwitch1: !individual.customSwitch1 } }) }}
                                />
                                <label className="custom-control-label text-white" for="customSwitch1">
                                  <span className="perform-m">Perform KYC via SYNAPS</span>
                                </label>
                              </div>
                              <span className="text-danger">{error.customSwitch1}</span>
                            </div>
                          </div>

                        </div>

                        <p className="tab_ver_heading">Trading Conditions</p>
                        <p className="form_val_text">By initiating a transaction, please confirm that you comply with the following information: <div class="checkbox">
                          <label className="mt-3 check_sm_cr">

                            <input type="checkbox" value="" onChange={() => setIndividual({ ...individual, ...{ chkbox1: !individual.chkbox1 } })} />
                            <span class="cr"></span> The funds used to carry out transactions via the Trading ONE platform do not originate from illegal or illicit activities;
                          </label>

                          <label className="mt-3 check_sm_cr">


                            <input type="checkbox" value="" onChange={() => setIndividual({ ...individual, ...{ chkbox2: !individual.chkbox2 } })} />
                            <span class="cr"></span> Digital assets traded through the Trading ONE platform do not originate from illicit or illegal activities;
                          </label>

                          <label className="mt-3 check_sm_cr">
                            <input type="checkbox" value="" onChange={() => setIndividual({ ...individual, ...{ chkbox3: !individual.chkbox3 } })} />
                            <span class="cr"></span> Trading through the Trading ONE platform does not constitute money laundering or terrorist financing;
                          </label>


                          <label className="mt-3 check_sm_cr">
                            <input type="checkbox" value="" onChange={() => setIndividual({ ...individual, ...{ chkbox4: !individual.chkbox4 } })} />
                            <span class="cr"></span> You are the holder of the trading account and the account is not used by another person;
                          </label>

                          <label className="mt-3 check_sm_cr">
                            <input type="checkbox" value="" onChange={() => setIndividual({ ...individual, ...{ chkbox5: !individual.chkbox5 } })} />
                            <span class="cr"></span> You will not pass on your login credentials/data (username, password or other information required to log into the platform) to any other person;
                          </label>

                          <label className="mt-3 check_sm_cr">
                            <input type="checkbox" value="" onChange={() => setIndividual({ ...individual, ...{ chkbox6: !individual.chkbox6 } })} />
                            <span class="cr"></span> I am aware of and assume the risks arising from trading and holding digital assets on the Trading ONE platform;
                          </label>

                          <label className="mt-3 check_sm_cr">
                            <input type="checkbox" value="" onChange={() => setIndividual({ ...individual, ...{ chkbox7: !individual.chkbox7 } })} />
                            <span class="cr"></span> I will comply with the contractual provisions, including those related to the prevention and fight against money laundering and terrorist financing, i will provide data and supporting information/documents that may be required during the business relationship, necessary to comply with the legislation in force;
                          </label>
                          <div className="text-center mt-5">
                            <button className="btn btn btn-light" onClick={(e) => { Verification(e, "individual") }}>Send Form</button>
                          </div>
                        </div></p>

                      </form>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="entry-verification" role="tabpanel" aria-labelledby="entry-verification-tab" >

                    <div className="py-4">

                      <form className="stoForm">
                        <p className="tab_ver_heading">Company Registration Details</p>
                        <p className="form_val_text">The data provided must be identical to those in the registration document</p>
                        <div className='row'>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <label>Company Name<span className="style_span_grey pl-1 invisible_sm">(Certificate of Incorporation/Registration Number)</span></label>
                              <input id="companyname" type="text" className={classnames("form-control")} maxlength="30" onChange={(e) => onChangeentity(e)} />
                              <span className="text-danger">{error.companyname}</span>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <label>Certificate of Incorporation/Registration Number</label>
                              <input id="regno" type="text" className={classnames("form-control")} maxlength="30" onChange={(e) => onChangeentity(e)} />
                              <span className="text-danger">{error.regno}</span>
                            </div>
                          </div>


                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <label className="max_height_md_labl">Registration Date<span className="style_span_grey pl-1 invisible">(Jurisdiction of Incorporation/Registration)</span></label>
                              {/* <DatePicker
                                id="regdate"
                                onChange={(e) => onChangeentity(e)}
                                showYearDropdown
                                showMonthDropdown
                                // minDate={new Date()}
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                  
                                }}
                              /> */}
                              {/* <input type="date" class="form-control" id="regdate" aria-describedby="emailHelp" maxlength="0" onkeydown="return false" max={new Date().toISOString().substr(0, 10)} onChange={(e) => onChangeentity(e)} /> */}

                              {/* <Form.Control 
                                 type="date" 
                               

                                id="dateOfBirth1" 
                      selected={entity.regdate}

                                placeholder="Enter Expiry"
                                className="example-custom-input input_date_new_pick input_date_new_pick_kyc ml-0 ml-sm-2"
                                name="dob"
                                onChange={(date) => {
                                  let newDate = new Date(date)
                                  setEntity({ ...entity, 'regdate': newDate.getTime() })
                                
                               }}
                               
                                dateFormat="MMMM d, yyyy "

                              
                                maxDate={new Date()}
                               
                             
                             
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                                 /> */}

                              <Datetime
                                popperPlacement="top-end"
                                timeFormat={false}
                                input={true}
                                closeOnSelect={true}
                                // isValidDate={valid}
                                className="w-100 rdt_new rd_new_upload_date"
                                isValidDate={disableFutureDt}
                                inputProps={{ onKeyDown: (e) => { e.preventDefault() } }}
                                onChange={(e) =>{
                                  let newDate= new Date(e._d)
                                  setEntity({ ...entity, 'regdate': newDate.getTime() })
                                 console.log("event",e._d)
                                 }}
                                
                              // timeFormat="HH:mm:ss"
                              // timeConstraints={{
                              //   hours: { min: new Date(Date.now()).getHours(), max: 23 },
                              // }}
                              // onChange={(value) => {
                              //   Auctionset(value);
                              // }}
                              />


                              {/* <DatePicker
                      popperClassName="some-custom-class mb-mob-ip datr_pick_dateonly"
                      className="example-custom-input"
                      popperPlacement="bottom-end"
                      id="dateOfBirth1" 
                      selected={entity.regdate}

                      onChange={(date) => {
                        let newDate = new Date(date)
                        setEntity({ ...entity, 'regdate': newDate.getTime() })
                     }}
                     
                      dateFormat="MMMM d, yyyy"
                      maxDate={new Date()}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                              */}

                              <span className="text-danger">{error.regdate}</span>
                            </div>
                          </div>

                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <label>Jurisdiction of Incorporation/Registration</label>
                              <select id="registration" className="darkselect selectcount dark_selc_kyc ml-1" onChange={(e) => onChangeentity(e)}>
                                <option hidden>Select Jurisdiction of Incorporation/Registration</option>
                                {europiancountries.map((item, key) => {

                                  return <option value={item}>{item}</option>

                                })}

                              </select>

                              {/* <input id="registration" type="text" className={classnames("form-control")} maxlength="30" onChange={(e) => onChangeentity(e)} /> */}
                              <span className="text-danger">{error.registration}</span>
                            </div>
                          </div>

                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <label>Headquarter/Address</label>
                              <input id="address" type="text" className={classnames("form-control")} maxlength="75" onChange={(e) => onChangeentity(e)} />
                              <span className="text-danger">{error.address}</span>
                            </div>
                          </div>

                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <label>Sector/Industry/Business Type</label>
                              <select id="businesstype" className="darkselect selectcount dark_selc_kyc ml-1" maxlength="30" onChange={(e) => onChangeentity(e)}>
                                <option hidden>Select Sector/Industry/Business Type</option>
                                {options6.map((item, key) => {

                                  return <option value={item}>{item}</option>

                                })}


                              </select>
                              {/* <input id="type" type="text" className={classnames("form-control")} /> */}
                              <span className="text-danger">{error.businesstype}</span>
                            </div>
                          </div>

                        </div>



                        <p className="tab_ver_heading">Legal representative of the Company</p>
                        <p className="form_val_text">The data provided must be identical to those in the personal documents</p>

                        <div className='row'>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <label>Full Name</label>
                              <input id="fullname" type="text" className={classnames("form-control")} maxlength="30" onChange={(e) => onChangeentity(e)} />
                              <span className="text-danger">{error.fullname}</span>
                            </div>
                          </div>

                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <label>Personal ID Number</label>
                              <input id="personalIdNo" type="text" className={classnames("form-control")} maxlength="30" onChange={(e) => onChangeentity(e)} />
                              <span className="text-danger">{error.personalIdNo}</span>
                            </div>
                          </div>


                        </div>

                        <p className="form_val_text">Address (address from ID document)</p>

                        <div className='row'>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <label>Address</label>
                              <input id="legalStreet" type="text" className={classnames("form-control")} maxlength="75" onChange={(e) => onChangeentity(e)} />
                              <span className="text-danger">{error.legalStreet}</span>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <label>City</label>
                              <input id="legalCity" type="text" className={classnames("form-control")} maxlength="30" onChange={(e) => onChangeentity(e)} />
                              <span className="text-danger">{error.legalCity}</span>
                            </div>
                          </div>

                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <label>Country</label>
                              <input id="legalCountry" type="text" className={classnames("form-control")} maxlength="30" onChange={(e) => onChangeentity(e)} />
                              <span className="text-danger">{error.legalCountry}</span>
                            </div>
                          </div>

                        </div>



                        <p className="tab_ver_heading">Company Financial Details</p>

                        <div className='row'>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <label className="min_height_md_labl">Source of Funds<span className="style_span_grey pl-1">(Please select the closest option)</span>
                                {/* <span className="style_span_grey pl-1 invisible">((Please select the option fiscal year))</span> */}
                                {/* <span className="style_span_grey pl-1 invisible_sm">((Please select the option))</span> */}
                              </label>
                              <select id="companySourceOfFunds" className="darkselect selectcount dark_selc_kyc ml-1" onChange={(e) => onChangeentity(e)}>
                                <option hidden>Select Source of Funds</option>
                                {options7.map((item, key) => {

                                  return <option value={item}>{item}</option>

                                })}


                              </select>
                              {entity.companySourceOfFunds === "Other sources of income. Please provide details:" ?
                                <>
                                  <div className="form-group mt-3">
                                    <label>Other Sources of Income</label>
                                    <input id="othercompanySourceOfFunds" type="text" className={classnames("form-control")} maxlength="30"
                                      onChange={(e) => onChangeentity(e)}
                                    />
                                  </div>
                                </> : ""}
                              <span className="text-danger">{error.companySourceOfFunds}</span>
                            </div>
                          </div>

                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <label>Annual Turnover<span className="style_span_grey pl-1">(Please select the option corresponding to the turnover for the completed fiscal year, expressed in euros)</span></label>
                              <select id="companyAnnualTurnover" className="darkselect selectcount dark_selc_kyc ml-1" onChange={(e) => onChangeentity(e)}>
                                <option hidden>Select Annual Turnover</option>
                                {options8.map((item, key) => {

                                  return <option value={item}>{item}</option>

                                })}


                              </select>
                              <span className="text-danger">{error.companyAnnualTurnover}</span>
                            </div>
                          </div>

                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <label>Purpose and nature of the business relationship <span className="style_span_grey">(Please select one or more options that describe the reason for opening the account on the platform)</span></label>
                              <select id="companyBusinessRelationship" className="darkselect selectcount dark_selc_kyc ml-1" onChange={(e) => onChangeentity(e)}>
                                <option hidden>Select Purpose and nature of the business relationship</option>
                                {options9.map((item, key) => {

                                  return <option value={item}>{item}</option>

                                })}


                              </select>
                              <span className="text-danger">{error.companyBusinessRelationship}</span>
                            </div>
                          </div>

                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <label>Estimated annual volume to be traded<span className="style_span_grey pl-1">(Please select the range you estimate you will trade)</span><span className="style_span_grey pl-1 invisible">(Please select the range you estimate you will trade. Please select)</span></label>
                              <select id="companyAnnualVolume" className="darkselect selectcount dark_selc_kyc ml-1" onChange={(e) => onChangeentity(e)}>
                                <option hidden>Select Estimated annual volume to be traded</option>
                                {options10.map((item, key) => {

                                  return <option value={item}>{item}</option>

                                })}


                              </select>
                              <span className="text-danger">{error.companyAnnualVolume}</span>
                            </div>
                          </div>


                        </div>

                        <p className="form_val_text">Main Business Partner (please fill in the relevant information for the main business partner)</p>

                        <div className='row'>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <label>Name<span className="style_span_grey pl-1"></span></label>
                              <input id="businessPartnerName" type="text" className={classnames("form-control")} maxlength="30" onChange={(e) => onChangeentity(e)} />
                              <span className="text-danger">{error.businessPartnerName}</span>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <label className="min_height_md_labl_smla">Country where the partner is registered</label>
                              <input id="partnerCountry" type="text" className={classnames("form-control")} maxlength="30" onChange={(e) => onChangeentity(e)} />
                              {/* <select className="darkselect selectcount dark_selc_kyc ml-1"  onChange={(e) => onChangeentity(e)}>

                                {options11.map((item, key) => {

                                  return <option value={item}>{item}</option>

                                })}


                              </select> */}
                              <span className="text-danger">{error.partnerCountry}</span>
                            </div>
                          </div>

                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <label>Business Activity</label>
                              <select id="businessActivity" className="darkselect selectcount dark_selc_kyc ml-1" onChange={(e) => onChangeentity(e)}>
                                <option hidden>Select Business Activity</option>
                                {options11.map((item, key) => {

                                  return <option value={item}>{item}</option>

                                })}


                              </select>
                              {/* <input id="businessactivity" type="text" className={classnames("form-control")}  onChange={(e) => onChangeentity(e)}/> */}

                            </div>
                            <span className="text-danger">{error.businessActivity}</span>
                          </div>

                        </div>

                        {/* <div className="form-group">
                              <div className="custom-control custom-switch switch_new_kyc">
                                <input type="checkbox" className="custom-control-input" id="customSwitch1"
                                  onChange={(e) => { showKycmodal(e.target.checked) }}
                                />
                                <label className="custom-control-label text-white" for="customSwitch1">
                                  <span className="perform-m">Perform KYC via SYNAPS</span>
                                </label>
                              </div>
                              <span className="text-danger">{error.chkboxKyc}</span>
                            </div> */}




                        <p className="tab_ver_heading">KYC</p>
                        <div className='row'>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                              <div className="custom-control custom-switch switch_new_kyc">
                                <input type="checkbox" className="custom-control-input" id="customSwitch2"
                                  onChange={(e) => { getData(); showKycmodal1(e.target.checked); setEntity({ ...entity, ...{ customSwitch2: !entity.customSwitch2 } }) }}
                                />
                                <label className="custom-control-label text-white" for="customSwitch2" >
                                  <span className="perform-m">Perform KYC via SYNAPS</span>
                                </label>
                              </div>
                              <span className="text-danger">{error.customSwitch2}</span>
                            </div>
                          </div>

                        </div>

                        <p className="tab_ver_heading">Trading Conditions</p>
                        <p className="form_val_text">By initiating a transaction, please confirm that you meet and comply with, cumulatively, the following information: <div class="checkbox">
                          <label className="mt-3 check_sm_cr">
                            <input type="checkbox" value="" onChange={() => setEntity({ ...entity, ...{ entrychkbox1: !entity.entrychkbox1 } })} />
                            <span class="cr"></span> I am 18 years old;
                          </label>

                          <label className="mt-3 check_sm_cr">
                            <input type="checkbox" value="" onChange={() => setEntity({ ...entity, ...{ entrychkbox2: !entity.entrychkbox2 } })} />
                            <span class="cr"></span> I am the legal representative of the company for which I am requesting the opening of the trading account;
                          </label>

                          <label className="mt-3 check_sm_cr">
                            <input type="checkbox" value="" onChange={() => setEntity({ ...entity, ...{ entrychkbox3: !entity.entrychkbox3 } })} />
                            <span class="cr"></span> All information I provide in the relationship with ONE Trading is correct;
                          </label>


                          <label className="mt-3 check_sm_cr">
                            <input type="checkbox" value="" onChange={() => setEntity({ ...entity, ...{ entrychkbox4: !entity.entrychkbox4 } })} />
                            <span class="cr"></span> I agree to the processing of my personal data in accordance with the Personal Data Policy;
                          </label>

                          <label className="mt-3 check_sm_cr">
                            <input type="checkbox" value="" onChange={() => setEntity({ ...entity, ...{ entrychkbox5: !entity.entrychkbox5 } })} />
                            <span class="cr"></span> I declare under my own responsibility that I am the real beneficiary of the legal entity for which the account is being opened on the platform;
                          </label>

                          <label className="mt-3 check_sm_cr">
                            <input type="checkbox" value="" onChange={() => setEntity({ ...entity, ...{ entrychkbox6: !entity.entrychkbox6 } })} />
                            <span class="cr"></span> The funds used to carry out transactions through the ONE Trading platform do not come from illegal or illicit activities;
                          </label>

                          <label className="mt-3 check_sm_cr">
                            <input type="checkbox" value="" onChange={() => setEntity({ ...entity, ...{ entrychkbox7: !entity.entrychkbox7 } })} />
                            <span class="cr"></span> Trading through the ONE Trading platform does not constitute money laundering or terrorism financing activity;
                          </label>

                          <label className="mt-3 check_sm_cr">
                            <input type="checkbox" value="" onChange={() => setEntity({ ...entity, ...{ entrychkbox8: !entity.entrychkbox8 } })} />
                            <span class="cr"></span> You are the account holder and the account is not used by another person;
                          </label>

                          <label className="mt-3 check_sm_cr">
                            <input type="checkbox" value="" onChange={() => setEntity({ ...entity, ...{ entrychkbox9: !entity.entrychkbox9 } })} />
                            <span class="cr"></span> You will not disclose your login credentials (username, password or other information required to log into the platform) to anyone else;
                          </label>

                          <label className="mt-3 check_sm_cr">
                            <input type="checkbox" value="" onChange={() => setEntity({ ...entity, ...{ entrychkbox10: !entity.entrychkbox10 } })} />
                            <span class="cr"></span> You understand and assume the risks arising from trading and holding digital assets on the ONE Trading platform;
                          </label>


                          <label className="mt-3 check_sm_cr">
                            <input type="checkbox" value="" onChange={() => setEntity({ ...entity, ...{ entrychkbox11: !entity.entrychkbox11 } })} />
                            <span class="cr"></span> You will comply with the contractual provisions, including those related to the prevention and combating of money laundering and terrorism financing, and will provide tha data and information/documents that may be requested during the business relationship, necessary to comply with the applicable legislation;
                          </label>

                          <div className="text-center mt-5">
                            <button className="btn btn btn-light" onClick={(e) => { Verification(e) }}>Send Form</button>
                          </div>
                        </div>
                        </p>

                      </form>
                    </div>

                  </div>
                </div>

                {/* <div className="view_sec">
                  <div className="text-right">
                    <button className="vbtn_biew_doc" onClick={() => { setViewupload(!viewupload) }}>View Documents</button>

                  </div>
                  {viewupload ?
                    <div className="browsesec uploaded">
                      <p>Uploaded Document</p>
                      <div className="pdfsec mt-3">

                        <div>
                          <i class="fas fa-file-pdf"></i>
                        </div>
                        <div className="pdfjoin">
                          <p className="labelname mb-2">
                            {idProof.status == "approved" || idProof.status == "pending" ? idProof.frontImage : "Photo.png"}</p>


                          <span>{idProof.status == "rejected" ? `${t(kycStatus(idProof.status))}  : ${idProof.reason}` : t(kycStatus(idProof.status))} <i className={clsx({ "fas fa-check-circle enableGreen pl-2": idProof.status == 'approved' }, { "fas fa-times-circle disabledRed pl-2": ['new', 'pending', 'rejected'].includes(idProof.status) })}></i></span>


                        </div>
                      </div>
                      <div className="pdfsec mt-3">
                        <div>
                          <i class="fas fa-file-pdf"></i>
                        </div>
                        <div className="pdfjoin">

                          <p className="labelname mb-2">

                            {idProof.status == "approved" || idProof.status == "pending" ? idProof.selfiImage : "Photo.png"}

                          </p>


                          <span>{idProof.status == "rejected" ? `${t(kycStatus(idProof.status))}  : ${idProof.reason}` : t(kycStatus(idProof.status))} <i className={clsx({ "fas fa-check-circle enableGreen pl-2": idProof.status == 'approved' }, { "fas fa-times-circle disabledRed pl-2": ['new', 'pending', 'rejected'].includes(idProof.status) })}></i></span>


                        </div>
                      </div>




                    </div> :
                    ""}

                </div> */}
              </>
              :
              <>

                {verifiedstatus && verifiedstatus == "PENDING" || Synapsverify == "PENDING" ?
                  <div className="success_div_white">
                    <p>Thank you for the information provided!</p>
                    <p>The Trading ONE team is already verifying the information in order to validate your trading account.</p>
                    <p>You will be notified by email about the next steps.</p>
                    <p>The trading contract becomes available in your trading account immediately after the data validation.</p>
                    <div className="text-center">

                      <button className="btn btn-light mt-3 mb-3" onClick={() => { getData(); showKycmodal(true); }}>KYC SYNAPS</button>
                    </div>

                    <p>If you are not finished submitting KYC via SYNAPS, please click the above button and submit.</p>

                  </div>
                  : <>
                    {verifiedstatus && verifiedstatus == "APPROVED" ?
                      <div className="success_div_white">
                        <p>Thank you for the information provided!</p>
                        <p>Your KYC information successfully Approved!</p>
                      </div> : ""
                    }
                  </>
                }
              </>
            }





            {/* <>
                      {this.state.verifiedstatus && this.state.verifiedstatus == "PENDING" || this.state.Synapsverified == "PENDING" ?
                        <div className="success_div_white">
                          <p>Thank you for the information provided!</p>
                          <p>The Trading ONE team is already verifying the information in order to validate your trading acount.</p>
                          <p>You will be notified by email about the next steps.</p>
                          <p>The trading contract becomes available in your trading account immediately after the data validation.</p>
                         <button className="btnLandingJoin mb-3" onClick={()=>{$('#kyc-validate-modal').modal('show');this.getData()}}>KYC SYNAPS</button> 
                         <p>If you are not finished submitting KYC via SYNAPSE, please click the above button and submit.</p>
                        </div> 
                        
                        : <> {this.state.verifiedstatus && this.state.verifiedstatus == "FINISHED" ?
                          <div className="success_div_white">
                            <p>Thank you for the information provided!</p>
                            <p>Your KYC information successfully Approved!</p>
                          </div> : ""
                        }
                        </>
                      }
                    </> */}
            {/* <div className="row">
              <div className="col-md-6 mb-3">

                <div className="browsesec">



                  <label for="images" class="drop-container">
                    <span class="labelname">Upload your Documents here</span>
                    <span class="labelname">or</span>
                    <button className="btn btn-light mt-3" onClick={() => setUpload(true)}

                  disabled={idProof.status == "pending"||idProof.status == "approved"?true:false}
                    > {<span>{idProof.status == "rejected" ? `${t(kycStatusupload(idProof.status))}  ` : t(kycStatusupload(idProof.status))} <i className={({ "fas fa-check-circle enableGreen pl-2": idProof.status == 'approved' }, { "fas fa-times-circle disabledRed pl-2": ['new', 'pending', 'rejected'].includes(idProof.status) })}></i></span>}</button>
                   
                  </label>

                </div>
              </div>
              <div className="col-md-6 ">

                <div className="browsesec uploaded">
                  <p>Uploaded Document</p>
                  <div className="pdfsec mt-3">
                
                    <div>
                      <i class="fas fa-file-pdf"></i>
                    </div>
                    <div className="pdfjoin">
                      <p className="labelname mb-2"> 
                       {idProof.status=="approved"||idProof.status=="pending" ?idProof.frontImage:"Photo.png"}</p>
                   
                     
                      <span>{idProof.status == "rejected" ? `${t(kycStatus(idProof.status))}  : ${idProof.reason}` : t(kycStatus(idProof.status))} <i className={clsx({ "fas fa-check-circle enableGreen pl-2": idProof.status == 'approved' }, { "fas fa-times-circle disabledRed pl-2": ['new', 'pending', 'rejected'].includes(idProof.status) })}></i></span>
                      
                  
                    </div>
                  </div>
                  <div className="pdfsec mt-3">
                    <div>
                      <i class="fas fa-file-pdf"></i>
                    </div>
                    <div className="pdfjoin">
                      
                      <p className="labelname mb-2">
                        
                        {idProof.status=="approved"||idProof.status=="pending" ?idProof.selfiImage:"Photo.png"}

                      </p>

                    
        <span>{idProof.status == "rejected" ? `${t(kycStatus(idProof.status))}  : ${idProof.reason}` : t(kycStatus(idProof.status))} <i className={clsx({ "fas fa-check-circle enableGreen pl-2": idProof.status == 'approved' }, { "fas fa-times-circle disabledRed pl-2": ['new', 'pending', 'rejected'].includes(idProof.status) })}></i></span>
                  
                  
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

    </div>
  );

}

export default UserKycDetail;
