import React, { useState } from 'react';
import { Modal, Tabs, Dropdown, InputGroup, Form, Tab } from 'react-bootstrap'

//import action 
import { removeFundAction } from 'actions/copyTradeAction';

//import lib
import { toastAlert } from 'lib/toastAlert';
import isEmpty from 'lib/isEmpty';
const initialFormValue = {
    'accountData': {},
    'type': '',
    'leverage': '',
    'currency': 'USD',
    'removeableAmount': '',
    'minInvestment': '',
    'traderAccountId': '',
    'followerAccountId': ''
}
const RemoveFund = (props) => {
    const [removeFund, setremoveFund] = useState(true)
    const { removedData, removedAccount } = props
    const [formValue, setFormValue] = useState(initialFormValue)
    const [error,Seterror]=useState({})
    const { removeableAmount } = formValue
    console.log(props, "setRemovedData")
    const handleChange = (e) => {
        try {
            if(e.target.name == "removeableAmount"){
                let formData = {...formValue,[e.target.name]:e.target.value}
                if(parseFloat(e.target.value)  > removedData.removeableFunds){
                    let errors = {...error,[e.target.name]:"The amount is greater than available funds"}
                    Seterror(errors)
                }
                setFormValue(formData)
            }
            
        } catch (err) {
            console.log(err,"handleChange__err")
        }
    }
    const validation = (data)=>{
        try{
            let errors = error
            if(isEmpty(data.removeableAmount)){
                errors = {...errors,"removeableAmount":"Amount field must not empty"}
            }
            return errors
        }catch(err){
            console.log(err,"validation__err")
        }
    }
    const handleSubmit =async()=>{
        try{
            let data ={
                amount:removeableAmount,
                accountId:removedAccount.accountId,
                followerData:removedData
            }
            let validateError = validation(data)
            if(!isEmpty(validateError)){
                Seterror(validateError)
                return false
            }
            const {status,message}= await removeFundAction(data)
            if(status){
                toastAlert('success', message, 'Startcopying')
                props.onDismiss()
            }else{
                toastAlert('error', message, 'Startcopying')
            }

        }catch (err) {
            console.log("handleSubmit__err",err)
        }
    }
    return (
        <>
            <Modal show={removeFund} className="modalbg font-12" size="md" centered scrolable>

                <Modal.Header className='align-items-center'>
                    <Modal.Title>Remove Funds - TradingOne</Modal.Title>
                    <button className='closebtn btn btn-link text-light nounder' onClick={() => props.onDismiss()}>
                        <i className="fa fa-times fa-6" aria-hidden="true"></i>

                    </button>
                </Modal.Header>
                <Modal.Body className='font-12'>
                    <div className='themetab modal_align'>

                        <div className='px-0 mt-1 d-flex align-items-center'>
                            <div className='px-0'>
                                <h5 className='text-white'>Strategy:</h5>
                            </div>
                            <div className='px-0'>
                                <h5 className='text-white' >{removedData.trader[0].strategyName}</h5>
                            </div>
                        </div>
                        <hr className='marg_red_hr' />

                        <div className='px-0 mt-1 sho_accu_div_full d-flex justify-content-between flx_mob_accor'>
                            <div className='px-0 content_box'>
                                <small className='text-white'>To Account</small>
                            </div>
                            <div className='px-0 content_box'>
                                <select className='themeselect flex-05' name='accountData'>
                                    <option>
                                        {removedAccount.type} {removedAccount.accountId} - USD {removedAccount.balance.toFixed(4)} - {removedAccount.leverage}
                                    </option>
                                </select>
                            </div>
                        </div>


                        <div className='px-0 mt-2 d-flex justify-content-between '>
                            <div className='px-0  content_box'>
                                <small className='text-white'>Amount</small>
                            </div>
                            <div className='content_box'>
                                <div className='cont_box_le'>
                                    <div class="input-group input-group-h25 bg-dark text-white inut_grp_le">
                                        <div class="input-group-prepend ">
                                            <span class="input-group-text text-white " id="basic-addon1">USD</span>
                                        </div>
                                        <input type="number" class="form-control  input-group-input"
                                            aria-label="Username"
                                            aria-describedby="basic-addon1"
                                            value={removeableAmount}
                                            onChange={handleChange}
                                            name="removeableAmount"
                                        />
                                    </div>
                                    <div className='bg-dark icon_bg_dark input-group-h25'
                                     onClick={() => {
                                        let formData = { ...formValue, ...{ 'removeableAmount': removedData.removeableFunds } };
                                        setFormValue(formData)
                                    }}
                                    >
                                        <svg id="ic-max-amount" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
                                            <path d="M6 4h7v1H6zM12 11L9.51 7.32 7 11h2v4h1v-4h2z"></path>
                                        </svg>
                                    </div>
                                </div>
                                <span className="text-danger">{error && error?.removeableAmount}</span>
                            </div>
                        </div>
                        <div className='px-0 mt-2  d-flex justify-content-between mb-2'>
                            <div className='px-0  content_box'>
                                <small className='text-white'>Available to Remove</small>
                            </div>
                            <div className='px-0 content_box'>
                                <small className='text-white'>USD {removedData.removeableFunds}</small>
                            </div>
                        </div>
                        <div className='px-0 mt-1 d-flex justify-content-between mb-2'>
                            <div className='px-0  content_box'>
                                <small className='text-white'>Current Investment</small>
                            </div>
                            <div className='px-0 content_box'>
                                <small className='text-white' >USD {removedData.balanceInvestment}</small>
                            </div>
                        </div>

                        <div className='px-0 mt-1 d-flex justify-content-between '>
                            <div className='px-0  content_box'>
                                <small className='text-white'>Volume Fee</small>
                            </div>
                            <div className='content_box'>
                                <div class="input-group input-group-h25 bg-dark text-white ">
                                    <div class="input-group-prepend ">
                                        <span class="input-group-text text-white " id="basic-addon1">USD</span>
                                    </div>
                                    <input type="text" readOnly class="form-control bg-dark readOnly input-group-input" aria-label="Username" aria-describedby="basic-addon1" value={removedData.trader[0].volumeFee} />
                                </div>
                            </div>
                        </div>
                        {/* <div className='px-0 mt-2 d-flex justify-content-between '>
                            <div className='px-0  content_box'>
                                <small className='text-white'>Management Fee</small>
                            </div>
                            <div className='content_box'>
                                <div class="input-group input-group-h25 bg-dark text-white ">
                                    <div class="input-group-prepend ">
                                        <span class="input-group-text text-white " id="basic-addon1">USD</span>
                                    </div>
                                    <input type="text" readOnly class="form-control bg-dark readOnly input-group-input" aria-label="Username" aria-describedby="basic-addon1" />
                                </div>
                            </div>
                        </div> */}
                        <p className='my-2'>Existing positions can be changed due to the recalculation of position sizes according to the updated Equity to Equity ration.</p>
                        <div className='d-flex align-items-center pb-3'>
                            <button className='btn green-btn mr-2 w-full' onClick={()=>{handleSubmit()}}>Remove Funds</button>
                            <button className='btn red-btn w-full' onClick={() => props.onDismiss()}>Cancel</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default RemoveFund