// import lib
import isEmpty from '../../lib/isEmpty';


const validation = value => {
    console.log()
    console.log('hihi',value)
    let errors = {};
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
    var letters =/^[A-Za-z\s]*$/;
    var numbers = /^[0-9]+$/;
    var imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG)$/;

    console.log('value.frontImage && value.frontImage.size',value.frontImage && value.frontImage.size)
    console.log('value.frontImage',value.frontImage)
    console.log('value.frontImage.size',value.frontImage.size)




    //  if(isEmpty(value.frontImages))
    //  {
    //     if (value.frontImage && value.frontImage.size) {
    //         if (value.frontImage.size > 1000000) {   // 10 MB
    //             errors.frontImage = "TOO_LARGE"
    
    //         } else if (!imageFormat.test(value.frontImage.name)) {
    
    //             errors.frontImage = "INVALID_IMAGE"
    //         }
    //     } else {
    //         errors.frontImage = "REQUIRED";
    //     }
    //  }
    //  else if(!isEmpty(value.frontImages))
    //  {
    //     if(value.frontImage)
    //     {
    //       if (value.frontImage && value.frontImage.size) {
    //             if (value.frontImage.size > 1000000) {   // 10 MB
    //                 errors.frontImage = "TOO_LARGE"
        
    //             } else if (!imageFormat.test(value.frontImage.name)) {
        
    //                 errors.frontImage = "INVALID_IMAGE"
    //             }
    //         }
    //         else
    //         {
    //             errors.frontImage = "REQUIRED";
    //         }
    //     }
    //  }
     
    console.log('is---->',isEmpty(value.Email))
    if (isEmpty(value.Email)) {
        errors.Email = "REQUIRED"
    } else if (!(emailRegex.test(value.Email))) {
        errors.Email = "INVALID_EMAIL"
    }

    if (isEmpty(value.firstname)) {
        errors.firstname = "REQUIRED"
    } else if (!letters.test(value.firstname)) {
        errors.firstname = "FIRST_NAME_ALPHABET"
    }
    if (isEmpty(value.lastname)) {
        errors.lastname = "REQUIRED"
    } else if (!letters.test(value.lastname)) {
        errors.lastname = "LAST_NAME_ALPHABET"
    }
    // if (isEmpty(value.blockNo)) {
    //     errors.blockNo = "REQUIRED"
    // }
   
    if (isEmpty(value.PhoneNo)) {
        errors.PhoneNo = "REQUIRED"
    }else if (!numbers.test(value.PhoneNo)) {
        errors.PhoneNo = "PHONE_NO_NUMBER"
    }


    if (isEmpty(value.dob)) {
        errors.dob = "REQUIRED"
    }
    if (isNaN(value.dob)) {
        errors.dob = "REQUIRED"
    }
    if (isEmpty(value.citizenship)) {
        errors.citizenship = "REQUIRED"
    }
    // else if (!letters.test(value.blockNo)) {
    //     errors.blockNo = "BLOCKNO_NAME_ALPHABET"
    // }
    // if (isEmpty(value.address)) {
    //     errors.address = "REQUIRED"
    // }
    if (isEmpty(value.Country)) {
        errors.Country = "REQUIRED"
    }
    // if (isEmpty(value.state)) {
    //     errors.state = "REQUIRED"
    // } else if (!letters.test(value.state)) {
    //     errors.state = "STATE_NAME_ALPHABET"
    // }
    if (isEmpty(value.City)) {
        errors.City = "REQUIRED"
    } else if (!letters.test(value.City)) {
        errors.City = "CITY_NAME_ALPHABET"
    }
    // if (isEmpty(value.postalCode)) {
    //     errors.postalCode = "REQUIRED"
    // } else if (!numbers.test(value.postalCode)) {
    //     errors.postalCode = "POSTAL_CODE_MUST_NUMBER"
    // }

    // if (isEmpty(value.profileImage.name)) {
    //     errors.profileImage = "REQUIRED"
    // } else if (!imageFormat.test(value.profileImage.name)) {
    //     errors.profileImage = "INVALID_IMAGE"
    // }


    return errors;
}

export default validation;