//import lib
import isEmpty from "lib/isEmpty";
/** 
 * Calculate order cost
*/
export const inverseOrderCost = ({ price, quantity, leverage, takerFee, buyorsell }) => {
    try {
        price = parseFloat(price)
        quantity = parseFloat(quantity)
        leverage = parseFloat(leverage)
        takerFee = parseFloat(takerFee)

        return (
            inverseInitialMargin({ price, quantity, leverage })
            +
            inverseFeeToOpen({ price, quantity, takerFee })
            +
            inverseFeeToClose({ price, quantity, leverage, takerFee, buyorsell })
        )
    } catch (err) {
        return 0
    }
}
/**
 * calculate the Estimated Margin
 */
export const EstMargin = async (price, quantity, leverage, pairData, buyorsell, pairList) => {
    try {
        console.log(leverage,'EstMargin')
        if (typeof leverage == 'string') {
            let lever = leverage.split(':')
            leverage = parseFloat(lever[0]) / parseFloat(lever[1])
        }
        // let estmargin = (price * quantity)*leverage
        console.log(leverage,'EstMargin')
        // return estmargin
        let estmargin
        if (pairData.category.toUpperCase() == 'FOREX') {
            if (pairData.secondCurrencySymbol != 'USD' && pairData.firstCurrencySymbol != 'USD') {
                let convertquan = await priceConversion(pairData.firstCurrencySymbol, quantity, buyorsell, pairList)
                estmargin = convertquan *leverage
            }
            else if (pairData.firstCurrencySymbol == 'USD') {
                estmargin = leverage * quantity
            } else {
                estmargin = (price * quantity) * (leverage.toFixed(4))
            }
        }
        else if (pairData.category.toUpperCase() == 'CRYPTO') {
            estmargin = quantity * leverage * price
        }
        else {
            estmargin = quantity * leverage
        }
        console.log('EstMargin', estmargin)
        return estmargin
    } catch (err) {
        console.log(err, 'EstMargin')
        return false
    }
}
export const inverseInitialMargin = ({ price, quantity, leverage }) => {
    try {
        price = parseFloat(price)
        quantity = parseFloat(quantity)
        leverage = parseFloat(leverage)

        return quantity / (price * leverage)
    } catch (err) {
        return 0
    }
}

export const orderValue = ({ price, quantity }) => {
    try {
        price = parseFloat(price)
        quantity = parseFloat(quantity)

        return quantity / price
    } catch (err) {
        return 0
    }
}

export const inverseFeeToOpen = ({ price, quantity, takerFee }) => {
    try {
        price = parseFloat(price)
        quantity = parseFloat(quantity)
        takerFee = parseFloat(takerFee)

        return (quantity / price) * (takerFee / 100)
    } catch (err) {
        return 0
    }
}

export const inverseFeeToClose = ({ price, quantity, leverage, takerFee, buyorsell }) => {
    try {
        price = parseFloat(price)
        quantity = parseFloat(quantity)
        takerFee = parseFloat(takerFee)

        return (quantity / inverseBankrupty({ price, leverage, buyorsell })) * (takerFee / 100)
    } catch (err) {
        return 0
    }
}

export const inverseBankrupty = ({ price, leverage, buyorsell }) => {
    try {
        price = parseFloat(price)
        leverage = parseFloat(leverage)

        if (buyorsell == 'buy') {
            return price * (leverage / (leverage + 1))
        } else if (buyorsell == 'sell') {
            return price * (leverage / (leverage - 1))
        }
        return 0
    } catch (err) {
        return 0
    }
}

export const unrealizedProfitLoss = ({ price, quantity, lastPrice, buyorsell }) => {
    try {
        price = parseFloat(price)
        quantity = parseFloat(quantity)
        lastPrice = parseFloat(lastPrice)
        if (buyorsell == 'buy') {
            return quantity * ((1 / price) - (1 / lastPrice))
        } else if (buyorsell == 'sell') {
            return quantity * ((1 / lastPrice) - (1 / price))
        }
    } catch (err) {
        return 0
    }
}

export const unrealizedPnLPercentage = ({ price, quantity, lastPrice, leverage, takerFee, buyorsell }) => {
    try {
        price = parseFloat(price)
        quantity = parseFloat(quantity)
        lastPrice = parseFloat(lastPrice)
        leverage = parseFloat(leverage)
        takerFee = parseFloat(takerFee)

        return (unrealizedProfitLoss({ price, quantity, lastPrice, buyorsell }) / inversePositionMargin({ price, quantity, leverage, takerFee, buyorsell })) * 100
    } catch (err) {
        return 0
    }
}

export const inversePositionMargin = ({ price, quantity, leverage, takerFee, buyorsell }) => {
    try {
        price = parseFloat(price)
        quantity = parseFloat(quantity)
        leverage = parseFloat(leverage)
        takerFee = parseFloat(takerFee)

        return (
            inverseInitialMargin({ price, quantity, leverage })
            +
            inverseFeeToClose({ price, quantity, leverage, takerFee, buyorsell })
        )
    } catch (err) {
        return 0
    }
}

export const SpreadCal = (high, low) => {
    let High = parseFloat(high).toFixed(5).split('.')
    let highpip = High[1].slice(3, 4) + '.' + High[1].slice(4)
    let Low = parseFloat(low).toFixed(5).split('.')
    let lowpip = Low[1].slice(3, 4) + '.' + Low[1].slice(4)
    let spread = Math.abs(parseFloat(highpip) - parseFloat(lowpip)).toFixed(1)
    console.log(High, Low, "SpreadCal", lowpip, highpip, highpip - lowpip, spread)
    return spread

}
export const balanecDispaly = (walletsData, currencysData) => {
    console.log(walletsData, 'walletsData')
    let walletData = walletsData.filter(val => val.coin === 'USD')
    let currencyData = currencysData.filter(val => val.symbol === 'USD')
    return walletData[0].derivativeBal.toFixed(currencyData[0].decimal)
}

export const BidandAskDisplay = (value, precision) => {

    let Hole = value * Math.pow(10, precision)
    let val
    let lastIndx = Hole.toFixed(0).length - 1
    if (precision == 0) {
        val = ''
    } else {

        val = Hole.toFixed(0).slice(lastIndx)
    }
    // console.log(Hole,value,precision,val,lastIndx,"value,precision")
    return val
}

export const pipDisplay = (value, precision) => {
    try {
        let Hole = value.toString()
        let val
        let lastIndx = Hole.length - 1
        if (precision == 0) {
            val = ''
        } else {
            val = Hole.slice(lastIndx)
            val = `<span className='text-danger lastnumber'>${val}</span>`

        }
        console.log(document.getElementById(`${precision}`), "value,precision")
        return document.getElementById(`${precision}`).innerHTML = Hole + val
    } catch (err) {
        console.log(err)
    }

}

export const quantitytovolume = (quantity, pairData, price) => {
    try {
        let volume
        if (pairData.category.toUpperCase() == 'FOREX') {
            if (pairData.precision < 5) {
                volume = quantity / pairData.lotsize
            } else {
                volume = quantity / Math.pow(10, pairData.precision)
            }
        }
        else if (pairData.category.toUpperCase() == 'CRYPTO') {
            volume = quantity / pairData.lotsize
        }
        else {
            volume = quantity / pairData.lotsize / price
        }
        return volume
    } catch (err) {
        console.log(err, 'quantitytovolume__err')
    }
}

export const volumetoquantity = (volume, pairData, open_price) => {
    try {
        let quantity
        if (pairData.category.toUpperCase() == 'FOREX') {
            if (pairData.precision < 5) {
                quantity = volume * pairData.lotsize
            } else {
                quantity = volume * Math.pow(10, pairData.precision)
            }
        }
        else if (pairData.category.toUpperCase() == 'CRYPTO') {
            quantity = volume * pairData.lotsize
        }
        else {
            quantity = volume * pairData.lotsize * open_price
        }
        console.log('volumetoquantity', quantity)
        return quantity
    } catch (err) {
        console.log(err, 'volumetoquantity--err')
    }
}
export const priceConversion = async (currecnysymbol, amount, buyorsell, pairList) => {
    try {
        let symbol = currecnysymbol + 'USD'
        let timeStamp = new Date().getTime()
        let pairData = pairList.find((val => val.socketsymbol == symbol))
        if (isEmpty(pairData)) {
            symbol = 'USD' + currecnysymbol
            let pairData = pairList.find((val => val.socketsymbol == symbol))
            //   console.log("priceConversion",pairData,pairList)
            let price = buyorsell == 'buy' ? pairData.ask : pairData.bid
            let totalCost = (amount * (1 / price))
            //   console.log("priceConversion", amount, price, totalCost)
            return totalCost
        } else {
            let price = buyorsell == 'buy' ? pairData.ask : pairData.bid
            //   console.log("priceConversion",pairData,pairList)
            let totalCost = amount * price
            return totalCost
        }
    } catch (err) {
        console.log(err, 'priceConversion')
    }
}

export const getPorfitCalculation = async (close_price, open_price, volume, pairData, buyorsell, pairList) => {
    try {
        // let 
        let quan = volume * pairData.lotsize
        let profit
        if (buyorsell == 'buy') {
            profit = (parseFloat(open_price) - parseFloat(close_price)) * quan
            //  console.log('getPorfitCalculation',parseFloat(open_price) - parseFloat(close_price),'-',(parseFloat(open_price) - parseFloat(close_price))*quan,pairData.socketsymbol)
        } else {
            profit = (parseFloat(close_price) - parseFloat(open_price)) * quan
            // console.log('getPorfitCalculation',(parseFloat(close_price) - parseFloat(open_price)),'-',(parseFloat(close_price) - parseFloat(open_price))*quan,pairData.socketsymbol)
        }

        if (pairData.firstCurrencySymbol !== 'USD' && pairData.secondCurrencySymbol !== 'USD') {
            profit = await priceConversion(pairData.secondCurrencySymbol, profit, buyorsell, pairList)
            return profit
        } else if (pairData.firstCurrencySymbol == 'USD' && pairData.secondCurrencySymbol !== 'USD') {
            profit = buyorsell == 'sell' ? (profit * (1 / pairData.bid)) : (profit * (1 / pairData.ask))
            return profit
        } else {
            return profit
        }
    } catch (err) {
        console.log(err, 'getPorfitCalculation--err')
    }
}

export const strategyAge = (createdDate) => {
    try {
        // let differ = new Date(createdDate).getTime() - new Date().getTime()
        // var daydiff = Math.abs(differ / (1000 * 60 * 60 * 24), 0).toFixed(0);
        // var hrsdiff = new Date().getHours()
        // console.log(new Date(hrsdiff * 1000), 'hrsdiff')
        // var age = daydiff + 'd ' + hrsdiff + 'h'

        let diffTime = Math.abs(new Date(createdDate).getTime() - new Date().getTime());
        let days = diffTime / (24 * 60 * 60 * 1000);
        let hours = (days % 1) * 24;
        let minutes = (hours % 1) * 60;
        let secs = (minutes % 1) * 60;
        [days, hours, minutes, secs] = [Math.floor(days), Math.floor(hours), Math.floor(minutes), Math.floor(secs)]

        console.log(days + 'd', hours + 'h', minutes + 'm', secs + 's');
        var age = days + 'd ' + hours + 'h'
        return age
    } catch (err) {
        console.log(err, 'strategyAge')
    }
}