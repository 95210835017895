// import lib
import isEmpty from './isEmpty';

export const capitalize = (s) => {
    try{
        if (typeof s !== 'string'){
            return ''
        } 
        let val = s.split('_')
        if(val.length > 1){
            return val[0].charAt(0).toUpperCase() + val[0].slice(1)+" "+val[1].charAt(0).toUpperCase() + val[1].slice(1)
        }else{
            // console.log(s.charAt(0).toUpperCase(),s.slice(1),'s.charAt(0).toUpperCase()')
            return s.charAt(0).toUpperCase() + s.slice(1)
        }
    }catch(err){
        console.log(err,'capitalize')
    }
}

export const firstLetterCase = (value) => {
    if (!isEmpty(value)) {
        return value.charAt(0).toUpperCase();
    }
    return ''
}

export const upperCase = (s) => {
    if (isEmpty(s)) {
        return ''
    }
    return s.toUpperCase()
}

export const emailFormat = (email) => {
    try {
        if (!isEmpty(email)) {
            let domain = email.substring(email.indexOf('@'), email.indexOf('.'))
            domain = domain.substring(domain.length - 2, domain.length)
            return email.substring(0, 3) + "....@..." + domain + email.substring(email.indexOf('.'), email.length)
        }
        return ''
    } catch (err) {
        return ''
    }
}

export const cnvtBoolean = value => {
    if (typeof value === 'boolean' && value == true) return true;
    if (typeof value === 'boolean' && value == false) return false;
    if (typeof value === 'string' && value == 'true') return true;
    if (typeof value === 'string' && value == 'false') return false;
}