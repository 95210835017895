import React, { useState } from 'react';
import { Modal, Tabs, Dropdown, InputGroup, FormControl, Button, Form, Tab } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { MenuItem, Select } from '@material-ui/core';
import { useDispatch } from 'react-redux';

// import component
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem"

import { updateIdProof } from '../../../../actions/userKyc'

import isEmpty from '../../../../lib/isEmpty';
import { toastAlert } from '../../../../lib/toastAlert';
import validation from '../../../IDProof/validation';


const initialFormValue = {
    'type': "",
    'proofNumber': '',
    'frontImage': '',
    'backImage': '',
    'selfiImage': '',
    'documenttype': '',
    'address': ''

}

// console.log("initialFormValue--->",initialFormValue)

const Upload = (props) => {
    console.log("props------------>", props);
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [upload, setUpload] = useState(true)
    //state
    const [formValue, setFormValue] = useState(initialFormValue);
    console.log("formValue-------->", formValue);
    const [validateError, setValidateError] = useState({});
    const [loader, setLoader] = useState();
    const { type, proofNumber, frontImage, backImage, selfiImage, documenttype, address } = formValue;

    console.log("formValue=============>", formValue)
    //function
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        if (name == "proofNumber") {
            if (/[^a-zA-Z0-9]/.test(value)) return
        }
        let formData = { ...formValue, ...{ [name]: value } }
        console.log("formData------------->", formData);
        setFormValue(formData)
        if (!isEmpty(validateError)) {
            setValidateError({})
        }
    }

    const handleFile = async (e) => {
        const { name, files } = e.target;
        let formData = { ...formValue, ...{ [name]: files[0] } }
        console.log("formData------------->", formData);
        setFormValue(formData)
        if (!isEmpty(validateError)) {
            setValidateError({})
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true)
        let reqData = {
            type,
            proofNumber,
            frontImage,
            backImage,
            selfiImage,
            documenttype,
            address,

            proofType: 'id'
        }
        let validationError = validation(reqData)
        console.log("validation", validationError);
        if (!isEmpty(validationError)) {
            setValidateError(validationError)
            console.log("validationError====>", validationError)
            setLoader(false)
            return
        }
        try {
            const formData = new FormData();
            console.log("formData==========================>.", formData);

            formData.append('type', type);
            formData.append('proofNumber', proofNumber);
            formData.append('frontImage', frontImage);
            formData.append('backImage', backImage);
            formData.append('selfiImage', selfiImage);
            formData.append('documenttype', documenttype);
            formData.append('address', address)

            const { status, loading, message, error } = await updateIdProof(formData, dispatch);
            console.log("formData*******************", formData);
            setLoader(loading)
            if (status == 'success') {
                toastAlert('success', t(message), 'idproof')
                props.onDismiss()
            } else {
                if (error) {
                    setValidateError(error)
                    return
                }
                toastAlert('error', t(message), 'idproof')
            }
        } catch (err) { }
        // props.onDismiss()
    }
    var Getimage = () => {
        var img = document.getElementById("demo")
        img.click();
        console.log('juhdgfjhm', img)
    }
    var Getimages = () => {
        var img = document.getElementById("demos")
        img.click();
        console.log('juhdgfjhm', img)
    }
    var Getimagess = () => {
        var img = document.getElementById("demoss")
        img.click();
        console.log('juhdgfjhm', img)
    }

    return (
        <>
            <Modal show={upload} className="modalbg uploadmodal font-12" size="md" centered scrolable>

                <Modal.Header className='align-items-center'>
                    <Modal.Title>Upload</Modal.Title>
                    <button className='closebtn btn btn-link text-light nounder' onClick={() => props.onDismiss()}>
                        <i className="fa fa-times fa-6" aria-hidden="true"></i>

                    </button>
                </Modal.Header>
                <Modal.Body className='font-12'>
                    <div className='themetab modal_align'>

                        <h5>Upload Document</h5>
                        <hr />
                        <p className='mb-2 headsec'>1. Proof of Identity</p>
                        <p className='labelname mb-1'>Please upload a clear, readable copy of any of the followings </p>
                        <small className='text-danger my-3'>Note: Image must be .jpg/.png/.jpeg max 3MB</small>


                        <div className='my-3'>
                            {/* <p className="mb-2">Document type</p>
                                        <div class="dropdown">
        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Select Document Type
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" href="#">Passport</a>
          <a class="dropdown-item" href="#">Aadhar_card</a>
          <a class="dropdown-item" href="#">Driving_license</a>
        </div>
      </div> */}
                            <p className="mb-2">Document type</p>
                            <select className='themeselect w-full' placeholder="select your Document type"
                                name="type"
                                value={type}
                                onChange={handleChange}
                            >
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton"></div>
                                <option value="0" hidden>Select document type</option>
                                <option class="dropdown-item" href="#">Passport</option>
                                <option class="dropdown-item" href="#" >Aadhar_card</option>
                                <option class="dropdown-item" href="#" >Driving_license</option>

                            </select>

                        </div>
                        <div >
                            <p className="mb-1">ID Number</p>
                            <input type="number" className='themeinput w-full'
                                name="proofNumber"
                                value={proofNumber}
                                onChange={handleChange}
                            />
                            {
                                validateError.proofNumber && <p className="error-message">{t(validateError.proofNumber)}</p>
                            }
                        </div>
                        <div className='my-3'>
                            <p className="mb-2">Front Side</p>
                            <div className='browseinput w-full p-1'>
                                <p className='labelname'>Select File</p>
                                <input type="file"
                                    id="demo"
                                    name="frontImage"
                                    onChange={handleFile}
                                //    disabled
                                />
                                {
                                    frontImage && frontImage.name ? <small>{frontImage.name}</small> : <small>{t("IDENTITY_HINT2")}</small>
                                }
                                <button className='browse btn btn-light m-0' onClick={() => Getimage()}>Browse</button>

                            </div>
                            {
                                validateError.frontImage && <p className="error-message">{t(validateError.frontImage)}</p>
                            }
                        </div>
                        <div>
                            <p className="mb-2">Back Side</p>
                            <div className='browseinput w-full p-1' >
                                <p className='ml-1 labelname'>Select File</p>
                                <input type="file" id="demos"
                                    name="backImage"
                                    onChange={handleFile}
                                //   disabled
                                />
                                {
                                    backImage && backImage.name ? <small>{backImage.name}</small> : <small>{t("IDENTITY_HINT2")}</small>
                                }


                                <button className='browse btn btn-light p-1 m-0' onClick={() => Getimages()}>Browse</button>

                            </div>
                            {
                                validateError.backImage && <p className="error-message">{t(validateError.backImage)}</p>
                            }
                        </div>

                        <hr />
                        <p className='mb-2 headsec'>2. Proof of address</p>
                        <p className='labelname mb-1'>Please upload a clear, readable copy of any of the followings </p>
                        <small className='text-danger my-3'>Note: Image must be .jpg/.png/.jpeg max 3MB</small  >

                        <div className='my-3'>
                            <p className="mb-2">Select document type</p>
                            {/* <div class="dropdown">
        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Select Document Type
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" href="#">Passport</a>
          <a class="dropdown-item" href="#">Aadhar_card</a>
          <a class="dropdown-item" href="#">Driving_license</a>
        </div>
      </div> */}
                            <div class="dropdown"></div>
                            <select className='themeselect w-full'
                                name="documenttype"
                                value={documenttype}
                                onChange={handleChange}
                            >
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton"></div>
                                <option hidden>Select document type</option>
                                <option class="dropdown-item" href="#" >Passport</option>
                                <option class="dropdown-item" href="#">Aadhar_card</option>
                                <option class="dropdown-item" href="#">Driving_license</option>
                            </select>
                        </div>
                        <div>
                            <p className="mb-2">Address</p>
                            <input type="text" className='themeinput w-full'

                                name="address"
                                value={address}
                                onChange={handleChange}

                            />  {
                                validateError.address && <p className="error-message">{t(validateError.address)}</p>
                            }
                        </div>
                        <div className='my-3'>
                            <p className="labelname mb-2">Back Side</p>
                            <div className='browseinput w-full p-1'>
                                <p className='ml-1 labelname'>Select File</p>
                                {
                                    selfiImage && selfiImage.name ? <small>{selfiImage.name}</small> : <small>{t("IDENTITY_HINT2")}</small>
                                }

                                <input type="file"
                                    id="demoss"
                                    name="selfiImage"

                                    onChange={handleFile}
                                //   disabled
                                />
                                <button className='browse btn btn-light m-0' onClick={() => Getimagess()}>Browse</button>

                            </div>
                            {
                                validateError.selfiImage && <p className="error-message">{t(validateError.selfiImage)}</p>
                            }

                        </div>

                        <div className='d-flex align-items-center pb-3 mt-4'>
                            <button className='btn  btn-light mr-2 w-full' onClick={(e) => handleSubmit(e)}>Upload</button>

                            <button className='btn  btn-light gray w-full' onClick={() => props.onDismiss()}>Cancel</button>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Upload