import React, { useEffect, useState, useContext } from 'react'
import { Link, NavLink, useHistory, useParams } from 'react-router-dom';
import { addwishlisthook } from 'actions/currencycategoryAction';
import { Accordion, Card, Tabs, Tab, Button, InputGroup, Form, Dropdown } from 'react-bootstrap'
import SocketContext from '../../../Context/SocketContext';
import star from '../../../../assets/images/newtrade/star.svg'
import chart from '../../../../assets/images/newtrade/chart.svg'
import file from '../../../../assets/images/newtrade/file.svg'
import CreateWishlist from '../Modals/CreateWishlist';
import NewOrder from '../Modals/NewOrder';
import { SpreadCal } from 'lib/bybit';
import { useDispatch, useSelector } from "react-redux";
import { toastAlert } from 'lib/toastAlert';
import { encryptObject } from 'lib/cryptoJS'
import { EstMargin, BidandAskDisplay } from 'lib/bybit'
// import action
import { getPositionOrder, orderPlace, getTradeData, getuserTradeData, cancelOrder, getTransaction } from '../../../../actions/perpetualTradeAction';
import { userPair } from 'actions/users';

const initialFormValue = {
    'price': "",
    'quantity': '',
    'total': '',
    'leverage': 1 / 100,
    'buyOrderCost': '0',
    'buyOrderValue': '0',
    'sellOrderCost': '0',
    'sellOrderValue': '0',
    'takeProfitPrice': '',
    'stopLossPrice': '',
    'isProfitLoss': false,
    'typeTIF': 'GTC',
    'pairname': '',
    'Expriydate': ''
}
const Singelwhislist = (props) => {
    const dispatch = useDispatch()
    const pairData = useSelector(state => state.tradePair)
    // console.log("pairData_pairData_V_pairData",pairData);
    const socketContext = useContext(SocketContext)
    const [formValue, setFormValue] = useState(initialFormValue);
    const { price, total, leverage, buyOrderCost, buyOrderValue, sellOrderCost, sellOrderValue, takeProfitPrice, stopLossPrice, isProfitLoss, typeTIF, pairname, Epriydate, comment } = formValue;

    const history = useHistory();
    const [volume, setVolume] = useState(0)
    // console.log("volume_V_volume_volumevolume",volume);
    const [Est_Margin, setEmargin] = useState(0)
    const [buyorsell, Setbuyorsell] = useState('buy')
    const { tikerRoot } = useParams()
    const [Markupprice, setmarkupprice] = useState({})
    const [buyorder, setBuyorder] = useState([])
    const [sellorder, setSellorder] = useState([])
    const [whishlist, setWishlist] = useState(false)
    const [neworder, setNewOrder] = useState(false)
    const [info, setInfo] = useState(false)
    const [pairList, setPairList] = useState([])
    const [limit, setLimit] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);
    const [quantity, setquantity] = useState(props?.data?.interval)
    const [contract, setContract] = useState(1000)
    const [errors, setError] = useState({})
    const { useraccount } = useSelector(state => state.account)
    const handlePairChange = async (pairData) => {
        let pair = `${pairData.tiker_root}`
        localStorage.setItem('curpair', pair)
        history.push('/trade/' + pair)
        await userPair({ pair: pair }, dispatch)
        if (tikerRoot != pair) {
            //   await fetchAssetByCurrency(pairData.firstCurrencyId, 'firstCurrency')
            //   await fetchAssetByCurrency(pairData.secondCurrencyId, 'secondCurrency')
            //   await setMarketPrice(pairData, dispatch)
            //   await setTradePair(pairData, dispatch)
            //   socketContext.socket.off("marketPrice");
            //   socketContext.socket.off("orderBook");
            //   socketContext.socket.off("recentTrade");
        }
    }

    const fetchData = async (reqData) => {
        const { status, loading, result } = await getTradeData(reqData)
        // console.log(result.buyOrder,result.sellOrder,result,'Singelwhislist',reqData)
        setBuyorder(result.buyOrder)
        setSellorder(result.sellOrder)
    }
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        // if(name=='quantity'){
        //     setquantity(value)
        //     // let Estmargin =  EstMargin(Markupprice.markprice,quantity,1/100)
        //     // setEmargin(EstMargin)
        // }
        if (name == 'quantity') {
            if (value < props.data.minQuantity) {
                console.log(value < props.data.minQuantity, 'value < pairData.minQuantity')
                setError({ ...errors, ...{ [name]: `Volume shouldn't be less than ${props.data.minQuantity}` } })
                return false
            } else if (value > props.data.maxQuantity) {
                console.log(value > props.data.maxQuantity, 'value > pairData.maxQuantity')
                setError({ ...errors, ...{ [name]: `Volume shouldn't be greater than ${props.data.maxQuantity}` } })
                return false
            }
            let quantity = value * Math.pow(10, props.data.precision)
            setquantity(quantity)
            setError({ ...errors, ...{ [name]: "" } })
            return true
        }
    }
    useEffect(() => {
        let reqData = {
            pairId: props.data._id
        }
        fetchData(reqData)
    }, [])
    useEffect(() => {
        //console.log(props.data.firstCurrencySymbol,props.data.secondCurrencySymbol,'props.data.secondCurrencySymbol')
        // let reqData = {
        //     pairId:props.data._id
        //   }
        // fetchData(reqData)
        socketContext.socket.on('perpetualmarketprice', (result) => {
            //console.log('perpetualmarketprice2', result)
            try {
                if (props.data.tiker_root == result.tiker_root) {
                    setmarkupprice(result)
                }
            } catch (err) {
                console.log(err, 'perpetualmarketprice__err')
            }
        })
        socketContext.socket.on('TRADE', (result) => {
            // console.log('Singelwhislist-TRADE', result)
            try {
                if(result.type == localStorage.getItem("liveorDemo")){
                    if (result.tiker_root == props.data.tiker_root) {
                        // console.log('Singelwhislist-TRADE', result)
                        setBuyorder(result.buyOrder)
                        setSellorder(result.sellOrder)
                    }
                }
            } catch (err) {
                console.log(err, 'TRADE__err')
            }
        })
        // console.log(props?.data?.minQuantity,'props?.data?.minQuantity',Math.pow(10,props?.data?.precision))
        let contract = parseInt(props?.data?.minQuantity * Math.pow(10, props?.data?.precision))
        setContract(contract)
    }, [socketContext.socket, props,useraccount])
    const handlewishlist = async (data, id) => {
        let payload = {
            name: data,
            id: id
        }
        console.log("payload", payload);
        let result = await addwishlisthook(payload);
        console.log("result", result);
        props.refresh()
    }
    const calculateQuantity = async (value) => {
        try {
            let Price = price ? price : buyorsell == 'buy' ? Markupprice?.bid : Markupprice.ask
            let quantity = value * Math.pow(10, pairData.precision)
            console.log('Markupprice?.markprice', price, Price, quantity, leverage)
            let Estmargin = await EstMargin(Price, quantity, leverage)
            setEmargin(Estmargin.toFixed(4))
            setError({ ...errors, ...{ 'quantity': '' } })
            let formData = { ...formValue, ...{ 'quantity': quantity } }
            setFormValue(formData)
        } catch (err) {
            console.log(err, 'calculateQuantity')
        }
    }
    const handleSubmit = async (buyorsell, price) => {
        try {
            let Estmargin = await EstMargin(price, quantity, parseFloat(useraccount.leverage))
            let reqData = {
                price: price,
                quantity: quantity,
                buyorsell: buyorsell,
                ordertype: 'limit',
                pairId: props.data._id,
                leverage: parseFloat(useraccount.leverage),
                'timeinforcetype': 'GTC',
                trigger_price: 0,
                trigger_type: 'none',
                Estmargin: Estmargin,
                pairname: props.data.tiker_root,
                'newdate': new Date(),
                type: useraccount?.type,
                accountId: useraccount?.accountId
            }

            let encryptToken = {
                token: encryptObject(reqData)
            }

            console.log('buyorsell--singel', reqData, buyorsell, price)

            console.log();
            let { status, loading, message, error, result } = await orderPlace(encryptToken);
            // setResults(result)
            //   if (buyorsell == 'buy') {
            //     setBuyLoader(loading)
            //   } else if (buyorsell == 'sell') {
            //     setSellLoader(loading)
            //   }
            //limit or market
            //   if (orderType == 'limit') {
            //     setLimitLoader(loading)
            //   } else if (orderType == 'market') {
            //     setMarketLoader(loading)
            //   }


            if (status) {
                // setFormValue(initialFormValue);
                console.log('buyorsell--singel', status)
                console.log("initialFormValue------->", initialFormValue);
                toastAlert('success', message, 'spotOrder');
            } else {
                toastAlert('error', message, 'spotOrder');
            }
        }
        catch (err) {
            console.log(err, 'buyorsell--singel')
        }
    }
    const increaseVolume = async (volume) => {
        try {

            let value
            if (volume > pairData.maxQuantity) {
                setError({ ...errors, ...{ 'quantity': `Volume shouldn't be greater than ${pairData.maxQuantity}` } })
                setVolume(volume)
                return
            }
            else if (volume >= 10) {
                value = volume + 10
                setVolume(value)
            } else if (volume >= 1) {
                value = volume + 1
                setVolume(value)
            }
            else if (volume >= 0.1) {
                value = (volume + 0.1).toFixed(1)
                setVolume(parseFloat(value))
            }
            else {
                value = (volume + 0.01).toFixed(2)
                setVolume(parseFloat(value))
            }
            await calculateQuantity(value)
        } catch (err) {
            console.log(err)
        }
    }
    const decreaseVolume = async (volume) => {
        try {
            let value
            if (volume < pairData.minQuantity) {
                value = 0
                setError({ ...errors, ...{ 'quantity': `Volume shouldn't be less than ${pairData.minQuantity}` } })
                setVolume(value)
                return
            }
            else if (volume > 10) {
                value = volume - 10
                console.log(volume, 'decreaseVolume')
                setVolume(value)
            }
            else if (volume > 1) {
                value = volume - 1
                console.log(volume, 'decreaseVolume')
                setVolume(value)
            }
            else if (volume > 0.1) {
                value = (volume - 0.1).toFixed(1)
                setVolume(parseFloat(value))
            }
            else {
                value = (volume - 0.01).toFixed(2)
                setVolume(parseFloat(value))
            }
            await calculateQuantity(value)
        } catch (err) {
            console.log(err)
        }
    }
    return (
        <>
            {neworder && <NewOrder onDismiss={() => setNewOrder(false)} openinfo={() => { setInfo(true); setNewOrder(false) }} />}
            {whishlist && <CreateWishlist onDismiss={() => setWishlist(false)} refresh={() => props.refresh()} />}
            <div className={props.expand ? "d-none" :  'shrinked d-flex jc-between'} onClick={() => { handlePairChange(props?.data) }}>
                <div className='d-flex gap-2 align-items-center'>
                    <span className='fa fa-check text-light'></span>
                    <p>{props?.data?.displayname}</p>
                </div>
                <div className='d-flex gap-2'>
                    <p className='text-secondary'>
                        {/* +3887.8 (+2.49%) */}{Markupprice && Markupprice.change}
                    </p>
                    <p className='text-danger'>{Markupprice && Markupprice.disbid ? Markupprice?.disbid : props.data?.bid?.toFixed(props.data.pip_position)}
                        <span className='text-danger lastnumber'>
                            {Markupprice && Markupprice.bid ? Markupprice?.pipBid : BidandAskDisplay(props.data?.bid, props.data.precision)}
                        </span>
                    </p>
                    <p className='text-success'>{Markupprice && Markupprice?.disask ? Markupprice?.disask : props.data?.ask?.toFixed(props.data.pip_position)}
                        <span className='text-success lastnumber'>
                            {Markupprice && Markupprice.ask ? Markupprice?.pipAsk : BidandAskDisplay(props.data?.ask, props.data.precision)}
                        </span>
                    </p>

                </div>
            </div>
            <div className={props.expand ? 'expanded w-full' : ' d-none'} >
                <div className='d-flex jc-between align-items-center'>
                    <p><span className='fa fa-check text-success mr-1'></span>{props?.data?.displayname}</p>
                    <div className='d-flex'>
                        <Dropdown autoClose="outside" className='topdrop '>
                            <Dropdown.Toggle variant="success" id="dropdown-basic" className='charticon'>
                                {/* <span className='fa fa-star ml-2 plus_common'></span> */}
                                <img src={star} />
                            </Dropdown.Toggle>

                            {/* <Dropdown.Menu>
                                <InputGroup className="mb-2 dark p-1">
                                    <Form.Control />
                                    <InputGroup.Text id="basic-addon2"><span className='fa fa-search'></span></InputGroup.Text>
                                </InputGroup>
                            </Dropdown.Menu> */}
                            <Dropdown.Menu>
                                {props.wishlist && props.wishlist.map((val) => <>
                                    <Dropdown.Item href="#" onClick={() => handlewishlist(val?.name, props?.data?._id)}><i className={val.wished.find((e) => e._id === props.data._id) ? "fa fa-check" : "d-none"}></i>&nbsp;&nbsp;<p>{val?.name}</p></Dropdown.Item>
                                </>)}
                                <hr className='my-2' />
                                <Dropdown.Item onClick={() => { setWishlist(true); props.id(props?.data?._id) }} href="#">New Wishlist</Dropdown.Item>
                            </Dropdown.Menu>

                        </Dropdown>
                        <button className='charticon' onClick={() => { handlePairChange(props?.data) }}><img src={chart} /></button>
                        <button className='charticon' onClick={() => { handlePairChange(props?.data); setNewOrder(true) }} ><img src={file} /></button>
                    </div>
                </div>
                <div className='d-flex jc-between'>
                    <p>{props?.data?.description}</p>
                    <p className='text-success pr-1'>
                        {/* +3347.1 (+2.07%) */}{Markupprice && Markupprice.change}
                    </p>
                </div>
                <div className='d-flex my-1 jc-between px-0'>
                    {/* <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic" className='dark-select'>
                            SELL LIMIT
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {sellorder && sellorder.map((val,index)=>(
                                <Dropdown.Item href="#/action-1" onClick={()=>{handleSubmit('sell',val._id)}}>{val._id}</Dropdown.Item>
                            ))}
                            
                        </Dropdown.Menu>
                    </Dropdown> */}
                    {/* <div className='d-flex'> */}
                    <p>L: {Markupprice && Markupprice?.low ? Markupprice?.low : props?.data?.low}</p>
                    {/* {console.log('Markupprice-whislist',Markupprice.high)} */}
                    <p>Spread: {Markupprice && Markupprice?.spread ? Markupprice?.spread : props?.data?.spread}</p>
                    <p>H: {Markupprice && Markupprice?.high ? Markupprice?.high : props?.data?.high}</p>
                    {/* </div> */}
                    {/* <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic" className='dark-select'>
                            BUY LIMIT
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {buyorder && buyorder.map((val,index)=>(
                                <Dropdown.Item href="#/action-1" onClick={()=>{handleSubmit('buy',val._id)}}>{val._id}</Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown> */}
                </div>
                <p className='text-center mb-4'>Volume in lots</p>
                <div className='d-flex flex-1 gap-2 sellbuybtn marg_top_20px'>
                    <div className='selectcount_new selectnew_dark'>
                        <span className="text-danger erro_abs_side">
                            {errors && errors.quantity}
                            {/* error */}
                        </span>
                        <div className='input_grp_number top_decrease'>
                            <input className="dark-select no_arr_num"
                                type='text'
                                name="quantity"
                                min={pairData.minQuantity}
                                value={volume}
                                // min={props.data.minQuantity}
                                // value={quantity} 
                                onChange={handleChange} />
                            <div className='input-group-append font_sm_ap' onClick={() => { increaseVolume(volume) }}>
                                <i className='fa fa-plus' />
                            </div>
                            <div className='input-group-append font_sm_ap' onClick={() => { decreaseVolume(volume) }}>
                                <i className='fa fa-minus' />
                            </div>
                        </div>
                    </div>

                    {/* <span className="text-danger">{errors && errors.quantity}</span> */}
                    {/* <select className="dark-select selectcount" name='quantity' onChange={handleChange}>
                        {limit.map((val, i) => {
                            //console.log("mapping", val, i, props?.data?.interval);
                            // setLimit(limit)
                            
                            return (
                                <>
                                    <option value={contract * (i + 1)}>{contract  * (i + 1)} {props?.data?.firstCurrencySymbol}</option>
                                </>)
                        })}
                    </select> */}
                    <button className='btn bg-orange' onClick={() => { handleSubmit('sell', Markupprice.bid) }}>
                        <p className='text-left'>Sell</p>
                        <p className='text-right'>{Markupprice && Markupprice.disbid ? Markupprice?.disbid : props.data?.bid?.toFixed(props.data.pip_position)}
                            <span className='sellnumber'>
                                {Markupprice && Markupprice.bid ? Markupprice?.pipBid : BidandAskDisplay(props.data?.bid, props.data.precision)}
                            </span>
                        </p>
                    </button>
                    <button className='btn bg-success' onClick={() => { handleSubmit('buy', Markupprice.ask) }}>
                        <p className='text-right'>Buy</p>
                        <p className='text-left'>{Markupprice && Markupprice?.disask ? Markupprice?.disask : props.data?.ask?.toFixed(props.data.pip_position)}
                            <span className='buynumber'>
                                {Markupprice && Markupprice.ask ? Markupprice?.pipBid : BidandAskDisplay(props.data?.ask, props.data.precision)}
                            </span>
                        </p>
                    </button>
                </div>
            </div>
        </>
    )
}

export default Singelwhislist
