import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams ,useHistory} from "react-router-dom";
import ProgressBar from 'react-bootstrap/ProgressBar';
import ReactDatatable from '@ashvin27/react-datatable';
import { toastAlert } from "lib/toastAlert";



import Pagination from "../components/Dashboard/pagination";
// import action
import { gettradeHistory } from "../actions/dashboardAction";
import { getreferraltree } from "../actions/dashboardAction"
import { getreferralhistory } from "../actions/dashboardAction";
import favicon from "../assets/images/pfimg.png"
import CopyToClipboard from "react-copy-to-clipboard";
import profileimg from "../assets/images/prifileimg.png"
 import Referralmoredata from "../pages/Referralmoredata"
// import lib
import { dateTimeFormat } from "../lib/dateTimeHelper";
import { CompareArrowsOutlined, ImportExport } from "@material-ui/icons";
import isEmpty from "lib/isEmpty";
import keys from "../config/index";



const Referrallist = () => {
  const accountData = useSelector(state => state.account);
  const { referralCode } = useSelector(state => state.auth);
  console.log("referrr----",referralCode);
  const state = useSelector(state => state.auth);
  const [copy, setCopy] = useState('');
  const [data, setData] = useState([]);
  const history = useHistory();
const [historys,sethistory]=useState("")
console.log("historyshistoryshistoryshistorys",historys);
  const [usersdata, setUserdata] = useState({});
  console.log("usersdatausersdata",usersdata);
  const [loaddata, setLoadData] = useState([]);
   console.log("loaddata----------->",loaddata);
  const [count, setcount] = useState(0);
  const [loader, setLoader] = useState(true);
  const [treeToggle, treeToggleState] = useState(false)
  console.log("treeToggle-------",treeToggle);
  const [reportData, setreportData] = useState([]);
  const { Image } = accountData;
  const [showChild, setShowChild] = useState(-1);
  const {id}= useParams()
  // console.log("id------------->>",id);
  const type=localStorage.getItem("liveorDemo")
  const [filter, setFilter] = useState({
    'page': 1,
    'limit': 5,
  })
  function handlePagination(index) {
    let filterData = { ...filter, ...{ 'page': index.page_number, 'limit': index.page_size } }


    setFilter(filterData);
    fetchTransaction(filterData);
  }
  // const referralmoredata = async (referralCode) => {
  //   try {
  //     let req = { referralCode: referralCode }
  //     const { status, loading, result } = await getreferraltree(req)
  //     console.log("result---------------ss-----s----->", result);
  //     if (status == "success") {
  //       setUsersdata(result);
  //       setcount(result.length)
  //       // setLoadData(loaddata.concat(result));


  //     }
  //   }
  //   catch (err) {

  //   }
  // }
const moredatas = async (loaddata)=>{  
  history.push('/tradedashboard/'+ loaddata)
}

  let reqData = {
    referralCode: id,
    skip: 0,
    limit: 5
  }

  const referraltree = async () => {
    try {
      const { status, loading, result } = await getreferraltree(reqData)
      console.log("result--dataaaa-->", result);
      if (status == "success") {
        setLoadData(result.referrenceCode);
        setUserdata(result.user)

      }
    }
    catch (err) {

    }
  }
  let reqDatas = {
    referralCode: id,
    skip: loaddata.length,
    limit: 5
  }
  console.log("reqDatas-------",reqDatas);
  const loadreferraltree = async () => {
    try {
      const { status, loading, result } = await getreferraltree(reqDatas)
      console.log("result--bbfff-->", result);
      if (status == "success") {
        setLoadData(loaddata.concat(result.referrenceCode));

      }
    }
    catch (err) {

    }
  }


  const getreferral = async () => {
    try {
      const result = await getreferralhistory(reqData)
      console.log("resultresult--------",result);
      if (result.status == "success") {
        result.result.userArray.map((res, i) => {
          res['foloweCount'] = result.result.followeCountArray[i].length
        })

        sethistory(result.result.userArray)
      }


    } catch (err) {

    }
  }

  const fetchTransaction = async () => {
    try {
      const { status, loading, result, count, reportData } = await gettradeHistory(filter,type);
      // console.log("result-------------------------------->>", result);
      setLoader(loading);
      if (status == "success") {
        setData(result);
        setreportData(reportData);
        // setcount(count)

        // const headers = [
        //   [
        //     "Date",
        //     "Transaction Id",
        //     "Trade Type",
        //     "Contract",
        //     "Price",
        //     "Quantity",
        //     "Order Value",
        //   ],
        // ];

        // const download_data =
        //   reportData &&
        //   reportData.length > 0 &&
        //   reportData.map((elt) => [
        //     dateTimeFormat(elt.createdAt, "YYYY-MM-DD HH:mm"),
        //     elt._id,
        //     elt.orderType == "limit" ? "Limit" : "Market",
        //     elt.firstCurrency + elt.secondCurrency,
        //     elt.price,
        //     elt.quantity,
        //     elt.orderValue && elt.orderValue.toFixed(4),
        //   ]);
        // let download_csv = headers.concat(download_data);

        // if (reportData &&
        //   reportData.length > 0) {
        //   setDownloadData(download_csv);

        // }
      }
    } catch (err) { }
  };
  const handleChanges = async (e) => {
    setCopy(e.target.value);
  }
  const copyCode = (data) => {
    if (data) {
      toastAlert('success', 'Referral Code copied')
    }
    else {
      toastAlert('error', 'no respond can found')
    }


  }

  useEffect(() => {
    getreferral();
    referraltree();
    fetchTransaction();
    // loadreferraltree();
  }, [referralCode,id]);
  const columns = [
    {
      key: "referralCode",
      text: "ID",
      className: "id",
      align: "center",
      sortable: true,
    },
    {
      key: "firstName",
      text: "User Name",
      className: "username",
      align: "center",
      sortable: true,
      cell : historys => {
   return historys?.firstName ? historys?.firstName : "-"
    }
    },
    {
      key: "foloweCount",
      text: "Referral Count",
      className: "referralcount",
      align: "center",
      sortable: true,
      cell: record => {
        return record?.foloweCount
      }
    },
    // {
    //   key: "status",
    //   text: "Interest",
    //   className: "interest",
    //   align: "center",
    //   sortable: true
    // },
    {
      key: "commission",
      text: "Commission",
      className: "commission",
      align: "center",
      sortable: true,
      cell:historys=>{
            return historys?.commission ? parseFloat(historys.commission).toFixed(4) : 0
      }
    },




  ];
  const config = {
    page_size: 5,
    length_menu: [5, 10, 20, 50],
    filename: "Order",
    no_data_text: 'No Records found!',
    language: {
      length_menu: "Show _MENU_ Entries",
      filter: "Search",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: <i class="fa fa-backward" aria-hidden="true" />,
        previous: <i class="fa fa-chevron-left" />,
        next: <i class="fa fa-chevron-right" />,
        last: <i class="fa fa-forward" aria-hidden="true" />
      }
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: false,
  };


  const currentdata = [{
    id: '12233', referralCode
  },
  {
    id: '12233',
    username: 'Withdrawal for 1000902',
    referralcount: '6 NO.s',

    interest: '2%',
    commission: '10%',

  },
  {
    id: '12233',
    username: 'Withdrawal for 1000902',
    referralcount: '6 NO.s',

    interest: '2%',
    commission: '10%',

  },
  ];
console.log(keys.API_URL,'API_URL')
  return (
    <div className='referrallist'>
      <div className='container custom-container1'>
        {/* {console.log('gdfgyugyuigghu', history)} */}
        <div className="headsec my-3">
          <div className="leftsec" >

            <h5 >Referral</h5>
            <button onClick={() => treeToggleState(!treeToggle)} className="btn btn-blue-back" >{treeToggle ? "Tree view" : "Table View"}</button>
          </div>
          <div className="referralcodecopy referralcodecopy_inside_ref">

            <div > <label htmlFor="minimum">Referral code:</label></div>
            <div className="inputcopy"> <input name="minimumAmount" value={referralCode} type="text" className="form-control" onChange={(e) => handleChanges(e)} /><span>
              <CopyToClipboard text={referralCode} onCopy={() => copyCode(referralCode)}><div><button class="fa fa-copy copybtn"> </button></div></CopyToClipboard></span>  </div>
          </div>
        </div>
        <br />
        <hr />
        <div className='row my-3 mx-0'>
          <div className={treeToggle ? "table-responsive" : "d-none"} >

            <ReactDatatable className="table table-bordered table-striped custome_table_responsive"
              config={config}
              records={historys}
              columns={columns}
              dynamic={false}
              total_record={count}
              onChange={handlePagination}
            />
          </div>
  
           {treeToggle == false ? <button className="btn btn-grey-back" onClick={()=>{history.push('/tradedashboard/'+ referralCode)} }>Back</button> : ""}
          <div className={treeToggle ? "d-none" : "d-block col-12 px-0"} >

            <div className="treemap">

              <div class="container">
                <div class="horizontal">


                  <div class="verticals twelve">
                    <section class="management-tree">
                      <div class="mgt-container">
                        <div class="mgt-wrapper">

                          <div class="mgt-item">

                            <div class="mgt-item-parent parent_first">
                              {/* <div class="person">
                                <img src={favicon} alt="" />
                                <p class="name">Admin</p>
                              </div> */}
                            </div>
                            <div class="mgt-item-mainparent">
                              <div class="person">
                                {(usersdata?.Image && usersdata?.Image) ? <img src={`${keys.API_URL}/images/kyc/${usersdata?.Image}`} alt="profileicon" /> : <img className="prof_icon_header" src={profileimg} />}

                                <p class="name">{`${usersdata?.firstName}  ${usersdata?.lastName}`}</p>

                              </div>
                            </div>
                            <div class="mgt-item-children">


                              {
                                loaddata && loaddata.length > 0 ? loaddata.map((item, index) => (
                                  <>
                                    <div class="mgt-item-child">
                                      <div class="mgt-item">
                                        <div class="mgt-item-parent cur_pointer_tree" onClick={() => { moredatas(item.referralCode); setShowChild(showChild == index ? "-1" : index) }}>
                                          <div class="person">
                                            {(item.Image && item.Image) ? <img src={`${keys.API_URL}/images/kyc/${item.Image}`} className="prof_icon_header" alt="profileicon" /> : <img className="prof_icon_header" src={profileimg} />}

                                            <p>{item.firstName ? item.firstName : "-"}</p>

                                          </div>
                                        </div>
                                        <div class="mgt-item-children">
                                          <div class="mgt-item-child grand_child">
                                            <div class="person">
                                              <div class="mgt-item-mainparent main_parent_no_after">


                                                
                                     
                                    
                                                {/* {index == showChild ?
                                                  <>
                                                    <Referralmoredata usersdata= {usersdata} referralmoredata={referralmoredata}/>
                                                  </>
                                                  :
                                                  <></>
                                                } */}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  </>))
                                  :
                                  <div className="empty_child">
                                    <p>No data</p>
                                  </div>

                              }


                              {loaddata && loaddata.length > 0 ?
                                <div class="mgt-item-child lastchildadd">
                                  <div class="mgt-item cur_pointer_tree" onClick={loadreferraltree}>


                                    <div class="person">
                                      <a class="button circle" href="#">
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                :
                                ""
                              }

                            </div>
                          </div>

                        </div>
                      </div>
                    </section>
                  </div>



                </div>
              </div>



            </div>







          </div>



        </div>

      </div>

    </div>
  );

}

export default Referrallist;