import React, { Component, useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap'
import Chart from "react-apexcharts";
const Volume = (props) => {
  console.log("Volume_props", props)
  const { summaryDetails } = props


  return (
    <div className="tab-content">
      <p className="boxheadings mb-1">Volumes</p>
      <div className="darkbox p-3">
        <ul className="pl-0 symbolTab_list">


          <li>
            <div className="d-flex p-0 justify-content-between">
              <small className="text-light">Traded (Total)</small>
              <small className="text-white">USD {props && summaryDetails?.tradedTotal == undefined ? 0 : summaryDetails?.tradedTotal.toFixed(3)}</small>
            </div>
          </li>
          <li>
            <div className="d-flex p-0 justify-content-between">
              <small className="text-light">
                Copied (Total)
              </small>
              <small className="text-white">USD  {props && summaryDetails?.copiedTotal == undefined ? 0 : summaryDetails?.copiedTotal.toFixed(3)}</small>
            </div>
          </li>

          <li>
            <div className="d-flex p-0 justify-content-between">
              <small className="text-light">
                Copied (Live)
              </small>
              <small className="text-white">USD {props && summaryDetails?.copiedLive == undefined ? 0 : summaryDetails?.copiedLive.toFixed(3)}</small>
            </div>
          </li>
          <li>
            <div className="d-flex p-0 justify-content-between">
              <small className="text-light">
                Avg. Volume Traded per Day
              </small>
              <small className="text-white">USD {props && summaryDetails?.tradedPerDay == undefined ? 0 : summaryDetails?.tradedPerDay.toFixed(3)}</small>
            </div>
          </li>
          <li>
            <div className="d-flex p-0 justify-content-between">
              <small className="text-light"> Avg. Volume Copied per Day</small>
              <small className="text-white">
                USD {props && summaryDetails?.copiedPerDay == undefined ? 0 : summaryDetails?.copiedPerDay.toFixed(3)}
              </small>
            </div>
          </li>


        </ul>
      </div>

    </div>
  )
}

export default Volume