import React, { useState } from 'react';
import {
  Modal, Tabs, Dropdown, InputGroup, Form, Tab, Col,
  Row
} from 'react-bootstrap'
import Slider from "@material-ui/core/Slider";
import { Details } from '@material-ui/icons';
const MoreFilter = (props) => {
  console.log('MoreFilter_props', props)
  const [morefilter, setMoreFilter] = useState(true)
  const [valueone, setValueOne] = React.useState([0]);
  const [valuetwo, setValueTWo] = React.useState([10]);
  const [valuethree, setValueThree] = React.useState([0]);
  const [valuefour, setValueFour] = React.useState([0]);
  const [valuefive, setValueFive] = React.useState([0]);
  const [valuesix, setValueSix] = React.useState([0]);

  // Filter-Details
  const [strategyAge, setStrategyAge] = useState([""]);
  const [volume, setVolume] = React.useState(0);
  const [copyingCapital, setCopyingCapital] = React.useState(0);
  const [copyier, setCopyier] = React.useState(0);
  const [ownFunds, setOwnFunds] = React.useState(0);
  const [investorFunds, setInvestorFunds] = React.useState(0);

  function valuetext(value) {
    return `${value}%`;
  }
  function valuetextone(valueone) {
    return `${valueone}`;
  }
  function valuetexttwo(valuetwo) {
    return `${valuetwo}`;
  }
  function valuetextthree(valuetthree) {
    return `${valuetthree}`;
  }
  function valuetextfour(valuetfour) {
    return `${valuetfour}`;
  }
  function valuetextfive(valuetfive) {
    return `${valuetfive}`;
  }
  function valuetextsix(valuetsix) {
    return `${valuetsix}`;
  }

  const handleChangeTwo = (event, newValue) => {
    setValueTWo(newValue);
  };
  const handleChangeThree = (event, newValue) => {
    setValueThree(newValue);
  };
  const handleChangeFour = (event, newValue) => {
    setValueFour(newValue);
  };
  const handleChangeFive = (event, newValue) => {
    setValueFive(newValue);
  };
  const handleChangeSix = (event, newValue) => {
    setValueSix(newValue);
  };

  const handleChange = (newValue, type) => {
    console.log("moreFilter_handleChange", newValue, type)
    if (type == 'volume') {
      setVolume(newValue);
    }
    if (type == 'copyier') {
      setCopyier(newValue);
    }
    if (type == 'ownFunds') {
      setOwnFunds(newValue);
    }
    if (type == 'copyingCapital') {
      setCopyingCapital(newValue);
    }
    if (type == 'investorFunds') {
      setInvestorFunds(newValue)
    }
  }
  const filterSearchDatas = async () => {
    let data = {}
    data.ownFunds = parseFloat(ownFunds)
    data.copyingCapital = parseFloat(copyingCapital)
    data.copyier = parseFloat(copyier)
    data.volumeFee = parseFloat(volume)
    props.onSet(data)
    props.onDismiss()
  }
  return (
    <>
      <Modal show={morefilter} className="modalbg filtermodal font-12 darkbox blur_darkbox" size="md" centered scrolable>
        {console.log('status_morefilter', volume, copyier, ownFunds, copyingCapital)}
        {/* <Modal.Header className='align-items-center '> 
               <Modal.Title>AddFunds - TradingOne</Modal.Title>
               <button className='closebtn btn btn-link text-light nounder' onClick={() => props.onDismiss()}>
               <i className="fa fa-times fa-6" aria-hidden="true"></i>

                </button>
            </Modal.Header> */}
        <Modal.Body className='font-12 py-0 darkbox'>
          <Row className="filter_button_detail ">
            <Col className="w-100 p-3" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
              {/* <span>Strategy Age</span>
              <br />
              <select
                className="w-100 mt-2 themeinput bg-black"
                name="values"
                id="days"
                form="carform"
              >
                <option value="Any">Any</option>
                <option value="1_week">1 week</option>
                <option value="1_month">1 Month</option>
                <option value="3_month">3 Month</option>
                <option value="6_month">6 Month</option>
                <option value="1_year">1 Year</option>
              </select>

              <div className="mt-2">
                            <span>Management Fee</span>
                            <Slider
                              getAriaLabel={() => "Temperature range"}
                              value={valueone}
                              onChange={handleChange}
                              valueLabelDisplay="auto"
                              getAriaValueText={valuetextone}
                            />
                            <div className="copiers p-0 d-flex justify-content-start align-items-center">
                              <input
                                className="themeinput bg-black"
                                type="text"
                                defaultValue="0"
                              />
                              <div className="d-inline">
                                <p>---</p>
                              </div>
                              <input
                                className="themeinput bg-black"
                                defaultValue="500+"
                                type="text"
                              />
                              <span>% p.a.</span>
                            </div>
                          </div>

              <div className="mt-2">
                            <span>Investor funds (live)</span>
                            <Slider
                              getAriaLabel={() => "Temperature range"}
                              value={investorFunds}
                              onChange={(e, newValue) => { handleChange(newValue, 'investorFunds') }}
                              valueLabelDisplay="auto"
                              getAriaValueText={valuetexttwo}
                              max={100}
                            />
                            <div className="copiers p-0 d-flex justify-content-start align-items-center">
                              <input
                                className="themeinput bg-black"
                                type="text"
                                defaultValue="0"
                                value={investorFunds}
                                onChange={(e)=> {setInvestorFunds(e.target.value)}}
                            />&nbsp;
                              <span>%</span>
                            </div>
                          </div> */}

              <div className="mt-2">
                <span>Volume Fee</span>
                <Slider
                  getAriaLabel={() => "Temperature range"}
                  value={volume}
                  onChange={(e, newValue) => { handleChange(newValue, 'volume') }}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetextthree}
                  max={100}
                />
                <div className="copiers p-0 d-flex justify-content-start align-items-center">
                  <input
                    className={volume > 10 ? "themeinput bg-black" : "themeinput bg-black"}
                    type="text"
                    defaultValue="0"
                    value={volume}
                    onChange={(e) => { setVolume(e.target.value) }}
                  />&nbsp; &nbsp;
                  <span>USD per mil.</span>
                </div>
              </div>
            </Col>

            <Col
              className="w-100 p-3"
              xxl={6}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <span>Copiers</span>
              <Slider
                getAriaLabel={() => "Temperature range"}
                value={copyier}
                onChange={(e, newValue) => { handleChange(newValue, 'copyier') }}
                valueLabelDisplay="auto"
                getAriaValueText={valuetextfour}
                max={500}
              />
              <div className="copiers p-0 d-flex justify-content-start align-items-center">
                <input
                  className={copyier > 500 ? "themeinput bg-black" : "themeinput bg-black"}
                  type="text"
                  defaultValue="0"
                  value={copyier >= 500 ? "500+" : copyier}
                  onChange={(e) => { setCopyier(e.target.value) }}
                />&nbsp;
                {/* <div className="d-inline">
                              <p>---</p>
                            </div>
                            <input
                              className="themeinput bg-black"
                              defaultValue="500+"
                              type="text"
                            /> */}
              </div>



              {/* <div className="mt-2">
                <span>Own Funds</span>
                <Slider
                  getAriaLabel={() => "Temperature range"}
                  value={volumeFee}
                  onChange={(e, newValue) => { handleChange(newValue, 'volumeFee') }}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetextsix}
                  max={1000000}
                />
                <div className="copiers p-0 d-flex justify-content-start align-items-center">
                  <input
                    className={volumeFee > 100 ? "themeinput bg-black" : "themeinput bg-black"}
                    type="text"
                    defaultValue="0"
                    value={volumeFee}
                    onChange = {(e)=> {setVolumeFee(e.target.value)}}
                  />&nbsp;
                  <div className="d-inline">
                                <p>---</p>
                              </div>
                              <input
                                className="themeinput bg-black"
                                defaultValue="1M+"
                                type="text"
                              />
                  <span>USD</span>
                </div>
              </div> */}
            </Col>


            <Col className="w-100 p-3" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
              <div className="mt-2">
                <span>Copying Capital (Live)</span>
                <Slider
                  getAriaLabel={() => "Temperature range"}
                  value={copyingCapital}
                  onChange={(e, newValue) => { handleChange(newValue, 'copyingCapital') }}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetextfive}
                  max={1000000}
                />
                <div className="copiers p-0 d-flex justify-content-start align-items-center">
                  <input
                    className={copyingCapital > 1000000 ? "themeinput bg-black" : "themeinput bg-black"}
                    type="text"
                    defaultValue="0"
                    value={copyingCapital == 1000000 ? "1M" : copyingCapital}
                    onChange={(e) => { setCopyingCapital(e.target.value) }}
                  />&nbsp;
                  {/* <div className="d-inline">
                                <p>---</p>
                              </div>
                              <input
                                className="themeinput bg-black"
                                defaultValue="1M+"
                                type="text"
                              /> */}
                  <span>USD</span>
                </div>
              </div>
            </Col>

            <Col className="w-100 p-3" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >

              <div className="mt-2">
                <span>Own Funds</span>
                <Slider
                  getAriaLabel={() => "Temperature range"}
                  value={ownFunds}
                  onChange={(e, newValue) => { handleChange(newValue, 'ownFunds') }}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetextsix}
                  max={1000000}
                />
                <div className="copiers p-0 d-flex justify-content-start align-items-center">
                  <input
                    className={ownFunds > 1000000 ? "themeinput bg-black" : "themeinput bg-black"}
                    type="text"
                    defaultValue="0"
                    value={ownFunds == 1000000 ? "1M" : ownFunds}
                    onChange={(e) => { setOwnFunds(e.target.value) }}
                  />&nbsp;
                  {/* <div className="d-inline">
                                <p>---</p>
                              </div>
                              <input
                                className="themeinput bg-black"
                                defaultValue="1M+"
                                type="text"
                              /> */}
                  <span>USD</span>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className='py-3 darkbox br-0' >
          <button className="btn btn-gray py-1" onClick={() => { setCopyier(0); setCopyingCapital(0); setVolume(0); setOwnFunds(0); setInvestorFunds(0) }}>Reset</button>
          <button className="btn green-btn py-1 ml-auto" onClick={() => { filterSearchDatas() }}>Apply</button>
          <button className="btn btn-gray py-1 ml-2" onClick={() => { props.onDismiss() }}>Cancel</button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default MoreFilter