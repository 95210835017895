// import packageUserToken
import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink, useHistory, useLocation, useParams } from "react-router-dom";
import { Hidden, Button, Menu, MenuItem } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TimeAgo } from "@n1ru4l/react-time-ago";
import SocketContext from "../Context/SocketContext";
import Profileicon from "../../assets/images/circle_card_section.png"
import { ListItem } from "@material-ui/core";
import profileimg from "../../assets/images/prifileimg.png"
import config from "../../config";

import { Dropdown } from "react-bootstrap";

import { SET_USER_ACCOUNTS } from "constant";

// import action
import { logout, SetAccounts, setAccountData } from "../../actions/users";
import { setTradeTheme, setTheme } from "../../actions/commonAction";
import {
  readNotification,
  FetchunReadNotice,
  noticePopup,

} from "../../actions/notificationAction";
import Accounttype from "../../components/SportTrade/newtrade/Modals/Accounttype";

//lib
import { momentFormat } from "../../lib/dateTimeHelper";
import isEmpty from "lib/isEmpty";
import { toLocaleString } from "lib/roundOf";


import Kycinitialmodal from "../SportTrade/newtrade/Modals/Kycinitialmodal"

export default function HeaderLinks(props) {
  const dispatch = useDispatch();
  const socketContext = useContext(SocketContext);
  const history = useHistory();
  const { trade } = useParams()
  const location = useLocation()
  console.log(location, "tardeeeeeeeeeeeeeeeeee");



  const routeMatch = useRouteMatch();
  const { t, i18n } = useTranslation();
  var hello = localStorage.getItem("liveorDemo")
  // console.log("hello",hello);

  const [livedemos, setLiveDemo] = useState(hello)
  // console.log("livedemos--->",livedemos);
  // state
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElNoti, setAnchorElNoti] = React.useState(null);

  const [acctype, setAcctype] = useState(false);

  const [headerActive, setHeaderActive] = useState("home")
  const [liveCount,setLivecount]=useState(0)
  const [demoCount,setDemocount]=useState(0)

  //localStorage
  const localAccount = localStorage.getItem('accounts')
  // redux state
  const themeData = useSelector((state) => state.theme);
  const { isAuth } = useSelector((state) => state.auth);
  // const AccountData = useSelector((state) => state.account);
  const { unread, isOpen } = useSelector((state) => state.notice);
  // console.log("isAuth", isAuth)
  // redux-state
  const accountData = useSelector(state => state.account);

  const { firstName, lastName, email, blockNo, address, state, city, postalCode, country, Image, accounts, useraccount } = accountData;
  console.log("accountData-gg------->", accountData, account, accounts)
  useEffect(() => {
    console.log("hello buddy")
    // if(isEmpty(localStorage.getItem('headerActive'))){
    localStorage.setItem('headerActive', 'home')
    let pairName = isEmpty(localStorage.getItem('curpair')) ? accountData.viewedpair : localStorage.getItem('curpair')
    if (location.pathname == "/tradedashboard") {
      localStorage.setItem('headerActive', 'home')
    } else if (location.pathname == `/trade/${pairName}`) {
      localStorage.setItem('headerActive', 'arrows')
    } else if (location.pathname == "/support-ticket") {
      localStorage.setItem('headerActive', 'excla')
    }
    // }
    CreateInterval()
  }, [location, accountData])

  const CreateInterval = () => {
    try {
      const { state } = location
      let oldintervalId = localStorage.getItem('getProfitCalculation')
      let pairName = isEmpty(localStorage.getItem('curpair')) ? accountData.viewedpair : localStorage.getItem('curpair')
      if (oldintervalId) {
        clearInterval(oldintervalId);
        localStorage.removeItem('getProfitCalculation')
      }
      if (location.pathname == '/copyingAccount') {
        const { Followerdetails, strategydetails } = state
        const intervalId = setInterval(async () => {
          let data = {
            accountId: Followerdetails.followerAccountId,
            pairName: pairName,
            traderAccountId: Followerdetails.traderAccountId
          }
          socketContext.socket.emit('getporfitcalculation', data)
        }, 2000)
        localStorage.setItem('getProfitCalculation', intervalId)
      }
      else if (location.pathname == `/trade/${pairName}`) {
        console.log('CreateInterval')
        const intervalId = setInterval(async () => {
          let accountId = localStorage.getItem('accounts')
          // console.log(accountId, 'getporfitcalculation')
          let data = {
            accountId: accountId,
            pairName: pairName
          }
          socketContext.socket.emit('getporfitcalculation', data)
        }, 2000)
        localStorage.setItem('getProfitCalculation', intervalId)
      }
      else if (location.pathname == "/strategy") {
        const { strategydetails } = state
        console.log('CreateInterval')
        const intervalId = setInterval(async () => {
          let data = {
            accountId: strategydetails.accountId,
            pairName: pairName,
            userId: accountData.userId
          }
          socketContext.socket.emit('getporfitcalculation', data)
        }, 2000)
        localStorage.setItem('getProfitCalculation', intervalId)
      }
      else if (location.pathname == "/providingstrategy") {
        const { accountId } = state
        console.log('CreateInterval')
        const intervalId = setInterval(async () => {
          let data = {
            accountId: accountId,
            pairName: pairName
          }
          socketContext.socket.emit('getporfitcalculation', data)
        }, 2000)
        localStorage.setItem('getProfitCalculation', intervalId)
      }
      else if (location.pathname == "/tradedashboard") {
        console.log('CreateInterval')
        const intervalId = setInterval(async () => {
          let accountId = localStorage.getItem('accounts')
          console.log(accountId, 'getporfitcalculation')
          let data = {
            accountId: accountId,
            pairName: pairName
          }
          socketContext.socket.emit('getporfitcalculation', data)
        }, 2000)
        localStorage.setItem('getProfitCalculation', intervalId)
      }
      console.log('CreateInterval', location.pathname == `/strategy`, pairName)
    } catch (err) {
      console.log(err, 'CeateInterval__errr')
    }
  }
  const [account, Setaccount] = React.useState()

  const [kycinitialvalidate, setKycinitialvalidate] = useState(false)


  // function
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  // const livedemoss = localStorage.getItem('liveorDemo');
  // console.log("themeeeeee---",livedemoss);
  // setLiveDemo(livedemo)

  const handleClickNoti = (event) => {
    setAnchorElNoti(event.currentTarget);
  };

  const OnSelect = (val, e) => {
    e.preventDefault()
    Setaccount(val)
    let localAcc = localStorage.getItem('accounts')
    if (localAcc) {
      localStorage.removeItem('accounts')
      localStorage.setItem('accounts', val.accountId)
      localStorage.setItem('liveorDemo', val.type)
      dispatch(SetAccounts(val))

    }
    else {
      localStorage.setItem('accounts', val.accountId)
      localStorage.setItem('liveorDemo', val.type)
      dispatch(SetAccounts(val))
    }
  }
  const handleClickNotification = async (event, val) => {
    // alert(1);
    if (val == "readall") {
      let { staus, message } = await readNotification();
    }
    setAnchorElNoti(event.currentTarget);
    // document.getElementsByTagName("body")[0].style.overflow ="auto";
    document.getElementsByTagName("body")[0].classList.add("padi_over_body");


  };
  const handleCloseNotification = () => {
    setAnchorElNoti(null);
    document.getElementsByTagName("body")[0].classList.remove("padi_over_body");

    // document.getElementsByTagName("body")[0].style.overflow ="auto";
    // document.getElementsByTagName("body")[0].style.paddingRight ="0px";

  };
  const handleCloseNoti = () => {
    setAnchorElNoti(null);
  };
  const livedemo = (e) => {
    console.log("eeee", e);
    localStorage.setItem("liveorDemo", e)
    // const livedemoss = localStorage.getItem('liveorDemo');
    // console.log("themeeeeee---",livedemoss)
    // setLiveDemo(livedemoss)
    window.location.reload();
  };

  const readAllMsg = async () => {
    let { staus, message } = await readNotification();
    noticePopup(dispatch, false);
  };


  const closeBox = () => {
    noticePopup(dispatch, true);
  };

  useEffect(() => {
    setKycinitialvalidate(true);
    let localAcc = localStorage.getItem('accounts')
    let liveCount = accounts?.filter((val)=>(val.type=='live'))
    let demoCount = accounts?.filter((val)=>(val.type=='demo'))
    setDemocount(demoCount?.length)
    setLivecount(liveCount?.length)
    if (localAcc) {
      let acc = accounts?.find((val) => val.accountId == localAcc)
      // console.log('localStorage.setItem', acc, localAcc, accounts, new Date().getMilliseconds())
      Setaccount(acc)
      dispatch(SetAccounts(acc))
      return
    }
    else {
      if (isAuth) {
        localStorage.setItem('accounts', accounts[0]?.accountId)
        localStorage.setItem('liveorDemo', accounts[0]?.type)
        Setaccount(accounts[0])
        dispatch(SetAccounts(accounts[0]))
      }
    }
  }, [accounts])

  useEffect(() => {
    socketContext.socket.on("notice", (result) => {
      try {
        FetchunReadNotice(dispatch, result);
      } catch (err) {
        console.log(err, 'notice__err')
      }
    });
    socketContext.socket.on('Balanceupdate', (result) => {
      try {
        dispatch(setAccountData(result))
        let localAcc = localStorage.getItem('accounts')
        if (localAcc) {
          let acc = result.accounts?.find((val) => val.accountId == localAcc)
          // console.log('localStorage.setItem', acc, localAcc, accounts, new Date().getMilliseconds())
          Setaccount(acc)
          dispatch(SetAccounts(acc))
        }
      } catch (err) {
        console.log(err, 'Balanceupdate__err')
      }
    })
  }, [socketContext.socket]);

  useEffect(() => {
    return () => {
      if (isOpen) {
        readAllMsg();
      }

    };
  }, [isOpen]);

  useEffect(() => {
    setLiveDemo(hello)
  }, [hello]);
  return (
    <>
      <div className="inner_page_menu testmeu">
        <div className="dashboard_login">

          <Hidden smDown>
            <ul className="list-iline">

              {isAuth == false ?
                <li>
                  <Link to="/login" color="transparent" class="nav-link home_menu_btn1 btn_login_dash">Login</Link>
                </li> :
                <>
                  <li>
                    <Link to="/tradedashboard">

                      <i class={localStorage.getItem('headerActive') == "home" ? "fa fa-home activate" : "fa fa-home"} aria-hidden="true" onClick={() => { localStorage.setItem('headerActive', "home"); setHeaderActive("home") }} /></Link>

                  </li>
                  <li>
                    <Link to="/trade/">
                      <i class={localStorage.getItem('headerActive') == "arrows" ? "fa fa-solid fa-right-left activate" : "fa fa-solid fa-right-left "} onClick={() => { localStorage.setItem('headerActive', "arrows"); setHeaderActive("arrows") }} aria-hidden="true" /></Link>
                  </li>
                </>

              }
              {/* <li>
                <Link to="/tradedashboard">
                  <i class="fa fa-home" aria-hidden="true"></i></Link>
              </li> */}

              {/* { isEmpty(trade) ? 
                <li>
                <Link to="/trade/">
                  <i class="fa fa-solid fa-right-left" aria-hidden="true"></i></Link>
              </li>:<li></li> } */}
              {/* <li>
              <Link to="/login" color="transparent" class="nav-link home_menu_btn1 btn_login_dash">Login</Link>
              </li> */}


              {isAuth && (
                <>
                  <li className="noti_parent_po notiification_link_for_web">
                    {unread && unread.length > 0 ? (
                      <span className="notify_count">
                        {unread && unread.length}
                      </span>
                    ) : null}
                    {isOpen == false ? (
                      // <Button
                      //   class="btn btnNotification"
                      //   type="button"
                      //   data-toggle="collapse"
                      //   data-target="#notificationDropdown"
                      //   onClick={closeBox}
                      //   aria-expanded="false"
                      //   aria-controls="notificationDropdown"
                      // >
                      <Button
                        aria-controls="notificationDropdown"
                        aria-haspopup="true"
                        onClick={handleClickNotification}
                      >
                        <i className="fas fa-bell"></i>
                      </Button>
                    ) : (
                      <Button
                        aria-controls="notificationDropdown"
                        aria-haspopup="true"
                        onClick={() => { handleClickNotification("readall") }}
                      >
                        <i className="fas fa-bell"></i>
                      </Button>
                    )}
                    <Menu
                      id="notificationDropdown"
                      className="afterlogin_hr"
                      anchorEl={anchorElNoti}
                      keepMounted
                      open={Boolean(anchorElNoti)}
                      onClose={handleCloseNotification}
                    >

                      <div className="notificationDropdown noti_child_po">
                        {!isEmpty(unread) && unread.length > 0 ? (
                          <>
                            <div className="text-right">
                              <button onClick={() => { readAllMsg() }} className="mark_read_link">Mark all as read </button>
                            </div>
                            <ul>
                              {unread &&
                                unread.length > 0 &&
                                unread.map((item) => {
                                  return (
                                    <li>
                                      <p>
                                        <TimeAgo date={new Date(item.createdAt)}>
                                          {({ value }) => value}
                                        </TimeAgo>
                                      </p>
                                      <h5>{item.description}</h5>
                                    </li>
                                  );
                                })}
                            </ul>
                          </>
                        ) : (
                          <>
                            <ul>
                              <li>
                                <h5 className="text-center nomore_not_fobt">No more unread Notifications</h5>
                              </li>
                            </ul>
                          </>
                        )}

                        <p className="text-center pb-3 pt-2">
                          <Link to="/notification" className="all_noti_link_green">All Notifications</Link>
                        </p>
                      </div>
                    </Menu>

                  </li>

                  <li className="notiification_link_for_mob">
                    <a href="/notification">
                      <i className="fas fa-bell show_bell_web_on"></i>
                      <span className="hide_bell_web_on">Notifications</span>
                    </a></li>
                </>
              )}
              {/* {
              <li>
                <Link to="/spot">{t("MARKET")}</Link>
              </li>
            } */}

              {/* {isAuth && (
              <li>
                <Link to="/tradedashboard"><i class="fas fa-credit-card"></i></Link>
               
              </li>
            )} */}



              {isAuth && (
                <li className="mob_link_pad_rem">
                  <Link to="/support-ticket"><i class={localStorage.getItem('headerActive') == "excla" ? "fas fa-info-circle activate" : "fas fa-info-circle "} onClick={() => { localStorage.setItem('headerActive', "excla"); setHeaderActive("excla") }}></i></Link>
                </li>
              )}
              {isAuth && (
                <li className="li_ellipse_menu li_ellipse_menu_demo_ac">
                  <Button
                    aria-controls="profile_menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    {/* <div className="d-flex prof_icon_header"> */}
                    {/* <div> */}
                    {(Image && Image) ? <img src={`${config.API_URL}/images/kyc/${Image}`} className="prof_icon_header" alt="profileicon" /> : <img className="prof_icon_header" src={profileimg} />}

                    {/* </div> */}
                    {/* </div> */}

                    {/* <i className="fas fa-user"></i> */}
                    {/* <i class="fas fa-ellipsis-h"></i> */}
                  </Button>
                  <Menu
                    id="profile_menu"
                    className="afterlogin_hr"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <Link to="/tradedashboard"><MenuItem className="px-2 without_prof_hover">
                      <div className="d-flex afterlogin_profile"><div>
                        {(Image && Image) ? <img src={`${config.API_URL}/images/kyc/${Image}`} alt="profileicon" /> : <img className="prof_icon_header" src={profileimg} />}</div><div><p className="mx-3 mb-0 first">{`${firstName} ${lastName}`}</p>
                          <p className="second mb-2 mx-3">{email}</p>

                        </div> </div>
                    </MenuItem></Link>

                    {/* <Link to="/profile">           
                   <MenuItem>
                   <i className="fa fa-user" aria-hidden="true"></i><span>Profile</span>
                  </MenuItem>
                  </Link>
                  <hr/>  */}
                    <Link>

                      <MenuItem className="br-bottom">

                        {/* <div onClick={() => livedemo(livedemos == "LIVE" ? "DEMO" : "LIVE")} >
                        {livedemos == "LIVE" ?
                          <div className="d-flex align-items-center"><span className="demo_dot ml-0"></span><span>Switch to demo</span></div>
                          :
                          <div className="d-flex align-items-center"><span className="live_dot ml-0"></span><span>Swith to Live</span></div>
                        }

                      </div> */}
                      </MenuItem>

                    </Link>


                    <Link to="/security">
                      <MenuItem>
                        <i className="fa fa-lock" aria-hidden="true"></i><span>Security</span>
                      </MenuItem>
                    </Link>
                    <hr />
                    <Link to="/setting">
                      <MenuItem>
                        <i className="fa fa-cog" aria-hidden="true"></i><span>Settings</span>
                      </MenuItem>
                    </Link>

                    {/* <Link to="/orders">
                  <MenuItem>
                   <i className="fa fa-list" aria-hidden="true"></i><span>Orders</span>
                  </MenuItem>
                  </Link> */}
                    {/* <hr/> 
                  <MenuItem>
                    <Link to="/referral"><i className="fa fa-users" aria-hidden="true"></i><span>Referral</span></Link>
                  </MenuItem> */}
                    <hr />
                    {/* <MenuItem><Link to="/notification">Notifications</Link></MenuItem> */}
                    {/* <Link to="/history">
                  <MenuItem>
                    <i className="far fa-clock"></i><span>History</span>
                  </MenuItem>
                  </Link>
                  <hr/>   */}
                    <Link to="/support-ticket">
                      <MenuItem>
                        <i className="fas fa-info-circle text-white" aria-hidden="true"></i> <span>Support</span>
                      </MenuItem>
                    </Link>


                    <hr />
                    {/* <MenuItem>
                    <Link to="/orders">Orders</Link>
                  </MenuItem> */}
                    {/* <MenuItem><Link to="/api-management">API Management</Link></MenuItem> */}
                    <Link to="#" onClick={() => {
                      let oldintervalId = localStorage.getItem('getProfitCalculation')
                      if (oldintervalId) {
                        clearInterval(oldintervalId);
                      }
                      let oldintervalId2 = localStorage.getItem('ActiveCheck')
                      if (oldintervalId2) {
                        clearInterval(oldintervalId2);
                      }
                      logout(history, dispatch)
                      }}>
                      <MenuItem>

                        <i className="fas fa-sign-out-alt"></i> <span> Logout</span>

                      </MenuItem>
                    </Link>
                  </Menu>
                  <Dropdown className="demo_live_dd_menu">
                    {account &&
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <i className={account.type == 'demo' ? "fa fa-circle circle_demo_dot" : "fa fa-circle circle_live_dot"}></i> <span><span className={account.type == 'demo' ? "demo_span_txt" : "live_span_txt"}>{account.type}</span> - {account.accountId} - USD {toLocaleString(parseFloat(account.balance.toFixed(2)))} - {account.leverage} </span>
                      </Dropdown.Toggle>
                    }
                    <Dropdown.Menu>
                      {accounts && accounts ? accounts?.map((val, index) => (

                        <Dropdown.Item href="#/action-1" onClick={(e) => { OnSelect(val, e) }}>
                          <i className={val.type == 'demo' ? "fa fa-circle circle_demo_dot" : "fa fa-circle circle_live_dot"} aria-hidden="true"></i> <span><span className={val.type == 'demo' ? "demo_span_txt" : "live_span_txt"}>{val.type}</span> - {val.accountId}   - USD {toLocaleString(parseFloat(val.balance.toFixed(2)))} - {val.leverage}</span>

                          {/* <Dropdown.Item href="#/action-2">
                      <i className="fa fa-circle circle_live_dot" aria-hidden="true"></i> <span><span className="live_span_txt">{val.type}</span> - 700061 - Heading - USD 1 019.26 - 1:200</span>
                    </Dropdown.Item> */}
                        </Dropdown.Item>

                      )) : ('')}
                      <hr />
                      { liveCount == 5 && demoCount == 5 ? "":<button to="/" className="btn btn-light btn_nothing_bg" onClick={() => setAcctype(true)}>
                        <i className="fa fa-user mr-2 text-white font_12_user" aria-hidden="true"></i>
                        Create New Trading Account
                      </button>}
                    </Dropdown.Menu>
                  </Dropdown>

                </li>
              )}

              {/* {
            
             
             <li>
                <div className="toggleMode" title="toggle dark mode">
                  <label>
                    <input type="checkbox" checked={themeData == 'light' ? true : false} name="" onClick={() => setTheme(dispatch, themeData == 'light' ? 'dark' : 'light')} />
                    <span></span>
                  </label>
                </div>
              </li>
            } */}
            </ul>
          </Hidden>
          <Hidden only={["md", "lg", "xl"]}>
            <ul className="list-iline">
              {/* {
             <li>
                <div className="toggleMode" title="toggle dark mode">
                  <label>
                    <input type="checkbox" checked={themeData == 'light' ? true : false} name="" onClick={() => setTheme(dispatch, themeData == 'light' ? 'dark' : 'light')} />
                    <span></span>
                  </label>
                </div>
              </li>
            } */}

              {/* <li>
              <Link to="/spot">Spot</Link>
            </li> */}
              {/*<li>
              <Link to="/derivative">Derivative</Link>
            </li>*/}

              {isAuth && <li>
                <Dropdown className="demo_live_dd_menu">
                  {account &&
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <i className={account.type == 'demo' ? "fa fa-circle circle_demo_dot" : "fa fa-circle circle_live_dot"}></i> <span><span className={account.type == 'demo' ? "demo_span_txt" : "live_span_txt"}>{account.type}</span> - {account.accountId} - USD {account.balance} </span>
                    </Dropdown.Toggle>
                  }
                  <Dropdown.Menu>
                    {accounts ? accounts?.map((val, index) => (

                      <Dropdown.Item href="#/action-1" onClick={(e) => { OnSelect(val, e) }}>
                        <i className={val.type == 'demo' ? "fa fa-circle circle_demo_dot" : "fa fa-circle circle_live_dot"} aria-hidden="true"></i> <span><span className={val.type == 'demo' ? "demo_span_txt" : "live_span_txt"}>{val.type}</span> - {val.accountId}   - USD {val.balance}</span>

                        {/* <Dropdown.Item href="#/action-2">
                      <i className="fa fa-circle circle_live_dot" aria-hidden="true"></i> <span><span className="live_span_txt">{val.type}</span> - 700061 - Heading - USD 1 019.26 - 1:200</span>
                    </Dropdown.Item> */}
                      </Dropdown.Item>

                    )) : ('')}
                    <hr />
                    {liveCount == 5 && demoCount == 5 ? "" : <button to="/" className="btn btn-light btn_nothing_bg" onClick={() => setAcctype(true)}>
                      <i className="fa fa-user mr-2 text-white font_12_user" aria-hidden="true"></i>
                      Create New Trading Account
                    </button>}
                  </Dropdown.Menu>
                </Dropdown>
              </li>}

              {/* <button className="btn btn-livedemo my-0 ml-2 mr-0">{true ?
              <div className="d-flex align-items-center"><span className="live_dot"></span><span className="ml-2">Live</span></div>
              :
              <div className="d-flex align-items-center"><span className="demo_dot"></span><span className="ml-2">Demo</span></div>
            }</button> */}

              {isAuth == false ?
                <li>
                  <Link to="/login" >Login</Link>
                </li> :
                <></>
              }
              {isAuth && (
                <li>
                  <Link to="/tradedashboard">Dashboard</Link>
                </li>
              )}
              {/*{
              isAuth && <li>
                <Link to="/p2p">P2P</Link>
              </li>
            }*/}
              {isAuth && (
                <li>
                  <Link to="/trade/">Trade</Link>
                </li>
              )}


              {isAuth && (
                <li>
                  <Link to="/notification">Notifications</Link>
                </li>
              )}

              {/* {isAuth && (
              <li>
                <Link to="/profile">Profile</Link>
              </li>
            )} */}
              {/* {
              isAuth && <li>
                <Link to="/launchpad">Launchpad</Link>
              </li>
            }
             {
              isAuth && 
              <li>
                <Link to="/staking">Staking</Link>
              </li>
            } */}

              {/* {
              isAuth && <li>
                <Link to="/profile">KYC</Link>
              </li>
            } */}

              {/* {isAuth && (
              <li>
                <Link to="/security">Security</Link>
              </li>
            )} */}

              {isAuth && (
                <li>
                  <Link to="/security">Security</Link>
                </li>
              )}

              {isAuth && (
                <li>
                  <Link to="setting">Settings</Link>
                </li>
              )}

              {isAuth && (
                <li>
                  <Link to="/support-ticket">Support</Link>
                </li>
              )}

              {!isAuth && (
                <li>
                  <Link to="/tradedashboard">Home</Link>
                </li>
              )}

              {!isAuth && (
                <li>
                  <Link to="/trade">Trade</Link>
                </li>
              )}

              <li>
                <Link to="/login">Login</Link>
              </li>


              {/* {isAuth && (
              <li>
                <Link to="/referral">Referral</Link>
              </li>
            )} */}

              {/* {isAuth && (
              <li>
                <Link to="/history">History</Link>
              </li>
            )} */}



              {/* {
              isAuth && <li>
                <Link to="/api-management">API Management</Link>
              </li>
            } */}


              {isAuth && (
                <li>
                  <Link to="#" onClick={() => {
                    let oldintervalId = localStorage.getItem('getProfitCalculation')
                    if (oldintervalId) {
                      clearInterval(oldintervalId);
                    }
                    let oldintervalId2 = localStorage.getItem('ActiveCheck')
                    if (oldintervalId2) {
                      clearInterval(oldintervalId2);
                    }
                    logout(history, dispatch)
                    }}>
                    Logout
                  </Link>
                </li>
              )}
            </ul>
          </Hidden>
        </div>
        {acctype && <Accounttype onDismiss={() => setAcctype(false)} liveCount={liveCount} demoCount={demoCount} />}
      </div>

      {kycinitialvalidate && <Kycinitialmodal onDismiss={() => setKycinitialvalidate(false)} />}

    </>

  );
}