import React, { Component, useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap'
import Chart from "react-apexcharts";
import { getCopierChartData } from 'actions/copyTradeAction';
const CopierStat = (props) => {
  const {TrdaerDetail}=props
  console.log("CopierStat_CopierStat", props)
  const monthArray = ['Jan', 'Feb', 'Mar', 'Apr', "May", 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  useEffect(() => {
    getChartData();
    copyingFundsLive();
  }, [TrdaerDetail])
  const [categories, setCategories] = useState([])
  const [chartData, setChartData] = useState()

  // const copyierChart = ()=>{
  //   const currentMonth = monthArray.indexOf(monthArray[new Date().getMonth() + 1])
  //   const getFilterData = monthArray.slice(currentMonth-3, currentMonth)
  //   setCategories(getFilterData)
  //   console.log('copyierChart',{currentMonth:currentMonth,getFilterData:getFilterData})
  // }

  const getChartData = async()=> {
    const getData = await getCopierChartData({accountId: props?.TrdaerDetail?.accountId});
    setChartData(getData?.result?.data[0])
    setCategories(getData?.result?.monthArr)
    console.log('CopierStat_getData', getData?.result?.data[0])
  }

  // const data5 = {
  //   series: [{
  //     name: 'Inflation',
  //     data: [2.3, 3.1, 4.0]
  //   }],
  //   options: {
  //     chart: {
  //       height: 350,
  //       type: 'bar',
  //     },
  //     plotOptions: {
  //       bar: {
  //         borderRadius: 10,
  //         dataLabels: {
  //           position: 'top', // top, center, bottom
  //         },
  //       }
  //     },
  //     dataLabels: {
  //       enabled: true,
  //       formatter: function (val) {
  //         return val + "%";
  //       },
  //       offsetY: -20,
  //       style: {
  //         fontSize: '12px',
  //         colors: ["#304758"]
  //       }
  //     },

  //     xaxis: {
  //       categories: ["Jan", "Feb", "Mar"],
  //       position: 'bottom',
  //       axisBorder: {
  //         show: false
  //       },
  //       axisTicks: {
  //         show: false
  //       },
  //       crosshairs: {
  //         fill: {
  //           type: 'gradient',
  //           gradient: {
  //             colorFrom: '#D8E3F0',
  //             colorTo: '#BED1E6',
  //             stops: [0, 100],
  //             opacityFrom: 0.4,
  //             opacityTo: 0.5,
  //           }
  //         }
  //       },
  //       tooltip: {
  //         enabled: true,
  //       }
  //     },
  //     yaxis: {
  //       axisBorder: {
  //         show: false
  //       },
  //       axisTicks: {
  //         show: false,
  //       },
  //       labels: {
  //         show: true,
  //         formatter: function (val) {
  //           return val + "%";
  //         }
  //       }

  //     },
  //     title: {
  //       text: 'Monthly Inflation in Argentina, 2002',
  //       floating: true,
  //       offsetY: 330,
  //       align: 'center',
  //       style: {
  //         color: '#444'
  //       }
  //     }
  //   },



  // }

  const data4 = {
    series4: [
      {
        name: "Copyier",
        data: chartData && [chartData?.previousOfprevious, chartData?.previous, chartData?.current]
      },

    ],
    options4: {
      chart: {
        id: "basic-bar",
        foreColor: "#ccc",
        toolbar: {
          autoSelected: "pan",
          show: false
        }
      },
      colors: ["#2769ba"],

      grid: {
        borderColor: "#555",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: false
          },
        }
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1]
      },
      markers: {
        size: 2,
        colors: ["#2769ba"],
        strokeColor: "#2769ba",
        strokeWidth: 3
      },
      //    tooltip: {
      //   theme: "dark"
      // },
      tooltip: {
        theme: "dark",
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div class="arrow_box">' +
            '<span>' +
            "Average number of copiers : " +
            series[seriesIndex][dataPointIndex] +
            '</span>' +
            '</div>'
          )
        }
      },

      xaxis: {
        categories: categories,
        position: 'bottom',
        axisBorder: {
          show: true
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        labels: {
          show: false
        }
      }
    },
  };


  const [copyierLiveFunds, setCopyierLiveFunds] = useState(0)
  const copyingFundsLive = async () => {
    var balance = 0;
    const liveFollowers = props?.TrdaerDetail?.Followers?.map((value, index) => {
      if ((value.status == "active" && value.type == "live")) {
        balance = balance += value.balanceInvestment
      }
    });
    setCopyierLiveFunds(balance)
  }
  return (
    <Tabs defaultActiveKey="copierstats" id="uncontrolled-tab-example2" className="tbas_bg_tran_new graytab pt-2 px-0 copie_stat_pad">
      <Tab eventKey="copierstats" title="Copier Stats">
        <div className="row w-100 mx-0">

          {
            chartData && chartData?.show == 0 || chartData?.show == "0" ? <div className='without_map_graph col-12 col-lg-8 pr-3 pr-lg-0 mb-roi-mon'><p className='text-center'>Not enough data to display Copiers chart</p></div> :
              <div className="col-12 col-lg-8 pr-3 pr-lg-0 mb-roi-mon">
                <Chart
                  options={data4?.options4}
                  series={data4?.series4}
                  type="bar" height={218} width="100%" className="px-0"
                />
              </div>
          }

          <div className="col-12 col-lg-4 pl-1 pl-lg-1 detail_tab_height_1 chart_cont_cent_div deal_show_copir">
          <p class="boxheadings mb-1">Copier Stats Details</p>
            <div className="darkbox w-100 h-100">
              <ul className="pl-0 symbolTab_list">


                <li>
                  <div className="d-flex p-0 justify-content-between">
                    <small className="text-light">Currently Copying</small>
                    <small className="text-white">{props && props?.TrdaerDetail?.Followers?.filter((value) => (value.status == "active")).length}</small>
                  </div>
                </li>
                <li>
                  <div className="d-flex p-0 justify-content-between">
                    <small className="text-light">
                      Active Live Copiers
                    </small>
                    <small className="text-white">{props && props?.TrdaerDetail?.Followers?.filter((value) => (value.status == "active" && value.type == "live")).length}</small>
                  </div>
                </li>

                <li>
                  <div className="d-flex p-0 justify-content-between">
                    <small className="text-light">
                      Active Demo Copiers
                    </small>
                    <small className="text-white">{props && props?.TrdaerDetail?.Followers?.filter((value) => (value.status == "active" && value.type == "demo")).length}</small>
                  </div>
                </li>
                <li>
                  <div className="d-flex p-0 justify-content-between">
                    <small className="text-light">
                      All Time Copiers
                    </small>
                    <small className="text-white">{props && props?.TrdaerDetail?.Followers?.length}</small>
                  </div>
                </li>
                <li>
                  <div className="d-flex p-0 justify-content-between">
                    <small className="text-light">Copying Funds</small>
                    <small className="text-white">
                      USD {copyierLiveFunds}
                    </small>
                  </div>
                </li>


              </ul>
            </div>

          </div>
        </div>

      </Tab>
    </Tabs>
  )
}

export default CopierStat