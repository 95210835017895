import React, { useEffect, useState } from 'react';
import { Modal, Tabs, Dropdown, InputGroup, Form, Tab } from 'react-bootstrap'
import Slider from '@material-ui/core/Slider';

//import action
import { SetEquitystopLoss } from 'actions/copyTradeAction';

//import lib 
import { toastAlert } from 'lib/toastAlert';
import isEmpty from 'lib/isEmpty';
const EquityStoploss = (props) => {
  const { EquityData } = props
  const [equitystop, SetEquitystop] = useState(true)
  const [equitystoploss, setequitystoploss] = useState(0)
  const [error,setError]=useState({})
  const marks = [
    {
      value: 0,
      label: '1%',
    },
    {
      value: 25,
      label: '25%',
    },
    {
      value: 50,
      label: '50%',
    },
    {
      value: 75,
      label: '75%',
    },
    {
      value: 100,
      label: '100%',
    },
  ];
  console.log(EquityData, 'EquityData')
  useEffect(() => {
    setequitystoploss(EquityData?.equitystoploss)
  }, [EquityData])

  const valuetext = (e, value) => {
    console.log(value, 'valuetext')
    setequitystoploss(value)
    return `${value}%`;
  }
  
  const handleSubmit =async()=>{
    try{
      let data = {
        followerAccountId:EquityData.followerAccountId,
        traderId:EquityData.traderId,
        traderAccountId:EquityData.traderAccountId,
        equitystoploss:equitystoploss
      }
      if(!isEmpty(error)){
        return false
      }
      const {status,message}=await SetEquitystopLoss(data)
      if(status){
        toastAlert('success',message,'EquityStoploss')
        props.onDismiss()
      }else{
        toastAlert('error',message,'EquityStoploss')
      }
    }catch(err){
      console.log(err,'handleSubmit--err')
    }
  }
  return (
    <>
      <Modal show={equitystop} className="modalbg font-12" size="md" centered scrolable>
        <Modal.Header className='align-items-center'>
          <Modal.Title>Set Equity Stop Loss - TradingOne</Modal.Title>
          <button className='closebtn btn btn-link text-light nounder' onClick={() => props.onDismiss()}>
            <i className="fa fa-times fa-6" aria-hidden="true"></i>
          </button>
        </Modal.Header>
        <Modal.Body className='font-12'>
          <div className='themetab'>
            <h5>Set Equity Stop Loss</h5>
            <hr />
            <p>Stop Copying if Equity Drops to:</p>
            <div class="input-group inpt_grp_equity mt-2 mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">USD</span>
              </div>
              <input
                type="number"
                class="form-control"
                value={equitystoploss}
                aria-label="equity"
                aria-describedby="basic-addon1"
                onChange={(e)=>{
                  let value = e.target.value
                  if(e.target.value  > EquityData?.balanceInvestment){
                    setError({"equitystoploss":`Equity stop loss must not be greater than the ${EquityData?.balanceInvestment}`})
                  }
                  setequitystoploss(value)
                }}
              />
            </div>
            <span className="text-danger">{error && error?.equitystoploss}</span>
            <Slider
              defaultValue={equitystoploss}
              // getAriaValueText={valuetext}
              aria-labelledby="discrete-slider-custom"
              step={0.1}
              min={50}
              max={EquityData?.balanceInvestment}
              valueLabelDisplay="auto"
              onChange={valuetext}
              value={equitystoploss}
            // marks={marks}
            />
            <div className='d-flex align-items-center justify-content-between'>
              <p>USD 50</p>
              <p>USD {EquityData?.balanceInvestment}</p>
            </div>
            <div>
            </div>
            <div className='d-flex align-items-center pb-3'>
              <button className='btn green-btn mr-2 w-full' onClick={()=>{handleSubmit()}}>OK</button>
              <button className='btn red-btn w-full' onClick={()=>{props.onDismiss()}}>Cancel</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default EquityStoploss