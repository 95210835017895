import React, { useEffect, useState } from 'react';
import { Modal, Tabs, Dropdown, InputGroup, Form, Tab } from 'react-bootstrap'
import { CKEditor } from 'ckeditor4-react';
import { useDispatch ,useSelector} from 'react-redux';

//import lib
import isEmpty from '../../../../lib/isEmpty';
import { toastAlert } from '../../../../lib/toastAlert';

//import validation
import { StrategyValidate } from 'components/SportTrade/validation';

//import action
import { createStrategy } from '../../../../actions/copyTradeAction'

//import components
import Wishlisttab from '../elements/Wishlisttab';
import Symboltab from "../elements/Symboltab";
import Infomation from './Information';

const initialFormValue = {
    'strategyName': "",
    'minInvestment': '',
    'allowCopying': '',
    'visibleFor': '',
    'showPositions': '',
    'accountId': '',
    'type': '',
    'currency':'USD',
    'leverage':'',
    'volumeFee': ''
}

const Startegy = (props) => {
    console.log("props_prpos_prpos",props);
    const [show, setShow] = useState(true)
    const [showdrop, setShowDrop] = useState(false)
    const [info, setInfo] = useState(false)
    const [symbolTab, setSymbolTab] = useState(false)
    const [formValue, setFormValue] = useState(initialFormValue)
    const [Descriptions,setDescriptions] =useState('')
    const [error, setError] = useState({})
    const dispatch =useDispatch()
    const kycStatus = useSelector(state => state.userKyc.Status);
    const { verifiedstatus, Synapsverify } = kycStatus
    const { strategyName, minInvestment, allowCopying, visibleFor, showPositions, accountId,type,volumeFee } = formValue

    const handleChange = (e) => {
        try {
            e.preventDefault();
            const { name, value } = e.target;
            console.log(name, value,'handleChange')
            let formData = { ...formValue, ...{ [name]: value }  };
            setFormValue(formData)
            console.log(formValue,'handleChange-->')
        } catch (err) {
            console.log(err, 'handleChange')
        }
    }
    useEffect(() => {
        if (props?.accountDetails) {
            let accountDetails = props?.accountDetails
            let formData = { ...formValue, ...{'accountId': accountDetails?.accountId, 'type': accountDetails?.type ,currency:accountDetails?.type,leverage:accountDetails?.leverage}};
            setFormValue(formData)
        }
    }, [props])

    const handleEditorChange = (e) => {
        try {
            // e.preventDefault();
            // console.log(formValue,e,'...formValue')
            // let formData = { ...formValue, ...{'Descriptions': e.editor.getData()}};
            //setFormValue(formData);
            setDescriptions(e.editor.getData())
             console.log(formValue,'...formValue')
            if (!isEmpty(e)) {
                setError({ 'Descriptions': '' })
            }
        } catch (err) {
            console.log(err, 'handleEditorChange')
        }
    }
    const handleSubmit = async () => {
        try {
            if (verifiedstatus === 'PENDING') {
                toastAlert('error', "You are not able to trade until kyc is approved")
                props.onDismiss()
                return
            }
            else if (verifiedstatus === 'REJECTED' || verifiedstatus === 'Not verified') {
                toastAlert('error', "Please submit your kyc")
                props.onDismiss()
                return
            }
            let reqData ={
                strategyName,
                minInvestment,
                allowCopying,
                visibleFor,
                showPositions,
                Descriptions:Descriptions,
                accountId,
                type,
                volumeFee
            }
            console.log('handleSubmit-->',formValue,reqData)
            let validationError = await StrategyValidate(reqData)
            if (Object.keys(validationError).length > 0) {
                console.log('handleSubmit1-->',validationError)
                setError(validationError)
                return
            }
            console.log('handleSubmit-->')
            let { status, message, error, result } = await createStrategy(reqData,dispatch);
            console.log("result_result_result_",error);
            if (status == 'success') {
                toastAlert('success', message, 'Create strategy')
                props.onDismiss()
            } else {
                toastAlert('error', message, 'Create strategy')
            }
        } catch (err) {
            console.log(err, 'handleSubmit')
        }
    }
    return (
       
        <>
            <Modal show={show} className="modalbg font-12" size="md" centered scrolable>
                {info && <Infomation onDismiss={() => setInfo(false)} />}
                <Modal.Header className='align-items-center'>
                    <Modal.Title>Become a Strategy Provider</Modal.Title>
                    <button className='closebtn btn btn-link text-light nounder' onClick={() => props.onDismiss()}>
                    <i className="fa fa-times fa-6" aria-hidden="true"></i>

                        </button>
                </Modal.Header>
                <Modal.Body className='font-12'>
                    <div className='themetab strategy'>
                        <h5>Become a Strategy Provider</h5>
                        <hr />
                        <div className='d-flex jc-between widthset mb-3'>
                            <div>
                                <p>Strategy Name</p>
                            </div>
                            <div>
                                <input className='form-control themeinput' type="text" name='strategyName' value={strategyName} onChange={handleChange} />
                                <span className="text-danger">{error && error?.strategyName}</span>
                            </div>
                        </div>
                        <p className='heading'>Conditions</p>
                        <hr />
                        <div className='d-flex jc-between widthset mb-3'>
                            <div>
                                <p>Min.Investment</p>
                            </div>
                            <div>
                                <div class="input-group inpt_grp_equity equit_input_width">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">USD</span>
                                    </div>
                                    <input type="number" class="form-control" placeholder='50.00' aria-label="equity" aria-describedby="basic-addon1" name='minInvestment' value={minInvestment} onChange={handleChange} />
                                </div>
                                <span className="text-danger">{error && error?.minInvestment}</span>
                            </div>
                        </div>
                        <div className='d-flex jc-between widthset mb-3'>
                            <div>
                                <p>Allow Copying For</p>
                            </div>
                            <div className='select_erro_input'>
                                <select name='allowCopying' className="selectcount themeselect mt-2 w-full" onChange={handleChange}>
                                    <option hidden></option>
                                    <option value="Live and Demo">Live and Demo</option>
                                    <option value="Live">Live</option>
                                    <option value="Demo">Demo</option>
                                </select>
                        <span className="text-danger">{error && error?.allowCopying}</span>

                            </div>
                        </div>

                        <p className='heading'>Visibility</p>
                        <hr />
                        <div className='d-flex jc-between widthset mb-3'>
                            <div>
                                <p>Visible For</p>
                            </div>
                            <div className='select_erro_input'>
                                <select name='visibleFor' className="selectcount themeselect w-full" onChange={handleChange}>
                                    <option hidden></option>
                                    <option value="Everyone">Everyone</option>
                                    <option value="Traders With Invitation Link">Traders With Invitation Link</option>

                                </select>
                        <span className="text-danger">{error && error?.visibleFor}</span>

                            </div>
                        </div>

                        <div className='d-flex jc-between widthset mb-3'>
                            <div>
                                <p>Show Positions To</p>
                            </div>
                            <div className='select_erro_input'>
                                <select name='showPositions' className="selectcount themeselect mt-2 w-full" onChange={handleChange}>
                                    <option hidden></option>
                                    <option value="Everyone">Everyone</option>
                                    <option value="Only my Copiers">Only my Copiers</option>
                                    <option value="No one">No one</option>
                                </select>
                                <span className="text-danger">{error && error?.showPositions}</span>
                            </div>
                        </div>
                        <div className='d-flex jc-between widthset mb-3'>
                            <div>
                                <p>Volume Fee</p>
                            </div>
                            <div>
                                <div class="input-group inpt_grp_equity">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">%</span>
                                    </div>
                                    <input type="number" class="form-control" placeholder='0' aria-label="equity" aria-describedby="basic-addon1" name='volumeFee' value={volumeFee} onChange={handleChange} />
                                </div>
                                <span className="text-danger">{error && error?.volumeFee}</span>

                            </div>
                        </div>
                        <p className='heading'>Description</p>
                        <hr />
                        <CKEditor
                            config={{
                                extraAllowedContent: 'div(*)',
                                allowedContent: true,
                                height: 500,
                            }}
                            initData={Descriptions}
                            onChange={handleEditorChange}
                        />
                        <span className="text-danger">{error && error?.Descriptions}</span>
                        <div className='d-flex align-items-center pb-3 mt-2 block_res_mob_btn'>
                            <button className='btn green-btn mr-2 w-full' onClick={() => { handleSubmit() }}>Become a Strategy Provider</button>
                            <button className='btn red-btn w-full' onClick={() => props.onDismiss()}>Cancel</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Startegy