import { object } from 'prop-types';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux";
import { lotDisplay ,toLocaleString} from 'lib/roundOf';

const Infomation = (props) => {
    const [info, setInfo] = useState(true)
    const pairData = useSelector(state => state.tradePair)
    console.log("pairDatapairData_pairData_V_V",pairData);
    const fees = pairData.fees
    console.log("fees----->",fees);
    return (
        <Modal show={info} size="md" className="modalbg m-auto font-12 infomodal" centered scrolable>
            <Modal.Header className='align-items-center'>
                <Modal.Title>Information for{pairData.displayname}</Modal.Title>
                <button className='closebtn btn btn-link text-light nounder' onClick={() => props.onDismiss()}>
                <i className="fa fa-times fa-6" aria-hidden="true"></i>

                    </button>
            </Modal.Header>
            <Modal.Body>
                <h6 className='mb-2'>{pairData.displayname}</h6>
                <p className='mb-2'>{pairData.firstCurrencySymbol} vs {pairData.secondCurrencySymbol}</p>
                <div className='darkbox mb-2 p-3 br-4'>
                    <div className='d-flex jc-between mb-1'>
                        <p>Base Asset</p>
                        <p>{pairData.firstCurrencySymbol}</p>
                    </div>
                    <div className='d-flex jc-between mb-1'>
                        <p>Quote Asset</p>
                        <p>{pairData.secondCurrencySymbol}</p>
                    </div>
                    {/* <div className='d-flex jc-between mb-1'>
                        <p>Minimum Change</p>
                        <p>0.01</p>
                    </div> */}
                </div>
                <div className='darkbox mb-2 p-3 br-4'>
                    <div className='d-flex jc-between mb-1'>
                        <p>Lot Size</p>
                        <p>{pairData.firstCurrencySymbol}    {pairData && pairData.lotsize ? toLocaleString(parseFloat(pairData.lotsize)) : 0}</p>
                    </div>
                    {/* <div className='d-flex jc-between mb-1'>
                        <p>Commission  (per mil. USD volume)</p>
                        <p>0.00 USD</p>
                    </div> */}
                    <div className='d-flex jc-between mb-1'>
                        <p>Minimum Trade Volume</p>
                        <p>{pairData && pairData.minQuantity} Lots</p>
                    </div>
                    <div className='d-flex jc-between mb-1'>
                        <p>Maximum Trade Volume</p>
                        <p>{pairData && pairData.maxQuantity} Lots</p>
                    </div>
                </div>
                <div className='darkbox mb-2 p-3 br-4'>
                    <div className='d-flex jc-between mb-1'>
                        <p>Market Hours</p>
                        <div>
                            <p className='mb-1'>Mon 17:35 - Mon 23:55</p>
                            <p className='mb-1'>Tue 17:35 - Tue 23:55</p>
                            <p className='mb-1'>Wed 17:35 - Wed 23:55</p>
                            <p className='mb-1'>Thu 17:35 - Thu 23:55</p>
                            <p className='mb-1'>Fri 17:35 - Fri 23:55</p>
                        </div>
                    </div>

                </div>
                {/* <div className='darkbox mb-2 p-3 br-4'>
                    <div className='d-flex jc-between mb-1'>
                        <p>Administrative Charge</p>
                        <p>0.00</p>
                    </div>
                    <div className='d-flex jc-between mb-1'>
                        <p>Grace Period (Days)</p>
                        <p>3</p>
                    </div>
                    <div className='d-flex jc-between mb-1'>
                        <p>3-Days Administrative Charge</p>
                        <p>Wednesday</p>
                    </div>
                </div> */}
                <div className='darkbox mb-2 p-3 br-4'>
                    <div className='d-flex jc-between mb-1'>
                        <p>Buy Positions</p>
                        <p>  {pairData && pairData.buyposition?.sum ? lotDisplay(parseFloat(pairData.buyposition?.sum), pairData.precision) : 0} Lots</p>
                    </div>
                    <div className='d-flex jc-between mb-1'>
                        <p>Sell Positions</p>
                        <p>{pairData && pairData.sellposition?.sum ? lotDisplay(parseFloat(pairData.sellposition?.sum), pairData.precision) : 0} Lots</p>
                    </div>
                </div>


                <div className='darkbox mb-2 p-3 br-4'>
                    {
                        fees && fees.length > 0 && fees.map((item, key) =>
                        (<>
                            <div className='d-flex jc-between mb-1'> {Object.keys(item)}
                            <p>{Object.values(item)} % </p></div>
                        </>
                        ))
                    }

                </div>



                {/* <div className='darkbox mb-2 p-3 br-4'>
                    <div className='d-flex jc-between '>
                        <p>FIX Symbol ID</p>
                        <p>375</p>
                    </div>
                </div> */}
                <h6>Leverage (Volume {pairData.firstCurrencySymbol} / Leverage)</h6>
                <div className='darkbox mb-2 p-3 br-4'>
                    <div className='d-flex jc-around'>
                        <p>Any</p>
                        <p>{props.leverage && props.leverage}</p>
                    </div>
                </div>
                <button className='btn graybtn w-full' onClick={() => props.onDismiss()}>Cancel</button>

            </Modal.Body>
        </Modal>

    )
}

export default Infomation