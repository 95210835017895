import React, { Component, useState, useEffect } from 'react';
import Myaccount from './myaccount';
import Fundaccount from './fundaccount';
import Accountsetting from './Accountsetting';
import Referrallist from './Referrallist';
import Requeststatus from './Requeststatus';
import Accounttrade from "./Accounttrade"
import Transaction from './Transaction';
import { useSelector, useDispatch } from 'react-redux';
import { Link, NavLink, useHistory, useParams } from 'react-router-dom';
import { getPairList, setPairList } from 'actions/spotTradeAction';

import Header from "components/Header/Header.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
const Tradedashboard = () => {
  let dispatch = useDispatch()
  const [Redirected, setRedirected] = useState("myaccount")
  // const[Redirected,setRedirecteds]=useState("transcation")
  const { referralCode } = useSelector(state => state.auth);
  const pairList = useSelector(state => state.pairList);
  const params = useParams()
  console.log(params, "params")
  const history = useHistory();
  const dashboardRoutes = [];

  useEffect(() => {
    // Update the document title using the browser API
    if (!(pairList.length > 0)) {
      fetchPairList()
    }
    document.body.style.overflowX = "unset";
    if (params.id === 'entity' || params.id === 'individual' || params.id === "settings") {
      setRedirected("settings")
    }

  });
  useEffect(() => {
    if (params.id === 'deposit' || params.id === 'withdraw') {
      setRedirected("fundaccount")
    }
  })

  const referalid = async () => {
    let pair = `${referralCode}`
    console.log("pair----p--->", pair);
    history.push('/tradedashboard/' + pair)
  }
  const fetchPairList = async () => {
    try {
      const { status, result } = await getPairList()
      if (status == 'success') {
        setPairList(result, dispatch)
      }
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <div className='tradedashboard'>
      <Header className="header"
        // color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinksAfterlogin />}
        fixed
      // changeColorOnScroll={{
      //   height: 20,
      //   color: "dark",
      // }}
      />
      <div className='container header_space custom-container'>
        <nav>
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <a class={Redirected.includes("myaccount") ? "nav-item nav-link active" : "nav-item nav-link"} onClick={() => { setRedirected("myaccount"); history.push('/tradedashboard') }} id="nav-myaccount-tab" data-toggle="tab" href="#nav-myaccount" role="tab" aria-controls="nav-myaccount" aria-selected="true" >My Account</a>
            <a class={Redirected.includes("fundaccount") ? "nav-item nav-link active" : "nav-item nav-link"} onClick={() => { setRedirected("fundaccount"); history.push('/tradedashboard') }} id="nav-fundaccount-tab" data-toggle="tab" href="#nav-fundaccount" role="tab" aria-controls="nav-fundaccount" aria-selected="true">Fund Account</a>
            <a class={Redirected.includes("transcation") ? "nav-item nav-link active" : "nav-item nav-link"} onClick={() => { setRedirected("transcation"); history.push('/tradedashboard') }} id="nav-transcation-tab" data-toggle="tab" href="#nav-transcation" role="tab" aria-controls="nav-transcation" aria-selected="false">Transaction</a>
            <a class={Redirected.includes("trade") ? "nav-item nav-link active" : "nav-item nav-link"} onClick={() => { setRedirected("trade"); history.push('/tradedashboard') }} id="nav-trade-tab" data-toggle="tab" href="#nav-trade" role="tab" aria-controls="nav-trade" aria-selected="false">Trade</a>
            <a class={Redirected.includes("settings") ? "nav-item nav-link active" : "nav-item nav-link"} onClick={() => { setRedirected("settings"); history.push('/tradedashboard') }} id="nav-settings-tab" data-toggle="tab" href="#nav-settings" role="tab" aria-controls="nav-settings" aria-selected="false">Account Settings</a>
            <a class={Redirected.includes("referral") ? "nav-item nav-link active" : "nav-item nav-link"} onClick={() => referalid()} id="nav-referral-tab" data-toggle="tab" href="#nav-referral" role="tab" aria-controls="nav-referral" aria-selected="false">Referral</a>
            {/* <a class={Redirected.includes("request") ? "nav-item nav-link active" : "nav-item nav-link"} onClick={() => { setRedirected("request"); history.push('/tradedashboard') }} id="nav-request-tab" data-toggle="tab" href="#nav-request" role="tab" aria-controls="nav-request" aria-selected="false">Request</a> */}
          </div>
        </nav>
      </div>
      <hr />
      <div className='container custom-container'>

        <div class="tab-content" id="nav-tabContent">
          <div class={Redirected.includes("myaccount") ? "tab-pane fade show active " : "tab-pane fade show  "} id="nav-myaccount" role="tabpanel" aria-labelledby="nav-myaccount-tab"><Myaccount setRedirected={setRedirected} /></div>
          <div class={Redirected.includes("fundaccount") ? "tab-pane fade show active " : "tab-pane fade show  "} id="nav-fundaccount" role="tabpanel" aria-labelledby="nav-fundaccount-tab"><Fundaccount setRedirected={setRedirected} /></div>
          <div class={Redirected.includes("transcation") ? "tab-pane fade show active " : "tab-pane fade show  "} id="nav-transcation" role="tabpanel" aria-labelledby="nav-transcation-tab"><Transaction setRedirected={setRedirected} /></div>
          <div class={Redirected.includes("trade") ? "tab-pane fade show active " : "tab-pane fade show  "} id="nav-trade" role="trade" aria-labelledby="nav-trade-tab"><Accounttrade /></div>
          <div class={Redirected.includes("settings") ? "tab-pane fade show active " : "tab-pane fade show  "} id="nav-settings" role="tabpanel" aria-labelledby="nav-settings-tab"><Accountsetting /></div>
          <div class={Redirected.includes("referral") ? "tab-pane fade show active " : "tab-pane fade show  "} id="nav-referral" role="tabpanel" aria-labelledby="nav-referral-tab"><Referrallist /></div>
          <div class={Redirected.includes("request") ? "tab-pane fade show active " : "tab-pane fade show  "} id="nav-request" role="tabpanel" aria-labelledby="nav-request-tab"><Requeststatus /></div>


        </div>

      </div>
    </div>
  );
}


export default Tradedashboard;